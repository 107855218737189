import { ContactPersonContactTypeEnum } from "~/api/openapi";

export const RetailerPageDE = {
  title: "Mein Unternehmen",
  header: {
    customerNumber: "Kundennummer: {{number}}",
    customerSince: "Kunde seit: {{date}}",
  },
  tabs: {
    basicInformationLabel: "Basisinformationen",
    accountAdministrationLabel: "Accountverwaltung",
    billingDeliverAddress: "Rechnungs- und Lieferadressen",
    paymentAgreements: "Zahlungsvereinbarungen",
    discountAgreements: "Rabattvereinbarungen",
    socialMediaLabel: "Soziale Medien",
    contactPersons: "Ansprechpersonen",
  },
  basicInformation: {
    error:
      "Beim Laden der Unternehmensdaten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
    titel: "Allgemeine Unternehmens- & Kontaktdaten",
    changeButtonLabel: "Änderung beantragen",
    basicCompanyinformationLabel: "Allgemeine Unternehmensdaten",
    basicCompanyContactdataLabel: "Allgemeine Kontaktdaten",
    basicCompanyInvoiceAddressLabel: "Rechnungsadresse",
    basicCompanyMediaLinksLabel: "Media Links",
    changeCompanyInformationButton: "Änderung der Unternehmensdaten beantragen",
    changeCompanyContactdataButton: "Änderung der Kontaktdaten beantragen",
    companyNameLabel: "Firmenname",
    businessTypeLabel: "Geschäftstyp",
    phoneNumberLabel: "Telefonnummer",
    streetLabel: "Straße",
    streetNumberLabel: "Hausnummer",
    zipCodeLabel: "PLZ",
    cityLabel: "Ort",
    countryLabel: "Land",
    vatIdLabel: "USt-ID",
    phoneLabel: "Telefon",
    emailLabel: "E-Mail",
    shopUrl: "Shop-URL",
    websiteLabel: "Webseite",
    socialMediaFbLabel: "Facebook",
    socialMediaTwitterLabel: "Twitter",
    socialMediaTwitchLabel: "Twitch",
    socialMediaInstagramLabel: "Instagram",
    socialMediaYoutubeLabel: "Youtube",
    salutation: {
      label: "Anrede",
    },
    invoiceAddress: {
      nameLabel: "Name",
      additionalInfoLabel: "Zusatz",
      streetLabel: "Straße",
      streetNumberLabel: "Hausnummer",
      zipCodeLabel: "PLZ",
      cityLabel: "Ort",
      countryLabel: "Land",
    },
    retailerDataForm: {
      description:
        "Über nachfolgende Eingabefelder kannst du Änderungen deiner Unternehmensdaten beantragen. <1 /> Bitte beachte, dass für alle Änderungen (Ausnahme Geschäftstyp) ein Gewerbeschein-Nachweis notwendig ist.",
      companyNameLabel: "Firmenname",
      businessTypeLabel: "Geschäftstyp",
      streetNameLabel: "Straße",
      houseNumberLabel: "Hausnummer",
      additionalInfoLabel: "Adresszusatz",
      zipCodeLabel: "PLZ",
      cityLabel: "Ort",
      countryLabel: "Land",
      vatIdLabel: "Umsatzsteuer-ID",
      uploadLabel: "Nachweis Gewerbeschein",
      saveButtonLabel: "Änderung beantragen",
      changeNotice:
        "Eine Änderung der Geschäftsadresse führt auch zu einer Änderung der primären Rechnungsadresse und umgekehrt, da diese Daten verknüpft sind. <1 /> Du musst daher keine separate Änderung der Rechnungsadresse veranlassen.",
      successMessage: "Eine Änderung der Unternehmensdaten wurde erfolgreich beantragt.",
      tradeLicenseRequiredError:
        "Der Gewerbeschein Nachweis ist Pflicht wenn irgendein Feld abgesehen vom Geschäftstyp geändert wurde.",
      validation: {
        companyName: "Firmenname ist ein Pflichtfeld",
        streetName: "Straße ist ein Pflichtfeld",
        houseNumber: "Hausnummer ist ein Pflichtfeld",
        zipCode: "PLZ ist ein Pflichtfeld",
        city: "Ort ist ein Pflichtfeld",
        vatId: "Umsatzsteuer-ID ist ein Pflichtfeld",
      },
    },
    retailerContactForm: {
      description:
        "Über nachfolgende Eingabefelder kannst du Änderungen deiner Kontaktdaten beantragen.",
      phoneLabel: "Telefon",
      emailLabel: "E-Mail-Adresse",
      websiteUrlLabel: "Webseite",
      shopUrlLabel: "Shop-URL",
      twitterUrlLabel: "Twitter",
      facebookUrlLabel: "Facebook",
      youtubeUrlLabel: "Youtube",
      instagramUrlLabel: "Instagram",
      twitchUrlLabel: "Twitch",
      successMessage: "Eine Änderung der Kontaktdaten wurde erfolgreich beantragt.",
      saveButtonLabel: "Änderung beantragen",
      errorMessage:
        "Die Änderungen konnten leider nicht beantragt werden. Bitte versuche es später erneut.",
    },
  },
  accountAdministration: {
    title: "Übersicht meiner Mitarbeiter",

    table: {
      account: "Account",
      firstName: "Vorname",
      lastName: "Nachname",
      role: "Rolle",
    },
  },
  contactPersons: {
    addNewContactPerson: "Weitere Ansprechperson hinzufügen",
    buttonLabel: "Ändern",
    title: "Ansprechpersonen für Kommunikation mit heo",
    contactPerson: "Ansprechperson {{contactPersonType}}",
    contactPersonType: {
      [ContactPersonContactTypeEnum.Contractual]: "Vertragliches",
      [ContactPersonContactTypeEnum.Delivery]: "Lieferung",
      [ContactPersonContactTypeEnum.Invoice]: "Rechnung",
      [ContactPersonContactTypeEnum.Other]: "Sonstiges",
    },
    modal: {
      validation: {
        firstName: "Bitte gib einen gültigen Vornamen ein",
        lastName: "Bitte gib einen gültigen Nachnamen ein",
        email: "Bitte gib eine gültige E-Mail-Adresse ein",
      },
      firstNameLabel: "Vorname",
      lastNameLabel: "Nachname",
      mailLabel: "E-Mail-Adresse",
      phoneLabel: "Telefon",
      mobileLabel: "Mobil",
      typeLabel: "Ansprechperson für Bereich",
      firstNamePlaceholder: "Vorname",
      lastNamePlaceholder: "Nachname",
      mailplaceholder: "nachname@unternehmen.de",
      phonePlaceholder: "+123",
      mobilePlaceholder: "+23",
      typePlaceholder: "Bitte Bereich auswählen",

      createContactPersonModal: {
        addNewContactPersonTitle: "Neue Ansprechperson anlegen",
        saveButtonLabel: "Ansprechperson anlegen",
        saved: "Ansprechperson wurde erfolgreich angelegt",
      },
      updateContactPersonModal: {
        updateContactPersonTitle: "Ansprechperson ändern",
        saveButtonLabel: "Ansprechperson ändern",
        saved: "Ansprechperson wurde erfolgreich geändert",
      },
      deleteContactPerson: {
        confirmTitle: "Ansprechperson löschen",
        confirmLabel: "Löschen",
        cancelLabel: "Abbrechen",
        success: "Die Ansprechperson {{name}} wurde erfolgreich gelöscht.",
        error: "Beim Löschen der Ansprechperson {{name}}  ist ein Fehler aufgetreten.",
        infotext: "Bist du sicher, dass du die Ansprechperson {{name}} wirklich löschen möchtest?",
      },
    },
  },
  billingDeliveryAddress: {
    title: "Aktive Rechnungs- & Lieferadresse",
    activeBillingAddressTitle: "Aktive Rechnungsadresse",
    activeDeliveryAddressTitle: "Aktive Lieferadresse",
    deliveryAddressTitle: "Lieferadresse",
    deliveryAddressesTitle: "Weitere Lieferadressen",
    errorLoadingBillingAddress: "Beim Laden der Rechnungsadresse ist ein Fehler aufgetreten.",
    errorLoadingDeliveryAddresses: "Beim Laden der Lieferadresse ist ein Fehler aufgetreten.",

    addAnotherAddressTitle: "Weitere Adresse anlegen",
    addNewInvoiceAddress: "Neue Rechnungsadresse beantragen",
    addNewDeliveryAddress: "Neue Lieferadresse beantragen",

    changeDefaultInvoiceAddress: "Als Rechnungsadresse beantragen",
    changeDefaultDeliveryAddress: "Als Standard-Lieferadresse beantragen",

    changeDefaultDeliveryAddressInfotext:
      "Bist du sicher, dass du die Adressvorlage {{address}} als neue Standard-Lieferadresse beantragen möchtest?",
    changeDefaultInvoiceAddressInfotext:
      "Damit du die gewählte Adresse als neue Rechnungsadresse verwenden kannst, benötigen wir eine Kopie des Gewerbescheins. Bitte füge den entsprechenden Nachweis diesem Änderungsantrag bei. Wir werden den Änderungsantrag anschließend umgehend prüfen.",
    buttonConfirmLabel: "Bestätigen",
    changeDefaultDeliveryAddressSuccessNotification:
      "Du hast die Adresse {{address}} als neue Standard-Lieferadresse beantragt. Die Änderung befindet sich in Prüfung.",
    changeDefaultInvoiceAddressSuccessNotification:
      "Du hast die Adresse {{address}} als neue Standard-Rechnungsaddresse beantragt. Die Änderung befindet sich in Prüfung.",
    changeDefaultInvoiceAddressErrorNotification:
      "Bei der Beantragung der Adresse {{address}} als neue Standard-Rechnungsadresse ist ein Fehler aufgetreten.",
    changeDefaultDeliveryAddressErrorNotification:
      "Bei der Beantragung der Adresse {{address}} als neue Standard-Lieferadresse ist ein Fehler aufgetreten.",
  },
  paymentAgreements: {
    activePaymentAgreements: "Aktive Zahlungsvereinbarung",
    paymentMethodTitle: "Zahlungsmethode",
    noDataText: "Es sind keine Zahlungsvereinbarungen vorhanden",
    bankAccount: {
      owner: "Kontoinhaber",
      bank: "Kreditinstitut",
      iban: "IBAN",
    },
    factoringLimit: {
      title: "Factoringlimit",
      activeSince: "Aktiv seit",
      limit: "Gewährtes Gesamtlimit",
      available: "Verfügbares Limit",
      used: "Genutztes Limit",
    },
    freightAllowance: {
      title: "Frachtfreigrenze",
      freightAllowance: "Gewährte Frachtfreigrenze",
      information: "Gültig für Lieferungen innerhalb Deutschlands.",
    },
    paymentDue: {
      title: "Zahlungskonditionen",
      paymentDueTarget: "Zahlungsziel",
      paymentDueTerm: "Zahlungsfrist",
      cashDiscountTerm: "Skontofrist",
      cashDiscount: "Skonto",
      days: "{{days}} Tage",
    },
    billingStop: {
      title: "Faktura-Stopp",
      error: "Beim Laden der Faktura-Stopp-Daten ist ein Fehler aufgetreten.",
      reason: {
        BILLING_STOP_OVERDUE_PAYMENT:
          "In deinem Konto wurde aufgrund einer überfälligen Zahlung ein Faktura-Stopp vermerkt. Bitte wende dich bei Fragen an den Customer-Service.",
        BILLING_STOP_PAYMENT_ADAPTION:
          "In deinem Konto wurde ein Faktura-Stopp vermerkt. Es ist eine Aktualisierung der Zahlungsmethode notwendig. Bitte wende dich bei Fragen an den Customer-Service.",
        BILLING_STOP_TEMPORARY_ABSENCE:
          "In deinem Konto wurde ein Faktura-Stopp bis zum {{ until }} vermerkt. Bitte wende dich bei Fragen an den Customer-Service.",
        BILLING_STOP_OTHERS:
          "In deinem Konto wurde ein Faktura-Stopp vermerkt. Bitte wende dich bei Fragen an den Customer-Service.",
        BILLING_STOP_UNKNOWN:
          "In deinem Konto wurde ein Faktura-Stopp vermerkt. Bitte wende dich bei Fragen an den Customer-Service.",
      },
    },
  },
  discountAgreements: {
    generallyDiscounts: "General-Rabatt",
    supplierDiscounts: "Lieferanten-Rabatte",
    customDiscounts: "Sonstige Rabatte",
    activeSince: "Aktiv seit",
    discount: "{{discountValue}} % Rabatt",
    discountWithName: "{{discountValue}} % {{discountName}} Rabatt",
    globalDiscount: "Genereller Rabatt auf alle rabattierfähigen Artikel des heo-Sortiments.",
    infoBox: {
      title: "Wichtige Hinweise zu Rabatten",
      description:
        "Einige Produkte in unserem Sortiment sind von einer Rabattierung ausgeschlossen oder können nur bis zu einer bestimmten Höhe rabattiert werden.<br /><br />Der jeweils gewährte Rabatt, der u.a. auch auf der Rechnung vermerkt wird, wird in allen Ansichten der Business Console entsprechend dargestellt.",
    },
    showMore: "Mehr anzeigen",
    showLess: "Weniger anzeigen",
  },
  mediaLinks: {
    websiteUrlLabel: "Webseite",
    shopUrlLabel: "Shop-URL",
    twitterUrlLabel: "Twitter",
    facebookUrlLabel: "Facebook",
    youtubeUrlLabel: "Youtube",
    instagramUrlLabel: "Instagram",
    twitchUrlLabel: "Twitch",
  },
};
