import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { ProductClaimWidgetDict } from "./mapProductClaimQueryDataToBarChart";

const ClaimBarChart: React.FC<ProductClaimWidgetDict> = (productClaimWidgetDict) => {
  const { t } = useTranslation();

  const categories = Object.values(productClaimWidgetDict).map((item) => item.id);
  const data = Object.values(productClaimWidgetDict).map((item) => item.value);

  return (
    <Chart
      type="bar"
      height="270"
      options={{
        chart: {
          id: "productClaims",
          toolbar: {
            show: false,
          },
        },

        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            barHeight: "80%",
            distributed: true,
          },
        },
        colors: [
          "hsl(208, 80%, 52%)",
          "hsl(27, 98%, 54%)",
          "hsl(210, 7%, 56%)",
          "hsl(230, 7%, 16%)",
        ],
        yaxis: {
          show: true,
          axisTicks: {
            show: false,
          },
          labels: {
            formatter(val) {
              return val.toFixed(0);
            },
          },
        },
        tooltip: {
          y: {
            formatter(val) {
              return val.toFixed(0);
            },
          },
        },

        stroke: {
          show: false,
        },

        legend: {
          position: "bottom",
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        xaxis: {
          categories: categories,
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          },
        },
        grid: {
          show: true,
        },
      }}
      series={[
        {
          name: t("dashboardPage.OpenClaimsWidget.claims"),
          data: data,
        },
      ]}
    />
  );
};

export { ClaimBarChart };
