import { Group, GroupProps } from "@mantine/core";
import React from "react";

import styles from "./OneLineCell.module.css";

export interface OneLineCellProps extends GroupProps {
  children: React.ReactNode;
}

const OneLineCell: React.FC<OneLineCellProps> = (props) => {
  const { children, ...groupProps } = props;

  return (
    <Group {...groupProps} className={styles.container}>
      {children}
    </Group>
  );
};

export { OneLineCell };
