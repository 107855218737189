export const SmileyMaeh = ({ size = 48, color = "currentColor", ...restProps }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>SmileyMaeh</title>
      <path
        d="M20 20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22C17.4696 22 16.9609 21.7893 16.5858 21.4142C16.2107 21.0391 16 20.5304 16 20C16 19.4696 16.2107 18.9609 16.5858 18.5858C16.9609 18.2107 17.4696 18 18 18C18.5304 18 19.0391 18.2107 19.4142 18.5858C19.7893 18.9609 20 19.4696 20 20ZM30 22C30.5304 22 31.0391 21.7893 31.4142 21.4142C31.7893 21.0391 32 20.5304 32 20C32 19.4696 31.7893 18.9609 31.4142 18.5858C31.0391 18.2107 30.5304 18 30 18C29.4696 18 28.9609 18.2107 28.5858 18.5858C28.2107 18.9609 28 19.4696 28 20C28 20.5304 28.2107 21.0391 28.5858 21.4142C28.9609 21.7893 29.4696 22 30 22Z"
        fill={color}
      />
      <path
        d="M16 33L20 33C20 31.9391 20.4214 30.9217 21.1716 30.1716C21.9217 29.4214 22.9391 29 24 29C25.0609 29 26.0783 29.4214 26.8284 30.1716C27.5786 30.9217 28 31.9391 28 33L32 33C32 30.8783 31.1571 28.8434 29.6569 27.3431C28.1566 25.8429 26.1217 25 24 25C21.8783 25 19.8434 25.8429 18.3431 27.3431C16.8429 28.8434 16 30.8783 16 33Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 24C44 35.046 35.046 44 24 44C12.954 44 4 35.046 4 24C4 12.954 12.954 4 24 4C35.046 4 44 12.954 44 24ZM40 24C40 28.2435 38.3143 32.3131 35.3137 35.3137C32.3131 38.3143 28.2435 40 24 40C19.7565 40 15.6869 38.3143 12.6863 35.3137C9.68571 32.3131 8 28.2435 8 24C8 19.7565 9.68571 15.6869 12.6863 12.6863C15.6869 9.68571 19.7565 8 24 8C28.2435 8 32.3131 9.68571 35.3137 12.6863C38.3143 15.6869 40 19.7565 40 24Z"
        fill={color}
      />
    </svg>
  );
};
