/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PaymentMethod = {
    DirectDebit: 'DIRECT_DEBIT',
    Prepayment: 'PREPAYMENT',
    Cash: 'CASH',
    Paypal: 'PAYPAL',
    Braintree: 'BRAINTREE',
    ShopifyPayment: 'SHOPIFY_PAYMENT',
    ShopifyPaymentPaypal: 'SHOPIFY_PAYMENT_PAYPAL',
    Other: 'OTHER',
    CashOnDelivery: 'CASH_ON_DELIVERY',
    Invoice: 'INVOICE',
    Cheque: 'CHEQUE',
    CreditCard: 'CREDIT_CARD',
    SepaDirectDebit: 'SEPA_DIRECT_DEBIT',
    Factoring: 'FACTORING'
} as const;
export type PaymentMethod = typeof PaymentMethod[keyof typeof PaymentMethod];


export function PaymentMethodFromJSON(json: any): PaymentMethod {
    return PaymentMethodFromJSONTyped(json, false);
}

export function PaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethod {
    return json as PaymentMethod;
}

export function PaymentMethodToJSON(value?: PaymentMethod | null): any {
    return value as any;
}

