import { Scrollbar, ScrollbarProps } from "@etecture/rex";
import "@etecture/rex/styles.css";
import React, { ReactNode } from "react";

export interface ScrollerProps extends ScrollbarProps {
  children: ReactNode;
}

export const Scroller: React.FC<ScrollerProps> = (props) => {
  const { children, ...scrollbarProps } = props;
  return <Scrollbar {...scrollbarProps}>{children}</Scrollbar>;
};
