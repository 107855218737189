import { useLocalStorage } from "@mantine/hooks";
import { hideNotification, showNotification } from "@mantine/notifications";
import { useEffect } from "react";
import { Trans } from "react-i18next";

import { useGetRetailerFactoringLimitQuery } from "~/api/hooks/retailer/useGetRetailerFactoringLimitQuery";
import { formatCurrency } from "~/helper/Formatter/formatCurrency";

const FACTORING_LIMIT_EXCEEDED_STORAGE_KEY = "FACTORING_LIMIT_EXCEEDED";

export const useFactoringLimitNotification = () => {
  const { data: factoringData, isError } = useGetRetailerFactoringLimitQuery();

  const [factoringLimitWasExceeded, setFactoringLimitWasExceeded] = useLocalStorage<boolean>({
    key: FACTORING_LIMIT_EXCEEDED_STORAGE_KEY,
    getInitialValueInEffect: false,
    defaultValue: false,
  });

  useEffect(() => {
    if (!factoringData || isError) return;

    const currentUsageExceedsLimit = factoringData.used > factoringData.limit;
    const usageSurplus = factoringData.used - factoringData.limit;

    // current usage exceeds limit and was not reported yet
    if (currentUsageExceedsLimit && !factoringLimitWasExceeded) {
      showNotification({
        id: "factoringLimitExceeded",
        message: (
          <Trans
            i18nKey={"factoringLimit.exceeded"}
            values={{
              limit: formatCurrency(factoringData.limit),
              surplus: formatCurrency(usageSurplus),
            }}
          />
        ),
        color: "red",
        autoClose: false,
      });
    }

    // current usage is below limit again and latest usage exceeded limit
    if (factoringLimitWasExceeded && !currentUsageExceedsLimit) {
      hideNotification("factoringLimitExceeded");
      showNotification({
        message: (
          <Trans
            i18nKey={"factoringLimit.cleared"}
            values={{
              limit: formatCurrency(factoringData.limit),
              usage: formatCurrency(factoringData.used),
            }}
          />
        ),
        color: "green",
        autoClose: 6000,
      });
    }

    setFactoringLimitWasExceeded(factoringData.used > factoringData.limit);
  }, [factoringData, factoringLimitWasExceeded, isError, setFactoringLimitWasExceeded]);
};
