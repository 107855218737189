/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Announcement
 */
export interface Announcement {
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    shortDescription: string;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    description: string;
    /**
     * 
     * @type {Date}
     * @memberof Announcement
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Announcement
     */
    displayStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof Announcement
     */
    displayEnd: Date;
}

/**
 * Check if a given object implements the Announcement interface.
 */
export function instanceOfAnnouncement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "shortDescription" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "displayStart" in value;
    isInstance = isInstance && "displayEnd" in value;

    return isInstance;
}

export function AnnouncementFromJSON(json: any): Announcement {
    return AnnouncementFromJSONTyped(json, false);
}

export function AnnouncementFromJSONTyped(json: any, ignoreDiscriminator: boolean): Announcement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'shortDescription': json['shortDescription'],
        'description': json['description'],
        'createdAt': (new Date(json['createdAt'])),
        'displayStart': (new Date(json['displayStart'])),
        'displayEnd': (new Date(json['displayEnd'])),
    };
}

export function AnnouncementToJSON(value?: Announcement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'shortDescription': value.shortDescription,
        'description': value.description,
        'createdAt': (value.createdAt.toISOString()),
        'displayStart': (value.displayStart.toISOString()),
        'displayEnd': (value.displayEnd.toISOString()),
    };
}

