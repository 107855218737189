export enum PeriodOption {
  SEVEN_DAYS = "7d",
  TWO_WEEKS = "14d",
  ONE_MONTH = "1m",
  THREE_MONTHS = "3m",
  SIX_MONTHS = "6m",
  TWELVE_MONTHS = "12m",
  START_OF_YEAR = "START_OF_YEAR",
  ALL = "ALL",
  OLDER_TWELVE_MONTH = "OLDER_TWELVE_MONTH",
  CUSTOM = "CUSTOM",
}
