import { Divider, Group } from "@mantine/core";
import { Feedback } from "~/ui/components/Feedback/Feedback";
import { HelpAndSupport } from "../HelpAndSupport/HelpAndSupport";

import { ToShopActionButton } from "./ToShopActionButton";

type ActionBarProps = {
  hideFeedbackButton?: boolean;
};

const ActionBar: React.FC<ActionBarProps> = (props) => {
  const { hideFeedbackButton } = props;

  const showFeedbackButton = !hideFeedbackButton;
  return (
    <>
      <Group wrap="nowrap" gap={"md"}>
        <HelpAndSupport />
        {showFeedbackButton && <Feedback />}
        <ToShopActionButton />
      </Group>
      <Divider orientation="vertical" size="xs" />
    </>
  );
};

export { ActionBar };
