import { Anchor, Blockquote, Code } from "@mantine/core";
import DomPurify from "dompurify";
import HtmlParser, { Element, HTMLReactParserOptions, domToReact } from "html-react-parser";
import React from "react";

export interface HtmlRendererProps {
  /**
   * The raw html string content
   */
  children: string;

  /**
   * The allowed html tags
   * @default ["br", "a", "b", "strong"]
   */
  tags?: string[];

  /**
   * The allowed html attributes
   * @default ["href", "target"]
   */
  attributes?: string[];
}

/**
 * Takes a raw html string, purifies tags, fixes errors and removes potential harmful markup
 */
const HtmlRenderer: React.FC<HtmlRendererProps> = (props) => {
  const { children, tags = ["br", "a", "b", "strong"], attributes = ["href", "target"] } = props;

  const purifiedContent = DomPurify.sanitize(children, {
    ALLOWED_TAGS: tags,
    ALLOWED_ATTR: attributes,
  });

  const options: HTMLReactParserOptions = {
    replace: (node) => {
      if (node instanceof Element && node.name === "a") {
        return <Anchor {...node.attribs}>{domToReact(node.children, options)}</Anchor>;
      }

      if (node instanceof Element && node.name === "code") {
        return (
          <Code block {...node.attribs}>
            {domToReact(node.children, options)}
          </Code>
        );
      }

      if (node instanceof Element && node.name === "blockquote") {
        return <Blockquote {...node.attribs}>{domToReact(node.children, options)}</Blockquote>;
      }
    },
  };

  return <>{HtmlParser(purifiedContent, options)}</>;
};

export { HtmlRenderer };
