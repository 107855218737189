import { Box, Group, Text, Tooltip } from "@mantine/core";
import { IconChevronsLeft } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import styles from "./ExtendedNavbarButton.module.css";

type ExtendedNavbarButtonProps = {
  isNavbarExtended: boolean;
  handleExtendNavbarClick: () => void;
};

const ExtendedNavbarButton = (props: ExtendedNavbarButtonProps) => {
  const { t } = useTranslation();
  const { isNavbarExtended, handleExtendNavbarClick } = props;

  return (
    <Tooltip disabled={isNavbarExtended} label={t("navBar.expandNavBar")} withinPortal>
      <Group onClick={handleExtendNavbarClick} className={styles.expandGroup}>
        <Box className={isNavbarExtended ? styles.icon : styles.iconExtended}>
          <IconChevronsLeft size={24} />
        </Box>
        {isNavbarExtended && <Text>{t("navBar.collapseNavBar")}</Text>}
      </Group>
    </Tooltip>
  );
};
export { ExtendedNavbarButton };
