import { LoginPageDE } from "../de/de.loginPage";

export const LoginPageEN: typeof LoginPageDE = {
  emailPlaceholder: "Email",
  passwordPlaceholder: "Password",
  staySignedInLabel: "Stay signed in",
  forgotPassword: "Forgot your password?",
  loginButton: "Log in",
  invalidCredentialsError: "The username and/or password you entered is invalid",
  footer: {
    noCustomerYet: "Not a customer yet?",
    retailRegisterLink: "Click here to go to retailer registration",
  },
};
