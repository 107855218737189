import {
  StoreOverviewTypeEnum,
  StoreProfileNewRequestOverviewApprovalStatusEnum,
} from "~/api/openapi";
import { ProfileStatusEnum } from "~/ui/pages/StoreProfilePage/StoreProfileForm/StoreProfileFormProgressPanel/StoreProfileStatusInformation/StoreProfileStatusInformation";
import { VisibilityEnum } from "~/ui/pages/StoreProfilePage/StoreProfileForm/StoreProfileFormProgressPanel/StoreProfileStatusInformation/getVisibility";

import { StoreProfilePageDE } from "../de/de.storeProfilePage";

export const StoreProfilePageEN: typeof StoreProfilePageDE = {
  overViewPage: {
    title: "My Store Profiles",

    noData: {
      title: "Let yourself be found! The store locator puts you in the right light!",
      subtitle:
        "To ensure that end customers who visit <strong>heo.com</strong> every day don't leave the website disappointed, we want to guide them directly to your shop or store.",
      button: "Create a store profile now",
    },
    overview: {
      confirmedProfileTitle: "Approved store profiles",
      noConfirmedProfileAvailable: "There are no profiles available for you yet.",
      unconfirmedProfileTitle: "Unconfirmed store profiles",
      noUnconfirmedProfileAvailable: "There are no unconfirmed profiles available.",
      createButton: "Create new store profile",
      showDetailsButton: "Show details",
      continueProgressButton: "Continue editing",
      deleteStoreProfileTooltip: "Delete store profile",
      deleteDraftTooltip: "Delete draft",
      activateProfileTooltip: "Activate store profile",
      deactivateStoreProfileTooltip: "Deactivate store profile",
      showPublishedVersionTooltip: "Show published store profile",
      showDeactivatedVersionTooltip: "Show released (unpublished) store profile",
      storeType: {
        [StoreOverviewTypeEnum.Local]: "Local shop",
        [StoreOverviewTypeEnum.Online]: "Online store",
      } as Record<StoreOverviewTypeEnum, string>,
      location: "{{street}} {{houseNumber}}, {{zip}} {{city}}",
      inReviewInfoText:
        "We will review your new store profile for you. As soon as it is approved, it will be published on heo.com. You can <strong>activate</strong> or <strong>deactivate</strong> the visibility of your profile at any time (even before approval).",
      profileApprovalStatus: {
        [StoreProfileNewRequestOverviewApprovalStatusEnum.Approved]: "Approved",
        [StoreProfileNewRequestOverviewApprovalStatusEnum.Rejected]: "New version rejected",
        [StoreProfileNewRequestOverviewApprovalStatusEnum.InReview]: "New version under review",
      } as Record<StoreProfileNewRequestOverviewApprovalStatusEnum, string>,
      newRequestApprovalStatus: {
        [StoreProfileNewRequestOverviewApprovalStatusEnum.Approved]: "Approved",
        [StoreProfileNewRequestOverviewApprovalStatusEnum.Rejected]: "Rejected",
        [StoreProfileNewRequestOverviewApprovalStatusEnum.InReview]: "Under review",
      } as Record<StoreProfileNewRequestOverviewApprovalStatusEnum, string>,
      draft: {
        status: "Draft",
        noContactData: "No data",
      },
    },
  },

  confirmationModals: {
    deleteModal: {
      title: {
        draft: "Delete store profile draft",
        profil: "Delete store profile",
      },
      confirmationText: {
        draft:
          "Do you really want to delete the store profile draft for <strong>{{storeName}}</strong>?",
        profile: "Do you really want to delete the store profile <strong>{{storeName}}</strong>?",
      },

      informationText: {
        withChangeRequest:
          "Both the approved or published and the new store profile version will be deleted.",
        withoutChangeRequest:
          "The store profile already published on heo.com will also be removed. ",
        draft: "Any information provided so far will be irreversibly lost.",
      },
      cancelButton: "Cancel",
      submitButton: "Delete store profile",
    },
    publishModal: {
      title: {
        activate: "Activate store profile",
        deactivate: "Deactivate store profile",
      },
      confirmationText: {
        deactivate:
          "Do you really want to deactivate the store profile <strong>{{storeName}}</strong>?",
        activate:
          "Do you really want to activate the store profile <strong>{{storeName}}</strong>?",
      },

      informationText: {
        profile: {
          deactivate:
            "The last approved store profile version will no longer be displayed on heo.com for end customers after deactivation. You can reactivate the store profile at any time.",
          activate:
            "The last store profile version approved by heo is automatically published after activation.",
        },
        changeRequest: {
          deactivate:
            "By deactivating, the store profile is no longer automatically published on heo.com for end customers after it has been approved. You can reactivate the store profile at any time.",
          activate:
            "This will automatically publish the store profile on heo.com as soon as it has been approved.",
        },
      },
      success: {
        activate:
          "Your store profile <strong>{{storeName}}</strong> has been successfully activated.",
        deactivate:
          "Your store profile <strong>{{storeName}}</strong> has been successfully deactivated and will no longer be displayed on heo.com.",
      },

      cancelButton: "Cancel",
      submitButton: {
        activate: "Activate store profile",
        deactivate: "Deactivate store profile",
      },
    },

    deleteChangeRequestModal: {
      title: "Discard new version?",
      confirmationText:
        "Do you really want to discard the new version and revert to the last approved or currently published version?",

      error: {
        title: "The new version could not be discarded.",
        message: "There was an error discarding the new version. Please try again.",
      },
      success: {
        message: "Your newly submitted store profile version was successfully discarded.",
      },

      cancelButton: "No",
      submitButton: "Yes, discard new version",
    },
  },

  form: {
    newProfilePageTitle: "Create new store profile",
    existiingProfilePageTitle: "Store profile: {{storeName}}",
    backToOverviewButton: "Back to the store profile overview",
    createOrUpdateSuccessMessage: {
      draft: {
        title: "Store profile draft successfully saved.",
        message: "Your store profile draft has been saved successfully.",
      },
      profile: {
        title: "Store profile successfully saved.",
        message: "Your store profile has been saved successfully.",
      },
    },

    preview: {
      tooltip: "Specify minimum data for preview",
      showPreviewButton: "Show preview",
    },

    logo: {
      approvalRequiredInfoText:
        "Please note: Uploading a new store logo requires a new approval by heo. A previously activated store profile remains visible until the revision is approved.",
      modalTitle: "Your store logo",
      deleteModalTitle: "Do you really want to delete the store logo?",
      deleteModalDescription: "Do you really want to delete your store logo?",
      deleteCancel: "Cancel",
      deleteConfirm: "Delete logo",
    },

    banner: {
      approvalRequiredInfoText:
        "Please note: Uploading a new store banner requires a new approval by heo. A previously activated store profile remains visible until the revision is approved.",
      modalTitle: "Your store banner",
      deleteModalTitle: "Do you really want to delete the store banner?",
      deleteModalDescription:
        "Do you really want to delete your store banner and revert to the default background?",
      deleteCancel: "Cancel",
      deleteConfirm: "Delete banner",
    },

    imageUploader: {
      cancelLabel: "Cancel",
      submitLabel: "Crop & upload",
      uploadError: "The image could not be uploaded.",
    },

    basicInformation: {
      approvalRequiredInfoText:
        "Please note: Changes to free text fields require a new approval by heo. A previously published store profile remains visible until the changes are approved.",
      introText:
        "Present your store! Add the name of your shop, the date it was founded, a short description and other details to give customers an initial insight into what makes your store special.",
      openModalButtonLabel: "Add basic information",
      modalTitle: { add: "Add basic information", edit: "Change basic information" },
      storeNameTitel: "Which store name should be displayed to users when searching?",
      storeNameLabel: "Store name",
      storeTypeDescription: "Should the store profile represent a local shop or an online store? ",
      storeTypeAdditionalInfo:
        "(Creating more store profiles for additional locations or online stores are possible.)",

      localStoreLabel: "Local shop",
      onlineStoreLabel: "Online store",
      openingDateLabel: "When was your store founded? (Optional)",
      openingDateMonthLabel: "Month",
      openingDateYearLabel: "Year",
      descriptionLabel: "What makes your store special? (Optional)",
      descriptionPlaceholder:
        "Please describe your store for your customers with a maximum of 2000 characters.",
      wpnStoreLabel: "Are you an official WPN store?",
      wpnStoreTooltip:
        "The Wizards Play Network is a community of over 6,000 game stores where Magic players meet to play their favourite game together.",
      wpnStoreYes: "Yes",
      wpnStoreNo: "No",
      otsStoreLabel: "Are you an official OTS store?",
      otsStoreTooltip:
        "Official Tournament Stores are tournament shops that host events for the Yu-Gi-Oh! TCG community.",
      otsStoreYes: "Yes",
      otsStoreNo: "No",
      cancelButtonLabel: "Cancel",
      submitButtonLabel: "Apply",
      localStoreBadgeLabel: "Local shop",
      onlineStoreBadgeLabel: "Online store",
      openingDateBadge: "Founded: {{date}}",
      wpnStoreBadge: "WPN store",
      otsStoreBadge: "OTS store",
      descriptionUsageHint: "{{ count }} of maximum {{ max }} characters",
      showMoreDescriptionLabel: "Show more",
      showLessDescriptionLabel: "Show less",
      descriptionMaxLengthExceeded:
        "The store description may be a maximum of {{ length }} characters.",
    },

    contact: {
      approvalRequiredInfoText:
        "Please note: Changes to free text fields require a new approval by heo. A previously published store profile remains visible until the changes are approved.",
      sectionTitle: "Contact details",
      introText: "Share your contact details so that customers can find or reach you.",
      openModalButtonLabel: "Add contact details",
      modalTitle: { add: "Add contact details", edit: "Change contact details" },
      addressTitle: "What is the address of your store?",
      streetLabel: "Street",
      streetPlaceholder: "Street",
      houseNumberLabel: "House number",
      houseNumberPlaceholder: "HN",
      additionalAddressLabel: "Additional address",
      additionalAddressPlaceholder: "e.g. left entrance",
      zipCodeLabel: "Postal code",
      zipCodePlaceholder: "Postal code",
      cityLabel: "City",
      cityPlaceholder: "City",
      countryLabel: "Country",
      countryPlaceholder: "Please select",
      phoneNumerTitle: "Under which phone number can your customers reach you?",
      phoneNumberLabel: "Phone number",
      phoneNumberPlaceholder: "Enter phone number",
      mailTitle: "Which email address can your customers use to contact you?",
      mailLabel: "Email address",
      mailPlaceholder: "Enter email address",
      websiteTitle: "Which website can your customers use to find you?",
      websiteLabel: "Website",
      websitePlaceholder: "Enter website",
      cancelButtonLabel: "Cancel",
      submitButtonLabel: "Apply",
      formInvalidText: "By changing the store type, your contact details are no longer complete.",
      placeIdLabel: "Google place ID",
      placeIdPlaceholder: "Select your store",
      placeIdPreviewLabel: "Google place ID preview",
      addAddress: "Add address (optional)",
      removeAddress: "Remove address",
    },

    openingHours: {
      sectionTitle: "Opening hours",
      introText: "Indicate when your store is open so customers know when they can visit you.",
      openModalButtonLabel: "Add opening hours",
      modalTitle: "Opening hours",
      openHoursDescription: "On which days and at what time is your store open? ",
      weekdays: {
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday and public holiday",
      },
      isOpenCheckedLabel: "Open",
      isOpenUncheckedLabel: "Closed",
      weekdayClosed: "Closed",
      cancelButtonLabel: "Cancel",
      submitButtonLabel: "Apply",
      genericErrorText:
        "On open days, both an opening time and a closing time must be specified. The opening time must be before the closing time.",
    },
    productCategory: {
      sectionTitle: "Product range",
      modalTitle: "Select product categories",
      modalDescription: "Which product categories do you have in your range?",
      introText:
        "Inform your customers about the product range you offer. Select the product categories that best suit your store.",
      openModalButtonLabel: "Select product categories",
      cancelButtonLabel: "Cancel",
      submitButtonLabel: "Apply",
    },
    paymentOptions: {
      sectionTitle: "Payment options",
      modalTitle: "Select payment options",
      modalDescription: "Which payment options are available to your customers?",
      introText:
        "Show your customers how they can pay with you - add your accepted payment options.",
      openModalButtonLabel: "Add payment options",
      cancelButtonLabel: "Cancel",
      submitButtonLabel: "Accept",
    },

    storeImages: {
      approvalRequiredInfoText:
        "Please note: Uploading store images requires a new approval by heo. A previously activated store profile remains visible until the revision is approved.",
      sectionTitle: "Store images",
      introText:
        "Upload photos of your store to give customers a real impression of what you have to offer.",
      deleteModalTitle: "Really delete the image?",
      deleteModalConfirmLabel: "Delete image",
      deleteModalCancelLabel: "Cancel",
      uploadError: "The image could not be uploaded.",
    },

    accessibility: {
      sectionTitle: "Store accessibility",
      modalTitle: "Select store accessibility",
      modalDescription: "What accessibility requirements does your store fulfil?",
      introText:
        "Provide information about the accessibility of your store to welcome all customers.",
      openModalButtonLabel: "Select store accessibility",
      cancelButtonLabel: "Cancel",
      submitButtonLabel: "Apply",
    },
    activities: {
      sectionTitle: "Store activities",
      modalTitle: "Select store activities",
      modalDescription: "What activities are taking place in your store?",
      introText: "Tell your customers what activities or events are taking place in your store.",
      openModalButtonLabel: "Select store activities",
      cancelButtonLabel: "Cancel",
      submitButtonLabel: "Apply",
    },
    equipment: {
      sectionTitle: "Store equipment",
      modalTitle: "Select store equipment",
      modalDescription: "Which equipment features can be found in your store?",
      introText: "Stand out with your store features! Choose from several equipment options.",
      openModalButtonLabel: "Select store equipment",
      cancelButtonLabel: "Cancel",
      submitButtonLabel: "Apply",
    },
    progressPanel: {
      statusBox: {
        title: "Store profile information",
        createdAt: "Created on: <strong>{{date}}</strong>",
        updatedAt: "Last modified: <strong>{{date}}</strong>",
        statusLabel: "Status: <strong>{{status}}</strong>",
        visibilityLabel: "Visibility: <strong>{{visibility}}</strong>",
        deactivateButton: "Deactivate store profile",
        activateButton: "Activate store profile",
        showPublishedVersionLinkLabel: "Show published version",
        showReleasedVersionLinkLabel: "Show approved version",
        discardVersionButton: "Discard new version",
        status: {
          newRequest: "Initial request under review",
          newRequestRejected: "Rejected",
          approved: "Approved",
          changeRequest: "New version under review",
          changeRequestRejected: "New version rejected",
        } as Record<ProfileStatusEnum, string>,
        visibility: {
          published: "Published",
          notPublished: "Deactivated",
          notYetPublished: "Not yet published",
        } as Record<VisibilityEnum, string>,
      },
      newRequest: {
        title: "Your store profile in 5 minutes",
        description:
          "Great that you want to present <strong>your store to end customers on heo.com</strong>! <br><br> In order to approve and publish a store profile after a legal check, we still need some information from you.",
      },
      editRequest: {
        title: "Improve your store profile",
        description: "Add more details to your store profile and impress your customers.",
      },

      progressBar: {
        title: "Current progress",
        stepOfSteps: "{{step}}/{{maxSteps}}",
        infoBox: {
          mandatory: {
            title: "Required minimum information",
            basicInformation: "Basic information",
            contact: "Contact details",
            productCategories: "Product range",
            allDone: {
              title: "Store profile is ready to be published:",
              description: "You have filled in all the necessary information.",
            },
          },

          optional: {
            infoText: "Add more details to your store profile and impress your customers.",
            allDone: "Great! Your store profile is complete.",
          },
        },
      },
      legal: {
        title: "Legal information",
        description:
          "Please read our terms and conditions, copyright & legal disclaimer and privacy policy carefully and confirm that you accept them:",
        termsAndConditions:
          "I hereby confirm that I have read and accepted the <0>Terms and conditions</0> and the <1>Copyright & legal disclaimer</1>.",
        privacy: "I have taken note of the <0>Privacy Policy</0>.",
      },
      submitButtonNew: "Apply for a store profile",
      submitButtonEdit: "Request changes",
      draftButton: "Save as draft",
    },

    errors: {
      title: {
        default: "Sorry, your store profile could not be saved.",
        changeRequestNotDeletable: "The request could not be cancelled.",
      },
      message: {
        duplicate: "You have already created a store profile with the same address.",
        staleProfile: "The request has already been accepted and can no longer be processed.",
        staleChangeRequest:
          "The request has already been accepeted and can no longer be processed.",
        profileNotFound: "The store profile no longer exists.",
        changeRequestNotFound: "The request no longer exists.",
        changeRequestNotDeletable: "The request has already been approved.",
        default: "Please try again later or check your entries for errors",
      },
    },
  },
};
