/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TotalSales } from './TotalSales';
import {
    TotalSalesFromJSON,
    TotalSalesFromJSONTyped,
    TotalSalesToJSON,
} from './TotalSales';
import type { TotalSalesSummary } from './TotalSalesSummary';
import {
    TotalSalesSummaryFromJSON,
    TotalSalesSummaryFromJSONTyped,
    TotalSalesSummaryToJSON,
} from './TotalSalesSummary';

/**
 * 
 * @export
 * @interface TotalSalesReport
 */
export interface TotalSalesReport {
    /**
     * 
     * @type {Array<TotalSales>}
     * @memberof TotalSalesReport
     */
    years: Array<TotalSales>;
    /**
     * 
     * @type {TotalSalesSummary}
     * @memberof TotalSalesReport
     */
    total: TotalSalesSummary;
}

/**
 * Check if a given object implements the TotalSalesReport interface.
 */
export function instanceOfTotalSalesReport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "years" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function TotalSalesReportFromJSON(json: any): TotalSalesReport {
    return TotalSalesReportFromJSONTyped(json, false);
}

export function TotalSalesReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): TotalSalesReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'years': ((json['years'] as Array<any>).map(TotalSalesFromJSON)),
        'total': TotalSalesSummaryFromJSON(json['total']),
    };
}

export function TotalSalesReportToJSON(value?: TotalSalesReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'years': ((value.years as Array<any>).map(TotalSalesToJSON)),
        'total': TotalSalesSummaryToJSON(value.total),
    };
}

