/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PublicationUrl } from './PublicationUrl';
import {
    PublicationUrlFromJSON,
    PublicationUrlFromJSONTyped,
    PublicationUrlToJSON,
} from './PublicationUrl';

/**
 * 
 * @export
 * @interface RetailerApiAccess
 */
export interface RetailerApiAccess {
    /**
     * 
     * @type {Set<PublicationUrl>}
     * @memberof RetailerApiAccess
     */
    publicationUrls: Set<PublicationUrl>;
    /**
     * 
     * @type {string}
     * @memberof RetailerApiAccess
     */
    status: RetailerApiAccessStatusEnum;
}


/**
 * @export
 */
export const RetailerApiAccessStatusEnum = {
    Active: 'ACTIVE',
    Disabled: 'DISABLED'
} as const;
export type RetailerApiAccessStatusEnum = typeof RetailerApiAccessStatusEnum[keyof typeof RetailerApiAccessStatusEnum];


/**
 * Check if a given object implements the RetailerApiAccess interface.
 */
export function instanceOfRetailerApiAccess(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "publicationUrls" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function RetailerApiAccessFromJSON(json: any): RetailerApiAccess {
    return RetailerApiAccessFromJSONTyped(json, false);
}

export function RetailerApiAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetailerApiAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'publicationUrls': (new Set((json['publicationUrls'] as Array<any>).map(PublicationUrlFromJSON))),
        'status': json['status'],
    };
}

export function RetailerApiAccessToJSON(value?: RetailerApiAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'publicationUrls': (Array.from(value.publicationUrls as Set<any>).map(PublicationUrlToJSON)),
        'status': value.status,
    };
}

