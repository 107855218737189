import { PaymentMethod } from "~/api/openapi";

import { CreditNotesPageDE } from "../de/de.creditNotesPage";

export const CreditNotesPageEN: typeof CreditNotesPageDE = {
  pageTitle: "My credit notes",
  title: "My credit notes ({{ count }})",
  creditNoteSummaryRefundMethod: {
    PREPAYMENT: "Refund",
    PAYPAL: "Repayment",
    SEPA_DIRECT_DEBIT: "Repayment",
    FACTORING: "Offsetting",
    BRAINTREE: "Repayment",
    CREDIT_CARD: "Repayment",
    DIRECT_DEBIT: "Offsetting",
    INVOICE: "Repayment",
    CASH: "Cash payment",
    OTHER: "Other",
  } as Record<PaymentMethod, string>,

  refundMethodInfoText: {
    PREPAYMENT: "The amount will be transferred to your account.",
    PAYPAL: "The amount will be transferred to your PayPal account.",
    SEPA_DIRECT_DEBIT: "The amount will be included in the next direct debit.",
    FACTORING: "Please offset with the next payment.",
    BRAINTREE: "The amount will be transferred to your credit card account.",
    CREDIT_CARD: "The amount will be credited to your credit card.",
    DIRECT_DEBIT: "Please offset with the next payment.",
    INVOICE: "The amount will be transferred to your bank account.",
    CASH: "The amount will be paid out in cash.",
    OTHER: "Other",
  } as Record<PaymentMethod, string>,

  filter: {
    title: "Filter & Sort",
    createdAt: "Created at",
    searchPlaceholder: "Search credit notes (min. 3 characters)",
  },

  sort: {
    creditCreatedAt: "Created at",
    creditNumber: "Credit note",
    amount: "Amount",
    refundMethod: "Refund method",
    description: "Description",
  },

  card: {
    amount: "Amount",
    refundMethod: "Refund method",
    description: "Description",
    showCreditNotesButton: "Show credit note {{creditNoteNumber}}",
  },

  pdfDownload: {
    notFound: "No document exists for the credit note {{ creditNoteNumber }}.",
  },

  table: {
    noCreditsAvailable: "No credits available.",
    noCreditsFound: "No credits found that match your search criteria.",
    header: {
      creditCreatedAt: "Date",
      creditNumber: "Credit note",
      amount: "Amount",
      refundMethod: "Refund method",
      description: "Description",
    },
    body: {
      refundMethodInfoText: {
        paypal: "Please offset with the next payment.",
      },
    },
  },
};
