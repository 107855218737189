/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChangeRequestGeoPositionRequest } from './ChangeRequestGeoPositionRequest';
import {
    ChangeRequestGeoPositionRequestFromJSON,
    ChangeRequestGeoPositionRequestFromJSONTyped,
    ChangeRequestGeoPositionRequestToJSON,
} from './ChangeRequestGeoPositionRequest';

/**
 * 
 * @export
 * @interface ChangeRequestAddressRequest
 */
export interface ChangeRequestAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestAddressRequest
     */
    additional?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestAddressRequest
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestAddressRequest
     */
    countryCode?: string;
    /**
     * 
     * @type {ChangeRequestGeoPositionRequest}
     * @memberof ChangeRequestAddressRequest
     */
    geoPosition?: ChangeRequestGeoPositionRequest;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestAddressRequest
     */
    googlePlaceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestAddressRequest
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestAddressRequest
     */
    streetNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestAddressRequest
     */
    zip?: string;
}

/**
 * Check if a given object implements the ChangeRequestAddressRequest interface.
 */
export function instanceOfChangeRequestAddressRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeRequestAddressRequestFromJSON(json: any): ChangeRequestAddressRequest {
    return ChangeRequestAddressRequestFromJSONTyped(json, false);
}

export function ChangeRequestAddressRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestAddressRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'additional': !exists(json, 'additional') ? undefined : json['additional'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'geoPosition': !exists(json, 'geoPosition') ? undefined : ChangeRequestGeoPositionRequestFromJSON(json['geoPosition']),
        'googlePlaceId': !exists(json, 'googlePlaceId') ? undefined : json['googlePlaceId'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'streetNumber': !exists(json, 'streetNumber') ? undefined : json['streetNumber'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
    };
}

export function ChangeRequestAddressRequestToJSON(value?: ChangeRequestAddressRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'additional': value.additional,
        'city': value.city,
        'countryCode': value.countryCode,
        'geoPosition': ChangeRequestGeoPositionRequestToJSON(value.geoPosition),
        'googlePlaceId': value.googlePlaceId,
        'street': value.street,
        'streetNumber': value.streetNumber,
        'zip': value.zip,
    };
}

