import { ResponseError } from "./openapi";

export type ResponseInfo = {
  status: number;
};

/**
 * Takes an error and extracts response information if available
 * Returns null otherwise
 */
export const composeResponseError = (error: unknown): Response | null => {
  // request was made with rest endpoint fetch
  if (error instanceof ResponseError) {
    return error.response;
  }

  return null;
};
