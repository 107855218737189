import { PaymentType } from "~/api/openapi";

export const InvoicesPageDE = {
  filter: {
    title: "Filtern & Sortieren",
    statusTiles: {
      overdueOfWhich: "davon",
      total: "Gesamt",
    },
    searchPlaceholder: "Rechnungen durchsuchen (min. 3 Zeichen)",
    statusFilterPlaceholder: "Nach Status filtern",
    invoiceCreatedAt: "Rechnungsdatum",
    status: "Status",
  },
  pageTitle: "Meine Rechnungen",
  title: "Meine Rechnungen ({{ count }})",

  piece: "Stück",

  pdfDownload: {
    notFound: "Für die Rechnung {{ invoiceNumber }} existiert kein Dokument.",
  },

  sort: {
    invoiceDate: "Erstellt am",
    invoiceNumber: "Rechnung",
    totalAmount: "Betrag",
    paymentMethod: "Zahlungsmethode",
    paymentStatus: "Zahlungsstatus",
    shippingStatus: "Versandstatus",
    shippingAddress: "Lieferadresse",
    reference: "Referenz",
  },
  card: {
    totalAmount: "Betrag",
    paymentMethod: "Zahlungsmethode",
    paymentStatus: "Zahlungsstatus",
    shippingStatus: "Versandstatus",
    reference: "Rechnungsreferenz",
    showInvoiceButton: "Rechnung {{invoiceNumber}} anzeigen",
    shippingAddress: "Lieferadresse",
  },
  table: {
    head: {
      date: "Datum",
      number: "Rechnung",
      amount: "Betrag",
      paymentMethod: "Zahlungsmethode",
      paymentStatus: "Zahlungsstatus",
      shippingStatus: "Versandstatus",
      shippingAddress: "Lieferadresse",
      reference: "Referenz",
    },
    body: {
      noInvoicesAvailable: "Noch keine Rechnungen vorhanden.",
      noInvoicesFound: "Keine Rechnungen gefunden, die deinen Suchkriterien entsprechen.",
      noPaymentMethod: "Unbekannt",
      paidAt: "am {{ date }}",
      canceled: "Storniert",
      type: {
        [PaymentType.Prepayment]: "Zahlungsfrist {{ date }}",
        [PaymentType.Factoring]: "Zahlungsziel {{ date }}",
        [PaymentType.Other]: "{{ date }}",
      } as Record<PaymentType, string>,
      cancellationReasons: {
        BY_HEO: "durch heo",
        BY_CUSTOMER: "durch Kunde",
        null: "---",
      } as Record<string, string>,
      shippingStatusInProgress: "In Bearbeitung",
      shippingStatusShipped: "Versandt",
      shippingStatusTrackingHint_other: "{{ count }} Pakete",
      shippingStatusTrackingHint_one: "{{ count }} Paket",
    },
  },

  csv: {
    downloadFileName: "heo-csv-export-invoices_{{date}}",
    tooltipDownloadButton: "CSV-Export (Daten aus Suchergebnis)",
    invoiceDate: "Rechnungsdatum",
    invoiceNumber: "Rechnungsnummer",
    paymentDueDate: "Zahlungsziel",
    paymentStatus: "Zahlungsstatus",
    paymentDate: "bezahlt am",
    paymentMethod: "Zahlungsmethode",
    shippingAddress: "Lieferadresse",
    productNumber: "Artikelnummer",
    barcode: "Barcode",
    productName: "Artikelname",
    productManufacturers: "Hersteller",
    originCountry: "Herkunftsland",
    tariffNumber: "HS-Code",
    quantity: "Menge",
    pricePerUnit: "Einzelpreis",
    discount: "Rabatt",
    discountedPricePerUnit: "Endpreis (inkl. Rabatt)",
    postionPrice: "Gesamtpreis",
    reference: "Rechnungsreferenz",
    customerReference: "Bestellreferenz",
    customerItemReference: "Artikelreferenz",
    manufacturer: "Hersteller",
    shippingStatus: "Versandstatus",
  },

  detail: {
    invoiceNumber: "Rechnung: {{ invoiceNumber }}",
    baseInformation: "Basisinformationen",
    paymentStatus: "Zahlungsstatus",
    paymentDueDatePrepayment: "Zahlungsfrist",
    paymentDueDate: "Zahlungsziel",
    canceled: "Storniert",
    canceledReason: "Stornierungsgrund",
    paymentMethod: "Zahlungsmethode",
    paymentDate: "Zahlungseingang",
    totalAmount: "Gesamtbetrag",
    undiscountedNetAmount: "Zwischensumme",
    discountedNetAmount: "Summe",
    freightCharges: "zzgl. Versandkosten",
    freightSurcharge: "inkl. Frachtkostenzuschlag",
    grantedDiscount: "abzgl. Rabatt",
    grantedDiscountPercentage: "({{percentage}}%)",
    addedTax: "zzgl. MwSt. {{taxRate}}%",
    reference: "Referenz",
    invoiceItemCount: "Rechnungspositionen ({{count}})",
    payNow: "Jetzt bezahlen",
    missingPackage: {
      reportPackageButton: "Fehlendes Paket melden",
      reportPackageButtonTooltip:
        "Ein nicht erhaltenes Paket melden, das bereits hätte zugestellt sein sollen",
      modal: {
        title: "Fehlendes Paket melden",
        closeButton: "Schließen",
        sendButton: "Abschicken",

        infoContent: {
          delayToReport:
            "Fehlende Pakete können frühestens 5 Tage nach Rechnungsstellung gemeldet werden.",
          todoIntro:
            "Wenn du ein Paket noch nicht erhalten hast, obwohl es bereits hätte zugestellt sein sollen, kannst du Folgendes vorab tun:",
          listEntries: {
            1: "Überprüfe, ob alle Sendungsnummern den Status “Zugestellt” anzeigen.",
            2: "Überprüfe die Lieferadresse, die innerhalb der Rechnung angegeben ist.",
            3: "Kontrolliere, ob eine Nachricht über einen Zustellversuch hinterlassen wurde.",
            4: "Bringe in Erfahrung, ob das Paket von jemand anderen angenommen wurde.",
            5: "Nimm Kontakt mit dem entsprechenden Versanddienstleister auf und kläre den Verbleib eines als zugestellt angezeigten Pakets ab. Halte dabei die Sendungsnummer oder die Lieferungsbestätigung bereit.",
          } as Record<string, string>,
          infoDescription: "Das Paket ist trotzdem nicht auffindbar?",
        },
        reportContent: {
          description:
            "Es tut uns leid, dass Du nicht alle Pakete der <strong>Rechnung {{invoiceNumber}}</strong> erhalten hast. Bitte fülle die folgenden Felder aus, um uns über die fehlenden Pakete zu informieren. Wir werden uns so schnell wie möglich mit Dir in Verbindung setzen, um das Problem zu lösen.",
          selectTrackingIdInfo_other:
            "Bitte wähle die Sendungsnummern aus, für die du keine Pakete erhalten hast:",
          selectTrackingIdInfo_one: "Für folgende Sendungsnummer möchte ich eine Meldung abgeben:",
          commentLabel: "Bitte gebe eine kurze Beschreibung ein (max. 500 Zeichen)",
          commentPlaceholder: "Beschreibung eingeben",
          commentError: "Bitte gebe eine Beschreibung mit max. 500 Zeichen ein",
          placeholderDropdown: "Sendungsnummern auswählen",
          nothingFound: "Keine Sendungsnummer gefunden",
        },
      },
    },
    createNewClaimButton: "Artikel reklamieren",
    createNewClaimButtonTooltip: "Einen oder mehrere Artikel aus dieser Rechnung reklamieren",
    noDataAvailable: "Noch keine Rechnungspositionen gefunden.",
    noDataFound: "Keine Rechnungspositionen gefunden, die deinen Suchkriterien entsprechen.",
    positionTable: {
      noDataAvailable: "Noch keine Rechnungspositionen gefunden.",
      noDataFound: "Keine Rechnungspositionen gefunden, die deinen Suchkriterien entsprechen.",
      titleWithTotalCount: "Rechnungspositionen ({{totalCount}})",
      titleWithItemCount: "Rechnungspositionen ({{ itemCount }} von {{totalCount}})",
      position: "Pos.",
      article: "Artikel",
      orderNr: "Bestell-Nr.",
      originCountry: "Herk.-L.",
      manufacturerCode: "HS-Code",
      quantity: "Menge",
      unitPrice: "Einzelpreis",
      discount: "Rabatt",
      discountedUnitPrice: "Endpreis",
      totalPrice: "Gesamtpreis",
      customerReference: "Bestellreferenz",
      customerItemReference: "Artikelreferenz",
      searchPlaceholder: "Rechnungspositionen durchsuchen (min. 3 Zeichen)",
      freightSurchargeTooltip:
        "Einzelpreis = Basispreis {{ basePrice }} + {{ percentage }} ({{ portion }}) Frachtkostenzuschlag",

      endOfTableTitle: "Nicht gefunden wonach du suchst?",
      endOfTableSubtitle: "Verfeinere deine Ergebnisse mit dem Suchfilter und der Sortierung!",
    },
    filter: {
      title: "Filtern & Sortieren",
      searchPlaceholder: "Rechnungspositionen durchsuchen (min. 3 Zeichen)",
      noDataAvailable: "Noch keine Rechnungspositionen gefunden.",
      noDataFound: "Keine Rechnungspositionen gefunden, die deinen Suchkriterien entsprechen.",
    },
    sort: {
      orderNumber: "Bestell-Nr.",
      originCountry: "Herk.-L.",
      tariffNumber: "HS-Code",
      quantity: "Menge",
      unitPrice: "Einzelpreis",
      discount: "Rabatt",
      discountedUnitPrice: "Endpreis",
      total: "Gesamtpreis",
      customerReference: "Bestellreferenz",
      customerItemReference: "Artikelreferenz",
    },
    card: {
      orderNr: "Bestell-Nr.",
      originCountry: "Herk.-L.",
      manufacturerCode: "HS-Code",
      quantity: "Menge",
      unitPrice: "Einzelpreis",
      discount: "Rabatt",
      discountedUnitPrice: "Endpreis",
      totalPrice: "Gesamtpreis",
      customerReference: "Bestellreferenz",
      customerItemReference: "Artikelreferenz",
    },
    infoBox: {
      title: "Liefer- und Versandinformationen",
      selectedShippingAddress: "Ausgewählte Lieferadresse",
      shipmentTracking: "Sendeverfolgung",
      noShipmentTracking: "Keine Sendeverfolgungsdaten vorhanden.",
    },
    cancellationReasons: {
      BY_HEO: "Stornierung durch heo",
      BY_CUSTOMER: "Stornierung durch Kunde",
      null: "---",
    } as Record<string, string>,
  },
};
