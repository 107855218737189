import { getDate } from "date-fns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { DateStyle, useLocalizedDateFormatter } from "./useLocalizedDateFormatter";

export enum RelativeMonthPrefix {
  START = "START",
  MID = "MID",
  END = "END",
}

/**
 * Formats a date into a relative month prefix with month and year slashed
 * e.g. "Mitte 02/2022"
 */
export const useRelativeMonth = () => {
  const { t } = useTranslation();
  const { formatDate } = useLocalizedDateFormatter();

  return useCallback(
    (date: Date | string) => {
      const targetDate = new Date(date);
      const translatedPrefix = t(`relativeMonth.${getMonthPrefix(targetDate)}`);
      return `${translatedPrefix} ${formatDate(targetDate, DateStyle.MONTH_YEAR_SLASHED)}`;
    },
    [formatDate, t],
  );
};

function getMonthPrefix(date: Date) {
  const day = getDate(date);
  if (day < 11) return RelativeMonthPrefix.START;
  if (day < 21) return RelativeMonthPrefix.MID;
  return RelativeMonthPrefix.END;
}
