/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreProfileDetailsHourRange } from './StoreProfileDetailsHourRange';
import {
    StoreProfileDetailsHourRangeFromJSON,
    StoreProfileDetailsHourRangeFromJSONTyped,
    StoreProfileDetailsHourRangeToJSON,
} from './StoreProfileDetailsHourRange';

/**
 * 
 * @export
 * @interface StoreProfileDay
 */
export interface StoreProfileDay {
    /**
     * 
     * @type {Array<StoreProfileDetailsHourRange>}
     * @memberof StoreProfileDay
     */
    hours: Array<StoreProfileDetailsHourRange>;
    /**
     * 
     * @type {boolean}
     * @memberof StoreProfileDay
     */
    isOpen: boolean;
}

/**
 * Check if a given object implements the StoreProfileDay interface.
 */
export function instanceOfStoreProfileDay(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "hours" in value;
    isInstance = isInstance && "isOpen" in value;

    return isInstance;
}

export function StoreProfileDayFromJSON(json: any): StoreProfileDay {
    return StoreProfileDayFromJSONTyped(json, false);
}

export function StoreProfileDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfileDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hours': ((json['hours'] as Array<any>).map(StoreProfileDetailsHourRangeFromJSON)),
        'isOpen': json['isOpen'],
    };
}

export function StoreProfileDayToJSON(value?: StoreProfileDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hours': ((value.hours as Array<any>).map(StoreProfileDetailsHourRangeToJSON)),
        'isOpen': value.isOpen,
    };
}

