import { useMemo } from "react";

import { useGetAccountDetailsQuery } from "~/api/hooks/account/useGetAccountDetailsQuery";
import { useAppConfig } from "~/config/AppConfig";

export enum ShopLanguage {
  DE = "de",
  UK = "en",
  FR = "fr",
}

export type CompanyCode = "DE" | "UK" | "FR";

//TODO In future we should use the language-flag from portal
export const defaultLanguageByCompanyCode = (companyCode: CompanyCode) => {
  return ShopLanguage[companyCode];
};

export const useShopUrl = (productNumber?: string) => {
  const { data: retailerDetails, isFetched } = useGetAccountDetailsQuery();
  const appConfig = useAppConfig();
  const shopBaseUrl = appConfig.links.shop;

  const shopUrl: string | undefined = useMemo(() => {
    if (isFetched && retailerDetails) {
      const companyCode = retailerDetails.customerNumber.companyCode;
      const shopLanguage = defaultLanguageByCompanyCode(
        retailerDetails.customerNumber.companyCode as CompanyCode,
      );

      return `${shopBaseUrl}/${companyCode.toLowerCase()}/${shopLanguage}`;
    }
  }, [retailerDetails, isFetched, shopBaseUrl]);

  const shopUrlWithProduct: string | undefined = useMemo(() => {
    if (isFetched && retailerDetails) {
      return `${shopUrl}/product/${productNumber}`;
    }
  }, [retailerDetails, isFetched, shopUrl, productNumber]);

  return { shopUrl, shopUrlWithProduct };
};
