import { init, reactRouterV6BrowserTracingIntegration, replayIntegration } from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ENV } from "~/env";

const environments: Record<string, string> = {
  "bc.stage.heo.com": "stage",
  "bc.preprod.heo.com": "preprod",
  "bc.heo.com": "prod",
  "port.stage.heo.com": "stage",
  "port.preprod.heo.com": "preprod",
  "port.heo.com": "prod",
};

const getEnvironment = () => {
  const host = window.location.host;
  if (host.includes("localhost")) return "local";
  return environments[host] ?? "unknown";
};

init({
  dsn: ENV.sentryDsn,
  enabled: !!ENV.sentryDsn,

  integrations: [
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),

    replayIntegration(),
  ],

  environment: getEnvironment(),
  release: ENV.commitHash ?? undefined,

  attachStacktrace: true,

  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
