/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeoItemId } from './HeoItemId';
import {
    HeoItemIdFromJSON,
    HeoItemIdFromJSONTyped,
    HeoItemIdToJSON,
} from './HeoItemId';

/**
 * 
 * @export
 * @interface CustomerOrderItemsByCustomerOrderSummary
 */
export interface CustomerOrderItemsByCustomerOrderSummary {
    /**
     * 
     * @type {HeoItemId}
     * @memberof CustomerOrderItemsByCustomerOrderSummary
     */
    heoItemId: HeoItemId;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderItemsByCustomerOrderSummary
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderItemsByCustomerOrderSummary
     */
    acceptedQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderItemsByCustomerOrderSummary
     */
    shippedQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderItemsByCustomerOrderSummary
     */
    pricePerUnit: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderItemsByCustomerOrderSummary
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderItemsByCustomerOrderSummary
     */
    discount: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderItemsByCustomerOrderSummary
     */
    customerItemReference: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderItemsByCustomerOrderSummary
     */
    productNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderItemsByCustomerOrderSummary
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderItemsByCustomerOrderSummary
     */
    productImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderItemsByCustomerOrderSummary
     */
    productThumbnailUrl: string;
}

/**
 * Check if a given object implements the CustomerOrderItemsByCustomerOrderSummary interface.
 */
export function instanceOfCustomerOrderItemsByCustomerOrderSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "heoItemId" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "acceptedQuantity" in value;
    isInstance = isInstance && "shippedQuantity" in value;
    isInstance = isInstance && "pricePerUnit" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "discount" in value;
    isInstance = isInstance && "customerItemReference" in value;
    isInstance = isInstance && "productNumber" in value;
    isInstance = isInstance && "productName" in value;
    isInstance = isInstance && "productImageUrl" in value;
    isInstance = isInstance && "productThumbnailUrl" in value;

    return isInstance;
}

export function CustomerOrderItemsByCustomerOrderSummaryFromJSON(json: any): CustomerOrderItemsByCustomerOrderSummary {
    return CustomerOrderItemsByCustomerOrderSummaryFromJSONTyped(json, false);
}

export function CustomerOrderItemsByCustomerOrderSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOrderItemsByCustomerOrderSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'heoItemId': HeoItemIdFromJSON(json['heoItemId']),
        'quantity': json['quantity'],
        'acceptedQuantity': json['acceptedQuantity'],
        'shippedQuantity': json['shippedQuantity'],
        'pricePerUnit': json['pricePerUnit'],
        'total': json['total'],
        'discount': json['discount'],
        'customerItemReference': json['customerItemReference'],
        'productNumber': json['productNumber'],
        'productName': json['productName'],
        'productImageUrl': json['productImageUrl'],
        'productThumbnailUrl': json['productThumbnailUrl'],
    };
}

export function CustomerOrderItemsByCustomerOrderSummaryToJSON(value?: CustomerOrderItemsByCustomerOrderSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'heoItemId': HeoItemIdToJSON(value.heoItemId),
        'quantity': value.quantity,
        'acceptedQuantity': value.acceptedQuantity,
        'shippedQuantity': value.shippedQuantity,
        'pricePerUnit': value.pricePerUnit,
        'total': value.total,
        'discount': value.discount,
        'customerItemReference': value.customerItemReference,
        'productNumber': value.productNumber,
        'productName': value.productName,
        'productImageUrl': value.productImageUrl,
        'productThumbnailUrl': value.productThumbnailUrl,
    };
}

