import { DragOverlay } from "@dnd-kit/core";
import React from "react";

import { GridItemWrapper } from "../GridItemWrapper/GridItemWrapper";
import { GridLayoutItem } from "../GridLayout";

import styles from "./GridDragOverlay.module.css";

export interface GridDragOverlayProps {
  item: GridLayoutItem | undefined;
}

const GridDragOverlay: React.FC<GridDragOverlayProps> = (props) => {
  const { item } = props;

  return (
    <DragOverlay zIndex={2}>
      {item && (
        <GridItemWrapper item={item} isDragging={false} isEditing={false}>
          <div className={styles.contentOverlay} />
          {item.content}
        </GridItemWrapper>
      )}
    </DragOverlay>
  );
};

export { GridDragOverlay };
