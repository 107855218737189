import { Box, Container, ContainerProps } from "@mantine/core";
import clsx from "clsx";
import React, { useRef } from "react";

import { usePageTitle } from "~/hooks/pageTitle/usePageTitle";

import { useScrollTopOnRouteChange } from "~/hooks/ui/useScrollTopOnRouteChange";
import styles from "./PageContainer.module.css";

export interface PageContainerProps extends ContainerProps {
  id: string;
  pageTitle?: string;
  withPadding?: boolean;
  positionRelative?: boolean;
  fixedHeight?: boolean;
  children: React.ReactNode;
}

const PageContainer: React.FC<PageContainerProps> = (props) => {
  const {
    id,
    pageTitle,
    children,
    withPadding,
    className,
    positionRelative,
    fixedHeight = true,
    ...containerProps
  } = props;

  usePageTitle({ title: pageTitle });

  const ref = useRef<HTMLDivElement>(null);
  useScrollTopOnRouteChange({ ref });

  const containerClasses = clsx(
    styles.container,
    positionRelative && styles.relativeContainer,
    fixedHeight && styles.fixedHeight,
    className,
  );

  return (
    <Container
      data-testid={id}
      px={withPadding ? "md" : 0}
      py={withPadding ? "lg" : 0}
      ref={ref}
      fluid
      className={containerClasses}
      {...containerProps}
    >
      <Box className={styles.innerContainer}>{children}</Box>
    </Container>
  );
};

export { PageContainer };
