import { Group, GroupProps } from "@mantine/core";
import clsx from "clsx";
import React, { ReactNode } from "react";

import styles from "./ResponsiveButtonGroup.module.css";

export interface ResponsiveButtonGroupProps extends GroupProps {
  children: ReactNode;
}

/**
 * A group wrapper for buttons that switches to a column layout with full width buttons on mobile
 */
const ResponsiveButtonGroup: React.FC<ResponsiveButtonGroupProps> = (props) => {
  const { children, className, ...groupProps } = props;

  return (
    <Group justify="flex-end" gap={"sm"} {...groupProps} className={clsx(styles.group, className)}>
      {children}
    </Group>
  );
};

export { ResponsiveButtonGroup };
