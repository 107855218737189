import { useQuery } from "@tanstack/react-query";

import { GetNotificationsOnDisplayRequest } from "~/api/openapi";
import { useRestAPI } from "../../useRestAPI";

const useGetRetailerNotificationQueryKey = "GetRetailerNotifications";

export const useGetRetailerNotificationQuery = (
  requestParameters: GetNotificationsOnDisplayRequest,
) => {
  const api = useRestAPI();
  return useQuery({
    queryKey: [useGetRetailerNotificationQueryKey],
    queryFn: () => api.retailerNotification.getNotificationsOnDisplay(requestParameters),
  });
};
