/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerOrderItemSummary } from './CustomerOrderItemSummary';
import {
    CustomerOrderItemSummaryFromJSON,
    CustomerOrderItemSummaryFromJSONTyped,
    CustomerOrderItemSummaryToJSON,
} from './CustomerOrderItemSummary';

/**
 * 
 * @export
 * @interface PagedResultCustomerOrderItemSummary
 */
export interface PagedResultCustomerOrderItemSummary {
    /**
     * 
     * @type {Array<CustomerOrderItemSummary>}
     * @memberof PagedResultCustomerOrderItemSummary
     */
    content: Array<CustomerOrderItemSummary>;
    /**
     * 
     * @type {number}
     * @memberof PagedResultCustomerOrderItemSummary
     */
    totalElements: number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultCustomerOrderItemSummary
     */
    totalPages: number;
}

/**
 * Check if a given object implements the PagedResultCustomerOrderItemSummary interface.
 */
export function instanceOfPagedResultCustomerOrderItemSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "totalElements" in value;
    isInstance = isInstance && "totalPages" in value;

    return isInstance;
}

export function PagedResultCustomerOrderItemSummaryFromJSON(json: any): PagedResultCustomerOrderItemSummary {
    return PagedResultCustomerOrderItemSummaryFromJSONTyped(json, false);
}

export function PagedResultCustomerOrderItemSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedResultCustomerOrderItemSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': ((json['content'] as Array<any>).map(CustomerOrderItemSummaryFromJSON)),
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
    };
}

export function PagedResultCustomerOrderItemSummaryToJSON(value?: PagedResultCustomerOrderItemSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': ((value.content as Array<any>).map(CustomerOrderItemSummaryToJSON)),
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
    };
}

