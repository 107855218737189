import { LoadingOverlay } from "@mantine/core";
import { GoogleTagManagerProvider } from "@tracktor/react-google-tag-manager";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAppConfig } from "~/config/AppConfig";
import { InitAuthenticated } from "~/ui/InitAuthenticated";
import { Layout } from "~/ui/layout/Layout";

import { RoutesEnum } from "../RoutesEnum";
import { useIsAuthenticated } from "./useIsAuthenticated";
import { useIsAuthorized } from "./useIsAuthorized";

const AuthRoute: React.FC = () => {
  const appConfig = useAppConfig();

  const { isAuthenticated, isLoading } = useIsAuthenticated();
  const { isAuthorized, isLoading: isLoadingAuthorized } = useIsAuthorized();

  if ((isLoading && !isAuthenticated) || (isLoadingAuthorized && !isAuthorized)) {
    return <LoadingOverlay visible />;
  }

  if (!isAuthenticated) {
    return <Navigate to={RoutesEnum.LOGIN} replace />;
  }

  if (!isAuthorized) {
    return <Navigate to={RoutesEnum.NO_ACCESS} replace />;
  }

  return (
    <Layout>
      <GoogleTagManagerProvider id={appConfig.tracking.gtmId ?? ""}>
        <InitAuthenticated />
        <Outlet />
      </GoogleTagManagerProvider>
    </Layout>
  );
};

export { AuthRoute };
