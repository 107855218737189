import { useState } from "react";
import { DonutChart } from "~/ui/components/Charts/DonutChart/DonutChart";
import { OpenOrdersChartSummary } from "./OpenOrdersChartSummary/OpenOrdersChartSummary";
import { SalesOrderStatusTileSummary } from "./interface/SalesOrderStatusTileSummary";

interface OpenOrdersChartProps {
  orderDictWithoutEmpty: SalesOrderStatusTileSummary[];
}

const OpenOrdersChart: React.FC<OpenOrdersChartProps> = (props) => {
  const { orderDictWithoutEmpty } = props;

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
  const handleDetailClick = (tileIndex: number | undefined) => {
    setSelectedIndex((state) => (state === tileIndex ? undefined : tileIndex));
  };

  return (
    <DonutChart
      height={400}
      type="donut"
      options={{
        labels: orderDictWithoutEmpty.map((data) => data.label.concat(` (${data.value})`)),
        colors: orderDictWithoutEmpty.map((data) => data.color),
        states: { active: { filter: { type: "none" } } },
        responsive: [
          {
            breakpoint: 610,
            options: {
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          position: "right",
        },
        chart: {
          id: "openOrders",
          events: {
            dataPointSelection: (_, __, config) => {
              handleDetailClick(config.dataPointIndex);
            },
          },
        },

        dataLabels: {
          enabled: false,
        },
      }}
      series={orderDictWithoutEmpty.map((data) => data.value)}
    >
      <OpenOrdersChartSummary data={orderDictWithoutEmpty} selectedIndex={selectedIndex} />
    </DonutChart>
  );
};
export { OpenOrdersChart };
