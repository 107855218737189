import { ContactPersonContactTypeEnum } from "~/api/openapi";

import { RetailerPageDE } from "../de/de.retailerPage";

export const RetailerPageEN: typeof RetailerPageDE = {
  title: "My company",
  header: {
    customerNumber: "Customer ID: {{number}}",
    customerSince: "Customer since: {{date}}",
  },
  tabs: {
    basicInformationLabel: "Basic information",
    accountAdministrationLabel: "Account administration",
    billingDeliverAddress: "Billing and delivery addresses",
    paymentAgreements: "Payment agreements",
    discountAgreements: "Discount agreements",
    socialMediaLabel: "Social media",
    contactPersons: "Contact persons",
  },
  basicInformation: {
    error: "An error occurred while loading the company data. Please try again.",
    titel: "General company & contact data",
    changeButtonLabel: "Request change",
    basicCompanyinformationLabel: "General company data",
    basicCompanyContactdataLabel: "General contact data",
    basicCompanyInvoiceAddressLabel: "Invoice address",
    basicCompanyMediaLinksLabel: "Media links",
    changeCompanyInformationButton: "Request change of company data",
    changeCompanyContactdataButton: "Request change of contact data",
    companyNameLabel: "Company name",
    businessTypeLabel: "Business type",
    phoneNumberLabel: "Phone number",
    streetLabel: "Street",
    streetNumberLabel: "House number",
    zipCodeLabel: "Postal code",
    cityLabel: "City",
    countryLabel: "Country",
    vatIdLabel: "VAT ID",
    phoneLabel: "Phone",
    emailLabel: "Email",
    shopUrl: "Shop URL",
    websiteLabel: "Website",
    socialMediaFbLabel: "Facebook",
    socialMediaTwitterLabel: "Twitter",
    socialMediaTwitchLabel: "Twitch",
    socialMediaInstagramLabel: "Instagram",
    socialMediaYoutubeLabel: "Youtube",
    salutation: {
      label: "Salutation",
    },
    invoiceAddress: {
      nameLabel: "Name",
      additionalInfoLabel: "Address addition",
      streetLabel: "Street address",
      streetNumberLabel: "House number",
      zipCodeLabel: "Postal code",
      cityLabel: "City",
      countryLabel: "Country",
    },
    retailerDataForm: {
      description:
        "You can use the following input fields to request changes to your company data. <1 /> Please note that proof of a trade licence is required for all changes (except business type).",
      companyNameLabel: "Company name",
      businessTypeLabel: "Business type",
      streetNameLabel: "Street",
      houseNumberLabel: "House number",
      additionalInfoLabel: "Address suffix",
      zipCodeLabel: "Postal code",
      cityLabel: "City",
      countryLabel: "Country",
      vatIdLabel: "Value added VAD ID",
      uploadLabel: "Proof of trade licence",
      saveButtonLabel: "Request change",
      changeNotice:
        "A change to the business address also results in a change to the primary billing address and vice versa, as these data are linked. <1 /> You therefore do not need to initiate a separate change to the billing address.",
      successMessage: "A change to the company data has been successfully requested.",
      tradeLicenseRequiredError:
        "The trade licence verification is mandatory if any field apart from the business type has been changed.",
      validation: {
        companyName: "Company name is a mandatory field",
        streetName: "Street is a mandatory field",
        houseNumber: "House number is a mandatory field",
        zipCode: "Postal code is a mandatory field",
        city: "City is a mandatory field",
        vatId: "VAT ID is a mandatory field",
      },
    },
    retailerContactForm: {
      description:
        "You can use the following input fields to request changes to your contact details.",
      phoneLabel: "Telephone",
      emailLabel: "Email address",
      websiteUrlLabel: "Website",
      shopUrlLabel: "Shop URL",
      twitterUrlLabel: "Twitter",
      facebookUrlLabel: "Facebook",
      youtubeUrlLabel: "Youtube",
      instagramUrlLabel: "Instagram",
      twitchUrlLabel: "Twitch",
      successMessage: "A change of contact details has been successfully requested.",
      saveButtonLabel: "Request change",
      errorMessage: "Sorry, the changes could not be requested. Please try again later.",
    },
  },
  accountAdministration: {
    title: "Overview of my employees",
    table: {
      account: "Account",
      firstName: "First name",
      lastName: "Last name",
      role: "Role",
    },
  },
  contactPersons: {
    addNewContactPerson: "Add another contact person",
    buttonLabel: "Edit",
    title: "Contact persons for communication with heo",
    contactPerson: "Contact person {{contactPersonType}}",
    contactPersonType: {
      [ContactPersonContactTypeEnum.Contractual]: "Contractual",
      [ContactPersonContactTypeEnum.Delivery]: "Delivery",
      [ContactPersonContactTypeEnum.Invoice]: "Invoice",
      [ContactPersonContactTypeEnum.Other]: "Other",
    },
    modal: {
      validation: {
        firstName: "Please enter a valid first name",
        lastName: "Please enter a valid last name",
        email: "Please enter a valid email address",
      },
      firstNameLabel: "First name",
      lastNameLabel: "Last name",
      mailLabel: "Email address",
      phoneLabel: "Phone",
      mobileLabel: "Mobile",
      typeLabel: "Contact person for area",
      firstNamePlaceholder: "First name",
      lastNamePlaceholder: "Last name",
      mailplaceholder: "name@company.com",
      phonePlaceholder: "+123 4567 8900",
      mobilePlaceholder: "+12345678900",
      typePlaceholder: "Please select area",

      createContactPersonModal: {
        addNewContactPersonTitle: "Create new contact person",
        saveButtonLabel: "Create contact person",
        saved: "Contact person was successfully created",
      },
      updateContactPersonModal: {
        updateContactPersonTitle: "Change contact person",
        saveButtonLabel: "Change contact person",
        saved: "Contact person has been successfully changed",
      },
      deleteContactPerson: {
        confirmTitle: "Delete contact person",
        confirmLabel: "Delete",
        cancelLabel: "Cancel",
        success: "The contact person {{name}} was successfully deleted.",
        error: "An error occurred while deleting the contact person {{name}}.",
        infotext: "Are you sure you really want to delete the contact person {{name}}?",
      },
    },
  },
  billingDeliveryAddress: {
    title: "Active billing & delivery address",
    activeBillingAddressTitle: "Active billing address",
    activeDeliveryAddressTitle: "Active delivery address",
    deliveryAddressTitle: "Delivery address",
    deliveryAddressesTitle: "Other delivery addresses",
    errorLoadingBillingAddress: "An error occurred while loading the billing address.",
    errorLoadingDeliveryAddresses: "An error occurred while loading the delivery address.",

    addAnotherAddressTitle: "Create another address",
    addNewInvoiceAddress: "Request a new billing address",
    addNewDeliveryAddress: "Request new delivery address",

    changeDefaultInvoiceAddress: "Request as billing address",
    changeDefaultDeliveryAddress: "Request as default delivery address",

    changeDefaultDeliveryAddressInfotext:
      "Are you sure you want to request the address template {{address}} as the new default delivery address?",
    changeDefaultInvoiceAddressInfotext:
      "In order for you to use the selected address as your new billing address, we need a copy of your trade licence. Please enclose the relevant proof with this change request. We will then check the change request immediately.",
    buttonConfirmLabel: "Confirm",
    changeDefaultDeliveryAddressSuccessNotification:
      "You have requested the address {{address}} as the new default delivery address. The change is currently being checked.",
    changeDefaultInvoiceAddressSuccessNotification:
      "You have requested the address {{address}} as the new default billing address. The change is currently being checked.",
    changeDefaultInvoiceAddressErrorNotification:
      "An error occurred while requesting the address {{address}} as the new default invoice address.",
    changeDefaultDeliveryAddressErrorNotification:
      "An error occurred when requesting the address {{address}} as the new default delivery address.",
  },
  paymentAgreements: {
    activePaymentAgreements: "Active payment agreement",
    paymentMethodTitle: "Payment method",
    noDataText: "There are no payment agreements available",
    bankAccount: {
      owner: "Account holder",
      bank: "Banking institute",
      iban: "IBAN",
    },
    factoringLimit: {
      title: "Factoring limit",
      activeSince: "Active since",
      limit: "Total limit granted",
      available: "Available limit",
      used: "Utilised limit",
    },
    freightAllowance: {
      title: "Freight allowance",
      freightAllowance: "Freight allowance granted",
      information: "Valid for deliveries within Germany.",
    },
    paymentDue: {
      title: "Terms of payment",
      paymentDueTarget: "Payment target",
      paymentDueTerm: "Payment term",
      cashDiscountTerm: "Cash discount term",
      cashDiscount: "Cash discount",
      days: "{{days}} days",
    },
    billingStop: {
      title: "Invoice stop",
      error: "An error occurred when loading the billing stop data.",
      reason: {
        BILLING_STOP_OVERDUE_PAYMENT:
          "A billing stop has been placed on your account due to an overdue payment. Please contact Customer Service if you have any questions.",
        BILLING_STOP_PAYMENT_ADAPTION:
          "A billing stop has been noted on your account. It is necessary to update the payment method. Please contact Customer Service if you have any questions.",
        BILLING_STOP_TEMPORARY_ABSENCE:
          "A billing stop has been noted in your account until {{ until }}. Please contact Customer Service if you have any questions.",
        BILLING_STOP_OTHERS:
          "A billing stop has been noted in your account. Please contact Customer Service if you have any questions.",
        BILLING_STOP_UNKNOWN:
          "A billing stop has been noted in your account. Please contact Customer Service if you have any questions.",
      },
    },
  },
  discountAgreements: {
    generallyDiscounts: "General discount",
    supplierDiscounts: "Supplier discounts",
    customDiscounts: "Other discounts",
    activeSince: "Active since",
    discount: "{{discountValue}} % discount",
    discountWithName: "{{discountValue}} % {{discountName}} discount",
    globalDiscount: "General discount on all discountable items in the heo range.",
    infoBox: {
      title: "Important notes on discounts",
      description:
        "Some products in our range are excluded from discounting or can only be discounted up to a certain amount.<br /><br />The discount granted in each case, which is also noted on the invoice, among other things, is displayed accordingly in all views of the Business Console.",
    },
    showMore: "Show more",
    showLess: "Show less",
  },
  mediaLinks: {
    websiteUrlLabel: "Website",
    shopUrlLabel: "Shop URL",
    twitterUrlLabel: "Twitter",
    facebookUrlLabel: "Facebook",
    youtubeUrlLabel: "Youtube",
    instagramUrlLabel: "Instagram",
    twitchUrlLabel: "Twitch",
  },
};
