/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeoCustomerNumber } from './HeoCustomerNumber';
import {
    HeoCustomerNumberFromJSON,
    HeoCustomerNumberFromJSONTyped,
    HeoCustomerNumberToJSON,
} from './HeoCustomerNumber';
import type { InvoiceAddress } from './InvoiceAddress';
import {
    InvoiceAddressFromJSON,
    InvoiceAddressFromJSONTyped,
    InvoiceAddressToJSON,
} from './InvoiceAddress';
import type { MediaLinks } from './MediaLinks';
import {
    MediaLinksFromJSON,
    MediaLinksFromJSONTyped,
    MediaLinksToJSON,
} from './MediaLinks';
import type { SalesTeam } from './SalesTeam';
import {
    SalesTeamFromJSON,
    SalesTeamFromJSONTyped,
    SalesTeamToJSON,
} from './SalesTeam';

/**
 * 
 * @export
 * @interface RetailerDetails
 */
export interface RetailerDetails {
    /**
     * 
     * @type {string}
     * @memberof RetailerDetails
     */
    id: string;
    /**
     * 
     * @type {HeoCustomerNumber}
     * @memberof RetailerDetails
     */
    customerNumber: HeoCustomerNumber;
    /**
     * 
     * @type {string}
     * @memberof RetailerDetails
     */
    companyName: string;
    /**
     * 
     * @type {InvoiceAddress}
     * @memberof RetailerDetails
     */
    invoiceAddress: InvoiceAddress;
    /**
     * 
     * @type {string}
     * @memberof RetailerDetails
     */
    vatId: string;
    /**
     * 
     * @type {Date}
     * @memberof RetailerDetails
     */
    customerSince: Date;
    /**
     * 
     * @type {string}
     * @memberof RetailerDetails
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerDetails
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerDetails
     */
    businessType: RetailerDetailsBusinessTypeEnum;
    /**
     * 
     * @type {MediaLinks}
     * @memberof RetailerDetails
     */
    mediaLinks: MediaLinks;
    /**
     * 
     * @type {string}
     * @memberof RetailerDetails
     */
    productExportLink: string;
    /**
     * 
     * @type {SalesTeam}
     * @memberof RetailerDetails
     */
    salesTeam: SalesTeam;
}


/**
 * @export
 */
export const RetailerDetailsBusinessTypeEnum = {
    Store: 'STORE',
    Wholesale: 'WHOLESALE',
    Ecommerce: 'ECOMMERCE',
    StoreAndEcommerce: 'STORE_AND_ECOMMERCE',
    Other: 'OTHER'
} as const;
export type RetailerDetailsBusinessTypeEnum = typeof RetailerDetailsBusinessTypeEnum[keyof typeof RetailerDetailsBusinessTypeEnum];


/**
 * Check if a given object implements the RetailerDetails interface.
 */
export function instanceOfRetailerDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "customerNumber" in value;
    isInstance = isInstance && "companyName" in value;
    isInstance = isInstance && "invoiceAddress" in value;
    isInstance = isInstance && "vatId" in value;
    isInstance = isInstance && "customerSince" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "phoneNumber" in value;
    isInstance = isInstance && "businessType" in value;
    isInstance = isInstance && "mediaLinks" in value;
    isInstance = isInstance && "productExportLink" in value;
    isInstance = isInstance && "salesTeam" in value;

    return isInstance;
}

export function RetailerDetailsFromJSON(json: any): RetailerDetails {
    return RetailerDetailsFromJSONTyped(json, false);
}

export function RetailerDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetailerDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerNumber': HeoCustomerNumberFromJSON(json['customerNumber']),
        'companyName': json['companyName'],
        'invoiceAddress': InvoiceAddressFromJSON(json['invoiceAddress']),
        'vatId': json['vatId'],
        'customerSince': (new Date(json['customerSince'])),
        'email': json['email'],
        'phoneNumber': json['phoneNumber'],
        'businessType': json['businessType'],
        'mediaLinks': MediaLinksFromJSON(json['mediaLinks']),
        'productExportLink': json['productExportLink'],
        'salesTeam': SalesTeamFromJSON(json['salesTeam']),
    };
}

export function RetailerDetailsToJSON(value?: RetailerDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerNumber': HeoCustomerNumberToJSON(value.customerNumber),
        'companyName': value.companyName,
        'invoiceAddress': InvoiceAddressToJSON(value.invoiceAddress),
        'vatId': value.vatId,
        'customerSince': (value.customerSince.toISOString()),
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'businessType': value.businessType,
        'mediaLinks': MediaLinksToJSON(value.mediaLinks),
        'productExportLink': value.productExportLink,
        'salesTeam': SalesTeamToJSON(value.salesTeam),
    };
}

