/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscountAgreement
 */
export interface DiscountAgreement {
    /**
     * 
     * @type {string}
     * @memberof DiscountAgreement
     */
    type: DiscountAgreementTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DiscountAgreement
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountAgreement
     */
    regularDiscount: number;
    /**
     * 
     * @type {Date}
     * @memberof DiscountAgreement
     */
    createdAt: Date;
}


/**
 * @export
 */
export const DiscountAgreementTypeEnum = {
    Producer: 'PRODUCER',
    Custom: 'CUSTOM',
    Theme: 'THEME',
    Producttype: 'PRODUCTTYPE'
} as const;
export type DiscountAgreementTypeEnum = typeof DiscountAgreementTypeEnum[keyof typeof DiscountAgreementTypeEnum];


/**
 * Check if a given object implements the DiscountAgreement interface.
 */
export function instanceOfDiscountAgreement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "regularDiscount" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function DiscountAgreementFromJSON(json: any): DiscountAgreement {
    return DiscountAgreementFromJSONTyped(json, false);
}

export function DiscountAgreementFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountAgreement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'name': json['name'],
        'regularDiscount': json['regularDiscount'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function DiscountAgreementToJSON(value?: DiscountAgreement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'name': value.name,
        'regularDiscount': value.regularDiscount,
        'createdAt': (value.createdAt.toISOString()),
    };
}

