import { ProductSummaryPackagingTypeEnum } from "~/api/openapi/models/ProductSummary";
import { ProductsPageDE } from "../de/de.productsPage";

export const ProductsPageEN: typeof ProductsPageDE = {
  pageTitle: "My products",
  title: "My products ({{ count }})",
  item_one: "{{count}} item",
  item_other: "{{count}} items",
  filter: {
    title: "Filter & Sort",
    manufacturerTitle: "Manufacturer",
    manufacturerSearch: "Filter for manufacturers",
    typesTitle: "Product types",
    typesSearch: "Filter for types",
    searchPlaceholder: "Search products (at least 3 characters)",
    manufacturerSelectAll: "All manufacturers",
    productTypeSelectAll: "All product types",
    onlyOpen: "Only show products in open orders",
    onlyOpenSegmentOn: "Open Orders",
    onlyOpenSegmentOff: "All Orders",
    nothingFound: "No results found",
  },
  noProductsFound: "No products found that match your search criteria.",
  noProductsAvailable: "No products available yet.",
  sort: {
    product: "Article",
    productManufacturer: "Manufacturer",
    typeAndTheme: "Product type",
    customerOrder: "Ordered so far",
    lastRequestedAt: "Last ordered",
    salesOrder: "Confirmed so far",
    status: "Processing status",
  },
  table: {
    head: {
      article: "Article",
      manufacturer: "Manufacturer",
      type: "Product type",
      customerOrder: "Ordered so far",
      lastRequested: "Last ordered",
      salesOrder: "Confirmed so far",
      processingStatus: "Processing status",
    },
    body: {
      customerOrders_one: "{{count}} Order for {{orderValue}}",
      customerOrders_other: "{{count}} Orders for {{orderValue}}",
      salesOrders_one: "{{count}} Confirmation for {{orderValue}}",
      salesOrders_other: "{{count}} Confirmations for {{orderValue}}",
      moreEntries_one: "+ {{count}} more",
      moreEntries_other: "+ {{ count }} more",
      status: {
        outstanding: "Open",
        processing: "In progress",
        shipped: "Delivered",
        inClaim: "In claim",
      },
    },
  },
  card: {
    orderedSoFar: "Ordered so far: <0>{{count}}</0>",
    confirmedSoFar: "Confirmed so far: <0>{{count}}</0>",
  },
  detail: {
    title: "Product: {{articleNumber}}",
    articleNumber: "Item No.: {{ articleNumber }}",
    descriptionTitle: "Description",
    priceTitle: "Price information",
    customerOrdersTabTitle: "Orders",
    salesOrderTabTitle: "Confirmed orders",
    invoiceItemsTabTitle: "Invoices",
    productClaimsTabTitle: "Claims",
    downloadImages: "Save images",
    salesOrderAvailability: {
      tooltip: "Show all pre-orders and backorders of the item",
      linkText_zero: "{{count}} items ({{totalAmount}}) in backorder",
      linkText_one: "{{count}} item ({{totalAmount}}) in backorder",
      linkText_other: "{{count}} items ({{totalAmount}}) in backorder",
      noData: "No backorders available",
    },
    productInformation: {
      descriptionLabel: "Description",
      productInformationLabel: "Product information",
      price: {
        title: "Current price information",
        priceIncl: "Price incl.",
        amountAndDiscount: "{{discount}} discount",
        basePrice: "Base price",
        plusFreightSurcharge: "plus freight surcharge",
        noPrice: "No price is currently available.",
      },
      reorderButton: "Reorder item",
      scalePrices: {
        title: "Scale prices",
        fromQuantity: "From {{quantity}} unit:",
        unitPrice: "Unit price",
      },
      unlisted: {
        title: "Item sold out",
        unlisted: "Item has been removed from the shop.",
        unlistedAt: "The item was removed from the shop on {{ at }}",
      },
      packages: {
        title: "Product information",
        upc: "UPC/EAN number",
        hsCode: "HS code",
        weight: "Weight",
        weightInKg: "{{number}} kg",
        weightInGramm: "{{number}} g",
        size: "Size",
        sizeUnit: "{{number}} mm",
        numberInCase: "Number in the case",
        numberInCaseUnit: "{{number}} unit",
        numberInCaseInformation:
          "Indicates the unit quantity in the manufacturer's outer carton. If ordered in full case quantities, we endeavour to deliver in unopened original outer cartons.",
        typeOfPackaging: "Type of packaging",
        notSpecified: "Not specified",
        suggestedRetailPrice: "SRP",
        suggestedRetailPriceInformation: "Suggested retail price",
        packagingTypes: {
          [ProductSummaryPackagingTypeEnum.BlisterCard]: "Blister card",
          [ProductSummaryPackagingTypeEnum.Can]: "Can",
          [ProductSummaryPackagingTypeEnum.Cardboard]: "Cardboard",
          [ProductSummaryPackagingTypeEnum.CardboardPolystyrene]: "Cardboard, Styropor",
          [ProductSummaryPackagingTypeEnum.FullBlister]: "Full blister",
          [ProductSummaryPackagingTypeEnum.PolybagFoil]: "Polybag",
          [ProductSummaryPackagingTypeEnum.PrintedProducts]: "Print",
          [ProductSummaryPackagingTypeEnum.TradingCards]: "Cardboard",
          [ProductSummaryPackagingTypeEnum.Unknown]: "k.A.",
          [ProductSummaryPackagingTypeEnum.WindowBox]: "Window box",
          [ProductSummaryPackagingTypeEnum.WithoutPackaging]: "Without packaging",
        },
      },
    },

    customerOrder: {
      noDataFound: "No orders available with this item.",
      noDataFoundFiltered: "No orders found that match your search criteria.",
      searchPlaceholder: "Search orders (at least 3 characters)",
      statusFilterLabelAll: "All orders",
      endOfTableTitle: "Not found what you are looking for?",
      endOfTableSubtitle: "Refine your results with the search filter and sorting!",
      filter: {
        title: "Sort",
      },
      labels: {
        orderNumber: "Order",
        orderDate: "Ordered on",
        status: "Status",
        quantity: "Quantity",
        acceptedValues: "Confirmed quantity",
        pricePerUnit: "Unit price",
        discount: "Discount",
        total: "Total amount",
        customerItemReference: "Item reference",
        customerOrderCustomerReference: "Order reference",
      },
    },

    salesOrders: {
      noDataFound: "No confirmed orders available with this item",
      noDataFoundFiltered: "No confirmed orders found that match your search criteria.",
      searchPlaceholder: "Search orders (at least 3 characters)",
      statusFilterLabelAll: "All orders",
      endOfTableTitle: "Not found what you are looking for?",
      endOfTableSubtitle: "Refine your results with the search filter and sorting!",
      filter: {
        title: "Sort",
      },
      noOrderNumber: "No Order-No available",
      labels: {
        orderNumber: "Order",
        confirmationDate: "Confirmation date",
        status: "Status",
        availability: "ETA",
        quantity: "Quantity",
        quantitValue: "{{quantity}} Pieces",
        shipped: "Item shipped",
        pricePerUnit: "Unit price",
        discount: "Discount",
        total: "Total amount",
        customerItemReference: "Item reference",
        customerReference: "Order reference",
      },
    },

    invoiceItems: {
      searchPlaceholder: "Search invoices (at least 3 characters)",
      noDataFound: "No invoices available with this item.",
      noDataFoundFiltered: "No invoices found that match your search criteria.",
      endOfTableTitle: "Not found what you are looking for?",
      endOfTableSubtitle: "Refine your results with the search filter and sorting!",
      filter: {
        title: "Sort",
      },
      labels: {
        invoiceDate: "Date",
        invoiceNumber: "Invoice",
        invoiceTotal: "Amount",
        customerOrderNumber: "Order",
        originCountry: "OC",
        tariffNumber: "HS Code",
        quantity: "Quantity",
        unitPrice: "Unit price",
        discount: "Discount",
        discountedUnitPrice: "Final price",
        total: "Total price",
        customerItemReference: "Item reference",
        customerReference: "Order reference",
      },
    },

    productClaims: {
      searchPlaceholder: "Search claims (at least 3 characters)",
      noDataFound: "No claims available for this item.",
      noDataFoundFiltered: "No claims that match your search criteria.",
      endOfTableTitle: "Not found what you are looking for?",
      endOfTableSubtitle: "Refine your results with the search filter and sorting!",
      filter: {
        title: "Sort",
      },
      labels: {
        createdAt: "Date",
        claimNumber: "Claim no.",
        invoiceNumber: "Invoice no.",
        reason: "Reason",
        status: "Status",
      },
    },
  },
};
