import { useQuery } from "@tanstack/react-query";

import { useRestAPI } from "../../useRestAPI";

export const GetProductClaimsOverviewQueryKey = "GetProductClaimsOverviewOfCurrentRetailer";

export const useGetProductClaimsOverviewQuery = () => {
  const api = useRestAPI();

  return useQuery({
    queryKey: [GetProductClaimsOverviewQueryKey],
    queryFn: () => api.dashboard.getProductClaimsOverviewOfCurrentRetailer(),
  });
};
