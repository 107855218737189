/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreOverview } from './StoreOverview';
import {
    StoreOverviewFromJSON,
    StoreOverviewFromJSONTyped,
    StoreOverviewToJSON,
} from './StoreOverview';

/**
 * 
 * @export
 * @interface StoreProfileDraft
 */
export interface StoreProfileDraft {
    /**
     * 
     * @type {string}
     * @memberof StoreProfileDraft
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof StoreProfileDraft
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StoreProfileDraft
     */
    updatedAt?: Date;
    /**
     * 
     * @type {StoreOverview}
     * @memberof StoreProfileDraft
     */
    store: StoreOverview;
}

/**
 * Check if a given object implements the StoreProfileDraft interface.
 */
export function instanceOfStoreProfileDraft(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "store" in value;

    return isInstance;
}

export function StoreProfileDraftFromJSON(json: any): StoreProfileDraft {
    return StoreProfileDraftFromJSONTyped(json, false);
}

export function StoreProfileDraftFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfileDraft {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'store': StoreOverviewFromJSON(json['store']),
    };
}

export function StoreProfileDraftToJSON(value?: StoreProfileDraft | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'store': StoreOverviewToJSON(value.store),
    };
}

