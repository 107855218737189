import { ProductSummaryPackagingTypeEnum } from "~/api/openapi/models/ProductSummary";

export const ProductsPageDE = {
  pageTitle: "Meine Produkte",
  title: "Meine Produkte ({{ count }})",
  item_one: "{{count}} Stück",
  item_other: "{{count}} Stück",
  filter: {
    title: "Filtern & Sortieren",
    manufacturerTitle: "Hersteller",
    manufacturerSearch: "Nach Hersteller filtern",
    typesTitle: "Produkttypen",
    typesSearch: "Nach Produkttypen filtern",
    searchPlaceholder: "Produkte durchsuchen (min. 3 Zeichen)",
    manufacturerSelectAll: "Alle Hersteller",
    productTypeSelectAll: "Alle Produkttypen",
    onlyOpen: "Nur Produkte in laufenden Bestellungen anzeigen",
    onlyOpenSegmentOn: "Laufende Bestellungen",
    onlyOpenSegmentOff: "Alle Bestellungen",
    nothingFound: "Keine Ergebnisse gefunden",
  },
  noProductsFound: "Keine Produkte gefunden, die deinen Suchkriterien entsprechen.",
  noProductsAvailable: "Noch keine Produkte vorhanden.",
  sort: {
    product: "Artikel",
    productManufacturer: "Hersteller",
    typeAndTheme: "Produkttyp",
    customerOrder: "Bisher bestellt",
    lastRequestedAt: "Zuletzt bestellt",
    salesOrder: "Bisher bestätigt",
    status: "Bearbeitungsstatus",
  },
  table: {
    head: {
      article: "Artikel",
      manufacturer: "Hersteller",
      type: "Produkttyp",
      lastRequested: "Zuletzt bestellt",
      customerOrder: "Bisher bestellt",
      salesOrder: "Bisher bestätigt",
      processingStatus: "Bearbeitungsstatus",
    },
    body: {
      customerOrders_one: "{{count}} Bestellung für {{orderValue}}",
      customerOrders_other: "{{count}} Bestellungen für {{orderValue}}",
      salesOrders_one: "{{count}} Bestätigung für {{orderValue}}",
      salesOrders_other: "{{count}} Bestätigungen für {{orderValue}}",
      moreEntries_one: "+ {{count}} weiterer",
      moreEntries_other: "+ {{ count }} weitere",
      status: {
        outstanding: "Offen",
        processing: "In Bearbeitung",
        shipped: "Ausgeliefert",
        inClaim: "In Reklamation",
      },
    },
  },
  card: {
    orderedSoFar: "Bisher bestellt: <0>{{count}}</0>",
    confirmedSoFar: "Bisher bestätigt: <0>{{count}}</0>",
  },
  detail: {
    title: "Produkt: {{articleNumber}}",
    articleNumber: "Art. Nr.: {{ articleNumber }}",
    descriptionTitle: "Beschreibung",
    priceTitle: "Preisinformation",
    customerOrdersTabTitle: "Bestellungen",
    salesOrderTabTitle: "Bestätigte Bestellungen",
    invoiceItemsTabTitle: "Rechnungen",
    productClaimsTabTitle: "Reklamationen",
    downloadImages: "Bilder speichern",
    salesOrderAvailability: {
      tooltip: "Alle Rückstände des Artikels anzeigen",
      linkText_zero: "{{count}} Stück ({{totalAmount}}) in Rückstand",
      linkText_one: "{{count}} Stück ({{totalAmount}}) in Rückstand",
      linkText_other: "{{count}} Stück ({{totalAmount}}) in Rückstand",
      noData: "Keine Rückstände vorhanden",
    },
    productInformation: {
      descriptionLabel: "Beschreibung",
      productInformationLabel: "Produktinformationen",
      price: {
        title: "Aktuelle Preisinformationen",
        priceIncl: "Preis inkl.",
        amountAndDiscount: "{{discount}} Rabatt",
        basePrice: "Basispreis",
        plusFreightSurcharge: "zzgl. Frachtkostenzuschlag",
        noPrice: "Aktuell ist kein Preis verfügbar.",
      },
      reorderButton: "Artikel nachbestellen",
      scalePrices: {
        title: "Staffelpreise",
        fromQuantity: "ab {{quantity}} Stück:",
        unitPrice: "Einzelpreis",
      },
      unlisted: {
        title: "Artikel ausverkauft",
        unlisted: "Artikel wurde aus dem Shop entfernt.",
        unlistedAt: "Der Artikel wurde am {{ at }} aus dem Shop entfernt.",
      },
      packages: {
        title: "Produktinformationen",
        upc: "UPC/EAN-Nummer",
        hsCode: "HS-Code",
        weight: "Gewicht",
        weightInKg: "{{number}} kg",
        weightInGramm: "{{number}} g",
        size: "Größe",
        sizeUnit: "{{number}} mm",
        numberInCase: "Anzahl im Case",
        numberInCaseUnit: "{{number}} Stück",
        numberInCaseInformation:
          "Gibt die Stückmenge im Hersteller-Umkarton an. Sollte in vollen Case-Mengen bestellt werden, sind wir bemüht, in ungeöffneten Original-Umkartons zu liefern.",
        typeOfPackaging: "Verpackungsart",
        notSpecified: "Keine Angaben",
        suggestedRetailPrice: "EVP",
        suggestedRetailPriceInformation: "Empfohlener Verkaufspreis",
        packagingTypes: {
          [ProductSummaryPackagingTypeEnum.BlisterCard]: "Blisterkarte",
          [ProductSummaryPackagingTypeEnum.Can]: "Dose",
          [ProductSummaryPackagingTypeEnum.Cardboard]: "Karton",
          [ProductSummaryPackagingTypeEnum.CardboardPolystyrene]: "Karton, Styropor",
          [ProductSummaryPackagingTypeEnum.FullBlister]: "Vollblister",
          [ProductSummaryPackagingTypeEnum.PolybagFoil]: "Polybag",
          [ProductSummaryPackagingTypeEnum.PrintedProducts]: "Print",
          [ProductSummaryPackagingTypeEnum.TradingCards]: "Karton",
          [ProductSummaryPackagingTypeEnum.Unknown]: "k.A.",
          [ProductSummaryPackagingTypeEnum.WindowBox]: "Fensterbox",
          [ProductSummaryPackagingTypeEnum.WithoutPackaging]: "Ohne Verpackung",
        },
      },
    },

    customerOrder: {
      noDataFound: "Keine Bestellungen mit diesem Artikel vorhanden.",
      noDataFoundFiltered: "Keine Bestellungen gefunden, die deinen Suchkriterien entsprechen.",
      searchPlaceholder: "Bestellungen durchsuchen (min. 3 Zeichen)",
      statusFilterLabelAll: "Alle Bestellungen",
      endOfTableTitle: "Nicht gefunden wonach du suchst?",
      endOfTableSubtitle: "Verfeinere deine Ergebnisse mit dem Suchfilter und der Sortierung!",
      filter: {
        title: "Sortieren",
      },
      labels: {
        orderNumber: "Bestellung",
        orderDate: "Bestellt am",
        status: "Status",
        quantity: "Menge",
        acceptedValues: "Bestätigte Menge",
        pricePerUnit: "Einzelpreis",
        discount: "Rabatt",
        total: "Gesamtsumme",
        customerItemReference: "Artikelreferenz",
        customerOrderCustomerReference: "Bestellreferenz",
      },
    },

    salesOrders: {
      noDataFound: "Keine bestätigten Bestellungen mit diesem Artikel vorhanden.",
      noDataFoundFiltered:
        "Keine bestätigten Bestellungen gefunden, die deinen Suchkriterien entsprechen.",
      searchPlaceholder: "Bestellungen durchsuchen (min. 3 Zeichen)",
      statusFilterLabelAll: "Alle Bestellungen",
      endOfTableTitle: "Nicht gefunden wonach du suchst?",
      endOfTableSubtitle: "Verfeinere deine Ergebnisse mit dem Suchfilter und der Sortierung!",
      filter: {
        title: "Sortieren",
      },
      noOrderNumber: "Keine Bestell-Nr. vorhanden",
      labels: {
        orderNumber: "Bestellung",
        confirmationDate: "Bestätigungsdatum",
        status: "Status",
        availability: "Vsl. Verfügbarkeit",
        quantity: "Menge",
        quantitValue: "{{quantity}} Stück",
        shipped: "Ausgelieferte Artikel",
        pricePerUnit: "Einzelpreis",
        discount: "Rabatt",
        total: "Gesamtsumme",
        customerItemReference: "Artikelreferenz",
        customerReference: "Bestellreferenz",
      },
    },

    invoiceItems: {
      noDataFound: "Keine Rechnungen mit diesem Artikel vorhanden.",
      noDataFoundFiltered: "Keine Rechnungen gefunden, die deinen Suchkriterien entsprechen.",
      searchPlaceholder: "Rechnungen durchsuchen (min. 3 Zeichen)",
      endOfTableTitle: "Nicht gefunden wonach du suchst?",
      endOfTableSubtitle: "Verfeinere deine Ergebnisse mit dem Suchfilter und der Sortierung!",
      filter: {
        title: "Sortieren",
      },
      labels: {
        invoiceDate: "Datum",
        invoiceNumber: "Rechnung",
        invoiceTotal: "Betrag",
        customerOrderNumber: "Bestellung",
        originCountry: "Herk.-L.",
        tariffNumber: "HS-Code",
        quantity: "Menge",
        unitPrice: "Einzelpreis",
        discount: "Rabatt",
        discountedUnitPrice: "Endpreis",
        total: "Gesamtpreis",
        customerItemReference: "Artikelreferenz",
        customerReference: "Bestellreferenz",
      },
    },

    productClaims: {
      searchPlaceholder: "Reklamationen durchsuchen (min. 3 Zeichen)",
      noDataFound: "Keine Reklamationen zu diesem Artikel vorhanden.",
      noDataFoundFiltered: "Keine Reklamationen gefunden, die deinen Suchkriterien entsprechen.",
      endOfTableTitle: "Nicht gefunden wonach du suchst?",
      endOfTableSubtitle: "Verfeinere deine Ergebnisse mit dem Suchfilter und der Sortierung!",
      filter: {
        title: "Sortieren",
      },
      labels: {
        createdAt: "Datum",
        claimNumber: "Rekl.Nr.",
        invoiceNumber: "Rechn.Nr.",
        reason: "Grund",
        status: "Status",
      },
    },
  },
};
