import { useMemo } from "react";
import { useAuth } from "react-oidc-context";

import { HTTPHeaders } from "./openapi";

export const useAuthHeader = (): HTTPHeaders => {
  const auth = useAuth();

  return useMemo(() => {
    if (!auth.user?.access_token) {
      return {} as HTTPHeaders;
    }

    return {
      Authorization: `Bearer ${auth.user?.access_token}`,
    };
  }, [auth.user?.access_token]);
};
