import { Box, Group, MantineColor, Text, Tooltip } from "@mantine/core";
import clsx from "clsx";
import React, { ReactNode, RefObject } from "react";

import { SortDirection } from "~/api/openapi";

import { getColumnPaddings } from "../helper/getColumnPaddings";
import { DataTableColumn } from "../interface/DataTableColumn";
import { TableSortIcon } from "./TableSortIcon/TableSortIcon";

import { SortBy } from "~/common/interface/SortBy";
import { getNextArrayValue } from "~/helper/Array/getNextArrayValue";
import styles from "./TableHeader.module.css";

const SortDirections = [SortDirection.Asc, SortDirection.Desc, undefined];

export interface TableHeaderProps {
  headerRef?: RefObject<HTMLDivElement>;
  columns: DataTableColumn[];
  sortBy: SortBy | undefined;
  backgroundColor: MantineColor | undefined;
  columnGap: number;
  onSortChange?: (columnId: string, direction: SortDirection | undefined) => void;
}

const TableHeader: React.FC<TableHeaderProps> = (props) => {
  const { columns, sortBy, backgroundColor, columnGap, headerRef, onSortChange } = props;

  return (
    <Box className={styles.header} ref={headerRef}>
      {columns.map((column, index) => {
        const headerContent: ReactNode = column.header;

        const hasHeaderContent =
          headerContent !== null &&
          headerContent !== undefined &&
          (typeof headerContent !== "string" || headerContent.trim() !== "");

        const sortDirection = sortBy?.[column.id ?? column.accessor];

        const canSort = !column.disableSort && onSortChange !== undefined;

        const handleHeaderClick = () => {
          if (!canSort) {
            return;
          }

          onSortChange?.(
            column.id ?? column.accessor,
            getNextArrayValue(SortDirections, sortDirection),
          );
        };

        const { pl, pr } = getColumnPaddings({
          column,
          index,
          columnCount: columns.length,
          columnGap,
        });

        const testIdPrefix = column.testId ?? column.accessor;

        return (
          <Tooltip
            key={column.id ?? column.accessor}
            disabled={!hasHeaderContent || column.disableHeaderTooltip}
            label={headerContent}
            position="top-start"
          >
            <Group
              justify="left"
              className={clsx(styles.headerContent, canSort && styles.clickable)}
              onClick={handleHeaderClick}
              style={{ backgroundColor }}
              wrap="nowrap"
              pr={pr}
              pl={pl}
              py={4}
              data-testid="datatable-header"
              role="columnheader"
            >
              <Text
                lineClamp={1}
                className={styles.headerLabel}
                data-testid={`${testIdPrefix}-header`}
              >
                {headerContent}
              </Text>
              <TableSortIcon
                testId={column.testId ?? column.accessor}
                isSorted={sortDirection !== undefined}
                isSortedDesc={sortDirection === SortDirection.Desc}
              />
            </Group>
          </Tooltip>
        );
      })}
    </Box>
  );
};

TableHeader.displayName = "TableHeader";

export { TableHeader };
