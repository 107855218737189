/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactPermissions
 */
export interface ContactPermissions {
    /**
     * 
     * @type {boolean}
     * @memberof ContactPermissions
     */
    emailAllowed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactPermissions
     */
    phoneAllowed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactPermissions
     */
    smsAllowed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContactPermissions
     */
    whatsappAllowed: boolean;
}

/**
 * Check if a given object implements the ContactPermissions interface.
 */
export function instanceOfContactPermissions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "emailAllowed" in value;
    isInstance = isInstance && "phoneAllowed" in value;
    isInstance = isInstance && "smsAllowed" in value;
    isInstance = isInstance && "whatsappAllowed" in value;

    return isInstance;
}

export function ContactPermissionsFromJSON(json: any): ContactPermissions {
    return ContactPermissionsFromJSONTyped(json, false);
}

export function ContactPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAllowed': json['emailAllowed'],
        'phoneAllowed': json['phoneAllowed'],
        'smsAllowed': json['smsAllowed'],
        'whatsappAllowed': json['whatsappAllowed'],
    };
}

export function ContactPermissionsToJSON(value?: ContactPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailAllowed': value.emailAllowed,
        'phoneAllowed': value.phoneAllowed,
        'smsAllowed': value.smsAllowed,
        'whatsappAllowed': value.whatsappAllowed,
    };
}

