import { QueryClient } from "@tanstack/react-query";

import { addBreadcrumb } from "@sentry/react";
import { composeResponseError } from "./api/composeResponseError";
import { ENV } from "./env";

const retryableStatusCodes = [
  500, // Internal Server Error
  502, // Bad Gateway
  503, // Service Unavailable
  504, // Gateway Timeout
  408, // Request Timeout
  429, // Too Many Requests
  444, // Connection closed without response
];

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 15000,
      retry(failureCount, error) {
        // We dont need retries in local development
        if (ENV.mode === "development") return false;

        const responseError = composeResponseError(error);
        const status = responseError?.status;

        // If status is not in provided retryable list we don't retry queries
        if (status && !retryableStatusCodes.includes(status)) return false;

        addBreadcrumb({
          category: "queryClient",
          message: `Retrying request ${responseError?.url}. Retry #${failureCount}`,
          level: "info",
        });

        // We only retry 3 times
        return failureCount < 3;
      },
    },
  },
});
