/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PagedAndSyncedResultProductOrderStatusSummary,
  SortDirection,
} from '../models';
import {
    PagedAndSyncedResultProductOrderStatusSummaryFromJSON,
    PagedAndSyncedResultProductOrderStatusSummaryToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface GetProductOrderStatusSummariesOfCurrentRetailerRequest {
    offset?: number;
    limit?: number;
    onlyOpen?: boolean;
    manufacturer?: Array<number>;
    productType?: Array<number>;
    fulltextQuery?: string;
    sortByProductNumber?: SortDirection;
    sortByProductManufacturer?: SortDirection;
    sortByProductType?: SortDirection;
    sortByRequestedQuantity?: SortDirection;
    sortByAcceptedQuantity?: SortDirection;
    sortByLastRequestedAt?: SortDirection;
}

/**
 * 
 */
export class ProductOrderStatusResourceApi extends runtime.BaseAPI {

    /**
     */
    async getProductOrderStatusSummariesOfCurrentRetailerRaw(requestParameters: GetProductOrderStatusSummariesOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedAndSyncedResultProductOrderStatusSummary>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.onlyOpen !== undefined) {
            queryParameters['onlyOpen'] = requestParameters.onlyOpen;
        }

        if (requestParameters.manufacturer) {
            queryParameters['manufacturer'] = requestParameters.manufacturer;
        }

        if (requestParameters.productType) {
            queryParameters['productType'] = requestParameters.productType;
        }

        if (requestParameters.fulltextQuery !== undefined) {
            queryParameters['fulltextQuery'] = requestParameters.fulltextQuery;
        }

        if (requestParameters.sortByProductNumber !== undefined) {
            queryParameters['sortByProductNumber'] = requestParameters.sortByProductNumber;
        }

        if (requestParameters.sortByProductManufacturer !== undefined) {
            queryParameters['sortByProductManufacturer'] = requestParameters.sortByProductManufacturer;
        }

        if (requestParameters.sortByProductType !== undefined) {
            queryParameters['sortByProductType'] = requestParameters.sortByProductType;
        }

        if (requestParameters.sortByRequestedQuantity !== undefined) {
            queryParameters['sortByRequestedQuantity'] = requestParameters.sortByRequestedQuantity;
        }

        if (requestParameters.sortByAcceptedQuantity !== undefined) {
            queryParameters['sortByAcceptedQuantity'] = requestParameters.sortByAcceptedQuantity;
        }

        if (requestParameters.sortByLastRequestedAt !== undefined) {
            queryParameters['sortByLastRequestedAt'] = requestParameters.sortByLastRequestedAt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/product-order-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedAndSyncedResultProductOrderStatusSummaryFromJSON(jsonValue));
    }

    /**
     */
    async getProductOrderStatusSummariesOfCurrentRetailer(requestParameters: GetProductOrderStatusSummariesOfCurrentRetailerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedAndSyncedResultProductOrderStatusSummary> {
        const response = await this.getProductOrderStatusSummariesOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
