/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface PaymentTerms
 */
export interface PaymentTerms {
    /**
     * 
     * @type {PaymentMethod}
     * @memberof PaymentTerms
     */
    paymentMethod: PaymentMethod;
    /**
     * 
     * @type {number}
     * @memberof PaymentTerms
     */
    paymentDueDays: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentTerms
     */
    cashDiscount: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentTerms
     */
    cashDiscountDays: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentTerms
     */
    freightAllowance: number;
}

/**
 * Check if a given object implements the PaymentTerms interface.
 */
export function instanceOfPaymentTerms(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "paymentMethod" in value;
    isInstance = isInstance && "paymentDueDays" in value;
    isInstance = isInstance && "cashDiscount" in value;
    isInstance = isInstance && "cashDiscountDays" in value;
    isInstance = isInstance && "freightAllowance" in value;

    return isInstance;
}

export function PaymentTermsFromJSON(json: any): PaymentTerms {
    return PaymentTermsFromJSONTyped(json, false);
}

export function PaymentTermsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTerms {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentMethod': PaymentMethodFromJSON(json['paymentMethod']),
        'paymentDueDays': json['paymentDueDays'],
        'cashDiscount': json['cashDiscount'],
        'cashDiscountDays': json['cashDiscountDays'],
        'freightAllowance': json['freightAllowance'],
    };
}

export function PaymentTermsToJSON(value?: PaymentTerms | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentMethod': PaymentMethodToJSON(value.paymentMethod),
        'paymentDueDays': value.paymentDueDays,
        'cashDiscount': value.cashDiscount,
        'cashDiscountDays': value.cashDiscountDays,
        'freightAllowance': value.freightAllowance,
    };
}

