import { useTranslation } from "react-i18next";

export type HeoLocale = "de_DE" | "en_US" | "es_ES" | "fr_FR" | "it_IT" | "pl_PL";

export const useHeoLocale = (): HeoLocale => {
  const { i18n } = useTranslation();

  switch (i18n.language) {
    case "de":
      return "de_DE";
    case "en":
      return "en_US";
    case "es":
      return "es_ES";
    case "fr":
      return "fr_FR";
    case "it":
      return "it_IT";
    case "pl":
      return "pl_PL";
    default:
      return "de_DE";
  }
};
