/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeoTransactionNumber } from './HeoTransactionNumber';
import {
    HeoTransactionNumberFromJSON,
    HeoTransactionNumberFromJSONTyped,
    HeoTransactionNumberToJSON,
} from './HeoTransactionNumber';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface CreditNoteSummary
 */
export interface CreditNoteSummary {
    /**
     * 
     * @type {HeoTransactionNumber}
     * @memberof CreditNoteSummary
     */
    creditNoteNumber: HeoTransactionNumber;
    /**
     * 
     * @type {Date}
     * @memberof CreditNoteSummary
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof CreditNoteSummary
     */
    amount: number;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof CreditNoteSummary
     */
    refundMethod: PaymentMethod;
    /**
     * 
     * @type {Date}
     * @memberof CreditNoteSummary
     */
    refundedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CreditNoteSummary
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreditNoteSummary
     */
    hasDocument: boolean;
}

/**
 * Check if a given object implements the CreditNoteSummary interface.
 */
export function instanceOfCreditNoteSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "creditNoteNumber" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "refundMethod" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "hasDocument" in value;

    return isInstance;
}

export function CreditNoteSummaryFromJSON(json: any): CreditNoteSummary {
    return CreditNoteSummaryFromJSONTyped(json, false);
}

export function CreditNoteSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditNoteSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creditNoteNumber': HeoTransactionNumberFromJSON(json['creditNoteNumber']),
        'createdAt': (new Date(json['createdAt'])),
        'amount': json['amount'],
        'refundMethod': PaymentMethodFromJSON(json['refundMethod']),
        'refundedAt': !exists(json, 'refundedAt') ? undefined : (new Date(json['refundedAt'])),
        'description': json['description'],
        'hasDocument': json['hasDocument'],
    };
}

export function CreditNoteSummaryToJSON(value?: CreditNoteSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creditNoteNumber': HeoTransactionNumberToJSON(value.creditNoteNumber),
        'createdAt': (value.createdAt.toISOString()),
        'amount': value.amount,
        'refundMethod': PaymentMethodToJSON(value.refundMethod),
        'refundedAt': value.refundedAt === undefined ? undefined : (value.refundedAt.toISOString()),
        'description': value.description,
        'hasDocument': value.hasDocument,
    };
}

