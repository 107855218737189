import { ActionIcon, Group, Text } from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { useResponsiveMode } from "~/hooks/ui/useResponsiveMode";

type Borders = {
  min: number;
  max: number;
};

type YearSelectorProps = {
  selectedValue: number;
  borders: Borders;
  handleNext: () => void;
  handlePrevious: () => void;
};
const YearSelector: React.FC<YearSelectorProps> = (props) => {
  const { selectedValue, borders, handleNext, handlePrevious } = props;

  const { isMobile } = useResponsiveMode();

  return (
    <Group wrap="nowrap" justify="right">
      <ActionIcon
        variant="outline"
        onClick={handlePrevious}
        disabled={selectedValue === borders.min}
        size={isMobile ? "lg" : "md"}
      >
        <IconChevronLeft />
      </ActionIcon>
      <Text fw={700}>{selectedValue}</Text>
      <ActionIcon
        variant="outline"
        onClick={handleNext}
        disabled={selectedValue === borders.max}
        size={isMobile ? "lg" : "md"}
      >
        <IconChevronRight />
      </ActionIcon>
    </Group>
  );
};

export { YearSelector };
