import { showNotification } from "@mantine/notifications";
import { Translation } from "react-i18next";

import { composeResponseError } from "~/api/composeResponseError";

export function globalErrorHandler(error: unknown) {
  const responseError = composeResponseError(error);

  // TODO: Evaluate if we really need a global error handler or if we should handle errors everywhere individually
  // We only handle 500 errors globally
  if (!responseError || responseError.status < 500) {
    return;
  }

  let errorMessage: React.ReactNode | string = (
    <Translation>{(t) => <div>{t("errors.unknownError")}</div>}</Translation>
  );

  if (error instanceof Error) {
    errorMessage = error.message;
  }

  showNotification({
    id: "global-fallback-error",
    title: <Translation>{(t) => <div>{t("errors.unknownErrorTitle")}</div>}</Translation>,
    message: errorMessage,
    autoClose: 6000,
    color: "red",
  });
}
