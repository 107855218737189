/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ActivateApiAccessForCurrentRetailerCommand,
  ApiAccess,
  UpdatePublicationUrlsForCurrentRetailerCommand,
} from '../models';
import {
    ActivateApiAccessForCurrentRetailerCommandFromJSON,
    ActivateApiAccessForCurrentRetailerCommandToJSON,
    ApiAccessFromJSON,
    ApiAccessToJSON,
    UpdatePublicationUrlsForCurrentRetailerCommandFromJSON,
    UpdatePublicationUrlsForCurrentRetailerCommandToJSON,
} from '../models';

export interface ActivateApiAccessRequest {
    activateApiAccessForCurrentRetailerCommand: ActivateApiAccessForCurrentRetailerCommand;
}

export interface UpdatePublicationUrlsRequest {
    updatePublicationUrlsForCurrentRetailerCommand: UpdatePublicationUrlsForCurrentRetailerCommand;
}

/**
 * 
 */
export class ApiAccessResourceApi extends runtime.BaseAPI {

    /**
     */
    async activateApiAccessRaw(requestParameters: ActivateApiAccessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAccess>> {
        if (requestParameters.activateApiAccessForCurrentRetailerCommand === null || requestParameters.activateApiAccessForCurrentRetailerCommand === undefined) {
            throw new runtime.RequiredError('activateApiAccessForCurrentRetailerCommand','Required parameter requestParameters.activateApiAccessForCurrentRetailerCommand was null or undefined when calling activateApiAccess.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/retailerapi/access/activate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivateApiAccessForCurrentRetailerCommandToJSON(requestParameters.activateApiAccessForCurrentRetailerCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAccessFromJSON(jsonValue));
    }

    /**
     */
    async activateApiAccess(requestParameters: ActivateApiAccessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAccess> {
        const response = await this.activateApiAccessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getApiAccessRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailerapi/access`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAccessFromJSON(jsonValue));
    }

    /**
     */
    async getApiAccess(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAccess> {
        const response = await this.getApiAccessRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updatePublicationUrlsRaw(requestParameters: UpdatePublicationUrlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAccess>> {
        if (requestParameters.updatePublicationUrlsForCurrentRetailerCommand === null || requestParameters.updatePublicationUrlsForCurrentRetailerCommand === undefined) {
            throw new runtime.RequiredError('updatePublicationUrlsForCurrentRetailerCommand','Required parameter requestParameters.updatePublicationUrlsForCurrentRetailerCommand was null or undefined when calling updatePublicationUrls.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/retailerapi/access`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePublicationUrlsForCurrentRetailerCommandToJSON(requestParameters.updatePublicationUrlsForCurrentRetailerCommand),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAccessFromJSON(jsonValue));
    }

    /**
     */
    async updatePublicationUrls(requestParameters: UpdatePublicationUrlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAccess> {
        const response = await this.updatePublicationUrlsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
