import { Box, Group, Skeleton } from "@mantine/core";

import loadingSkeletonStyles from "./LoadingSkeleton.module.css";

const LoadingSkeleton: React.FC = () => {
  return (
    <>
      <Box
        mt={"xl"}
        ml={"md"}
        mr={"md"}
        style={{
          borderLeft: "2px solid #ccc",
          borderBottom: "2px solid #ccc",
        }}
      >
        <Group>
          <Skeleton className={loadingSkeletonStyles.bar} ml={25} />
          <Skeleton className={loadingSkeletonStyles.bar} ml={30} />
          <Skeleton className={loadingSkeletonStyles.bar} ml={30} />
          <Skeleton className={loadingSkeletonStyles.bar} ml={30} />
        </Group>
      </Box>
      <Group pl={75} mt={20} gap={"xs"}>
        <Skeleton className={loadingSkeletonStyles.legendSmall} />
        <Skeleton className={loadingSkeletonStyles.legend} />
        <Skeleton className={loadingSkeletonStyles.legendSmall} />
        <Skeleton className={loadingSkeletonStyles.legend} />
        <Skeleton className={loadingSkeletonStyles.legendSmall} />
        <Skeleton className={loadingSkeletonStyles.legend} />
        <Skeleton className={loadingSkeletonStyles.legendSmall} />
        <Skeleton className={loadingSkeletonStyles.legend} />
      </Group>
    </>
  );
};

export { LoadingSkeleton };
