/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoreProfileImageMetaData
 */
export interface StoreProfileImageMetaData {
    /**
     * 
     * @type {string}
     * @memberof StoreProfileImageMetaData
     */
    imageKey: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileImageMetaData
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileImageMetaData
     */
    contentType: string;
}

/**
 * Check if a given object implements the StoreProfileImageMetaData interface.
 */
export function instanceOfStoreProfileImageMetaData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "imageKey" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "contentType" in value;

    return isInstance;
}

export function StoreProfileImageMetaDataFromJSON(json: any): StoreProfileImageMetaData {
    return StoreProfileImageMetaDataFromJSONTyped(json, false);
}

export function StoreProfileImageMetaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfileImageMetaData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imageKey': json['imageKey'],
        'filename': json['filename'],
        'contentType': json['contentType'],
    };
}

export function StoreProfileImageMetaDataToJSON(value?: StoreProfileImageMetaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imageKey': value.imageKey,
        'filename': value.filename,
        'contentType': value.contentType,
    };
}

