/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MiddlewareError,
  ProductPrices,
} from '../models';
import {
    MiddlewareErrorFromJSON,
    MiddlewareErrorToJSON,
    ProductPricesFromJSON,
    ProductPricesToJSON,
} from '../models';

export interface GetProductPricesRequest {
    productNumber: string;
}

/**
 * 
 */
export class ProductPricesResourceApi extends runtime.BaseAPI {

    /**
     */
    async getProductPricesRaw(requestParameters: GetProductPricesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductPrices>>> {
        if (requestParameters.productNumber === null || requestParameters.productNumber === undefined) {
            throw new runtime.RequiredError('productNumber','Required parameter requestParameters.productNumber was null or undefined when calling getProductPrices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/product/{productNumber}/product-prices/`.replace(`{${"productNumber"}}`, encodeURIComponent(String(requestParameters.productNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductPricesFromJSON));
    }

    /**
     */
    async getProductPrices(requestParameters: GetProductPricesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductPrices>> {
        const response = await this.getProductPricesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
