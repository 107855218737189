import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useGetTotalSales } from "~/api/hooks/totalSales/useGetTotalSales";
import { DataTable } from "~/ui/components/DataTable";

import { mapTotalSalesReportDataToListItems } from "./mapTotalSalesDataToListItems";
import { useTotalSalesTableConfig } from "./useTotalSalesTableConfig";

const TotalSalesTable = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "totalSalesDetailsPage.table",
  });

  const { data, isFetching } = useGetTotalSales();

  const totalSalesListItems = useMemo(() => mapTotalSalesReportDataToListItems(data), [data]);

  const { totalSalesListColumns } = useTotalSalesTableConfig();

  return (
    <DataTable
      noDataText={t("noDataText")}
      data={totalSalesListItems}
      columns={totalSalesListColumns}
      isLoading={isFetching}
      tableMinHeight={0}
      rowHeight={40}
    />
  );
};

export { TotalSalesTable };
