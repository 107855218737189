/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeoTransactionNumber } from './HeoTransactionNumber';
import {
    HeoTransactionNumberFromJSON,
    HeoTransactionNumberFromJSONTyped,
    HeoTransactionNumberToJSON,
} from './HeoTransactionNumber';

/**
 * 
 * @export
 * @interface TrackingShipmentProjection
 */
export interface TrackingShipmentProjection {
    /**
     * 
     * @type {HeoTransactionNumber}
     * @memberof TrackingShipmentProjection
     */
    invoiceNumber: HeoTransactionNumber;
    /**
     * 
     * @type {string}
     * @memberof TrackingShipmentProjection
     */
    service: string;
    /**
     * 
     * @type {string}
     * @memberof TrackingShipmentProjection
     */
    trackingNumber: string;
    /**
     * 
     * @type {string}
     * @memberof TrackingShipmentProjection
     */
    trackingLink: string;
}

/**
 * Check if a given object implements the TrackingShipmentProjection interface.
 */
export function instanceOfTrackingShipmentProjection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "invoiceNumber" in value;
    isInstance = isInstance && "service" in value;
    isInstance = isInstance && "trackingNumber" in value;
    isInstance = isInstance && "trackingLink" in value;

    return isInstance;
}

export function TrackingShipmentProjectionFromJSON(json: any): TrackingShipmentProjection {
    return TrackingShipmentProjectionFromJSONTyped(json, false);
}

export function TrackingShipmentProjectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackingShipmentProjection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoiceNumber': HeoTransactionNumberFromJSON(json['invoiceNumber']),
        'service': json['service'],
        'trackingNumber': json['trackingNumber'],
        'trackingLink': json['trackingLink'],
    };
}

export function TrackingShipmentProjectionToJSON(value?: TrackingShipmentProjection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoiceNumber': HeoTransactionNumberToJSON(value.invoiceNumber),
        'service': value.service,
        'trackingNumber': value.trackingNumber,
        'trackingLink': value.trackingLink,
    };
}

