/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerOrderSummary } from './CustomerOrderSummary';
import {
    CustomerOrderSummaryFromJSON,
    CustomerOrderSummaryFromJSONTyped,
    CustomerOrderSummaryToJSON,
} from './CustomerOrderSummary';

/**
 * 
 * @export
 * @interface PagedAndSyncedResultCustomerOrderSummary
 */
export interface PagedAndSyncedResultCustomerOrderSummary {
    /**
     * 
     * @type {Array<CustomerOrderSummary>}
     * @memberof PagedAndSyncedResultCustomerOrderSummary
     */
    content: Array<CustomerOrderSummary>;
    /**
     * 
     * @type {number}
     * @memberof PagedAndSyncedResultCustomerOrderSummary
     */
    totalElements: number;
    /**
     * 
     * @type {number}
     * @memberof PagedAndSyncedResultCustomerOrderSummary
     */
    totalPages: number;
    /**
     * 
     * @type {Date}
     * @memberof PagedAndSyncedResultCustomerOrderSummary
     */
    lastSyncedAt?: Date;
}

/**
 * Check if a given object implements the PagedAndSyncedResultCustomerOrderSummary interface.
 */
export function instanceOfPagedAndSyncedResultCustomerOrderSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "totalElements" in value;
    isInstance = isInstance && "totalPages" in value;

    return isInstance;
}

export function PagedAndSyncedResultCustomerOrderSummaryFromJSON(json: any): PagedAndSyncedResultCustomerOrderSummary {
    return PagedAndSyncedResultCustomerOrderSummaryFromJSONTyped(json, false);
}

export function PagedAndSyncedResultCustomerOrderSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedAndSyncedResultCustomerOrderSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': ((json['content'] as Array<any>).map(CustomerOrderSummaryFromJSON)),
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'lastSyncedAt': !exists(json, 'lastSyncedAt') ? undefined : (new Date(json['lastSyncedAt'])),
    };
}

export function PagedAndSyncedResultCustomerOrderSummaryToJSON(value?: PagedAndSyncedResultCustomerOrderSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': ((value.content as Array<any>).map(CustomerOrderSummaryToJSON)),
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
        'lastSyncedAt': value.lastSyncedAt === undefined ? undefined : (value.lastSyncedAt.toISOString()),
    };
}

