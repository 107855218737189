import { useEffect, useRef } from "react";

export const useOnce = (callback: () => void) => {
  const done = useRef(false);

  useEffect(() => {
    if (!done.current) {
      done.current = true;
      callback();
    }
  }, [callback]);
};
