/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PagedAndSyncedResultProductClaimSummary,
  PagedResultClaimableInvoiceItem,
  ProductClaimDetailProjection,
  ProductClaimProcessingStatus,
  ProductClaimReason,
  SortDirection,
} from '../models';
import {
    PagedAndSyncedResultProductClaimSummaryFromJSON,
    PagedAndSyncedResultProductClaimSummaryToJSON,
    PagedResultClaimableInvoiceItemFromJSON,
    PagedResultClaimableInvoiceItemToJSON,
    ProductClaimDetailProjectionFromJSON,
    ProductClaimDetailProjectionToJSON,
    ProductClaimProcessingStatusFromJSON,
    ProductClaimProcessingStatusToJSON,
    ProductClaimReasonFromJSON,
    ProductClaimReasonToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface CreateProductClaimRequest {
    reason: string;
    preferredSettlement: string;
    description: string;
    invoiceItemId: string;
    serialNumber?: string;
    deliveryDeviation?: number;
    files?: Array<Blob>;
}

export interface GetAttachmentRequest {
    claimId: string;
    attachmentId: string;
}

export interface GetClaimableInvoiceItemsOfCurrentRetailerRequest {
    offset?: number;
    limit?: number;
    sortByHeoItemId?: SortDirection;
    sortByInvoiceCreatedAt?: SortDirection;
    sortByInvoiceNumber?: SortDirection;
    sortByInvoiceTotal?: SortDirection;
    sortByCustomerOrderNumber?: SortDirection;
    sortByProductOriginCountry?: SortDirection;
    sortByProductTariffNumber?: SortDirection;
    sortByProductNumber?: SortDirection;
    sortByQuantity?: SortDirection;
    sortByPricePerUnit?: SortDirection;
    sortByDiscount?: SortDirection;
    sortByDiscountedPricePerUnit?: SortDirection;
    sortByTotal?: SortDirection;
    sortByCustomerItemReference?: SortDirection;
    fulltextQuery?: string;
    createdBetweenFrom?: Date;
    createdBetweenUntil?: Date;
}

export interface GetProductClaimDetailRequest {
    claimId: string;
}

export interface GetProductClaimSummariesOfCurrentRetailerRequest {
    offset?: number;
    limit?: number;
    productNumber?: string;
    processingStatus?: Array<ProductClaimProcessingStatus>;
    fullTextQuery?: string;
    createdBetweenFrom?: Date;
    createdBetweenUntil?: Date;
    reasons?: Array<ProductClaimReason>;
    sortByCreatedAt?: SortDirection;
    sortByNumber?: SortDirection;
    sortByProductNumber?: SortDirection;
    sortByInvoiceNumber?: SortDirection;
    sortByReason?: SortDirection;
    sortByStatus?: SortDirection;
    sortByProcessingStatus?: SortDirection;
    sortByRmaNumber?: SortDirection;
    sortByCreditNoteNumber?: SortDirection;
}

/**
 * 
 */
export class ClaimRessourceApi extends runtime.BaseAPI {

    /**
     */
    async createProductClaimRaw(requestParameters: CreateProductClaimRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reason === null || requestParameters.reason === undefined) {
            throw new runtime.RequiredError('reason','Required parameter requestParameters.reason was null or undefined when calling createProductClaim.');
        }

        if (requestParameters.preferredSettlement === null || requestParameters.preferredSettlement === undefined) {
            throw new runtime.RequiredError('preferredSettlement','Required parameter requestParameters.preferredSettlement was null or undefined when calling createProductClaim.');
        }

        if (requestParameters.description === null || requestParameters.description === undefined) {
            throw new runtime.RequiredError('description','Required parameter requestParameters.description was null or undefined when calling createProductClaim.');
        }

        if (requestParameters.invoiceItemId === null || requestParameters.invoiceItemId === undefined) {
            throw new runtime.RequiredError('invoiceItemId','Required parameter requestParameters.invoiceItemId was null or undefined when calling createProductClaim.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.serialNumber !== undefined) {
            formParams.append('serialNumber', requestParameters.serialNumber as any);
        }

        if (requestParameters.reason !== undefined) {
            formParams.append('reason', requestParameters.reason as any);
        }

        if (requestParameters.preferredSettlement !== undefined) {
            formParams.append('preferredSettlement', requestParameters.preferredSettlement as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.invoiceItemId !== undefined) {
            formParams.append('invoiceItemId', requestParameters.invoiceItemId as any);
        }

        if (requestParameters.deliveryDeviation !== undefined) {
            formParams.append('deliveryDeviation', requestParameters.deliveryDeviation as any);
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('files', element as any);
            })
        }

        const response = await this.request({
            path: `/api/v1/ui/claim/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createProductClaim(requestParameters: CreateProductClaimRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createProductClaimRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getAttachmentRaw(requestParameters: GetAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling getAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling getAttachment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/claim/{claimId}/attachment/{attachmentId}`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))).replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async getAttachment(requestParameters: GetAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getAttachmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getClaimableInvoiceItemsOfCurrentRetailerRaw(requestParameters: GetClaimableInvoiceItemsOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedResultClaimableInvoiceItem>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortByHeoItemId !== undefined) {
            queryParameters['sortByHeoItemId'] = requestParameters.sortByHeoItemId;
        }

        if (requestParameters.sortByInvoiceCreatedAt !== undefined) {
            queryParameters['sortByInvoiceCreatedAt'] = requestParameters.sortByInvoiceCreatedAt;
        }

        if (requestParameters.sortByInvoiceNumber !== undefined) {
            queryParameters['sortByInvoiceNumber'] = requestParameters.sortByInvoiceNumber;
        }

        if (requestParameters.sortByInvoiceTotal !== undefined) {
            queryParameters['sortByInvoiceTotal'] = requestParameters.sortByInvoiceTotal;
        }

        if (requestParameters.sortByCustomerOrderNumber !== undefined) {
            queryParameters['sortByCustomerOrderNumber'] = requestParameters.sortByCustomerOrderNumber;
        }

        if (requestParameters.sortByProductOriginCountry !== undefined) {
            queryParameters['sortByProductOriginCountry'] = requestParameters.sortByProductOriginCountry;
        }

        if (requestParameters.sortByProductTariffNumber !== undefined) {
            queryParameters['sortByProductTariffNumber'] = requestParameters.sortByProductTariffNumber;
        }

        if (requestParameters.sortByProductNumber !== undefined) {
            queryParameters['sortByProductNumber'] = requestParameters.sortByProductNumber;
        }

        if (requestParameters.sortByQuantity !== undefined) {
            queryParameters['sortByQuantity'] = requestParameters.sortByQuantity;
        }

        if (requestParameters.sortByPricePerUnit !== undefined) {
            queryParameters['sortByPricePerUnit'] = requestParameters.sortByPricePerUnit;
        }

        if (requestParameters.sortByDiscount !== undefined) {
            queryParameters['sortByDiscount'] = requestParameters.sortByDiscount;
        }

        if (requestParameters.sortByDiscountedPricePerUnit !== undefined) {
            queryParameters['sortByDiscountedPricePerUnit'] = requestParameters.sortByDiscountedPricePerUnit;
        }

        if (requestParameters.sortByTotal !== undefined) {
            queryParameters['sortByTotal'] = requestParameters.sortByTotal;
        }

        if (requestParameters.sortByCustomerItemReference !== undefined) {
            queryParameters['sortByCustomerItemReference'] = requestParameters.sortByCustomerItemReference;
        }

        if (requestParameters.fulltextQuery !== undefined) {
            queryParameters['fulltextQuery'] = requestParameters.fulltextQuery;
        }

        if (requestParameters.createdBetweenFrom !== undefined) {
            queryParameters['createdBetweenFrom'] = (requestParameters.createdBetweenFrom as any).toISOString();
        }

        if (requestParameters.createdBetweenUntil !== undefined) {
            queryParameters['createdBetweenUntil'] = (requestParameters.createdBetweenUntil as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/claim/claimable-invoice-items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedResultClaimableInvoiceItemFromJSON(jsonValue));
    }

    /**
     */
    async getClaimableInvoiceItemsOfCurrentRetailer(requestParameters: GetClaimableInvoiceItemsOfCurrentRetailerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedResultClaimableInvoiceItem> {
        const response = await this.getClaimableInvoiceItemsOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProductClaimDetailRaw(requestParameters: GetProductClaimDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductClaimDetailProjection>> {
        if (requestParameters.claimId === null || requestParameters.claimId === undefined) {
            throw new runtime.RequiredError('claimId','Required parameter requestParameters.claimId was null or undefined when calling getProductClaimDetail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/claim/{claimId}`.replace(`{${"claimId"}}`, encodeURIComponent(String(requestParameters.claimId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductClaimDetailProjectionFromJSON(jsonValue));
    }

    /**
     */
    async getProductClaimDetail(requestParameters: GetProductClaimDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductClaimDetailProjection> {
        const response = await this.getProductClaimDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProductClaimSummariesOfCurrentRetailerRaw(requestParameters: GetProductClaimSummariesOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedAndSyncedResultProductClaimSummary>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.productNumber !== undefined) {
            queryParameters['productNumber'] = requestParameters.productNumber;
        }

        if (requestParameters.processingStatus) {
            queryParameters['processingStatus'] = requestParameters.processingStatus;
        }

        if (requestParameters.fullTextQuery !== undefined) {
            queryParameters['fullTextQuery'] = requestParameters.fullTextQuery;
        }

        if (requestParameters.createdBetweenFrom !== undefined) {
            queryParameters['createdBetweenFrom'] = (requestParameters.createdBetweenFrom as any).toISOString();
        }

        if (requestParameters.createdBetweenUntil !== undefined) {
            queryParameters['createdBetweenUntil'] = (requestParameters.createdBetweenUntil as any).toISOString();
        }

        if (requestParameters.reasons) {
            queryParameters['reasons'] = requestParameters.reasons;
        }

        if (requestParameters.sortByCreatedAt !== undefined) {
            queryParameters['sortByCreatedAt'] = requestParameters.sortByCreatedAt;
        }

        if (requestParameters.sortByNumber !== undefined) {
            queryParameters['sortByNumber'] = requestParameters.sortByNumber;
        }

        if (requestParameters.sortByProductNumber !== undefined) {
            queryParameters['sortByProductNumber'] = requestParameters.sortByProductNumber;
        }

        if (requestParameters.sortByInvoiceNumber !== undefined) {
            queryParameters['sortByInvoiceNumber'] = requestParameters.sortByInvoiceNumber;
        }

        if (requestParameters.sortByReason !== undefined) {
            queryParameters['sortByReason'] = requestParameters.sortByReason;
        }

        if (requestParameters.sortByStatus !== undefined) {
            queryParameters['sortByStatus'] = requestParameters.sortByStatus;
        }

        if (requestParameters.sortByProcessingStatus !== undefined) {
            queryParameters['sortByProcessingStatus'] = requestParameters.sortByProcessingStatus;
        }

        if (requestParameters.sortByRmaNumber !== undefined) {
            queryParameters['sortByRmaNumber'] = requestParameters.sortByRmaNumber;
        }

        if (requestParameters.sortByCreditNoteNumber !== undefined) {
            queryParameters['sortByCreditNoteNumber'] = requestParameters.sortByCreditNoteNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/claim`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedAndSyncedResultProductClaimSummaryFromJSON(jsonValue));
    }

    /**
     */
    async getProductClaimSummariesOfCurrentRetailer(requestParameters: GetProductClaimSummariesOfCurrentRetailerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedAndSyncedResultProductClaimSummary> {
        const response = await this.getProductClaimSummariesOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
