import { ApiAdvantageType } from "~/ui/pages/ManageApiPage/ActivateApiPage/ApiAdvantage";

import { ManageApiPageDE } from "../de/de.manageApiPage";

export const ManageApiPageEN: typeof ManageApiPageDE = {
  title: "heoGATE - Manage your access for the Retailer API",

  activateApiPage: {
    pageTitle: "Enable API access",
    notYetEnabled:
      "Your account is not yet enabled to use the Retailer API - unlock your access right now.",
    apiAdvantagesTitle: "Use the advantages of the heoGATE Retailer API",
    apiAdvantagesHeaderInfo:
      "heoGATE - our Retailer API, which connects your webshop with the powerful backend of heoVERSE",

    advantages: {
      contentManagement: {
        title: "Simplified maintenance",
        description:
          "You can integrate our products directly into your own website or online shop. Save hours of manual content management and copy&paste.",
      },
      dataQuality: {
        title: "Increased data quality",
        description:
          "All product information, availability and prices are obtained directly and automatically and are always up to date in your online shop.",
      },
      dropshipping: {
        title: "Dropshipping to end customers",
        description:
          "You can sell products directly to end customers without having to buy and store these products yourself beforehand.",
      },
      simpleUsage: {
        title: "Simple & Secure",
        description:
          "Using our API is simple. Use our comprehensive documentation and find solutions for all your use cases. The security of our API is state-of-the-art. And make use of a sandbox for testing purposes.",
      },
    } as Record<ApiAdvantageType, Record<string, string>>,
    apiActivateTitle: "Active the heoGATE Retailer API for your account",
    form: {
      formDescription:
        "To grant you access to our API, we need the URLs of your websites where you intend to publish the data from our API. This ensures that the API data is used lawfully and in accordance with our policies.",
      formTitle: "Websites for API data publication:",
      submitButtonLabel: "Activate API access now",
      addShopUrlButtonLabel: "Add another website",
      shopUrlPlaceholder: "https://exampleshop.de",
      invalidUrl: "Please enter a valid URL. (https://exampleshop.de)",
      userAgreementCheckboxLabel: "I have read the <0>AGB</0><1/> and agree to them.",
      userAgreementNotChecked: "You must accept the T&Cs in order to activate your API access.",
      successMessage: "Your API access has been successfully activated!",
      noUrlsAdded: "Please enter at least one URL.",
      identiclUrlsAdded: "Please do not enter identical URLs.",
    },
  },

  sideInfo: {
    documentationTitle: "Retailer API documentation",
    documentationDescription: [
      "Find the <0>comprehensive documentation for our Retailer API</0>, including <i>Getting Started</i>, a <i>Use Case Guide</i>, a <i>Reference Guide</i> and <i>Swagger UI</i> documentation.<br><br>To access the documentation, you need the credentials for the production environment (<i>Client ID</i> and <i>Client Secret</i>).",
    ],
    postmanTitle: "Quick Start: Postman Collection",
    postmanDescription: [
      "For a quick and easy start with using the Retailer API, you can <0>download a Postman collection here</0>.",
    ],
    supportTitle: "Support",
    supportDescription: [
      "If you have any further questions, our support team will be happy to help you at any time at <0>api@heo.com</0>.",
    ],
    websitesTitle: "Data publication",
    websitesDescription: [
      "You registered the following websites for the publication of data from the Retailer API.<br><br>For details on the use of the data, please refer to our <0>terms and conditions for the Retailer API</0>",
    ],
    changeUrlsButtonLabel: "Change details",

    publicationUrlForm: {
      formTitle: "Change web pages for API data publication",
      userAgreementCheckboxLabel: "I have read and agree to the <0>AGB</0><1/>.",
      userAgreementNotChecked: "You must accept the terms and conditions to change your web pages.",
      shopUrlPlaceholder: "https://beispielshop.de",
      invalidUrl: "Please enter a valid URL.",
      addShopUrlButtonLabel: "Add another website",
      submitButtonLabel: "Save changes",
      cancelButtonLabel: "Cancel",
      submitError: "The URLs could not be saved, please try again.",
    },
  },

  apiDisabled: {
    title: "API access blocked",
    pageTitle: "API access blocked",
    subtitle:
      "Your API access has been disabled by heo. Please contact <0>Customer Service</0> if you would like to continue to have access to the Retailer API.",
    mailSubject: "Request access to the Retailer API",
  },

  apiAccessDisabled: {
    title: "API access disabled",
    pageTitle: "API access disabled",
    subtitle:
      "Your API access has been disabled by heo. Please contact <0>Customer Service</0> if you would like to continue to have access to the Retailer API.",
    mailSubject: "Request access to the Retailer API",
  },

  apiClientManager: {
    pageTitle: "heoGATE - Manage your access for the Retailer API",
    pageDescription:
      "Manage your clients for accessing the production environment and your test instances of the Retailer API.",
    liveClientTitle: "Retailer API production environment",
    liveClientDescription:
      "To access live data, we need the IP address of your production environment. Please note that the API client can only be used with the IP address you specify. <br /> <strong>Important note: Only one API client can be generated per productive environment. </strong>",
    ipAddressPlaceholder: "IP address (IPv4) of your shop (e.g. 172.217.168.206)",
    ipAddressLabel: "IP address",
    liveClientSubmitLabel: "Generate API client for live environment",
    invalidIp: "Please enter a valid IPv4 address.",
    reservedIp:
      "This IP is reserved for private networks and is only visible internally. Please enter the IP address of the component via which the Retailer API is used.",
    clientIpAddress: "Your Retailer API client is registered for IP address",
    clientIpAddresses: "Your Retailer API client is registered for IP addresses",
    clientCreatedAt: "Created on: {{ date }}",
    clientIdLabel: "Client ID",
    clientSecretLabel: "Client Secret",
    deleteLiveClientButtonLabel: "Delete API client",
    deleteLiveClientModalTitle: "Delete API client for production environment",

    deleteLiveClientForm: {
      description:
        "Are you really sure you want to delete the API client with the following Client ID?",
      confirmText: "Please confirm the deletion by entering the Client ID.",
      clientIdLabel: "Client ID",
      clientIdPlaceholder: "Enter Client ID",
      clientIdError: "The Client ID entered does not match",
      warningText:
        "Deleting this API client will result in immediate loss of access to the production environment of the Retailer API and its services. You need to create a new API client to regain access.",
      deleteButtonLabel: "Delete API client irrevocably",
      cancelButtonLabel: "Cancel",
    },

    testClientForm: {
      title: "Testing environments of the Retailer API",
      description:
        "You can generate any number of API clients for your testing environments (sandboxes). These clients are intended exclusively for use in the sandboxes.",
      descriptionLabel: "Description",
      descriptionPlaceholder: "Give your sandbox client a description...",
      createTestClientButtonLabel: "Generate API client for sandbox",
      clientIdColumnLabel: "Client ID",
      clientSecretColumnLabel: "Client Secret",
      createdAtColumnLabel: "Created on",
      noDataText: "You have not yet created any API clients for the sandbox",
      deleteModalTitle: "Delete API client for test instance",
      deleteModalDescription:
        "You are about to delete the API client with the following Client ID for the test instance.",
      deleteModalHint: "The deletion cannot be undone.",
      deleteModalConfirmButtonLabel: "Delete API Client",
      deleteModalCancelButtonLabel: "Cancel",
    },
  },
};
