/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetStoreProfileVisibilityRequest
 */
export interface SetStoreProfileVisibilityRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SetStoreProfileVisibilityRequest
     */
    published: boolean;
}

/**
 * Check if a given object implements the SetStoreProfileVisibilityRequest interface.
 */
export function instanceOfSetStoreProfileVisibilityRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "published" in value;

    return isInstance;
}

export function SetStoreProfileVisibilityRequestFromJSON(json: any): SetStoreProfileVisibilityRequest {
    return SetStoreProfileVisibilityRequestFromJSONTyped(json, false);
}

export function SetStoreProfileVisibilityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetStoreProfileVisibilityRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'published': json['published'],
    };
}

export function SetStoreProfileVisibilityRequestToJSON(value?: SetStoreProfileVisibilityRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'published': value.published,
    };
}

