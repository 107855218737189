/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PagedAndSyncedResultSalesOrderItemAvailabilitySummary } from './PagedAndSyncedResultSalesOrderItemAvailabilitySummary';
import {
    PagedAndSyncedResultSalesOrderItemAvailabilitySummaryFromJSON,
    PagedAndSyncedResultSalesOrderItemAvailabilitySummaryFromJSONTyped,
    PagedAndSyncedResultSalesOrderItemAvailabilitySummaryToJSON,
} from './PagedAndSyncedResultSalesOrderItemAvailabilitySummary';
import type { SalesOrderItemAvailabilityFacets } from './SalesOrderItemAvailabilityFacets';
import {
    SalesOrderItemAvailabilityFacetsFromJSON,
    SalesOrderItemAvailabilityFacetsFromJSONTyped,
    SalesOrderItemAvailabilityFacetsToJSON,
} from './SalesOrderItemAvailabilityFacets';

/**
 * 
 * @export
 * @interface SalesOrderItemAvailabilitySummaryResponse
 */
export interface SalesOrderItemAvailabilitySummaryResponse {
    /**
     * 
     * @type {PagedAndSyncedResultSalesOrderItemAvailabilitySummary}
     * @memberof SalesOrderItemAvailabilitySummaryResponse
     */
    result: PagedAndSyncedResultSalesOrderItemAvailabilitySummary;
    /**
     * 
     * @type {SalesOrderItemAvailabilityFacets}
     * @memberof SalesOrderItemAvailabilitySummaryResponse
     */
    facets: SalesOrderItemAvailabilityFacets;
}

/**
 * Check if a given object implements the SalesOrderItemAvailabilitySummaryResponse interface.
 */
export function instanceOfSalesOrderItemAvailabilitySummaryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "facets" in value;

    return isInstance;
}

export function SalesOrderItemAvailabilitySummaryResponseFromJSON(json: any): SalesOrderItemAvailabilitySummaryResponse {
    return SalesOrderItemAvailabilitySummaryResponseFromJSONTyped(json, false);
}

export function SalesOrderItemAvailabilitySummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesOrderItemAvailabilitySummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': PagedAndSyncedResultSalesOrderItemAvailabilitySummaryFromJSON(json['result']),
        'facets': SalesOrderItemAvailabilityFacetsFromJSON(json['facets']),
    };
}

export function SalesOrderItemAvailabilitySummaryResponseToJSON(value?: SalesOrderItemAvailabilitySummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': PagedAndSyncedResultSalesOrderItemAvailabilitySummaryToJSON(value.result),
        'facets': SalesOrderItemAvailabilityFacetsToJSON(value.facets),
    };
}

