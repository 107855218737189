/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HealthResponseBody
 */
export interface HealthResponseBody {
    /**
     * 
     * @type {string}
     * @memberof HealthResponseBody
     */
    commit: string;
    /**
     * 
     * @type {string}
     * @memberof HealthResponseBody
     */
    build: string;
    /**
     * 
     * @type {number}
     * @memberof HealthResponseBody
     */
    failedMessages: number;
}

/**
 * Check if a given object implements the HealthResponseBody interface.
 */
export function instanceOfHealthResponseBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "commit" in value;
    isInstance = isInstance && "build" in value;
    isInstance = isInstance && "failedMessages" in value;

    return isInstance;
}

export function HealthResponseBodyFromJSON(json: any): HealthResponseBody {
    return HealthResponseBodyFromJSONTyped(json, false);
}

export function HealthResponseBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthResponseBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commit': json['commit'],
        'build': json['build'],
        'failedMessages': json['failedMessages'],
    };
}

export function HealthResponseBodyToJSON(value?: HealthResponseBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commit': value.commit,
        'build': value.build,
        'failedMessages': value.failedMessages,
    };
}

