import { DataTableColumn } from "../interface/DataTableColumn";

export interface IGridColumnWidthDictionary {
  [columnId: string]: string;
}

export const DEFAULT_COLUMN_MIN_WIDTH = 30;

export const GridHelper = {
  /**
   * Composes a grid template column string from the provided column definitions
   * @param columns - the adjusted column definitions from which the template should be derived from
   * @returns the grid template column css string
   */
  composeGridTemplateColumns(columns: Array<DataTableColumn>): string {
    return columns.reduce((template, column, index) => {
      const targetGridTemplateColumnString = GridHelper.composeSingleGridTemplateColumn(
        column,
        index === columns.length - 1,
      );

      return `${template} ${targetGridTemplateColumnString}`;
    }, "");
  },

  /**
   * Returns the gridTemplateColumn style string for a single column
   * @param column - the column definition for which the gridTemplateColumn style has to be created
   * @param isLastColumn - whether the column is the last one; enforces minmax 'auto' to avoid empty space
   */
  composeSingleGridTemplateColumn(column: DataTableColumn, isLastColumn: boolean): string {
    let targetWidth = column.width ?? "auto";
    const minWidth = column?.minWidth || DEFAULT_COLUMN_MIN_WIDTH;

    if (typeof column.width === "number") {
      targetWidth = `${Math.max(column.width, minWidth)}px`;
    }

    if (column.width === undefined) {
      targetWidth = `minmax(${minWidth}px, auto)`;
    }

    if (isLastColumn && !targetWidth.toString().startsWith("minmax")) {
      targetWidth = `minmax(${targetWidth}, auto)`;
    }

    return `[${column.id ?? column.accessor}] ${targetWidth}`;
  },
};
