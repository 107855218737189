export const InvoiceOverdue = ({ size = 16, color = "currentColor", ...restProps }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>InvoiceOverdue</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3386 0H1.88977C0.850396 0 0 0.852609 0 1.89469V14.2101C0 15.2522 0.850396 16.1048 1.88977 16.1048H6.42796C5.94512 15.2686 5.66869 14.2978 5.66869 13.2627C5.66869 12.94 5.69556 12.6235 5.74718 12.3155H2.83465V10.4208H6.42786C6.86688 9.66037 7.47657 9.01122 8.20427 8.52609H2.83465V6.6314H10.3937V7.65726C10.7008 7.60556 11.0163 7.57866 11.338 7.57866C12.0007 7.57866 12.637 7.69286 13.2284 7.9027V1.89469C13.2284 0.852609 12.378 0 11.3386 0ZM10.3937 4.73672H2.83465V2.84203H10.3937V4.73672Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.61445 13.2633C6.61445 10.6486 8.73099 8.52657 11.3389 8.52657C13.9468 8.52657 16.0633 10.6486 16.0633 13.2633C16.0633 15.878 13.9468 18 11.3389 18C8.73099 18 6.61445 15.878 6.61445 13.2633ZM10.7374 15.5484H11.8163V14.467H10.7374V15.5484ZM10.7374 13.4996H11.8163V10.5971H10.7374V13.4996Z"
        fill={color}
      />
    </svg>
  );
};
