import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { ResponseError } from "../../openapi";
import { useRestAPI } from "../../useRestAPI";

export const GetRetailerFactoringLimitQueryKey = "GetRetailerFactoringLimit";
export const useGetRetailerFactoringLimitQuery = () => {
  const api = useRestAPI();

  return useQuery({
    queryKey: [GetRetailerFactoringLimitQueryKey],
    queryFn: async () => {
      try {
        return await api.retailer.getFactoringLimitOfCurrentRetailer();
      } catch (error) {
        if (error instanceof ResponseError && error.response.status === 404) {
          return null;
        }

        throw error;
      }
    },
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: true,
  });
};
