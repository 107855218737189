/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MiddlewareError,
  StoreProfilePreviewQuery,
  StoreProfilePreviewResponse,
} from '../models';
import {
    MiddlewareErrorFromJSON,
    MiddlewareErrorToJSON,
    StoreProfilePreviewQueryFromJSON,
    StoreProfilePreviewQueryToJSON,
    StoreProfilePreviewResponseFromJSON,
    StoreProfilePreviewResponseToJSON,
} from '../models';

export interface CreateStoreProfilePreviewOfCurrentRetailerRequest {
    storeProfilePreviewQuery: StoreProfilePreviewQuery;
}

/**
 * 
 */
export class StoreProfilePreviewResourceApi extends runtime.BaseAPI {

    /**
     */
    async createStoreProfilePreviewOfCurrentRetailerRaw(requestParameters: CreateStoreProfilePreviewOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoreProfilePreviewResponse>> {
        if (requestParameters.storeProfilePreviewQuery === null || requestParameters.storeProfilePreviewQuery === undefined) {
            throw new runtime.RequiredError('storeProfilePreviewQuery','Required parameter requestParameters.storeProfilePreviewQuery was null or undefined when calling createStoreProfilePreviewOfCurrentRetailer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/store-profile/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StoreProfilePreviewQueryToJSON(requestParameters.storeProfilePreviewQuery),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoreProfilePreviewResponseFromJSON(jsonValue));
    }

    /**
     */
    async createStoreProfilePreviewOfCurrentRetailer(requestParameters: CreateStoreProfilePreviewOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoreProfilePreviewResponse> {
        const response = await this.createStoreProfilePreviewOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
