import { OpenInvoiceSummary, PaymentStatus } from "~/api/openapi";

import { InvoiceWidgetData } from "./interface/InvoiceWidgetData";

export const mapInvoiceResponseToInvoiceWidgetData = (
  invoicesResponse: OpenInvoiceSummary | undefined,
): InvoiceWidgetData | undefined => {
  if (!invoicesResponse) {
    return undefined;
  }

  return {
    invoiceSummaryData: {
      totalAmount: invoicesResponse.total,
      totalCount: invoicesResponse.count,
      overDueCount: invoicesResponse.openInvoices.filter(
        (invoice) => invoice.paymentStatus === PaymentStatus.Overdue,
      ).length,
    },
    invoiceWidgetChartData: invoicesResponse.openInvoices.map((invoice) => ({
      invoiceNumber: invoice.number.number,
      invoiceId: invoice.id,
      invoiceDate: invoice.createdAt,
      totalAmount: invoice.total,
      paymentDueDate: invoice.paymentDueDate,
      paymentStatus: invoice.paymentStatus,
      paymentMethod: invoice.paymentMethod,
      paymentType: invoice.paymentType,
    })),
  };
};
