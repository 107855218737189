/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeoCustomerOrderNumber } from './HeoCustomerOrderNumber';
import {
    HeoCustomerOrderNumberFromJSON,
    HeoCustomerOrderNumberFromJSONTyped,
    HeoCustomerOrderNumberToJSON,
} from './HeoCustomerOrderNumber';
import type { HeoItemId } from './HeoItemId';
import {
    HeoItemIdFromJSON,
    HeoItemIdFromJSONTyped,
    HeoItemIdToJSON,
} from './HeoItemId';
import type { OrderOrigin } from './OrderOrigin';
import {
    OrderOriginFromJSON,
    OrderOriginFromJSONTyped,
    OrderOriginToJSON,
} from './OrderOrigin';
import type { OrderType } from './OrderType';
import {
    OrderTypeFromJSON,
    OrderTypeFromJSONTyped,
    OrderTypeToJSON,
} from './OrderType';

/**
 * 
 * @export
 * @interface SalesOrderItemSummary
 */
export interface SalesOrderItemSummary {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderItemSummary
     */
    id: string;
    /**
     * 
     * @type {HeoItemId}
     * @memberof SalesOrderItemSummary
     */
    heoItemId: HeoItemId;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderItemSummary
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderItemSummary
     */
    pricePerUnit: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderItemSummary
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderItemSummary
     */
    discount: number;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderItemSummary
     */
    customerItemReference: string;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderItemSummary
     */
    availability?: Date;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderItemSummary
     */
    productNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderItemSummary
     */
    salesOrderCreatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderItemSummary
     */
    salesOrderCustomerReference: string;
    /**
     * 
     * @type {HeoCustomerOrderNumber}
     * @memberof SalesOrderItemSummary
     */
    customerOrderNumber?: HeoCustomerOrderNumber;
    /**
     * 
     * @type {OrderOrigin}
     * @memberof SalesOrderItemSummary
     */
    orderOrigin?: OrderOrigin;
    /**
     * 
     * @type {OrderType}
     * @memberof SalesOrderItemSummary
     */
    orderType?: OrderType;
}

/**
 * Check if a given object implements the SalesOrderItemSummary interface.
 */
export function instanceOfSalesOrderItemSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "heoItemId" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "pricePerUnit" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "discount" in value;
    isInstance = isInstance && "customerItemReference" in value;
    isInstance = isInstance && "productNumber" in value;
    isInstance = isInstance && "salesOrderCreatedAt" in value;
    isInstance = isInstance && "salesOrderCustomerReference" in value;

    return isInstance;
}

export function SalesOrderItemSummaryFromJSON(json: any): SalesOrderItemSummary {
    return SalesOrderItemSummaryFromJSONTyped(json, false);
}

export function SalesOrderItemSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesOrderItemSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'heoItemId': HeoItemIdFromJSON(json['heoItemId']),
        'quantity': json['quantity'],
        'pricePerUnit': json['pricePerUnit'],
        'total': json['total'],
        'discount': json['discount'],
        'customerItemReference': json['customerItemReference'],
        'availability': !exists(json, 'availability') ? undefined : (new Date(json['availability'])),
        'productNumber': json['productNumber'],
        'salesOrderCreatedAt': (new Date(json['salesOrderCreatedAt'])),
        'salesOrderCustomerReference': json['salesOrderCustomerReference'],
        'customerOrderNumber': !exists(json, 'customerOrderNumber') ? undefined : HeoCustomerOrderNumberFromJSON(json['customerOrderNumber']),
        'orderOrigin': !exists(json, 'orderOrigin') ? undefined : OrderOriginFromJSON(json['orderOrigin']),
        'orderType': !exists(json, 'orderType') ? undefined : OrderTypeFromJSON(json['orderType']),
    };
}

export function SalesOrderItemSummaryToJSON(value?: SalesOrderItemSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'heoItemId': HeoItemIdToJSON(value.heoItemId),
        'quantity': value.quantity,
        'pricePerUnit': value.pricePerUnit,
        'total': value.total,
        'discount': value.discount,
        'customerItemReference': value.customerItemReference,
        'availability': value.availability === undefined ? undefined : (value.availability.toISOString()),
        'productNumber': value.productNumber,
        'salesOrderCreatedAt': (value.salesOrderCreatedAt.toISOString()),
        'salesOrderCustomerReference': value.salesOrderCustomerReference,
        'customerOrderNumber': HeoCustomerOrderNumberToJSON(value.customerOrderNumber),
        'orderOrigin': OrderOriginToJSON(value.orderOrigin),
        'orderType': OrderTypeToJSON(value.orderType),
    };
}

