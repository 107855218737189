/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreOverview } from './StoreOverview';
import {
    StoreOverviewFromJSON,
    StoreOverviewFromJSONTyped,
    StoreOverviewToJSON,
} from './StoreOverview';

/**
 * 
 * @export
 * @interface StoreProfileNewRequestOverview
 */
export interface StoreProfileNewRequestOverview {
    /**
     * 
     * @type {string}
     * @memberof StoreProfileNewRequestOverview
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileNewRequestOverview
     */
    approvalStatus: StoreProfileNewRequestOverviewApprovalStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof StoreProfileNewRequestOverview
     */
    storeProfileVisibleAfterApproval?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileNewRequestOverview
     */
    rejectionReason?: string;
    /**
     * 
     * @type {Date}
     * @memberof StoreProfileNewRequestOverview
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StoreProfileNewRequestOverview
     */
    updatedAt?: Date;
    /**
     * 
     * @type {StoreOverview}
     * @memberof StoreProfileNewRequestOverview
     */
    store: StoreOverview;
}


/**
 * @export
 */
export const StoreProfileNewRequestOverviewApprovalStatusEnum = {
    InReview: 'IN_REVIEW',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Draft: 'DRAFT',
    Submitted: 'SUBMITTED'
} as const;
export type StoreProfileNewRequestOverviewApprovalStatusEnum = typeof StoreProfileNewRequestOverviewApprovalStatusEnum[keyof typeof StoreProfileNewRequestOverviewApprovalStatusEnum];


/**
 * Check if a given object implements the StoreProfileNewRequestOverview interface.
 */
export function instanceOfStoreProfileNewRequestOverview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "approvalStatus" in value;
    isInstance = isInstance && "store" in value;

    return isInstance;
}

export function StoreProfileNewRequestOverviewFromJSON(json: any): StoreProfileNewRequestOverview {
    return StoreProfileNewRequestOverviewFromJSONTyped(json, false);
}

export function StoreProfileNewRequestOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfileNewRequestOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'approvalStatus': json['approvalStatus'],
        'storeProfileVisibleAfterApproval': !exists(json, 'storeProfileVisibleAfterApproval') ? undefined : json['storeProfileVisibleAfterApproval'],
        'rejectionReason': !exists(json, 'rejectionReason') ? undefined : json['rejectionReason'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'store': StoreOverviewFromJSON(json['store']),
    };
}

export function StoreProfileNewRequestOverviewToJSON(value?: StoreProfileNewRequestOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'approvalStatus': value.approvalStatus,
        'storeProfileVisibleAfterApproval': value.storeProfileVisibleAfterApproval,
        'rejectionReason': value.rejectionReason,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'store': StoreOverviewToJSON(value.store),
    };
}

