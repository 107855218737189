import { PeriodOption } from "~/common/enum/PeriodOption";

import { DashboardPageDE } from "../de/de.dashboardPage";

export const DashboardPageEN: typeof DashboardPageDE = {
  title: "My Dashboard",

  editDashboardTooltip: "Edit dashboard",
  endEditing: "Save dashboard",
  noDataTitle: "Your dashboard is currently empty",
  noDataDescription:
    "You do not currently have any widgets activated for your dashboard. Switch to edit mode and customize your dashboard to your needs.",
  noDataDescriptionMobile: "You do not currently have any widgets activated for your dashboard.",
  startEditingButtonLabel: "Edit dashboard",
  resetDashboard: "Reset dashboard",

  CurrentDeliveriesWidget: {
    title: "Current deliveries",
    subtitle: {
      noDeliveries: "No deliveries available",
      delivery_one:
        "{{count}} Delivery (of which {{countInProgress}} in progress) in the last 7 days",
      delivery_other:
        "{{count}} Deliveries (of which {{countInProgress}} in progress) in the last 7 days",
    },

    numberOfPackages: {
      package_one: "{{count}} Package",
      package_other: "{{count}} Packages",
    },
    shippingStatusInProgress: "In progress",
    shippingStatusShipped: "Shipped",
    showAllDeliveriesButton: "Show all deliveries",
    noAdditionalProducts: "{{productNumber}}",
    moreAdditionalProducts: "{{productNumber}} + {{count}} more",
  },

  OpenOrderWidget: {
    title: "Items in open orders",
    subtitle: {
      noOrders: "No open orders available",
      order_one: "{{count}} Item ({{totalAmount}}) in total",
      order_other: "{{count}} Items ({{totalAmount}}) in total",
    },
    urlTooltip: 'To the pre-order and backorder list with filter "{{filter}}"',
    totalLabel: "Total",
    allOpenOrdersButton: "Show all open orders",
  },
  OpenInvoicesWidget: {
    title: "Open invoices",

    subtitle: {
      invoiceWithOverdue_one:
        "{{count}} outstanding invoice ({{totalAmount}}), {{overdueCount}} of which is overdue",
      invoiceWithoutOverdue_one: "{{count}} outstanding invoice ({{totalAmount}})",
      invoiceWithOverdue_other:
        "{{count}} outstanding invoices ({{totalAmount}}), {{overdueCount}} of which are overdue",
      invoiceWithoutOverdue_other: "{{count}} outstanding invoices ({{totalAmount}})",
      noInvoices: "No outstanding invoices available",
    },

    item: {
      paymentDeadlineLabel: "PAyment deadline: {{paymentDueDate}}",
      paymentDueDateLabel: "Payment due date: {{paymentDueDate}}",
    },
    showAllInvoicesButton: "Show all invoices",
  },
  tiles: {
    customerOrderTitle: "Shop orders",
    customerOrderTooltipTitle: "To order overview",
    salesOrderTiele: "Back orders",
    salesOrderTooltipTitle: "To backorder list",
    invoicesTitle: "Invoices",
    invoicesTooltipTitle: "To invoice overview",
    creditNotesTitle: "Credit notes",
    creditNotesTooltipTitle: "To credit note overview",
    periodSelect: {
      [PeriodOption.SEVEN_DAYS]: "in the last 7 days",
      [PeriodOption.ONE_MONTH]: "in the last month",
      [PeriodOption.THREE_MONTHS]: "in the last 3 months",
      [PeriodOption.SIX_MONTHS]: "in the last 6 months",
      [PeriodOption.TWELVE_MONTHS]: "in the last 12 months",
      [PeriodOption.START_OF_YEAR]: "since the start of the year",
    } as Record<PeriodOption, string>,
  },

  OpenClaimsWidget: {
    title: "Open claims",
    subtitle: {
      withClaims_one: "{{count}} claim in process",
      withClaims_other: "{{count}} claims in process",
      noClaims: "No open claims available",
    },

    newClaimButton: "New claim",
    allClaimsButton: "Show all claims",
    claims: "Claims",
  },
  ExpectedAvailabilityWidget: {
    title: "Est. Availability of ordered items",
    subtitle: {
      item_one: "{{count}} item available soon",
      item_other: "{{count}} items available soon",
      item_zero: "No items available soon",
    },

    tile: {
      header: {
        data_other: " ({{count}} positions for {{price}})",
        data_one: " ({{count}} position for {{price}})",
        noData: " (No position available)",
      },
      showItemButton: "Show items",
      noData: "No position available",
    },
    showNextPeriod: "Show next period",
    showPreviousPeriod: "Show previous period",
  },

  factoringWidget: {
    title: "Your factoring limit",
    description: "Your total limit is <strong>{{ amount }}</strong>",
    used: "Used",
    limit: "Limit",
    available: "Available",
    exceeded: "Exceeded",
    exceededAlert: "Your limit has been exceeded with <strong>{{ amount }}</strong>!",
  },
  totalSalesReportWidget: {
    title: "Turnover summary",
    titleInfoTooltip:
      "In the turnover summary, you can see the total amounts of all ordered, invoiced and credited items. You can also see the total amounts of all order and invoice items you have cancelled.",
    showDetails: "Show details",
    noDataInfoText:
      "The turnover overview is still empty. <strong>Place your first order in the heo online store now</strong> to fill the sales page with content and get an overview of your orders, invoices and credit notes.",
    heoShopButton: "Visit heo shop",
    table: {
      totalLabel: "Total",
      ordersLabel: "Ordered",
      invoicesLabel: "Invoiced",
      creditsLabel: "Credited",
      cancledOrdersLabel: "Cancellation",
      cancledInvoicesLabel: "Reversal",
      cancellationRateLabel: "Quota",
      cancellationRate: "{{cancellationRate}} %",
    },
    quote: {
      description:
        "The quota is an indicator of the ratio of orders, invoices, credit notes and cancellations.",
      ok: "0 - 5 %: Acceptable",
      medium: { title: "5 - 10 %: Caution", description: "Reduction of the ratio required" },
      bad: {
        title: "From 10 %: Critical",
        description:
          "May lead to restrictions, including termination of the business relationship.",
      },
    },
  },
};
