import { Popover, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconInfoCircle } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import { CancellationRateStatus } from "../../CancellationRateStatus";
import {
  GOOD_CANCELLATION_RATE_BORDER,
  MEDIUM_CANCELLATION_RATE_BORDER,
} from "../../getCancellationRateStatus/getCancellationRateStatus";
import { CancellationRateAlertBox } from "./CancellationRateAlertBox/CancellationRateAlertBox";

const CancellationRateInformationenPopover = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "dashboardPage.totalSalesReportWidget.quote",
  });
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover shadow="md" withArrow opened={opened} transitionProps={{ transition: "pop" }}>
      <Popover.Target>
        <IconInfoCircle onMouseEnter={open} onMouseLeave={close} color={"teal"} />
      </Popover.Target>

      <Popover.Dropdown w={650} maw={"100%"} style={{ pointerEvents: "none" }} p="md">
        <Stack gap="xs">
          <Text>{t("description")}</Text>
          <CancellationRateAlertBox
            status={CancellationRateStatus.GOOD}
            title={t("ok", { lowerBorder: 0, upperBorder: GOOD_CANCELLATION_RATE_BORDER })}
          />
          <CancellationRateAlertBox
            status={CancellationRateStatus.MEDIUM}
            title={t("medium.title", {
              lowerBorder: GOOD_CANCELLATION_RATE_BORDER,
              upperBorder: MEDIUM_CANCELLATION_RATE_BORDER,
            })}
            descripton={t("medium.description")}
          />
          <CancellationRateAlertBox
            status={CancellationRateStatus.BAD}
            title={t("bad.title", { border: MEDIUM_CANCELLATION_RATE_BORDER })}
            descripton={t("bad.description")}
          />
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export { CancellationRateInformationenPopover };
