import { useQuery } from "@tanstack/react-query";

import { useRestAPI } from "../useRestAPI";

export const GetMostRecentChangelogQueryKey = "GetMostRecentChangelog";
export const useGetMostRecentChangelog = () => {
  const api = useRestAPI();
  return useQuery({
    queryKey: [GetMostRecentChangelogQueryKey],
    queryFn: () => api.changelog.getMostRecentChangelog(),
  });
};
