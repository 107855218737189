/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContactPerson,
  ContactPersonRequestParams,
} from '../models';
import {
    ContactPersonFromJSON,
    ContactPersonToJSON,
    ContactPersonRequestParamsFromJSON,
    ContactPersonRequestParamsToJSON,
} from '../models';

export interface CreateContactPersonRequest {
    contactPersonRequestParams: ContactPersonRequestParams;
}

export interface DeleteContactPersonByIdRequest {
    contactPersonId: string;
}

export interface UpdateContactPersonRequest {
    contactPersonId: string;
    contactPersonRequestParams: ContactPersonRequestParams;
}

/**
 * 
 */
export class ContactPersonResourceApi extends runtime.BaseAPI {

    /**
     */
    async createContactPersonRaw(requestParameters: CreateContactPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contactPersonRequestParams === null || requestParameters.contactPersonRequestParams === undefined) {
            throw new runtime.RequiredError('contactPersonRequestParams','Required parameter requestParameters.contactPersonRequestParams was null or undefined when calling createContactPerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/retailer/contact-person`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactPersonRequestParamsToJSON(requestParameters.contactPersonRequestParams),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createContactPerson(requestParameters: CreateContactPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createContactPersonRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteContactPersonByIdRaw(requestParameters: DeleteContactPersonByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contactPersonId === null || requestParameters.contactPersonId === undefined) {
            throw new runtime.RequiredError('contactPersonId','Required parameter requestParameters.contactPersonId was null or undefined when calling deleteContactPersonById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailer/contact-person/{contactPersonId}`.replace(`{${"contactPersonId"}}`, encodeURIComponent(String(requestParameters.contactPersonId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteContactPersonById(requestParameters: DeleteContactPersonByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteContactPersonByIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getContactPersonsOfCurrentRetailerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ContactPerson>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailer/contact-person`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContactPersonFromJSON));
    }

    /**
     */
    async getContactPersonsOfCurrentRetailer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ContactPerson>> {
        const response = await this.getContactPersonsOfCurrentRetailerRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateContactPersonRaw(requestParameters: UpdateContactPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contactPersonId === null || requestParameters.contactPersonId === undefined) {
            throw new runtime.RequiredError('contactPersonId','Required parameter requestParameters.contactPersonId was null or undefined when calling updateContactPerson.');
        }

        if (requestParameters.contactPersonRequestParams === null || requestParameters.contactPersonRequestParams === undefined) {
            throw new runtime.RequiredError('contactPersonRequestParams','Required parameter requestParameters.contactPersonRequestParams was null or undefined when calling updateContactPerson.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/retailer/contact-person/{contactPersonId}`.replace(`{${"contactPersonId"}}`, encodeURIComponent(String(requestParameters.contactPersonId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ContactPersonRequestParamsToJSON(requestParameters.contactPersonRequestParams),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateContactPerson(requestParameters: UpdateContactPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateContactPersonRaw(requestParameters, initOverrides);
    }

}
