import { useMemo } from "react";

import { TableRowType } from "../interface/DataTableProps";
import { Row } from "../interface/DataTableRow";

export const useRows = <RowType extends TableRowType>(
  data: RowType[],
  getRowId: ((data: RowType) => string) | undefined,
): Row<RowType>[] => {
  return useMemo(
    () =>
      data.map((item, index) => {
        const id = getRowId ? getRowId(item) : `${index}`;
        return { id, data: item };
      }),
    [data, getRowId],
  );
};
