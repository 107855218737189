import { useQuery } from "@tanstack/react-query";

import { ResponseError } from "../../openapi";
import { useRestAPI } from "../../useRestAPI";

export const GetRetailerBillingStopQueryKey = "GetRetailerBillingStop";
export const useGetRetailerBillingStopQuery = () => {
  const api = useRestAPI();

  return useQuery({
    queryKey: [GetRetailerBillingStopQueryKey],
    queryFn: async () => {
      try {
        return await api.retailer.getBillingStopOfCurrentRetailer();
      } catch (error) {
        if (error instanceof ResponseError && error.response.status === 404) {
          return null;
        }

        throw error;
      }
    },
    refetchOnWindowFocus: true,
  });
};
