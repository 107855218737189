import { ActionIcon, NavLink, Popover, Text, TextProps, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import clsx from "clsx";
import React, { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./NavbarItem.module.css";

type NavbarItemAnchorProps = {
  href: string;
  target?: "_blank" | "_self";
  popover?: undefined;
  path?: undefined;
};

type NavbarItemPopoverProps = {
  popover: ReactNode;
  href?: undefined;
  target?: undefined;
  path?: undefined;
};

type NavbarItemLinkProps = {
  path: string;
  popover?: undefined;
  href?: undefined;
  target?: undefined;
};

type NavbarItemButtonProps = {
  onClick: () => void;
  path?: undefined;
  popover?: undefined;
  href?: undefined;
  target?: undefined;
};

export type NavbarItemProps = {
  icon?: ReactNode;
  label: string;
  labelProps?: TextProps;
  isExpanded: boolean;
  iconPosition?: "left" | "right";
  onClick?: () => void;
} & (NavbarItemAnchorProps | NavbarItemLinkProps | NavbarItemPopoverProps | NavbarItemButtonProps);

const NavbarItem: React.FC<NavbarItemProps> = (props) => {
  const {
    icon,
    label,
    path,
    href,
    target,
    popover,
    isExpanded,
    iconPosition = "right",
    labelProps,
    onClick,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const isActive = location.pathname === path;
  const [popoverOpened, popoverHandler] = useDisclosure(false);

  const handleClick = () => {
    if (!path && popover) {
      popoverHandler.toggle();
    }

    if (path) {
      navigate(path);
    }

    if (href) {
      window.open(href, target ?? "_self");
    }

    onClick?.();
  };

  const textLabel = <Text {...labelProps}>{label}</Text>;

  return (
    <Popover
      withArrow
      opened={popoverOpened}
      onClose={popoverHandler.close}
      position="top"
      shadow={"md"}
    >
      <Popover.Target>
        {isExpanded ? (
          <NavLink
            disableRightSectionRotation
            label={textLabel}
            leftSection={iconPosition === "left" && icon}
            rightSection={iconPosition === "right" && icon}
            onClick={handleClick}
            active={isActive}
            aria-label={label}
            className={styles.navLink}
            pr={"20px"}
            {...{ "data-testid": `nav-to-${path}` }}
          />
        ) : (
          <Tooltip label={label} position="right" withArrow withinPortal zIndex={1000}>
            <ActionIcon
              onClick={handleClick}
              aria-label={label}
              w={"100%"}
              h={50}
              radius={0}
              variant="subtle"
              color="heoBlack"
              className={clsx(styles.navLink, isActive && styles.active)}
              {...{ "data-testid": `nav-to-${path}` }}
            >
              {icon}
            </ActionIcon>
          </Tooltip>
        )}
      </Popover.Target>

      <Popover.Dropdown p={"md"}>{popover}</Popover.Dropdown>
    </Popover>
  );
};

export { NavbarItem };
