export type DynamicRouteParameters<QueryKeys> = {
  [Key in keyof QueryKeys]:
    | {
        queryKey: string;
        default?: string;
      }
    | string;
};

export type DynamicRouteConfig<QueryKeys extends Record<string, string>> = {
  url: string;
  params: DynamicRouteParameters<QueryKeys>;
};

export type DynamicRouteProps<Values extends Record<string, string>> = {
  [Key in keyof Values]?: string | number | boolean | string[];
};

export type DynamicRouteOptions = {
  /**
   * @default true
   */
  clear?: boolean;
};

export const createDynamicRoute =
  <Values extends Record<string, string>>(config: DynamicRouteConfig<Values>) =>
  (props?: DynamicRouteProps<Values>, options?: DynamicRouteOptions) => {
    const { clear = true } = options ?? {};
    const searchParams = new URLSearchParams();

    const routeParams = config.params;
    for (const paramKey of Object.keys(routeParams)) {
      const param = config.params[paramKey];
      const queryKey = typeof param === "string" ? param : param.queryKey;
      const defaultValue = typeof param === "string" ? undefined : param.default;
      const queryValue = props?.[paramKey];

      const getSanitizedValue = (): string | undefined => {
        if (typeof queryValue === "string") {
          return queryValue;
        }

        if (typeof queryValue === "number" || typeof queryValue === "boolean") {
          return `${queryValue}`;
        }

        if (Array.isArray(queryValue) && queryValue.every((it) => typeof it === "string")) {
          return queryValue.join(",");
        }

        return undefined;
      };

      const targetValue = getSanitizedValue() ?? defaultValue;

      if (targetValue !== undefined) {
        searchParams.set(queryKey, targetValue);
        continue;
      }

      if (clear) {
        searchParams.set(queryKey, "");
      }
    }

    return `${config.url}?${searchParams.toString()}`;
  };
