/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface Place
 */
export interface Place {
    /**
     * 
     * @type {string}
     * @memberof Place
     */
    googlePlaceId: string;
    /**
     * 
     * @type {string}
     * @memberof Place
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Place
     */
    address: string;
    /**
     * 
     * @type {Location}
     * @memberof Place
     */
    location: Location;
}

/**
 * Check if a given object implements the Place interface.
 */
export function instanceOfPlace(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "googlePlaceId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "location" in value;

    return isInstance;
}

export function PlaceFromJSON(json: any): Place {
    return PlaceFromJSONTyped(json, false);
}

export function PlaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Place {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'googlePlaceId': json['googlePlaceId'],
        'name': json['name'],
        'address': json['address'],
        'location': LocationFromJSON(json['location']),
    };
}

export function PlaceToJSON(value?: Place | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'googlePlaceId': value.googlePlaceId,
        'name': value.name,
        'address': value.address,
        'location': LocationToJSON(value.location),
    };
}

