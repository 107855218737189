/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Place,
} from '../models';
import {
    PlaceFromJSON,
    PlaceToJSON,
} from '../models';

export interface SearchGooglePlacesRequest {
    searchString: string;
    acceptLanguage?: string;
}

/**
 * 
 */
export class GooglePlaceResourceApi extends runtime.BaseAPI {

    /**
     */
    async searchGooglePlacesRaw(requestParameters: SearchGooglePlacesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Place>>> {
        if (requestParameters.searchString === null || requestParameters.searchString === undefined) {
            throw new runtime.RequiredError('searchString','Required parameter requestParameters.searchString was null or undefined when calling searchGooglePlaces.');
        }

        const queryParameters: any = {};

        if (requestParameters.searchString !== undefined) {
            queryParameters['searchString'] = requestParameters.searchString;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
            headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
        }

        const response = await this.request({
            path: `/api/v1/ui/google/maps/place`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlaceFromJSON));
    }

    /**
     */
    async searchGooglePlaces(requestParameters: SearchGooglePlacesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Place>> {
        const response = await this.searchGooglePlacesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
