/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChangeRequestHourRangeRequest } from './ChangeRequestHourRangeRequest';
import {
    ChangeRequestHourRangeRequestFromJSON,
    ChangeRequestHourRangeRequestFromJSONTyped,
    ChangeRequestHourRangeRequestToJSON,
} from './ChangeRequestHourRangeRequest';

/**
 * 
 * @export
 * @interface ChangeRequestOpeningDay
 */
export interface ChangeRequestOpeningDay {
    /**
     * 
     * @type {Array<ChangeRequestHourRangeRequest>}
     * @memberof ChangeRequestOpeningDay
     */
    hours?: Array<ChangeRequestHourRangeRequest>;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeRequestOpeningDay
     */
    isOpen?: boolean;
}

/**
 * Check if a given object implements the ChangeRequestOpeningDay interface.
 */
export function instanceOfChangeRequestOpeningDay(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeRequestOpeningDayFromJSON(json: any): ChangeRequestOpeningDay {
    return ChangeRequestOpeningDayFromJSONTyped(json, false);
}

export function ChangeRequestOpeningDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestOpeningDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hours': !exists(json, 'hours') ? undefined : ((json['hours'] as Array<any>).map(ChangeRequestHourRangeRequestFromJSON)),
        'isOpen': !exists(json, 'isOpen') ? undefined : json['isOpen'],
    };
}

export function ChangeRequestOpeningDayToJSON(value?: ChangeRequestOpeningDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hours': value.hours === undefined ? undefined : ((value.hours as Array<any>).map(ChangeRequestHourRangeRequestToJSON)),
        'isOpen': value.isOpen,
    };
}

