import { Box, Group, Stack } from "@mantine/core";
import React from "react";

import { ReleaseNotes } from "../components/ReleaseNotes/ReleaseNotes";
import { Scroller } from "../components/Scroller/Scroller";
import { Header } from "./Header/Header";
import { Navbar } from "./Navbar/Navbar";

import clsx from "clsx";
import { useHtmlAttribute } from "~/hooks/ui/useHtmlAttribute";
import { useResponsiveMode } from "~/hooks/ui/useResponsiveMode";
import styles from "./Layout.module.css";
import { useNavbarExtend } from "./useNavbarExtend";

export interface LayoutProps {
  children: React.ReactNode;
  hideNavbarLinks?: boolean;
  hideUserMenuLinks?: boolean;
  hideFeedbackButton?: boolean;
}

const Layout: React.FC<LayoutProps> = (props) => {
  const { children, hideNavbarLinks, hideUserMenuLinks, hideFeedbackButton } = props;

  const { isMobile, isTouch } = useResponsiveMode();
  const { isExtended } = useNavbarExtend();

  useHtmlAttribute("data-mobile", isMobile);
  useHtmlAttribute("data-touch", isTouch);
  useHtmlAttribute("data-navbar-extended", isExtended);

  const layoutClassNames = clsx(
    styles.layout,
    isTouch && styles.touch,
    isMobile && styles.mobile,
    isExtended && styles.navbarExtended,
  );

  return (
    <Stack gap={0} h={"100dvh"} className={layoutClassNames}>
      <Header
        withUserMenu
        hideUserMenuLinks={hideUserMenuLinks}
        hideFeedbackButton={hideFeedbackButton}
        isMobileView={isMobile}
        hideNavbarLinks={hideNavbarLinks}
      />

      <Group wrap="nowrap" gap={0} className={styles.body}>
        {!isMobile && <Navbar hideNavbarLinks={hideNavbarLinks} />}

        <Box className={styles.contentContainer}>
          {isTouch ? children : <Scroller horizontalScrollDisabled>{children}</Scroller>}
        </Box>
      </Group>
      <ReleaseNotes />
    </Stack>
  );
};

export { Layout };
