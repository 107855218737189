import { useEffect, useMemo, useState } from "react";

import { useGetRetailerFactoringLimitQuery } from "~/api/hooks/retailer/useGetRetailerFactoringLimitQuery";
import { PeriodOption } from "~/common/enum/PeriodOption";
import { KpiTilesPeriods } from "~/hooks/accountSettings/KpiTilesPeriodsDefaultSchema";
import { useAccountSettings } from "~/hooks/accountSettings/useAccountSettings";
import { GridLayoutItem } from "~/ui/components/GridLayout/GridLayout";

import { ClaimWidget } from "../DashboardWidgets/Claims/ClaimWidget";
import { CurrentDeliveriesWidget } from "../DashboardWidgets/CurrentDeliveriesWidget/CurrentDeliveriesWidget";
import { ExpectedAvailabilityWidget } from "../DashboardWidgets/ExpectedAvailabilityWidget/ExpectedAvailabilityWidget";
import { FactoringWidget } from "../DashboardWidgets/FactoringWidget/FactoringWidget";
import { OpenInvoicesWidget } from "../DashboardWidgets/OpenInvoicesWidget/OpenInvoicesWidget";
import { OpenOrdersWidget } from "../DashboardWidgets/OpenOrders/OpenOrdersWidget";
import { TotalSalesReportWidget } from "../DashboardWidgets/TotalSalesReportWidget/TotalSalesReportWidget";

const defaultGridItems: GridLayoutItem[] = [
  { id: "currentDeliveries", content: <CurrentDeliveriesWidget />, disabled: false },
  { id: "openOrders", content: <OpenOrdersWidget />, disabled: false },
  { id: "openInvoices", content: <OpenInvoicesWidget />, disabled: false },
  { id: "claim", content: <ClaimWidget />, disabled: false },
  { id: "availability", content: <ExpectedAvailabilityWidget />, disabled: false },
  { id: "totalSales", content: <TotalSalesReportWidget />, disabled: false },
];

const kpiTilesDefaultPeriods: KpiTilesPeriods = {
  creditNotePeriod: PeriodOption.SEVEN_DAYS,
  invoicePeriod: PeriodOption.SEVEN_DAYS,
  customerOrderPeriod: PeriodOption.SEVEN_DAYS,
  salesOrderPeriod: PeriodOption.SEVEN_DAYS,
};

export const useDashboardGrid = () => {
  const { accountSettings, updateSettings, accountSettingsLoading } = useAccountSettings();
  const settingsItems = accountSettings?.dashboardSettings;

  const { data: factoringData, isPending: isFactoringDataLoading } =
    useGetRetailerFactoringLimitQuery();

  const isLoading = accountSettingsLoading || isFactoringDataLoading;

  const [gridItems, setGridItems] = useState<GridLayoutItem[]>(defaultGridItems);
  const [kpiTilesPeriods, setKpiTilesPeriods] = useState<KpiTilesPeriods>(kpiTilesDefaultPeriods);

  const defaultItems = useMemo(() => {
    const items: GridLayoutItem[] = [...defaultGridItems];

    if (factoringData) {
      const factoringGridItem: GridLayoutItem = {
        id: "factoring",
        content: <FactoringWidget limit={factoringData.limit} used={factoringData.used} />,
        disabled: false,
      };

      items.push(factoringGridItem);
    }

    return items;
  }, [factoringData]);

  const isChanged = useMemo(() => {
    const gridItemsChanged = gridItems.some((gridItem, index) => {
      const orderChanged = defaultItems[index].id !== gridItem.id;
      const visibilityChanged = defaultItems[index].disabled !== gridItem.disabled;
      return orderChanged || visibilityChanged;
    });

    const kpiTilesChanged =
      kpiTilesPeriods?.creditNotePeriod !== PeriodOption.SEVEN_DAYS ||
      kpiTilesPeriods?.customerOrderPeriod !== PeriodOption.SEVEN_DAYS ||
      kpiTilesPeriods?.invoicePeriod !== PeriodOption.SEVEN_DAYS ||
      kpiTilesPeriods?.salesOrderPeriod !== PeriodOption.SEVEN_DAYS;
    return gridItemsChanged || kpiTilesChanged;
  }, [defaultItems, gridItems, kpiTilesPeriods]);

  useEffect(() => {
    if (isLoading) return;

    const orderedGridItems: GridLayoutItem[] = defaultItems.map((it) => ({ ...it }));

    if (settingsItems?.gridItems) {
      orderedGridItems.sort((left, right) => {
        return (
          settingsItems.gridItems.findIndex((item) => item.id === left.id) -
          settingsItems.gridItems.findIndex((item) => item.id === right.id)
        );
      });

      for (const orderedItem of orderedGridItems) {
        const settingsItem = settingsItems.gridItems.find((item) => item.id === orderedItem.id);
        if (!settingsItem) return;
        orderedItem.disabled = settingsItem.disabled;
      }
    }

    setGridItems(orderedGridItems);
    setKpiTilesPeriods(settingsItems?.kpiTilePeriods ?? kpiTilesDefaultPeriods);
  }, [settingsItems, defaultItems, isLoading]);

  const saveSettings = () => {
    updateSettings({
      dashboardSettings: {
        gridItems: gridItems.map((it) => ({ id: it.id, disabled: it.disabled ?? false })),
        kpiTilePeriods: kpiTilesPeriods,
      },
    });
  };

  const resetSettings = () => {
    setGridItems(defaultItems);
    setKpiTilesPeriods(kpiTilesDefaultPeriods);
  };

  return {
    gridItems,
    setGridItems,
    setKpiTilesPeriods,
    kpiTilesPeriods,
    saveSettings,
    resetSettings,
    isLoading,
    isChanged,
  };
};
