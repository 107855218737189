import { useMeQuery } from "~/api/hooks/me.hooks";
import { ResponseError } from "~/api/openapi";

import { useIsAuthenticated } from "./useIsAuthenticated";

export const useIsAuthorized = () => {
  const { isAuthenticated, isLoading } = useIsAuthenticated();

  const { error, isFetching: isMeQueryFetching } = useMeQuery({
    queryKey: ["meQueryKey"],
    enabled: isAuthenticated,
    retry: false,
  });

  const isAuthorized =
    !isMeQueryFetching && !(error instanceof ResponseError && error.response.status === 403);

  return {
    isLoading: isLoading || isMeQueryFetching,
    isAuthorized,
  };
};
