/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AvailableItems } from './AvailableItems';
import {
    AvailableItemsFromJSON,
    AvailableItemsFromJSONTyped,
    AvailableItemsToJSON,
} from './AvailableItems';

/**
 * 
 * @export
 * @interface SalesOrderItemAvailabilityByMonth
 */
export interface SalesOrderItemAvailabilityByMonth {
    /**
     * 
     * @type {Array<AvailableItems>}
     * @memberof SalesOrderItemAvailabilityByMonth
     */
    itemAvailabilityGroupByMonth: Array<AvailableItems>;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderItemAvailabilityByMonth
     */
    countAllArticle: number;
}

/**
 * Check if a given object implements the SalesOrderItemAvailabilityByMonth interface.
 */
export function instanceOfSalesOrderItemAvailabilityByMonth(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "itemAvailabilityGroupByMonth" in value;
    isInstance = isInstance && "countAllArticle" in value;

    return isInstance;
}

export function SalesOrderItemAvailabilityByMonthFromJSON(json: any): SalesOrderItemAvailabilityByMonth {
    return SalesOrderItemAvailabilityByMonthFromJSONTyped(json, false);
}

export function SalesOrderItemAvailabilityByMonthFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesOrderItemAvailabilityByMonth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemAvailabilityGroupByMonth': ((json['itemAvailabilityGroupByMonth'] as Array<any>).map(AvailableItemsFromJSON)),
        'countAllArticle': json['countAllArticle'],
    };
}

export function SalesOrderItemAvailabilityByMonthToJSON(value?: SalesOrderItemAvailabilityByMonth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'itemAvailabilityGroupByMonth': ((value.itemAvailabilityGroupByMonth as Array<any>).map(AvailableItemsToJSON)),
        'countAllArticle': value.countAllArticle,
    };
}

