import { Anchor } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { hideNotification, showNotification } from "@mantine/notifications";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useGetRetailerBillingStopQuery } from "~/api/hooks/retailer/useGetRetailerBillingStopQuery";
import { RoutesEnum } from "~/ui/routes/RoutesEnum";

const BILLING_STOP_FLAG_STORAGE_KEY = "billing-stop-flag";

export const useBillingStopNotification = () => {
  const { t } = useTranslation();
  const { data: billingStopData, isError, isLoading } = useGetRetailerBillingStopQuery();

  const [billingStopFlag, setBillingStopFlag] = useLocalStorage<boolean>({
    key: BILLING_STOP_FLAG_STORAGE_KEY,
    getInitialValueInEffect: false,
    defaultValue: false,
  });

  useEffect(() => {
    if (isLoading || isError) return;

    const hasBillingstop = Boolean(billingStopData);
    const isBillingStopNew = hasBillingstop && !billingStopFlag;
    const isBillingStopLifted = !hasBillingstop && billingStopFlag;

    if (isBillingStopNew) {
      showNotification({
        id: "isBillingStopNew",
        message: (
          <Trans i18nKey={"billingStop.added"} t={t}>
            <Anchor href={`${RoutesEnum.RETAILER}/payment-agreement`} />
          </Trans>
        ),
        color: "red",
        autoClose: false,
      });
    }

    if (isBillingStopLifted) {
      hideNotification("isBillingStopLifted");
      showNotification({
        message: <Trans i18nKey={"billingStop.lifted"} t={t} />,
        color: "green",
        autoClose: 6000,
      });
    }

    setBillingStopFlag(hasBillingstop);
  }, [billingStopData, billingStopFlag, isLoading, isError, setBillingStopFlag, t]);
};
