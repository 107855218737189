import { ActionIcon, Group, Text, useMantineTheme } from "@mantine/core";
import { useToggle } from "@mantine/hooks";
import { IconMessage } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { FeedbackDrawer } from "./FeedbackDrawer";

const Feedback: React.FC = () => {
  const { t } = useTranslation();
  const [isOpen, toggle] = useToggle([false, true]);
  const theme = useMantineTheme();
  return (
    <>
      <Group>
        <ActionIcon size={32} w="100%" p="xs" color={"blue"} onClick={() => toggle()}>
          <IconMessage size="27" color={theme.colors.blue[5]} />
          <Text c="dark" pl="8px">
            {t("header.feedbackLabel")}
          </Text>
        </ActionIcon>
      </Group>
      <FeedbackDrawer isOpen={isOpen} onClose={() => toggle()} />
    </>
  );
};

export { Feedback };
