import { Box, Button, Center, useMantineTheme } from "@mantine/core";
import Lottie from "lottie-react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useGetSalesOrderItemAvailabilitySummaryQuery } from "~/api/hooks/salesOrders/useGetSalesOrderItemAvailabilitySummaryQuery";
import { formatCurrency } from "~/helper/Formatter/formatCurrency";
import { createSalesOrdersPageLink } from "~/ui/routes/DynamicRoutes/createSalesOrdersPageLink";

import { WidgetContainer } from "../../WidgetContainer/WidgetContainer";
import { OpenOrdersChart } from "./OpenOrdersChart";
import { OpenOrdersSkeleton } from "./OpenOrdersSkeleton";
import emptyWidget from "./animations/emptyWidget.json";
import {
  OpenOrdersColors,
  mapAvailabilitySummaryToPieChart,
} from "./mapper/mapAvailabilitySummaryToPieChart";

import { ResponsiveButtonGroup } from "~/ui/components/ResponsiveButtonGroup/ResponsiveButtonGroup";
import openOrderStyles from "./OpenOrdersWidget.module.css";

export interface PieCenterElement {
  label: string;
  value: number;
  totalAmount: string;
  link?: string;
}

const OpenOrdersWidget: React.FC = () => {
  const { t } = useTranslation();
  const ringRef = useRef<HTMLDivElement>(null);
  const { data: summaryQuery, isLoading } = useGetSalesOrderItemAvailabilitySummaryQuery();

  const theme = useMantineTheme();
  const chartColors: OpenOrdersColors = {
    IN_PREORDER: "hsl(27, 98%, 54%)",
    IN_STOCK: "hsl(131, 53%, 46%)",
    IN_STOCK_OVERDUE: theme.colors.red[5],
    INCOMING: "hsl(187, 80%, 42%)",
    ON_REQUEST: "hsl(210, 7%, 56%)",
  };

  const mappedDictData = mapAvailabilitySummaryToPieChart({
    salesOrderItemAvailabilities: summaryQuery,
    colors: chartColors,
    t,
  });
  const mappedData = Object.values(mappedDictData);
  const orderDictWithoutEmpty = mappedData.filter((data) => data.value !== 0);
  const quantitySum = orderDictWithoutEmpty.reduce((sum, current) => sum + current.value, 0);
  const costSum = orderDictWithoutEmpty.reduce((sum, current) => sum + current.totalCost, 0);

  const getSubTitleText = (): string => {
    if (quantitySum === 0) return t("dashboardPage.OpenOrderWidget.subtitle.noOrders");

    return t("dashboardPage.OpenOrderWidget.subtitle.order", {
      count: quantitySum,
      totalAmount: formatCurrency(costSum),
    });
  };

  return (
    <WidgetContainer
      isLoading={isLoading}
      title={t("dashboardPage.OpenOrderWidget.title")}
      subtitle={getSubTitleText()}
    >
      <Box className={openOrderStyles.pieWrapper} ref={ringRef} data-testid="PieChartWrapper">
        {isLoading && <OpenOrdersSkeleton />}

        {!isLoading && quantitySum === 0 && (
          <Center className={openOrderStyles.noDataWrapper}>
            <Lottie animationData={emptyWidget} loop={false} style={{ height: 200 }} />
          </Center>
        )}

        {!isLoading && orderDictWithoutEmpty.length > 0 && quantitySum !== 0 && (
          <OpenOrdersChart orderDictWithoutEmpty={orderDictWithoutEmpty} />
        )}
      </Box>

      <ResponsiveButtonGroup>
        <Button component={Link} to={createSalesOrdersPageLink({})} variant="outline">
          {t("dashboardPage.OpenOrderWidget.allOpenOrdersButton")}
        </Button>
      </ResponsiveButtonGroup>
    </WidgetContainer>
  );
};

export { OpenOrdersWidget };
