import { setUser } from "@sentry/react";
import { useGetAccountDetailsQuery } from "~/api/hooks/account/useGetAccountDetailsQuery";
import { useGetCurrentRetailerQuery } from "~/api/hooks/retailer/useGetCurrentRetailerQuery";

export const useSentryUserInit = () => {
  const { data: accountQueryData } = useGetAccountDetailsQuery();
  const { data: retailerData } = useGetCurrentRetailerQuery();

  if (accountQueryData && retailerData) {
    setUser({
      id: accountQueryData.id,
      email: accountQueryData.email,
      username: accountQueryData.username,
      companyName: retailerData.companyName,
      customerNumber: retailerData.customerNumber,
    });
  }
};
