import { z } from "zod";

import { KpiTilesPeriodsSchema } from "./KpiTilesPeriodsDefaultSchema";

export const DashboardSettingsSchema = z
  .object({
    gridItems: z
      .array(
        z.object({
          id: z.string(),
          disabled: z.boolean().default(false),
        }),
      )
      .catch([]),
    kpiTilePeriods: KpiTilesPeriodsSchema,
  })
  .nullable()
  .catch(null);
