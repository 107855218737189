import { Anchor, Divider, Drawer, Group, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { IconMail, IconPhone } from "@tabler/icons-react";
import { useGetApiAccess } from "~/api/hooks/apiAccess/useGetApiAccess";
import { useGetCurrentRetailerQuery } from "~/api/hooks/retailer/useGetCurrentRetailerQuery";
import { useResponsiveMode } from "~/hooks/ui/useResponsiveMode";
import styles from "./ContactDrawer.module.css";

interface ContactDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const ContactDrawer: React.FC<ContactDrawerProps> = (props) => {
  const { t } = useTranslation();
  const { onClose, isOpen } = props;

  const { data: retailerData } = useGetCurrentRetailerQuery();
  const salesTeamData = retailerData?.salesTeam;
  const apiAccess = useGetApiAccess();
  const { retailerApiDisabled, retailerApiAccess } = apiAccess.data ?? {};
  const retailerApiAccessExists = retailerApiAccess != null;

  const showApiSupport = !retailerApiDisabled && retailerApiAccessExists;

  const { isMobile } = useResponsiveMode();

  const transitionDuration = isMobile ? 0 : 500;

  const salesEmail =
    salesTeamData?.email && salesTeamData?.email !== "" ? salesTeamData.email : "info@heo.com";
  const salesPhone = salesTeamData?.phone;
  const hasNoContactData =
    (!salesEmail || salesTeamData?.email === "") && !salesPhone && salesTeamData?.phone === "";

  return (
    <Drawer
      opened={isOpen}
      onClose={onClose}
      title={
        <Text fw={"bold"} fz={"26px"}>
          {t("contact.title")}
        </Text>
      }
      size="lg"
      position="right"
      lockScroll={false}
      transitionProps={{
        transition: "rotate-left",
        duration: transitionDuration,
        timingFunction: "ease",
      }}
      classNames={{ header: styles.header, close: styles.closeButton }}
      className={styles.drawer}
      data-testid="contact-drawer"
    >
      <Divider />
      <Stack p={isMobile ? "md" : 36} pt="md" gap="xs">
        <Text fz={"md"}>{t(`contact.${hasNoContactData ? "infoTextFallback" : "infoText"}`)}</Text>

        <Stack gap="4">
          <Group gap="xs">
            <IconMail size={"18"} />
            <Anchor href={`mailto:${salesEmail}?subject=Kontaktanfrage`}>{salesEmail}</Anchor>
          </Group>

          {salesPhone && (
            <Group gap="xs">
              <IconPhone size={"18"} />
              <Anchor href={`tel:${salesPhone}`}>{salesPhone}</Anchor>
            </Group>
          )}
        </Stack>

        {showApiSupport && (
          <Stack gap="4" pt="xl">
            <Text>{t("contact.infoTextApi")}</Text>
            <Group gap="xs">
              <IconMail size={"18"} />
              <Anchor href="mailto:api@heo.com">api@heo.com</Anchor>
            </Group>
          </Stack>
        )}
      </Stack>
    </Drawer>
  );
};

export { ContactDrawer };
