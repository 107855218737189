/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProductSummaryResponse,
} from '../models';
import {
    ProductSummaryResponseFromJSON,
    ProductSummaryResponseToJSON,
} from '../models';

export interface GetProductSummaryByProductNumberRequest {
    productNumber: string;
}

/**
 * 
 */
export class ProductResourceApi extends runtime.BaseAPI {

    /**
     */
    async getProductSummaryByProductNumberRaw(requestParameters: GetProductSummaryByProductNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductSummaryResponse>> {
        if (requestParameters.productNumber === null || requestParameters.productNumber === undefined) {
            throw new runtime.RequiredError('productNumber','Required parameter requestParameters.productNumber was null or undefined when calling getProductSummaryByProductNumber.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/product/{productNumber}`.replace(`{${"productNumber"}}`, encodeURIComponent(String(requestParameters.productNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductSummaryResponseFromJSON(jsonValue));
    }

    /**
     */
    async getProductSummaryByProductNumber(requestParameters: GetProductSummaryByProductNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductSummaryResponse> {
        const response = await this.getProductSummaryByProductNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
