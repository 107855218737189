/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductItem
 */
export interface ProductItem {
    /**
     * 
     * @type {string}
     * @memberof ProductItem
     */
    productThumbnailUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ProductItem
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof ProductItem
     */
    productNumber: string;
}

/**
 * Check if a given object implements the ProductItem interface.
 */
export function instanceOfProductItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productThumbnailUrl" in value;
    isInstance = isInstance && "productName" in value;
    isInstance = isInstance && "productNumber" in value;

    return isInstance;
}

export function ProductItemFromJSON(json: any): ProductItem {
    return ProductItemFromJSONTyped(json, false);
}

export function ProductItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productThumbnailUrl': json['productThumbnailUrl'],
        'productName': json['productName'],
        'productNumber': json['productNumber'],
    };
}

export function ProductItemToJSON(value?: ProductItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productThumbnailUrl': value.productThumbnailUrl,
        'productName': value.productName,
        'productNumber': value.productNumber,
    };
}

