import { Button, Center, Space } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useGetProductClaimsOverviewQuery } from "~/api/hooks/dashboard/useGetProductClaimsOverviewQuery";
import { PeriodOption } from "~/common/enum/PeriodOption";
import { createProductClaimsLink } from "~/ui/routes/DynamicRoutes/createProductClaimsLink";
import { RoutesEnum } from "~/ui/routes/RoutesEnum";

import { ResponsiveButtonGroup } from "~/ui/components/ResponsiveButtonGroup/ResponsiveButtonGroup";
import { WidgetContainer } from "../../WidgetContainer/WidgetContainer";
import { ClaimBarChart } from "./ClaimBarChart";
import { LoadingSkeleton } from "./LoadingSkeleton";
import emptyWidget from "./animations/emptyWidget.json";
import { mapProductClaimQueryDataToBarChart } from "./mapProductClaimQueryDataToBarChart";

const ClaimWidget: React.FC = () => {
  const { t } = useTranslation();

  const productClaimsQuery = useGetProductClaimsOverviewQuery();
  const chartData = mapProductClaimQueryDataToBarChart(productClaimsQuery.data);
  const subtitle =
    chartData.totalSum === 0
      ? t("dashboardPage.OpenClaimsWidget.subtitle.noClaims")
      : t("dashboardPage.OpenClaimsWidget.subtitle.withClaims", {
          count: chartData.totalSum,
        });

  return (
    <WidgetContainer
      title={t("dashboardPage.OpenClaimsWidget.title")}
      subtitle={subtitle}
      isLoading={productClaimsQuery.isLoading}
    >
      {productClaimsQuery.isLoading && <LoadingSkeleton />}

      {!productClaimsQuery.isLoading && chartData.totalSum === 0 && (
        <Center>
          <Lottie animationData={emptyWidget} loop={false} style={{ height: 200 }} />
        </Center>
      )}

      {!productClaimsQuery.isLoading && chartData.totalSum !== 0 && (
        <ClaimBarChart {...chartData.productClaimWidgetDict} />
      )}

      <Space />

      <ResponsiveButtonGroup>
        <Button
          variant="outline"
          leftSection={<IconPlus />}
          component={Link}
          to={RoutesEnum.CREATE_PRODUCT_CLAIMS}
        >
          {t("dashboardPage.OpenClaimsWidget.newClaimButton")}
        </Button>
        <Button
          component={Link}
          to={createProductClaimsLink({ period: PeriodOption.ALL })}
          variant="outline"
        >
          {t("dashboardPage.OpenClaimsWidget.allClaimsButton")}
        </Button>
      </ResponsiveButtonGroup>
    </WidgetContainer>
  );
};

export { ClaimWidget };
