import { TFunction } from "i18next";

import {
  SalesOrderItemAvailabilitySummaryStatusEnum,
  SalesOrderItemAvailabilityTotalSummary,
} from "~/api/openapi";

import {
  SalesOrderStatusTileSummary,
  SalesOrderStatusTileSummaryDict,
} from "../interface/SalesOrderStatusTileSummary";

const emptySummary: Pick<SalesOrderStatusTileSummary, "value" | "totalCost"> = {
  value: 0,
  totalCost: 0,
};

export type OpenOrdersColors = Record<SalesOrderItemAvailabilitySummaryStatusEnum, string>;

export type MapAvailabilitySummaryToPieChartProps = {
  salesOrderItemAvailabilities: SalesOrderItemAvailabilityTotalSummary[] | undefined;
  colors: OpenOrdersColors | undefined;
  t: TFunction;
};

export const mapAvailabilitySummaryToPieChart = (
  props: MapAvailabilitySummaryToPieChartProps,
): SalesOrderStatusTileSummaryDict => {
  const { salesOrderItemAvailabilities, colors, t } = props;

  const dict: SalesOrderStatusTileSummaryDict = {
    [SalesOrderItemAvailabilitySummaryStatusEnum.InStock]: {
      ...emptySummary,
      id: t(`salesOrderAvailabilityStatus.${SalesOrderItemAvailabilitySummaryStatusEnum.InStock}`),
      label: t(
        `salesOrderAvailabilityStatus.${SalesOrderItemAvailabilitySummaryStatusEnum.InStock}`,
      ),
      linkId: SalesOrderItemAvailabilitySummaryStatusEnum.InStock,
      color: colors?.IN_STOCK ?? "",
    },
    [SalesOrderItemAvailabilitySummaryStatusEnum.Incoming]: {
      ...emptySummary,
      id: t(`salesOrderAvailabilityStatus.${SalesOrderItemAvailabilitySummaryStatusEnum.Incoming}`),
      label: t(
        `salesOrderAvailabilityStatus.${SalesOrderItemAvailabilitySummaryStatusEnum.Incoming}`,
      ),
      linkId: SalesOrderItemAvailabilitySummaryStatusEnum.Incoming,
      color: colors?.INCOMING ?? "",
    },
    [SalesOrderItemAvailabilitySummaryStatusEnum.InPreorder]: {
      ...emptySummary,
      id: t(
        `salesOrderAvailabilityStatus.${SalesOrderItemAvailabilitySummaryStatusEnum.InPreorder}`,
      ),
      label: t(
        `salesOrderAvailabilityStatus.${SalesOrderItemAvailabilitySummaryStatusEnum.InPreorder}`,
      ),
      linkId: SalesOrderItemAvailabilitySummaryStatusEnum.InPreorder,
      color: colors?.IN_PREORDER ?? "",
    },
    [SalesOrderItemAvailabilitySummaryStatusEnum.OnRequest]: {
      ...emptySummary,
      id: t(
        `salesOrderAvailabilityStatus.${SalesOrderItemAvailabilitySummaryStatusEnum.OnRequest}`,
      ),
      label: t(
        `salesOrderAvailabilityStatus.${SalesOrderItemAvailabilitySummaryStatusEnum.OnRequest}`,
      ),
      linkId: SalesOrderItemAvailabilitySummaryStatusEnum.OnRequest,
      color: colors?.ON_REQUEST ?? "",
    },
  };

  if (salesOrderItemAvailabilities) {
    let inStockOverdueTotalQuantity = 0;
    let inStockOverdueTotalAmount = 0;

    for (const summary of salesOrderItemAvailabilities) {
      if (summary.status === SalesOrderItemAvailabilitySummaryStatusEnum.InStockOverdue) {
        inStockOverdueTotalQuantity = summary.totalQuantity;
        inStockOverdueTotalAmount = summary.totalAmount;
        continue;
      }
      dict[summary.status] = {
        id: t(`salesOrderAvailabilityStatus.${summary.status}`),
        label: t(`salesOrderAvailabilityStatus.${summary.status}`),
        linkId: summary.status,
        color: colors?.[summary.status] ?? "",
        value: summary.totalQuantity,
        totalCost: summary.totalAmount,
      };

      if (summary.totalQuantity > 0) {
        dict[summary.status].value = summary.totalQuantity;
        dict[summary.status].totalCost = summary.totalAmount;
      }
    }

    const inStockTrans = t(
      `salesOrderAvailabilityStatus.${SalesOrderItemAvailabilitySummaryStatusEnum.InStock}`,
    );
    const inStockOverdueTrans = t(
      `salesOrderAvailabilityStatus.${SalesOrderItemAvailabilitySummaryStatusEnum.InStockOverdue}`,
    );

    const totalQuantity =
      dict[SalesOrderItemAvailabilitySummaryStatusEnum.InStock].value + inStockOverdueTotalQuantity;

    dict[SalesOrderItemAvailabilitySummaryStatusEnum.InStock] = {
      id: `${inStockTrans} ${t("incl")} ${
        inStockOverdueTrans ? inStockOverdueTrans.toLowerCase() : inStockOverdueTrans
      }`,
      label: `${inStockTrans} ${t("incl")} ${
        inStockOverdueTrans ? inStockOverdueTrans.toLowerCase() : inStockOverdueTrans
      }`,
      color: colors?.IN_STOCK ?? "",
      linkId: [
        SalesOrderItemAvailabilitySummaryStatusEnum.InStock,
        SalesOrderItemAvailabilitySummaryStatusEnum.InStockOverdue,
      ],
      value: totalQuantity,
      totalCost:
        dict[SalesOrderItemAvailabilitySummaryStatusEnum.InStock].totalCost +
        inStockOverdueTotalAmount,
    };
  }

  return dict;
};
