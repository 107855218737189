import { Box, Divider, Group } from "@mantine/core";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useAppConfig } from "~/config/AppConfig";
import { AnnouncementBanner } from "../../components/AnnouncementBanner/AnnouncementBanner";
import { HeoLogo } from "../../components/HeoLogo/HeoLogo";
import { MobileNavbar } from "../Navbar/MobileNavbar/MobileNavbar";
import { ActionBar } from "./ActionBar/ActionBar";
import styles from "./Header.module.css";
import { LanguageSwitch } from "./LanguageSwitch/LanguageSwitch";
import { UserMenu } from "./UserMenu/UserMenu";

interface HeaderProps {
  withUserMenu?: boolean;
  hideUserMenuLinks?: boolean;
  hideFeedbackButton?: boolean;
  hideNavbarLinks?: boolean;
  isMobileView?: boolean;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { withUserMenu, hideUserMenuLinks, hideFeedbackButton, isMobileView, hideNavbarLinks } =
    props;

  const appConfig = useAppConfig();

  const logoHeight = isMobileView ? 25 : 32;

  return (
    <Box className={clsx(styles.container, isMobileView && styles.mobile)}>
      <AnnouncementBanner className={styles.announcement} />
      <Group h={"100%"} wrap="nowrap" justify="space-between" px="sm" data-testid="headerGroup">
        {isMobileView && <MobileNavbar hideNavbarLinks={Boolean(hideNavbarLinks)} />}

        <Box component={Link} to="/" data-testid="headerLogo">
          <HeoLogo height={logoHeight} dataTestId="headerLogo" />
        </Box>

        <Group justify="right" align={"center"} wrap="nowrap">
          {!isMobileView && <ActionBar hideFeedbackButton={hideFeedbackButton} />}

          {appConfig.feature?.languageSwitch && !isMobileView && (
            <>
              <LanguageSwitch />
              <Divider orientation="vertical" size="xs" />
            </>
          )}

          {withUserMenu && (
            <UserMenu hideUserMenuLinks={hideUserMenuLinks} isMobileView={isMobileView} />
          )}
        </Group>
      </Group>
    </Box>
  );
};

export { Header };
