import { Button, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useGetMostRecentChangelog } from "~/api/hooks/changelog.hooks";
import { useLocalizedDateFormatter } from "~/hooks/date/useLocalizedDateFormatter";
import { useAppDispatch } from "~/store/store";

import { HeoModal } from "../HeoModal/HeoModal";
import { ModalFooter } from "../HeoModal/ModalFooter/ModalFooter";
import { HtmlRenderer } from "../HtmlRenderer/HtmlRenderer";
import { ReleaseNotesSliceActions } from "./store/ReleaseNotesSlice";

const allowedTags = [
  "br",
  "a",
  "b",
  "p",
  "span",
  "pre",
  "code",
  "div",
  "blockquote",
  "ul",
  "li",
  "ol",
  "strong",
  "img",
  "del",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
];

const allowedAttributes: string[] = ["href", "alt", "target", "src"];

const RELEASE_NOTES_LAST_SEEN_STORAGE_KEY = "release-notes-last-seen";

export interface ReleaseNotesProps {
  forceShow?: boolean;
  onClose?: () => void;
}

const ReleaseNotes: React.FC<ReleaseNotesProps> = (props) => {
  const { forceShow, onClose } = props;
  const dispatch = useAppDispatch();
  const changelogQuery = useGetMostRecentChangelog();
  const { t } = useTranslation();
  const [isOpen, handlers] = useDisclosure(true);
  const { formatDate } = useLocalizedDateFormatter();

  const handleClose = () => {
    if (changelogQuery.data) {
      localStorage.setItem(RELEASE_NOTES_LAST_SEEN_STORAGE_KEY, changelogQuery.data.commitHash);
    }
    dispatch(ReleaseNotesSliceActions.toggleReleaseNotesModal({ isOpen: false }));
    handlers.close();
    if (onClose) onClose();
  };

  useEffect(() => {
    dispatch(ReleaseNotesSliceActions.releaseNotesLoading(changelogQuery.isLoading));
  }, [changelogQuery.isLoading, dispatch]);

  const lastSeenHash = localStorage.getItem(RELEASE_NOTES_LAST_SEEN_STORAGE_KEY);

  if (!changelogQuery.data) return null;
  if (!forceShow && lastSeenHash === changelogQuery.data.commitHash) return null;

  dispatch(ReleaseNotesSliceActions.toggleReleaseNotesModal({ isOpen: true }));

  return (
    <HeoModal opened={isOpen} onClose={handleClose} size={"xl"} title={t("releaseNotes.title")}>
      <Text c={"dimmed"}>
        {t("releaseNotes.releaseDate", {
          date: formatDate(changelogQuery.data.releasedAt),
        })}
      </Text>

      <HtmlRenderer tags={allowedTags} attributes={allowedAttributes}>
        {changelogQuery.data.changes}
      </HtmlRenderer>
      <ModalFooter>
        <Button onClick={handleClose} data-testid="closeReleaseNotes">
          {t("releaseNotes.closeButtonLabel")}
        </Button>
      </ModalFooter>
    </HeoModal>
  );
};

export { ReleaseNotes };
