import { RefObject } from "react";
import { useResponsiveMode } from "../useResponsiveMode";

export type UseScrollTopProps = {
  ref: RefObject<HTMLElement>;
};

/**
 * Scrolls the closest scroller element back to the top
 * Needs either a ref passed in the parameters or the returned ref needs to be used
 * The ref either uses itself if its a scroller element otherwise it searches for the closest vertical scroll container
 */
export const useScrollTop = (props: UseScrollTopProps) => {
  const { ref } = props ?? {};

  const { isTouch } = useResponsiveMode();

  const scrollToTop = (options?: ScrollToOptions) => {
    const closestScroller = isTouch
      ? window
      : ref.current?.getAttribute("data-vertical-scroll") === "true"
        ? ref.current
        : ref.current?.closest('[data-vertical-scroll="true"]');

    closestScroller?.scrollTo({ top: isTouch ? -10 : 0, behavior: "smooth", ...options });
  };

  return { scrollToTop };
};
