/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeoTransactionNumber } from './HeoTransactionNumber';
import {
    HeoTransactionNumberFromJSON,
    HeoTransactionNumberFromJSONTyped,
    HeoTransactionNumberToJSON,
} from './HeoTransactionNumber';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';
import type { PaymentStatus } from './PaymentStatus';
import {
    PaymentStatusFromJSON,
    PaymentStatusFromJSONTyped,
    PaymentStatusToJSON,
} from './PaymentStatus';
import type { PaymentType } from './PaymentType';
import {
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './PaymentType';

/**
 * 
 * @export
 * @interface OpenInvoiceOverview
 */
export interface OpenInvoiceOverview {
    /**
     * 
     * @type {string}
     * @memberof OpenInvoiceOverview
     */
    id: string;
    /**
     * 
     * @type {HeoTransactionNumber}
     * @memberof OpenInvoiceOverview
     */
    number: HeoTransactionNumber;
    /**
     * 
     * @type {Date}
     * @memberof OpenInvoiceOverview
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof OpenInvoiceOverview
     */
    paymentDueDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof OpenInvoiceOverview
     */
    total: number;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof OpenInvoiceOverview
     */
    paymentStatus: PaymentStatus;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof OpenInvoiceOverview
     */
    paymentMethod: PaymentMethod;
    /**
     * 
     * @type {PaymentType}
     * @memberof OpenInvoiceOverview
     */
    paymentType: PaymentType;
}

/**
 * Check if a given object implements the OpenInvoiceOverview interface.
 */
export function instanceOfOpenInvoiceOverview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "paymentStatus" in value;
    isInstance = isInstance && "paymentMethod" in value;
    isInstance = isInstance && "paymentType" in value;

    return isInstance;
}

export function OpenInvoiceOverviewFromJSON(json: any): OpenInvoiceOverview {
    return OpenInvoiceOverviewFromJSONTyped(json, false);
}

export function OpenInvoiceOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenInvoiceOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': HeoTransactionNumberFromJSON(json['number']),
        'createdAt': (new Date(json['createdAt'])),
        'paymentDueDate': !exists(json, 'paymentDueDate') ? undefined : (new Date(json['paymentDueDate'])),
        'total': json['total'],
        'paymentStatus': PaymentStatusFromJSON(json['paymentStatus']),
        'paymentMethod': PaymentMethodFromJSON(json['paymentMethod']),
        'paymentType': PaymentTypeFromJSON(json['paymentType']),
    };
}

export function OpenInvoiceOverviewToJSON(value?: OpenInvoiceOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': HeoTransactionNumberToJSON(value.number),
        'createdAt': (value.createdAt.toISOString()),
        'paymentDueDate': value.paymentDueDate === undefined ? undefined : (value.paymentDueDate.toISOString().substr(0,10)),
        'total': value.total,
        'paymentStatus': PaymentStatusToJSON(value.paymentStatus),
        'paymentMethod': PaymentMethodToJSON(value.paymentMethod),
        'paymentType': PaymentTypeToJSON(value.paymentType),
    };
}

