import { z } from "zod";

import { DashboardSettingsSchema } from "./DashboardSettingsSchema";
import { SearchHistorySchema } from "./SearchHistorySchema";
import { TableColumnSettingsSchema } from "./TableColumnSettingsSchema";

export const AccountSettingsSchema = z.object({
  productPageGuidedTourSeen: z.boolean().optional().default(false).catch(false),
  invoicePageGuidedTourSeen: z.boolean().optional().default(false).catch(false),
  dashboardGuidedTourSeen: z.boolean().optional().default(false).catch(false),
  productClaimPageGuidedTourSeen: z.boolean().optional().default(false).catch(false),
  customerOrderPageGuidedTourSeen: z.boolean().optional().default(false).catch(false),
  salesOrderPageGuidedTourSeen: z.boolean().optional().default(false).catch(false),
  creditNotesPageGuidedTourSeen: z.boolean().optional().default(false).catch(false),
  tableColumnSettingsCreditNotes: TableColumnSettingsSchema,
  tableColumnSettingsSalesOrders: TableColumnSettingsSchema,
  tableColumnSettingsProducts: TableColumnSettingsSchema,
  tableColumnSettingsProductDetailSalesOrders: TableColumnSettingsSchema,
  tableColumnSettingsProductDetailsProductClaims: TableColumnSettingsSchema,
  tableColumnSettingsProductDetailsInvoices: TableColumnSettingsSchema,
  tableColumnSettingsProductDetailsCustomerOrders: TableColumnSettingsSchema,
  tableColumnSettingsProductClaims: TableColumnSettingsSchema,
  tableColumnSettingsClaimSelectProduct: TableColumnSettingsSchema,
  tableColumnSettingsInvoices: TableColumnSettingsSchema,
  tableColumnSettingsInvoiceDetailsInvoicePosition: TableColumnSettingsSchema,
  tableColumnSettingsCustomerOrders: TableColumnSettingsSchema,
  dashboardSettings: DashboardSettingsSchema,
  searchHistory: SearchHistorySchema,
});

export type TableColumnSettings = z.infer<typeof TableColumnSettingsSchema>;

export type AccountSettings = z.infer<typeof AccountSettingsSchema>;
export type AccountSettingsKeys = keyof AccountSettings;
