/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangeRequestCreateOrUpdateRequest,
  DraftDetails,
  MiddlewareError,
} from '../models';
import {
    ChangeRequestCreateOrUpdateRequestFromJSON,
    ChangeRequestCreateOrUpdateRequestToJSON,
    DraftDetailsFromJSON,
    DraftDetailsToJSON,
    MiddlewareErrorFromJSON,
    MiddlewareErrorToJSON,
} from '../models';

export interface DeleteStoreProfileDraftOfCurrentRetailerRequest {
    uuid: string;
}

export interface GetStoreProfileDraftDetailsOfCurrentRetailerRequest {
    uuid: string;
}

export interface UpdateStoreProfileDetailsDraftOfCurrentRetailerRequest {
    changeRequestCreateOrUpdateRequest: ChangeRequestCreateOrUpdateRequest;
}

/**
 * 
 */
export class StoreProfileDraftResourceApi extends runtime.BaseAPI {

    /**
     */
    async deleteStoreProfileDraftOfCurrentRetailerRaw(requestParameters: DeleteStoreProfileDraftOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling deleteStoreProfileDraftOfCurrentRetailer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/store-profile/draft/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteStoreProfileDraftOfCurrentRetailer(requestParameters: DeleteStoreProfileDraftOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStoreProfileDraftOfCurrentRetailerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getStoreProfileDraftDetailsOfCurrentRetailerRaw(requestParameters: GetStoreProfileDraftDetailsOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DraftDetails>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getStoreProfileDraftDetailsOfCurrentRetailer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/store-profile/draft/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DraftDetailsFromJSON(jsonValue));
    }

    /**
     */
    async getStoreProfileDraftDetailsOfCurrentRetailer(requestParameters: GetStoreProfileDraftDetailsOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DraftDetails> {
        const response = await this.getStoreProfileDraftDetailsOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateStoreProfileDetailsDraftOfCurrentRetailerRaw(requestParameters: UpdateStoreProfileDetailsDraftOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.changeRequestCreateOrUpdateRequest === null || requestParameters.changeRequestCreateOrUpdateRequest === undefined) {
            throw new runtime.RequiredError('changeRequestCreateOrUpdateRequest','Required parameter requestParameters.changeRequestCreateOrUpdateRequest was null or undefined when calling updateStoreProfileDetailsDraftOfCurrentRetailer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/store-profile/updatedraft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeRequestCreateOrUpdateRequestToJSON(requestParameters.changeRequestCreateOrUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateStoreProfileDetailsDraftOfCurrentRetailer(requestParameters: UpdateStoreProfileDetailsDraftOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateStoreProfileDetailsDraftOfCurrentRetailerRaw(requestParameters, initOverrides);
    }

}
