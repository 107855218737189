/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MiddlewareError,
  SetStoreProfileVisibilityRequest,
  StoreProfileDetails,
  StoreProfiles,
} from '../models';
import {
    MiddlewareErrorFromJSON,
    MiddlewareErrorToJSON,
    SetStoreProfileVisibilityRequestFromJSON,
    SetStoreProfileVisibilityRequestToJSON,
    StoreProfileDetailsFromJSON,
    StoreProfileDetailsToJSON,
    StoreProfilesFromJSON,
    StoreProfilesToJSON,
} from '../models';

export interface DeleteStoreProfileOfCurrentRetailerRequest {
    uuid: string;
}

export interface GetStoreProfileDetailsOfCurrentRetailerRequest {
    uuid: string;
}

export interface SetVisibilityOfStoreProfileOfCurrentRetailerRequest {
    uuid: string;
    setStoreProfileVisibilityRequest: SetStoreProfileVisibilityRequest;
}

/**
 * 
 */
export class StoreProfileResourceApi extends runtime.BaseAPI {

    /**
     */
    async deleteStoreProfileOfCurrentRetailerRaw(requestParameters: DeleteStoreProfileOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling deleteStoreProfileOfCurrentRetailer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/store-profile/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteStoreProfileOfCurrentRetailer(requestParameters: DeleteStoreProfileOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStoreProfileOfCurrentRetailerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getStoreProfileDetailsOfCurrentRetailerRaw(requestParameters: GetStoreProfileDetailsOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoreProfileDetails>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getStoreProfileDetailsOfCurrentRetailer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/store-profile/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoreProfileDetailsFromJSON(jsonValue));
    }

    /**
     */
    async getStoreProfileDetailsOfCurrentRetailer(requestParameters: GetStoreProfileDetailsOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoreProfileDetails> {
        const response = await this.getStoreProfileDetailsOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStoreProfilesOfCurrentRetailerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoreProfiles>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/store-profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoreProfilesFromJSON(jsonValue));
    }

    /**
     */
    async getStoreProfilesOfCurrentRetailer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoreProfiles> {
        const response = await this.getStoreProfilesOfCurrentRetailerRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async setVisibilityOfStoreProfileOfCurrentRetailerRaw(requestParameters: SetVisibilityOfStoreProfileOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling setVisibilityOfStoreProfileOfCurrentRetailer.');
        }

        if (requestParameters.setStoreProfileVisibilityRequest === null || requestParameters.setStoreProfileVisibilityRequest === undefined) {
            throw new runtime.RequiredError('setStoreProfileVisibilityRequest','Required parameter requestParameters.setStoreProfileVisibilityRequest was null or undefined when calling setVisibilityOfStoreProfileOfCurrentRetailer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/store-profile/{uuid}/visibility`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetStoreProfileVisibilityRequestToJSON(requestParameters.setStoreProfileVisibilityRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setVisibilityOfStoreProfileOfCurrentRetailer(requestParameters: SetVisibilityOfStoreProfileOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setVisibilityOfStoreProfileOfCurrentRetailerRaw(requestParameters, initOverrides);
    }

}
