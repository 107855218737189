import {
  Country,
  CustomerOrderItemSummaryStatusEnum,
  GrantedSettlement,
  OrderType,
  PaymentMethod,
  PaymentStatus,
  PreferredSettlement,
  ProductClaimProcessingStatus,
  ProductClaimReason,
  RetailerDetailsBusinessTypeEnum,
  SalesOrderItemAvailabilitySummaryStatusEnum,
} from "~/api/openapi";
import { PeriodOption } from "~/common/enum/PeriodOption";
import { RelativeMonthPrefix } from "~/hooks/date/useRelativeMonth";
import { CategoryType, MoodType } from "~/ui/components/Feedback/FeedbackDrawer";

export const GlobalDE = {
  All: "Alle",
  showMore: "Mehr anzeigen",
  showLess: "Weniger anzeigen",
  time: {
    suffix: "Uhr",
  },
  date: {
    lastWeek: "'Letzten' eeee ',' p",
    today: "'Heute,' p",
    yesterday: "'Gestern,' p",
    tomorrow: "'Morgen,' p",
  },
  incl: "inkl.",

  relativeMonth: {
    [RelativeMonthPrefix.START]: "Anfang",
    [RelativeMonthPrefix.MID]: "Mitte",
    [RelativeMonthPrefix.END]: "Ende",
  } as Record<RelativeMonthPrefix, string>,

  errors: {
    unknownErrorTitle: "Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.",
    unknownError: "Ein unbekannter Fehler ist aufgetreten.",
    runtimeErrorTitle: "Ein Fehler ist aufgetreten.",
    runtimeErrorReloadLabel: "Seite neu laden",
    runtimeErrorShowDetails: "Fehler anzeigen",
    runtimeErrorHideDetails: "Fehler verstecken",
    dynamicLoadError: "Die Seite konnte nicht geladen werden.",
    dynamicLoadErrorMeasure: "Lade die Seite erneut um das Problem zu beheben.",
    logoutError: "Beim Abmelden ist ein Fehler aufgetreten.",
    statusTitle: "Fehler {{ status }}",
  },

  pageTitle: {
    default: "Business Console - heo Business Console",
    prefix: "Business Console - ",
  },

  form: {
    errors: {
      required: "Bitte hier etwas eingeben.",
      mail: {
        invalid: "Eine gültige E-Mail-Adresse du eingeben musst!",
      },
      phone: {
        required: "Bitte eine Telefonnummer eintragen.",
      },
      max: "Die Eingabe darf maximal {{ max }} Zeichen lang sein.",
      nonempty: "Das Feld darf nicht leer sein.",
    },
  },

  header: {
    guidedTourLabel: "Guided Tour",
    feedbackLabel: "Feedback",
    toShopLabel: "Zum Shop",
    helpAndSupportLabel: "Hilfe & Support",
  },

  navBar: {
    homeLabel: "Home",
    productsLabel: "Produkte",
    customerOrdersLabel: "Bestellungen",
    invoicesLabel: "Rechnungen",
    productClaimsLabel: "Reklamationen",
    backorderLabel: "Rückstände",
    dashboadLabel: "Dashboard",
    creditsLabel: "Gutschriften",
    impressLabel: "Rechtliches",
    feedbackLabel: "Feedback",
    contactLabel: "Kontakt",
    version: {
      label: "Version",
      copy: "Kopieren",
      copied: "Kopiert",
    },
    expandNavBar: "Ausklappen",
    collapseNavBar: "Einklappen",
  },

  dropzone: {
    addMoreFiles: "Weitere Dateien hinzufügen",
  },

  aria: {
    productImageAltText: "Es wird eine Abbildung von Produkt {{ productName }} angezeigt.",
  },

  country: {
    DE: "Deutschland",
    AT: "Österreich",
    CH: "Schweiz",
    BE: "Belgien",
    DK: "Dänemark",
    ES: "Spanien",
    FI: "Finnland",
    FR: "Frankreich",
    GB: "Großbritannien",
    IE: "Irland",
    IT: "Italien",
    NL: "Niederlande",
    LU: "Luxemburg",
    PT: "Portugal",
    SE: "Schweden",
    BG: "Bulgarien",
    CZ: "Tschechien",
    EE: "Estland",
    GR: "Griechenland",
    HU: "Ungarn",
    LV: "Lettland",
    LT: "Litauen",
    PL: "Polen",
    RO: "Rumänien",
    SI: "Slowenien",
    SK: "Slowakei",
    NO: "Norwegen",
  } as Record<Country["iso2"], string>,

  productClaimReason: {
    [ProductClaimReason.Unknown]: "Unbekannt",
    [ProductClaimReason.DamagedPackaging]: "DAP (Beschädigte Verpackung)",
    [ProductClaimReason.DamagedProduct]: "DEF (Produktionsfehler, Abbruch, Kratzer am Produkt)",
    [ProductClaimReason.MissingParts]: "Fehlende Teile",
    [ProductClaimReason.Overdelivered]: "Überlieferung",
    [ProductClaimReason.Underdelivered]: "Unterlieferung",
    [ProductClaimReason.WronglyDelivered]: "Falscher Artikel geliefert",
    [ProductClaimReason.WronglyOrdered]: "Falsch bestellt",
    [ProductClaimReason.InferiorQuality]: "Qualität stimmt nicht",
    [ProductClaimReason.WrongSerialNumber]: "Seriennummern stimmen nicht",
  } as Record<ProductClaimReason, string>,

  productClaimProcessingStatus: {
    [ProductClaimProcessingStatus.ToBeSubmitted]: "Wird eingereicht",
    [ProductClaimProcessingStatus.Open]: "Offen",
    [ProductClaimProcessingStatus.Pending]: "In Bearbeitung",
    [ProductClaimProcessingStatus.Confirmed]: "Abgeschlossen",
    [ProductClaimProcessingStatus.Denied]: "Abgelehnt",
    [ProductClaimProcessingStatus.WaitingForSupplier]: "Warten auf Lieferant",
    [ProductClaimProcessingStatus.RetailerActionRequired]: "Händleraktion erforderlich",
  } as Record<ProductClaimProcessingStatus, string>,

  preferredSettlement: {
    [PreferredSettlement.FullReplacement]: "Ersatzartikel",
    [PreferredSettlement.PartialReplacement]: "Ersatzteillieferung",
    [PreferredSettlement.Refund]: "Gutschrift",
    [PreferredSettlement.Return]: "Rücksendung",
    [PreferredSettlement.Purchase]: "Kauf",
    [PreferredSettlement.PurchaseAndRedelivery]: "Kauf & Nachlieferung",
    [PreferredSettlement.ReturnAndRedelivery]: "Rücksendung & Nachlieferung",
  } as Record<PreferredSettlement, string>,

  grantedSettlement: {
    [GrantedSettlement.FullRefund]: "Vollgutschrift",
    [GrantedSettlement.FullReplacement]: "Ersatzartikel",
    [GrantedSettlement.PartialRefund]: "Teilgutschrift",
    [GrantedSettlement.PartialReplacement]: "Ersatzteillieferung",
    [GrantedSettlement.Unknown]: "Offen",
    [GrantedSettlement.Return]: "Rücksendung",
    [GrantedSettlement.Purchase]: "Kauf",
    [GrantedSettlement.PurchaseAndRedelivery]: "Kauf & Nachlieferung",
    [GrantedSettlement.ReturnAndRedelivery]: "Rücksendung & Nachlieferung",
    [GrantedSettlement.ReturnAndRefund]: "Rücksendung & Gutschrift",
  } as Record<GrantedSettlement, string>,

  paymentMethod: {
    CASH_ON_DELIVERY: "Nachnahme",
    DIRECT_DEBIT: "Lastschrift",
    PREPAYMENT: "Vorkasse",
    INVOICE: "Rechnung",
    CHEQUE: "Scheck",
    CASH: "Bar",
    CREDIT_CARD: "Kreditkarte",
    PAYPAL: "PayPal",
    SEPA_DIRECT_DEBIT: "SEPA Lastschrift",
    FACTORING: "Rechnung",
    BRAINTREE: "Braintree",
    SHOPIFY_PAYMENT: "Shopify",
    SHOPIFY_PAYMENT_PAYPAL: "Shopify PayPal",
    OTHER: "Andere",
  } as Record<PaymentMethod, string>,

  orderTypeEnum: {
    DROPSHIPPING: "Dropshipping",
  } as Record<OrderType, string>,

  paymentStatus: {
    [PaymentStatus.Open]: "Offen",
    [PaymentStatus.Overdue]: "Überfällig",
    [PaymentStatus.Paid]: "Bezahlt",
    [PaymentStatus.Canceled]: "Storniert",
  } as Record<PaymentStatus, string>,

  periodSelect: {
    [PeriodOption.SEVEN_DAYS]: "7 Tage",
    [PeriodOption.TWO_WEEKS]: "14 Tage",
    [PeriodOption.ONE_MONTH]: "1 Monat",
    [PeriodOption.THREE_MONTHS]: "3 Monate",
    [PeriodOption.SIX_MONTHS]: "6 Monate",
    [PeriodOption.TWELVE_MONTHS]: "12 Monate",
    [PeriodOption.START_OF_YEAR]: "Seit Jahresbeginn",
    [PeriodOption.ALL]: "Alle",
    [PeriodOption.OLDER_TWELVE_MONTH]: "Älter als 12 Monate",
    [PeriodOption.CUSTOM]: "Individuell",
  } as Record<PeriodOption, string>,

  customerOrderItemStatus: {
    [CustomerOrderItemSummaryStatusEnum.Confirmed]: "Bestätigte",
    [CustomerOrderItemSummaryStatusEnum.Unconfirmed]: "Unbestätigte",
  } as Record<CustomerOrderItemSummaryStatusEnum, string>,

  salesOrderAvailabilityStatus: {
    [SalesOrderItemAvailabilitySummaryStatusEnum.InStock]: "Lagernd",
    [SalesOrderItemAvailabilitySummaryStatusEnum.InStockOverdue]: "Überfällig",
    [SalesOrderItemAvailabilitySummaryStatusEnum.Incoming]: "In Zulauf",
    [SalesOrderItemAvailabilitySummaryStatusEnum.OnRequest]: "Auf Anfrage",
    [SalesOrderItemAvailabilitySummaryStatusEnum.InPreorder]: "In Vorbestellung",
  } as Record<SalesOrderItemAvailabilitySummaryStatusEnum, string>,

  businessType: {
    [RetailerDetailsBusinessTypeEnum.Store]: "Geschäft",
    [RetailerDetailsBusinessTypeEnum.Wholesale]: "Großhandel",
    [RetailerDetailsBusinessTypeEnum.Ecommerce]: "E-Commerce",
    [RetailerDetailsBusinessTypeEnum.StoreAndEcommerce]: "Geschäft & E-Commerce",
    [RetailerDetailsBusinessTypeEnum.Other]: "---",
  } as Record<RetailerDetailsBusinessTypeEnum, string>,

  fileUpload: {
    uploadText: "Dateien hier ablegen oder klicken",
    uploadTextSingle: "Datei hier ablegen oder klicken",
    uploadAccepted: "Dateien ablegen",
    uploadAcceptedSingle: "Datei ablegen",
    uploadRejected: "Falsches Dateiformat",
    tooManyFiles: "Es kann nur ein Bild hochgeladen werden.",
    uploadRejectedDetails: {
      "file-invalid-type": "Falsches Dateiformat",
      "file-too-large": "Datei zu groß",
    } as Record<string, string>,
    uploadRejectedDetailNotification: "Fehler bei Dateien: {{wrongFiles}}",
    mimeTypes: {
      "image/png": "PNG",
      "image/gif": "GIF",
      "image/jpeg": "JPEG",
      "image/svg+xml": "SVG",
      "image/webp": "WEBP",
      "application/pdf": "PDF",
    } as Record<string, string>,
  },

  helpAndSupportMenu: {
    guidedTour: "Guided Tour",
    contact: "Kontakt",
  },

  userMenu: {
    accountSettingsLink: "Mein Konto",
    companyProfileLink: "Mein Unternehmen",
    myStoreProfileLink: "Meine Store-Profile",
    logoutLink: "Abmelden",
    title: "Mein Bereich",
    myServices: "Meine Services",
    manageApi: "heoGATE - Retailer API",
    downloadProductCatalog: "Produktkatalog herunterladen",
    copyProductCatalogLink: "Produktkatalog-Link kopieren",
    linkCopied: "Link kopiert",
    releaseNotes: "Release Notes",
  },

  copyGroup: {
    copyTooltipLabel: "Kopieren",
    copiedTooltipLabel: "Kopiert",
  },

  contact: {
    title: "Kontakt",
    infoText:
      "Hast du Fragen oder benötigst Unterstützung? Unser Sales-Kollegen stehen dir gerne zur Verfügung. Wähle eine der folgenden Kontaktmöglichkeiten:",
    infoTextFallback:
      "Hast du Fragen oder benötigst Unterstützung? Unser Sales-Kollegen stehen dir gerne zur Verfügung. Du kannst uns per E-Mail unter der folgenden Adresse erreichen:",

    infoTextApi:
      "Solltest du Fragen zum API-Zugang (heoGATE) und dessen Nutzung haben, verwendet hierfür bitte folgende E-Mail-Adresse:",
  },

  feedback: {
    buttonTitle: "Feedback",

    drawer: {
      title: "Dein Feedback",

      mood: {
        title: "Wie ist dein genereller Eindruck von der Business Console?",
        [MoodType.VERY_BAD]: "Sehr schlecht",
        [MoodType.BAD]: "Schlecht",
        [MoodType.NEUTRAL]: "Neutral",
        [MoodType.GOOD]: "Gut",
        [MoodType.VERY_GOOD]: "Sehr gut",
      } as Record<string, string>,
      category: {
        title: "Zu welchem Thema möchtest du Feedback geben?",
        required: "Bitte wähle ein Thema aus",
        [CategoryType.IMPROVEMENT]: "Verbesserung",
        [CategoryType.ERROR]: "Fehler",
        [CategoryType.FEATURE]: "Neue Feature-Idee",
      } as Record<string, string>,
      summary: {
        title: "Kurze Zusammenfassung / Titel des Feedbacks",
        placeholder: "Titel Ihres Feedbacks (max. 60 Zeichen)",
        required: "Bitte gib einen Titel ein",
      },
      description: {
        title: "Bitte nachfolgend dein Feedback eintragen:",
        placeholder: "Feedback eingeben (max. 500 Zeichen)",
        required: "Bitte gib eine Beschreibung deines Anliegens ein",
      },
      submitCloseTitle: "Bestätigung",
      submitCloseText:
        "Möchtest du das Feedbackformular wirklich schließen und alle bereits eingegeben Inhalte verwerfen?",
      cancel: "Lieber nicht",
      submitCancel: "Lieber nicht",
      submitClose: "Weg damit",
      send: "Feedback abschicken",
      submitMessage: "Vielen Dank für dein Feedback. Es wurde erfolgreich an uns abgeschickt.",
      requiredText: "Felder mit * sind Pflichtfelder.",
    },
  },

  releaseNotes: {
    title: "Was ist neu?",
    releaseDate: "Released am {{ date }}",
    closeButtonLabel: "Schließen",
  },

  signedPdfLink: {
    defaultError:
      "Beim Abrufen des Dokuments ist ein Fehler aufgetreten. Bitte versuch es später erneut.",
  },

  footer: {
    backendVersionLabel: "BE-Version",
    frontendVersionLabel: "FE-Version",
    buildDateLabel: "gebaut am",
    impressumLinkLabel: "Impressum",
    privacyLinkLabel: "Datenschutz",
    copyrightLinkLabel: "Copyright",
    copyTooltip: "Version kopieren",
    copiedTooltip: "Kopiert",
  },

  datatable: {
    noNextPage: "Es gibt keine weiteren Einträge.",
  },

  filter: {
    resetAll: "Filter zurücksetzen",
  },

  orderList: {
    showTooltip: "Einblenden",
    hideTooltip: "Ausblenden",
  },

  announcements: {
    moreInfo: "Mehr lesen",
    modalTitle: "Ankündigungen",
  },

  tableColumnSettings: {
    toggleTooltip: "Spalteneinstellungen",
    save: "Speichern",
    cancel: "Abbrechen",
    reset: "Zurücksetzen",
  },

  factoringLimit: {
    exceeded:
      "Du hast dein Factoring-Limit von <strong>{{ limit }}</strong> um <strong>{{ surplus }}</strong> überschritten!",
    cleared:
      "Du liegst mit einer Nutzung von <strong>{{ usage }}</strong> wieder unter deinem Factoring-Limit von <strong>{{ limit }}</strong>.",
  },

  billingStop: {
    added:
      "In deinem Konto wurde ein Faktura-Stopp eingetragen. Mehr Infos findest du <0>hier</0>.",
    lifted: "Der Faktura-Stopp für dein Konto wurde aufgehoben.",
  },

  lastSynced: {
    dataStatusLabel: "Stand: ",
    notAvailable: "nicht verfügbar",
  },

  imageCropper: {
    newImageTooltip: "Neues Bild auswählen",
    resetViewTooltip: "Ansicht zurücksetzen",
    zoomInTooltip: "Hineinzoomen",
    zoomOutTooltip: "Herauszoomen",
    outputTooSmallTooltip: "Der zugeschnittene Bereich ist zu klein",
  },

  preventPageLeave: {
    modalTitle: "Du hast noch ungespeicherte Änderungen!",
    modalDescription:
      "Du hast noch ungespeicherte Änderungen die beim Verlassen der Seite verloren gehen würden.",
    confirmPageLeave: "Seite verlassen und Änderungen verwerfen",
    cancelPageLeave: "Auf der Seite bleiben",
  },

  longTextLineCell: {
    copy: "Zusatzinfo kopieren",
    copied: "Zusatzinfo wurde kopiert",
  },

  googlePlaceSelect: {
    noData: "Für deine Adresse konnte kein Google Place gefunden werden.",
  },

  gridLayout: {
    staticLockTooltip: "Dieses Widget kann nicht verschoben werden.",
    hideWidgetTooltip: "Dieses Widget ausblenden",
    showWidgetTooltip: "Dieses Widget einblenden",
  },

  pageFilterModal: {
    sortBy: "Sortiere nach",
    showResults: "Ergebnisse anzeigen",
    resetFilter: "Filter zurücksetzen",
  },

  viewModeToggle: {
    switchToTableMode: "Zur Tabellenansicht wechseln",
    switchToCardsMode: "Zur Kachelansicht wechseln",
  },
};
