/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PublicationUrl } from './PublicationUrl';
import {
    PublicationUrlFromJSON,
    PublicationUrlFromJSONTyped,
    PublicationUrlToJSON,
} from './PublicationUrl';

/**
 * 
 * @export
 * @interface ActivateApiAccessForCurrentRetailerCommand
 */
export interface ActivateApiAccessForCurrentRetailerCommand {
    /**
     * 
     * @type {Set<PublicationUrl>}
     * @memberof ActivateApiAccessForCurrentRetailerCommand
     */
    publicationUrls: Set<PublicationUrl>;
    /**
     * 
     * @type {boolean}
     * @memberof ActivateApiAccessForCurrentRetailerCommand
     */
    generalTermsAccepted: boolean;
}

/**
 * Check if a given object implements the ActivateApiAccessForCurrentRetailerCommand interface.
 */
export function instanceOfActivateApiAccessForCurrentRetailerCommand(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "publicationUrls" in value;
    isInstance = isInstance && "generalTermsAccepted" in value;

    return isInstance;
}

export function ActivateApiAccessForCurrentRetailerCommandFromJSON(json: any): ActivateApiAccessForCurrentRetailerCommand {
    return ActivateApiAccessForCurrentRetailerCommandFromJSONTyped(json, false);
}

export function ActivateApiAccessForCurrentRetailerCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivateApiAccessForCurrentRetailerCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'publicationUrls': (new Set((json['publicationUrls'] as Array<any>).map(PublicationUrlFromJSON))),
        'generalTermsAccepted': json['generalTermsAccepted'],
    };
}

export function ActivateApiAccessForCurrentRetailerCommandToJSON(value?: ActivateApiAccessForCurrentRetailerCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'publicationUrls': (Array.from(value.publicationUrls as Set<any>).map(PublicationUrlToJSON)),
        'generalTermsAccepted': value.generalTermsAccepted,
    };
}

