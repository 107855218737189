/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClaimableInvoiceItem } from './ClaimableInvoiceItem';
import {
    ClaimableInvoiceItemFromJSON,
    ClaimableInvoiceItemFromJSONTyped,
    ClaimableInvoiceItemToJSON,
} from './ClaimableInvoiceItem';

/**
 * 
 * @export
 * @interface PagedResultClaimableInvoiceItem
 */
export interface PagedResultClaimableInvoiceItem {
    /**
     * 
     * @type {Array<ClaimableInvoiceItem>}
     * @memberof PagedResultClaimableInvoiceItem
     */
    content: Array<ClaimableInvoiceItem>;
    /**
     * 
     * @type {number}
     * @memberof PagedResultClaimableInvoiceItem
     */
    totalElements: number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultClaimableInvoiceItem
     */
    totalPages: number;
}

/**
 * Check if a given object implements the PagedResultClaimableInvoiceItem interface.
 */
export function instanceOfPagedResultClaimableInvoiceItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "totalElements" in value;
    isInstance = isInstance && "totalPages" in value;

    return isInstance;
}

export function PagedResultClaimableInvoiceItemFromJSON(json: any): PagedResultClaimableInvoiceItem {
    return PagedResultClaimableInvoiceItemFromJSONTyped(json, false);
}

export function PagedResultClaimableInvoiceItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedResultClaimableInvoiceItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': ((json['content'] as Array<any>).map(ClaimableInvoiceItemFromJSON)),
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
    };
}

export function PagedResultClaimableInvoiceItemToJSON(value?: PagedResultClaimableInvoiceItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': ((value.content as Array<any>).map(ClaimableInvoiceItemToJSON)),
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
    };
}

