import { ProductClaimsPageDE } from "../de/de.productClaimsPage";

export const ProductClaimsPageEN: typeof ProductClaimsPageDE = {
  pageTitle: "My claims",
  title: "My claims ({{ count }})",
  filter: {
    title: "Filter & Sort",
    statusPlaceholder: "All status",
    claimNumberPlaceholder: "Search for claim number",
    fulltextPlaceholder: "Search claims (at least 3 characters)",
    reasonPlaceholder: "All reasons",
    nothingFound: "No results found",
    notification:
      "According to our terms and conditions, items that were delivered more than 12 months ago are excluded from a claim. A claim is only possible as a gesture of goodwill.",
  },
  table: {
    head: {
      claimNumber: "Claim no.",
      date: "Date",
      invoiceNumber: "Invoice no.",
      reason: "Reason",
      article: "Article",
      product: "Article",
      status: "Status",
      creditNoteNumber: "Credit note no.",
      rmaNumber: "RMA no.",
    },
    noClaimsAvailable: "No claims created yet.",
    noClaimsFound: "No claims found that match your search criteria.",
    productOneClickTooltip: "Search list for item {{productNumber}}",
  },
  detail: {
    informationTabTitel: "Information",
    activityListTabTitel: "Communication history",
    claimNumber: "Claim no.: {{ claimNumber }}",
    productNumberLabel: "Item no.",
    invoiceLabel: "Invoice",
    invoiceDateLabel: "Invoice date",
    serialNumberLabel: "Serial number",
    reasonLabel: "Reason",
    overdeliveryLabel: "Excess quantity delivered",
    underdeliveryLabel: "Insufficient quantity delivered",
    preferredSettlementLabel: "Requested preference",
    grantedSettlementLabel: "Confirmed preference",
    creditNoteNumberLabel: "Credit note number",
    rmaNumberLabel: "RMA no.",
    additionalInfoLabel: "Description",
    noAdditionalInfo: "No description available",
    photoLabel: "Photo documentation",
    noPhotosAvailable: "No photos stored",
    statusLabel: "Status",
    activity: {
      title: "Communication history",
      systemAuthor: "System",
      claimCreatedMessage: "Claim successfully submitted.",
    },
  },
  openCreateClaimsPageButton: "Create new claim",
};
