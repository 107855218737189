/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactPersonRequestParams
 */
export interface ContactPersonRequestParams {
    /**
     * 
     * @type {string}
     * @memberof ContactPersonRequestParams
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonRequestParams
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonRequestParams
     */
    mail: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonRequestParams
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonRequestParams
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPersonRequestParams
     */
    contactType: ContactPersonRequestParamsContactTypeEnum;
}


/**
 * @export
 */
export const ContactPersonRequestParamsContactTypeEnum = {
    Delivery: 'DELIVERY',
    Invoice: 'INVOICE',
    Contractual: 'CONTRACTUAL',
    Other: 'OTHER'
} as const;
export type ContactPersonRequestParamsContactTypeEnum = typeof ContactPersonRequestParamsContactTypeEnum[keyof typeof ContactPersonRequestParamsContactTypeEnum];


/**
 * Check if a given object implements the ContactPersonRequestParams interface.
 */
export function instanceOfContactPersonRequestParams(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "mail" in value;
    isInstance = isInstance && "contactType" in value;

    return isInstance;
}

export function ContactPersonRequestParamsFromJSON(json: any): ContactPersonRequestParams {
    return ContactPersonRequestParamsFromJSONTyped(json, false);
}

export function ContactPersonRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactPersonRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'mail': json['mail'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'contactType': json['contactType'],
    };
}

export function ContactPersonRequestParamsToJSON(value?: ContactPersonRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'mail': value.mail,
        'phone': value.phone,
        'mobile': value.mobile,
        'contactType': value.contactType,
    };
}

