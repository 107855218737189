import { useAuth } from "react-oidc-context";

import { globalErrorHandler } from "~/common/notifications/globalErrorHandler";
import { useAppConfig } from "~/config/AppConfig";
import { useHeoLocale } from "~/hooks/useHeoLocale";
import { queryClient } from "~/queryClient";

import { captureException } from "@sentry/react";
import {
  AccountResourceApi,
  AccountUiSettingsResourceApi,
  AnnouncementResourceApi,
  ApiAccessResourceApi,
  BusinessTypeResourceApi,
  ChangelogResourceApi,
  ClaimRessourceApi,
  ClientResourceApi,
  Configuration,
  ContactPersonResourceApi,
  CountryResourceApi,
  CreditNoteResourceApi,
  CustomerOrderItemResourceApi,
  CustomerOrderResourceApi,
  DashboardResourceApi,
  FeedbackResourceApi,
  GooglePlaceResourceApi,
  HealthResourceApi,
  InvoiceItemResourceApi,
  InvoiceResourceApi,
  LoginResourceApi,
  LogoutResourceApi,
  ManufacturerResourceApi,
  MeResourceApi,
  Middleware,
  ProductOrderStatusResourceApi,
  ProductPricesResourceApi,
  ProductResourceApi,
  ProductTypeResourceApi,
  RetailerAddressResourceApi,
  RetailerNotificationResourceApi,
  RetailerResourceApi,
  SalesOrderItemAvailabilityResourceApi,
  SalesOrderItemResourceApi,
  StoreProfileChangeRequestResourceApi,
  StoreProfileDraftResourceApi,
  StoreProfileImageResourceApi,
  StoreProfileMasterDataResourceApi,
  StoreProfilePreviewResourceApi,
  StoreProfileResourceApi,
  TotalSalesResourceApi,
} from "./openapi";
import { useAuthHeader } from "./useAuthHeader";

export const useRestAPI = () => {
  const appConfig = useAppConfig();
  const auth = useAuth();
  const authHeader = useAuthHeader();
  const heoLocale = useHeoLocale();

  const unauthenticatedMiddleware: Middleware = {
    post: async (ctx) => {
      // TODO: Remove once we fully migrated to keycloak and dont need to invalidate getMe anymore
      // This fixes the issue that we lose the login error when the login request fails
      if (ctx.url.endsWith("/login")) return;

      if (ctx.response.status === 401) {
        await auth.removeUser();
        queryClient.invalidateQueries({ queryKey: ["getMe"] });
      }
    },
  };

  const errorHandlerMiddlerware: Middleware = {
    onError: async (ctx) => {
      captureException(ctx);
    },
    post: async (ctx) => {
      globalErrorHandler(ctx.response);
    },
  };

  const composedConfig = new Configuration({
    basePath: appConfig.api.backendBaseUrl,
    credentials: "include",
    middleware: [unauthenticatedMiddleware, errorHandlerMiddlerware],
    headers: { ...authHeader, "Accept-Language": heoLocale },
  });

  return {
    login: new LoginResourceApi(composedConfig),
    logout: new LogoutResourceApi(composedConfig),
    health: new HealthResourceApi(composedConfig),
    announcement: new AnnouncementResourceApi(composedConfig),
    claim: new ClaimRessourceApi(composedConfig),
    contactPerson: new ContactPersonResourceApi(composedConfig),
    creditNotes: new CreditNoteResourceApi(composedConfig),
    invoices: new InvoiceResourceApi(composedConfig),
    customerOrder: new CustomerOrderResourceApi(composedConfig),
    changelog: new ChangelogResourceApi(composedConfig),
    dashboard: new DashboardResourceApi(composedConfig),
    product: new ProductResourceApi(composedConfig),
    productPrices: new ProductPricesResourceApi(composedConfig),
    feedback: new FeedbackResourceApi(composedConfig),
    me: new MeResourceApi(composedConfig),
    account: {
      base: new AccountResourceApi(composedConfig),
      settings: new AccountUiSettingsResourceApi(composedConfig),
    },
    retailer: new RetailerResourceApi(composedConfig),
    productType: new ProductTypeResourceApi(composedConfig),
    manufacturer: new ManufacturerResourceApi(composedConfig),
    salesOrderItemAvailability: new SalesOrderItemAvailabilityResourceApi(composedConfig),
    country: new CountryResourceApi(composedConfig),
    apiAccess: new ApiAccessResourceApi(composedConfig),
    apiClient: new ClientResourceApi(composedConfig),
    productOrderStatus: new ProductOrderStatusResourceApi(composedConfig),
    businessType: new BusinessTypeResourceApi(composedConfig),
    invoiceItem: new InvoiceItemResourceApi(composedConfig),
    customerOrderItem: new CustomerOrderItemResourceApi(composedConfig),
    salesOrderItem: new SalesOrderItemResourceApi(composedConfig),
    retailerAddress: new RetailerAddressResourceApi(composedConfig),
    storeProfile: {
      masterData: new StoreProfileMasterDataResourceApi(composedConfig),
      image: new StoreProfileImageResourceApi(composedConfig),
      draft: new StoreProfileDraftResourceApi(composedConfig),
      changeRequest: new StoreProfileChangeRequestResourceApi(composedConfig),
      base: new StoreProfileResourceApi(composedConfig),
      preview: new StoreProfilePreviewResourceApi(composedConfig),
    },
    totalSales: new TotalSalesResourceApi(composedConfig),
    googlePlace: new GooglePlaceResourceApi(composedConfig),
    retailerNotification: new RetailerNotificationResourceApi(composedConfig),
  };
};
