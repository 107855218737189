/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetUiSettingsRequestBody
 */
export interface SetUiSettingsRequestBody {
    /**
     * 
     * @type {string}
     * @memberof SetUiSettingsRequestBody
     */
    json: string;
}

/**
 * Check if a given object implements the SetUiSettingsRequestBody interface.
 */
export function instanceOfSetUiSettingsRequestBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "json" in value;

    return isInstance;
}

export function SetUiSettingsRequestBodyFromJSON(json: any): SetUiSettingsRequestBody {
    return SetUiSettingsRequestBodyFromJSONTyped(json, false);
}

export function SetUiSettingsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetUiSettingsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'json': json['json'],
    };
}

export function SetUiSettingsRequestBodyToJSON(value?: SetUiSettingsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'json': value.json,
    };
}

