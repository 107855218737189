import { Grid } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { useGetKpiDataOverviewQuery } from "~/api/hooks/dashboard/useGetKpiDataOverviewQuery";
import { GetKpiDataOverviewOfCurrentRetailerRequest } from "~/api/openapi";
import { PeriodRange } from "~/common/classes/PeriodRange";
import { PeriodOption } from "~/common/enum/PeriodOption";
import { KpiTilesPeriods } from "~/hooks/accountSettings/KpiTilesPeriodsDefaultSchema";
import { createCreditNotesLink } from "~/ui/routes/DynamicRoutes/createCreditNotesLink";
import { createCustomerOrderPageLink } from "~/ui/routes/DynamicRoutes/createCustomerOrderPageLink";
import { createInvoiceLink } from "~/ui/routes/DynamicRoutes/createInvoiceLink";
import { createSalesOrdersPageLink } from "~/ui/routes/DynamicRoutes/createSalesOrdersPageLink";

import { KpiTile } from "./KpiTile";

type KpiContainerProps = {
  isEditing: boolean;
  kpiTilesPeriods: KpiTilesPeriods | null;
  onChange: (periodRanges: KpiTilesPeriods) => void;
};
const KpiContainer: React.FC<KpiContainerProps> = (props) => {
  const { isEditing, onChange, kpiTilesPeriods } = props;
  const { t } = useTranslation(undefined, { keyPrefix: "dashboardPage.tiles" });

  const requestParams: GetKpiDataOverviewOfCurrentRetailerRequest = {
    customerOrderCreatedBetweenFrom: PeriodRange.fromString(kpiTilesPeriods?.customerOrderPeriod)
      .from,
    customerOrderCreatedBetweenUntil: PeriodRange.fromString(kpiTilesPeriods?.customerOrderPeriod)
      .until,
    salesOrderItemAvailabilityCreatedBetweenFrom: PeriodRange.fromString(
      kpiTilesPeriods?.salesOrderPeriod,
    ).from,
    salesOrderItemAvailabilityCreatedBetweenUntil: PeriodRange.fromString(
      kpiTilesPeriods?.salesOrderPeriod,
    ).until,
    invoiceCreatedBetweenFrom: PeriodRange.fromString(kpiTilesPeriods?.invoicePeriod).from,
    invoiceCreatedBetweenUntil: PeriodRange.fromString(kpiTilesPeriods?.invoicePeriod).until,
    creditNotesCreatedBetweenFrom: PeriodRange.fromString(kpiTilesPeriods?.creditNotePeriod).from,
    creditNotesCreatedBetweenUntil: PeriodRange.fromString(kpiTilesPeriods?.creditNotePeriod).until,
  };
  const loadKpiData = kpiTilesPeriods !== null;
  const { data: overviewData, isLoading } = useGetKpiDataOverviewQuery(requestParams, loadKpiData);

  const showLoadingSkeleton = isLoading || kpiTilesPeriods === null;

  return (
    <Grid overflow="hidden">
      <Grid.Col span={{ base: 6, md: 6, lg: 3 }}>
        <KpiTile
          title={t("customerOrderTitle")}
          count={overviewData?.customerOrderCount ?? 0}
          selectedPeriod={kpiTilesPeriods?.customerOrderPeriod}
          tooltipTitle={t("customerOrderTooltipTitle")}
          link={createCustomerOrderPageLink({
            period: kpiTilesPeriods?.customerOrderPeriod ?? PeriodOption.SEVEN_DAYS,
          })}
          isEditing={isEditing}
          onChange={(selectedPeriod: string) =>
            onChange({ ...kpiTilesPeriods, customerOrderPeriod: selectedPeriod })
          }
          isLoading={showLoadingSkeleton}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 6, md: 6, lg: 3 }}>
        <KpiTile
          title={t("salesOrderTiele")}
          count={overviewData?.salesOrderItemAvailabilityCount ?? 0}
          selectedPeriod={kpiTilesPeriods?.salesOrderPeriod}
          tooltipTitle={t("salesOrderTooltipTitle")}
          link={createSalesOrdersPageLink({
            createdPeriod: kpiTilesPeriods?.salesOrderPeriod ?? PeriodOption.SEVEN_DAYS,
          })}
          isEditing={isEditing}
          onChange={(selectedPeriod: string) =>
            onChange({ ...kpiTilesPeriods, salesOrderPeriod: selectedPeriod })
          }
          isLoading={showLoadingSkeleton}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 6, md: 6, lg: 3 }}>
        <KpiTile
          title={t("invoicesTitle")}
          count={overviewData?.invoiceCount ?? 0}
          selectedPeriod={kpiTilesPeriods?.invoicePeriod}
          tooltipTitle={t("invoicesTooltipTitle")}
          link={createInvoiceLink({
            period: kpiTilesPeriods?.invoicePeriod ?? PeriodOption.SEVEN_DAYS,
          })}
          isEditing={isEditing}
          onChange={(selectedPeriod: string) =>
            onChange({ ...kpiTilesPeriods, invoicePeriod: selectedPeriod })
          }
          isLoading={showLoadingSkeleton}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 6, md: 6, lg: 3 }}>
        <KpiTile
          title={t("creditNotesTitle")}
          count={overviewData?.creditNotesCount ?? 0}
          selectedPeriod={kpiTilesPeriods?.creditNotePeriod}
          tooltipTitle={t("creditNotesTooltipTitle")}
          link={createCreditNotesLink({
            period: kpiTilesPeriods?.creditNotePeriod ?? PeriodOption.SEVEN_DAYS,
          })}
          isEditing={isEditing}
          onChange={(selectedPeriod: string) =>
            onChange({ ...kpiTilesPeriods, creditNotePeriod: selectedPeriod })
          }
          isLoading={showLoadingSkeleton}
        />
      </Grid.Col>
    </Grid>
  );
};

export { KpiContainer };
