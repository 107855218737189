import { useQuery } from "@tanstack/react-query";

import { useRestAPI } from "../../useRestAPI";

export const GetSalesOrderItemAvailabilitySummaryQueryKey = "GetSalesOrderItemAvailabilitySummary";
export const useGetSalesOrderItemAvailabilitySummaryQuery = () => {
  const api = useRestAPI();
  return useQuery({
    queryKey: [GetSalesOrderItemAvailabilitySummaryQueryKey],
    queryFn: () =>
      api.salesOrderItemAvailability.getSalesOrderItemAvailabilityTotalOfCurrentRetailer(),
  });
};
