/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccountDetails,
  ChangeContactDetailsRequestBody,
  ChangeContactPermissionsRequestBody,
  ChangePasswordRequestBody,
} from '../models';
import {
    AccountDetailsFromJSON,
    AccountDetailsToJSON,
    ChangeContactDetailsRequestBodyFromJSON,
    ChangeContactDetailsRequestBodyToJSON,
    ChangeContactPermissionsRequestBodyFromJSON,
    ChangeContactPermissionsRequestBodyToJSON,
    ChangePasswordRequestBodyFromJSON,
    ChangePasswordRequestBodyToJSON,
} from '../models';

export interface ChangeContactDetailsRequest {
    changeContactDetailsRequestBody: ChangeContactDetailsRequestBody;
}

export interface ChangeContactPermissionsRequest {
    changeContactPermissionsRequestBody: ChangeContactPermissionsRequestBody;
}

export interface ChangePasswordRequest {
    changePasswordRequestBody: ChangePasswordRequestBody;
}

/**
 * 
 */
export class AccountResourceApi extends runtime.BaseAPI {

    /**
     */
    async changeContactDetailsRaw(requestParameters: ChangeContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.changeContactDetailsRequestBody === null || requestParameters.changeContactDetailsRequestBody === undefined) {
            throw new runtime.RequiredError('changeContactDetailsRequestBody','Required parameter requestParameters.changeContactDetailsRequestBody was null or undefined when calling changeContactDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/account/changeContactDetails`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeContactDetailsRequestBodyToJSON(requestParameters.changeContactDetailsRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeContactDetails(requestParameters: ChangeContactDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeContactDetailsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async changeContactPermissionsRaw(requestParameters: ChangeContactPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.changeContactPermissionsRequestBody === null || requestParameters.changeContactPermissionsRequestBody === undefined) {
            throw new runtime.RequiredError('changeContactPermissionsRequestBody','Required parameter requestParameters.changeContactPermissionsRequestBody was null or undefined when calling changeContactPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/account/changeContactPermissions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeContactPermissionsRequestBodyToJSON(requestParameters.changeContactPermissionsRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeContactPermissions(requestParameters: ChangeContactPermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeContactPermissionsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.changePasswordRequestBody === null || requestParameters.changePasswordRequestBody === undefined) {
            throw new runtime.RequiredError('changePasswordRequestBody','Required parameter requestParameters.changePasswordRequestBody was null or undefined when calling changePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/account/changePassword`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordRequestBodyToJSON(requestParameters.changePasswordRequestBody),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changePassword(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.changePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAccountDetailsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/account`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDetailsFromJSON(jsonValue));
    }

    /**
     */
    async getAccountDetails(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountDetails> {
        const response = await this.getAccountDetailsRaw(initOverrides);
        return await response.value();
    }

}
