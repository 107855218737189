export const ProductClaimsPageDE = {
  pageTitle: "Meine Reklamationen",
  title: "Meine Reklamationen ({{ count }})",
  filter: {
    title: "Filtern & Sortieren",
    statusPlaceholder: "Alle Status",
    claimNumberPlaceholder: "Suche nach Reklamations-Nr.",
    fulltextPlaceholder: "Reklamationen durchsuchen (min 3. Zeichen)",
    reasonPlaceholder: "Alle Gründe",
    nothingFound: "Keine Ergebnisse gefunden",
    notification:
      "Nach unseren AGB sind Artikel, deren Lieferung länger als 12 Monate zurückliegt, von einer Reklamation ausgeschlossen. Hier ist nur im Kulanzfall eine Reklamation möglich.",
  },
  table: {
    head: {
      claimNumber: "Reklam.-Nr.",
      date: "Datum",
      invoiceNumber: "Rechn.-Nr.",
      reason: "Grund",
      article: "Artikel",
      product: "Artikel",
      status: "Status",
      creditNoteNumber: "Gutschrift-Nr.",
      rmaNumber: "RMA-Nr.",
    },
    noClaimsAvailable: "Noch keine Reklamationen angelegt.",
    noClaimsFound: "Keine Reklamationen gefunden, die deinen Suchkriterien entsprechen.",
    productOneClickTooltip: "Liste nach Artikel {{productNumber}} durchsuchen",
  },
  detail: {
    informationTabTitel: "Informationen",
    activityListTabTitel: "Kommunikationsverlauf",
    claimNumber: "Reklamation: {{ claimNumber }}",
    productNumberLabel: "Artikel-Nr.",
    invoiceLabel: "Rechnung",
    invoiceDateLabel: "Rechnungsdatum",
    serialNumberLabel: "Seriennummer",
    reasonLabel: "Grund",
    overdeliveryLabel: "Zu viel gelieferte Menge",
    underdeliveryLabel: "Zu wenig gelieferte Menge",
    preferredSettlementLabel: "Angefragte Präferenz",
    grantedSettlementLabel: "Bestätigte Präferenz",
    creditNoteNumberLabel: "Gutschrift-Nr.",
    rmaNumberLabel: "RMA-Nr.",
    additionalInfoLabel: "Beschreibung",
    noAdditionalInfo: "Keine Beschreibung vorhanden",
    photoLabel: "Foto-Dokumentation",
    noPhotosAvailable: "Keine Fotos hinterlegt",
    statusLabel: "Status",
    activity: {
      title: "Kommunikationsverlauf",
      systemAuthor: "System",
      claimCreatedMessage: "Reklamation erfolgreich eingereicht.",
    },
  },
  openCreateClaimsPageButton: "Neue Reklamation erstellen",
};
