import { Group, Popover, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconInfoCircle } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const TotalSalesReportTitel = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "dashboardPage.totalSalesReportWidget" });
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Group gap={"xs"}>
      {t("title")}
      <Popover shadow="md" withArrow opened={opened} transitionProps={{ transition: "pop" }}>
        <Popover.Target>
          <IconInfoCircle onMouseEnter={open} onMouseLeave={close} color={"teal"} />
        </Popover.Target>

        <Popover.Dropdown w={600} maw={"100%"} style={{ pointerEvents: "none" }}>
          <Text>{t("titleInfoTooltip")}</Text>
        </Popover.Dropdown>
      </Popover>
    </Group>
  );
};

export { TotalSalesReportTitel };
