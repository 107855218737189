/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SalesOrderItemAvailabilitySummary } from './SalesOrderItemAvailabilitySummary';
import {
    SalesOrderItemAvailabilitySummaryFromJSON,
    SalesOrderItemAvailabilitySummaryFromJSONTyped,
    SalesOrderItemAvailabilitySummaryToJSON,
} from './SalesOrderItemAvailabilitySummary';

/**
 * 
 * @export
 * @interface PagedAndSyncedResultSalesOrderItemAvailabilitySummary
 */
export interface PagedAndSyncedResultSalesOrderItemAvailabilitySummary {
    /**
     * 
     * @type {Array<SalesOrderItemAvailabilitySummary>}
     * @memberof PagedAndSyncedResultSalesOrderItemAvailabilitySummary
     */
    content: Array<SalesOrderItemAvailabilitySummary>;
    /**
     * 
     * @type {number}
     * @memberof PagedAndSyncedResultSalesOrderItemAvailabilitySummary
     */
    totalElements: number;
    /**
     * 
     * @type {number}
     * @memberof PagedAndSyncedResultSalesOrderItemAvailabilitySummary
     */
    totalPages: number;
    /**
     * 
     * @type {Date}
     * @memberof PagedAndSyncedResultSalesOrderItemAvailabilitySummary
     */
    lastSyncedAt?: Date;
}

/**
 * Check if a given object implements the PagedAndSyncedResultSalesOrderItemAvailabilitySummary interface.
 */
export function instanceOfPagedAndSyncedResultSalesOrderItemAvailabilitySummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "totalElements" in value;
    isInstance = isInstance && "totalPages" in value;

    return isInstance;
}

export function PagedAndSyncedResultSalesOrderItemAvailabilitySummaryFromJSON(json: any): PagedAndSyncedResultSalesOrderItemAvailabilitySummary {
    return PagedAndSyncedResultSalesOrderItemAvailabilitySummaryFromJSONTyped(json, false);
}

export function PagedAndSyncedResultSalesOrderItemAvailabilitySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedAndSyncedResultSalesOrderItemAvailabilitySummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': ((json['content'] as Array<any>).map(SalesOrderItemAvailabilitySummaryFromJSON)),
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'lastSyncedAt': !exists(json, 'lastSyncedAt') ? undefined : (new Date(json['lastSyncedAt'])),
    };
}

export function PagedAndSyncedResultSalesOrderItemAvailabilitySummaryToJSON(value?: PagedAndSyncedResultSalesOrderItemAvailabilitySummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': ((value.content as Array<any>).map(SalesOrderItemAvailabilitySummaryToJSON)),
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
        'lastSyncedAt': value.lastSyncedAt === undefined ? undefined : (value.lastSyncedAt.toISOString()),
    };
}

