import { Group, Image } from "@mantine/core";
import { CSSProperties } from "react";

import { AppConfig, useAppConfig } from "~/config/AppConfig";

import Logo from "../../../assets/img/heoBC-logo-black.svg";
import LogoStage from "../../../assets/img/heoBC-logo-staging.svg";

interface HeoLogoProps {
  height?: number;
  width?: number;
  dataTestId?: string;
  style?: CSSProperties;
}

const HeoLogo: React.FC<HeoLogoProps> = (props) => {
  const { height, width, dataTestId, style } = props;
  const appConfig = useAppConfig();

  const logos: Record<AppConfig["logo"], string> = {
    stage: LogoStage,
    preprod: Logo,
    prod: Logo,
  };

  const logoSrc = logos[appConfig.logo];

  return (
    <Group h={height}>
      <Image
        src={logoSrc}
        h={height}
        w={width ?? "auto"}
        alt="Heo Logo"
        data-testid={dataTestId}
        style={style}
      />
    </Group>
  );
};

export { HeoLogo };
