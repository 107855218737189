import { ActionIcon, Box, Group, ThemeIcon, Tooltip } from "@mantine/core";
import { IconEye, IconEyeOff, IconLock } from "@tabler/icons-react";
import clsx from "clsx";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { GridLayoutItem, OnToggleVisibility } from "../GridLayout";

import styles from "./GridItemWrapper.module.css";

export interface GridItemWrapperProps {
  children: ReactNode;
  item: GridLayoutItem;
  isEditing: boolean;
  isDragging: boolean;
  withFadeIn?: boolean;
  onToggleVisibility?: OnToggleVisibility;
}

const GridItemWrapper: React.FC<GridItemWrapperProps> = (props) => {
  const { children, item, isEditing, isDragging, withFadeIn, onToggleVisibility } = props;

  const { t } = useTranslation(undefined, { keyPrefix: "gridLayout" });

  const handleToggleVisibility = () => {
    onToggleVisibility?.(item);
  };

  const wrapperClassNames = clsx(
    styles.wrapper,
    withFadeIn && styles.fadeIn,
    isEditing && styles.editing,
    isDragging && styles.dragging,
    item.static && styles.static,
  );

  return (
    <div className={wrapperClassNames}>
      <div className={styles.inner}>
        <Group justify="space-between" className={styles.actions}>
          {item.static && (
            <Tooltip label={t("staticLockTooltip")}>
              <ThemeIcon variant="subtle" color="gray">
                <IconLock />
              </ThemeIcon>
            </Tooltip>
          )}

          <Tooltip label={item.disabled ? t("showWidgetTooltip") : t("hideWidgetTooltip")}>
            <ActionIcon
              size="xl"
              color={item.disabled ? "gray" : "blue"}
              onClick={handleToggleVisibility}
            >
              {item.disabled ? <IconEyeOff /> : <IconEye />}
            </ActionIcon>
          </Tooltip>
        </Group>

        <Box>{children}</Box>
      </div>
    </div>
  );
};

export { GridItemWrapper };
