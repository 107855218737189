import { AvailableItems } from "~/api/openapi";
import { AvailableItemsWithMonthToAdd } from "./interface/AvailableItems";

let periodIndex = 0;

export const getItemAvailabilityGroupedByPeriod = (itemAvailabilities: AvailableItems[]) =>
  itemAvailabilities.reduce(
    (
      monthGroupedByPeriod: Record<number, AvailableItemsWithMonthToAdd[]>,
      singleMonth: AvailableItems,
      index,
    ) => {
      if (index % 3 === 0) {
        periodIndex = index / 3;
      }
      monthGroupedByPeriod[periodIndex] = monthGroupedByPeriod[periodIndex]
        ? [...monthGroupedByPeriod[periodIndex], { ...singleMonth, monthToAdd: index }]
        : [{ ...singleMonth, monthToAdd: index }];
      return monthGroupedByPeriod;
    },
    {},
  );
