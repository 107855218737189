import { Group, Skeleton, Table } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { CancellationRateInformationenPopover } from "./CancellationRateInformationenPopover/CancellationRateInformationenPopover";

import styles from "./TotalSalesReportTable.module.css";

const TotalSalesReportTableSkeleton = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "dashboardPage.totalSalesReportWidget.table",
  });

  const textHeight = 18;

  return (
    <Table striped highlightOnHover withRowBorders={false}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th />
          <Table.Th>
            <Skeleton height={28} width={140} radius="xs" />
          </Table.Th>
          <Table.Th>{t("totalLabel")}</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td>{t("ordersLabel")}</Table.Td>
          <Table.Td>
            <Skeleton height={textHeight} width={110} radius="xs" />
          </Table.Td>
          <Table.Td>
            <Skeleton height={textHeight} width={110} radius="xs" />
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("invoicesLabel")}</Table.Td>
          <Table.Td>
            <Skeleton height={textHeight} width={110} radius="xs" />
          </Table.Td>
          <Table.Td>
            <Skeleton height={textHeight} width={110} radius="xs" />
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("creditsLabel")}</Table.Td>
          <Table.Td>
            <Skeleton height={textHeight} width={110} radius="xs" />
          </Table.Td>
          <Table.Td>
            <Skeleton height={textHeight} width={110} radius="xs" />
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("cancledOrdersLabel")}</Table.Td>
          <Table.Td>
            <Skeleton height={textHeight} width={110} radius="xs" />
          </Table.Td>
          <Table.Td>
            <Skeleton height={textHeight} width={110} radius="xs" />
          </Table.Td>
        </Table.Tr>
        <Table.Tr>
          <Table.Td>{t("cancledInvoicesLabel")}</Table.Td>
          <Table.Td>
            <Skeleton height={textHeight} width={110} radius="xs" />
          </Table.Td>
          <Table.Td>
            <Skeleton height={textHeight} width={110} radius="xs" />
          </Table.Td>
        </Table.Tr>
      </Table.Tbody>

      <Table.Tfoot classNames={{ tfoot: styles.tableFooter }}>
        <Table.Tr>
          <Table.Td>
            <Group gap={"xs"}>
              {t("cancellationRateLabel")} <CancellationRateInformationenPopover />
            </Group>
          </Table.Td>
          <Table.Td>
            <Skeleton height={30} width={90} radius="xs" />
          </Table.Td>
          <Table.Td>
            <Skeleton height={textHeight} width={60} radius="xs" />
          </Table.Td>
        </Table.Tr>
      </Table.Tfoot>
    </Table>
  );
};

export { TotalSalesReportTableSkeleton };
