/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomerOrderDetailsExport,
  CustomerOrderDetailsWithOrderItems,
  PagedAndSyncedResultCustomerOrderSummary,
  SortDirection,
} from '../models';
import {
    CustomerOrderDetailsExportFromJSON,
    CustomerOrderDetailsExportToJSON,
    CustomerOrderDetailsWithOrderItemsFromJSON,
    CustomerOrderDetailsWithOrderItemsToJSON,
    PagedAndSyncedResultCustomerOrderSummaryFromJSON,
    PagedAndSyncedResultCustomerOrderSummaryToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface GetCustomerOrderSummariesOfCurrentRetailerRequest {
    offset?: number;
    limit?: number;
    createdBetweenFrom?: Date;
    createdBetweenUntil?: Date;
    fullTextQuery?: string;
    sortByCreatedAt?: SortDirection;
    sortByTotal?: SortDirection;
    sortByCustomerReference?: SortDirection;
    sortByTransactionNumber?: SortDirection;
}

export interface GetCustomerOrderSummariesOfCurrentRetailerCsvExportRequest {
    createdBetweenFrom?: Date;
    createdBetweenUntil?: Date;
    fullTextQuery?: string;
}

export interface GetCustomerOrderWithOrderItemsOfCurrentRetailerByCustomerOrderIdRequest {
    customerOrderId: number;
}

/**
 * 
 */
export class CustomerOrderResourceApi extends runtime.BaseAPI {

    /**
     */
    async getCustomerOrderSummariesOfCurrentRetailerRaw(requestParameters: GetCustomerOrderSummariesOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedAndSyncedResultCustomerOrderSummary>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.createdBetweenFrom !== undefined) {
            queryParameters['createdBetweenFrom'] = (requestParameters.createdBetweenFrom as any).toISOString();
        }

        if (requestParameters.createdBetweenUntil !== undefined) {
            queryParameters['createdBetweenUntil'] = (requestParameters.createdBetweenUntil as any).toISOString();
        }

        if (requestParameters.fullTextQuery !== undefined) {
            queryParameters['fullTextQuery'] = requestParameters.fullTextQuery;
        }

        if (requestParameters.sortByCreatedAt !== undefined) {
            queryParameters['sortByCreatedAt'] = requestParameters.sortByCreatedAt;
        }

        if (requestParameters.sortByTotal !== undefined) {
            queryParameters['sortByTotal'] = requestParameters.sortByTotal;
        }

        if (requestParameters.sortByCustomerReference !== undefined) {
            queryParameters['sortByCustomerReference'] = requestParameters.sortByCustomerReference;
        }

        if (requestParameters.sortByTransactionNumber !== undefined) {
            queryParameters['sortByTransactionNumber'] = requestParameters.sortByTransactionNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/customer-order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedAndSyncedResultCustomerOrderSummaryFromJSON(jsonValue));
    }

    /**
     */
    async getCustomerOrderSummariesOfCurrentRetailer(requestParameters: GetCustomerOrderSummariesOfCurrentRetailerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedAndSyncedResultCustomerOrderSummary> {
        const response = await this.getCustomerOrderSummariesOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCustomerOrderSummariesOfCurrentRetailerCsvExportRaw(requestParameters: GetCustomerOrderSummariesOfCurrentRetailerCsvExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerOrderDetailsExport>>> {
        const queryParameters: any = {};

        if (requestParameters.createdBetweenFrom !== undefined) {
            queryParameters['createdBetweenFrom'] = (requestParameters.createdBetweenFrom as any).toISOString();
        }

        if (requestParameters.createdBetweenUntil !== undefined) {
            queryParameters['createdBetweenUntil'] = (requestParameters.createdBetweenUntil as any).toISOString();
        }

        if (requestParameters.fullTextQuery !== undefined) {
            queryParameters['fullTextQuery'] = requestParameters.fullTextQuery;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/customer-order/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerOrderDetailsExportFromJSON));
    }

    /**
     */
    async getCustomerOrderSummariesOfCurrentRetailerCsvExport(requestParameters: GetCustomerOrderSummariesOfCurrentRetailerCsvExportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerOrderDetailsExport>> {
        const response = await this.getCustomerOrderSummariesOfCurrentRetailerCsvExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCustomerOrderWithOrderItemsOfCurrentRetailerByCustomerOrderIdRaw(requestParameters: GetCustomerOrderWithOrderItemsOfCurrentRetailerByCustomerOrderIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerOrderDetailsWithOrderItems>> {
        if (requestParameters.customerOrderId === null || requestParameters.customerOrderId === undefined) {
            throw new runtime.RequiredError('customerOrderId','Required parameter requestParameters.customerOrderId was null or undefined when calling getCustomerOrderWithOrderItemsOfCurrentRetailerByCustomerOrderId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/customer-order/{customerOrderId}`.replace(`{${"customerOrderId"}}`, encodeURIComponent(String(requestParameters.customerOrderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerOrderDetailsWithOrderItemsFromJSON(jsonValue));
    }

    /**
     */
    async getCustomerOrderWithOrderItemsOfCurrentRetailerByCustomerOrderId(requestParameters: GetCustomerOrderWithOrderItemsOfCurrentRetailerByCustomerOrderIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerOrderDetailsWithOrderItems> {
        const response = await this.getCustomerOrderWithOrderItemsOfCurrentRetailerByCustomerOrderIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
