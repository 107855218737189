/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { HeoCustomerOrderNumber } from './HeoCustomerOrderNumber';
import {
    HeoCustomerOrderNumberFromJSON,
    HeoCustomerOrderNumberFromJSONTyped,
    HeoCustomerOrderNumberToJSON,
} from './HeoCustomerOrderNumber';
import type { OrderOrigin } from './OrderOrigin';
import {
    OrderOriginFromJSON,
    OrderOriginFromJSONTyped,
    OrderOriginToJSON,
} from './OrderOrigin';
import type { OrderType } from './OrderType';
import {
    OrderTypeFromJSON,
    OrderTypeFromJSONTyped,
    OrderTypeToJSON,
} from './OrderType';

/**
 * 
 * @export
 * @interface CustomerOrderSummary
 */
export interface CustomerOrderSummary {
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderSummary
     */
    id: string;
    /**
     * 
     * @type {HeoCustomerOrderNumber}
     * @memberof CustomerOrderSummary
     */
    number: HeoCustomerOrderNumber;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderSummary
     */
    customerReference: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerOrderSummary
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderSummary
     */
    total: number;
    /**
     * 
     * @type {OrderOrigin}
     * @memberof CustomerOrderSummary
     */
    orderOrigin?: OrderOrigin;
    /**
     * 
     * @type {OrderType}
     * @memberof CustomerOrderSummary
     */
    orderType?: OrderType;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerOrderSummary
     */
    productImageUrls: Array<string>;
    /**
     * 
     * @type {Address}
     * @memberof CustomerOrderSummary
     */
    shippingAddress?: Address;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderSummary
     */
    itemSalesAcceptedQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderSummary
     */
    itemCustomerQuantity: number;
}

/**
 * Check if a given object implements the CustomerOrderSummary interface.
 */
export function instanceOfCustomerOrderSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "customerReference" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "productImageUrls" in value;
    isInstance = isInstance && "itemSalesAcceptedQuantity" in value;
    isInstance = isInstance && "itemCustomerQuantity" in value;

    return isInstance;
}

export function CustomerOrderSummaryFromJSON(json: any): CustomerOrderSummary {
    return CustomerOrderSummaryFromJSONTyped(json, false);
}

export function CustomerOrderSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOrderSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': HeoCustomerOrderNumberFromJSON(json['number']),
        'customerReference': json['customerReference'],
        'createdAt': (new Date(json['createdAt'])),
        'total': json['total'],
        'orderOrigin': !exists(json, 'orderOrigin') ? undefined : OrderOriginFromJSON(json['orderOrigin']),
        'orderType': !exists(json, 'orderType') ? undefined : OrderTypeFromJSON(json['orderType']),
        'productImageUrls': json['productImageUrls'],
        'shippingAddress': !exists(json, 'shippingAddress') ? undefined : AddressFromJSON(json['shippingAddress']),
        'itemSalesAcceptedQuantity': json['itemSalesAcceptedQuantity'],
        'itemCustomerQuantity': json['itemCustomerQuantity'],
    };
}

export function CustomerOrderSummaryToJSON(value?: CustomerOrderSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': HeoCustomerOrderNumberToJSON(value.number),
        'customerReference': value.customerReference,
        'createdAt': (value.createdAt.toISOString()),
        'total': value.total,
        'orderOrigin': OrderOriginToJSON(value.orderOrigin),
        'orderType': OrderTypeToJSON(value.orderType),
        'productImageUrls': value.productImageUrls,
        'shippingAddress': AddressToJSON(value.shippingAddress),
        'itemSalesAcceptedQuantity': value.itemSalesAcceptedQuantity,
        'itemCustomerQuantity': value.itemCustomerQuantity,
    };
}

