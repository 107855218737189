/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { HeoTransactionNumber } from './HeoTransactionNumber';
import {
    HeoTransactionNumberFromJSON,
    HeoTransactionNumberFromJSONTyped,
    HeoTransactionNumberToJSON,
} from './HeoTransactionNumber';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface InvoiceItemCsvExportSummary
 */
export interface InvoiceItemCsvExportSummary {
    /**
     * 
     * @type {number}
     * @memberof InvoiceItemCsvExportSummary
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceItemCsvExportSummary
     */
    pricePerUnit: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceItemCsvExportSummary
     */
    discount: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceItemCsvExportSummary
     */
    discountedPricePerUnit: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceItemCsvExportSummary
     */
    customerReference: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceItemCsvExportSummary
     */
    customerItemReference: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceItemCsvExportSummary
     */
    productNumber: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceItemCsvExportSummary
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceItemCsvExportSummary
     */
    productOriginCountry: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceItemCsvExportSummary
     */
    productTariffNumber: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceItemCsvExportSummary
     */
    productManufactur?: string;
    /**
     * 
     * @type {HeoTransactionNumber}
     * @memberof InvoiceItemCsvExportSummary
     */
    invoiceNumber: HeoTransactionNumber;
    /**
     * 
     * @type {number}
     * @memberof InvoiceItemCsvExportSummary
     */
    positionPrice: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceItemCsvExportSummary
     */
    shipped: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceItemCsvExportSummary
     */
    invoiceCanceled: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceItemCsvExportSummary
     */
    invoiceCreatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceItemCsvExportSummary
     */
    paymentDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceItemCsvExportSummary
     */
    paymentDueDate?: Date;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof InvoiceItemCsvExportSummary
     */
    paymentMethod: PaymentMethod;
    /**
     * 
     * @type {Address}
     * @memberof InvoiceItemCsvExportSummary
     */
    shippingAddress: Address;
    /**
     * 
     * @type {string}
     * @memberof InvoiceItemCsvExportSummary
     */
    productGtin: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceItemCsvExportSummary
     */
    invoiceReference: string;
}

/**
 * Check if a given object implements the InvoiceItemCsvExportSummary interface.
 */
export function instanceOfInvoiceItemCsvExportSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "pricePerUnit" in value;
    isInstance = isInstance && "discount" in value;
    isInstance = isInstance && "discountedPricePerUnit" in value;
    isInstance = isInstance && "customerReference" in value;
    isInstance = isInstance && "customerItemReference" in value;
    isInstance = isInstance && "productNumber" in value;
    isInstance = isInstance && "productName" in value;
    isInstance = isInstance && "productOriginCountry" in value;
    isInstance = isInstance && "productTariffNumber" in value;
    isInstance = isInstance && "invoiceNumber" in value;
    isInstance = isInstance && "positionPrice" in value;
    isInstance = isInstance && "shipped" in value;
    isInstance = isInstance && "invoiceCanceled" in value;
    isInstance = isInstance && "invoiceCreatedAt" in value;
    isInstance = isInstance && "paymentMethod" in value;
    isInstance = isInstance && "shippingAddress" in value;
    isInstance = isInstance && "productGtin" in value;
    isInstance = isInstance && "invoiceReference" in value;

    return isInstance;
}

export function InvoiceItemCsvExportSummaryFromJSON(json: any): InvoiceItemCsvExportSummary {
    return InvoiceItemCsvExportSummaryFromJSONTyped(json, false);
}

export function InvoiceItemCsvExportSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceItemCsvExportSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'quantity': json['quantity'],
        'pricePerUnit': json['pricePerUnit'],
        'discount': json['discount'],
        'discountedPricePerUnit': json['discountedPricePerUnit'],
        'customerReference': json['customerReference'],
        'customerItemReference': json['customerItemReference'],
        'productNumber': json['productNumber'],
        'productName': json['productName'],
        'productOriginCountry': json['productOriginCountry'],
        'productTariffNumber': json['productTariffNumber'],
        'productManufactur': !exists(json, 'productManufactur') ? undefined : json['productManufactur'],
        'invoiceNumber': HeoTransactionNumberFromJSON(json['invoiceNumber']),
        'positionPrice': json['positionPrice'],
        'shipped': json['shipped'],
        'invoiceCanceled': json['invoiceCanceled'],
        'invoiceCreatedAt': (new Date(json['invoiceCreatedAt'])),
        'paymentDate': !exists(json, 'paymentDate') ? undefined : (new Date(json['paymentDate'])),
        'paymentDueDate': !exists(json, 'paymentDueDate') ? undefined : (new Date(json['paymentDueDate'])),
        'paymentMethod': PaymentMethodFromJSON(json['paymentMethod']),
        'shippingAddress': AddressFromJSON(json['shippingAddress']),
        'productGtin': json['productGtin'],
        'invoiceReference': json['invoiceReference'],
    };
}

export function InvoiceItemCsvExportSummaryToJSON(value?: InvoiceItemCsvExportSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'quantity': value.quantity,
        'pricePerUnit': value.pricePerUnit,
        'discount': value.discount,
        'discountedPricePerUnit': value.discountedPricePerUnit,
        'customerReference': value.customerReference,
        'customerItemReference': value.customerItemReference,
        'productNumber': value.productNumber,
        'productName': value.productName,
        'productOriginCountry': value.productOriginCountry,
        'productTariffNumber': value.productTariffNumber,
        'productManufactur': value.productManufactur,
        'invoiceNumber': HeoTransactionNumberToJSON(value.invoiceNumber),
        'positionPrice': value.positionPrice,
        'shipped': value.shipped,
        'invoiceCanceled': value.invoiceCanceled,
        'invoiceCreatedAt': (value.invoiceCreatedAt.toISOString()),
        'paymentDate': value.paymentDate === undefined ? undefined : (value.paymentDate.toISOString().substr(0,10)),
        'paymentDueDate': value.paymentDueDate === undefined ? undefined : (value.paymentDueDate.toISOString().substr(0,10)),
        'paymentMethod': PaymentMethodToJSON(value.paymentMethod),
        'shippingAddress': AddressToJSON(value.shippingAddress),
        'productGtin': value.productGtin,
        'invoiceReference': value.invoiceReference,
    };
}

