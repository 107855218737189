import { Text } from "@mantine/core";
import { IconSortAscending } from "@tabler/icons-react";
import clsx from "clsx";
import React from "react";

import styles from "./TableSortIcon.module.css";

export interface TableSortIconProps {
  isSorted: boolean;
  isSortedDesc: boolean;
  testId?: string;
}

const TableSortIcon: React.FC<TableSortIconProps> = (props) => {
  const { isSorted, isSortedDesc, testId } = props;

  const sortIconClassNames = clsx(
    styles.sortIcon,
    isSortedDesc && styles.descending,
    isSorted && styles.visible,
  );

  if (!isSorted) {
    return null;
  }

  return (
    <Text
      className={styles.container}
      data-testid={
        testId &&
        (isSorted && !isSortedDesc ? `${testId}-sort-icon-asc` : `${testId}-sort-icon-desc`)
      }
    >
      <IconSortAscending size={20} className={sortIconClassNames} />
    </Text>
  );
};

export { TableSortIcon };
