/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CurrentInvoiceSummary,
  KpiOverviewData,
  OpenInvoiceSummary,
  ProductClaimsOverview,
  SalesOrderItemAvailabilityByMonth,
} from '../models';
import {
    CurrentInvoiceSummaryFromJSON,
    CurrentInvoiceSummaryToJSON,
    KpiOverviewDataFromJSON,
    KpiOverviewDataToJSON,
    OpenInvoiceSummaryFromJSON,
    OpenInvoiceSummaryToJSON,
    ProductClaimsOverviewFromJSON,
    ProductClaimsOverviewToJSON,
    SalesOrderItemAvailabilityByMonthFromJSON,
    SalesOrderItemAvailabilityByMonthToJSON,
} from '../models';

export interface GetCurrentDeliveriesOfCurrentRetailerRequest {
    createdFrom?: Date;
    createdUntil?: Date;
}

export interface GetKpiDataOverviewOfCurrentRetailerRequest {
    customerOrderCreatedBetweenFrom?: Date;
    customerOrderCreatedBetweenUntil?: Date;
    salesOrderItemAvailabilityCreatedBetweenFrom?: Date;
    salesOrderItemAvailabilityCreatedBetweenUntil?: Date;
    invoiceCreatedBetweenFrom?: Date;
    invoiceCreatedBetweenUntil?: Date;
    creditNotesCreatedBetweenFrom?: Date;
    creditNotesCreatedBetweenUntil?: Date;
}

export interface GetOpenInvoiceOverviewOfCurrentRetailerRequest {
    createdBetweenFrom?: Date;
    createdBetweenUntil?: Date;
}

export interface GetSalesOrderItemAvailabilitiesGroupByMonthOfCurrentRetailerRequest {
    availabilityBetweenFrom: Date;
    availabilityBetweenUntil: Date;
}

/**
 * 
 */
export class DashboardResourceApi extends runtime.BaseAPI {

    /**
     */
    async getCurrentDeliveriesOfCurrentRetailerRaw(requestParameters: GetCurrentDeliveriesOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CurrentInvoiceSummary>>> {
        const queryParameters: any = {};

        if (requestParameters.createdFrom !== undefined) {
            queryParameters['createdFrom'] = (requestParameters.createdFrom as any).toISOString();
        }

        if (requestParameters.createdUntil !== undefined) {
            queryParameters['createdUntil'] = (requestParameters.createdUntil as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/dashboard/current-deliveries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CurrentInvoiceSummaryFromJSON));
    }

    /**
     */
    async getCurrentDeliveriesOfCurrentRetailer(requestParameters: GetCurrentDeliveriesOfCurrentRetailerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CurrentInvoiceSummary>> {
        const response = await this.getCurrentDeliveriesOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getKpiDataOverviewOfCurrentRetailerRaw(requestParameters: GetKpiDataOverviewOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<KpiOverviewData>> {
        const queryParameters: any = {};

        if (requestParameters.customerOrderCreatedBetweenFrom !== undefined) {
            queryParameters['customerOrderCreatedBetweenFrom'] = (requestParameters.customerOrderCreatedBetweenFrom as any).toISOString();
        }

        if (requestParameters.customerOrderCreatedBetweenUntil !== undefined) {
            queryParameters['customerOrderCreatedBetweenUntil'] = (requestParameters.customerOrderCreatedBetweenUntil as any).toISOString();
        }

        if (requestParameters.salesOrderItemAvailabilityCreatedBetweenFrom !== undefined) {
            queryParameters['salesOrderItemAvailabilityCreatedBetweenFrom'] = (requestParameters.salesOrderItemAvailabilityCreatedBetweenFrom as any).toISOString();
        }

        if (requestParameters.salesOrderItemAvailabilityCreatedBetweenUntil !== undefined) {
            queryParameters['salesOrderItemAvailabilityCreatedBetweenUntil'] = (requestParameters.salesOrderItemAvailabilityCreatedBetweenUntil as any).toISOString();
        }

        if (requestParameters.invoiceCreatedBetweenFrom !== undefined) {
            queryParameters['invoiceCreatedBetweenFrom'] = (requestParameters.invoiceCreatedBetweenFrom as any).toISOString();
        }

        if (requestParameters.invoiceCreatedBetweenUntil !== undefined) {
            queryParameters['invoiceCreatedBetweenUntil'] = (requestParameters.invoiceCreatedBetweenUntil as any).toISOString();
        }

        if (requestParameters.creditNotesCreatedBetweenFrom !== undefined) {
            queryParameters['creditNotesCreatedBetweenFrom'] = (requestParameters.creditNotesCreatedBetweenFrom as any).toISOString();
        }

        if (requestParameters.creditNotesCreatedBetweenUntil !== undefined) {
            queryParameters['creditNotesCreatedBetweenUntil'] = (requestParameters.creditNotesCreatedBetweenUntil as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/dashboard/kpi-data-overview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KpiOverviewDataFromJSON(jsonValue));
    }

    /**
     */
    async getKpiDataOverviewOfCurrentRetailer(requestParameters: GetKpiDataOverviewOfCurrentRetailerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<KpiOverviewData> {
        const response = await this.getKpiDataOverviewOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getOpenInvoiceOverviewOfCurrentRetailerRaw(requestParameters: GetOpenInvoiceOverviewOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OpenInvoiceSummary>> {
        const queryParameters: any = {};

        if (requestParameters.createdBetweenFrom !== undefined) {
            queryParameters['createdBetweenFrom'] = (requestParameters.createdBetweenFrom as any).toISOString();
        }

        if (requestParameters.createdBetweenUntil !== undefined) {
            queryParameters['createdBetweenUntil'] = (requestParameters.createdBetweenUntil as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/dashboard/invoice/open`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OpenInvoiceSummaryFromJSON(jsonValue));
    }

    /**
     */
    async getOpenInvoiceOverviewOfCurrentRetailer(requestParameters: GetOpenInvoiceOverviewOfCurrentRetailerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OpenInvoiceSummary> {
        const response = await this.getOpenInvoiceOverviewOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProductClaimsOverviewOfCurrentRetailerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductClaimsOverview>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/dashboard/product-claims-overview`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductClaimsOverviewFromJSON(jsonValue));
    }

    /**
     */
    async getProductClaimsOverviewOfCurrentRetailer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductClaimsOverview> {
        const response = await this.getProductClaimsOverviewOfCurrentRetailerRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSalesOrderItemAvailabilitiesGroupByMonthOfCurrentRetailerRaw(requestParameters: GetSalesOrderItemAvailabilitiesGroupByMonthOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SalesOrderItemAvailabilityByMonth>> {
        if (requestParameters.availabilityBetweenFrom === null || requestParameters.availabilityBetweenFrom === undefined) {
            throw new runtime.RequiredError('availabilityBetweenFrom','Required parameter requestParameters.availabilityBetweenFrom was null or undefined when calling getSalesOrderItemAvailabilitiesGroupByMonthOfCurrentRetailer.');
        }

        if (requestParameters.availabilityBetweenUntil === null || requestParameters.availabilityBetweenUntil === undefined) {
            throw new runtime.RequiredError('availabilityBetweenUntil','Required parameter requestParameters.availabilityBetweenUntil was null or undefined when calling getSalesOrderItemAvailabilitiesGroupByMonthOfCurrentRetailer.');
        }

        const queryParameters: any = {};

        if (requestParameters.availabilityBetweenFrom !== undefined) {
            queryParameters['availabilityBetweenFrom'] = (requestParameters.availabilityBetweenFrom as any).toISOString();
        }

        if (requestParameters.availabilityBetweenUntil !== undefined) {
            queryParameters['availabilityBetweenUntil'] = (requestParameters.availabilityBetweenUntil as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/dashboard/salesorderavailabilities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SalesOrderItemAvailabilityByMonthFromJSON(jsonValue));
    }

    /**
     */
    async getSalesOrderItemAvailabilitiesGroupByMonthOfCurrentRetailer(requestParameters: GetSalesOrderItemAvailabilitiesGroupByMonthOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SalesOrderItemAvailabilityByMonth> {
        const response = await this.getSalesOrderItemAvailabilitiesGroupByMonthOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
