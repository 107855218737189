/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerOrderDetails } from './CustomerOrderDetails';
import {
    CustomerOrderDetailsFromJSON,
    CustomerOrderDetailsFromJSONTyped,
    CustomerOrderDetailsToJSON,
} from './CustomerOrderDetails';
import type { CustomerOrderItemsByCustomerOrderSummary } from './CustomerOrderItemsByCustomerOrderSummary';
import {
    CustomerOrderItemsByCustomerOrderSummaryFromJSON,
    CustomerOrderItemsByCustomerOrderSummaryFromJSONTyped,
    CustomerOrderItemsByCustomerOrderSummaryToJSON,
} from './CustomerOrderItemsByCustomerOrderSummary';

/**
 * 
 * @export
 * @interface CustomerOrderDetailsWithOrderItems
 */
export interface CustomerOrderDetailsWithOrderItems {
    /**
     * 
     * @type {CustomerOrderDetails}
     * @memberof CustomerOrderDetailsWithOrderItems
     */
    customerOrderDetails: CustomerOrderDetails;
    /**
     * 
     * @type {Array<CustomerOrderItemsByCustomerOrderSummary>}
     * @memberof CustomerOrderDetailsWithOrderItems
     */
    customerOrderItems: Array<CustomerOrderItemsByCustomerOrderSummary>;
}

/**
 * Check if a given object implements the CustomerOrderDetailsWithOrderItems interface.
 */
export function instanceOfCustomerOrderDetailsWithOrderItems(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerOrderDetails" in value;
    isInstance = isInstance && "customerOrderItems" in value;

    return isInstance;
}

export function CustomerOrderDetailsWithOrderItemsFromJSON(json: any): CustomerOrderDetailsWithOrderItems {
    return CustomerOrderDetailsWithOrderItemsFromJSONTyped(json, false);
}

export function CustomerOrderDetailsWithOrderItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOrderDetailsWithOrderItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerOrderDetails': CustomerOrderDetailsFromJSON(json['customerOrderDetails']),
        'customerOrderItems': ((json['customerOrderItems'] as Array<any>).map(CustomerOrderItemsByCustomerOrderSummaryFromJSON)),
    };
}

export function CustomerOrderDetailsWithOrderItemsToJSON(value?: CustomerOrderDetailsWithOrderItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerOrderDetails': CustomerOrderDetailsToJSON(value.customerOrderDetails),
        'customerOrderItems': ((value.customerOrderItems as Array<any>).map(CustomerOrderItemsByCustomerOrderSummaryToJSON)),
    };
}

