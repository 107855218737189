/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FileAccessResponseBody,
  PagedAndSyncedResultCreditNoteSummary,
  SortDirection,
} from '../models';
import {
    FileAccessResponseBodyFromJSON,
    FileAccessResponseBodyToJSON,
    PagedAndSyncedResultCreditNoteSummaryFromJSON,
    PagedAndSyncedResultCreditNoteSummaryToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface DownloadCreditNoteDocumentRequest {
    downloadToken: string;
}

export interface GetCreditNoteSummariesOfCurrentRetailerRequest {
    offset?: number;
    limit?: number;
    createdBetweenFrom?: Date;
    createdBetweenUntil?: Date;
    fullTextQuery?: string;
    sortByCreatedAt?: SortDirection;
    sortByCreditNoteNumber?: SortDirection;
    sortByAmount?: SortDirection;
    sortByRefundMethod?: SortDirection;
    sortByRefundedAt?: SortDirection;
    sortByDescription?: SortDirection;
}

export interface RequestCreditNoteDownloadRequest {
    companyCode: string;
    creditNoteNumber: number;
}

/**
 * 
 */
export class CreditNoteResourceApi extends runtime.BaseAPI {

    /**
     */
    async downloadCreditNoteDocumentRaw(requestParameters: DownloadCreditNoteDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.downloadToken === null || requestParameters.downloadToken === undefined) {
            throw new runtime.RequiredError('downloadToken','Required parameter requestParameters.downloadToken was null or undefined when calling downloadCreditNoteDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/credit-note/download/{downloadToken}`.replace(`{${"downloadToken"}}`, encodeURIComponent(String(requestParameters.downloadToken))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async downloadCreditNoteDocument(requestParameters: DownloadCreditNoteDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadCreditNoteDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCreditNoteSummariesOfCurrentRetailerRaw(requestParameters: GetCreditNoteSummariesOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedAndSyncedResultCreditNoteSummary>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.createdBetweenFrom !== undefined) {
            queryParameters['createdBetweenFrom'] = (requestParameters.createdBetweenFrom as any).toISOString();
        }

        if (requestParameters.createdBetweenUntil !== undefined) {
            queryParameters['createdBetweenUntil'] = (requestParameters.createdBetweenUntil as any).toISOString();
        }

        if (requestParameters.fullTextQuery !== undefined) {
            queryParameters['fullTextQuery'] = requestParameters.fullTextQuery;
        }

        if (requestParameters.sortByCreatedAt !== undefined) {
            queryParameters['sortByCreatedAt'] = requestParameters.sortByCreatedAt;
        }

        if (requestParameters.sortByCreditNoteNumber !== undefined) {
            queryParameters['sortByCreditNoteNumber'] = requestParameters.sortByCreditNoteNumber;
        }

        if (requestParameters.sortByAmount !== undefined) {
            queryParameters['sortByAmount'] = requestParameters.sortByAmount;
        }

        if (requestParameters.sortByRefundMethod !== undefined) {
            queryParameters['sortByRefundMethod'] = requestParameters.sortByRefundMethod;
        }

        if (requestParameters.sortByRefundedAt !== undefined) {
            queryParameters['sortByRefundedAt'] = requestParameters.sortByRefundedAt;
        }

        if (requestParameters.sortByDescription !== undefined) {
            queryParameters['sortByDescription'] = requestParameters.sortByDescription;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/credit-note`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedAndSyncedResultCreditNoteSummaryFromJSON(jsonValue));
    }

    /**
     */
    async getCreditNoteSummariesOfCurrentRetailer(requestParameters: GetCreditNoteSummariesOfCurrentRetailerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedAndSyncedResultCreditNoteSummary> {
        const response = await this.getCreditNoteSummariesOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async requestCreditNoteDownloadRaw(requestParameters: RequestCreditNoteDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileAccessResponseBody>> {
        if (requestParameters.companyCode === null || requestParameters.companyCode === undefined) {
            throw new runtime.RequiredError('companyCode','Required parameter requestParameters.companyCode was null or undefined when calling requestCreditNoteDownload.');
        }

        if (requestParameters.creditNoteNumber === null || requestParameters.creditNoteNumber === undefined) {
            throw new runtime.RequiredError('creditNoteNumber','Required parameter requestParameters.creditNoteNumber was null or undefined when calling requestCreditNoteDownload.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyCode !== undefined) {
            queryParameters['companyCode'] = requestParameters.companyCode;
        }

        if (requestParameters.creditNoteNumber !== undefined) {
            queryParameters['creditNoteNumber'] = requestParameters.creditNoteNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/credit-note/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileAccessResponseBodyFromJSON(jsonValue));
    }

    /**
     */
    async requestCreditNoteDownload(requestParameters: RequestCreditNoteDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileAccessResponseBody> {
        const response = await this.requestCreditNoteDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
