export const TotalSalesDetailsPageEN = {
  titel: "Turnover summary details",
  orders: "Orders",
  invoices: "Invoices",
  creditNotes: "Credit notes",
  canceledOrders: "Canceled orders",
  canceledInvoices: "Canceled invoices",
  quota: "Quota",
  backToDashboardButton: "Back to dashboard",
  table: {
    noDataText: "No data available",
    header: {
      year: "Year",
      orderAmount: "Order amount",
      invoiceAmount: "Invoice amount",
      creditNoteAmount: "Credit note amount",
      canceledOrderAmount: "Canceled order amount",
      canceledInvoiceAmount: "Canceled invoice amount",
      quota: "Quota",
    },
  },
};
