/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreditNoteSummary } from './CreditNoteSummary';
import {
    CreditNoteSummaryFromJSON,
    CreditNoteSummaryFromJSONTyped,
    CreditNoteSummaryToJSON,
} from './CreditNoteSummary';

/**
 * 
 * @export
 * @interface PagedAndSyncedResultCreditNoteSummary
 */
export interface PagedAndSyncedResultCreditNoteSummary {
    /**
     * 
     * @type {Array<CreditNoteSummary>}
     * @memberof PagedAndSyncedResultCreditNoteSummary
     */
    content: Array<CreditNoteSummary>;
    /**
     * 
     * @type {number}
     * @memberof PagedAndSyncedResultCreditNoteSummary
     */
    totalElements: number;
    /**
     * 
     * @type {number}
     * @memberof PagedAndSyncedResultCreditNoteSummary
     */
    totalPages: number;
    /**
     * 
     * @type {Date}
     * @memberof PagedAndSyncedResultCreditNoteSummary
     */
    lastSyncedAt?: Date;
}

/**
 * Check if a given object implements the PagedAndSyncedResultCreditNoteSummary interface.
 */
export function instanceOfPagedAndSyncedResultCreditNoteSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "totalElements" in value;
    isInstance = isInstance && "totalPages" in value;

    return isInstance;
}

export function PagedAndSyncedResultCreditNoteSummaryFromJSON(json: any): PagedAndSyncedResultCreditNoteSummary {
    return PagedAndSyncedResultCreditNoteSummaryFromJSONTyped(json, false);
}

export function PagedAndSyncedResultCreditNoteSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedAndSyncedResultCreditNoteSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': ((json['content'] as Array<any>).map(CreditNoteSummaryFromJSON)),
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'lastSyncedAt': !exists(json, 'lastSyncedAt') ? undefined : (new Date(json['lastSyncedAt'])),
    };
}

export function PagedAndSyncedResultCreditNoteSummaryToJSON(value?: PagedAndSyncedResultCreditNoteSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': ((value.content as Array<any>).map(CreditNoteSummaryToJSON)),
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
        'lastSyncedAt': value.lastSyncedAt === undefined ? undefined : (value.lastSyncedAt.toISOString()),
    };
}

