import { AccountSettingsKeys } from "~/hooks/accountSettings/AccountSettingsSchema";
import { GuidedTour, GuidedTourData } from "~/ui/components/GuidedTourModal/GuidedTourModal";

import GTTeaser1 from "./GT-Teaser_1_1.jpg";
import GTTeaser2 from "./GT-Teaser_1_2.jpg";
import GTTeaser3 from "./GT-Teaser_1_3.jpg";
import GTTeaser4 from "./GT-Teaser_1_4.jpg";
import GTTeaser5 from "./GT-Teaser_1_5.jpg";
import GTTeaser6 from "./GT-Teaser_1_6.jpg";
import GTTeaser7 from "./GT-Teaser_1_7.jpg";
import GTTeaser8 from "./GT-Teaser_1_8.jpg";
import GTTeaser9 from "./GT-Teaser_1_9.jpg";
import GTTeaser10 from "./GT-Teaser_1_10.jpg";
import GTTeaser11 from "./GT-Teaser_1_11.jpg";
import GTTeaser12 from "./GT-Teaser_1_12.jpg";

export const DASHBOARD_GUIDED_TOUR_SEEN_KEY: AccountSettingsKeys = "dashboardGuidedTourSeen";

const dashboardGuidedTourData = [
  {
    image: GTTeaser1,
  },
  {
    image: GTTeaser2,
  },
  {
    image: GTTeaser3,
  },
  {
    image: GTTeaser4,
  },
  {
    image: GTTeaser5,
  },
  {
    image: GTTeaser6,
  },
  {
    image: GTTeaser7,
  },
  {
    image: GTTeaser8,
  },
  {
    image: GTTeaser9,
  },
  {
    image: GTTeaser10,
  },
  {
    image: GTTeaser11,
  },
  {
    image: GTTeaser12,
  },
] satisfies GuidedTourData[];

export const dashboardGuidedTour: GuidedTour = {
  guidedTourData: dashboardGuidedTourData,
  title: "guidedTour.userInterfaceDashboard.title",
  translationBaseKey: "guidedTour.userInterfaceDashboard.steps",
};
