import { clsx } from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

import { WidgetContainer } from "../../WidgetContainer/WidgetContainer";
import { FactoringChart } from "./FactoringChart";

import factoringWidgetStyles from "./FactoringWidget.module.css";

export interface FactoringWidgetProps {
  limit: number;
  used: number;
}

const FactoringWidget: React.FC<FactoringWidgetProps> = (props) => {
  const { used, limit } = props;
  const { t } = useTranslation();

  const limitExceeded = used > limit;

  return (
    <WidgetContainer
      title={t("dashboardPage.factoringWidget.title")}
      className={clsx(limitExceeded && factoringWidgetStyles.exceededBorder)}
    >
      <FactoringChart limit={limit} used={used} />
    </WidgetContainer>
  );
};

export { FactoringWidget };
