import {
  BACKORDERS_PAGE_AVAILABILITY_QUERY_KEY,
  BACKORDERS_PAGE_CREATE_PERIOD_QUERY_KEY,
  BACKORDERS_PAGE_MANUFACTURERS_QUERY_KEY,
  BACKORDERS_PAGE_PRODUCT_TYPES_QUERY_KEY,
  BACKORDERS_PAGE_SEARCH_QUERY_KEY,
  BACKORDERS_PAGE_STATUS_QUERY_KEY,
} from "~/ui/pages/SalesOrdersPage/SalesOrdersPageQueryKeys";

import { PeriodOption } from "~/common/enum/PeriodOption";
import { RoutesEnum } from "../RoutesEnum";
import { createDynamicRoute } from "./util/createDynamicRoute";

const createSalesOrdersPageLink = createDynamicRoute({
  url: RoutesEnum.SALES_ORDERS,
  params: {
    createdPeriod: {
      queryKey: BACKORDERS_PAGE_CREATE_PERIOD_QUERY_KEY,
      default: PeriodOption.ALL,
    },

    availabilityPeriod: BACKORDERS_PAGE_AVAILABILITY_QUERY_KEY,
    manufacturers: BACKORDERS_PAGE_MANUFACTURERS_QUERY_KEY,
    productTypes: BACKORDERS_PAGE_PRODUCT_TYPES_QUERY_KEY,
    availabilityStatus: BACKORDERS_PAGE_STATUS_QUERY_KEY,
    search: BACKORDERS_PAGE_SEARCH_QUERY_KEY,
  },
});

export { createSalesOrdersPageLink };
