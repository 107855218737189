/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestRetailerContactUpdateRequestBody
 */
export interface RequestRetailerContactUpdateRequestBody {
    /**
     * 
     * @type {string}
     * @memberof RequestRetailerContactUpdateRequestBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RequestRetailerContactUpdateRequestBody
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof RequestRetailerContactUpdateRequestBody
     */
    websiteUrl: string;
    /**
     * 
     * @type {string}
     * @memberof RequestRetailerContactUpdateRequestBody
     */
    shopUrl: string;
    /**
     * 
     * @type {string}
     * @memberof RequestRetailerContactUpdateRequestBody
     */
    twitterUrl: string;
    /**
     * 
     * @type {string}
     * @memberof RequestRetailerContactUpdateRequestBody
     */
    facebookUrl: string;
    /**
     * 
     * @type {string}
     * @memberof RequestRetailerContactUpdateRequestBody
     */
    youtubeUrl: string;
    /**
     * 
     * @type {string}
     * @memberof RequestRetailerContactUpdateRequestBody
     */
    instagramUrl: string;
    /**
     * 
     * @type {string}
     * @memberof RequestRetailerContactUpdateRequestBody
     */
    twitchUrl: string;
}

/**
 * Check if a given object implements the RequestRetailerContactUpdateRequestBody interface.
 */
export function instanceOfRequestRetailerContactUpdateRequestBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "phone" in value;
    isInstance = isInstance && "websiteUrl" in value;
    isInstance = isInstance && "shopUrl" in value;
    isInstance = isInstance && "twitterUrl" in value;
    isInstance = isInstance && "facebookUrl" in value;
    isInstance = isInstance && "youtubeUrl" in value;
    isInstance = isInstance && "instagramUrl" in value;
    isInstance = isInstance && "twitchUrl" in value;

    return isInstance;
}

export function RequestRetailerContactUpdateRequestBodyFromJSON(json: any): RequestRetailerContactUpdateRequestBody {
    return RequestRetailerContactUpdateRequestBodyFromJSONTyped(json, false);
}

export function RequestRetailerContactUpdateRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestRetailerContactUpdateRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'phone': json['phone'],
        'websiteUrl': json['websiteUrl'],
        'shopUrl': json['shopUrl'],
        'twitterUrl': json['twitterUrl'],
        'facebookUrl': json['facebookUrl'],
        'youtubeUrl': json['youtubeUrl'],
        'instagramUrl': json['instagramUrl'],
        'twitchUrl': json['twitchUrl'],
    };
}

export function RequestRetailerContactUpdateRequestBodyToJSON(value?: RequestRetailerContactUpdateRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'phone': value.phone,
        'websiteUrl': value.websiteUrl,
        'shopUrl': value.shopUrl,
        'twitterUrl': value.twitterUrl,
        'facebookUrl': value.facebookUrl,
        'youtubeUrl': value.youtubeUrl,
        'instagramUrl': value.instagramUrl,
        'twitchUrl': value.twitchUrl,
    };
}

