/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreProfileChangeRequest } from './StoreProfileChangeRequest';
import {
    StoreProfileChangeRequestFromJSON,
    StoreProfileChangeRequestFromJSONTyped,
    StoreProfileChangeRequestToJSON,
} from './StoreProfileChangeRequest';
import type { StoreProfileStoreDetails } from './StoreProfileStoreDetails';
import {
    StoreProfileStoreDetailsFromJSON,
    StoreProfileStoreDetailsFromJSONTyped,
    StoreProfileStoreDetailsToJSON,
} from './StoreProfileStoreDetails';

/**
 * 
 * @export
 * @interface StoreProfileDetails
 */
export interface StoreProfileDetails {
    /**
     * 
     * @type {string}
     * @memberof StoreProfileDetails
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreProfileDetails
     */
    published: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileDetails
     */
    url?: string;
    /**
     * 
     * @type {Date}
     * @memberof StoreProfileDetails
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StoreProfileDetails
     */
    updatedAt?: Date;
    /**
     * 
     * @type {StoreProfileStoreDetails}
     * @memberof StoreProfileDetails
     */
    store: StoreProfileStoreDetails;
    /**
     * 
     * @type {StoreProfileChangeRequest}
     * @memberof StoreProfileDetails
     */
    changeRequest?: StoreProfileChangeRequest;
}

/**
 * Check if a given object implements the StoreProfileDetails interface.
 */
export function instanceOfStoreProfileDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "published" in value;
    isInstance = isInstance && "store" in value;

    return isInstance;
}

export function StoreProfileDetailsFromJSON(json: any): StoreProfileDetails {
    return StoreProfileDetailsFromJSONTyped(json, false);
}

export function StoreProfileDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfileDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'published': json['published'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'store': StoreProfileStoreDetailsFromJSON(json['store']),
        'changeRequest': !exists(json, 'changeRequest') ? undefined : StoreProfileChangeRequestFromJSON(json['changeRequest']),
    };
}

export function StoreProfileDetailsToJSON(value?: StoreProfileDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'published': value.published,
        'url': value.url,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'store': StoreProfileStoreDetailsToJSON(value.store),
        'changeRequest': StoreProfileChangeRequestToJSON(value.changeRequest),
    };
}

