import React from "react";

import { useBillingStopNotification } from "~/hooks/factoringLimit/useBillingStopNotification";
import { useFactoringLimitNotification } from "~/hooks/factoringLimit/useFactoringLimitNotification";
import { useSentryUserInit } from "~/sentry/useSentryUserInit";
import { useCrispAndHotjar } from "./3rdPartyModules/useCrispAndHotjar";

const InitAuthenticated: React.FC = () => {
  useFactoringLimitNotification();
  useBillingStopNotification();
  useCrispAndHotjar();
  useSentryUserInit();
  return null;
};

export { InitAuthenticated };
