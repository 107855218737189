/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoreProfileGeoPosition
 */
export interface StoreProfileGeoPosition {
    /**
     * 
     * @type {number}
     * @memberof StoreProfileGeoPosition
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof StoreProfileGeoPosition
     */
    lon: number;
}

/**
 * Check if a given object implements the StoreProfileGeoPosition interface.
 */
export function instanceOfStoreProfileGeoPosition(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lat" in value;
    isInstance = isInstance && "lon" in value;

    return isInstance;
}

export function StoreProfileGeoPositionFromJSON(json: any): StoreProfileGeoPosition {
    return StoreProfileGeoPositionFromJSONTyped(json, false);
}

export function StoreProfileGeoPositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfileGeoPosition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lat': json['lat'],
        'lon': json['lon'],
    };
}

export function StoreProfileGeoPositionToJSON(value?: StoreProfileGeoPosition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lat': value.lat,
        'lon': value.lon,
    };
}

