import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "~/store/store";

export interface ReleaseNotesState {
  releaseNotesModalIsOpen: boolean;
  releaseNotesLoading: boolean;
}

export const initialReleaseNotesState: ReleaseNotesState = {
  releaseNotesModalIsOpen: false,
  releaseNotesLoading: true,
};

type ReleaseNoteIsOpenToogleAction = {
  isOpen: boolean;
};
const ReleaseNotesSlice = createSlice({
  name: "releaseNotes",
  initialState: initialReleaseNotesState,
  reducers: {
    toggleReleaseNotesModal: (state, action: PayloadAction<ReleaseNoteIsOpenToogleAction>) => {
      state.releaseNotesModalIsOpen = action.payload.isOpen;
    },
    releaseNotesLoading: (state, action: PayloadAction<boolean>) => {
      state.releaseNotesLoading = action.payload;
    },
  },
});

export const toggleReleaseNotesModalSelector = (state: RootState) =>
  state.releaseNotes.releaseNotesModalIsOpen;

export const releaseNotesLoadingSelector = (state: RootState) =>
  state.releaseNotes.releaseNotesLoading;

export const ReleaseNotesSliceActions = ReleaseNotesSlice.actions;
export const ReleaseNotesSliceReducer = ReleaseNotesSlice.reducer;
