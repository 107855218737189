/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CustomerOrderItemsByCustomerOrderSummary,
  PagedResultCustomerOrderItemSummary,
  SortDirection,
} from '../models';
import {
    CustomerOrderItemsByCustomerOrderSummaryFromJSON,
    CustomerOrderItemsByCustomerOrderSummaryToJSON,
    PagedResultCustomerOrderItemSummaryFromJSON,
    PagedResultCustomerOrderItemSummaryToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface GetCustomerOrderItemSummariesOfCurrentRetailerRequest {
    offset?: number;
    limit?: number;
    productNumber?: string;
    fulltextQuery?: string;
    sortByProductNumber?: SortDirection;
    sortByHeoItemId?: SortDirection;
    sortByCustomerOrderCreatedAt?: SortDirection;
    sortByCustomerOrderNumber?: SortDirection;
    sortByQuantity?: SortDirection;
    sortByAcceptedQuantity?: SortDirection;
    sortByPricePerUnit?: SortDirection;
    sortByTotal?: SortDirection;
    sortByDiscount?: SortDirection;
    sortByCustomerReference?: SortDirection;
    sortByCustomerItemReference?: SortDirection;
}

export interface GetCustomerOrderItemsByCustomerOrderOfCurrentRetailerRequest {
    customerOrderId: string;
}

/**
 * 
 */
export class CustomerOrderItemResourceApi extends runtime.BaseAPI {

    /**
     */
    async getCustomerOrderItemSummariesOfCurrentRetailerRaw(requestParameters: GetCustomerOrderItemSummariesOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedResultCustomerOrderItemSummary>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.productNumber !== undefined) {
            queryParameters['productNumber'] = requestParameters.productNumber;
        }

        if (requestParameters.fulltextQuery !== undefined) {
            queryParameters['fulltextQuery'] = requestParameters.fulltextQuery;
        }

        if (requestParameters.sortByProductNumber !== undefined) {
            queryParameters['sortByProductNumber'] = requestParameters.sortByProductNumber;
        }

        if (requestParameters.sortByHeoItemId !== undefined) {
            queryParameters['sortByHeoItemId'] = requestParameters.sortByHeoItemId;
        }

        if (requestParameters.sortByCustomerOrderCreatedAt !== undefined) {
            queryParameters['sortByCustomerOrderCreatedAt'] = requestParameters.sortByCustomerOrderCreatedAt;
        }

        if (requestParameters.sortByCustomerOrderNumber !== undefined) {
            queryParameters['sortByCustomerOrderNumber'] = requestParameters.sortByCustomerOrderNumber;
        }

        if (requestParameters.sortByQuantity !== undefined) {
            queryParameters['sortByQuantity'] = requestParameters.sortByQuantity;
        }

        if (requestParameters.sortByAcceptedQuantity !== undefined) {
            queryParameters['sortByAcceptedQuantity'] = requestParameters.sortByAcceptedQuantity;
        }

        if (requestParameters.sortByPricePerUnit !== undefined) {
            queryParameters['sortByPricePerUnit'] = requestParameters.sortByPricePerUnit;
        }

        if (requestParameters.sortByTotal !== undefined) {
            queryParameters['sortByTotal'] = requestParameters.sortByTotal;
        }

        if (requestParameters.sortByDiscount !== undefined) {
            queryParameters['sortByDiscount'] = requestParameters.sortByDiscount;
        }

        if (requestParameters.sortByCustomerReference !== undefined) {
            queryParameters['sortByCustomerReference'] = requestParameters.sortByCustomerReference;
        }

        if (requestParameters.sortByCustomerItemReference !== undefined) {
            queryParameters['sortByCustomerItemReference'] = requestParameters.sortByCustomerItemReference;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/customer-order-items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedResultCustomerOrderItemSummaryFromJSON(jsonValue));
    }

    /**
     */
    async getCustomerOrderItemSummariesOfCurrentRetailer(requestParameters: GetCustomerOrderItemSummariesOfCurrentRetailerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedResultCustomerOrderItemSummary> {
        const response = await this.getCustomerOrderItemSummariesOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCustomerOrderItemsByCustomerOrderOfCurrentRetailerRaw(requestParameters: GetCustomerOrderItemsByCustomerOrderOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerOrderItemsByCustomerOrderSummary>>> {
        if (requestParameters.customerOrderId === null || requestParameters.customerOrderId === undefined) {
            throw new runtime.RequiredError('customerOrderId','Required parameter requestParameters.customerOrderId was null or undefined when calling getCustomerOrderItemsByCustomerOrderOfCurrentRetailer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/customer-order-items/{customerOrderId}`.replace(`{${"customerOrderId"}}`, encodeURIComponent(String(requestParameters.customerOrderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerOrderItemsByCustomerOrderSummaryFromJSON));
    }

    /**
     */
    async getCustomerOrderItemsByCustomerOrderOfCurrentRetailer(requestParameters: GetCustomerOrderItemsByCustomerOrderOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerOrderItemsByCustomerOrderSummary>> {
        const response = await this.getCustomerOrderItemsByCustomerOrderOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
