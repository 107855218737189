/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreProfile } from './StoreProfile';
import {
    StoreProfileFromJSON,
    StoreProfileFromJSONTyped,
    StoreProfileToJSON,
} from './StoreProfile';
import type { StoreProfileDraft } from './StoreProfileDraft';
import {
    StoreProfileDraftFromJSON,
    StoreProfileDraftFromJSONTyped,
    StoreProfileDraftToJSON,
} from './StoreProfileDraft';
import type { StoreProfileNewRequestOverview } from './StoreProfileNewRequestOverview';
import {
    StoreProfileNewRequestOverviewFromJSON,
    StoreProfileNewRequestOverviewFromJSONTyped,
    StoreProfileNewRequestOverviewToJSON,
} from './StoreProfileNewRequestOverview';

/**
 * 
 * @export
 * @interface StoreProfiles
 */
export interface StoreProfiles {
    /**
     * 
     * @type {Array<StoreProfile>}
     * @memberof StoreProfiles
     */
    profiles: Array<StoreProfile>;
    /**
     * 
     * @type {Array<StoreProfileNewRequestOverview>}
     * @memberof StoreProfiles
     */
    newRequests: Array<StoreProfileNewRequestOverview>;
    /**
     * 
     * @type {Array<StoreProfileDraft>}
     * @memberof StoreProfiles
     */
    drafts: Array<StoreProfileDraft>;
}

/**
 * Check if a given object implements the StoreProfiles interface.
 */
export function instanceOfStoreProfiles(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "profiles" in value;
    isInstance = isInstance && "newRequests" in value;
    isInstance = isInstance && "drafts" in value;

    return isInstance;
}

export function StoreProfilesFromJSON(json: any): StoreProfiles {
    return StoreProfilesFromJSONTyped(json, false);
}

export function StoreProfilesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfiles {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profiles': ((json['profiles'] as Array<any>).map(StoreProfileFromJSON)),
        'newRequests': ((json['newRequests'] as Array<any>).map(StoreProfileNewRequestOverviewFromJSON)),
        'drafts': ((json['drafts'] as Array<any>).map(StoreProfileDraftFromJSON)),
    };
}

export function StoreProfilesToJSON(value?: StoreProfiles | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profiles': ((value.profiles as Array<any>).map(StoreProfileToJSON)),
        'newRequests': ((value.newRequests as Array<any>).map(StoreProfileNewRequestOverviewToJSON)),
        'drafts': ((value.drafts as Array<any>).map(StoreProfileDraftToJSON)),
    };
}

