import { addMonths, endOfMonth, startOfMonth } from "date-fns";

const getMonthRangeByMonthNumber = (monthToAdd: number) => {
  const startDate = addMonths(startOfMonth(new Date()), monthToAdd);
  return {
    availableFrom: startDate,
    availableUntil: endOfMonth(startDate),
  };
};

export { getMonthRangeByMonthNumber };
