import { Anchor, Box, Button, Group, HoverCard, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { DateStyle, useLocalizedDateFormatter } from "~/hooks/date/useLocalizedDateFormatter";

import { Link } from "react-router-dom";
import { CurrentInvoiceSummary } from "~/api/openapi";
import { PeriodOption } from "~/common/enum/PeriodOption";
import { createInvoiceLink } from "~/ui/routes/DynamicRoutes/createInvoiceLink";

interface TimelineItemProps {
  timelineItem: CurrentInvoiceSummary;
}

const TimelineItem: React.FC<TimelineItemProps> = (props) => {
  const {
    number: invoiceNumber,
    createdAt,
    filed,
    cityShippedTo,
    zipCodeShippedTo,
    shipments,
    productItems,
  } = props.timelineItem;

  const { t } = useTranslation(undefined, { keyPrefix: "dashboardPage.CurrentDeliveriesWidget" });
  const { formatDate } = useLocalizedDateFormatter();

  const productsCount = productItems.length;
  const mostExpensiveProduct = productItems[0];
  const hasMoreProducts = productsCount > 1;
  const additionalProductsCount = productsCount - 1;
  const packageCount = shipments.length;

  const invoiceLinkText = hasMoreProducts
    ? t("moreAdditionalProducts", {
        productNumber: mostExpensiveProduct.productNumber,
        count: additionalProductsCount,
      })
    : t("noAdditionalProducts", {
        productNumber: mostExpensiveProduct.productNumber,
      });

  return (
    <Stack pl={"sm"} gap={0}>
      <Text fz={"sm"} c={"dimmed"}>
        {formatDate(createdAt, DateStyle.SHORT)}
      </Text>
      <Group justify="space-between" align="flex-start" wrap="nowrap">
        <Stack gap={0}>
          <Text fw={"bolder"} size="lg">
            {t(`${filed ? "shippingStatusShipped" : "shippingStatusInProgress"}`)}
          </Text>
          <Text lineClamp={1}>
            {zipCodeShippedTo} {cityShippedTo}
          </Text>
        </Stack>
        <Box pr={"md"}>
          {packageCount > 0 && (
            <HoverCard
              withArrow={true}
              closeDelay={120}
              openDelay={120}
              arrowOffset={20}
              transitionProps={{ duration: 160 }}
              shadow="md"
              position="bottom-end"
            >
              <HoverCard.Target>
                <Button variant="light" size="compact-sm">
                  {t("numberOfPackages.package", {
                    count: packageCount,
                  })}
                </Button>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Stack gap={"xs"}>
                  {shipments.map((shipment) => (
                    <Anchor
                      key={shipment.trackingNumber}
                      href={shipment.trackingLink}
                      target="_blank"
                    >
                      {shipment.service}: {shipment.trackingNumber}
                    </Anchor>
                  ))}
                </Stack>
              </HoverCard.Dropdown>
            </HoverCard>
          )}
        </Box>
      </Group>
      <Box>
        <Anchor
          pt={6}
          component={Link}
          to={createInvoiceLink({
            period: PeriodOption.ALL,
            search: invoiceNumber.number,
            selected: invoiceNumber.number,
          })}
        >
          {invoiceLinkText}
        </Anchor>
      </Box>
    </Stack>
  );
};

export { TimelineItem };
