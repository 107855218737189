import {
  StoreOverviewTypeEnum,
  StoreProfileNewRequestOverviewApprovalStatusEnum,
} from "~/api/openapi";
import { ProfileStatusEnum } from "~/ui/pages/StoreProfilePage/StoreProfileForm/StoreProfileFormProgressPanel/StoreProfileStatusInformation/StoreProfileStatusInformation";
import { VisibilityEnum } from "~/ui/pages/StoreProfilePage/StoreProfileForm/StoreProfileFormProgressPanel/StoreProfileStatusInformation/getVisibility";

export const StoreProfilePageDE = {
  overViewPage: {
    title: "Meine Store-Profile",

    noData: {
      title: "Lass Dich finden! Die Händlersuche rückt Dich ins richtige Licht!",
      subtitle:
        "Damit Endkunden, die täglich auf <strong>heo.com</strong> landen, nicht enttäuscht die Website verlassen, möchten wir diese direkt zu dir auf deinen Shop oder in deinen Store leiten.",
      button: "Jetzt Store-Profil anlegen",
    },
    overview: {
      confirmedProfileTitle: "Freigegebene Store-Profile",
      noConfirmedProfileAvailable: "Es sind noch keine Profile für Dich freigegeben.",
      unconfirmedProfileTitle: "Unbestätigte Store-Profile",
      noUnconfirmedProfileAvailable: "Es sind keine unbestätigten Profile vorhanden.",
      createButton: "Neues Store-Profil erstellen",
      showDetailsButton: "Details anzeigen",
      continueProgressButton: "Bearbeitung fortsetzen",
      deleteStoreProfileTooltip: "Store-Profil löschen",
      deleteDraftTooltip: "Entwurf löschen",
      activateProfileTooltip: "Store-Profil aktivieren",
      deactivateStoreProfileTooltip: "Store-Profil deaktivieren",
      showPublishedVersionTooltip: "Veröffentliches Store-Profil anzeigen",
      showDeactivatedVersionTooltip: "Freigegebenes (nicht veröffentlichtes) Store-Profil anzeigen",
      storeType: {
        [StoreOverviewTypeEnum.Local]: "Lokales Ladengeschäft",
        [StoreOverviewTypeEnum.Online]: "Online-Store",
      } as Record<StoreOverviewTypeEnum, string>,
      location: "{{street}} {{houseNumber}}, {{zip}} {{city}}",
      inReviewInfoText:
        "Wir prüfen dein neues Store-Profil für dich. Sobald es freigegeben wird, wird es auf heo.com veröffentlicht. Du kannst die Sichtbarkeit deines Profils (auch vor der Freigabe) jederzeit <strong>aktivieren</strong> oder <strong>deaktivieren</strong>.",
      profileApprovalStatus: {
        [StoreProfileNewRequestOverviewApprovalStatusEnum.Approved]: "Freigegeben",
        [StoreProfileNewRequestOverviewApprovalStatusEnum.Rejected]: "Neue Version abgelehnt",
        [StoreProfileNewRequestOverviewApprovalStatusEnum.InReview]: "Neue Version in Prüfung",
      } as Record<StoreProfileNewRequestOverviewApprovalStatusEnum, string>,
      newRequestApprovalStatus: {
        [StoreProfileNewRequestOverviewApprovalStatusEnum.Approved]: "Freigegeben",
        [StoreProfileNewRequestOverviewApprovalStatusEnum.Rejected]: "Abgelehnt",
        [StoreProfileNewRequestOverviewApprovalStatusEnum.InReview]: "In Prüfung",
      } as Record<StoreProfileNewRequestOverviewApprovalStatusEnum, string>,
      draft: {
        status: "Entwurf",
        noContactData: "Keine Daten",
      },
    },
  },

  confirmationModals: {
    deleteModal: {
      title: {
        draft: "Store-Profil-Entwurf löschen",
        profil: "Store-Profil löschen",
      },
      confirmationText: {
        draft:
          "Möchtest du den Store-Profil-Entwurf für <strong>{{storeName}}</strong> wirklich löschen?",
        profile: "Möchtest du das Store-Profil <strong>{{storeName}}</strong> wirklich löschen?",
      },

      informationText: {
        withChangeRequest:
          "Sowohl die freigegebene bzw. veröffentlichte als auch die sich in Prüfung befindende neue Store-Profil-Version werden dabei gelöscht.",
        withoutChangeRequest:
          "Ein eventuell bereits veröffentlichtes Store-Profil auf heo.com wird ebenfalls entfernt. ",
        draft: "Bisher gemachte Angaben gehen dabei unwiderruflich verloren.",
      },
      cancelButton: "Abbrechen",
      submitButton: "Store-Profil löschen",
    },
    publishModal: {
      title: {
        activate: "Store-Profil aktivieren",
        deactivate: "Store-Profil deaktivieren",
      },
      confirmationText: {
        deactivate:
          "Möchtest du das Store-Profil <strong>{{storeName}}</strong> wirklich deaktivieren?",
        activate:
          "Möchtest du das Store-Profil <strong>{{storeName}}</strong> wirklich aktivieren?",
      },

      informationText: {
        profile: {
          deactivate:
            "Die zuletzt freigegebene Store-Profil-Version wird nach erfolgter Deaktivierung nicht mehr auf heo.com für Endkunden angezeigt. Du kannst das Store-Profil zu jeder Zeit wieder aktivieren.",
          activate:
            "Die zuletzt von heo freigegebene Store-Profil-Version wird nach Aktivierung automatisch veröffentlicht.",
        },
        changeRequest: {
          deactivate:
            "Durch die Deaktivierung wird das Store-Profil nach erfolgter Freigabe nicht mehr automatisch auf heo.com für Endkunden veröffentlicht. Du kannst das Store-Profil zu jeder Zeit wieder aktivieren.",
          activate:
            "Das Store-Profil wird dadurch automatisch auf heo.com veröffentlicht, sobald es freigegeben wurde.",
        },
      },
      success: {
        activate: "Dein Store-Profil <strong>{{storeName}}</strong> wurde erfolgreich aktiviert.",
        deactivate:
          "Dein Store-Profil <strong>{{storeName}}</strong> wurde erfolgreich deaktiviert und wird nun nicht mehr auf heo.com angezeigt.",
      },

      cancelButton: "Abbrechen",
      submitButton: {
        activate: "Store-Profil aktivieren",
        deactivate: "Store-Profil deaktivieren",
      },
    },

    deleteChangeRequestModal: {
      title: "Neue Version verwerfen?",
      confirmationText:
        "Möchtest du wirklich die neue Version verwerfen und zur zuletzt freigegebenen bzw. aktuell veröffentlichten Version zurückkehren?",

      error: {
        title: "Die neue Version konnte nicht verworfen werden.",
        message: "Es gab einen Fehler beim Verwerfen der neuen Version. Bitte nochmal versuchen.",
      },
      success: {
        message: "Deine neu eingereichte Store-Profil-Version wurde erfolgreich verworfen.",
      },

      cancelButton: "Nein",
      submitButton: "Ja, neue Version verwerfen",
    },
  },

  form: {
    newProfilePageTitle: "Neues Store-Profil anlegen",
    existiingProfilePageTitle: "Store-Profil: {{storeName}}",
    backToOverviewButton: "Zurück zur Store-Profile-Übersicht",
    createOrUpdateSuccessMessage: {
      draft: {
        title: "Store-Profil-Entwurf erfolgreich gespeichert.",
        message: "Dein Store-Profil-Entwurf wurde erfolgreich gespeichert.",
      },
      profile: {
        title: "Store-Profil erfolgreich gespeichert.",
        message: "Dein Store-Profil wurde erfolgreich gespeichert.",
      },
    },

    preview: {
      tooltip: "Für Vorschau Mindestangaben angeben",
      showPreviewButton: "Vorschau anzeigen",
    },

    logo: {
      approvalRequiredInfoText:
        "Bitte beachte: Das Hochladen eines neuen Store-Logos erfordert eine erneute inhaltliche Prüfung bzw. Freigabe durch heo. Ein bisher aktiviertes Store-Profil bleibt so lange sichtbar, bis die Überarbeitung freigegeben ist.",
      modalTitle: "Dein Store Logo",
      deleteModalTitle: "Store Logo wirklich löschen?",
      deleteModalDescription: "Möchtest du dein Store-Logo wirklich löschen?",
      deleteCancel: "Abbrechen",
      deleteConfirm: "Logo löschen",
    },

    banner: {
      approvalRequiredInfoText:
        "Bitte beachte: Das Hochladen eines neuen Store-Banners erfordert eine erneute inhaltliche Prüfung bzw. Freigabe durch heo. Ein bisher aktiviertes Store-Profil bleibt so lange sichtbar, bis die Überarbeitung freigegeben ist.",
      modalTitle: "Dein Store Banner",
      deleteModalTitle: "Store Banner wirklich löschen?",
      deleteModalDescription:
        "Möchtest du deinen Store-Banner wirklich löschen und zum Standard-Hintergrund zurückkehren?",
      deleteCancel: "Abbrechen",
      deleteConfirm: "Banner löschen",
    },

    imageUploader: {
      cancelLabel: "Abbrechen",
      submitLabel: "Zuschneiden & Hochladen",
      uploadError: "Das Bild konnte nicht hochgeladen werden.",
    },

    basicInformation: {
      approvalRequiredInfoText:
        "Bitte beachte: Änderungen an Freitext-Feldern erfordern eine erneute inhaltliche Prüfung bzw. Freigabe durch heo. Ein bisher veröffentlichtes Store-Profil bleibt sichtbar, bis die Änderungen freigegeben sind.",
      introText:
        "Präsentiere deinen Store! Füge den Namen deines Geschäfts, das Gründungsdatum, eine kurze Beschreibung und weitere Details hinzu, um den Kunden einen ersten Einblick zu geben, was deinen Store besonders macht.",
      openModalButtonLabel: "Basisinformationen hinzufügen",
      modalTitle: { add: "Basisinformationen hinzufügen", edit: "Basisinformationen ändern" },
      storeNameTitel: "Welcher Storename soll den Nutzern bei der Suche angezeigt werden?",
      storeNameLabel: "Storename",
      storeTypeDescription:
        "Soll das Store-Profil ein lokales Ladengeschäft repräsentieren oder einen Online-Store? ",
      storeTypeAdditionalInfo:
        "(Weitere Store-Profile für zusätzliche Standorte oder Online-Stores sind möglich.)",

      localStoreLabel: "Lokales Ladengeschäft",
      onlineStoreLabel: "Online-Store",
      openingDateLabel: "Seit wann gibt es deinen Store? (Optional)",
      openingDateMonthLabel: "Monat",
      openingDateYearLabel: "Jahr",
      descriptionLabel: "Was macht deinen Store besonders? (Optional)",
      descriptionPlaceholder:
        "Bitte beschreibe deinen Store für deine Kunden mit max. 2000 Zeichen.",
      wpnStoreLabel: "Bist du ein offizieller WPN-Store?",
      wpnStoreTooltip:
        "Das Wizards Play Network ist eine Community mit über 6.000 Game-Stores, in denen sich Magic-Spieler treffen, um geimeinsam ihr Lieblingsspiel zu spielen.",
      wpnStoreYes: "Ja",
      wpnStoreNo: "Nein",
      otsStoreLabel: "Bist du ein offizieller OTS-Store?",
      otsStoreTooltip:
        "Official Tournament Stores sind Turniergeschäfte die Veranstaltungen für die Yu-Gi-Oh! TCG Community veranstalten.",
      otsStoreYes: "Ja",
      otsStoreNo: "Nein",
      cancelButtonLabel: "Abbrechen",
      submitButtonLabel: "Übernehmen",
      localStoreBadgeLabel: "Lokales Ladengeschäft",
      onlineStoreBadgeLabel: "Online-Store",
      openingDateBadge: "Gründung: {{date}}",
      wpnStoreBadge: "WPN-Store",
      otsStoreBadge: "OTS-Store",
      descriptionUsageHint: "{{ count }} von maximal {{ max }} Zeichen",
      showMoreDescriptionLabel: "Mehr anzeigen",
      showLessDescriptionLabel: "Weniger anzeigen",
      descriptionMaxLengthExceeded:
        "Die Store-Beschreibung darf maximal {{ length }} Zeichen lang sein.",
    },

    contact: {
      approvalRequiredInfoText:
        "Bitte beachte: Änderungen an Freitext-Feldern erfordern eine erneute inhaltliche Prüfung bzw. Freigabe durch heo. Ein bisher veröffentlichtes Store-Profil bleibt sichtbar, bis die Änderungen freigegeben sind.",
      sectionTitle: "Kontaktdaten",
      introText: "Teile deine Kontaktdaten, damit Kunden dich finden bzw. erreichen können.",
      openModalButtonLabel: "Kontaktdaten hinzufügen",
      modalTitle: { add: "Kontaktdaten hinzufügen", edit: "Kontaktdaten ändern" },
      addressTitle: "Wie lautet die Adresse deines Stores?",
      streetLabel: "Straße",
      streetPlaceholder: "Straße",
      houseNumberLabel: "Hausnummer",
      houseNumberPlaceholder: "HsNr.",
      additionalAddressLabel: "Adresszusatz",
      additionalAddressPlaceholder: "z.B. linker Eingang",
      zipCodeLabel: "PLZ",
      zipCodePlaceholder: "PLZ",
      cityLabel: "Ort",
      cityPlaceholder: "Ort",
      countryLabel: "Land",
      countryPlaceholder: "Bitte auswählen",
      phoneNumerTitle: "Unter welcher Telefonnummer können dich deine Kunden erreichen?",
      phoneNumberLabel: "Telefonnummer",
      phoneNumberPlaceholder: "Telefonnummer eingeben",
      mailTitle: "Über welche E-Mailadresse können dich deine Kunden kontaktieren?",
      mailLabel: "E-Mailadresse",
      mailPlaceholder: "E-Mailadresse eingeben",
      websiteTitle: "Über welche Website können dich deine Kunden finden?",
      websiteLabel: "Webseite",
      websitePlaceholder: "Website eingeben",
      cancelButtonLabel: "Abbrechen",
      submitButtonLabel: "Übernehmen",
      formInvalidText:
        "Durch das Ändern des Store-Typs sind deine Kontaktdaten nicht mehr vollständig.",
      placeIdLabel: "Google Orts-ID",
      placeIdPlaceholder: "Wähle deinen Store",
      placeIdPreviewLabel: "Google Orts-ID Vorschau",
      addAddress: "Adresse hinzufügen (optional)",
      removeAddress: "Adresse entfernen",
    },

    openingHours: {
      sectionTitle: "Öffnungszeiten",
      introText:
        "Gib an, wann dein Store geöffnet ist, damit Kunden wissen, wann sie dich besuchen können.",
      openModalButtonLabel: "Öffungszeiten hinzufügen",
      modalTitle: "Öffnungszeiten",
      openHoursDescription: "An welchen Tagen und zu welcher Uhrzeit hat dein Store geöffnet? ",
      weekdays: {
        monday: "Montag",
        tuesday: "Dienstag",
        wednesday: "Mittwoch",
        thursday: "Donnerstag",
        friday: "Freitag",
        saturday: "Samstag",
        sunday: "Sonn- und Feiertag",
      },
      isOpenCheckedLabel: "Geöffnet",
      isOpenUncheckedLabel: "Geschlossen",
      weekdayClosed: "Geschlossen",
      cancelButtonLabel: "Abbrechen",
      submitButtonLabel: "Übernehmen",
      genericErrorText:
        "An geöffneten Tagen muss sowohl eine Öffnungs- als auch eine Schließzeit angegeben werden. Die Öffnungszeit muss vor der Schließzeit sein.",
    },
    productCategory: {
      sectionTitle: "Produktsortiment",
      modalTitle: "Produktkategorien auswählen",
      modalDescription: "Welche Produktkategorien führst du in deinem Sortiment?",
      introText:
        "Informiere deine Kunden über dein angebotenes Produktsortiment. Wähle die Produktkategorien aus, die am besten zu deinem Store passen.",
      openModalButtonLabel: "Produktkategorien auswählen",
      cancelButtonLabel: "Abbrechen",
      submitButtonLabel: "Übernehmen",
    },
    paymentOptions: {
      sectionTitle: "Zahlungsoptionen",
      modalTitle: "Zahlungsoptionen auswählen",
      modalDescription: "Welche Zahlungsmöglichkeiten stehen deinen Kunden zur Verfügung?",
      introText:
        "Zeige deinen Kunden, wie sie bei dir bezahlen können - füge deine akzeptierten Zahlungsoptionen hinzu.",
      openModalButtonLabel: "Zahlungsoptionen hinzufügen",
      cancelButtonLabel: "Abbrechen",
      submitButtonLabel: "Übernehmen",
    },

    storeImages: {
      approvalRequiredInfoText:
        "Bitte beachte: Das Hochladen neuer Store-Bilder erfordert eine erneute inhaltliche Prüfung bzw. Freigabe durch heo. Ein bisher aktiviertes Store-Profil bleibt so lange sichtbar, bis die Überarbeitung freigegeben ist.",
      sectionTitle: "Store-Bilder",
      introText:
        "Lade Fotos deines Stores hoch, um den Kunden einen echten Eindruck von deinem Angebot zu vermitteln.",
      deleteModalTitle: "Bild wirklich löschen?",
      deleteModalConfirmLabel: "Bild löschen",
      deleteModalCancelLabel: "Abbrechen",
      uploadError: "Das Bild konnte nicht hochgeladen werden.",
    },

    accessibility: {
      sectionTitle: "Store-Zugänglichkeit",
      modalTitle: "Store-Zugänglichkeit auswählen",
      modalDescription: "Welche Anforderungen hinsichtlich Barrierefreiheit erfüllt dein Store?",
      introText:
        "Gib Informationen zur Zugänglichkeit deines Stores an, um alle Kunden willkommen zu heißen.",
      openModalButtonLabel: "Store-Zugänglichkeit auswählen",
      cancelButtonLabel: "Abbrechen",
      submitButtonLabel: "Übernehmen",
    },
    activities: {
      sectionTitle: "Store-Aktivitäten",
      modalTitle: "Store-Aktivitäten auswählen",
      modalDescription: "Welche Aktivitäten finden in deinem Store statt?",
      introText:
        "Teile deinen Kunden mit, welche Aktivitäten oder Events in deinem Store stattfinden.",
      openModalButtonLabel: "Store-Aktivitäten auswählen",
      cancelButtonLabel: "Abbrechen",
      submitButtonLabel: "Übernehmen",
    },
    equipment: {
      sectionTitle: "Store-Ausstattung",
      modalTitle: "Store-Ausstattung auswählen",
      modalDescription: "Welche Ausstattungsmerksmale sind in deinem Store vorzufinden?",
      introText:
        "Hebe dich mit deiner Store-Ausstattung hervor! Wähle aus mehreren Ausstattungsoptionen aus.",
      openModalButtonLabel: "Store-Ausstattung auswählen",
      cancelButtonLabel: "Abbrechen",
      submitButtonLabel: "Übernehmen",
    },
    progressPanel: {
      statusBox: {
        title: "Informationen zum Store-Profil",
        createdAt: "Erstellt am: <strong>{{date}}</strong>",
        updatedAt: "Zuletzt geändert: <strong>{{date}}</strong>",
        statusLabel: "Status: <strong>{{status}}</strong>",
        visibilityLabel: "Sichtbarkeit: <strong>{{visibility}}</strong>",
        deactivateButton: "Store-Profil deaktivieren",
        activateButton: "Store-Profil aktivieren",
        showPublishedVersionLinkLabel: "Veröffentlichte Version anzeigen",
        showReleasedVersionLinkLabel: "Freigegebene Version anzeigen",
        discardVersionButton: "Neue Version verwerfen",
        status: {
          newRequest: "Erstantrag in Prüfung",
          newRequestRejected: "Abgelehnt",
          approved: "Freigegeben",
          changeRequest: "Neue Version in Prüfung",
          changeRequestRejected: "Neue Version abgelehnt",
        } as Record<ProfileStatusEnum, string>,
        visibility: {
          published: "Veröffentlicht",
          notPublished: "Deaktiviert",
          notYetPublished: "Noch nicht veröffentlicht",
        } as Record<VisibilityEnum, string>,
      },
      newRequest: {
        title: "Dein Store-Profil in 5 Minuten",
        description:
          "Super, dass du <strong>deinen Store auf heo.com</strong> Endkunden präsentieren möchtest! <br><br> Um ein Store-Profil nach einer rechtlichen Prüfung freigeben und veröffentlichen zu können, benötigen wir noch einige Informationen von dir.",
      },
      editRequest: {
        title: "Store-Profil verbessern",
        description: "Erweitere dein Store-Profil um weitere Angaben und beeindrucke deine Kunden.",
      },

      progressBar: {
        title: "Aktueller Fortschritt",
        stepOfSteps: "{{step}}/{{maxSteps}}",
        infoBox: {
          mandatory: {
            title: "Notwendige Mindestangaben",
            basicInformation: "Basisinformationen",
            contact: "Kontaktdaten",
            productCategories: "Produktsortiment",
            allDone: {
              title: "Store-Profil bereit für Veröffentlichung:",
              description: "Du hast alle notwendigen Angaben ausgefüllt.",
            },
          },

          optional: {
            infoText:
              "Erweitere dein Store-Profil um weitere Angaben und beeindrucke deine Kunden.",
            allDone: "Super! Dein Store-Profil ist vollständig.",
          },
        },
      },
      legal: {
        title: "Rechtliche Hinweise",
        description:
          "Bitte lies dir unsere allgemeinen Geschäftsbedingungen, die Copyright & Haftungsbeschränkung sowie Datenschutzerklärung aufmerksam durch und bestätige uns, dass du diese akzeptierst:",
        termsAndConditions:
          "Hiermit bestätige ich, dass ich die <0>allgemeinen Geschäftsbedingungen</0> und die <1>Copyright & Haftungsbeschränkung</1> gelesen und akzeptiert habe.",
        privacy: "Ich habe die <0>Datenschutzerklärung</0> zur Kenntnis genommen.",
      },
      submitButtonNew: "Store-Profil beantragen",
      submitButtonEdit: "Änderungen beantragen",
      draftButton: "Als Entwurf speichern",
    },

    errors: {
      title: {
        default: "Dein Store-Profil konte leider nicht gespeichert werden.",
        changeRequestNotDeletable: "Der Antrag konnte nicht verworfen werden.",
      },
      message: {
        duplicate: "Du hast bereits ein Store-Profil mit derselben Adresse angelegt.",
        staleProfile: "Der Antrag wurde bereits Angenommen und kann nicht mehr bearbeitet werden.",
        staleChangeRequest:
          "Der Antrag wurde bereits Angenommen und kann nicht mehr bearbeitet werden.",
        profileNotFound: "Das Store-Profil existiert nicht mehr.",
        changeRequestNotFound: "Der Antrag existiert nicht mehr.",
        changeRequestNotDeletable: "Der Antrag wurde bereits freigegeben.",
        default: "Bitte versuche es später erneut oder prüfe deine Eingaben auf Fehler",
      },
    },
  },
};
