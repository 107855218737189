/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GrantedSettlement = {
    Unknown: 'UNKNOWN',
    FullRefund: 'FULL_REFUND',
    PartialRefund: 'PARTIAL_REFUND',
    FullReplacement: 'FULL_REPLACEMENT',
    PartialReplacement: 'PARTIAL_REPLACEMENT',
    Return: 'RETURN',
    Purchase: 'PURCHASE',
    ReturnAndRedelivery: 'RETURN_AND_REDELIVERY',
    ReturnAndRefund: 'RETURN_AND_REFUND',
    PurchaseAndRedelivery: 'PURCHASE_AND_REDELIVERY'
} as const;
export type GrantedSettlement = typeof GrantedSettlement[keyof typeof GrantedSettlement];


export function GrantedSettlementFromJSON(json: any): GrantedSettlement {
    return GrantedSettlementFromJSONTyped(json, false);
}

export function GrantedSettlementFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrantedSettlement {
    return json as GrantedSettlement;
}

export function GrantedSettlementToJSON(value?: GrantedSettlement | null): any {
    return value as any;
}

