import { Box } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import clsx from "clsx";
import { memo } from "react";

import { TableRowType } from "..";
import { getColumnPaddings } from "../helper/getColumnPaddings";
import { getRowContent } from "../helper/getRowContent";
import { DataTableColumn } from "../interface/DataTableColumn";
import { Row } from "../interface/DataTableRow";

import styles from "./TableRow.module.css";

export interface TableRowProps<RowType extends TableRowType> {
  row: Row<RowType>;
  columns: DataTableColumn[];
  isSelected: boolean;
  hasRowSelection: boolean;
  rowHeight: number;
  columnGap: number;
  onRowClick: (row: Row<RowType>) => void;
}

const TableRow = <TableRow extends TableRowType>(props: TableRowProps<TableRow>) => {
  const {
    row,
    columns,
    isSelected,
    hasRowSelection,
    columnGap,
    rowHeight = 35,
    onRowClick,
  } = props;

  const handleRowClick = () => {
    onRowClick(row);
  };

  const rowClassNames = clsx(
    styles.tableRow,
    isSelected && styles.selectedRow,
    hasRowSelection && styles.hasRowSelection,
  );

  const [isHovered, handler] = useDisclosure(false);
  return (
    <div
      data-testid="datatable-row"
      role="row"
      className={rowClassNames}
      onClick={handleRowClick}
      onMouseEnter={handler.open}
      onMouseLeave={handler.close}
    >
      {columns.map((column, index) => {
        const { pl, pr } = getColumnPaddings({
          column,
          index,
          columnCount: columns.length,
          columnGap,
        });

        const testIdPrefix = column.testId ?? column.accessor;

        return (
          <Box
            key={`${row.id}_${index}`}
            data-testid={`${testIdPrefix}-cell`}
            role={"cell"}
            style={{ height: rowHeight }}
            className={styles.tableCell}
            pl={pl}
            pr={pr}
          >
            {getRowContent(row.data, column, isSelected, isHovered)}
          </Box>
        );
      })}
    </div>
  );
};

const MemoizedTableRow = memo(TableRow);
export { MemoizedTableRow as TableRow };
