/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MediaLinks
 */
export interface MediaLinks {
    /**
     * 
     * @type {string}
     * @memberof MediaLinks
     */
    websiteUrl: string;
    /**
     * 
     * @type {string}
     * @memberof MediaLinks
     */
    shopUrl: string;
    /**
     * 
     * @type {string}
     * @memberof MediaLinks
     */
    twitterUrl: string;
    /**
     * 
     * @type {string}
     * @memberof MediaLinks
     */
    facebookUrl: string;
    /**
     * 
     * @type {string}
     * @memberof MediaLinks
     */
    youtubeUrl: string;
    /**
     * 
     * @type {string}
     * @memberof MediaLinks
     */
    instagramUrl: string;
    /**
     * 
     * @type {string}
     * @memberof MediaLinks
     */
    twitchUrl: string;
}

/**
 * Check if a given object implements the MediaLinks interface.
 */
export function instanceOfMediaLinks(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "websiteUrl" in value;
    isInstance = isInstance && "shopUrl" in value;
    isInstance = isInstance && "twitterUrl" in value;
    isInstance = isInstance && "facebookUrl" in value;
    isInstance = isInstance && "youtubeUrl" in value;
    isInstance = isInstance && "instagramUrl" in value;
    isInstance = isInstance && "twitchUrl" in value;

    return isInstance;
}

export function MediaLinksFromJSON(json: any): MediaLinks {
    return MediaLinksFromJSONTyped(json, false);
}

export function MediaLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaLinks {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'websiteUrl': json['websiteUrl'],
        'shopUrl': json['shopUrl'],
        'twitterUrl': json['twitterUrl'],
        'facebookUrl': json['facebookUrl'],
        'youtubeUrl': json['youtubeUrl'],
        'instagramUrl': json['instagramUrl'],
        'twitchUrl': json['twitchUrl'],
    };
}

export function MediaLinksToJSON(value?: MediaLinks | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'websiteUrl': value.websiteUrl,
        'shopUrl': value.shopUrl,
        'twitterUrl': value.twitterUrl,
        'facebookUrl': value.facebookUrl,
        'youtubeUrl': value.youtubeUrl,
        'instagramUrl': value.instagramUrl,
        'twitchUrl': value.twitchUrl,
    };
}

