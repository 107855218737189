/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TotalSales
 */
export interface TotalSales {
    /**
     * 
     * @type {number}
     * @memberof TotalSales
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof TotalSales
     */
    ordersAmount: number;
    /**
     * 
     * @type {number}
     * @memberof TotalSales
     */
    openOrdersAmount: number;
    /**
     * 
     * @type {number}
     * @memberof TotalSales
     */
    invoiceAmount: number;
    /**
     * 
     * @type {number}
     * @memberof TotalSales
     */
    creditNoteAmount: number;
    /**
     * 
     * @type {number}
     * @memberof TotalSales
     */
    canceledInvoiceAmount: number;
    /**
     * 
     * @type {number}
     * @memberof TotalSales
     */
    canceledOrdersAmount: number;
    /**
     * 
     * @type {number}
     * @memberof TotalSales
     */
    quota: number;
}

/**
 * Check if a given object implements the TotalSales interface.
 */
export function instanceOfTotalSales(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "ordersAmount" in value;
    isInstance = isInstance && "openOrdersAmount" in value;
    isInstance = isInstance && "invoiceAmount" in value;
    isInstance = isInstance && "creditNoteAmount" in value;
    isInstance = isInstance && "canceledInvoiceAmount" in value;
    isInstance = isInstance && "canceledOrdersAmount" in value;
    isInstance = isInstance && "quota" in value;

    return isInstance;
}

export function TotalSalesFromJSON(json: any): TotalSales {
    return TotalSalesFromJSONTyped(json, false);
}

export function TotalSalesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TotalSales {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': json['year'],
        'ordersAmount': json['ordersAmount'],
        'openOrdersAmount': json['openOrdersAmount'],
        'invoiceAmount': json['invoiceAmount'],
        'creditNoteAmount': json['creditNoteAmount'],
        'canceledInvoiceAmount': json['canceledInvoiceAmount'],
        'canceledOrdersAmount': json['canceledOrdersAmount'],
        'quota': json['quota'],
    };
}

export function TotalSalesToJSON(value?: TotalSales | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'ordersAmount': value.ordersAmount,
        'openOrdersAmount': value.openOrdersAmount,
        'invoiceAmount': value.invoiceAmount,
        'creditNoteAmount': value.creditNoteAmount,
        'canceledInvoiceAmount': value.canceledInvoiceAmount,
        'canceledOrdersAmount': value.canceledOrdersAmount,
        'quota': value.quota,
    };
}

