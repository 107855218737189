import { useQuery } from "@tanstack/react-query";
import { GetCurrentDeliveriesOfCurrentRetailerRequest } from "~/api/openapi";
import { useRestAPI } from "~/api/useRestAPI";

export const GetOpenInvoiceOverviewQueryKey = "GetOpenInvoiceOverviewQueryKey";

export const useGetCurrentDeliveriesQuery = (
  requestParameters: GetCurrentDeliveriesOfCurrentRetailerRequest,
) => {
  const api = useRestAPI();

  return useQuery({
    queryKey: ["GetCurrentDeliveriesQueryKey"],
    queryFn: () => api.dashboard.getCurrentDeliveriesOfCurrentRetailer(requestParameters),
  });
};
