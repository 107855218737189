import { Box, Button, Group, Stack } from "@mantine/core";
import { Trans, useTranslation } from "react-i18next";

import { useAppConfig } from "~/config/AppConfig";

const TotalSalesNoDataContainer = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "dashboardPage.totalSalesReportWidget",
  });
  const appConfig = useAppConfig();
  const b2bShopUrl = appConfig.links.shop;
  return (
    <Stack gap={"xl"}>
      <Box>
        <Trans t={t} i18nKey="noDataInfoText" />
      </Box>
      <Group justify="right">
        <Button component="a" href={b2bShopUrl} target="_blank">
          {t("heoShopButton")}
        </Button>
      </Group>
    </Stack>
  );
};

export { TotalSalesNoDataContainer };
