import { Group, Skeleton, Space, Stack } from "@mantine/core";

import styles from "./LoadingSkeleton.module.css";

const LoadingSkeleton = () => {
  return (
    <>
      <Stack gap={"xs"} className={styles.wrapper}>
        <Group className={styles.header} justify={"center"}>
          <Skeleton h={20} width="250px" />
        </Group>
        <Group justify="center" gap={0}>
          <Skeleton circle height={55} />
          <Skeleton ml={-10} circle height={55} />
          <Skeleton ml={-10} circle height={55} />
          <Skeleton ml={-10} circle height={55} />
        </Group>
        <Group justify="center">
          <Skeleton h={35} width="130px" />
        </Group>
        <Space h={"xs"} />
      </Stack>
      <Stack gap={"xs"} className={styles.wrapper}>
        <Group className={styles.header} justify={"center"}>
          <Skeleton h={20} width="250px" />
        </Group>
        <Group justify="center" gap={0}>
          <Skeleton circle height={55} />
          <Skeleton ml={-10} circle height={55} />
          <Skeleton ml={-10} circle height={55} />
          <Skeleton ml={-10} circle height={55} />
        </Group>
        <Group justify="center">
          <Skeleton h={35} width="130px" />
        </Group>
        <Space h={"xs"} />
      </Stack>
      <Stack gap={"xs"} className={styles.wrapper}>
        <Group className={styles.header} justify={"center"}>
          <Skeleton h={20} width="250px" />
        </Group>
        <Group justify="center" gap={0}>
          <Skeleton circle height={55} />
          <Skeleton ml={-10} circle height={55} />
          <Skeleton ml={-10} circle height={55} />
          <Skeleton ml={-10} circle height={55} />
        </Group>
        <Group justify="center">
          <Skeleton h={35} width="130px" />
        </Group>
        <Space h={"xs"} />
      </Stack>
    </>
  );
};

export { LoadingSkeleton };
