/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreProfileImageMetaData } from './StoreProfileImageMetaData';
import {
    StoreProfileImageMetaDataFromJSON,
    StoreProfileImageMetaDataFromJSONTyped,
    StoreProfileImageMetaDataToJSON,
} from './StoreProfileImageMetaData';

/**
 * 
 * @export
 * @interface StoreProfileDetailsImages
 */
export interface StoreProfileDetailsImages {
    /**
     * 
     * @type {StoreProfileImageMetaData}
     * @memberof StoreProfileDetailsImages
     */
    header?: StoreProfileImageMetaData;
    /**
     * 
     * @type {StoreProfileImageMetaData}
     * @memberof StoreProfileDetailsImages
     */
    brand?: StoreProfileImageMetaData;
    /**
     * 
     * @type {Array<StoreProfileImageMetaData>}
     * @memberof StoreProfileDetailsImages
     */
    store?: Array<StoreProfileImageMetaData>;
}

/**
 * Check if a given object implements the StoreProfileDetailsImages interface.
 */
export function instanceOfStoreProfileDetailsImages(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StoreProfileDetailsImagesFromJSON(json: any): StoreProfileDetailsImages {
    return StoreProfileDetailsImagesFromJSONTyped(json, false);
}

export function StoreProfileDetailsImagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfileDetailsImages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'header': !exists(json, 'header') ? undefined : StoreProfileImageMetaDataFromJSON(json['header']),
        'brand': !exists(json, 'brand') ? undefined : StoreProfileImageMetaDataFromJSON(json['brand']),
        'store': !exists(json, 'store') ? undefined : ((json['store'] as Array<any>).map(StoreProfileImageMetaDataFromJSON)),
    };
}

export function StoreProfileDetailsImagesToJSON(value?: StoreProfileDetailsImages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'header': StoreProfileImageMetaDataToJSON(value.header),
        'brand': StoreProfileImageMetaDataToJSON(value.brand),
        'store': value.store === undefined ? undefined : ((value.store as Array<any>).map(StoreProfileImageMetaDataToJSON)),
    };
}

