import { Anchor, Group, Stack, Text } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import { useAppConfig } from "~/config/AppConfig";

import styles from "./LegalInfo.module.css";

const LegalInfo = () => {
  const { t } = useTranslation();
  const appConfig = useAppConfig();

  return (
    <Stack gap="xs">
      <Group wrap="nowrap">
        <Anchor
          className={styles.link}
          target="_blank"
          href={appConfig.links.impressum}
          c={"dimmed"}
          fw={"normal"}
        >
          <Text mr={4}>{t("footer.impressumLinkLabel")}</Text> <IconExternalLink size={14} />
        </Anchor>
      </Group>

      <Anchor
        className={styles.link}
        target="_blank"
        href={appConfig.links.privacy}
        c={"dimmed"}
        fw={"normal"}
      >
        <Text mr={4}>{t("footer.privacyLinkLabel")}</Text> <IconExternalLink size={14} />
      </Anchor>

      <Anchor
        className={styles.link}
        target="_blank"
        href={appConfig.links.copyright}
        c={"dimmed"}
        fw={"normal"}
      >
        <Text mr={4}>{t("footer.copyrightLinkLabel")}</Text> <IconExternalLink size={14} />
      </Anchor>
    </Stack>
  );
};
export { LegalInfo };
