import { useEffect } from "react";

export const useHtmlAttribute = (key: string, value: boolean) => {
  useEffect(() => {
    if (value) {
      document.getElementsByTagName("html")[0].setAttribute(key, `${value}`);
    } else {
      document.getElementsByTagName("html")[0].removeAttribute(key);
    }
  }, [key, value]);
};
