import {
  DndContext,
  DragOverEvent,
  DragStartEvent,
  PointerSensor,
  TouchSensor,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arraySwap } from "@dnd-kit/sortable";
import { useBreakpoint } from "@etecture/rex";
import { Group, MantineSize } from "@mantine/core";
import React, { ReactNode, useEffect, useState } from "react";
import { GridColumn } from "./GridColumn/GridColumn";
import { GridDragOverlay } from "./GridDragOverlay/GridDragOverlay";
import { useGridColumns } from "./hooks/useGridColumns";

export type GridLayoutItem = {
  id: string;
  content: ReactNode;
  hidden?: boolean;
  disabled?: boolean;
  static?: boolean;
};

export type OnToggleVisibility = (item: GridLayoutItem) => void;

export type GridLayoutBreakpoints = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

export interface GridLayoutProps {
  items: GridLayoutItem[];
  onChange: (items: GridLayoutItem[]) => void;
  columnBreakpoints: GridLayoutBreakpoints;
  gap?: MantineSize;
  weights?: number[];
  isEditing?: boolean;
  noDataContent?: ReactNode;
}

const GridLayout: React.FC<GridLayoutProps> = (props) => {
  const {
    items,
    onChange,
    columnBreakpoints,
    gap = "md",
    weights = [],
    isEditing,
    noDataContent,
  } = props;

  const [localItems, setLocalItems] = useState(items);
  useEffect(() => setLocalItems(items), [items]);

  const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));

  const { value: columnCount } = useBreakpoint(columnBreakpoints);
  const { columns } = useGridColumns({ items: localItems, columnCount: columnCount });

  const [draggingId, setDraggingId] = useState<string | null>(null);
  const draggingItem = localItems.find((it) => it.id === draggingId);

  const handleDragStart = (event: DragStartEvent) => {
    setDraggingId(event.active.id.toString());
  };

  const handleDragOver = (event: DragOverEvent) => {
    if (!event.over || event.over.id === event.active.id) return;
    const originalItems = [...localItems];

    const movingIndex = originalItems.findIndex((it) => it.id === event.active.id);
    const overIndex = originalItems.findIndex((it) => it.id === event.over?.id);

    if (movingIndex < 0 || overIndex < 0 || movingIndex === overIndex) return;
    if (originalItems[overIndex].static) return;

    setLocalItems(arraySwap(originalItems, movingIndex, overIndex));
  };

  const handleDragEnd = () => {
    setDraggingId(null);
    onChange(localItems);
  };

  const handleToggleVisibility = (item: GridLayoutItem) => {
    const newItems = [...localItems];
    newItems.splice(newItems.indexOf(item), 1, { ...item, disabled: !item.disabled });
    setLocalItems(newItems);
    onChange(newItems);
  };

  const allItemsDisabled = localItems.every((item) => item.disabled);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      cancelDrop={(args) => {
        return Boolean(localItems.find((it) => it.id === args.over?.id)?.static);
      }}
    >
      {allItemsDisabled && !isEditing && noDataContent}

      <Group wrap="nowrap" gap={gap} align="flex-start">
        {Object.keys(columns).map((columnId, index) => {
          const columnItems = columns[columnId];
          const columnWeight = weights[index] ?? 1;

          return (
            <SortableContext key={`column_${columnId}`} items={columnItems} disabled={!isEditing}>
              <GridColumn
                gap={gap}
                weight={columnWeight}
                items={columnItems}
                isEditing={Boolean(isEditing)}
                onToggleVisibility={handleToggleVisibility}
              />
            </SortableContext>
          );
        })}
      </Group>

      <GridDragOverlay item={draggingItem} />
    </DndContext>
  );
};

export { GridLayout };
