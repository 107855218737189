import { useQuery } from "@tanstack/react-query";

import { useRestAPI } from "../../useRestAPI";

export const GetOpenInvoiceOverviewQueryKey = "GetOpenInvoiceOverviewQueryKey";

export const useGetOpenInvoiceOverviewQuery = () => {
  const api = useRestAPI();

  return useQuery({
    queryKey: [GetOpenInvoiceOverviewQueryKey],
    queryFn: () => api.dashboard.getOpenInvoiceOverviewOfCurrentRetailer(),
  });
};
