import { Loader, Text } from "@mantine/core";
import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import styles from "./LoadMoreRow.module.css";

export interface LoadMoreRowProps {
  hasNextPage: boolean | undefined;
  style?: CSSProperties;
}

const LoadMoreRow: React.FC<LoadMoreRowProps> = (props) => {
  const { hasNextPage, style } = props;
  const { t } = useTranslation();

  const canLoadMore = hasNextPage === undefined || hasNextPage === true;

  return (
    <div style={style} className={styles.container}>
      {!canLoadMore && <Text c={"dimmed"}>{t("datatable.noNextPage")}</Text>}
      {canLoadMore && <Loader variant="dots" />}
    </div>
  );
};

export { LoadMoreRow };
