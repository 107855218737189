import {
  ActionIcon,
  Anchor,
  Button,
  Center,
  Group,
  Indicator,
  LoadingOverlay,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { GridLayout } from "~/ui/components/GridLayout/GridLayout";
import { GuidedTourModal } from "~/ui/components/GuidedTourModal/GuidedTourModal";
import { PageContainer } from "~/ui/layout/PageContainer/PageContainer";

import { useMediaQuery } from "@mantine/hooks";
import { useResponsiveMode } from "~/hooks/ui/useResponsiveMode";
import { KpiContainer } from "./DashboardWidgets/Kpi/KpiContainer";
import { RetailerNotifications } from "./RetailerNotifications/RetailerNotifications";
import {
  DASHBOARD_GUIDED_TOUR_SEEN_KEY,
  dashboardGuidedTour,
} from "./guidedTour/dashboardGuidedTourData";
import { useDashboardGrid } from "./hooks/useDashboardGrid";

const DashboardPage: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "dashboardPage" });

  const {
    gridItems,
    setGridItems,
    setKpiTilesPeriods,
    kpiTilesPeriods,
    saveSettings,
    resetSettings,
    isLoading,
    isChanged,
  } = useDashboardGrid();

  const allHidden = gridItems.every((item) => item.disabled);

  const [isEditing, setIsEditing] = useState(false);
  const startEditing = () => setIsEditing(true);
  const endEditing = () => {
    saveSettings();
    setIsEditing(false);
  };

  const { isMobile } = useResponsiveMode();

  const isSmall = useMediaQuery("(max-width: 500px)");

  const noDataContent = (
    <Center h={350}>
      <Stack>
        <Title order={2} ta="center">
          {t("noDataTitle")}
        </Title>
        <Text ta="center" maw={400}>
          {t(isMobile ? "noDataDescriptionMobile" : "noDataDescription")}
        </Text>

        {!isMobile && (
          <Group justify="center">
            <Button onClick={startEditing}>{t("startEditingButtonLabel")}</Button>
          </Group>
        )}
      </Stack>
    </Center>
  );

  return (
    <PageContainer
      px={isSmall ? 0 : undefined}
      id="dashboard-page-container"
      pageTitle={t("title")}
      withPadding
    >
      {!isMobile && (
        <GuidedTourModal
          guidedTourStorageKey={DASHBOARD_GUIDED_TOUR_SEEN_KEY}
          guidedTour={dashboardGuidedTour}
          showIntro={true}
        />
      )}

      <Stack pb={"md"}>
        <LoadingOverlay visible={isLoading} />

        <Group justify="space-between">
          <Title ml={isSmall ? "md" : undefined}>{t("title")}</Title>

          {isEditing && (
            <Group>
              {isChanged && <Anchor onClick={resetSettings}>{t("resetDashboard")}</Anchor>}
              <Button onClick={endEditing}>{t("endEditing")}</Button>
            </Group>
          )}

          {!isEditing && !allHidden && !isMobile && (
            <Tooltip label={t("editDashboardTooltip")}>
              <Indicator disabled={!isChanged}>
                <ActionIcon size={"lg"} variant="outline" color="blue" onClick={startEditing}>
                  <IconEdit />
                </ActionIcon>
              </Indicator>
            </Tooltip>
          )}
        </Group>

        <RetailerNotifications />

        <KpiContainer
          isEditing={isEditing}
          onChange={setKpiTilesPeriods}
          kpiTilesPeriods={kpiTilesPeriods}
        />

        <GridLayout
          columnBreakpoints={{ xl: 3, lg: 2, md: 1 }}
          items={gridItems}
          isEditing={isEditing}
          onChange={setGridItems}
          noDataContent={noDataContent}
        />
      </Stack>
    </PageContainer>
  );
};

// eslint-disable-next-line import/no-default-export
export default DashboardPage;
