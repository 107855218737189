/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FactoringLimit
 */
export interface FactoringLimit {
    /**
     * 
     * @type {Date}
     * @memberof FactoringLimit
     */
    activeSince: Date;
    /**
     * 
     * @type {number}
     * @memberof FactoringLimit
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof FactoringLimit
     */
    available: number;
    /**
     * 
     * @type {number}
     * @memberof FactoringLimit
     */
    used: number;
}

/**
 * Check if a given object implements the FactoringLimit interface.
 */
export function instanceOfFactoringLimit(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "activeSince" in value;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "available" in value;
    isInstance = isInstance && "used" in value;

    return isInstance;
}

export function FactoringLimitFromJSON(json: any): FactoringLimit {
    return FactoringLimitFromJSONTyped(json, false);
}

export function FactoringLimitFromJSONTyped(json: any, ignoreDiscriminator: boolean): FactoringLimit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activeSince': (new Date(json['activeSince'])),
        'limit': json['limit'],
        'available': json['available'],
        'used': json['used'],
    };
}

export function FactoringLimitToJSON(value?: FactoringLimit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activeSince': (value.activeSince.toISOString()),
        'limit': value.limit,
        'available': value.available,
        'used': value.used,
    };
}

