import { Button, Group } from "@mantine/core";
import { useTranslation } from "react-i18next";

type FeedbackDrawerActionBarProps = {
  checkIfDirtyAndClose: () => void;
  isDirty: boolean;
};
const FeedbackDrawerActionBar: React.FC<FeedbackDrawerActionBarProps> = (props) => {
  const { isDirty, checkIfDirtyAndClose } = props;
  const { t } = useTranslation();
  return (
    <Group justify={"right"}>
      <Button variant="outline" onClick={checkIfDirtyAndClose}>
        {t("feedback.drawer.cancel")}
      </Button>
      <Button type="submit" disabled={!isDirty}>
        {t("feedback.drawer.send")}
      </Button>
    </Group>
  );
};

export { FeedbackDrawerActionBar };
