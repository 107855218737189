import { ActionIcon, Tooltip } from "@mantine/core";
import { IconCreditCardPay } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useShopUrl } from "~/ui/pages/ProductsPage/ProductDetail/ProductInformation/PriceBox/useShopUrl";

type InvoicePayNowButtonProps = {
  invoiceNumber: number;
  grossAmount: number;
};
const InvoicePayNowButton: React.FC<InvoicePayNowButtonProps> = (props) => {
  const { invoiceNumber, grossAmount } = props;
  const { shopUrl } = useShopUrl();
  const { t } = useTranslation();
  const shopPaymentUrl = `${shopUrl}/account/invoices/${invoiceNumber}?total=${grossAmount}`;

  const handlePaymentClick = async (event: React.MouseEvent) => {
    event.stopPropagation();
    window.open(shopPaymentUrl, "_blank");
  };

  return (
    <Tooltip label={t("invoicesPage.detail.payNow")}>
      <ActionIcon onClick={handlePaymentClick}>
        <IconCreditCardPay />
      </ActionIcon>
    </Tooltip>
  );
};

export { InvoicePayNowButton };
