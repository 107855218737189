import { Divider, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconBox,
  IconBuildingWarehouse,
  IconCodeCircle2,
  IconCopyright,
  IconFileInvoice,
  IconInfoCircle,
  IconInfoSquareRounded,
  IconLayoutDashboard,
  IconMail,
  IconMessage,
  IconProps,
  IconReceiptRefund,
  IconShieldLock,
  IconShoppingBag,
  IconTruckReturn,
} from "@tabler/icons-react";
import { t } from "i18next";
import React from "react";

import { useAppConfig } from "~/config/AppConfig";
import { RoutesEnum } from "~/ui/routes/RoutesEnum";

import { ContactDrawer } from "~/ui/components/Contact/ContactDrawer";
import { FeedbackDrawer } from "~/ui/components/Feedback/FeedbackDrawer";
import { BuildVersions } from "../BuildVersions/BuildVersions";
import { ExtendedNavbarButton } from "../ExtendedNavbarButton/ExtendedNavbarButton";
import { LegalInfo } from "../LegalInfo/LegalInfo";
import { NavbarItem, NavbarItemProps } from "./NavbarItem/NavbarItem";

export interface NavbarContentProps {
  hideNavbarLinks: boolean;
  isExtended?: boolean;
  forceCollapse?: boolean;
  isMobileView?: boolean;
  onExtend?: () => void;
  onItemClick?: () => void;
}

const NavbarContent: React.FC<NavbarContentProps> = (props) => {
  const {
    hideNavbarLinks,
    isExtended = true,
    isMobileView,
    forceCollapse,
    onExtend,
    onItemClick,
  } = props;
  const appConfig = useAppConfig();

  const gap = isMobileView ? "xs" : 0;

  const [isFeedbackOpen, feedbackHandler] = useDisclosure(false);
  const [isContactOpen, contactHandler] = useDisclosure(false);

  const handleClick = () => {
    onItemClick?.();
  };

  const handleContactClick = () => {
    contactHandler.open();
    handleClick();
  };

  const handleFeedbackClick = () => {
    feedbackHandler.open();
    handleClick();
  };

  const itemProps = {
    isExpanded: Boolean(isExtended),
    iconPosition: isMobileView ? "left" : "right",
    labelProps: {
      fz: isMobileView ? "lg" : "md",
      pl: isMobileView ? "md" : undefined,
    },
    onClick: handleClick,
  } satisfies Partial<NavbarItemProps>;

  const iconProps = {
    size: isMobileView ? 30 : undefined,
  } satisfies Partial<IconProps>;

  return (
    <Stack style={{ flex: 1 }}>
      {!hideNavbarLinks && (
        <Stack gap={gap}>
          <NavbarItem
            icon={<IconLayoutDashboard {...iconProps} />}
            label={t("navBar.dashboadLabel")}
            path={RoutesEnum.DASHBOARD}
            {...itemProps}
          />
          <NavbarItem
            icon={<IconBox {...iconProps} />}
            label={t("navBar.productsLabel")}
            path={RoutesEnum.PRODUCTS}
            {...itemProps}
          />
          <NavbarItem
            icon={<IconShoppingBag {...iconProps} />}
            label={t("navBar.customerOrdersLabel")}
            path={RoutesEnum.CUSTOMER_ORDERS}
            {...itemProps}
          />
          <NavbarItem
            icon={<IconBuildingWarehouse {...iconProps} />}
            label={t("navBar.backorderLabel")}
            path={RoutesEnum.SALES_ORDERS}
            {...itemProps}
          />
          <NavbarItem
            icon={<IconFileInvoice {...iconProps} />}
            label={t("navBar.invoicesLabel")}
            path={RoutesEnum.INVOICES}
            {...itemProps}
          />
          <NavbarItem
            icon={<IconTruckReturn {...iconProps} />}
            label={t("navBar.productClaimsLabel")}
            path={RoutesEnum.PRODUCT_CLAIMS}
            {...itemProps}
          />
          <NavbarItem
            icon={<IconReceiptRefund {...iconProps} />}
            label={t("navBar.creditsLabel")}
            path={RoutesEnum.CREDITS}
            {...itemProps}
          />
        </Stack>
      )}

      {isMobileView && (
        <Stack>
          <Divider color="heoPetrol.7" />

          <NavbarItem
            icon={<IconMail {...iconProps} />}
            label={t("navBar.contactLabel")}
            {...itemProps}
            onClick={handleContactClick}
          />
          <ContactDrawer isOpen={isContactOpen} onClose={contactHandler.close} />

          <NavbarItem
            icon={<IconMessage {...iconProps} />}
            label={t("navBar.feedbackLabel")}
            {...itemProps}
            onClick={handleFeedbackClick}
          />
          <FeedbackDrawer isOpen={isFeedbackOpen} onClose={feedbackHandler.close} />

          <Divider color="heoPetrol.7" />

          <NavbarItem
            icon={<IconInfoSquareRounded {...iconProps} />}
            label={t("footer.impressumLinkLabel")}
            href={appConfig.links.impressum}
            target={"_blank"}
            {...itemProps}
          />
          <NavbarItem
            icon={<IconShieldLock {...iconProps} />}
            label={t("footer.privacyLinkLabel")}
            href={appConfig.links.privacy}
            target={"_blank"}
            {...itemProps}
          />
          <NavbarItem
            icon={<IconCopyright {...iconProps} />}
            label={t("footer.copyrightLinkLabel")}
            href={appConfig.links.copyright}
            target={"_blank"}
            {...itemProps}
          />
        </Stack>
      )}

      {!isMobileView && (
        <Stack gap={0} mt={"auto"}>
          <NavbarItem
            icon={<IconInfoCircle />}
            label={t("navBar.impressLabel")}
            popover={<LegalInfo />}
            {...itemProps}
          />

          <NavbarItem
            icon={<IconCodeCircle2 />}
            label={t("navBar.version.label")}
            popover={<BuildVersions />}
            {...itemProps}
          />
        </Stack>
      )}

      {onExtend && !forceCollapse && (
        <Stack mt={50}>
          <ExtendedNavbarButton isNavbarExtended={isExtended} handleExtendNavbarClick={onExtend} />
        </Stack>
      )}
    </Stack>
  );
};

export { NavbarContent };
