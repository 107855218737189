import { TotalSalesReport } from "~/api/openapi";

import { TotalSalesItem } from "./TotalSalesItem";

export const mapTotalSalesReportDataToListItems = (
  reportData: TotalSalesReport | undefined,
): TotalSalesItem[] => {
  if (!reportData?.years) return [];

  return reportData.years
    .map((year) => ({
      year: year.year,
      orderAmount: year.ordersAmount,
      invoiceAmount: year.invoiceAmount,
      creditNoteAmount: year.creditNoteAmount,
      canceledOrderAmount: year.canceledOrdersAmount,
      canceledInvoiceAmount: year.canceledInvoiceAmount,
      quota: year.quota,
    }))
    .sort((a, b) => b.year - a.year);
};
