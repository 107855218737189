/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FileAccessResponseBody,
  InvoiceItemCsvExportSummary,
  InvoiceSummary,
  InvoiceTotals,
  PagedAndSyncedResultInvoiceSummary,
  PaymentMethod,
  PaymentStatus,
  SortDirection,
} from '../models';
import {
    FileAccessResponseBodyFromJSON,
    FileAccessResponseBodyToJSON,
    InvoiceItemCsvExportSummaryFromJSON,
    InvoiceItemCsvExportSummaryToJSON,
    InvoiceSummaryFromJSON,
    InvoiceSummaryToJSON,
    InvoiceTotalsFromJSON,
    InvoiceTotalsToJSON,
    PagedAndSyncedResultInvoiceSummaryFromJSON,
    PagedAndSyncedResultInvoiceSummaryToJSON,
    PaymentMethodFromJSON,
    PaymentMethodToJSON,
    PaymentStatusFromJSON,
    PaymentStatusToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface DownloadInvoiceDocumentRequest {
    downloadToken: string;
}

export interface GetDetailOfInvoiceRequest {
    invoiceNumber: number;
}

export interface GetInvoiceSummariesOfCurrentRetailerRequest {
    offset?: number;
    limit?: number;
    createdBetweenFrom?: Date;
    createdBetweenUntil?: Date;
    paymentMethod?: PaymentMethod;
    fullTextQuery?: string;
    paymentStatus?: Array<PaymentStatus>;
    sortByCreatedAt?: SortDirection;
    sortByPaymentMethod?: SortDirection;
    sortByPaymentStatus?: SortDirection;
    sortByShippingAddressName?: SortDirection;
    sortByShippingStatus?: SortDirection;
    sortByTotal?: SortDirection;
    sortByTransactionNumber?: SortDirection;
    sortByInvoiceReference?: SortDirection;
}

export interface GetInvoiceSummariesOfCurrentRetailerCsvExportRequest {
    createdBetweenFrom?: Date;
    createdBetweenUntil?: Date;
    paymentMethod?: PaymentMethod;
    fullTextQuery?: string;
    paymentStatus?: Array<PaymentStatus>;
}

export interface RequestInvoiceDownloadRequest {
    companyCode: string;
    invoiceNumber: number;
}

/**
 * 
 */
export class InvoiceResourceApi extends runtime.BaseAPI {

    /**
     */
    async downloadInvoiceDocumentRaw(requestParameters: DownloadInvoiceDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.downloadToken === null || requestParameters.downloadToken === undefined) {
            throw new runtime.RequiredError('downloadToken','Required parameter requestParameters.downloadToken was null or undefined when calling downloadInvoiceDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/invoice/download/{downloadToken}`.replace(`{${"downloadToken"}}`, encodeURIComponent(String(requestParameters.downloadToken))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async downloadInvoiceDocument(requestParameters: DownloadInvoiceDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadInvoiceDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDetailOfInvoiceRaw(requestParameters: GetDetailOfInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InvoiceSummary>> {
        if (requestParameters.invoiceNumber === null || requestParameters.invoiceNumber === undefined) {
            throw new runtime.RequiredError('invoiceNumber','Required parameter requestParameters.invoiceNumber was null or undefined when calling getDetailOfInvoice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/invoice/{invoiceNumber}`.replace(`{${"invoiceNumber"}}`, encodeURIComponent(String(requestParameters.invoiceNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvoiceSummaryFromJSON(jsonValue));
    }

    /**
     */
    async getDetailOfInvoice(requestParameters: GetDetailOfInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InvoiceSummary> {
        const response = await this.getDetailOfInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getInvoiceSummariesOfCurrentRetailerRaw(requestParameters: GetInvoiceSummariesOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedAndSyncedResultInvoiceSummary>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.createdBetweenFrom !== undefined) {
            queryParameters['createdBetweenFrom'] = (requestParameters.createdBetweenFrom as any).toISOString();
        }

        if (requestParameters.createdBetweenUntil !== undefined) {
            queryParameters['createdBetweenUntil'] = (requestParameters.createdBetweenUntil as any).toISOString();
        }

        if (requestParameters.paymentMethod !== undefined) {
            queryParameters['paymentMethod'] = requestParameters.paymentMethod;
        }

        if (requestParameters.fullTextQuery !== undefined) {
            queryParameters['fullTextQuery'] = requestParameters.fullTextQuery;
        }

        if (requestParameters.paymentStatus) {
            queryParameters['paymentStatus'] = requestParameters.paymentStatus;
        }

        if (requestParameters.sortByCreatedAt !== undefined) {
            queryParameters['sortByCreatedAt'] = requestParameters.sortByCreatedAt;
        }

        if (requestParameters.sortByPaymentMethod !== undefined) {
            queryParameters['sortByPaymentMethod'] = requestParameters.sortByPaymentMethod;
        }

        if (requestParameters.sortByPaymentStatus !== undefined) {
            queryParameters['sortByPaymentStatus'] = requestParameters.sortByPaymentStatus;
        }

        if (requestParameters.sortByShippingAddressName !== undefined) {
            queryParameters['sortByShippingAddressName'] = requestParameters.sortByShippingAddressName;
        }

        if (requestParameters.sortByShippingStatus !== undefined) {
            queryParameters['sortByShippingStatus'] = requestParameters.sortByShippingStatus;
        }

        if (requestParameters.sortByTotal !== undefined) {
            queryParameters['sortByTotal'] = requestParameters.sortByTotal;
        }

        if (requestParameters.sortByTransactionNumber !== undefined) {
            queryParameters['sortByTransactionNumber'] = requestParameters.sortByTransactionNumber;
        }

        if (requestParameters.sortByInvoiceReference !== undefined) {
            queryParameters['sortByInvoiceReference'] = requestParameters.sortByInvoiceReference;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/invoice`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedAndSyncedResultInvoiceSummaryFromJSON(jsonValue));
    }

    /**
     */
    async getInvoiceSummariesOfCurrentRetailer(requestParameters: GetInvoiceSummariesOfCurrentRetailerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedAndSyncedResultInvoiceSummary> {
        const response = await this.getInvoiceSummariesOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getInvoiceSummariesOfCurrentRetailerCsvExportRaw(requestParameters: GetInvoiceSummariesOfCurrentRetailerCsvExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InvoiceItemCsvExportSummary>>> {
        const queryParameters: any = {};

        if (requestParameters.createdBetweenFrom !== undefined) {
            queryParameters['createdBetweenFrom'] = (requestParameters.createdBetweenFrom as any).toISOString();
        }

        if (requestParameters.createdBetweenUntil !== undefined) {
            queryParameters['createdBetweenUntil'] = (requestParameters.createdBetweenUntil as any).toISOString();
        }

        if (requestParameters.paymentMethod !== undefined) {
            queryParameters['paymentMethod'] = requestParameters.paymentMethod;
        }

        if (requestParameters.fullTextQuery !== undefined) {
            queryParameters['fullTextQuery'] = requestParameters.fullTextQuery;
        }

        if (requestParameters.paymentStatus) {
            queryParameters['paymentStatus'] = requestParameters.paymentStatus;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/invoice/export-csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvoiceItemCsvExportSummaryFromJSON));
    }

    /**
     */
    async getInvoiceSummariesOfCurrentRetailerCsvExport(requestParameters: GetInvoiceSummariesOfCurrentRetailerCsvExportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InvoiceItemCsvExportSummary>> {
        const response = await this.getInvoiceSummariesOfCurrentRetailerCsvExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getInvoiceTotalsByPaymentStatusOfCurrentRetailerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InvoiceTotals>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/invoice/total`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvoiceTotalsFromJSON));
    }

    /**
     */
    async getInvoiceTotalsByPaymentStatusOfCurrentRetailer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InvoiceTotals>> {
        const response = await this.getInvoiceTotalsByPaymentStatusOfCurrentRetailerRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async requestInvoiceDownloadRaw(requestParameters: RequestInvoiceDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileAccessResponseBody>> {
        if (requestParameters.companyCode === null || requestParameters.companyCode === undefined) {
            throw new runtime.RequiredError('companyCode','Required parameter requestParameters.companyCode was null or undefined when calling requestInvoiceDownload.');
        }

        if (requestParameters.invoiceNumber === null || requestParameters.invoiceNumber === undefined) {
            throw new runtime.RequiredError('invoiceNumber','Required parameter requestParameters.invoiceNumber was null or undefined when calling requestInvoiceDownload.');
        }

        const queryParameters: any = {};

        if (requestParameters.companyCode !== undefined) {
            queryParameters['companyCode'] = requestParameters.companyCode;
        }

        if (requestParameters.invoiceNumber !== undefined) {
            queryParameters['invoiceNumber'] = requestParameters.invoiceNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/invoice/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileAccessResponseBodyFromJSON(jsonValue));
    }

    /**
     */
    async requestInvoiceDownload(requestParameters: RequestInvoiceDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileAccessResponseBody> {
        const response = await this.requestInvoiceDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
