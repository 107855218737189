import { Avatar, Timeline, Tooltip } from "@mantine/core";
import { CurrentInvoiceSummary } from "~/api/openapi";
import { Scroller } from "~/ui/components/Scroller/Scroller";
import styles from "../CurrentDeliveriesWidget.module.css";
import { TimelineItem } from "./TimelineItem";

type CurrentDeliveriesTimelineProps = {
  timelineData: CurrentInvoiceSummary[];
};

const CurrentDeliveriesTimeline: React.FC<CurrentDeliveriesTimelineProps> = (props) => {
  const { timelineData } = props;

  return (
    <Scroller mah={400}>
      <Timeline
        style={{ marginLeft: 16 }}
        classNames={{
          item: styles.item,
          itemBullet: styles.itemBullet,
          itemBody: styles.itemBody,
        }}
      >
        {timelineData?.map((item) => {
          return (
            <Timeline.Item
              key={item.id}
              bullet={
                <Tooltip label={item.productItems[0].productName}>
                  <Avatar
                    styles={{
                      root: {
                        border: "solid 2px white",
                      },
                    }}
                    size="48px"
                    radius="xl"
                    src={item.productItems[0].thumbnailUrl}
                  />
                </Tooltip>
              }
            >
              <TimelineItem timelineItem={item} />
            </Timeline.Item>
          );
        })}
      </Timeline>
    </Scroller>
  );
};

export { CurrentDeliveriesTimeline };
