import { Box, Transition } from "@mantine/core";
import { ReactNode, useRef } from "react";
import Chart, { Props as ChartProps } from "react-apexcharts";

import { useDelayedValue } from "~/hooks/state/useDelayedValue";
import styles from "./DonutChart.module.css";

export type DonutChartProps = ChartProps & {
  type: "donut";
  children?: ReactNode;
  width?: undefined;
};

const DonutChart: React.FC<DonutChartProps> = (props) => {
  const { children, ...chartProps } = props;

  const centerBox = useRef<HTMLDivElement | null>(null);

  // the chart svg gets rendered before the parents have reached their correct width
  // so the chart is initially rendered with less width than it should
  // With a small delay we workaround this issue
  const isVisible = useDelayedValue({
    value: true,
    delay: 50,
    initialValue: false,
  });

  return (
    <Transition mounted={isVisible} transition={"fade"} duration={300}>
      {(transitionStyles) => (
        <Box pb={"lg"} style={{ position: "relative", ...transitionStyles }}>
          <Box className={styles.chartWrapper}>
            <Chart
              {...chartProps}
              options={{
                ...chartProps.options,
                legend: { ...chartProps.legend, position: "bottom" },
              }}
            />
          </Box>

          <Box ref={centerBox} className={styles.centerBox}>
            <Box className={styles.centerBoxElement}>{children}</Box>
          </Box>
        </Box>
      )}
    </Transition>
  );
};

export { DonutChart };
