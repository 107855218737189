/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClientCredentials } from './ClientCredentials';
import {
    ClientCredentialsFromJSON,
    ClientCredentialsFromJSONTyped,
    ClientCredentialsToJSON,
} from './ClientCredentials';
import type { HeoCustomerNumber } from './HeoCustomerNumber';
import {
    HeoCustomerNumberFromJSON,
    HeoCustomerNumberFromJSONTyped,
    HeoCustomerNumberToJSON,
} from './HeoCustomerNumber';

/**
 * 
 * @export
 * @interface SandboxClient
 */
export interface SandboxClient {
    /**
     * 
     * @type {HeoCustomerNumber}
     * @memberof SandboxClient
     */
    heoCustomerNumber: HeoCustomerNumber;
    /**
     * 
     * @type {ClientCredentials}
     * @memberof SandboxClient
     */
    clientCredentials: ClientCredentials;
    /**
     * 
     * @type {string}
     * @memberof SandboxClient
     */
    description: string;
    /**
     * 
     * @type {Date}
     * @memberof SandboxClient
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the SandboxClient interface.
 */
export function instanceOfSandboxClient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "heoCustomerNumber" in value;
    isInstance = isInstance && "clientCredentials" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function SandboxClientFromJSON(json: any): SandboxClient {
    return SandboxClientFromJSONTyped(json, false);
}

export function SandboxClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): SandboxClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'heoCustomerNumber': HeoCustomerNumberFromJSON(json['heoCustomerNumber']),
        'clientCredentials': ClientCredentialsFromJSON(json['clientCredentials']),
        'description': json['description'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function SandboxClientToJSON(value?: SandboxClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'heoCustomerNumber': HeoCustomerNumberToJSON(value.heoCustomerNumber),
        'clientCredentials': ClientCredentialsToJSON(value.clientCredentials),
        'description': value.description,
        'createdAt': (value.createdAt.toISOString()),
    };
}

