import { Anchor, Group, Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { formatCurrency } from "~/helper/Formatter/formatCurrency";
import { DateStyle, useLocalizedDateFormatter } from "~/hooks/date/useLocalizedDateFormatter";
import { createInvoiceLink } from "~/ui/routes/DynamicRoutes/createInvoiceLink";

import { PaymentMethod, PaymentStatus, PaymentType } from "~/api/openapi";
import { InvoicePayNowButton } from "~/ui/components/InvoicePayNowButton/InvoicePayNowButton";
import { InvoiceWidgetChartData } from "./interface/InvoiceWidgetData";

interface TimelineItemDataProps {
  widgetData: InvoiceWidgetChartData;
}

const TimelineItemData: React.FC<TimelineItemDataProps> = (props) => {
  const {
    invoiceNumber,
    invoiceDate,
    totalAmount,
    paymentDueDate,
    paymentType,
    paymentStatus,
    paymentMethod,
  } = props.widgetData;

  const { t } = useTranslation();
  const { formatDate } = useLocalizedDateFormatter();

  const showPayNowButton =
    paymentMethod === PaymentMethod.Braintree &&
    paymentStatus !== PaymentStatus.Paid &&
    paymentStatus !== PaymentStatus.Canceled;

  const isPrepayment = paymentType === PaymentType.Prepayment;

  return (
    <Group justify={"space-between"} align={"flex-end"}>
      <Stack gap={0} pl={"sm"}>
        <Text fz={"sm"} c={"dimmed"}>
          {formatDate(invoiceDate, DateStyle.SHORT)}
        </Text>
        <Group>
          <Text fw={"bolder"} size="lg" style={{ paddingTop: 4 }}>
            {t(`paymentStatus.${paymentStatus}`)}
          </Text>
          {showPayNowButton && (
            <InvoicePayNowButton invoiceNumber={invoiceNumber} grossAmount={totalAmount} />
          )}
        </Group>

        <Text fz={14}>
          {t(
            `dashboardPage.OpenInvoicesWidget.item.${isPrepayment ? "paymentDeadlineLabel" : "paymentDueDateLabel"}`,
            {
              paymentDueDate: paymentDueDate ? formatDate(paymentDueDate) : "",
            },
          )}
        </Text>
      </Stack>
      <Stack align={"flex-end"} gap={0} pr={"md"}>
        <Anchor
          component={Link}
          to={createInvoiceLink({ search: invoiceNumber, selected: invoiceNumber })}
        >
          {invoiceNumber}
        </Anchor>
        <Text fz={14}>{formatCurrency(totalAmount)}</Text>
      </Stack>
    </Group>
  );
};

export { TimelineItemData };
