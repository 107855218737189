/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductNumberThumbnail
 */
export interface ProductNumberThumbnail {
    /**
     * 
     * @type {string}
     * @memberof ProductNumberThumbnail
     */
    productNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductNumberThumbnail
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductNumberThumbnail
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductNumberThumbnail
     */
    productName?: string;
}

/**
 * Check if a given object implements the ProductNumberThumbnail interface.
 */
export function instanceOfProductNumberThumbnail(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductNumberThumbnailFromJSON(json: any): ProductNumberThumbnail {
    return ProductNumberThumbnailFromJSONTyped(json, false);
}

export function ProductNumberThumbnailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductNumberThumbnail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productNumber': !exists(json, 'productNumber') ? undefined : json['productNumber'],
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'productName': !exists(json, 'productName') ? undefined : json['productName'],
    };
}

export function ProductNumberThumbnailToJSON(value?: ProductNumberThumbnail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productNumber': value.productNumber,
        'thumbnailUrl': value.thumbnailUrl,
        'price': value.price,
        'productName': value.productName,
    };
}

