import { useGetUiSettings } from "~/api/hooks/userSettings/useGetUiSettings";
import { useSetUiSettings } from "~/api/hooks/userSettings/useSetUiSettings";

import { AccountSettings } from "./AccountSettingsSchema";

export type UpdateSettingsRequest = Partial<AccountSettings>;

export const useAccountSettings = () => {
  const { data: accountSettings, isPending, isFetching } = useGetUiSettings();

  const updateAccountSettings = useSetUiSettings();

  const updateSettings = (updateRequestParam: UpdateSettingsRequest) => {
    updateAccountSettings.mutate({
      setUiSettingsRequestBody: { json: JSON.stringify(updateRequestParam) },
    });
  };

  return {
    accountSettingsLoading: isPending,
    accountSettingsIsFetching: isFetching,
    accountSettings,
    updateSettings,
  };
};
