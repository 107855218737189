import { Box, Button, Center, Stack, Text, Title } from "@mantine/core";
import React, { ReactNode } from "react";

import styles from "./ErrorLayout.module.css";

export interface ErrorLayoutProps {
  image: ReactNode;
  title: string;
  subtitle: ReactNode;
  buttonLabel: string;
  buttonHref: string;
  buttonIcon?: ReactNode;
}

const ErrorLayout: React.FC<ErrorLayoutProps> = (props) => {
  const { image, buttonHref, buttonLabel, subtitle, title, buttonIcon } = props;

  return (
    <Box h={"100%"} w={"100%"} maw={1800} mx={"auto"} style={{ position: "relative" }}>
      <Box className={styles.image}>{image}</Box>

      <Center h={"100%"}>
        <Stack gap={"md"} align="flex-start" justify="center" className={styles.content}>
          <Title fz={42} fw={"bolder"}>
            {title}
          </Title>

          <Text fz={"lg"} maw={460}>
            {subtitle}
          </Text>

          <Button size="lg" component="a" href={buttonHref} leftSection={buttonIcon} mt={"md"}>
            {buttonLabel}
          </Button>
        </Stack>
      </Center>
    </Box>
  );
};

export { ErrorLayout };
