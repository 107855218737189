import React, { ReactNode } from "react";
import { AuthProvider } from "react-oidc-context";

import { useAppConfig } from "../config/AppConfig";

export interface AuthContainerProps {
  children: ReactNode;
}

const AuthContainer: React.FC<AuthContainerProps> = (props) => {
  const appConfig = useAppConfig();
  const { clientId, issuerUrl, redirectUri } = appConfig.keycloak;

  return (
    <AuthProvider
      authority={issuerUrl}
      client_id={clientId}
      redirect_uri={redirectUri}
      onSigninCallback={() => {
        window.history.replaceState({}, document.title, window.location.pathname);
      }}
    >
      {props.children}
    </AuthProvider>
  );
};

export { AuthContainer };
