export const SalesOrdersPageDE = {
  pageTitle: "Meine Rückstände",
  title: "Meine Rückstände ({{ count }})",

  filter: {
    title: "Filtern & Sortieren",
    statusPlaceholder: "Status auswählen",
    searchPlaceholder: "Rückstände durchsuchen (min. 3 Zeichen)",
    statusTiles: {
      overdueOfWhich: "davon",
      total: "Gesamt",
    },
    availabilityPlaceholder: "Verfügbarkeit auswählen",
    nothingFound: "Keine Ergebnisse gefunden",
    manufacturerSelectAll: "Alle Hersteller",
    productTypeSelectAll: "Alle Produkttypen",
    createdAtTitle: "Datum",
    manufacturersTitle: "Hersteller",
    manufacturersSearch: "Nach Hersteller filtern",
    typesTitle: "Produkttypen",
    typesSearch: "Nach Produkttypen filtern",
    availabilityTitle: "Vsl. Verfügbarkeit",
    statusTitle: "Status",
  },

  sort: {
    product: "Artikel",
    productManufacturers: "Hersteller",
    productTypesThemes: "Produkttyp",
    customerOrderNumber: "Bestellt",
    orderReceivedAt: "Eingang best. am",
    availabilityStatus: "Status",
    amount: "Anzahl",
    priceAndDiscount: "Einzelpreis",
    discount: "Rabatt",
    totalPrice: "Gesamtsumme",
    availability: "Vsl. Verfügbarkeit",
    customerReference: "Bestellreferenz",
    customerItemReference: "Artikelreferenz",
    gtin: "GTIN",
    infoToCustomer: "Zusatzinfo",
  },

  card: {
    noCustomerOrderNumber: "Keine Bestell-Nr. vorhanden",
    customerOrderNumber: "Bestellnummer",
    availabilityStatus: "Status",
    amount: "Anzahl",
    amountValue: "{{amount}} Stück",
    price: "Einzelpreis",
    priceValue: "{{price}} (inkl. {{discount}}% Rabatt)",
    totalPrice: "Gesamtsumme",
    availability: "Vsl. Verfügbarkeit",
    customerReference: "Bestellreferenz",
    customerItemReference: "Artikelreferenz",
    infoToCustomer: "Zusatzinfo",
  },

  table: {
    noSalesOrdersAvailable: "Keine Rückstände vorhanden.",
    noSalesOrdersFound: "Keine Rückstände gefunden, die deinen Suchkriterien entsprechen.",
    productOneClickTooltip: "Liste nach Artikel {{productNumber}} durchsuchen",
    header: {
      product: "Artikel",
      productManufacturers: "Hersteller",
      productTypes: "Produkttyp",
      customerOrderNumber: "Bestellnummer",
      orderReceivedAt: "Eingang best. am",
      status: "Status",
      amount: "Anzahl",
      pricePerUnit: "Einzelpreis",
      discount: "Rabatt",
      total: "Gesamtsumme",
      availability: "Vsl. Verfügbarkeit",
      customerReference: "Bestellreferenz",
      customerItemReference: "Artikelreferenz",
      gtin: "GTIN",
      infoToCustomer: "Zusatzinfo",
    },
    body: {
      amount: "{{ ofStatus }} von {{ total }}",
      lastGoodsReceiptAt: "Wareneingang:",
      noLastGoodsReceiptAt: "Kein Wareneingang",
      inclDiscount: "inkl. {{ discount }} Rabatt",
    },
  },
  csv: {
    downloadFileName: "heo-csv-export-backorders_{{date}}",
    tooltipDownloadButton: "CSV-Export (Daten aus Suchergebnis)",
    productNumber: "Artikelnummer",
    gtin: "GTIN",
    productName: "Artikelname",
    productManufacturers: "Hersteller",
    productTypes: "Produkttyp",
    productThemes: "Produktthema",
    customerOrderNumber: "Bestellnummer",
    orderReceivedAt: "Eingang best. am",
    status: "Status",
    lastGoodsReceiptAt: "Wareneingangsdatum",
    amount: "Anzahl",
    pricePerUnit: "Einzelpreis (inkl. Rabatt)",
    discount: "Rabatt",
    total: "Gesamtsumme",
    availability: "Vsl. Verfügbarkeit",
    customerReference: "Bestellreferenz",
    customerItemReference: "Artikelreferenz",
    infoToCustomer: "Zusatzinfo",
  },
};
