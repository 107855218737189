import { useAuth } from "react-oidc-context";

import { useGetAccountDetailsQuery } from "~/api/hooks/account/useGetAccountDetailsQuery";

export interface AuthProfile {
  fullName: string | undefined;
  email: string | undefined;
}

export const useAuthProfile = (): AuthProfile | null => {
  const auth = useAuth();
  const accountDetailsQuery = useGetAccountDetailsQuery();

  if (!auth.user && !accountDetailsQuery.data) {
    return null;
  }

  return {
    fullName:
      auth.user?.profile.name ?? accountDetailsQuery.data?.contactDetails.name.fullName ?? "Guest",
    email: auth.user?.profile.email ?? accountDetailsQuery.data?.email ?? undefined,
  };
};
