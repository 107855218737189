import { MantineSize, Stack } from "@mantine/core";
import React from "react";

import { GridLayoutItem, OnToggleVisibility } from "../GridLayout";
import { SortableGridItem } from "../SortableGridItem/SortableGridItem";

export interface GridColumnProps {
  weight: number;
  gap: MantineSize;
  items: GridLayoutItem[];
  isEditing: boolean;
  onToggleVisibility: OnToggleVisibility;
}

const GridColumn: React.FC<GridColumnProps> = (props) => {
  const { weight, gap, items, isEditing, onToggleVisibility } = props;

  return (
    <Stack gap={gap} style={{ flex: weight, minWidth: 0 }}>
      {items.map((item) => (
        <SortableGridItem
          id={item.id}
          key={item.id}
          item={item}
          isEditing={isEditing}
          onToggleVisibility={onToggleVisibility}
        />
      ))}
    </Stack>
  );
};

export { GridColumn };
