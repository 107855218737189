/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductScalePrice } from './ProductScalePrice';
import {
    ProductScalePriceFromJSON,
    ProductScalePriceFromJSONTyped,
    ProductScalePriceToJSON,
} from './ProductScalePrice';

/**
 * 
 * @export
 * @interface ProductPrices
 */
export interface ProductPrices {
    /**
     * 
     * @type {string}
     * @memberof ProductPrices
     */
    articleNumber: string;
    /**
     * 
     * @type {number}
     * @memberof ProductPrices
     */
    basePrice: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPrices
     */
    discount: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPrices
     */
    discountedPrice: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPrices
     */
    suggestedRetailPrice: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPrices
     */
    freightSurcharge: number;
    /**
     * 
     * @type {Array<ProductScalePrice>}
     * @memberof ProductPrices
     */
    scalePrices: Array<ProductScalePrice>;
}

/**
 * Check if a given object implements the ProductPrices interface.
 */
export function instanceOfProductPrices(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "articleNumber" in value;
    isInstance = isInstance && "basePrice" in value;
    isInstance = isInstance && "discount" in value;
    isInstance = isInstance && "discountedPrice" in value;
    isInstance = isInstance && "suggestedRetailPrice" in value;
    isInstance = isInstance && "freightSurcharge" in value;
    isInstance = isInstance && "scalePrices" in value;

    return isInstance;
}

export function ProductPricesFromJSON(json: any): ProductPrices {
    return ProductPricesFromJSONTyped(json, false);
}

export function ProductPricesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductPrices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'articleNumber': json['articleNumber'],
        'basePrice': json['basePrice'],
        'discount': json['discount'],
        'discountedPrice': json['discountedPrice'],
        'suggestedRetailPrice': json['suggestedRetailPrice'],
        'freightSurcharge': json['freightSurcharge'],
        'scalePrices': ((json['scalePrices'] as Array<any>).map(ProductScalePriceFromJSON)),
    };
}

export function ProductPricesToJSON(value?: ProductPrices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'articleNumber': value.articleNumber,
        'basePrice': value.basePrice,
        'discount': value.discount,
        'discountedPrice': value.discountedPrice,
        'suggestedRetailPrice': value.suggestedRetailPrice,
        'freightSurcharge': value.freightSurcharge,
        'scalePrices': ((value.scalePrices as Array<any>).map(ProductScalePriceToJSON)),
    };
}

