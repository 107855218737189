/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeoCustomerNumber } from './HeoCustomerNumber';
import {
    HeoCustomerNumberFromJSON,
    HeoCustomerNumberFromJSONTyped,
    HeoCustomerNumberToJSON,
} from './HeoCustomerNumber';
import type { RetailerApiAccess } from './RetailerApiAccess';
import {
    RetailerApiAccessFromJSON,
    RetailerApiAccessFromJSONTyped,
    RetailerApiAccessToJSON,
} from './RetailerApiAccess';

/**
 * 
 * @export
 * @interface ApiAccess
 */
export interface ApiAccess {
    /**
     * 
     * @type {HeoCustomerNumber}
     * @memberof ApiAccess
     */
    customerNumber: HeoCustomerNumber;
    /**
     * 
     * @type {boolean}
     * @memberof ApiAccess
     */
    retailerApiDisabled: boolean;
    /**
     * 
     * @type {RetailerApiAccess}
     * @memberof ApiAccess
     */
    retailerApiAccess?: RetailerApiAccess;
}

/**
 * Check if a given object implements the ApiAccess interface.
 */
export function instanceOfApiAccess(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerNumber" in value;
    isInstance = isInstance && "retailerApiDisabled" in value;

    return isInstance;
}

export function ApiAccessFromJSON(json: any): ApiAccess {
    return ApiAccessFromJSONTyped(json, false);
}

export function ApiAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerNumber': HeoCustomerNumberFromJSON(json['customerNumber']),
        'retailerApiDisabled': json['retailerApiDisabled'],
        'retailerApiAccess': !exists(json, 'retailerApiAccess') ? undefined : RetailerApiAccessFromJSON(json['retailerApiAccess']),
    };
}

export function ApiAccessToJSON(value?: ApiAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerNumber': HeoCustomerNumberToJSON(value.customerNumber),
        'retailerApiDisabled': value.retailerApiDisabled,
        'retailerApiAccess': RetailerApiAccessToJSON(value.retailerApiAccess),
    };
}

