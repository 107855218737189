import {
  CLAIMS_PAGE_PERIOD_QUERY_KEY,
  CLAIMS_PAGE_REASON_QUERY_KEY,
  CLAIMS_PAGE_SEARCH_QUERY_KEY,
  CLAIMS_PAGE_SELECTED_QUERY_KEY,
  CLAIMS_PAGE_STATUS_QUERY_KEY,
} from "~/ui/pages/ProductClaims/ProductClaimsPage/ProductClaimsPageQueryKeys";

import { RoutesEnum } from "../RoutesEnum";
import { createDynamicRoute } from "./util/createDynamicRoute";

const createProductClaimsLink = createDynamicRoute({
  url: RoutesEnum.PRODUCT_CLAIMS,
  params: {
    period: CLAIMS_PAGE_PERIOD_QUERY_KEY,
    search: CLAIMS_PAGE_SEARCH_QUERY_KEY,
    selected: CLAIMS_PAGE_SELECTED_QUERY_KEY,
    status: CLAIMS_PAGE_STATUS_QUERY_KEY,
    reason: CLAIMS_PAGE_REASON_QUERY_KEY,
  },
});

export { createProductClaimsLink };
