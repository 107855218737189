import { Anchor, Image } from "@mantine/core";
import { Trans, useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import { useAppConfig } from "~/config/AppConfig";
import { ErrorLayout } from "~/ui/layout/ErrorLayout/ErrorLayout";
import { Layout } from "~/ui/layout/Layout";
import { useIsAuthorized } from "~/ui/routes/RouteGuards/useIsAuthorized";

import Gandalf from "./assets/gandalf-clean.png";

const NoAccessPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "noAccessPage" });

  const appConfig = useAppConfig();
  const { isAuthorized } = useIsAuthorized();

  if (isAuthorized) {
    return <Navigate to={"/"} />;
  }

  const b2bShopUrl = appConfig.links.shop;

  const supportMailTo = `mailto: ${appConfig.mail.customerSupport}?subject=${t("mailSubject")}`;

  return (
    <Layout hideNavbarLinks hideUserMenuLinks hideFeedbackButton>
      <ErrorLayout
        title={t("title")}
        subtitle={
          <Trans t={t} i18nKey={"subtitle"}>
            <Anchor href={supportMailTo} />
          </Trans>
        }
        buttonHref={b2bShopUrl}
        buttonLabel={t("button")}
        image={<Image src={Gandalf} h={"100%"} mah={900} w={"auto"} />}
      />
    </Layout>
  );
};

export { NoAccessPage };
