/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KpiOverviewData
 */
export interface KpiOverviewData {
    /**
     * 
     * @type {number}
     * @memberof KpiOverviewData
     */
    customerOrderCount: number;
    /**
     * 
     * @type {number}
     * @memberof KpiOverviewData
     */
    salesOrderItemAvailabilityCount: number;
    /**
     * 
     * @type {number}
     * @memberof KpiOverviewData
     */
    invoiceCount: number;
    /**
     * 
     * @type {number}
     * @memberof KpiOverviewData
     */
    creditNotesCount: number;
}

/**
 * Check if a given object implements the KpiOverviewData interface.
 */
export function instanceOfKpiOverviewData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerOrderCount" in value;
    isInstance = isInstance && "salesOrderItemAvailabilityCount" in value;
    isInstance = isInstance && "invoiceCount" in value;
    isInstance = isInstance && "creditNotesCount" in value;

    return isInstance;
}

export function KpiOverviewDataFromJSON(json: any): KpiOverviewData {
    return KpiOverviewDataFromJSONTyped(json, false);
}

export function KpiOverviewDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): KpiOverviewData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerOrderCount': json['customerOrderCount'],
        'salesOrderItemAvailabilityCount': json['salesOrderItemAvailabilityCount'],
        'invoiceCount': json['invoiceCount'],
        'creditNotesCount': json['creditNotesCount'],
    };
}

export function KpiOverviewDataToJSON(value?: KpiOverviewData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerOrderCount': value.customerOrderCount,
        'salesOrderItemAvailabilityCount': value.salesOrderItemAvailabilityCount,
        'invoiceCount': value.invoiceCount,
        'creditNotesCount': value.creditNotesCount,
    };
}

