// Needs to be imported first, don't change order
import "./sentry/Sentry";

import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./ui/App";
import "./index.css";

// biome-ignore lint/style/noNonNullAssertion: <explanation>
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
