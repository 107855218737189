import { TotalSales, TotalSalesReport, TotalSalesSummary } from "~/api/openapi";

import { TotalSalesReportItem, TotalSalesView } from "./interface/TotalSalesReportItem";

const mapTotalSalesBEToTotalSalesView = (
  totalSales: TotalSales | TotalSalesSummary,
): TotalSalesView => ({
  currency: "EUR",
  customerOrders: totalSales.ordersAmount,
  invoices: totalSales.invoiceAmount,
  creditNotes: totalSales.creditNoteAmount,
  cancelledInvoices: totalSales.canceledInvoiceAmount,
  cancelledCustomerOrders: totalSales.canceledOrdersAmount,
  cancellationRate: totalSales.quota,
});
export const mapTotalSalesReportResponseToTotalSalesReport = (
  query: TotalSalesReport | undefined,
): TotalSalesReportItem | undefined => {
  if (!query || query.years.length === 0) {
    return undefined;
  }

  const annualSalesMap = new Map<number, TotalSalesView>();

  for (const item of query.years) {
    annualSalesMap.set(item.year, mapTotalSalesBEToTotalSalesView(item));
  }

  let minYear = new Date().getFullYear();
  let maxYear = new Date().getFullYear();
  if (query.years.length > 0) {
    minYear = query.years.map((item) => item.year).reduce((a, b) => Math.min(a, b));
    maxYear = query.years.map((item) => item.year).reduce((a, b) => Math.max(a, b));
  }

  return {
    totalSales: mapTotalSalesBEToTotalSalesView(query.total),
    annualSales: annualSalesMap,
    minYear,
    maxYear,
  };
};
