/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeoTransactionNumber } from './HeoTransactionNumber';
import {
    HeoTransactionNumberFromJSON,
    HeoTransactionNumberFromJSONTyped,
    HeoTransactionNumberToJSON,
} from './HeoTransactionNumber';
import type { ProductClaimProcessingStatus } from './ProductClaimProcessingStatus';
import {
    ProductClaimProcessingStatusFromJSON,
    ProductClaimProcessingStatusFromJSONTyped,
    ProductClaimProcessingStatusToJSON,
} from './ProductClaimProcessingStatus';
import type { ProductClaimReason } from './ProductClaimReason';
import {
    ProductClaimReasonFromJSON,
    ProductClaimReasonFromJSONTyped,
    ProductClaimReasonToJSON,
} from './ProductClaimReason';

/**
 * 
 * @export
 * @interface ProductClaimSummary
 */
export interface ProductClaimSummary {
    /**
     * 
     * @type {string}
     * @memberof ProductClaimSummary
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ProductClaimSummary
     */
    number?: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductClaimSummary
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductClaimSummary
     */
    productNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ProductClaimSummary
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof ProductClaimSummary
     */
    productThumbnailUrl: string;
    /**
     * 
     * @type {HeoTransactionNumber}
     * @memberof ProductClaimSummary
     */
    invoiceNumber: HeoTransactionNumber;
    /**
     * 
     * @type {Date}
     * @memberof ProductClaimSummary
     */
    invoiceCreatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof ProductClaimSummary
     */
    creditNoteNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductClaimSummary
     */
    rmaNumber?: number;
    /**
     * 
     * @type {ProductClaimProcessingStatus}
     * @memberof ProductClaimSummary
     */
    processingStatus: ProductClaimProcessingStatus;
    /**
     * 
     * @type {ProductClaimReason}
     * @memberof ProductClaimSummary
     */
    reason: ProductClaimReason;
}

/**
 * Check if a given object implements the ProductClaimSummary interface.
 */
export function instanceOfProductClaimSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "productNumber" in value;
    isInstance = isInstance && "productName" in value;
    isInstance = isInstance && "productThumbnailUrl" in value;
    isInstance = isInstance && "invoiceNumber" in value;
    isInstance = isInstance && "invoiceCreatedAt" in value;
    isInstance = isInstance && "processingStatus" in value;
    isInstance = isInstance && "reason" in value;

    return isInstance;
}

export function ProductClaimSummaryFromJSON(json: any): ProductClaimSummary {
    return ProductClaimSummaryFromJSONTyped(json, false);
}

export function ProductClaimSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductClaimSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'createdAt': (new Date(json['createdAt'])),
        'productNumber': json['productNumber'],
        'productName': json['productName'],
        'productThumbnailUrl': json['productThumbnailUrl'],
        'invoiceNumber': HeoTransactionNumberFromJSON(json['invoiceNumber']),
        'invoiceCreatedAt': (new Date(json['invoiceCreatedAt'])),
        'creditNoteNumber': !exists(json, 'creditNoteNumber') ? undefined : json['creditNoteNumber'],
        'rmaNumber': !exists(json, 'rmaNumber') ? undefined : json['rmaNumber'],
        'processingStatus': ProductClaimProcessingStatusFromJSON(json['processingStatus']),
        'reason': ProductClaimReasonFromJSON(json['reason']),
    };
}

export function ProductClaimSummaryToJSON(value?: ProductClaimSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'createdAt': (value.createdAt.toISOString()),
        'productNumber': value.productNumber,
        'productName': value.productName,
        'productThumbnailUrl': value.productThumbnailUrl,
        'invoiceNumber': HeoTransactionNumberToJSON(value.invoiceNumber),
        'invoiceCreatedAt': (value.invoiceCreatedAt.toISOString()),
        'creditNoteNumber': value.creditNoteNumber,
        'rmaNumber': value.rmaNumber,
        'processingStatus': ProductClaimProcessingStatusToJSON(value.processingStatus),
        'reason': ProductClaimReasonToJSON(value.reason),
    };
}

