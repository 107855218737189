import { SecurityLevel } from "~/ui/pages/AccountPage/AccountCredentials/PasswortStrengthChecker";

import { AccountPageDE } from "../de/de.accountPage";

export const AccountPageEN: typeof AccountPageDE = {
  title: "My account",
  error: "An error has occurred. Your profile details could not be loaded. Please try again later.",
  userNameLabel: "User name",
  loginData: {
    title: "Login data",
    emailLabel: "Email",
    changeEmailLink: "Edit email address",
    passwordLabel: "Password",
    changePasswordLink: "Edit",
    changePasswordModal: {
      title: "Change password",
      currentPasswordPlaceholder: "Old password",
      newPasswordPlaceholder: "New password",
      newPasswordConfirmPlaceholder: "Repeat new password",
      submitButtonLabel: "Change password",
      editTitle: "Change password",
      newPasswordsDontMatch: "The passwords do not match",
      wrongPasswordError: "The old password is not correct",
      successMessage: "Password changed successfully",
    },
    passwordMeter: {
      passwordStrength: "Password strength: ",
      placeholder: "Please enter password",
      securityLevel: {
        weak: "Weak",
        medium: "Medium",
        strong: "Strong",
      } as Record<SecurityLevel, string>,
    },
  },
  contactInfo: {
    title: "Contact information",
    firstnameLabel: "First name",
    surnameLabel: "Last name",
    phoneLabel: "Phone",
    mobileLabel: "Mobile",
    editTitle: "Change contact information",
    editButtonLabel: "Change contact information",
    abortButtonLabel: "Cancel",
    cancelButtonLabel: "Back",
    saveButtonLabel: "Save",
    validation: {
      firstNameRequired: "First name is required",
      lastNameRequired: "Last name is required",
      phoneRequired: "Phone is required",
    },
    changeButtonLabel: "Edit",
  },
  contactPermissions: {
    title: "Contact authorisations",
    description: "I authorise heo to contact me via the following channels:",
    switchEmailLabel: "Email",
    switchPhoneLabel: "Phone",
    switchWhatsappLabel: "Whatsapp",
    switchSmsLabel: "SMS",
    errorMessage: "The change could not be saved. Please try again later.",
  },

  accountDeletion: {
    buttonLabel: "Request closure of the customer account",
    deletionHint:
      "If you want to close your customer account irrevocably, please request this here. Existing purchase agreements, open invoices or credit notes remain unchanged.",
    modalConfirmDescription:
      "By deleting your account, you will lose access to your heo account for the heo store and the heo Business Console.",
    modalConfirmText: "Do you really want to delete your customer account?",
    modalConfirmButtonLabel: "Yes, request deletion",
    modalCancelButtonLabel: "Cancel",
    successMessage: "Request for deletion of the customer account was successfully submitted.",
    errorMessage:
      "An error has occurred. The request to delete the customer account could not be submitted. Please try again later.",
  },
};
