import {
  ActionIcon,
  Box,
  Button,
  Collapse,
  Container,
  CopyButton,
  Divider,
  Group,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconCheck,
  IconChevronDown,
  IconChevronUp,
  IconCopy,
  IconReload,
} from "@tabler/icons-react";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./ErrorPage.module.css";

interface ErrorDescription {
  description: string | undefined;
  measures: string | undefined;
}

export interface ErrorPageProps {
  error: unknown;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ error }) => {
  const { t } = useTranslation();

  const [isOpen, handlers] = useDisclosure(false);

  const handleReload = () => {
    window.location.reload();
  };

  const getErrorDescription = (error: unknown): ErrorDescription | undefined => {
    if (!(error instanceof Error)) {
      return undefined;
    }

    if (error.message.includes("Failed to fetch dynamically imported module")) {
      return {
        description: t("errors.dynamicLoadError"),
        measures: t("errors.dynamicLoadErrorMeasure"),
      };
    }

    return undefined;
  };

  const errorTitle = t("errors.runtimeErrorTitle");
  const errorDescription: ErrorDescription | undefined = getErrorDescription(error);

  return (
    <Box className={styles.container}>
      <Container pt={80} size={"xs"}>
        <Paper className={styles.card} shadow={"lg"} p={"lg"} withBorder>
          <Stack>
            <Title order={3} c="red">
              {errorTitle}
            </Title>

            {errorDescription && (
              <Box>
                <Text>{errorDescription.description}</Text>
                <Text>{errorDescription.measures}</Text>
              </Box>
            )}

            <Divider />

            <Group justify="space-between">
              <Button leftSection={<IconReload size={16} />} onClick={handleReload}>
                {t("errors.runtimeErrorReloadLabel")}
              </Button>

              <Button
                onClick={handlers.toggle}
                rightSection={isOpen ? <IconChevronUp size={18} /> : <IconChevronDown size={18} />}
                variant="subtle"
              >
                {isOpen ? t("errors.runtimeErrorHideDetails") : t("errors.runtimeErrorShowDetails")}
              </Button>
            </Group>

            {error instanceof Error && (
              <Collapse in={isOpen}>
                <Box className={styles.details}>
                  <Group justify="space-between" wrap="nowrap">
                    <Text c={"red"} fw="bold">
                      {error.message}
                    </Text>

                    <CopyButton
                      value={`${error.name}\n${error.message}\n${error.stack}`}
                      timeout={3000}
                    >
                      {({ copied, copy }) => (
                        <ActionIcon onClick={copy} color={copied ? "green" : undefined}>
                          {copied ? <IconCheck /> : <IconCopy />}
                        </ActionIcon>
                      )}
                    </CopyButton>
                  </Group>

                  {error.stack && <pre className={styles.trace}>{error.stack}</pre>}
                </Box>
              </Collapse>
            )}
          </Stack>
        </Paper>
      </Container>
    </Box>
  );
};

export { ErrorPage };
