import { Button } from "@mantine/core";
import { addMonths, endOfMonth, startOfMonth } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { WidgetContainer } from "../../WidgetContainer/WidgetContainer";

import { ResponsiveButtonGroup } from "~/ui/components/ResponsiveButtonGroup/ResponsiveButtonGroup";

import { AvailabilityPeriodGroupCarousel } from "./AvailabilityPeriodGroupCarousel";
import { useSalesOrderItemAvailabilitiesGroupByMonthQuery } from "./api/useSalesOrderItemAvailabilitiesGroupByMonthQuery";
import { getItemAvailabilityGroupedByPeriod } from "./getItemAvailabilityGroupedByPeriod";
import { useAvailabilityPeriodGroupCarousel } from "./hooks/useAvailabilityPeriodGroupCarousel";
const MONTH_TO_GET_DATA_FROM = 8;

const ExpectedAvailabilityWidget = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "dashboardPage.ExpectedAvailabilityWidget",
  });

  const availabilityPeriod = useMemo(() => {
    const availabilityBetweenFrom = startOfMonth(new Date());
    const availabilityBetweenUntil = endOfMonth(addMonths(new Date(), MONTH_TO_GET_DATA_FROM));

    return {
      availabilityBetweenFrom,
      availabilityBetweenUntil,
    };
  }, []);

  const { data: salesOrderItem, isLoading: salesOrderItemsLoading } =
    useSalesOrderItemAvailabilitiesGroupByMonthQuery({ ...availabilityPeriod });

  const getSubTitleText = (): string => {
    return t("subtitle.item", {
      count: salesOrderItem?.countAllArticle ? salesOrderItem?.countAllArticle : 0,
    });
  };

  const itemAvailabilityGroupByMonth = salesOrderItem?.itemAvailabilityGroupByMonth ?? [];

  const itemAvailabilityGroupedByPeriod = useMemo(() => {
    return getItemAvailabilityGroupedByPeriod(itemAvailabilityGroupByMonth);
  }, [itemAvailabilityGroupByMonth]);

  const {
    setEmbla,
    canScollNext,
    canScollPrevious,
    firstPeriodToShow,
    handleSelectNextPeriod,
    handleSelectPreviousPeriod,
  } = useAvailabilityPeriodGroupCarousel();

  return (
    <WidgetContainer
      isLoading={salesOrderItemsLoading}
      title={t("title")}
      subtitle={getSubTitleText()}
    >
      <AvailabilityPeriodGroupCarousel
        salesOrderItemsLoading={salesOrderItemsLoading}
        itemAvailabilityGroupedByPeriod={itemAvailabilityGroupedByPeriod}
        firstPeriodToShow={firstPeriodToShow}
        setEmbla={setEmbla}
      />

      <ResponsiveButtonGroup pt={"md"}>
        <Button disabled={!canScollPrevious} onClick={handleSelectPreviousPeriod} variant="outline">
          {t("showPreviousPeriod")}
        </Button>
        <Button disabled={!canScollNext} onClick={handleSelectNextPeriod} variant="outline">
          {t("showNextPeriod")}
        </Button>
      </ResponsiveButtonGroup>
    </WidgetContainer>
  );
};

export { ExpectedAvailabilityWidget };
