export const InvoiceOpen = ({ size = 16, color = "currentColor", ...restProps }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>InvoiceOpen</title>
      <path
        d="M11.4237 8.52657C8.79632 8.52657 6.66394 10.6486 6.66394 13.2633C6.66394 15.878 8.79632 18 11.4237 18C14.0511 18 16.1835 15.878 16.1835 13.2633C16.1835 10.6486 14.0511 8.52657 11.4237 8.52657ZM12.9119 15.5721L10.8652 13.4528V10.4213H11.8997V13.0643L13.6608 14.8169L12.9119 15.5721Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.90391 0H11.4235C12.4706 0 13.3274 0.852609 13.3274 1.89469V7.9027C12.7316 7.69286 12.0905 7.57866 11.4228 7.57866C11.0987 7.57866 10.7809 7.60556 10.4715 7.65726V6.6314H2.85586V8.52609H8.26566C7.53251 9.01122 6.91826 9.66037 6.47596 10.4208H2.85586V12.3155H5.79018C5.73818 12.6235 5.71111 12.94 5.71111 13.2627C5.71111 14.2978 5.98961 15.2686 6.47606 16.1048H1.90391C0.856759 16.1048 0 15.2522 0 14.2101V1.89469C0 0.852609 0.856759 0 1.90391 0ZM2.85586 4.73672H10.4715V2.84203H2.85586V4.73672Z"
        fill={color}
      />
    </svg>
  );
};
