import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store/store";

export type PageSettingsDict<Value> = Record<string, Value>;

export interface PageSettingsState {
  settings: {
    [pageKey: string]: PageSettingsDict<unknown>;
  };
}

type SetPageSettingsPayload = {
  pageKey: string;
  settings: PageSettingsDict<unknown>;
};

type UpdatePageSettingsPayload = {
  pageKey: string;
  settings: Partial<PageSettingsDict<unknown>>;
};

export const initialPageSettingsState: PageSettingsState = {
  settings: {},
};

const PageSettingsSlice = createSlice({
  name: "pageSettings",
  initialState: initialPageSettingsState,
  reducers: {
    setPageSettings: (state, action: PayloadAction<SetPageSettingsPayload>) => {
      state.settings[action.payload.pageKey] = action.payload.settings;
    },

    updatePageSettings: (state, action: PayloadAction<UpdatePageSettingsPayload>) => {
      if (!state.settings[action.payload.pageKey]) state.settings[action.payload.pageKey] = {};

      for (const key of Object.keys(action.payload.settings)) {
        state.settings[action.payload.pageKey][key] = action.payload.settings[key];
      }
    },
  },
});

export const selectPageSettingsByPageKey = createSelector(
  (state: RootState) => state.pageSettings.settings,
  (_: RootState, pageKey: string) => pageKey,
  (settings: PageSettingsDict<unknown>, pageKey: string) => {
    return settings[pageKey];
  },
);

export const PageSettingsSliceActions = PageSettingsSlice.actions;
export const PageSettingsSliceReducer = PageSettingsSlice.reducer;
