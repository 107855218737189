/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChangeRequestAddressRequest } from './ChangeRequestAddressRequest';
import {
    ChangeRequestAddressRequestFromJSON,
    ChangeRequestAddressRequestFromJSONTyped,
    ChangeRequestAddressRequestToJSON,
} from './ChangeRequestAddressRequest';
import type { ChangeRequestContactRequest } from './ChangeRequestContactRequest';
import {
    ChangeRequestContactRequestFromJSON,
    ChangeRequestContactRequestFromJSONTyped,
    ChangeRequestContactRequestToJSON,
} from './ChangeRequestContactRequest';
import type { ChangeRequestOpeningHourRequest } from './ChangeRequestOpeningHourRequest';
import {
    ChangeRequestOpeningHourRequestFromJSON,
    ChangeRequestOpeningHourRequestFromJSONTyped,
    ChangeRequestOpeningHourRequestToJSON,
} from './ChangeRequestOpeningHourRequest';
import type { StoreProfileImagesRequest } from './StoreProfileImagesRequest';
import {
    StoreProfileImagesRequestFromJSON,
    StoreProfileImagesRequestFromJSONTyped,
    StoreProfileImagesRequestToJSON,
} from './StoreProfileImagesRequest';

/**
 * 
 * @export
 * @interface ChangeRequestAndDraftRequest
 */
export interface ChangeRequestAndDraftRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestAndDraftRequest
     */
    type: ChangeRequestAndDraftRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestAndDraftRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestAndDraftRequest
     */
    openingDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestAndDraftRequest
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeRequestAndDraftRequest
     */
    wpnStore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeRequestAndDraftRequest
     */
    otsStore?: boolean;
    /**
     * 
     * @type {ChangeRequestAddressRequest}
     * @memberof ChangeRequestAndDraftRequest
     */
    address?: ChangeRequestAddressRequest;
    /**
     * 
     * @type {ChangeRequestContactRequest}
     * @memberof ChangeRequestAndDraftRequest
     */
    contact?: ChangeRequestContactRequest;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangeRequestAndDraftRequest
     */
    categories?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangeRequestAndDraftRequest
     */
    accessibilities?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangeRequestAndDraftRequest
     */
    equipments?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangeRequestAndDraftRequest
     */
    paymentMethods?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChangeRequestAndDraftRequest
     */
    activities?: Array<string>;
    /**
     * 
     * @type {ChangeRequestOpeningHourRequest}
     * @memberof ChangeRequestAndDraftRequest
     */
    openingHours?: ChangeRequestOpeningHourRequest;
    /**
     * 
     * @type {StoreProfileImagesRequest}
     * @memberof ChangeRequestAndDraftRequest
     */
    images?: StoreProfileImagesRequest;
}


/**
 * @export
 */
export const ChangeRequestAndDraftRequestTypeEnum = {
    Online: 'ONLINE',
    Local: 'LOCAL'
} as const;
export type ChangeRequestAndDraftRequestTypeEnum = typeof ChangeRequestAndDraftRequestTypeEnum[keyof typeof ChangeRequestAndDraftRequestTypeEnum];


/**
 * Check if a given object implements the ChangeRequestAndDraftRequest interface.
 */
export function instanceOfChangeRequestAndDraftRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ChangeRequestAndDraftRequestFromJSON(json: any): ChangeRequestAndDraftRequest {
    return ChangeRequestAndDraftRequestFromJSONTyped(json, false);
}

export function ChangeRequestAndDraftRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestAndDraftRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'openingDate': !exists(json, 'openingDate') ? undefined : json['openingDate'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'wpnStore': !exists(json, 'wpnStore') ? undefined : json['wpnStore'],
        'otsStore': !exists(json, 'otsStore') ? undefined : json['otsStore'],
        'address': !exists(json, 'address') ? undefined : ChangeRequestAddressRequestFromJSON(json['address']),
        'contact': !exists(json, 'contact') ? undefined : ChangeRequestContactRequestFromJSON(json['contact']),
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'accessibilities': !exists(json, 'accessibilities') ? undefined : json['accessibilities'],
        'equipments': !exists(json, 'equipments') ? undefined : json['equipments'],
        'paymentMethods': !exists(json, 'paymentMethods') ? undefined : json['paymentMethods'],
        'activities': !exists(json, 'activities') ? undefined : json['activities'],
        'openingHours': !exists(json, 'openingHours') ? undefined : ChangeRequestOpeningHourRequestFromJSON(json['openingHours']),
        'images': !exists(json, 'images') ? undefined : StoreProfileImagesRequestFromJSON(json['images']),
    };
}

export function ChangeRequestAndDraftRequestToJSON(value?: ChangeRequestAndDraftRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'name': value.name,
        'openingDate': value.openingDate,
        'description': value.description,
        'wpnStore': value.wpnStore,
        'otsStore': value.otsStore,
        'address': ChangeRequestAddressRequestToJSON(value.address),
        'contact': ChangeRequestContactRequestToJSON(value.contact),
        'categories': value.categories,
        'accessibilities': value.accessibilities,
        'equipments': value.equipments,
        'paymentMethods': value.paymentMethods,
        'activities': value.activities,
        'openingHours': ChangeRequestOpeningHourRequestToJSON(value.openingHours),
        'images': StoreProfileImagesRequestToJSON(value.images),
    };
}

