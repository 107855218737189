import { Box, Group, Table, Text, Tooltip } from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "~/helper/Formatter/formatCurrency";
import { CancellationRateStatus } from "../CancellationRateStatus";
import { getCancellationRateStatus } from "../getCancellationRateStatus/getCancellationRateStatus";
import { TotalSalesReportItem } from "../interface/TotalSalesReportItem";
import { CancellationRateInfoBadge } from "./CancellationRateInfoBadge/CancellationRateInfoBadge";
import { CancellationRateInformationenPopover } from "./CancellationRateInformationenPopover/CancellationRateInformationenPopover";
import styles from "./TotalSalesReportTable.module.css";
import { YearSelector } from "./YearSelector";

type TotalSalesReportTableProps = {
  salesOrderItem: TotalSalesReportItem;
  onHandleUpdateCancellationRateStatus: (
    cancellationRateStatus: CancellationRateStatus | undefined,
  ) => void;
};

const TotalSalesReportTable: React.FC<TotalSalesReportTableProps> = (props) => {
  const { salesOrderItem, onHandleUpdateCancellationRateStatus } = props;
  const { annualSales, maxYear, minYear, totalSales } = salesOrderItem;
  const { t } = useTranslation(undefined, {
    keyPrefix: "dashboardPage.totalSalesReportWidget.table",
  });

  const [selectedYear, setSelectedYear] = useState<number>(maxYear);
  const handleSelectPreviousYear = () => {
    setSelectedYear((prev) => prev - 1);
  };

  const handleSelectNextYear = () => {
    setSelectedYear((prev) => prev + 1);
  };

  const selectedYearData = annualSales.get(selectedYear);

  useEffect(() => {
    if (selectedYearData?.cancellationRate) {
      onHandleUpdateCancellationRateStatus(
        getCancellationRateStatus(selectedYearData.cancellationRate),
      );
    }
  }, [selectedYearData, onHandleUpdateCancellationRateStatus]);

  const { width, ref } = useElementSize();
  const tableClasses = clsx(width < 410 && styles.compact);

  return (
    <Box ref={ref}>
      <Table striped highlightOnHover withRowBorders={false} className={tableClasses}>
        <Table.Thead>
          <Table.Tr fz={"md"}>
            <Table.Th style={{ minWidth: "150px" }} />

            <Table.Th className={styles.cell}>
              <YearSelector
                handlePrevious={handleSelectPreviousYear}
                handleNext={handleSelectNextYear}
                borders={{ min: minYear, max: maxYear }}
                selectedValue={selectedYear}
              />
            </Table.Th>

            <Table.Th style={{ width: "180px" }} className={styles.cell}>
              {t("totalLabel")}
            </Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          <Table.Tr fz={"md"}>
            <Table.Td>
              <Tooltip label={t("ordersLabel")}>
                <Text>{t("ordersLabel")}</Text>
              </Tooltip>
            </Table.Td>

            <Table.Td className={styles.cell}>
              <Text>
                {formatCurrency(selectedYearData?.customerOrders, {
                  currency: selectedYearData?.currency,
                })}
              </Text>
            </Table.Td>

            <Table.Td className={styles.cell}>
              <Text>
                {formatCurrency(totalSales.customerOrders, { currency: totalSales?.currency })}
              </Text>
            </Table.Td>
          </Table.Tr>

          <Table.Tr fz={"md"}>
            <Table.Td>
              <Tooltip label={t("invoicesLabel")}>
                <Text>{t("invoicesLabel")}</Text>
              </Tooltip>
            </Table.Td>
            <Table.Td className={styles.cell}>
              <Text>
                {formatCurrency(selectedYearData?.invoices, {
                  currency: selectedYearData?.currency,
                })}
              </Text>
            </Table.Td>
            <Table.Td className={styles.cell}>
              <Text>{formatCurrency(totalSales.invoices, { currency: totalSales?.currency })}</Text>
            </Table.Td>
          </Table.Tr>
          <Table.Tr fz={"md"}>
            <Table.Td>
              <Tooltip label={t("creditsLabel")}>
                <Text>{t("creditsLabel")}</Text>
              </Tooltip>
            </Table.Td>
            <Table.Td className={styles.cell}>
              <Text>
                {formatCurrency(selectedYearData?.creditNotes, {
                  currency: selectedYearData?.currency,
                })}
              </Text>
            </Table.Td>
            <Table.Td className={styles.cell}>
              <Text>
                {formatCurrency(totalSales.creditNotes, { currency: totalSales?.currency })}
              </Text>
            </Table.Td>
          </Table.Tr>
          <Table.Tr fz={"md"}>
            <Table.Td>
              <Tooltip label={t("cancledOrdersLabel")}>
                <Text>{t("cancledOrdersLabel")}</Text>
              </Tooltip>
            </Table.Td>
            <Table.Td className={styles.cell}>
              <Text>
                {formatCurrency(selectedYearData?.cancelledCustomerOrders, {
                  currency: selectedYearData?.currency,
                })}
              </Text>
            </Table.Td>
            <Table.Td className={styles.cell}>
              <Text>
                {formatCurrency(totalSales.cancelledCustomerOrders, {
                  currency: totalSales?.currency,
                })}
              </Text>
            </Table.Td>
          </Table.Tr>
          <Table.Tr fz={"md"}>
            <Table.Td>
              <Tooltip label={t("cancledInvoicesLabel")}>
                <Text>{t("cancledInvoicesLabel")}</Text>
              </Tooltip>
            </Table.Td>
            <Table.Td className={styles.cell}>
              <Text>
                {formatCurrency(selectedYearData?.cancelledInvoices, {
                  currency: selectedYearData?.currency,
                })}
              </Text>
            </Table.Td>
            <Table.Td className={styles.cell}>
              <Text>
                {formatCurrency(totalSales.cancelledInvoices, { currency: totalSales?.currency })}
              </Text>
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>

        <Table.Tfoot classNames={{ tfoot: styles.tableFooter }}>
          <Table.Tr fz={"md"}>
            <Table.Td>
              <Group gap={"xs"}>
                {t("cancellationRateLabel")} <CancellationRateInformationenPopover />
              </Group>
            </Table.Td>
            <Table.Td className={styles.cell}>
              <CancellationRateInfoBadge cancellationRate={selectedYearData?.cancellationRate} />
            </Table.Td>
            <Table.Td className={styles.cell}>
              <Text>
                {t("cancellationRate", { cancellationRate: totalSales.cancellationRate })}
              </Text>
            </Table.Td>
          </Table.Tr>
        </Table.Tfoot>
      </Table>
    </Box>
  );
};

export { TotalSalesReportTable };
