import React from "react";
import { useRouteError } from "react-router-dom";

import { ErrorPage } from "./ErrorPage";

const importErrors = ["Failed to fetch dynamically imported module", "Unable to preload CSS"];

const RouteErrorPage: React.FC = () => {
  const routeError = useRouteError();

  if (routeError instanceof Error) {
    if (importErrors.some((e) => routeError.message.includes(e))) {
      window.location.reload();
    }
  }

  return <ErrorPage error={routeError} />;
};

export { RouteErrorPage };
