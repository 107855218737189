/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreProfileAddress } from './StoreProfileAddress';
import {
    StoreProfileAddressFromJSON,
    StoreProfileAddressFromJSONTyped,
    StoreProfileAddressToJSON,
} from './StoreProfileAddress';
import type { StoreProfileContact } from './StoreProfileContact';
import {
    StoreProfileContactFromJSON,
    StoreProfileContactFromJSONTyped,
    StoreProfileContactToJSON,
} from './StoreProfileContact';
import type { StoreProfileImageMetaData } from './StoreProfileImageMetaData';
import {
    StoreProfileImageMetaDataFromJSON,
    StoreProfileImageMetaDataFromJSONTyped,
    StoreProfileImageMetaDataToJSON,
} from './StoreProfileImageMetaData';

/**
 * 
 * @export
 * @interface StoreOverview
 */
export interface StoreOverview {
    /**
     * 
     * @type {string}
     * @memberof StoreOverview
     */
    type: StoreOverviewTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof StoreOverview
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof StoreOverview
     */
    openingDate?: Date;
    /**
     * 
     * @type {StoreProfileAddress}
     * @memberof StoreOverview
     */
    address?: StoreProfileAddress;
    /**
     * 
     * @type {StoreProfileContact}
     * @memberof StoreOverview
     */
    contact: StoreProfileContact;
    /**
     * 
     * @type {StoreProfileImageMetaData}
     * @memberof StoreOverview
     */
    headerImage?: StoreProfileImageMetaData;
}


/**
 * @export
 */
export const StoreOverviewTypeEnum = {
    Online: 'ONLINE',
    Local: 'LOCAL'
} as const;
export type StoreOverviewTypeEnum = typeof StoreOverviewTypeEnum[keyof typeof StoreOverviewTypeEnum];


/**
 * Check if a given object implements the StoreOverview interface.
 */
export function instanceOfStoreOverview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "contact" in value;

    return isInstance;
}

export function StoreOverviewFromJSON(json: any): StoreOverview {
    return StoreOverviewFromJSONTyped(json, false);
}

export function StoreOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'name': json['name'],
        'openingDate': !exists(json, 'openingDate') ? undefined : (new Date(json['openingDate'])),
        'address': !exists(json, 'address') ? undefined : StoreProfileAddressFromJSON(json['address']),
        'contact': StoreProfileContactFromJSON(json['contact']),
        'headerImage': !exists(json, 'headerImage') ? undefined : StoreProfileImageMetaDataFromJSON(json['headerImage']),
    };
}

export function StoreOverviewToJSON(value?: StoreOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'name': value.name,
        'openingDate': value.openingDate === undefined ? undefined : (value.openingDate.toISOString()),
        'address': StoreProfileAddressToJSON(value.address),
        'contact': StoreProfileContactToJSON(value.contact),
        'headerImage': StoreProfileImageMetaDataToJSON(value.headerImage),
    };
}

