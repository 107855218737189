/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangeRequestCreateOrUpdateRequest,
  ChangeRequestDetails,
  MiddlewareError,
  SetChangeRequestVisibilityAfterApprovalRequest,
} from '../models';
import {
    ChangeRequestCreateOrUpdateRequestFromJSON,
    ChangeRequestCreateOrUpdateRequestToJSON,
    ChangeRequestDetailsFromJSON,
    ChangeRequestDetailsToJSON,
    MiddlewareErrorFromJSON,
    MiddlewareErrorToJSON,
    SetChangeRequestVisibilityAfterApprovalRequestFromJSON,
    SetChangeRequestVisibilityAfterApprovalRequestToJSON,
} from '../models';

export interface CreateOrUpdateChangeRequestOfCurrentRetailerRequest {
    changeRequestCreateOrUpdateRequest: ChangeRequestCreateOrUpdateRequest;
}

export interface DeleteStoreProfileChangeRequestOfCurrentRetailerRequest {
    uuid: string;
}

export interface GetStoreProfileChangeRequestDetailsOfCurrentRetailerRequest {
    uuid: string;
}

export interface SetVisibilityOfChangeAfterApprovalRequestRequest {
    uuid: string;
    setChangeRequestVisibilityAfterApprovalRequest: SetChangeRequestVisibilityAfterApprovalRequest;
}

/**
 * 
 */
export class StoreProfileChangeRequestResourceApi extends runtime.BaseAPI {

    /**
     */
    async createOrUpdateChangeRequestOfCurrentRetailerRaw(requestParameters: CreateOrUpdateChangeRequestOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.changeRequestCreateOrUpdateRequest === null || requestParameters.changeRequestCreateOrUpdateRequest === undefined) {
            throw new runtime.RequiredError('changeRequestCreateOrUpdateRequest','Required parameter requestParameters.changeRequestCreateOrUpdateRequest was null or undefined when calling createOrUpdateChangeRequestOfCurrentRetailer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/store-profile/updatechangerequest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeRequestCreateOrUpdateRequestToJSON(requestParameters.changeRequestCreateOrUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createOrUpdateChangeRequestOfCurrentRetailer(requestParameters: CreateOrUpdateChangeRequestOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createOrUpdateChangeRequestOfCurrentRetailerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteStoreProfileChangeRequestOfCurrentRetailerRaw(requestParameters: DeleteStoreProfileChangeRequestOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling deleteStoreProfileChangeRequestOfCurrentRetailer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/store-profile/changerequest/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteStoreProfileChangeRequestOfCurrentRetailer(requestParameters: DeleteStoreProfileChangeRequestOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStoreProfileChangeRequestOfCurrentRetailerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getStoreProfileChangeRequestDetailsOfCurrentRetailerRaw(requestParameters: GetStoreProfileChangeRequestDetailsOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ChangeRequestDetails>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling getStoreProfileChangeRequestDetailsOfCurrentRetailer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/store-profile/changerequest/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChangeRequestDetailsFromJSON(jsonValue));
    }

    /**
     */
    async getStoreProfileChangeRequestDetailsOfCurrentRetailer(requestParameters: GetStoreProfileChangeRequestDetailsOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ChangeRequestDetails> {
        const response = await this.getStoreProfileChangeRequestDetailsOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async setVisibilityOfChangeAfterApprovalRequestRaw(requestParameters: SetVisibilityOfChangeAfterApprovalRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling setVisibilityOfChangeAfterApprovalRequest.');
        }

        if (requestParameters.setChangeRequestVisibilityAfterApprovalRequest === null || requestParameters.setChangeRequestVisibilityAfterApprovalRequest === undefined) {
            throw new runtime.RequiredError('setChangeRequestVisibilityAfterApprovalRequest','Required parameter requestParameters.setChangeRequestVisibilityAfterApprovalRequest was null or undefined when calling setVisibilityOfChangeAfterApprovalRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/store-profile/changerequest/{uuid}/visibility`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetChangeRequestVisibilityAfterApprovalRequestToJSON(requestParameters.setChangeRequestVisibilityAfterApprovalRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setVisibilityOfChangeAfterApprovalRequest(requestParameters: SetVisibilityOfChangeAfterApprovalRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setVisibilityOfChangeAfterApprovalRequestRaw(requestParameters, initOverrides);
    }

}
