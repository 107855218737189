/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvoiceItemSummary } from './InvoiceItemSummary';
import {
    InvoiceItemSummaryFromJSON,
    InvoiceItemSummaryFromJSONTyped,
    InvoiceItemSummaryToJSON,
} from './InvoiceItemSummary';

/**
 * 
 * @export
 * @interface PagedResultInvoiceItemSummary
 */
export interface PagedResultInvoiceItemSummary {
    /**
     * 
     * @type {Array<InvoiceItemSummary>}
     * @memberof PagedResultInvoiceItemSummary
     */
    content: Array<InvoiceItemSummary>;
    /**
     * 
     * @type {number}
     * @memberof PagedResultInvoiceItemSummary
     */
    totalElements: number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultInvoiceItemSummary
     */
    totalPages: number;
}

/**
 * Check if a given object implements the PagedResultInvoiceItemSummary interface.
 */
export function instanceOfPagedResultInvoiceItemSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "totalElements" in value;
    isInstance = isInstance && "totalPages" in value;

    return isInstance;
}

export function PagedResultInvoiceItemSummaryFromJSON(json: any): PagedResultInvoiceItemSummary {
    return PagedResultInvoiceItemSummaryFromJSONTyped(json, false);
}

export function PagedResultInvoiceItemSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedResultInvoiceItemSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': ((json['content'] as Array<any>).map(InvoiceItemSummaryFromJSON)),
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
    };
}

export function PagedResultInvoiceItemSummaryToJSON(value?: PagedResultInvoiceItemSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': ((value.content as Array<any>).map(InvoiceItemSummaryToJSON)),
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
    };
}

