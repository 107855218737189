import { usePrevious } from "@mantine/hooks";
import { RefObject, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useScrollTop } from "./useScrollTop/useScrollTop";

export type UseScrollTopOnRouteChangeProps = {
  ref: RefObject<HTMLElement>;
};

export const useScrollTopOnRouteChange = (props: UseScrollTopOnRouteChangeProps) => {
  const { scrollToTop } = useScrollTop({ ref: props.ref });

  const location = useLocation();
  const previousPathname = usePrevious(location.pathname);

  useEffect(() => {
    if (location.pathname !== previousPathname) {
      setTimeout(() => {
        scrollToTop({ behavior: "instant" });
      }, 10);
    }
  }, [scrollToTop, location.pathname, previousPathname]);
};
