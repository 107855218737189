import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { useRestAPI } from "../useRestAPI";

const GetMeQueryKey = "GetMeQuery";
export const useMeQuery = (queryOptions?: UseQueryOptions) => {
  const api = useRestAPI();

  return useQuery({
    queryKey: [GetMeQueryKey, queryOptions?.queryKey],
    queryFn: () => api.me.me(),
    staleTime: queryOptions?.staleTime,
    retry: queryOptions?.retry,
    enabled: queryOptions?.enabled,
  });
};
