import { Carousel, Embla, useAnimationOffsetEffect } from "@mantine/carousel";
import { Box, Button, Center, Group, Image, Space, Stack, Text } from "@mantine/core";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { GuidedTourData } from "./GuidedTourModal";

import styles from "./InfoContainer.module.css";

type InfoContainerProps = {
  guidedTourData: GuidedTourData[];
  translationBaseKey?: string;
  onClose: () => void;
  onCancelAll: () => void;
};

const InfoContainer = (props: InfoContainerProps) => {
  const { guidedTourData, translationBaseKey, onClose, onCancelAll } = props;
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const TRANSITION_DURATION = 200;
  const [embla, setEmbla] = useState<Embla | null>(null);
  useAnimationOffsetEffect(embla, TRANSITION_DURATION);
  const nextStep = () => {
    embla?.scrollNext();
  };

  const prevStep = () => {
    embla?.scrollPrev();
  };

  const guidedTourDataLength = guidedTourData.length;

  return (
    <Stack>
      <Carousel
        classNames={{ root: styles.root, controls: styles.controls }}
        w={650}
        mx="auto"
        getEmblaApi={setEmbla}
        height={450}
        onSlideChange={setCurrentPage}
        controlsOffset={0}
      >
        {guidedTourData.map((page, index) => {
          return (
            <Carousel.Slide key={page.image}>
              <Box h={70}>
                <Text>
                  {translationBaseKey && (
                    <Trans t={t} i18nKey={`${translationBaseKey}.${index + 1}`} />
                  )}
                  {!translationBaseKey && <Trans t={t} i18nKey={page.descriptionTranslationKey} />}
                </Text>
              </Box>
              <Space />
              <Center py="md">
                <Box>
                  <Image src={page.image} />
                </Box>
              </Center>
            </Carousel.Slide>
          );
        })}
      </Carousel>

      <Group justify="space-between">
        <Text>
          {t("guidedTour.pageCount", {
            currentPage: currentPage + 1,
            pageCount: guidedTourDataLength,
          })}
        </Text>
        <Group>
          {currentPage !== guidedTourDataLength - 1 && (
            <Button onClick={onCancelAll} variant={"white"}>
              {t("guidedTour.finishPrematurelyButtonLabel")}
            </Button>
          )}
          {currentPage > 0 && (
            <Button onClick={prevStep} variant={"outline"}>
              {t("guidedTour.previousButton")}
            </Button>
          )}

          {currentPage !== guidedTourDataLength - 1 && (
            <Button onClick={nextStep}>{t("guidedTour.nextButton")}</Button>
          )}
          {currentPage === guidedTourDataLength - 1 && (
            <Button onClick={onClose}> {t("guidedTour.finishButtonLabel")}</Button>
          )}
        </Group>
      </Group>
    </Stack>
  );
};
export { InfoContainer };
