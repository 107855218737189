/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccountDetails,
  BankAccount,
  DiscountAgreements,
  FactoringLimit,
  PaymentTerms,
  RequestRetailerContactUpdateRequestBody,
  RetailerBillingStop,
  RetailerDetails,
} from '../models';
import {
    AccountDetailsFromJSON,
    AccountDetailsToJSON,
    BankAccountFromJSON,
    BankAccountToJSON,
    DiscountAgreementsFromJSON,
    DiscountAgreementsToJSON,
    FactoringLimitFromJSON,
    FactoringLimitToJSON,
    PaymentTermsFromJSON,
    PaymentTermsToJSON,
    RequestRetailerContactUpdateRequestBodyFromJSON,
    RequestRetailerContactUpdateRequestBodyToJSON,
    RetailerBillingStopFromJSON,
    RetailerBillingStopToJSON,
    RetailerDetailsFromJSON,
    RetailerDetailsToJSON,
} from '../models';

export interface UpdateRequestRetailerContactRequest {
    requestRetailerContactUpdateRequestBody: RequestRetailerContactUpdateRequestBody;
}

/**
 * 
 */
export class RetailerResourceApi extends runtime.BaseAPI {

    /**
     */
    async deleteRetailerAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailer`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteRetailerAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRetailerAccountRaw(initOverrides);
    }

    /**
     */
    async getAccountsOfCurrentRetailerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AccountDetails>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailer/account`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccountDetailsFromJSON));
    }

    /**
     */
    async getAccountsOfCurrentRetailer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AccountDetails>> {
        const response = await this.getAccountsOfCurrentRetailerRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getBankAccountOfCurrentRetailerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BankAccount>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailer/bank-account`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BankAccountFromJSON(jsonValue));
    }

    /**
     */
    async getBankAccountOfCurrentRetailer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BankAccount> {
        const response = await this.getBankAccountOfCurrentRetailerRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getBillingStopOfCurrentRetailerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RetailerBillingStop>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailer/billing-stop`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RetailerBillingStopFromJSON(jsonValue));
    }

    /**
     */
    async getBillingStopOfCurrentRetailer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RetailerBillingStop> {
        const response = await this.getBillingStopOfCurrentRetailerRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getCurrentRetailerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RetailerDetails>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RetailerDetailsFromJSON(jsonValue));
    }

    /**
     */
    async getCurrentRetailer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RetailerDetails> {
        const response = await this.getCurrentRetailerRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getDiscountAgreementsOfCurrentRetailerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiscountAgreements>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailer/discount-agreements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountAgreementsFromJSON(jsonValue));
    }

    /**
     */
    async getDiscountAgreementsOfCurrentRetailer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiscountAgreements> {
        const response = await this.getDiscountAgreementsOfCurrentRetailerRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getFactoringLimitOfCurrentRetailerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FactoringLimit>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailer/factoring-limit`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FactoringLimitFromJSON(jsonValue));
    }

    /**
     */
    async getFactoringLimitOfCurrentRetailer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FactoringLimit> {
        const response = await this.getFactoringLimitOfCurrentRetailerRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPaymentTermsOfCurrentRetailerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaymentTerms>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailer/payment-terms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentTermsFromJSON(jsonValue));
    }

    /**
     */
    async getPaymentTermsOfCurrentRetailer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaymentTerms> {
        const response = await this.getPaymentTermsOfCurrentRetailerRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateRequestRetailerContactRaw(requestParameters: UpdateRequestRetailerContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestRetailerContactUpdateRequestBody === null || requestParameters.requestRetailerContactUpdateRequestBody === undefined) {
            throw new runtime.RequiredError('requestRetailerContactUpdateRequestBody','Required parameter requestParameters.requestRetailerContactUpdateRequestBody was null or undefined when calling updateRequestRetailerContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/retailer/requestRetailerContactUpdate`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RequestRetailerContactUpdateRequestBodyToJSON(requestParameters.requestRetailerContactUpdateRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateRequestRetailerContact(requestParameters: UpdateRequestRetailerContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRequestRetailerContactRaw(requestParameters, initOverrides);
    }

}
