import { SecurityLevel } from "~/ui/pages/AccountPage/AccountCredentials/PasswortStrengthChecker";

export const AccountPageDE = {
  title: "Mein Konto",
  error:
    "Ein Fehler ist aufgetreten. Deine Profildaten konnten nicht geladen werden. Bitte versuche es später erneut.",
  userNameLabel: "Benutzername",
  loginData: {
    title: "Anmeldedaten",
    emailLabel: "E-Mail",
    changeEmailLink: "E-Mail-Adresse ändern",
    passwordLabel: "Passwort",
    changePasswordLink: "Ändern",
    changePasswordModal: {
      title: "Passwort ändern",
      currentPasswordPlaceholder: "Altes Passwort",
      newPasswordPlaceholder: "Neues Passwort",
      newPasswordConfirmPlaceholder: "Neues Passwort wiederholen",
      submitButtonLabel: "Passwort ändern",
      editTitle: "Passwort ändern",
      newPasswordsDontMatch: "Die Passwörter stimmen nicht überein",
      wrongPasswordError: "Das alte Passwort stimmt nicht",
      successMessage: "Passwort erfolgreich geändert",
    },
    passwordMeter: {
      passwordStrength: "Passwortstärke: ",
      placeholder: "Bitte Passwort eingeben",
      securityLevel: {
        weak: "Schwach",
        medium: "Mittel",
        strong: "Stark",
      } as Record<SecurityLevel, string>,
    },
  },
  contactInfo: {
    title: "Kontaktinformationen",
    firstnameLabel: "Vorname",
    surnameLabel: "Nachname",
    phoneLabel: "Telefon",
    mobileLabel: "Mobil",
    editTitle: "Kontaktinformationen ändern",
    editButtonLabel: "Kontaktinformationen ändern",
    abortButtonLabel: "Abbrechen",
    cancelButtonLabel: "Zurück",
    saveButtonLabel: "Speichern",
    validation: {
      firstNameRequired: "Vorname ist erforderlich",
      lastNameRequired: "Nachname ist erforderlich",
      phoneRequired: "Telefon ist erforderlich",
    },
    changeButtonLabel: "Ändern",
  },
  contactPermissions: {
    title: "Kontaktberechtigungen",
    description: "Hiermit bestätige ich heo, mich über die folgenden Kanäle zu kontaktieren.",
    switchEmailLabel: "E-Mail",
    switchPhoneLabel: "Telefon",
    switchWhatsappLabel: "Whatsapp",
    switchSmsLabel: "SMS",
    errorMessage: "Die Änderung konnte nicht gespeichert werden. Bitte versuche es später erneut.",
  },

  accountDeletion: {
    buttonLabel: "Löschen des Kundenkontos beantragen",
    deletionHint:
      "Solltest Du dein Kundenkonto unwiderruflich schließen wollen, beantrage dies bitte hier. Hiervon unverändert bleiben bereits abgeschlossene Kaufverträge, offene Rechnungen oder Gutschriften.",
    modalConfirmDescription:
      "Durch das Löschen deines Kontos verlierst du den Zugriff auf den heo-Shop und die heo Business Console. ",
    modalConfirmText: "Möchtest du dein Kundenkonto wirklich löschen?",
    modalConfirmButtonLabel: "Ja, Löschung beantragen",
    modalCancelButtonLabel: "Abbrechen",
    successMessage: "Beantragung der Löschung des Kundenkonto wurde erfolgreich übermittelt.",
    errorMessage:
      "Ein Fehler ist aufgetreten. Die Beantragung der Löschung des Kundenkontos konnte nicht übermittelt werden. Bitte versuche es später erneut.",
  },
};
