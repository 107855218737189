export enum RoutesEnum {
  LANDING = "/",
  LOGIN = "/login",
  LOGOUT = "/logout",
  ACCOUNT = "/account",
  PASSWORD_RESET = "/password-reset",
  RETAILER = "/retailer",
  PRODUCTS = "/products",
  CUSTOMER_ORDERS = "/customer-orders",
  INVOICES = "/invoices",
  PRODUCT_CLAIMS = "/product-claims",
  CREATE_PRODUCT_CLAIMS = "/create-product-claims",
  STORE_PROFILE = "/store-profile",
  STORE_PROFILE_CREATE = "/store-profile/create",
  STORE_PROFILE_EDIT = "/store-profile/edit",
  SALES_ORDERS = "/sales-orders",
  DASHBOARD = "/dashboard",
  TOTAL_SALES_DETAILS = "total-sales-details",
  CREDITS = "/credit-notes",
  MANAGE_API = "/manage-api",
  NO_ACCESS = "/no-access",
}
