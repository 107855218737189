import { endOfDay, isValid, startOfDay, startOfYear, subDays, subMonths } from "date-fns";

import { PeriodOption } from "../../enum/PeriodOption";

/**
 * Helper Class to create a date range from various inputs such as PeriodOption keys (3m, 7d, etc.) or custom ranges (e.g. 1683756000000-1685224799999)
 */
export class PeriodRange {
  public readonly key: string;
  public readonly from: Date | undefined;
  public readonly until: Date | undefined;

  constructor(
    from: Date | string | undefined | null,
    until: Date | string | undefined | null,
    key?: string,
  ) {
    this.from = from ? new Date(from) : undefined;
    this.until = until ? new Date(until) : undefined;
    this.key = key ?? PeriodOption.CUSTOM;
  }

  public isCustomRange() {
    return this.key === PeriodOption.CUSTOM;
  }

  public toString() {
    const fromString = `${this.from?.valueOf()}`;
    const untilString = `${this.until?.valueOf()}`;

    if (!fromString && !untilString) {
      return null;
    }

    if (fromString && !untilString) {
      return fromString;
    }

    if (untilString && !fromString) {
      return untilString;
    }

    return `${fromString}-${untilString}`;
  }

  public static fromString(periodString: string | undefined | null, isCustomDefault = false) {
    if (!periodString) return new PeriodRange(undefined, undefined, PeriodOption.ALL);

    // Custom Range
    if (periodString?.match(/^\d+-\d+$/) || isCustomDefault) {
      const [from, until] = periodString.split("-");
      const fromDate = isValid(Number(from)) ? new Date(Number(from)) : undefined;
      const untilDate = isValid(Number(until)) ? new Date(Number(until)) : undefined;
      return new PeriodRange(fromDate, untilDate, PeriodOption.CUSTOM);
    }

    return PeriodRange.periodKeyToRange(periodString);
  }

  public static periodKeyToRange(option: string) {
    let from = startOfDay(new Date());
    let until = endOfDay(new Date());

    switch (option) {
      case PeriodOption.SEVEN_DAYS:
        from = subDays(from, 6);
        break;
      case PeriodOption.TWO_WEEKS:
        from = subDays(from, 13);
        break;
      case PeriodOption.ONE_MONTH:
        from = subMonths(from, 1);
        break;
      case PeriodOption.THREE_MONTHS:
        from = subMonths(from, 3);
        break;
      case PeriodOption.SIX_MONTHS:
        from = subMonths(from, 6);
        break;
      case PeriodOption.TWELVE_MONTHS:
        from = subMonths(from, 12);
        break;
      case PeriodOption.START_OF_YEAR:
        from = startOfYear(from);
        break;
      case PeriodOption.OLDER_TWELVE_MONTH:
        // The oldest order in bc is from 2010. So this is the oldest valid time
        from = new Date("2010-01-01");
        until = subMonths(startOfDay(new Date()), 12);
        break;
      default:
        return new PeriodRange(undefined, undefined, option);
    }

    return new PeriodRange(from, until, option);
  }
}
