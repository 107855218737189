/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeoTransactionNumber } from './HeoTransactionNumber';
import {
    HeoTransactionNumberFromJSON,
    HeoTransactionNumberFromJSONTyped,
    HeoTransactionNumberToJSON,
} from './HeoTransactionNumber';

/**
 * 
 * @export
 * @interface SalesOrderItemAvailabilitySummary
 */
export interface SalesOrderItemAvailabilitySummary {
    /**
     * 
     * @type {string}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    status: SalesOrderItemAvailabilitySummaryStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    quantity: number;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    lastGoodsReceiptAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    itemId: string;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    salesOrderItemAvailability?: Date;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    salesOrderItemPricePerUnit: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    salesOrderItemDiscount: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    salesOrderItemDiscountedPricePerUnit: number;
    /**
     * 
     * @type {number}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    salesOrderItemTotal: number;
    /**
     * 
     * @type {Date}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    salesOrderCreatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    productNumber: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    productThumbnailUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    productManufacturers: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    productTypes: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    productThemes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    gtin?: string;
    /**
     * 
     * @type {HeoTransactionNumber}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    customerOrderNumber?: HeoTransactionNumber;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    customerItemReference?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    customerReference?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderItemAvailabilitySummary
     */
    infoToCustomer?: string;
}


/**
 * @export
 */
export const SalesOrderItemAvailabilitySummaryStatusEnum = {
    InStockOverdue: 'IN_STOCK_OVERDUE',
    InStock: 'IN_STOCK',
    Incoming: 'INCOMING',
    InPreorder: 'IN_PREORDER',
    OnRequest: 'ON_REQUEST'
} as const;
export type SalesOrderItemAvailabilitySummaryStatusEnum = typeof SalesOrderItemAvailabilitySummaryStatusEnum[keyof typeof SalesOrderItemAvailabilitySummaryStatusEnum];


/**
 * Check if a given object implements the SalesOrderItemAvailabilitySummary interface.
 */
export function instanceOfSalesOrderItemAvailabilitySummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "itemId" in value;
    isInstance = isInstance && "salesOrderItemPricePerUnit" in value;
    isInstance = isInstance && "salesOrderItemDiscount" in value;
    isInstance = isInstance && "salesOrderItemDiscountedPricePerUnit" in value;
    isInstance = isInstance && "salesOrderItemTotal" in value;
    isInstance = isInstance && "salesOrderCreatedAt" in value;
    isInstance = isInstance && "productNumber" in value;
    isInstance = isInstance && "productName" in value;
    isInstance = isInstance && "productManufacturers" in value;
    isInstance = isInstance && "productTypes" in value;
    isInstance = isInstance && "productThemes" in value;

    return isInstance;
}

export function SalesOrderItemAvailabilitySummaryFromJSON(json: any): SalesOrderItemAvailabilitySummary {
    return SalesOrderItemAvailabilitySummaryFromJSONTyped(json, false);
}

export function SalesOrderItemAvailabilitySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesOrderItemAvailabilitySummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'status': json['status'],
        'quantity': json['quantity'],
        'lastGoodsReceiptAt': !exists(json, 'lastGoodsReceiptAt') ? undefined : (new Date(json['lastGoodsReceiptAt'])),
        'itemId': json['itemId'],
        'salesOrderItemAvailability': !exists(json, 'salesOrderItemAvailability') ? undefined : (new Date(json['salesOrderItemAvailability'])),
        'salesOrderItemPricePerUnit': json['salesOrderItemPricePerUnit'],
        'salesOrderItemDiscount': json['salesOrderItemDiscount'],
        'salesOrderItemDiscountedPricePerUnit': json['salesOrderItemDiscountedPricePerUnit'],
        'salesOrderItemTotal': json['salesOrderItemTotal'],
        'salesOrderCreatedAt': (new Date(json['salesOrderCreatedAt'])),
        'productNumber': json['productNumber'],
        'productName': json['productName'],
        'productThumbnailUrl': !exists(json, 'productThumbnailUrl') ? undefined : json['productThumbnailUrl'],
        'productManufacturers': json['productManufacturers'],
        'productTypes': json['productTypes'],
        'productThemes': json['productThemes'],
        'gtin': !exists(json, 'gtin') ? undefined : json['gtin'],
        'customerOrderNumber': !exists(json, 'customerOrderNumber') ? undefined : HeoTransactionNumberFromJSON(json['customerOrderNumber']),
        'customerItemReference': !exists(json, 'customerItemReference') ? undefined : json['customerItemReference'],
        'customerReference': !exists(json, 'customerReference') ? undefined : json['customerReference'],
        'infoToCustomer': !exists(json, 'infoToCustomer') ? undefined : json['infoToCustomer'],
    };
}

export function SalesOrderItemAvailabilitySummaryToJSON(value?: SalesOrderItemAvailabilitySummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'status': value.status,
        'quantity': value.quantity,
        'lastGoodsReceiptAt': value.lastGoodsReceiptAt === undefined ? undefined : (value.lastGoodsReceiptAt.toISOString()),
        'itemId': value.itemId,
        'salesOrderItemAvailability': value.salesOrderItemAvailability === undefined ? undefined : (value.salesOrderItemAvailability.toISOString()),
        'salesOrderItemPricePerUnit': value.salesOrderItemPricePerUnit,
        'salesOrderItemDiscount': value.salesOrderItemDiscount,
        'salesOrderItemDiscountedPricePerUnit': value.salesOrderItemDiscountedPricePerUnit,
        'salesOrderItemTotal': value.salesOrderItemTotal,
        'salesOrderCreatedAt': (value.salesOrderCreatedAt.toISOString()),
        'productNumber': value.productNumber,
        'productName': value.productName,
        'productThumbnailUrl': value.productThumbnailUrl,
        'productManufacturers': value.productManufacturers,
        'productTypes': value.productTypes,
        'productThemes': value.productThemes,
        'gtin': value.gtin,
        'customerOrderNumber': HeoTransactionNumberToJSON(value.customerOrderNumber),
        'customerItemReference': value.customerItemReference,
        'customerReference': value.customerReference,
        'infoToCustomer': value.infoToCustomer,
    };
}

