export const GuidedTourDE = {
  startTourActioButtonTitle: "Guided Tour starten",
  title: "Guided Tour: {{customTitle}}",
  previousButton: "Zurück",
  nextButton: "Weiter",
  finishPrematurelyButtonLabel: "Guided Tour beenden",
  finishButtonLabel: "Guided Tour abschließen",
  pageCount: "{{currentPage}} von {{pageCount}}",
  intro: {
    title: "Willkommen in Deiner Business Console!",
    description:
      "Die <strong>heo Business Console (BC) ist Deine Plattform</strong>, die Dir Transparenz und Funktionen zur Steuerung Deiner Aftersales Vorgänge und Geschäftsabläufe bietet. <br/><br/> Um den Einstieg für dich zu erleichtern, möchten wir Dir gerne die Basisfunktionen in einer kleinen Guided Tour vorstellen. (Zeitaufwand: ca. 5 Minuten)",
    startLaterButtonLabel: "Vielleicht später",
    startGuidedTourButtonLabel: "Guided Tour starten",
  },
  userInterfaceDashboard: {
    title: "Benutzeroberfläche & Dashboard",
    steps: {
      1: "Mit der <strong>Navigation</strong> auf der linken Seite steuerst Du die einzelnen Bereiche der Business Console an. Du kannst die Navigation auch einklappen um mehr Platz für den Arbeitsbereich zu haben.",
      2: "Im <strong>zentralen Arbeitsbereich</strong> findest Du Informationen und Funktionen der jeweiligen Bereiche.",
      3: "Deine <strong>Profil-Informationen</strong> kannst Du in der Kopfzeile oben rechts aufrufen. Hier findest Du den Einstieg zu Deinen Konto- und Unternehmensdaten. ",
      4: "Unter <strong>Mein Konto</strong> werden Deine Anmelde- und Kontaktinformationen angezeigt und können ggf. geändert werden.",
      5: "Unter <strong>Mein Unternehmen</strong> sind Deine Unternehmensdaten unter verschiedenen Rubriken, bspw. Liefer- und Zahlungsvereinbarungen zusammengestellt. Zu einigen Daten kannst Du Änderungen beantragen.",
      6: "Dein <strong>Feedback</strong> zu Fehlern, Verbesserungen und neuen Ideen kannst Du mit dem Feedback-Formular an heo senden.",
      7: "Im <strong>Mein Dashboard</strong> - Bereich bekommst Du einen schnellen und strukturierten Überblick über Deine wichtigsten Geschäftsvorfälle.",
      8: "Erfahre in der <strong>Willkommensbox</strong> alles über Deine aktuellen Geschäftsvorfälle innerhalb der letzten 7 Tage, wie z.B. neue Bestellungen, Lieferungen oder Veränderungen in Deinen Rückständen.",
      9: "Schaffe Dir einen schnellen Überblick über Deine <strong>offenen Bestellungen</strong> und deren Mengen- und Preisvolumen in einer interaktiven Diagramm-Darstellung.",
      10: "Behalte Deine <strong>offenen Rechnungen</strong> stets im Blick und springe direkt zu zu den jeweiligen Rechnungsdetails.",
      11: "Schau Dir im Bereich voraussichtliche Verfügbarkeit an, welche <strong>Artikel in den kommenden Monaten</strong> voraussichtlich geliefert werden, um Deinen Cashflow sinnvoll zu planen.",
      12: "Überprüfe schnell und einfach Deine <strong>offenen Reklamationen</strong>, um eine zeitnahe Abwicklung sicherzustellen.",
    },
  },
  product: {
    title: "Meine Produkte",
    steps: {
      1: "Unter <strong>Meine Produkte</strong> findest Du alle Produkte, die Du im heo-Shop bestellt hast. Diese Liste fasst die Produkte auf einen Eintrag pro Produkt zusammen unabhängig davon, wie viele du davon bereits bestellt hast.",
      2: "Zu jedem bestellten Produkt kannst Du unter <strong>Bearbeitungsstatus</strong> einsehen, wie viele davon noch offen, schon in Bearbeitung oder bereits ausgeliefert sind. Auch offene Reklamationen werden Dir hier ggfs. angezeigt.",
      3: "Mit den <strong>Filter- und Suchfunktionen</strong> kannst Du die Liste auf die für dich relevanten Produkte einschränken oder aber auch über die Spaltenüberschriften auf- und absteigend sortieren.",
      4: "Erhalte <strong>Detail-Informationen</strong> zu jedem bisher bestellten Produkt auf der Produkt-Detailsicht, in dem Du auf einen Artikel aus der Liste klickst.",
      5: "Neben aktuellen Preis- und Produktinformationen werden Dir hier <strong>alle Bestellungen, Rechnungen und ggfs. Reklamationen zu diesem Artikel</strong> aufgelistet und durchsuchbar angezeigt.",
    },
  },
  customerOrder: {
    title: "Meine Bestellungen",
    steps: {
      1: "Unter <strong>Meine Bestellungen </strong>findest Du alle Shop-Bestellungen, die Du bei heo getätigt hast. Zu jeder Bestellung werden Dir in der Bestellungs-Übersicht die wichtigsten Informationen zusammenfassend angezeigt.",
      2: "Mit den <strong>Filter- und Suchfunktionen</strong> kannst Du die Liste auf die für dich relevanten Bestellungen einschränken und bspw. gezielt nach Bestell- oder Artikelnummern suchen. Du kannst die Liste außerdem spaltenweise sortieren.",
      3: "Erhalte <strong>Detail-Informationen</strong> zu jeder Bestellung auf der Bestell-Detailsicht, in dem die eine Bestellung anklickst. Rufe Artikeldetails aus den Bestellungen heraus direkt per Link auf.",
      4: "Filtere in der <strong>Bestell-Detailsicht</strong> einzelne Bestellungen nach Bestellpositionen und verschaffe Dir einen Überblick darüber, welche Bestellpositionen bestätigt bzw. bereits ausgeliefert wurden.",
    },
  },
  salesOrder: {
    title: "Meine Rückstände",
    steps: {
      1: "Unter <strong>Meine Rückstände</strong> findest Du die Übersicht Deiner aktuellen Rückstände. Pro Rückstellung werden Dir hier zusammenfassend die wichtigsten Informationen und Status angezeigt.",
      2: "Die <strong>Filterkacheln</strong> zeigen Dir zusammenfassend die Anzahl und Beträge Deiner Rückstände für den jeweiligen Status an. Du kannst die Kacheln auch zur Filterung der Liste nutzen, um nur Rückstände eines bestimmten Status anzuzeigen.",
      3: "Mit den <strong>Filter- und Suchfunktionen</strong> kannst Du die Liste auf die für dich relevanten Rückstellungen einschränken oder gezielt über bspw. Artikelnummern oder ETAs nach Positionen suchen. Du kannst außerdem die Liste spaltenweise sortieren.",
      4: "Zugehörige Bestellungen und Details zu rückständigen Artikeln können über Links direkt aus der Rückstandsliste heraus aufgerufen werden.",
    },
  },
  invoice: {
    title: "Meine Rechnungen",
    steps: {
      1: "Unter <strong>Meine Rechnungen</strong> findest Du Deine Rechnungen, aber auch aktuelle Auftragsbestätigungen. Pro Rechnung werden Dir die wichtigsten Informationen wie Versand- und Zahlungsstatus, die Rechnungssumme und die Lieferadresse angezeigt.",
      2: "Die <strong>Filterkacheln</strong> zeigen Dir zusammenfassend die Anzahl und Beträge Deiner Rechnungen für den jeweiligen Status an. Du kannst die Kacheln auch zur Filterung der Liste nutzen, um z.B. nur offene oder überfällige Rechnungen anzuzeigen.",
      3: "Mit den <strong>Filter- und Suchfunktionen</strong> kannst Du die Liste auf die für dich relevanten Rechnungen einschränken. Über die Spaltenüberschriften lassen sich die Rechnungen zudem auf- und absteigend sortieren.",
      4: "Alle Rechnungen und Auftragsbestätigungen stehen Dir in der Rechnungs-Übersicht als <strong>PDF-Download</strong> zur Verfügungen.",
      5: "Zu jeder Rechnung werden die <strong>Anzahl der Pakete</strong> und die <strong>Sendungsnummern</strong> angezeigt. Ein Dropshipping-Versand ist ebenfalls gekennzeichnet.",
      6: "Erhalte <strong>Detail-Informationen</strong> zu jeder Rechnung in der Rechnungs-Detailsicht, in dem Du auf eine Rechnungszeile klickst. Hier werden Dir neben allgemeinen Rechnungsdaten auch alle Rechnungspositionen bebildert angezeigt.",
      7: "In dieser Ansicht sind <strong>alle Rechnungspositionen</strong> nach verschiedenen Kriterien durchsuchbar. Rufe außerdem Bestellungen und Artikeldetails direkt via Links auf, oder starte die Reklamation eines Artikels aus dieser Ansicht.",
    },
  },
  productClaim: {
    title: "Meine Reklamationen",
    steps: {
      1: "Unter <strong>Meine Reklamationen</strong> findest Du alle Reklamationen, die Du in der Business Console angelegt hast. Für jede Reklamation werden hier die wichtigsten Informationen, wie der aktuelle Status, übersichtlich zusammengefasst.",
      2: "Die <strong>Filter- und Suchfunktionen</strong> ermöglichen es Dir, die Liste der Reklamationen nach Deinen Bedürfnissen anzupassen oder gezielt nach betroffenen Rechnungen zu suchen. Eine spaltenweise Sortierung der Liste ist ebenfalls möglich.",
      3: "Erhalte <strong>Detail-Informationen</strong> zu jeder Reklamation auf der Reklamations-Detailsicht und verfolge den Kommunikations- und Bearbeitungsverlauf. Hier werden außerdem auch Deine Rückfragen und Antworten zur Reklamation angezeigt.",
      4: "In der Business Console hast Du die Möglichkeit, ohne Verlassen der Anwendung, direkt <strong>neue Reklamationen</strong> für einen oder mehrere Artikel zu erstellen.",
      5: "Wähle dabei in einem <strong>geführten Reklamationsprozess</strong> die Artikel aus, die Du bei heo reklamieren möchtest und gebe die benötigten Daten pro Artikel entsprechend ein.  ",
    },
  },
  creditNotes: {
    title: "Meine Gutschriften",
    steps: {
      1: "Unter <strong>Meine Gutschriften</strong> findest Du alle Gutschriften, die Du von heo bekommen hast. Pro Gutschrift werden Dir hier zusammenfassend die wichtigsten Informationen angezeigt.",
      2: "Mit den <strong>Filter- und Suchfunktionen</strong> kannst Du die Liste auf die für dich relevanten Gutschriften einschränken. Du kannst die Liste auch spaltenweise sortieren, indem Du auf die Spaltenüberschriften klickst.",
      3: "In der Gutschriften-Übersicht stehen Dir alle bisherigen Gutschriften in digitaler Form zur Verfügung und können als <strong>PDF-Datei</strong> heruntergeladen bzw. gespeichert werden.",
    },
  },
};
