export const TotalSalesDetailsPageDE = {
  titel: "Umsatzübersicht",
  orders: "Bestellungen",
  invoices: "Rechnungen",
  creditNotes: "Gutschriften",
  canceledOrders: "Stornierte Bestellungen",
  canceledInvoices: "Stornierte Rechnungen",
  quota: "Quote",
  backToDashboardButton: "Zurück zum Dashboard",
  table: {
    noDataText: "Keine Daten vorhanden",
    header: {
      year: "Jahr",
      orderAmount: "Bestellungen",
      invoiceAmount: "Rechnungen",
      creditNoteAmount: "Gutschriften",
      canceledOrderAmount: "Stornierte Bestellungen",
      canceledInvoiceAmount: "Stornierte Rechnungen",
      quota: "Quote",
    },
  },
};
