/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AvailabilityByProduct,
  QuerySortCriterionSalesOrderItemAvailabilitiesQuery,
  SalesOrderItemAvailabilitySummary,
  SalesOrderItemAvailabilitySummaryResponse,
  SalesOrderItemAvailabilityTotalSummary,
  SortDirection,
} from '../models';
import {
    AvailabilityByProductFromJSON,
    AvailabilityByProductToJSON,
    QuerySortCriterionSalesOrderItemAvailabilitiesQueryFromJSON,
    QuerySortCriterionSalesOrderItemAvailabilitiesQueryToJSON,
    SalesOrderItemAvailabilitySummaryFromJSON,
    SalesOrderItemAvailabilitySummaryToJSON,
    SalesOrderItemAvailabilitySummaryResponseFromJSON,
    SalesOrderItemAvailabilitySummaryResponseToJSON,
    SalesOrderItemAvailabilityTotalSummaryFromJSON,
    SalesOrderItemAvailabilityTotalSummaryToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerRequest {
    offset?: number;
    limit?: number;
    sortByCustomerOrderNumber?: SortDirection;
    sortBySalesOrderCreatedAt?: SortDirection;
    sortBySalesOrderItemAvailability?: SortDirection;
    sortByProductNumber?: SortDirection;
    sortByProductManufacturer?: SortDirection;
    sortByProductType?: SortDirection;
    sortByQuantity?: SortDirection;
    sortByAvailabilityStatus?: SortDirection;
    sortBySalesOrderItemPricePerUnit?: SortDirection;
    sortBySalesOrderItemDiscountedPricePerUnit?: SortDirection;
    sortBySalesOrderItemTotal?: SortDirection;
    sortByCustomerItemReference?: SortDirection;
    sortByCustomerReference?: SortDirection;
    sortByGtin?: SortDirection;
    sortByInfoToCustomer?: SortDirection;
    fulltextQuery?: string;
    productManufacturers?: Array<string>;
    productTypes?: Array<string>;
    status?: Array<GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerStatusEnum>;
    createdBetweenFrom?: Date;
    createdBetweenUntil?: Date;
    availableBetweenFrom?: Date;
    availableBetweenUntil?: Date;
    sortCriteria?: Set<QuerySortCriterionSalesOrderItemAvailabilitiesQuery>;
}

export interface GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerCsvExportRequest {
    sortByCustomerOrderNumber?: SortDirection;
    sortBySalesOrderCreatedAt?: SortDirection;
    sortBySalesOrderItemAvailability?: SortDirection;
    sortByProductNumber?: SortDirection;
    sortByProductManufacturer?: SortDirection;
    sortByProductType?: SortDirection;
    sortByQuantity?: SortDirection;
    sortByAvailabilityStatus?: SortDirection;
    sortBySalesOrderItemPricePerUnit?: SortDirection;
    sortBySalesOrderItemDiscountedPricePerUnit?: SortDirection;
    sortBySalesOrderItemTotal?: SortDirection;
    sortByCustomerItemReference?: SortDirection;
    sortByCustomerReference?: SortDirection;
    fulltextQuery?: string;
    productManufacturers?: Array<string>;
    productTypes?: Array<string>;
    status?: Array<GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerCsvExportStatusEnum>;
    createdBetweenFrom?: Date;
    createdBetweenUntil?: Date;
    availableBetweenFrom?: Date;
    availableBetweenUntil?: Date;
    sortCriteria?: Set<QuerySortCriterionSalesOrderItemAvailabilitiesQuery>;
}

export interface GetSalesOrderItemProductSummariesOfCurrentRetailerRequest {
    productNumbers: Array<string>;
}

/**
 * 
 */
export class SalesOrderItemAvailabilityResourceApi extends runtime.BaseAPI {

    /**
     */
    async getSalesOrderItemAvailabilitySummariesOfCurrentRetailerRaw(requestParameters: GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SalesOrderItemAvailabilitySummaryResponse>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortByCustomerOrderNumber !== undefined) {
            queryParameters['sortByCustomerOrderNumber'] = requestParameters.sortByCustomerOrderNumber;
        }

        if (requestParameters.sortBySalesOrderCreatedAt !== undefined) {
            queryParameters['sortBySalesOrderCreatedAt'] = requestParameters.sortBySalesOrderCreatedAt;
        }

        if (requestParameters.sortBySalesOrderItemAvailability !== undefined) {
            queryParameters['sortBySalesOrderItemAvailability'] = requestParameters.sortBySalesOrderItemAvailability;
        }

        if (requestParameters.sortByProductNumber !== undefined) {
            queryParameters['sortByProductNumber'] = requestParameters.sortByProductNumber;
        }

        if (requestParameters.sortByProductManufacturer !== undefined) {
            queryParameters['sortByProductManufacturer'] = requestParameters.sortByProductManufacturer;
        }

        if (requestParameters.sortByProductType !== undefined) {
            queryParameters['sortByProductType'] = requestParameters.sortByProductType;
        }

        if (requestParameters.sortByQuantity !== undefined) {
            queryParameters['sortByQuantity'] = requestParameters.sortByQuantity;
        }

        if (requestParameters.sortByAvailabilityStatus !== undefined) {
            queryParameters['sortByAvailabilityStatus'] = requestParameters.sortByAvailabilityStatus;
        }

        if (requestParameters.sortBySalesOrderItemPricePerUnit !== undefined) {
            queryParameters['sortBySalesOrderItemPricePerUnit'] = requestParameters.sortBySalesOrderItemPricePerUnit;
        }

        if (requestParameters.sortBySalesOrderItemDiscountedPricePerUnit !== undefined) {
            queryParameters['sortBySalesOrderItemDiscountedPricePerUnit'] = requestParameters.sortBySalesOrderItemDiscountedPricePerUnit;
        }

        if (requestParameters.sortBySalesOrderItemTotal !== undefined) {
            queryParameters['sortBySalesOrderItemTotal'] = requestParameters.sortBySalesOrderItemTotal;
        }

        if (requestParameters.sortByCustomerItemReference !== undefined) {
            queryParameters['sortByCustomerItemReference'] = requestParameters.sortByCustomerItemReference;
        }

        if (requestParameters.sortByCustomerReference !== undefined) {
            queryParameters['sortByCustomerReference'] = requestParameters.sortByCustomerReference;
        }

        if (requestParameters.sortByGtin !== undefined) {
            queryParameters['sortByGtin'] = requestParameters.sortByGtin;
        }

        if (requestParameters.sortByInfoToCustomer !== undefined) {
            queryParameters['sortByInfoToCustomer'] = requestParameters.sortByInfoToCustomer;
        }

        if (requestParameters.fulltextQuery !== undefined) {
            queryParameters['fulltextQuery'] = requestParameters.fulltextQuery;
        }

        if (requestParameters.productManufacturers) {
            queryParameters['productManufacturers'] = requestParameters.productManufacturers;
        }

        if (requestParameters.productTypes) {
            queryParameters['productTypes'] = requestParameters.productTypes;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.createdBetweenFrom !== undefined) {
            queryParameters['createdBetweenFrom'] = (requestParameters.createdBetweenFrom as any).toISOString();
        }

        if (requestParameters.createdBetweenUntil !== undefined) {
            queryParameters['createdBetweenUntil'] = (requestParameters.createdBetweenUntil as any).toISOString();
        }

        if (requestParameters.availableBetweenFrom !== undefined) {
            queryParameters['availableBetweenFrom'] = (requestParameters.availableBetweenFrom as any).toISOString();
        }

        if (requestParameters.availableBetweenUntil !== undefined) {
            queryParameters['availableBetweenUntil'] = (requestParameters.availableBetweenUntil as any).toISOString();
        }

        if (requestParameters.sortCriteria) {
            queryParameters['sortCriteria'] = requestParameters.sortCriteria;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/sales-order-item-availability/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SalesOrderItemAvailabilitySummaryResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSalesOrderItemAvailabilitySummariesOfCurrentRetailer(requestParameters: GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SalesOrderItemAvailabilitySummaryResponse> {
        const response = await this.getSalesOrderItemAvailabilitySummariesOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSalesOrderItemAvailabilitySummariesOfCurrentRetailerCsvExportRaw(requestParameters: GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerCsvExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SalesOrderItemAvailabilitySummary>>> {
        const queryParameters: any = {};

        if (requestParameters.sortByCustomerOrderNumber !== undefined) {
            queryParameters['sortByCustomerOrderNumber'] = requestParameters.sortByCustomerOrderNumber;
        }

        if (requestParameters.sortBySalesOrderCreatedAt !== undefined) {
            queryParameters['sortBySalesOrderCreatedAt'] = requestParameters.sortBySalesOrderCreatedAt;
        }

        if (requestParameters.sortBySalesOrderItemAvailability !== undefined) {
            queryParameters['sortBySalesOrderItemAvailability'] = requestParameters.sortBySalesOrderItemAvailability;
        }

        if (requestParameters.sortByProductNumber !== undefined) {
            queryParameters['sortByProductNumber'] = requestParameters.sortByProductNumber;
        }

        if (requestParameters.sortByProductManufacturer !== undefined) {
            queryParameters['sortByProductManufacturer'] = requestParameters.sortByProductManufacturer;
        }

        if (requestParameters.sortByProductType !== undefined) {
            queryParameters['sortByProductType'] = requestParameters.sortByProductType;
        }

        if (requestParameters.sortByQuantity !== undefined) {
            queryParameters['sortByQuantity'] = requestParameters.sortByQuantity;
        }

        if (requestParameters.sortByAvailabilityStatus !== undefined) {
            queryParameters['sortByAvailabilityStatus'] = requestParameters.sortByAvailabilityStatus;
        }

        if (requestParameters.sortBySalesOrderItemPricePerUnit !== undefined) {
            queryParameters['sortBySalesOrderItemPricePerUnit'] = requestParameters.sortBySalesOrderItemPricePerUnit;
        }

        if (requestParameters.sortBySalesOrderItemDiscountedPricePerUnit !== undefined) {
            queryParameters['sortBySalesOrderItemDiscountedPricePerUnit'] = requestParameters.sortBySalesOrderItemDiscountedPricePerUnit;
        }

        if (requestParameters.sortBySalesOrderItemTotal !== undefined) {
            queryParameters['sortBySalesOrderItemTotal'] = requestParameters.sortBySalesOrderItemTotal;
        }

        if (requestParameters.sortByCustomerItemReference !== undefined) {
            queryParameters['sortByCustomerItemReference'] = requestParameters.sortByCustomerItemReference;
        }

        if (requestParameters.sortByCustomerReference !== undefined) {
            queryParameters['sortByCustomerReference'] = requestParameters.sortByCustomerReference;
        }

        if (requestParameters.fulltextQuery !== undefined) {
            queryParameters['fulltextQuery'] = requestParameters.fulltextQuery;
        }

        if (requestParameters.productManufacturers) {
            queryParameters['productManufacturers'] = requestParameters.productManufacturers;
        }

        if (requestParameters.productTypes) {
            queryParameters['productTypes'] = requestParameters.productTypes;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.createdBetweenFrom !== undefined) {
            queryParameters['createdBetweenFrom'] = (requestParameters.createdBetweenFrom as any).toISOString();
        }

        if (requestParameters.createdBetweenUntil !== undefined) {
            queryParameters['createdBetweenUntil'] = (requestParameters.createdBetweenUntil as any).toISOString();
        }

        if (requestParameters.availableBetweenFrom !== undefined) {
            queryParameters['availableBetweenFrom'] = (requestParameters.availableBetweenFrom as any).toISOString();
        }

        if (requestParameters.availableBetweenUntil !== undefined) {
            queryParameters['availableBetweenUntil'] = (requestParameters.availableBetweenUntil as any).toISOString();
        }

        if (requestParameters.sortCriteria) {
            queryParameters['sortCriteria'] = requestParameters.sortCriteria;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/sales-order-item-availability/export-csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SalesOrderItemAvailabilitySummaryFromJSON));
    }

    /**
     */
    async getSalesOrderItemAvailabilitySummariesOfCurrentRetailerCsvExport(requestParameters: GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerCsvExportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SalesOrderItemAvailabilitySummary>> {
        const response = await this.getSalesOrderItemAvailabilitySummariesOfCurrentRetailerCsvExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSalesOrderItemAvailabilityTotalOfCurrentRetailerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SalesOrderItemAvailabilityTotalSummary>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/sales-order-item-availability/total`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SalesOrderItemAvailabilityTotalSummaryFromJSON));
    }

    /**
     */
    async getSalesOrderItemAvailabilityTotalOfCurrentRetailer(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SalesOrderItemAvailabilityTotalSummary>> {
        const response = await this.getSalesOrderItemAvailabilityTotalOfCurrentRetailerRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSalesOrderItemProductSummariesOfCurrentRetailerRaw(requestParameters: GetSalesOrderItemProductSummariesOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AvailabilityByProduct>>> {
        if (requestParameters.productNumbers === null || requestParameters.productNumbers === undefined) {
            throw new runtime.RequiredError('productNumbers','Required parameter requestParameters.productNumbers was null or undefined when calling getSalesOrderItemProductSummariesOfCurrentRetailer.');
        }

        const queryParameters: any = {};

        if (requestParameters.productNumbers) {
            queryParameters['productNumbers'] = requestParameters.productNumbers;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/sales-order-item-availability/product`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AvailabilityByProductFromJSON));
    }

    /**
     */
    async getSalesOrderItemProductSummariesOfCurrentRetailer(requestParameters: GetSalesOrderItemProductSummariesOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AvailabilityByProduct>> {
        const response = await this.getSalesOrderItemProductSummariesOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerStatusEnum = {
    InStockOverdue: 'IN_STOCK_OVERDUE',
    InStock: 'IN_STOCK',
    Incoming: 'INCOMING',
    InPreorder: 'IN_PREORDER',
    OnRequest: 'ON_REQUEST'
} as const;
export type GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerStatusEnum = typeof GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerStatusEnum[keyof typeof GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerStatusEnum];
/**
 * @export
 */
export const GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerCsvExportStatusEnum = {
    InStockOverdue: 'IN_STOCK_OVERDUE',
    InStock: 'IN_STOCK',
    Incoming: 'INCOMING',
    InPreorder: 'IN_PREORDER',
    OnRequest: 'ON_REQUEST'
} as const;
export type GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerCsvExportStatusEnum = typeof GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerCsvExportStatusEnum[keyof typeof GetSalesOrderItemAvailabilitySummariesOfCurrentRetailerCsvExportStatusEnum];
