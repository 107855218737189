import { z } from "zod";

const EnvSchema = z.object({
  mode: z.enum(["development", "mock", "test", "production"]).optional().default("production"),

  commitHash: z.string().nonempty().nullable().optional(),
  buildDate: z
    .string()
    .optional()
    .transform((arg) => (arg ? new Date(arg) : new Date()))
    .pipe(z.date()),

  sentryDsn: z.string().optional(),
});

export const ENV = EnvSchema.parse({
  mode: import.meta.env.MODE,
  commitHash: import.meta.env.VITE_COMMIT_HASH,
  buildDate: import.meta.env.VITE_BUILD_DATE,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
});
