import { ActionIcon, Space, Stack, Text, Tooltip } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import React from "react";
import { Link } from "react-router-dom";
import { SalesOrderStatusTileSummary } from "../interface/SalesOrderStatusTileSummary";

import { useTranslation } from "react-i18next";
import { PeriodOption } from "~/common/enum/PeriodOption";
import { formatCurrency } from "~/helper/Formatter/formatCurrency";
import { createSalesOrdersPageLink } from "~/ui/routes/DynamicRoutes/createSalesOrdersPageLink";

export interface OpenOrdersChartSummaryProps {
  data: SalesOrderStatusTileSummary[];
  selectedIndex?: number;
}

const OpenOrdersChartSummary: React.FC<OpenOrdersChartSummaryProps> = (props) => {
  const { data, selectedIndex } = props;

  const { t } = useTranslation();

  const selectedItem = selectedIndex !== undefined ? data[selectedIndex] : undefined;

  const label = selectedItem?.label ?? t("dashboardPage.OpenOrderWidget.totalLabel");
  const quantitySum = selectedItem?.value ?? data.reduce((sum, current) => sum + current.value, 0);
  const costSum =
    selectedItem?.totalCost ?? data.reduce((sum, current) => sum + current.totalCost, 0);

  const link = selectedItem?.linkId
    ? createSalesOrdersPageLink({
        createdPeriod: PeriodOption.ALL,
        availabilityStatus: selectedItem?.linkId,
      })
    : undefined;

  return (
    <Stack gap={0} align={"center"} pt={"sm"}>
      <Text c={"dimmed"} fw="bold">
        {label}
      </Text>
      <Text fz={32} fw={"bold"} lh={1.2}>
        {quantitySum}
      </Text>
      <Text>({formatCurrency(costSum)})</Text>
      <Space h="xs" />
      {link && (
        <Tooltip
          withArrow
          label={t("dashboardPage.OpenOrderWidget.urlTooltip", {
            filter: label,
          })}
        >
          <ActionIcon component={Link} to={link} variant="outline">
            <IconChevronRight />
          </ActionIcon>
        </Tooltip>
      )}
    </Stack>
  );
};

export { OpenOrdersChartSummary };
