import { useQuery } from "@tanstack/react-query";
import { GetSalesOrderItemAvailabilitiesGroupByMonthOfCurrentRetailerRequest } from "~/api/openapi";

import { useRestAPI } from "~/api/useRestAPI";

const GetSalesOrderItemAvailabilityGroupByMonthQueryKey =
  "GetSalesOrderItemAvailabilityGroupByMonth";

export const useSalesOrderItemAvailabilitiesGroupByMonthQuery = (
  requestParameters: GetSalesOrderItemAvailabilitiesGroupByMonthOfCurrentRetailerRequest,
) => {
  const api = useRestAPI();

  return useQuery({
    queryKey: [GetSalesOrderItemAvailabilityGroupByMonthQueryKey, requestParameters],
    queryFn: () =>
      api.dashboard.getSalesOrderItemAvailabilitiesGroupByMonthOfCurrentRetailer(requestParameters),
  });
};
