import { Avatar, MantineSize, Tooltip } from "@mantine/core";

import { getProductImageAltText } from "~/helper/A11y/imageAltText";

import styles from "./AvatarWithTooltip.module.css";

interface AvatarWithToolTipProps {
  avatarImageUrl: string;
  avatarSize?: number | MantineSize;
  avatarName?: string;
}

const AvatarWithToolTip: React.FC<AvatarWithToolTipProps> = (props) => {
  const { avatarImageUrl, avatarName, avatarSize } = props;

  return (
    <Tooltip disabled={!avatarName} label={avatarName} withinPortal>
      <Avatar
        classNames={{ image: styles.image }}
        key={avatarImageUrl}
        src={avatarImageUrl}
        radius="xl"
        size={avatarSize}
        alt={getProductImageAltText(avatarName || "")}
      />
    </Tooltip>
  );
};

export { AvatarWithToolTip };
