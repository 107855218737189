import { useEffect } from "react";
import useHotjar from "react-use-hotjar";

import { useAppConfig } from "~/config/AppConfig";

type UseInitHotjarProps = {
  customerNumber: string;
  email: string;
  company: string;
  hasData: boolean;
};

let isLoaded = false;

export const useInitHotjar = (props: UseInitHotjarProps) => {
  const { customerNumber, email, company, hasData } = props;

  const {
    tracking: { hotjarId },
  } = useAppConfig();

  const { initHotjar, identifyHotjar } = useHotjar();

  useEffect(() => {
    if (!hotjarId || !hasData || isLoaded) return;

    isLoaded = true;
    initHotjar(hotjarId, 6, false);
    identifyHotjar(`${customerNumber}`, {
      email: email,
      company: company,
    });
  }, [hotjarId, hasData, email, company, customerNumber, identifyHotjar, initHotjar]);
};
