import { ActionIcon, Anchor, Divider, Group, Modal, Stack, Text, Title } from "@mantine/core";
import { useDisclosure, useLocalStorage } from "@mantine/hooks";
import { IconX } from "@tabler/icons-react";
import { isAfter } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";

import { useGetAnnouncements } from "~/api/hooks/announcement.hooks";

import { HtmlRenderer } from "../HtmlRenderer/HtmlRenderer";

export interface AnnouncementBannerProps {
  className?: string;
}

const allowedTags = [
  "br",
  "a",
  "b",
  "p",
  "span",
  "pre",
  "code",
  "div",
  "blockquote",
  "ul",
  "li",
  "ol",
  "strong",
  "img",
  "del",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
];

const allowedAttributes: string[] = ["href", "alt", "target", "src"];

const AnnouncementBanner: React.FC<AnnouncementBannerProps> = (props) => {
  const { className } = props;

  const { t } = useTranslation(undefined, { keyPrefix: "announcements" });
  const [opened, handle] = useDisclosure();

  const { data: announcements } = useGetAnnouncements();

  const [seenAnnouncementIds, setSeenAnnouncementIds] = useLocalStorage({
    key: "seen-announcements",
    defaultValue: [] as string[],
  });

  const updateSeenAnnouncements = (newId: string) => {
    setSeenAnnouncementIds((current) => {
      const exists = (id: string) => announcements?.find((it) => it.id === id);
      return [...current, newId].filter(exists);
    });
  };

  const unseenAnnouncements = announcements?.filter((it) => !seenAnnouncementIds?.includes(it.id));

  if (!unseenAnnouncements || unseenAnnouncements.length === 0) return null;

  // Get the announcement where the displayStart is closest to the present
  const latestUnseenAnnouncement = unseenAnnouncements.reduce((latestAnnouncement, announcement) =>
    isAfter(announcement.displayStart, latestAnnouncement.displayStart)
      ? announcement
      : latestAnnouncement,
  );

  const markLatestAnnouncementAsSeen = () => {
    updateSeenAnnouncements(latestUnseenAnnouncement.id);
  };

  const hasMore = latestUnseenAnnouncement.description !== "" || unseenAnnouncements.length > 1;

  return (
    <Group bg={"yellow.3"} py={"xs"} justify="center" className={className}>
      <Text ta="center" c={"black"} maw={900} lineClamp={1}>
        {latestUnseenAnnouncement.shortDescription}
      </Text>

      {hasMore && (
        <Anchor c="black" onClick={handle.open}>
          {t("moreInfo")}
        </Anchor>
      )}

      <ActionIcon
        size={"sm"}
        variant="transparent"
        color="black"
        onClick={markLatestAnnouncementAsSeen}
      >
        <IconX color={"black"} />
      </ActionIcon>

      <Modal opened={opened} onClose={handle.close} title={t("modalTitle")} size={"xl"}>
        <Stack>
          {announcements?.map((announcement, index) => (
            <Stack key={announcement.id}>
              {index > 0 && <Divider my={"md"} />}
              <Title order={4}>{announcement.shortDescription}</Title>
              <HtmlRenderer tags={allowedTags} attributes={allowedAttributes}>
                {announcement.description}
              </HtmlRenderer>
            </Stack>
          ))}
        </Stack>
      </Modal>
    </Group>
  );
};

export { AnnouncementBanner };
