/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiscountAgreement } from './DiscountAgreement';
import {
    DiscountAgreementFromJSON,
    DiscountAgreementFromJSONTyped,
    DiscountAgreementToJSON,
} from './DiscountAgreement';

/**
 * 
 * @export
 * @interface DiscountAgreements
 */
export interface DiscountAgreements {
    /**
     * 
     * @type {number}
     * @memberof DiscountAgreements
     */
    globalDiscount: number;
    /**
     * 
     * @type {Array<DiscountAgreement>}
     * @memberof DiscountAgreements
     */
    discountAgreements: Array<DiscountAgreement>;
}

/**
 * Check if a given object implements the DiscountAgreements interface.
 */
export function instanceOfDiscountAgreements(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "globalDiscount" in value;
    isInstance = isInstance && "discountAgreements" in value;

    return isInstance;
}

export function DiscountAgreementsFromJSON(json: any): DiscountAgreements {
    return DiscountAgreementsFromJSONTyped(json, false);
}

export function DiscountAgreementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountAgreements {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'globalDiscount': json['globalDiscount'],
        'discountAgreements': ((json['discountAgreements'] as Array<any>).map(DiscountAgreementFromJSON)),
    };
}

export function DiscountAgreementsToJSON(value?: DiscountAgreements | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'globalDiscount': value.globalDiscount,
        'discountAgreements': ((value.discountAgreements as Array<any>).map(DiscountAgreementToJSON)),
    };
}

