/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClientCredentials,
  CreateLiveClientRequestBody,
  CreateTestClientRequestBody,
  LiveClient,
  SandboxClient,
} from '../models';
import {
    ClientCredentialsFromJSON,
    ClientCredentialsToJSON,
    CreateLiveClientRequestBodyFromJSON,
    CreateLiveClientRequestBodyToJSON,
    CreateTestClientRequestBodyFromJSON,
    CreateTestClientRequestBodyToJSON,
    LiveClientFromJSON,
    LiveClientToJSON,
    SandboxClientFromJSON,
    SandboxClientToJSON,
} from '../models';

export interface CreateLiveClientRequest {
    createLiveClientRequestBody: CreateLiveClientRequestBody;
}

export interface CreateTestClientRequest {
    createTestClientRequestBody: CreateTestClientRequestBody;
}

export interface DeleteLiveClientRequest {
    clientId: string;
}

export interface DeleteTestClientRequest {
    clientId: string;
}

export interface RecreateLiveClientSecretRequest {
    clientId: string;
}

export interface RecreateTestClientSecretRequest {
    clientId: string;
}

/**
 * 
 */
export class ClientResourceApi extends runtime.BaseAPI {

    /**
     */
    async createLiveClientRaw(requestParameters: CreateLiveClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LiveClient>> {
        if (requestParameters.createLiveClientRequestBody === null || requestParameters.createLiveClientRequestBody === undefined) {
            throw new runtime.RequiredError('createLiveClientRequestBody','Required parameter requestParameters.createLiveClientRequestBody was null or undefined when calling createLiveClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/retailerapi/client/live`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLiveClientRequestBodyToJSON(requestParameters.createLiveClientRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LiveClientFromJSON(jsonValue));
    }

    /**
     */
    async createLiveClient(requestParameters: CreateLiveClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LiveClient> {
        const response = await this.createLiveClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createTestClientRaw(requestParameters: CreateTestClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SandboxClient>> {
        if (requestParameters.createTestClientRequestBody === null || requestParameters.createTestClientRequestBody === undefined) {
            throw new runtime.RequiredError('createTestClientRequestBody','Required parameter requestParameters.createTestClientRequestBody was null or undefined when calling createTestClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/ui/retailerapi/client/test`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTestClientRequestBodyToJSON(requestParameters.createTestClientRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SandboxClientFromJSON(jsonValue));
    }

    /**
     */
    async createTestClient(requestParameters: CreateTestClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SandboxClient> {
        const response = await this.createTestClientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteLiveClientRaw(requestParameters: DeleteLiveClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling deleteLiveClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailerapi/client/live/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteLiveClient(requestParameters: DeleteLiveClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLiveClientRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteTestClientRaw(requestParameters: DeleteTestClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling deleteTestClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailerapi/client/test/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteTestClient(requestParameters: DeleteTestClientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTestClientRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getLiveClientRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LiveClient>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailerapi/client/live`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LiveClientFromJSON(jsonValue));
    }

    /**
     */
    async getLiveClient(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LiveClient> {
        const response = await this.getLiveClientRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getTestClientsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SandboxClient>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailerapi/client/test`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SandboxClientFromJSON));
    }

    /**
     */
    async getTestClients(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SandboxClient>> {
        const response = await this.getTestClientsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async recreateLiveClientSecretRaw(requestParameters: RecreateLiveClientSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientCredentials>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling recreateLiveClientSecret.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailerapi/client/live/{clientId}/recreate-secret`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientCredentialsFromJSON(jsonValue));
    }

    /**
     */
    async recreateLiveClientSecret(requestParameters: RecreateLiveClientSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientCredentials> {
        const response = await this.recreateLiveClientSecretRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async recreateTestClientSecretRaw(requestParameters: RecreateTestClientSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientCredentials>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling recreateTestClientSecret.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/retailerapi/client/test/{clientId}/recreate-secret`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientCredentialsFromJSON(jsonValue));
    }

    /**
     */
    async recreateTestClientSecret(requestParameters: RecreateTestClientSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientCredentials> {
        const response = await this.recreateTestClientSecretRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
