/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MiddlewareError,
  StoreProfileMasterData,
} from '../models';
import {
    MiddlewareErrorFromJSON,
    MiddlewareErrorToJSON,
    StoreProfileMasterDataFromJSON,
    StoreProfileMasterDataToJSON,
} from '../models';

export interface GetStoreProfileMasterdataRequest {
    acceptLanguage?: string;
}

/**
 * 
 */
export class StoreProfileMasterDataResourceApi extends runtime.BaseAPI {

    /**
     */
    async getStoreProfileMasterdataRaw(requestParameters: GetStoreProfileMasterdataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoreProfileMasterData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
            headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
        }

        const response = await this.request({
            path: `/api/v1/ui/store-profile/masterdata`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoreProfileMasterDataFromJSON(jsonValue));
    }

    /**
     */
    async getStoreProfileMasterdata(requestParameters: GetStoreProfileMasterdataRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoreProfileMasterData> {
        const response = await this.getStoreProfileMasterdataRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
