/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoreProfilePreviewAddress
 */
export interface StoreProfilePreviewAddress {
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewAddress
     */
    houseNumber: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewAddress
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewAddress
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewAddress
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewAddress
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewAddress
     */
    geoLat?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewAddress
     */
    geoLon?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewAddress
     */
    placeId?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewAddress
     */
    additional: string;
}

/**
 * Check if a given object implements the StoreProfilePreviewAddress interface.
 */
export function instanceOfStoreProfilePreviewAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "houseNumber" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "zip" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "additional" in value;

    return isInstance;
}

export function StoreProfilePreviewAddressFromJSON(json: any): StoreProfilePreviewAddress {
    return StoreProfilePreviewAddressFromJSONTyped(json, false);
}

export function StoreProfilePreviewAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfilePreviewAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'houseNumber': json['houseNumber'],
        'street': json['street'],
        'zip': json['zip'],
        'city': json['city'],
        'country': json['country'],
        'countryCode': json['countryCode'],
        'geoLat': !exists(json, 'geoLat') ? undefined : json['geoLat'],
        'geoLon': !exists(json, 'geoLon') ? undefined : json['geoLon'],
        'placeId': !exists(json, 'placeId') ? undefined : json['placeId'],
        'additional': json['additional'],
    };
}

export function StoreProfilePreviewAddressToJSON(value?: StoreProfilePreviewAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'houseNumber': value.houseNumber,
        'street': value.street,
        'zip': value.zip,
        'city': value.city,
        'country': value.country,
        'countryCode': value.countryCode,
        'geoLat': value.geoLat,
        'geoLon': value.geoLon,
        'placeId': value.placeId,
        'additional': value.additional,
    };
}

