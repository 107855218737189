import { useAppConfig } from "~/config/AppConfig";

/**
 * Checks current domain with the configured domain in the config.json.
 * If they do not match the app will redirect to the configured domain, keeping the rest of the url in tact.
 */
export const useDomainRedirect = () => {
  const config = useAppConfig();

  if (window.location.hostname !== config.domain) {
    window.location.hostname = config.domain;
  }
};
