/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  StoreProfileImageMetaData,
} from '../models';
import {
    StoreProfileImageMetaDataFromJSON,
    StoreProfileImageMetaDataToJSON,
} from '../models';

export interface DownloadStoreProfileImageRequest {
    uuidWithFileending: string;
}

export interface UploadStoreProfileImageRequest {
    file: Blob;
}

/**
 * 
 */
export class StoreProfileImageResourceApi extends runtime.BaseAPI {

    /**
     */
    async downloadStoreProfileImageRaw(requestParameters: DownloadStoreProfileImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.uuidWithFileending === null || requestParameters.uuidWithFileending === undefined) {
            throw new runtime.RequiredError('uuidWithFileending','Required parameter requestParameters.uuidWithFileending was null or undefined when calling downloadStoreProfileImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/store-profile/image/{uuidWithFileending}`.replace(`{${"uuidWithFileending"}}`, encodeURIComponent(String(requestParameters.uuidWithFileending))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async downloadStoreProfileImage(requestParameters: DownloadStoreProfileImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.downloadStoreProfileImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async uploadStoreProfileImageRaw(requestParameters: UploadStoreProfileImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoreProfileImageMetaData>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadStoreProfileImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/v1/ui/store-profile/image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoreProfileImageMetaDataFromJSON(jsonValue));
    }

    /**
     */
    async uploadStoreProfileImage(requestParameters: UploadStoreProfileImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoreProfileImageMetaData> {
        const response = await this.uploadStoreProfileImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
