import { useMutation } from "@tanstack/react-query";

import { RequestOptions } from "../interface/RequestOptions";
import { PostFeedbackRequest } from "../openapi";
import { useRestAPI } from "../useRestAPI";

export const useCreateFeedbackMutation = (options: RequestOptions) => {
  const api = useRestAPI();
  return useMutation({
    mutationFn: (parameters: PostFeedbackRequest) => api.feedback.postFeedback(parameters),
    ...options,
  });
};
