/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientCredentials
 */
export interface ClientCredentials {
    /**
     * 
     * @type {string}
     * @memberof ClientCredentials
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCredentials
     */
    secret: string;
}

/**
 * Check if a given object implements the ClientCredentials interface.
 */
export function instanceOfClientCredentials(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "secret" in value;

    return isInstance;
}

export function ClientCredentialsFromJSON(json: any): ClientCredentials {
    return ClientCredentialsFromJSONTyped(json, false);
}

export function ClientCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientCredentials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'secret': json['secret'],
    };
}

export function ClientCredentialsToJSON(value?: ClientCredentials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'secret': value.secret,
    };
}

