import { ProductClaimReasonType } from "~/ui/pages/ProductClaims/CreateClaimsPage/ClaimsDetailsStep/ClaimsDetailGroup/ClaimsDetailForm/createClaimDetailItems";

export const CreateClaimsPageDE = {
  title: "Neue Reklamation",
  submitClaims_one: "{{ count }} Reklamation abschicken",
  submitClaims_other: "{{ count }} Reklamationen abschicken",
  submitProgressTitle: "Reklamationen werden gespeichert",
  submitProgressMessage: "{{ count }}/{{ total }} gespeichert.",
  submitFailMessage_one: "{{ count }} Reklamation konnte nicht gespeichert werden.",
  submitFailMessage_other: "{{ count }} Reklamationen konnten nicht gespeichert werden.",
  submitSuccess_one: "{{ count }}/{{ total }} Reklamation wurde erfolgreich erstellt.",
  submitSuccess_other: "{{ count }}/{{ total }} Reklamationen wurden erfolgreich erstellt.",
  submitClaimsDisabledTooltip: "Bitte gib zuerst alle Pflichtangaben an, um fortzufahren.",
  selectClaimsDisabledTooltip: "Bitte wähle einen oder mehrere Artikel aus, um fortzufahren.",
  selectClaimsDisabledLabel: "Noch keine Artikel ausgewählt",
  selectProducts: {
    claimInfoModalTitel: "Reklamationshinweis",
    backToClaimsPageConfirmTitle: "Reklamationserstellung wirklich abbrechen?",
    backToClaimsPageConfirmInfoText:
      "Bist du sicher, dass du zur Reklamationsübersicht zurückkehren willst? Dadurch gehen alle bisher eingegebenen Daten verloren.",
    backToClaimsPageConfirmLabel: "Reklamation abbrechen",
    backToClaimsPageCancelLabel: "Abbrechen",
    stepTitle: "Artikel auswählen",
    stepDescription: "{{ count }} Artikel ausgewählt",
    claimableProducts: "Wähle die Artikel aus, die du reklamieren möchtest:",
    noClaimableProductsFound:
      "Es wurden keine reklamierbaren Artikel gefunden, die deinen Suchkriterien entsprechen.",
    noClaimableProductsAvailable: "Keine reklamierbaren Artikel verfügbar.",
    filter: {
      timePeriod: {
        lastTwoWeeks: "Rechnungen der letzten 14 Tage",
      },
      title: "Filtern & Sortieren",
      searchPlaceholder: "Reklamierbare Artikel durchsuchen (min. 3 Zeichen)",
    },
    card: {
      invoice: "Rechnung",
      price: "Einzelpreis",
      quantity: "Menge",
      customerItemReference: "Artikelreferenz",
      alreadyClaimdCount: "Bereits reklamiert",
    },
    sort: {
      productNumber: "Artikel-Nr.",
      invoiceNumber: "Rechnungs-Nr.",
      priceAndDiscount: "Einzelpreis",
      quantity: "Menge",
      claimedQuantity: "Reklamiert",
      customerItemReference: "Artikelreferenz",
    },
    table: {
      invoiceOneClickTooltip: "Liste nach Rechnung {{invoiceNumber}} durchsuchen",
      head: {
        product: "Artikel",
        order: "Bestellung",
        invoice: "Rechnung",
        pricePerUnit: "Einzelpreis",
        quantity: "Menge",
        claimedQuantity: "Reklamiert",
        customerItemReference: "Artikelreferenz",
        inclDiscount: "inkl. {{ discount }} Rabatt",
      },
    },
  },
  claimDetails: {
    card: {
      claimCount: "{{count}}/{{remainingClaimableQuantity}}",
      invoiceNumber: "Rechnung: {{ invoiceNumber }}",
      alreadyClaimed: "Bereits reklamiert",
    },
    stepTitle: "Reklamationsdetails eingeben",
    stepTitleMobile: "Details eingeben",

    articleNumber: "Artikel-Nr.: {{ productNumber }}",
    invoiceNumber: "Rechnungsnummer",
    unitPrice: "Einzelpreis",
    deliveredQuantity: "Gelieferte Menge",
    allreadyClaimed: " (davon {{count}} Artikel bereits reklamiert)",
    inclDiscount: "(inkl. {{ discount }} Rabatt)",
    claimCartInfo: {
      title: "Zusammenfassung",
      description: "Du hast folgende Artikel zur Reklamation ausgewählt:",
      invoiceNumber: "R.-Nr.: {{ invoiceNumber }}",
    },
    claimInfo: {
      title: "Reklamation bei heo",
      text1:
        "Ein Artikel ist defekt, beschädigt oder erfüllt nicht die versprochene Qualität? Es wurde ein falscher Artikel geliefert? Die Verpackung des Artikel ist beschädigt?",
      text2:
        "Es tut uns leid das zu hören. Mit diesem Online-Formular kannst du Reklamationen schnell und bequem an uns übermitteln. Wir finden anschließend eine passende Lösung für dich!",
      generalTitle: "Allgemeine Hinweise",
      generalInfo1: {
        title: "1. Welche Artikel kann ich reklamieren?",
        text: "Innerhalb von 12 Monaten ab Kaufdatum können alle Artikel, die einen Mangel aufweisen reklamiert werden. Voraussetzung ist, diesen Mangel für uns bestmöglich zu dokumentieren. Dabei hilft dir dieses Online-Formular.",
      },
      generalInfo2: {
        title: "2. Was ist mit Artikeln, die mir schon vor mehr als 12 Monaten geliefert wurden?",
        text: "Nach unseren AGB sind Artikel, deren Lieferung länger als 12 Monate zurückliegt, von einer Reklamation ausgeschlossen. Hier ist nur im Kulanzfall eine Reklamation möglich.",
      },
      generalInfo3: {
        title: "3. Wie geht es nach Ihrer Reklamation weiter?",
        text: "Die Reklamation wird in der Regel innerhalb von 14 Tagen von uns bearbeitet. Der aktuelle Status einer Reklamation kann jederzeit in der Reklamationsübersicht eingesehen werden. Über alle wesentlichen Aspekte der Reklamationen wird zudem per E-Mail informiert.",
      },
    },
    form: {
      title: "# {{ index }}",
      serialNumberInputLabel: "Seriennummer",
      serialNumberInputPlaceholder: {
        default: "Seriennummer eingeben (falls vorhanden)",
        wronglyOrdered: "Falsche Seriennummer des gelieferten Artikels eingeben",
      } as Record<string, string>,
      claimReasonSelectLabel: "Reklamationsgrund",
      claimReasonSelectPlaceholder: "Wähle einen Grund",
      claimReasonSelectNoReason: "Noch kein Grund ausgewählt",
      preferredSettlementSelectLabel: "Bearbeitungspräferenz",
      preferredSettlementSelectPlaceholder: "Wähle eine Bearbeitungspräferenz",
      overdeliverLabel: "Anzahl zu viel gelieferte Exemplare",
      underdeliverLabel: "Anzahl zu wenig gelieferte Exemplare",
      commentTextareaLabel: "Beschreibung",
      commentTextarea: {
        label: "",
        placeholder: {
          DAMAGED_PACKAGING: "Detaillierte Beschreibung eingeben",
          DAMAGED_PRODUCT: "Detaillierte Beschreibung eingeben",
          INFERIOR_QUALITY: "Detaillierte Beschreibung eingeben",
          MISSING_PARTS: "Detaillierte Beschreibung eingeben",
          OVERDELIVERED: "Detaillierte Beschreibung eingeben",
          UNDERDELIVERED: "Detaillierte Beschreibung eingeben",
          WRONGLY_ORDERED: "Detaillierte Beschreibung eingeben",
          WRONG_SERIAL_NUMBER: "Detaillierte Beschreibung eingeben",
          WRONGLY_DELIVERED: "Detaillierte Beschreibung eingeben",
        } as Record<ProductClaimReasonType, string>,
      },
      uploadFilesLabel: "Foto-Upload {{ size }}/{{ max }}MB",
      missingFiles: "Es muss mindestens 1 Foto hochgeladen werden.",
      maxFileSizeExceeded: "Es sind maximal 20MB erlaubt!",
    },
    tutorials: {
      DAMAGED_PACKAGING: {
        accordionTitle: "Beschädigte Verpackung (DAP)",
        description:
          "Wenn du ein beschädigtes Produkt erhältst, brauchen wir zwei Bilder von dir. Das erste Bild sollte das gesamte beschädigte Produkt zeigen und das zweite Bild sollte einen genaueren Blick auf die Beschädigung werfen. Es wäre hilfreich, wenn du auf dem zweiten Bild den Bereich markierst, wo die Beschädigung zu sehen ist. Wenn du das nicht machen kannst, dann schreibe bitte eine Beschreibung in das Kommentarfeld.",
      },
      DAMAGED_PRODUCT: {
        accordionTitle: "Beschädigtes Artikel (DEF)",
        description:
          "Wenn du ein defektes oder fehlerhaftes Produkt erhältst, brauchen wir zwei Bilder von dir. Das erste Bild sollte das ganze Produkt zeigen und das zweite Bild sollte einen genaueren Blick auf die Beschädigung oder den Fehler werfen. Es wäre hilfreich, wenn du auf dem zweiten Bild den Bereich markierst, wo die Beschädigung oder der Fehler zu sehen ist. Manchmal ist es besser, den beschädigten oder fehlerhaften Artikel aus der Verpackung zu nehmen, um das Problem besser erkennen zu können. Wenn das nicht möglich ist, dann schreibe bitte eine Beschreibung des Problems in das Kommentarfeld.",
      },
      INFERIOR_QUALITY: {
        accordionTitle: "Qualität stimmt nicht",
        description:
          "Wenn du ein Produkt mit schlechter Qualität oder einem offensichtlichen Mangel erhältst (zum Beispiel sieht es anders aus als beschrieben oder hat Farbfehler), dann brauchen wir zwei Bilder von dir. Das erste Bild sollte den Fehler am Produkt zeigen und das zweite Bild sollte einen genaueren Blick auf den Mangel werfen. Es wäre hilfreich, wenn du auf dem zweiten Bild den Bereich markierst, wo der Mangel zu sehen ist. Wenn du das nicht machen kannst, dann schreibe bitte eine Beschreibung des Mangels in das Kommentarfeld.",
      },
      MISSING_PARTS: {
        accordionTitle: "Artikel unvollständig",
        description:
          "Wenn du einen unvollständigen Artikel erhältst, bei dem zum Beispiel Teile innerhalb der Verpackung fehlen oder separat verpackte Zusatzteile nicht im Paket enthalten sind, dann brauchen wir zwei Bilder von dir. Das erste Bild sollte den Artikel als Ganzes zeigen und das zweite Bild sollte einen genaueren Blick auf den leeren Verpackungsbereich werfen. Wenn die fehlenden Teile separat verpackt waren, dann reicht ein Bild des gesamten Artikels. Wenn du kein Bild machen kannst, dann schreibe bitte eine Beschreibung der fehlenden oder zu ersetzenden Teile in das Kommentarfeld.",
      },
      OVERDELIVERED: {
        accordionTitle: "Überlieferung",
        description:
          "Wenn du feststellst, dass du versehentlich zu viel von einem Artikel erhalten hast, nenne uns die Artikel- und Rechnungsnummer, sowie die Menge, die du zu viel erhalten hast. Eine Beschreibung reicht aus, Bilder müssen nicht gesendet werden.",
      },
      UNDERDELIVERED: {
        accordionTitle: "Unterlieferung",
        description:
          "Wenn du feststellst, dass du versehentlich zu wenig von einem Artikel erhalten hast, nenne uns die Artikel- und Rechnungsnummer sowie, die Menge, die du zu wenig erhalten hast. Eine Beschreibung reicht aus, Bilder müssen nicht gesendet werden.",
      },
      WRONGLY_ORDERED: {
        accordionTitle: "Falsch bestellt",
        description:
          "Wenn du feststellst, dass du versehentlich einen falschen Artikel erhalten hast, nenne uns die Artikel- und Rechnungsnummer. Eine Beschreibung reicht aus, Bilder müssen nicht gesendet werden.",
      },
      WRONGLY_DELIVERED: {
        accordionTitle: "Falsch gelieferte Artikel",
        description:
          "Wenn du aus Versehen einen falschen Artikel erhalten hast, hast du zwei Optionen. Du kannst den Artikel zurücksenden und erhältst von uns ein kostenloses Versandlabel. Es muss in der Reklamation lediglich der Artikel gewählt sein, den du nicht erhalten hast. Alternativ kannst du den Artikel aber auch behalten und separat kaufen, wenn du diese Option wählst, stellen wir dir einfach eine separate Rechnung dazu aus. In jedem Fall wird aber der fehlende Artikel noch nach geliefert werden.",
      },
      WRONG_SERIAL_NUMBER: {
        accordionTitle: "Falsche Seriennummer",
        description:
          "Wenn die Seriennummern des gelieferten Artikels nicht übereinstimmen, benötigen wir ein Foto von allen gelieferten Verpackungen oder des Artikels selbst und ein Detailfoto von den Seriennummern. Eine Beschreibung des Problems kann helfen, ist aber nicht unbedingt erforderlich.",
      },
    } as Record<ProductClaimReasonType, Record<string, string>>,
    allDetailsValid: "Alle Details angegeben!",
    missingDetails: "Notwendige Angaben fehlen noch",
    untouchedDetails: "Einige Angaben fehlen noch.",
    deleteClaimConfirmTitle: "Willst du die Reklamation wirklich löschen?",
    deleteClaimConfirmLabel: "Reklamation löschen",
    deleteClaimCancelLabel: "Abbrechen",
    deleteClaimGroupConfirmTitle: "Artikel aus Reklamation entfernen",
    deleteClaimGroupConfirmDescription_other:
      "Willst du den Artikel <bold><italic>{{ productName }}</italic></bold> wirklich entfernen? Dadurch gehen {{ count }} vorbereitete Reklamationen verloren.",
    deleteClaimGroupConfirmDescription_one:
      "Willst du den Artikel <bold><italic>{{ productName }}</italic></bold> wirklich entfernen? Dadurch geht {{ count }} vorbereitete Reklamation verloren.",
    deleteClaimGroupConfirmLabel: "Artikel entfernen",
    deleteClaimGroupCancelLabel: "Abbrechen",
    addClaimClone: "Weiteres Exemplar mit gleichen Angaben reklamieren",
    addClaim: "Weiteres Exemplar reklamieren",
    continueButtonLabel: "Weiter",
    continueButtonWithCountLabel: "{{count}} Artikel reklamieren",

    backButtonLabel: "Zurück",
    backToClaimsPage: "Zurück zur Reklamationsübersicht",
  },
  confirmClaim: {
    stepTitle: "Daten überprüfen & abschicken",
    continueButtonLabel: "Weiter zur Überprüfung",
  },
};
