import { Avatar, Box, Center, Text } from "@mantine/core";

import { useElementSize } from "@mantine/hooks";
import { useMemo } from "react";
import { AvatarWithToolTip } from "./AvatarWithTooltip";

interface AvatarGroupItem {
  imageUrl: string;
  name?: string;
}

interface AvatarGroupProps {
  avatarItems: AvatarGroupItem[];
  avatarSize?: number;
  max?: number;
  centered?: boolean;
}

const DEFAULT_AVATAR_SIZE = 48;

const AvatarGroup: React.FC<AvatarGroupProps> = (props) => {
  const { max, avatarItems, avatarSize = DEFAULT_AVATAR_SIZE, centered } = props;

  const { ref, width } = useElementSize();
  const availableSpace = Math.floor((width || avatarSize) / avatarSize) - 1;
  const maxItems = max ? Math.min(availableSpace, max) : availableSpace;

  const endIndex = useMemo(
    () => (avatarItems.length === maxItems + 1 ? maxItems + 1 : maxItems),
    [maxItems, avatarItems.length],
  );

  const items = useMemo(() => {
    return avatarItems
      .slice(0, endIndex)
      .map((item) => (
        <AvatarWithToolTip
          avatarImageUrl={item.imageUrl}
          avatarName={item.name}
          avatarSize={avatarSize}
          key={item.imageUrl}
        />
      ));
  }, [avatarItems, avatarSize, endIndex]);

  const showOverflowIndicator = avatarItems.length > endIndex;

  const content = (
    <Avatar.Group spacing="xs">
      {items}
      {showOverflowIndicator && (
        <Avatar radius="xl" size={avatarSize}>
          <Text fz={"md"} fw={"bold"}>
            +{avatarItems.length - maxItems}
          </Text>
        </Avatar>
      )}
    </Avatar.Group>
  );

  return centered ? (
    <Center w={"100%"} ref={ref}>
      {content}
    </Center>
  ) : (
    <Box w={"100%"} ref={ref}>
      {content}
    </Box>
  );
};

export { AvatarGroup };
