import { SalesOrdersPageDE } from "../de/de.salesOrdersPage";

export const SalesOrdersPageEN: typeof SalesOrdersPageDE = {
  pageTitle: "My backorders",
  title: "My backorders ({{ count }})",

  filter: {
    title: "Filter & Sort",
    statusPlaceholder: "Select status",
    searchPlaceholder: "Search backorders (at least 3 characters)",
    statusTiles: {
      overdueOfWhich: "of which",
      total: "total",
    },
    availabilityPlaceholder: "Select via availability",
    nothingFound: "No results found",
    manufacturerSelectAll: "All manufacturers",
    productTypeSelectAll: "All product types",
    createdAtTitle: "Date",
    manufacturersTitle: "Manufacturer",
    manufacturersSearch: "Filter for manufacturers",
    typesTitle: "Product types",
    typesSearch: "Filter for types",
    availabilityTitle: "ETA",
    statusTitle: "Status",
  },

  sort: {
    product: "Article",
    productManufacturers: "Manufacturer",
    productTypesThemes: "Product type",
    customerOrderNumber: "Order number",
    orderReceivedAt: "Received on",
    availabilityStatus: "Status",
    amount: "Quantity",
    priceAndDiscount: "Unit price",
    discount: "Discount",
    totalPrice: "Total amount",
    availability: "ETA",
    customerReference: "Order reference",
    customerItemReference: "Item reference",
    gtin: "GTIN",
    infoToCustomer: "Additional information",
  },
  card: {
    noCustomerOrderNumber: "No Customer Order-No available",
    customerOrderNumber: "Order number",
    availabilityStatus: "Status",
    amount: "Quantity",
    amountValue: "{{amount}} Pieces",
    price: "Unit price",
    priceValue: "{{price}} (incl. {{discount}}% discount)",
    totalPrice: "Total amount",
    availability: "ETA",
    customerReference: "Order reference",
    customerItemReference: "Item reference",
    infoToCustomer: "Additional information",
  },
  table: {
    noSalesOrdersAvailable: "No backorders available.",
    noSalesOrdersFound: "No backorders found that match your search criteria.",
    productOneClickTooltip: "Search list for item {{productNumber}}",
    header: {
      product: "Article",
      productManufacturers: "Manufacturer",
      productTypes: "Product type",
      customerOrderNumber: "Order number",
      orderReceivedAt: "Received on",
      status: "Status",
      amount: "Quantity",
      pricePerUnit: "Unit price",
      discount: "Discount",
      total: "Total amount",
      availability: "ETA",
      customerReference: "Order reference",
      customerItemReference: "Item reference",
      gtin: "GTIN",
      infoToCustomer: "Additional information",
    },
    body: {
      amount: "{{ ofStatus }} of {{ total }}",
      lastGoodsReceiptAt: "Incoming goods:",
      noLastGoodsReceiptAt: "No goods receipt",
      inclDiscount: "incl. {{ discount }} discount",
    },
  },
  csv: {
    downloadFileName: "heo-csv-export-backorders_{{date}}",
    tooltipDownloadButton: "CSV export (data from search result)",
    productNumber: "Item number",
    gtin: "GTIN",
    productName: "Item name",
    productManufacturers: "Manufacturer",
    productTypes: "Product type",
    productThemes: "Product theme",
    customerOrderNumber: "Order number",
    orderReceivedAt: "Received on",
    status: "Status",
    lastGoodsReceiptAt: "Goods received date",
    amount: "Quantity",
    pricePerUnit: "Unit price (incl. discount)",
    discount: "Discount",
    total: "Total amount",
    availability: "ETA",
    customerReference: "Order reference",
    customerItemReference: "Item reference",
    infoToCustomer: "Add. information",
  },
};
