import {
  differenceInDays,
  format,
  formatRelative,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";
import { de, enUS, es, fr, it, pl } from "date-fns/locale";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export enum DateStyle {
  /**
   * 21. Aug 2022
   */
  DEFAULT = "DEFAULT",

  /**
   * 21.08.2022 /
   */
  SHORT = "SHORT",

  /**
   * 21. Aug 2022 13:07 Uhr / 21. Aug 2022 1:07pm
   */
  DEFAULT_WITH_TIME = "DEFAULT_WITH_TIME",

  /**
   * 21.08.2022 13:07 Uhr / 21.08.2022 1:07pm
   */
  SHORT_WITH_TIME = "SHORT_WITH_TIME",

  /**
   * 13:07 Uhr / 1:07pm
   */
  ONLY_TIME = "ONLY_TIME",

  /**
   * 02/2022
   */
  MONTH_YEAR_SLASHED = "MONTH_YEAR_SLASHED",

  /**
   * Januar 2022
   */
  MONTH_YEAR_LONG = "MONTH_YEAR_LONG",

  /**
   * 2023-05-12
   */
  YEAR_MONTH_DAY = "YEAR_MONTH_DAY",
}

const dateFormats = {
  [DateStyle.DEFAULT]: "PP",
  [DateStyle.SHORT]: "P",
  [DateStyle.DEFAULT_WITH_TIME]: "PP p 'px'",
  [DateStyle.SHORT_WITH_TIME]: "P p 'px'",
  [DateStyle.ONLY_TIME]: "p 'px'",
  [DateStyle.MONTH_YEAR_SLASHED]: "MM/yyyy",
  [DateStyle.MONTH_YEAR_LONG]: "MMMM yyyy",
  [DateStyle.YEAR_MONTH_DAY]: "yyyy-MM-dd",
};

const localeMap = {
  de: de,
  en: enUS,
  fr: fr,
  it: it,
  pl: pl,
  es: es,
};

export type FormatDate = (date: string | Date, format?: DateStyle | string) => string;

export type FormatRelativeProps = {
  date: string | Date;
};

export const useLocalizedDateFormatter = () => {
  const { i18n, t } = useTranslation();

  const locale =
    i18n.language in localeMap ? localeMap[i18n.language as keyof typeof localeMap] : de;

  // date-fns already formats time with am/pm for english
  const timeSuffix = i18n.language === "en" ? "" : t("time.suffix");

  const _formatDate: FormatDate = useCallback(
    (date, dateFormat = DateStyle.DEFAULT) => {
      const targetFormat =
        dateFormat in dateFormats
          ? dateFormats[dateFormat as keyof typeof dateFormats]
          : dateFormat;

      let formattedDate = format(new Date(date), targetFormat, { locale });
      formattedDate = formattedDate.replace("px", timeSuffix);

      return formattedDate.trim();
    },
    [locale, timeSuffix],
  );

  const _formatRelative = useCallback(
    (props: FormatRelativeProps) => {
      const targetDate = new Date(props.date);
      const compareDate = new Date();

      // date-fns formatRelative only shows time for time differences < 7 days so we add the time suffix then
      const hasTime = Math.abs(differenceInDays(targetDate, compareDate)) < 7;
      const suffix = hasTime ? ` ${timeSuffix}` : "";

      const formatRelativeLocale = {
        lastWeek: t("date.lastWeek"),
        yesterday: t("date.yesterday"),
        today: t("date.today"),
        tomorrow: t("date.tomorrow"),
        nextWeek: "eeee ',' p",
        other: "P",
      };

      type DataType = keyof typeof formatRelativeLocale;

      return (
        formatRelative(targetDate, compareDate, {
          locale: { ...locale, formatRelative: (token: DataType) => formatRelativeLocale[token] },
        }) + suffix
      ).trim();
    },
    [locale, timeSuffix, t],
  );

  const formatTimeString = useCallback(
    (time: string) => {
      const [hoursString, minutesString, secondsString] = time.split(":");
      let timeDate = new Date();

      const hours = Number(hoursString);
      const minutes = Number(minutesString);
      const seconds = Number(secondsString);

      if (!Number.isNaN(hours)) {
        timeDate = setHours(timeDate, hours);
      }

      if (!Number.isNaN(minutes)) {
        timeDate = setMinutes(timeDate, minutes);
      }

      if (!Number.isNaN(seconds)) {
        timeDate = setSeconds(timeDate, seconds);
      }

      return _formatDate(timeDate, dateFormats[DateStyle.ONLY_TIME]);
    },
    [_formatDate],
  );

  return { formatDate: _formatDate, formatRelative: _formatRelative, formatTimeString };
};
