import { useGetAccountDetailsQuery } from "~/api/hooks/account/useGetAccountDetailsQuery";
import { useGetCurrentRetailerQuery } from "~/api/hooks/retailer/useGetCurrentRetailerQuery";
import { useInitCrispChat } from "./useInitCrispChat";
import { useInitHotjar } from "./useInitHotjar";

export const useCrispAndHotjar = () => {
  const { data: accountQueryData, isFetched: accountQueryFetched } = useGetAccountDetailsQuery();
  const { data: retailerData, isFetched: retailerDataFetched } = useGetCurrentRetailerQuery();

  const hasData =
    accountQueryFetched &&
    retailerDataFetched &&
    accountQueryData !== undefined &&
    retailerData !== undefined;

  const companyName = retailerData?.companyName || "";
  const customerNumber = `${retailerData?.customerNumber.number}`;
  const email = accountQueryData?.email || "";
  const name = accountQueryData?.contactDetails.name.fullName || "";

  useInitHotjar({
    company: companyName,
    customerNumber: customerNumber,
    email: email,
    hasData: hasData,
  });

  useInitCrispChat({
    company: companyName,
    customerNumber: customerNumber,
    email: email,
    name: name,
    hasData: hasData,
  });
};
