/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeoCustomerNumber } from './HeoCustomerNumber';
import {
    HeoCustomerNumberFromJSON,
    HeoCustomerNumberFromJSONTyped,
    HeoCustomerNumberToJSON,
} from './HeoCustomerNumber';
import type { HeoTransactionNumber } from './HeoTransactionNumber';
import {
    HeoTransactionNumberFromJSON,
    HeoTransactionNumberFromJSONTyped,
    HeoTransactionNumberToJSON,
} from './HeoTransactionNumber';
import type { ProductNumberThumbnail } from './ProductNumberThumbnail';
import {
    ProductNumberThumbnailFromJSON,
    ProductNumberThumbnailFromJSONTyped,
    ProductNumberThumbnailToJSON,
} from './ProductNumberThumbnail';
import type { TrackingShipmentProjection } from './TrackingShipmentProjection';
import {
    TrackingShipmentProjectionFromJSON,
    TrackingShipmentProjectionFromJSONTyped,
    TrackingShipmentProjectionToJSON,
} from './TrackingShipmentProjection';

/**
 * 
 * @export
 * @interface CurrentInvoiceSummary
 */
export interface CurrentInvoiceSummary {
    /**
     * 
     * @type {string}
     * @memberof CurrentInvoiceSummary
     */
    id: string;
    /**
     * 
     * @type {HeoTransactionNumber}
     * @memberof CurrentInvoiceSummary
     */
    number: HeoTransactionNumber;
    /**
     * 
     * @type {HeoCustomerNumber}
     * @memberof CurrentInvoiceSummary
     */
    customerNumber: HeoCustomerNumber;
    /**
     * 
     * @type {string}
     * @memberof CurrentInvoiceSummary
     */
    cityShippedTo: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentInvoiceSummary
     */
    zipCodeShippedTo: string;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentInvoiceSummary
     */
    filed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentInvoiceSummary
     */
    canceled: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CurrentInvoiceSummary
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<TrackingShipmentProjection>}
     * @memberof CurrentInvoiceSummary
     */
    shipments: Array<TrackingShipmentProjection>;
    /**
     * 
     * @type {Array<ProductNumberThumbnail>}
     * @memberof CurrentInvoiceSummary
     */
    productItems: Array<ProductNumberThumbnail>;
}

/**
 * Check if a given object implements the CurrentInvoiceSummary interface.
 */
export function instanceOfCurrentInvoiceSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "customerNumber" in value;
    isInstance = isInstance && "cityShippedTo" in value;
    isInstance = isInstance && "zipCodeShippedTo" in value;
    isInstance = isInstance && "filed" in value;
    isInstance = isInstance && "canceled" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "shipments" in value;
    isInstance = isInstance && "productItems" in value;

    return isInstance;
}

export function CurrentInvoiceSummaryFromJSON(json: any): CurrentInvoiceSummary {
    return CurrentInvoiceSummaryFromJSONTyped(json, false);
}

export function CurrentInvoiceSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentInvoiceSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': HeoTransactionNumberFromJSON(json['number']),
        'customerNumber': HeoCustomerNumberFromJSON(json['customerNumber']),
        'cityShippedTo': json['cityShippedTo'],
        'zipCodeShippedTo': json['zipCodeShippedTo'],
        'filed': json['filed'],
        'canceled': json['canceled'],
        'createdAt': (new Date(json['createdAt'])),
        'shipments': ((json['shipments'] as Array<any>).map(TrackingShipmentProjectionFromJSON)),
        'productItems': ((json['productItems'] as Array<any>).map(ProductNumberThumbnailFromJSON)),
    };
}

export function CurrentInvoiceSummaryToJSON(value?: CurrentInvoiceSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': HeoTransactionNumberToJSON(value.number),
        'customerNumber': HeoCustomerNumberToJSON(value.customerNumber),
        'cityShippedTo': value.cityShippedTo,
        'zipCodeShippedTo': value.zipCodeShippedTo,
        'filed': value.filed,
        'canceled': value.canceled,
        'createdAt': (value.createdAt.toISOString()),
        'shipments': ((value.shipments as Array<any>).map(TrackingShipmentProjectionToJSON)),
        'productItems': ((value.productItems as Array<any>).map(ProductNumberThumbnailToJSON)),
    };
}

