import {
  Anchor,
  Box,
  Paper,
  Select,
  Skeleton,
  Stack,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
} from "@mantine/core";
import { IconLock } from "@tabler/icons-react";
import clsx from "clsx";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PeriodOption } from "~/common/enum/PeriodOption";

import { useMediaQuery } from "@mantine/hooks";
import styles from "./KpiTile.module.css";

type KpiTileProps = {
  title: string;
  tooltipTitle: string;
  count: number;
  selectedPeriod: string | undefined;
  link: string;
  isEditing: boolean;
  isLoading: boolean;
  onChange: (periodOption: PeriodOption) => void;
};

const KpiTile: React.FC<KpiTileProps> = (props) => {
  const { title, tooltipTitle, count, selectedPeriod, isLoading, link, isEditing, onChange } =
    props;
  const { t: globalT } = useTranslation();

  const { t } = useTranslation(undefined, { keyPrefix: "dashboardPage.tiles" });

  const PeriodData = [
    PeriodOption.SEVEN_DAYS,
    PeriodOption.ONE_MONTH,
    PeriodOption.THREE_MONTHS,
    PeriodOption.SIX_MONTHS,
    PeriodOption.TWELVE_MONTHS,
    PeriodOption.START_OF_YEAR,
  ];

  const TooltipLink: React.FC<{ label: string; to: string; children?: ReactNode }> = ({
    label,
    to,
    children,
  }) => {
    return (
      <Tooltip label={label}>
        <Anchor component={Link} to={to}>
          {children}
        </Anchor>
      </Tooltip>
    );
  };

  const handleOnSearchChange = (item: string | null) => {
    onChange(item as PeriodOption);
  };

  const isSmall = useMediaQuery("(max-width: 500px)");

  const wrapperClassNames = clsx(styles.wrapper, isEditing && styles.editing);

  return (
    <Paper
      className={wrapperClassNames}
      radius={isSmall ? 0 : undefined}
      h={"100%"}
      style={{ position: "relative" }}
    >
      <Box className={styles.actions}>
        <Tooltip label={globalT("gridLayout.staticLockTooltip")}>
          <ThemeIcon variant="subtle" color="gray">
            <IconLock />
          </ThemeIcon>
        </Tooltip>
      </Box>
      <Stack align="center" gap={0}>
        <Title order={2} size={24}>
          {title}
        </Title>

        {isLoading && !isEditing && (
          <Stack align="center" gap={"sm"} pt={10}>
            <Skeleton height={32} width={25} />

            <Skeleton height={16} width={165} />
          </Stack>
        )}
        {isLoading && isEditing && (
          <Stack align="center" gap={"sm"} pt={4}>
            <Skeleton height={32} width={25} />

            <Select
              data={PeriodData.map((item) => {
                return {
                  label: globalT(`periodSelect.${item}`),
                  value: item,
                };
              })}
              value={selectedPeriod}
              allowDeselect={false}
              onChange={handleOnSearchChange}
            />
          </Stack>
        )}
        {!isLoading && (
          <Stack align="center" gap={0}>
            <TooltipLink to={link} label={tooltipTitle}>
              <Text fw={"bold"} fz={32}>
                {count}
              </Text>
            </TooltipLink>

            {isEditing && (
              <Select
                data={PeriodData.map((item) => {
                  return {
                    label: globalT(`periodSelect.${item}`),
                    value: item,
                  };
                })}
                value={selectedPeriod}
                allowDeselect={false}
                onChange={handleOnSearchChange}
              />
            )}

            {!isEditing && (
              <Text c={"dimmed"} ta="center">
                {t(`periodSelect.${selectedPeriod}`)}
              </Text>
            )}
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

export { KpiTile };
