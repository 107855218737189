import { useEffect, useState } from "react";

export type UseDelayedValueProps<ValueType> = {
  value: ValueType;
  delay: number;
  initialValue: ValueType;
  enabled?: boolean;
};

export const useDelayedValue = <ValueType>(props: UseDelayedValueProps<ValueType>) => {
  const [localValue, setLocalValue] = useState(props.initialValue);

  useEffect(() => {
    if (props.enabled === false) return;

    const timeoutRef = setTimeout(() => {
      setLocalValue(props.value);
    }, props.delay);

    return () => {
      clearTimeout(timeoutRef);
    };
  }, [props.delay, props.value, props.enabled]);

  return localValue;
};
