import { useQuery } from "@tanstack/react-query";

import { useRestAPI } from "../../useRestAPI";

export const GetAccountDetailsQueryKey = "GetAccountDetailsQuery";

export const useGetAccountDetailsQuery = () => {
  const api = useRestAPI();

  return useQuery({
    queryKey: [GetAccountDetailsQueryKey],
    queryFn: () => api.account.base.getAccountDetails(),
  });
};
