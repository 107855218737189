import { Button, Group, Stack, Text } from "@mantine/core";
import { setMonth } from "date-fns";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ProductItem } from "~/api/openapi";
import { PeriodRange } from "~/common/classes/PeriodRange";
import { formatCurrency } from "~/helper/Formatter/formatCurrency";
import { createSalesOrdersPageLink } from "~/ui/routes/DynamicRoutes/createSalesOrdersPageLink";

import { AvatarGroup } from "../../../../components/AvatarGroup/AvatarGroup";

import availabilityMonthBoxStyles from "./AvailabilityMonthBox.module.css";

export interface AvailabilityMonthBoxProps {
  month: number;
  articleCount: number;
  price: number;
  products: ProductItem[];
  availabilityPeriod:
    | {
        availableFrom: Date;
        availableUntil: Date;
      }
    | undefined;
  isLoading?: boolean;
}

const AvailabilityMonthBox: React.FC<AvailabilityMonthBoxProps> = (props) => {
  const { month, articleCount, price, products, availabilityPeriod, isLoading } = props;

  const { t, i18n } = useTranslation();

  const availabilityPeriodString = new PeriodRange(
    availabilityPeriod?.availableFrom,
    availabilityPeriod?.availableUntil,
  ).toString();

  const linkUrl = createSalesOrdersPageLink({
    availabilityPeriod: availabilityPeriodString ?? undefined,
  });

  const monthDate = setMonth(new Date(), month - 1);
  const monthString = monthDate.toLocaleString(i18n.language, {
    month: "long",
  });

  if (isLoading) return null;

  return (
    <Stack gap={"md"} pb={"md"} h={"180px"}>
      <Group className={availabilityMonthBoxStyles.header} justify={"center"}>
        <Text fw={"bold"}>
          {monthString}
          <Text span fw="normal" inherit>
            {articleCount !== 0 &&
              t("dashboardPage.ExpectedAvailabilityWidget.tile.header.data", {
                count: articleCount,
                price: formatCurrency(price),
              })}
          </Text>
        </Text>
      </Group>

      {articleCount === 0 && (
        <Group justify="center" align={"center"} style={{ height: "100%" }}>
          <Text>{t("dashboardPage.ExpectedAvailabilityWidget.tile.noData")}</Text>
        </Group>
      )}

      {articleCount !== 0 && (
        <>
          <Group justify="center">
            <AvatarGroup
              avatarItems={products.map((prod) => ({
                imageUrl: prod.productThumbnailUrl,
                name: prod.productName,
              }))}
              avatarSize={55}
              centered
            />
          </Group>

          <Group justify="center" px={"md"}>
            <Button variant="light" component={Link} to={linkUrl} size="sm">
              {t("dashboardPage.ExpectedAvailabilityWidget.tile.showItemButton")}
            </Button>
          </Group>
        </>
      )}
    </Stack>
  );
};
export { AvailabilityMonthBox };
