import { Box, Center, LoadingOverlay, Text, px, useMantineTheme } from "@mantine/core";
import { useVirtualizer } from "@tanstack/react-virtual";
import { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useRef } from "react";

import { SortDirection } from "~/api/openapi";

import { Scroller } from "../Scroller/Scroller";
import { OneLineCell } from "./Cells/OneLineCell/OneLineCell";
import { LoadMoreRow } from "./LoadMoreRow/LoadMoreRow";
import { TableHeader } from "./TableHeader/TableHeader";
import { TableRow } from "./TableRow/TableRow";
import { GridHelper } from "./helper/GridHelper";
import { useRows } from "./hooks/useRows";
import { DataTableColumn } from "./interface/DataTableColumn";
import { DataTableProps, TableRowType } from "./interface/DataTableProps";
import { Row } from "./interface/DataTableRow";

import styles from "./DataTable.module.css";

const DataTable = <RowType extends TableRowType>(props: DataTableProps<RowType>) => {
  const {
    data,
    columns: columnsProp,
    defaultColumn: defaultColumnProp,
    backgroundColor,
    hiddenColumns,
    selectedRowIds,
    rowGap = "xs",
    columnGap = "md",
    rowHeight = 70,
    overscan = 5,
    loadMoreLimit,
    noDataText,
    hasNextPage,
    onLoadMore,
    sortBy,
    isLoading = false,
    onSortChange,
    tableMinHeight,
    tableMaxHeight,
    testId,
    useCompleteHeight,
    getRowId,
    onRowSelect,
    virtualized,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const theme = useMantineTheme();

  const rowGapValue = rowGap ? Number(px(theme.spacing[rowGap])) * 0.5 : 0;
  const columnGapValue = columnGap ? Number(px(theme.spacing[columnGap])) * 0.5 : 0;

  const rows = useRows(data, getRowId);

  const defaultColumn = useMemo(
    () =>
      ({
        cell: ({ value }) => <OneLineCell>{value as ReactNode}</OneLineCell>,
        ...defaultColumnProp,
      }) as Partial<DataTableColumn>,
    [defaultColumnProp],
  );

  const columns = useMemo(() => {
    return columnsProp
      .filter((column) => !hiddenColumns?.includes(column.id ?? column.accessor.toString()))
      .map((column) => ({ ...defaultColumn, ...column }));
  }, [columnsProp, hiddenColumns, defaultColumn]);

  /**
   * Sort handler
   */
  const handleSortChange = (columnId: string, direction: SortDirection | undefined) => {
    onSortChange?.({ [columnId]: direction } as Partial<
      Record<keyof RowType, SortDirection | undefined>
    >);
  };

  /**
   * Automatically scrolls to selected row if the row is initially selected and not manually selected
   */
  const initialScrollRef = useRef(false);
  useEffect(() => {
    if (!selectedRowIds || initialScrollRef.current) {
      return;
    }

    const targetIndex = rows.findIndex((row) => {
      return selectedRowIds?.includes(row.id);
    });

    if (targetIndex >= 0) {
      containerRef.current?.scrollTo({ top: targetIndex * rowHeight });
      initialScrollRef.current = true;
    }
  }, [rows, selectedRowIds, rowHeight]);

  const headerHeight = headerRef.current?.getBoundingClientRect().height ?? 0;

  const estimateSize = useCallback(() => rowHeight, [rowHeight]);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => containerRef.current,
    estimateSize,
    overscan,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();

  const [firstItem] = virtualItems;
  const [lastItem] = [...virtualItems].reverse();
  const lastItemIndex: number | null = lastItem?.index ?? null;

  const isSelected = useCallback(
    (rowId: string) => {
      if (Array.isArray(selectedRowIds)) return selectedRowIds.includes(rowId);
      return selectedRowIds === rowId;
    },
    [selectedRowIds],
  );

  /**
   * Load more effect
   * When reaching the end of the current rows we trigger the onLoadMore callback once
   */
  useEffect(() => {
    if (!hasNextPage || !lastItemIndex) {
      return;
    }

    if (lastItemIndex === rows.length - 1) {
      onLoadMore?.(rows.length);
    }
  }, [lastItemIndex, rows, hasNextPage, onLoadMore]);

  const hasLoadMore = useMemo(
    () => onLoadMore !== undefined && (!loadMoreLimit || loadMoreLimit <= rows.length),
    [onLoadMore, loadMoreLimit, rows.length],
  );

  const virtualOffset = virtualized ? firstItem?.start : 0;
  const tableHeight = rowVirtualizer.getTotalSize() + headerHeight;

  const containerStyle: CSSProperties = {
    minHeight: tableMinHeight,
    maxHeight: tableMaxHeight,
  };

  const tableStyle: CSSProperties = {
    gridTemplateColumns: GridHelper.composeGridTemplateColumns(columns as DataTableColumn[]),
    gridTemplateRows: `[header] min-content [offset] ${virtualOffset}px`,
    gridAutoRows: rowHeight,
    gap: `${rowGapValue}px 0`,
    height: useCompleteHeight ? "100%" : virtualized ? tableHeight : undefined,
    backgroundColor,
  };

  const rowElements = useMemo(() => {
    return (virtualized ? virtualItems : rows).map((virtualItem) => {
      const row = "index" in virtualItem ? rows[virtualItem.index] : virtualItem;

      return (
        <TableRow
          key={row.id}
          row={row}
          columns={columns}
          isSelected={isSelected(row.id)}
          rowHeight={rowHeight}
          columnGap={columnGapValue}
          onRowClick={onRowSelect as (row: Row<TableRowType>) => void}
          hasRowSelection={Boolean(onRowSelect)}
        />
      );
    });
  }, [
    virtualized,
    virtualItems,
    rows,
    rowHeight,
    columnGapValue,
    columns,
    isSelected,
    onRowSelect,
  ]);

  return (
    <Box
      data-testid={"table-loading-container"}
      style={containerStyle}
      className={styles.tableLoadingContainer}
    >
      <LoadingOverlay visible={isLoading} zIndex={50} />

      <Scroller
        classNames={{
          root: styles.scrollContainer,
        }}
        viewportRef={containerRef}
        data-testid={"table-outer-container"}
      >
        <Box
          data-testid={testId ?? "datatable-container"}
          role="table"
          ref={tableRef}
          className={styles.table}
          style={tableStyle}
        >
          <TableHeader
            headerRef={headerRef}
            columns={columns as DataTableColumn[]}
            columnGap={columnGapValue}
            onSortChange={handleSortChange}
            backgroundColor={backgroundColor}
            sortBy={sortBy}
          />

          <Box className={styles.tableBody}>
            {/* Offset row for the virtualization */}
            <Box style={{ gridColumn: "1/-1" }} />

            {rowElements}

            {hasLoadMore && <LoadMoreRow hasNextPage={hasNextPage ?? false} />}
          </Box>
        </Box>

        {data.length === 0 && !isLoading && (
          <Box className={styles.noDataWrapper} data-testid={`${testId}-nodata-wrapper`}>
            <Center h={"100%"}>
              <Text c={"dimmed"}>{noDataText ?? "No Data"}</Text>
            </Center>
          </Box>
        )}
      </Scroller>
    </Box>
  );
};

export { DataTable };
