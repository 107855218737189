/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClientCredentials } from './ClientCredentials';
import {
    ClientCredentialsFromJSON,
    ClientCredentialsFromJSONTyped,
    ClientCredentialsToJSON,
} from './ClientCredentials';
import type { HeoCustomerNumber } from './HeoCustomerNumber';
import {
    HeoCustomerNumberFromJSON,
    HeoCustomerNumberFromJSONTyped,
    HeoCustomerNumberToJSON,
} from './HeoCustomerNumber';
import type { RegisteredClientIp } from './RegisteredClientIp';
import {
    RegisteredClientIpFromJSON,
    RegisteredClientIpFromJSONTyped,
    RegisteredClientIpToJSON,
} from './RegisteredClientIp';

/**
 * 
 * @export
 * @interface LiveClient
 */
export interface LiveClient {
    /**
     * 
     * @type {HeoCustomerNumber}
     * @memberof LiveClient
     */
    heoCustomerNumber: HeoCustomerNumber;
    /**
     * 
     * @type {ClientCredentials}
     * @memberof LiveClient
     */
    clientCredentials: ClientCredentials;
    /**
     * 
     * @type {Array<RegisteredClientIp>}
     * @memberof LiveClient
     */
    ips: Array<RegisteredClientIp>;
    /**
     * 
     * @type {Date}
     * @memberof LiveClient
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the LiveClient interface.
 */
export function instanceOfLiveClient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "heoCustomerNumber" in value;
    isInstance = isInstance && "clientCredentials" in value;
    isInstance = isInstance && "ips" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function LiveClientFromJSON(json: any): LiveClient {
    return LiveClientFromJSONTyped(json, false);
}

export function LiveClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): LiveClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'heoCustomerNumber': HeoCustomerNumberFromJSON(json['heoCustomerNumber']),
        'clientCredentials': ClientCredentialsFromJSON(json['clientCredentials']),
        'ips': ((json['ips'] as Array<any>).map(RegisteredClientIpFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function LiveClientToJSON(value?: LiveClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'heoCustomerNumber': HeoCustomerNumberToJSON(value.heoCustomerNumber),
        'clientCredentials': ClientCredentialsToJSON(value.clientCredentials),
        'ips': ((value.ips as Array<any>).map(RegisteredClientIpToJSON)),
        'createdAt': (value.createdAt.toISOString()),
    };
}

