/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Street } from './Street';
import {
    StreetFromJSON,
    StreetFromJSONTyped,
    StreetToJSON,
} from './Street';

/**
 * 
 * @export
 * @interface InvoiceAddress
 */
export interface InvoiceAddress {
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddress
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddress
     */
    additionalInfo: string;
    /**
     * 
     * @type {Street}
     * @memberof InvoiceAddress
     */
    street: Street;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddress
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddress
     */
    countryIso2: string;
}

/**
 * Check if a given object implements the InvoiceAddress interface.
 */
export function instanceOfInvoiceAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "additionalInfo" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "zipCode" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "countryIso2" in value;

    return isInstance;
}

export function InvoiceAddressFromJSON(json: any): InvoiceAddress {
    return InvoiceAddressFromJSONTyped(json, false);
}

export function InvoiceAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'additionalInfo': json['additionalInfo'],
        'street': StreetFromJSON(json['street']),
        'zipCode': json['zipCode'],
        'city': json['city'],
        'countryIso2': json['countryIso2'],
    };
}

export function InvoiceAddressToJSON(value?: InvoiceAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'additionalInfo': value.additionalInfo,
        'street': StreetToJSON(value.street),
        'zipCode': value.zipCode,
        'city': value.city,
        'countryIso2': value.countryIso2,
    };
}

