import { GuidedTourDE } from "../de/de.guidedTour";

export const GuidedTourEN: typeof GuidedTourDE = {
  startTourActioButtonTitle: "Start guided Tour",
  title: "Guided Tour: {{customTitle}}",
  previousButton: "Back",
  nextButton: "Next",
  finishPrematurelyButtonLabel: "Stop guided Tour",
  finishButtonLabel: "Finish guided Tour",
  pageCount: "{{currentPage}} of {{pageCount}}",
  intro: {
    title: "Welcome to your Business Console!",
    description:
      "The <strong>heo Business Console (BC) is your platform</strong> that offers you transparency and functions for managing your aftersales processes and business workflows. <br/><br/> To make it easier for you to get started, we would like to introduce you to the basic functions in a short guided tour. (Time required: approx. 5 minutes)",
    startLaterButtonLabel: "Maybe later",
    startGuidedTourButtonLabel: "Start guided Tour",
  },
  userInterfaceDashboard: {
    title: "User Interface & Dashboard",
    steps: {
      1: "Use the <strong>navigation</strong> on the left-hand side to navigate to the individual areas of the Business Console. You can also collapse the navigation to have more space for the workspace.",
      2: "In the <strong>central workspace</strong> you will find information and functions of the respective areas.",
      3: "You can access your <strong>profile information</strong> in the header at the top right. Here you will find access to your account and company data. ",
      4: "Under <strong>My account</strong> your login and contact information is displayed and can be changed if necessary.",
      5: "Under <strong>My company</strong>, your company data is summarised under various headings, e.g. delivery and payment agreements. You can request changes to some data.",
      6: "You can send your <strong>feedback</strong> on errors, improvements and new ideas to heo using the feedback form.",
      7: "The <strong>My Dashboard</strong> area gives you a quick and structured overview of your most important business transactions.",
      8: "Find out everything about your current business transactions within the last 7 days, such as new orders, deliveries or changes in your backlog, in the <strong>Welcome box</strong>.",
      9: "Get a quick overview of your <strong>open orders</strong> and their quantity and price volume in an interactive chart display.",
      10: "Always keep an eye on your <strong>open invoices</strong> and jump directly to the respective invoice details.",
      11: "Check the expected availability section to see which <strong>items are expected to be delivered in the coming months</strong> in order to plan your cash flow sensibly.",
      12: "Quickly and easily check your <strong>open claims</strong> to ensure prompt processing.",
    },
  },
  product: {
    title: "My products",
    steps: {
      1: "Under <strong>My products</strong> you will find all the products you have ordered in the heo shop. This list summarises the products into one entry per product, regardless of how many you have already ordered.",
      2: "For each product ordered, you can see under <strong>Processing status</strong> how many of them are still open, are already being processed or have already been delivered. Any open claims are also displayed here.",
      3: "With the <strong>filter and search functions</strong>, you can restrict the list to the products relevant to you or sort them in ascending or descending order using the column headings.",
      4: "Get <strong>detail information</strong> on every product ordered so far in the product detail view by clicking on an item in the list.",
      5: "In addition to current price and product information, <strong>all orders, invoices and, if applicable, claims for this item</strong> are listed here and displayed in a searchable format.",
    },
  },
  customerOrder: {
    title: "My orders",
    steps: {
      1: "Under <strong>My orders </strong>you will find all shop orders that you have placed with heo. The most important information about each order is summarised in the order overview.",
      2: "With the <strong>filter and search functions</strong> you can restrict the list to the orders relevant to you and, for example, search specifically for order or article numbers. You can also sort the list by column.",
      3: "Get <strong>detail information</strong> for each order in the order detail view by clicking on an order. Call up item details directly from the orders via a link",
      4: "Filter individual orders by order items in the <strong>order detail view</strong> and get an overview of which order items have been confirmed or already delivered.",
    },
  },
  salesOrder: {
    title: "My backorders",
    steps: {
      1: "Under <strong>My backorders</strong> you will find an overview of your current pre-orders and backorders. The most important information and statuses are summarised here for each reset.",
      2: "The <strong>filter tiles</strong> summarise the number and amounts of your arrears for the respective status. You can also use the tiles to filter the list to only display arrears for a specific status.",
      3: "With the <strong>filter and search functions</strong>, you can restrict the list to the accruals relevant to you or search for specific items using article numbers or ETAs, for example. You can also sort the list by column.",
      4: "Associated orders and details on backordered items can be called up directly from the backorder list via links.",
    },
  },
  invoice: {
    title: "My invoices",
    steps: {
      1: "Under <strong>My invoices</strong> you will find your invoices as well as current order confirmations. For each invoice, you will see the most important information such as the dispatch and payment status, the invoice total and the delivery address.",
      2: "The <strong>filter tiles</strong> show you a summary of the number and amounts of your invoices for the respective status. You can also use the tiles to filter the list, e.g. to display only open or overdue invoices.",
      3: "You can use the <strong>filter and search functions</strong> to restrict the list to the invoices that are relevant to you. The invoices can also be sorted in ascending and descending order using the column headings.",
      4: "All invoices and order confirmations are available to you in the invoice overview as a <strong>PDF download</strong>.",
      5: "The <strong>number of parcels</strong> and the <strong>shipping numbers</strong> are displayed for each invoice. A dropshipping shipment is also labelled.",
      6: "Get <strong>detail information</strong> for each invoice in the invoice detail view by clicking on an invoice line. In addition to general invoice data, all invoice items are also displayed here with images.",
      7: "In this view, <strong>all invoice items</strong> can be searched according to various criteria. You can also call up orders and item details directly via links, or start the claim for an item from this view.",
    },
  },
  productClaim: {
    title: "My claims",
    steps: {
      1: "Under <strong>My claims</strong> you will find all the claims that you have created in the Business Console. For each claim, the most important information, such as the current status, is clearly summarised here.",
      2: "The <strong>filter and search functions</strong> allow you to customise the list of claims according to your needs or to search specifically for affected invoices. It is also possible to sort the list by column.",
      3: "Receive <strong>detail information</strong> on each claim in the claim detail view and track the communication and processing history. Your queries and responses to the claim are also displayed here.",
      4: "In the Business Console, you have the option of directly creating <strong>new claims</strong> for one or more items without leaving the application.",
      5: "In a <strong>guided claims process</strong>, select the items you want to complain to heo and enter the required data for each item accordingly.  ",
    },
  },
  creditNotes: {
    title: "My credits",
    steps: {
      1: "Under <strong>My credits</strong> you will find all the credits you have received from heo. The most important information for each credit note is summarised here.",
      2: "You can use the <strong>filter and search functions</strong> to restrict the list to the credit notes that are relevant to you. You can also sort the list column by column by clicking on the column headings.",
      3: "In the credit notes overview, all previous credit notes are available to you in digital form and can be downloaded or saved as a <strong>PDF file</strong>.",
    },
  },
};
