import { Button, Menu, Text } from "@mantine/core";
import { useDisclosure, useToggle } from "@mantine/hooks";
import { IconBook2, IconHelp, IconSpeakerphone } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { ContactDrawer } from "~/ui/components/Contact/ContactDrawer";
import { useShowGuidedTourActionButton } from "~/ui/components/GuidedTourModal/hooks/useShowGuidedTourActionButton";
import { useToogleGuidedTourModal } from "~/ui/components/GuidedTourModal/hooks/useToogleGuidedTourModal";

const MENU_ICON_SIZE = 16;

const HelpAndSupport = () => {
  const { t } = useTranslation();
  const [isContactOpen, toggleContact] = useToggle([false, true]);

  const { setIsGuidedTourModalOpen } = useToogleGuidedTourModal();

  const { showGuidedTourActionButton } = useShowGuidedTourActionButton();

  const handleOpenGuidedTour = () => {
    setIsGuidedTourModalOpen(true);
  };

  const [opened, handlers] = useDisclosure(false);
  return (
    <>
      <Menu
        opened={opened}
        onOpen={handlers.open}
        onClose={handlers.close}
        closeOnItemClick={false}
        transitionProps={{ transition: "pop-top-right" }}
        position="bottom-end"
        shadow={"md"}
        withinPortal={false}
      >
        <Menu.Target>
          <Button size="compact-md" variant="subtle" leftSection={<IconHelp size="27" />}>
            <Text c="dark">{t("header.helpAndSupportLabel")}</Text>
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {showGuidedTourActionButton && (
            <Menu.Item
              onClick={handleOpenGuidedTour}
              leftSection={<IconBook2 size={MENU_ICON_SIZE} />}
            >
              <Text c="dark">{t("helpAndSupportMenu.guidedTour")}</Text>
            </Menu.Item>
          )}
          <Menu.Item
            onClick={() => toggleContact()}
            leftSection={<IconSpeakerphone size={MENU_ICON_SIZE} />}
          >
            <Text c="dark">{t("helpAndSupportMenu.contact")}</Text>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <ContactDrawer isOpen={isContactOpen} onClose={() => toggleContact()} />
    </>
  );
};

export { HelpAndSupport };
