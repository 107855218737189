import {
  CREDIT_NOTES_PAGE_PERIOD_QUERY_KEY,
  CREDIT_NOTES_PAGE_SEARCH_QUERY_KEY,
} from "~/ui/pages/CreditsPage/CreditNotesPageQueryKeys";

import { RoutesEnum } from "../RoutesEnum";
import { createDynamicRoute } from "./util/createDynamicRoute";

const createCreditNotesLink = createDynamicRoute({
  url: RoutesEnum.CREDITS,
  params: {
    search: CREDIT_NOTES_PAGE_SEARCH_QUERY_KEY,
    period: CREDIT_NOTES_PAGE_PERIOD_QUERY_KEY,
  },
});

export { createCreditNotesLink };
