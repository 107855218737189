import { RoutesEnum } from "~/ui/routes/RoutesEnum";

export const useShowGuidedTourActionButton = () => {
  const pagesToShowGuidedTour: RoutesEnum[] = [
    RoutesEnum.DASHBOARD,
    RoutesEnum.PRODUCTS,
    RoutesEnum.CUSTOMER_ORDERS,
    RoutesEnum.INVOICES,
    RoutesEnum.PRODUCT_CLAIMS,
    RoutesEnum.SALES_ORDERS,
    RoutesEnum.CREDITS,
  ];

  const showGuidedTourActionButton = pagesToShowGuidedTour.includes(
    window.location.pathname as RoutesEnum,
  );

  return { showGuidedTourActionButton };
};
