/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeoAddressNumber } from './HeoAddressNumber';
import {
    HeoAddressNumberFromJSON,
    HeoAddressNumberFromJSONTyped,
    HeoAddressNumberToJSON,
} from './HeoAddressNumber';
import type { HeoCustomerNumber } from './HeoCustomerNumber';
import {
    HeoCustomerNumberFromJSON,
    HeoCustomerNumberFromJSONTyped,
    HeoCustomerNumberToJSON,
} from './HeoCustomerNumber';
import type { Street } from './Street';
import {
    StreetFromJSON,
    StreetFromJSONTyped,
    StreetToJSON,
} from './Street';

/**
 * 
 * @export
 * @interface RetailerAddress
 */
export interface RetailerAddress {
    /**
     * 
     * @type {string}
     * @memberof RetailerAddress
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerAddress
     */
    type: RetailerAddressTypeEnum;
    /**
     * 
     * @type {HeoCustomerNumber}
     * @memberof RetailerAddress
     */
    customerNumber: HeoCustomerNumber;
    /**
     * 
     * @type {HeoAddressNumber}
     * @memberof RetailerAddress
     */
    addressNumber: HeoAddressNumber;
    /**
     * 
     * @type {string}
     * @memberof RetailerAddress
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerAddress
     */
    additionalInfo: string;
    /**
     * 
     * @type {Street}
     * @memberof RetailerAddress
     */
    street: Street;
    /**
     * 
     * @type {string}
     * @memberof RetailerAddress
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof RetailerAddress
     */
    countryIso2: string;
    /**
     * 
     * @type {boolean}
     * @memberof RetailerAddress
     */
    isActiveAddress: boolean;
    /**
     * 
     * @type {Date}
     * @memberof RetailerAddress
     */
    lastSyncedAt: Date;
}


/**
 * @export
 */
export const RetailerAddressTypeEnum = {
    Shipping: 'SHIPPING'
} as const;
export type RetailerAddressTypeEnum = typeof RetailerAddressTypeEnum[keyof typeof RetailerAddressTypeEnum];


/**
 * Check if a given object implements the RetailerAddress interface.
 */
export function instanceOfRetailerAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "customerNumber" in value;
    isInstance = isInstance && "addressNumber" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "additionalInfo" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "zipCode" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "countryIso2" in value;
    isInstance = isInstance && "isActiveAddress" in value;
    isInstance = isInstance && "lastSyncedAt" in value;

    return isInstance;
}

export function RetailerAddressFromJSON(json: any): RetailerAddress {
    return RetailerAddressFromJSONTyped(json, false);
}

export function RetailerAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetailerAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'customerNumber': HeoCustomerNumberFromJSON(json['customerNumber']),
        'addressNumber': HeoAddressNumberFromJSON(json['addressNumber']),
        'name': json['name'],
        'additionalInfo': json['additionalInfo'],
        'street': StreetFromJSON(json['street']),
        'zipCode': json['zipCode'],
        'city': json['city'],
        'countryIso2': json['countryIso2'],
        'isActiveAddress': json['isActiveAddress'],
        'lastSyncedAt': (new Date(json['lastSyncedAt'])),
    };
}

export function RetailerAddressToJSON(value?: RetailerAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'customerNumber': HeoCustomerNumberToJSON(value.customerNumber),
        'addressNumber': HeoAddressNumberToJSON(value.addressNumber),
        'name': value.name,
        'additionalInfo': value.additionalInfo,
        'street': StreetToJSON(value.street),
        'zipCode': value.zipCode,
        'city': value.city,
        'countryIso2': value.countryIso2,
        'isActiveAddress': value.isActiveAddress,
        'lastSyncedAt': (value.lastSyncedAt.toISOString()),
    };
}

