import { useMediaQuery } from "@mantine/hooks";

const MOBILE_MAX_WIDTH = 900;

export const useResponsiveMode = () => {
  // Mobile means all devices with a width of 900px or less, desktop or touchdevice
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_MAX_WIDTH}px)`, false, {
    getInitialValueInEffect: false,
  });

  const isTouch = window.matchMedia("(pointer: coarse)").matches;

  return {
    isMobile: Boolean(isMobile),
    isTouch,
    mobileWidth: MOBILE_MAX_WIDTH,
  };
};
