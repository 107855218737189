import { useQuery } from "@tanstack/react-query";

import { useRestAPI } from "../../useRestAPI";

export const GetCurrentRetailerQueryKey = "GetCurrentRetailerQuery";
export const useGetCurrentRetailerQuery = () => {
  const api = useRestAPI();

  return useQuery({
    queryKey: [GetCurrentRetailerQueryKey],
    queryFn: () => api.retailer.getCurrentRetailer(),
  });
};
