import { Box, LoadingOverlay } from "@mantine/core";
import Chart from "react-apexcharts";

import { useGetTotalSales } from "~/api/hooks/totalSales/useGetTotalSales";

import { useTotalSalesChartConfig } from "./useTotalSalesChartConfig";

const TotalSalesChart = () => {
  const { data: totalSalesReport, isFetched } = useGetTotalSales();

  const { chartData, chartOptions } = useTotalSalesChartConfig(totalSalesReport?.years);

  return (
    <Box h="330" style={{ position: "relative" }}>
      <LoadingOverlay visible={!isFetched} />
      {chartData && chartOptions && (
        <Chart height={"100%"} series={chartData} options={chartOptions} />
      )}
    </Box>
  );
};

export { TotalSalesChart };
