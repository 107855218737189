import { useMemo } from "react";

import { GridLayoutItem } from "../GridLayout";

export type GridColumns = Record<string, GridLayoutItem[]>;

export type UseGridColumnsProps = {
  items: GridLayoutItem[];
  columnCount: number | undefined;
};

export const useGridColumns = (props: UseGridColumnsProps) => {
  const { items, columnCount } = props;

  const columns = useMemo(() => {
    if (!columnCount) return {};

    const contentColumns: GridColumns = {};

    for (const [index, item] of items.entries()) {
      if (item.hidden) continue;

      const columnIndex = index % columnCount;
      if (!contentColumns[columnIndex]) contentColumns[columnIndex] = [];
      contentColumns[columnIndex].push(item);
    }

    return contentColumns;
  }, [items, columnCount]);

  return { columns };
};
