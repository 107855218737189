import { use } from "i18next";
import { initReactI18next } from "react-i18next";

import { AccountPageDE } from "./de/de.accountPage";
import { CreateClaimsPageDE } from "./de/de.createClaimsPage";
import { CreditNotesPageDE } from "./de/de.creditNotesPage";
import { CustomerOrdersPageDE } from "./de/de.customerOdersPage";
import { DashboardPageDE } from "./de/de.dashboardPage";
import { FormErrorsDE } from "./de/de.formErrors";
import { GlobalDE } from "./de/de.global";
import { GuidedTourDE } from "./de/de.guidedTour";
import { InvoicesPageDE } from "./de/de.invoicesPage";
import { LoginPageDE } from "./de/de.loginPage";
import { ManageApiPageDE } from "./de/de.manageApiPage";
import { NoAccessPageDE } from "./de/de.noAccessPage";
import { NotFoundPageDE } from "./de/de.notFoundPage";
import { ProductClaimsPageDE } from "./de/de.productClaimsPage";
import { ProductsPageDE } from "./de/de.productsPage";
import { RetailerPageDE } from "./de/de.retailerPage";
import { SalesOrdersPageDE } from "./de/de.salesOrdersPage";
import { StoreProfilePageDE } from "./de/de.storeProfilePage";
import { TotalSalesDetailsPageDE } from "./de/de.totalSalesDetailsPage";
import { AccountPageEN } from "./en/en.accountPage";
import { CreateClaimsPageEN } from "./en/en.createClaimsPage";
import { CreditNotesPageEN } from "./en/en.creditNotesPage";
import { CustomerOrdersPageEN } from "./en/en.customerOdersPage";
import { DashboardPageEN } from "./en/en.dashboardPage";
import { FormErrorsEN } from "./en/en.formErrors";
import { GlobalEN } from "./en/en.global";
import { GuidedTourEN } from "./en/en.guidedTour";
import { InvoicesPageEN } from "./en/en.invoicesPage";
import { LoginPageEN } from "./en/en.loginPage";
import { ManageApiPageEN } from "./en/en.manageApiPage";
import { NoAccessPageEN } from "./en/en.noAccessPage";
import { NotFoundPageEN } from "./en/en.notFoundPage";
import { ProductClaimsPageEN } from "./en/en.productClaimsPage";
import { ProductsPageEN } from "./en/en.productsPage";
import { RetailerPageEN } from "./en/en.retailerPage";
import { SalesOrdersPageEN } from "./en/en.salesOrdersPage";
import { StoreProfilePageEN } from "./en/en.storeProfilePage";
import { TotalSalesDetailsPageEN } from "./en/en.totalSalesDetailsPage";

const deTranslations = {
  common: {
    ...GlobalDE,
    dashboardPage: DashboardPageDE,
    retailerPage: RetailerPageDE,
    productsPage: ProductsPageDE,
    customerOrdersPage: CustomerOrdersPageDE,
    salesOrdersPage: SalesOrdersPageDE,
    storeProfilePage: StoreProfilePageDE,
    productClaimsPage: ProductClaimsPageDE,
    createClaimsPage: CreateClaimsPageDE,
    accountPage: AccountPageDE,
    invoicesPage: InvoicesPageDE,
    loginPage: LoginPageDE,
    creditNotesPage: CreditNotesPageDE,
    manageApiPage: ManageApiPageDE,
    guidedTour: GuidedTourDE,
    noAccessPage: NoAccessPageDE,
    notFoundPage: NotFoundPageDE,
    formErrors: FormErrorsDE,
    totalSalesDetailsPage: TotalSalesDetailsPageDE,
  },
};

export const translations = {
  de: deTranslations,
  en: {
    common: {
      ...GlobalEN,
      dashboardPage: DashboardPageEN,
      retailerPage: RetailerPageEN,
      productsPage: ProductsPageEN,
      customerOrdersPage: CustomerOrdersPageEN,
      salesOrdersPage: SalesOrdersPageEN,
      storeProfilePage: StoreProfilePageEN,
      productClaimsPage: ProductClaimsPageEN,
      createClaimsPage: CreateClaimsPageEN,
      accountPage: AccountPageEN,
      invoicesPage: InvoicesPageEN,
      loginPage: LoginPageEN,
      creditNotesPage: CreditNotesPageEN,
      manageApiPage: ManageApiPageEN,
      guidedTour: GuidedTourEN,
      noAccessPage: NoAccessPageEN,
      notFoundPage: NotFoundPageEN,
      formErrors: FormErrorsEN,
      totalSalesDetailsPage: TotalSalesDetailsPageEN,
    },
  } satisfies typeof deTranslations,
};

const [defaultLang] = navigator.language.split("-");

use(initReactI18next).init({
  resources: translations,
  lng: defaultLang,
  fallbackLng: "de",
  defaultNS: "common",

  interpolation: {
    escapeValue: false,
  },
});
