/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductOrderStatusSummary } from './ProductOrderStatusSummary';
import {
    ProductOrderStatusSummaryFromJSON,
    ProductOrderStatusSummaryFromJSONTyped,
    ProductOrderStatusSummaryToJSON,
} from './ProductOrderStatusSummary';

/**
 * 
 * @export
 * @interface PagedAndSyncedResultProductOrderStatusSummary
 */
export interface PagedAndSyncedResultProductOrderStatusSummary {
    /**
     * 
     * @type {Array<ProductOrderStatusSummary>}
     * @memberof PagedAndSyncedResultProductOrderStatusSummary
     */
    content: Array<ProductOrderStatusSummary>;
    /**
     * 
     * @type {number}
     * @memberof PagedAndSyncedResultProductOrderStatusSummary
     */
    totalElements: number;
    /**
     * 
     * @type {number}
     * @memberof PagedAndSyncedResultProductOrderStatusSummary
     */
    totalPages: number;
    /**
     * 
     * @type {Date}
     * @memberof PagedAndSyncedResultProductOrderStatusSummary
     */
    lastSyncedAt?: Date;
}

/**
 * Check if a given object implements the PagedAndSyncedResultProductOrderStatusSummary interface.
 */
export function instanceOfPagedAndSyncedResultProductOrderStatusSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "totalElements" in value;
    isInstance = isInstance && "totalPages" in value;

    return isInstance;
}

export function PagedAndSyncedResultProductOrderStatusSummaryFromJSON(json: any): PagedAndSyncedResultProductOrderStatusSummary {
    return PagedAndSyncedResultProductOrderStatusSummaryFromJSONTyped(json, false);
}

export function PagedAndSyncedResultProductOrderStatusSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedAndSyncedResultProductOrderStatusSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': ((json['content'] as Array<any>).map(ProductOrderStatusSummaryFromJSON)),
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
        'lastSyncedAt': !exists(json, 'lastSyncedAt') ? undefined : (new Date(json['lastSyncedAt'])),
    };
}

export function PagedAndSyncedResultProductOrderStatusSummaryToJSON(value?: PagedAndSyncedResultProductOrderStatusSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': ((value.content as Array<any>).map(ProductOrderStatusSummaryToJSON)),
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
        'lastSyncedAt': value.lastSyncedAt === undefined ? undefined : (value.lastSyncedAt.toISOString()),
    };
}

