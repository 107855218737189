/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductSummary
 */
export interface ProductSummary {
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    thumbnailUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    gtin: string;
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    tariffNumber: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSummary
     */
    weight: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    dimensions: string;
    /**
     * 
     * @type {number}
     * @memberof ProductSummary
     */
    packagingUnit: number;
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    packagingType: ProductSummaryPackagingTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductSummary
     */
    productManufacturers: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductSummary
     */
    productThemes: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductSummary
     */
    productTypes: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductSummary
     */
    isUnlisted: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ProductSummary
     */
    unlistedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductSummary
     */
    imageDownloadUrl?: string;
}


/**
 * @export
 */
export const ProductSummaryPackagingTypeEnum = {
    WithoutPackaging: 'WITHOUT_PACKAGING',
    PolybagFoil: 'POLYBAG_FOIL',
    Cardboard: 'CARDBOARD',
    CardboardPolystyrene: 'CARDBOARD_POLYSTYRENE',
    WindowBox: 'WINDOW_BOX',
    BlisterCard: 'BLISTER_CARD',
    FullBlister: 'FULL_BLISTER',
    Can: 'CAN',
    PrintedProducts: 'PRINTED_PRODUCTS',
    TradingCards: 'TRADING_CARDS',
    Unknown: 'UNKNOWN'
} as const;
export type ProductSummaryPackagingTypeEnum = typeof ProductSummaryPackagingTypeEnum[keyof typeof ProductSummaryPackagingTypeEnum];


/**
 * Check if a given object implements the ProductSummary interface.
 */
export function instanceOfProductSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "imageUrl" in value;
    isInstance = isInstance && "thumbnailUrl" in value;
    isInstance = isInstance && "gtin" in value;
    isInstance = isInstance && "tariffNumber" in value;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "dimensions" in value;
    isInstance = isInstance && "packagingUnit" in value;
    isInstance = isInstance && "packagingType" in value;
    isInstance = isInstance && "productManufacturers" in value;
    isInstance = isInstance && "productThemes" in value;
    isInstance = isInstance && "productTypes" in value;
    isInstance = isInstance && "isUnlisted" in value;

    return isInstance;
}

export function ProductSummaryFromJSON(json: any): ProductSummary {
    return ProductSummaryFromJSONTyped(json, false);
}

export function ProductSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': json['number'],
        'name': json['name'],
        'description': json['description'],
        'imageUrl': json['imageUrl'],
        'thumbnailUrl': json['thumbnailUrl'],
        'gtin': json['gtin'],
        'tariffNumber': json['tariffNumber'],
        'weight': json['weight'],
        'dimensions': json['dimensions'],
        'packagingUnit': json['packagingUnit'],
        'packagingType': json['packagingType'],
        'productManufacturers': json['productManufacturers'],
        'productThemes': json['productThemes'],
        'productTypes': json['productTypes'],
        'isUnlisted': json['isUnlisted'],
        'unlistedAt': !exists(json, 'unlistedAt') ? undefined : (new Date(json['unlistedAt'])),
        'imageDownloadUrl': !exists(json, 'imageDownloadUrl') ? undefined : json['imageDownloadUrl'],
    };
}

export function ProductSummaryToJSON(value?: ProductSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'name': value.name,
        'description': value.description,
        'imageUrl': value.imageUrl,
        'thumbnailUrl': value.thumbnailUrl,
        'gtin': value.gtin,
        'tariffNumber': value.tariffNumber,
        'weight': value.weight,
        'dimensions': value.dimensions,
        'packagingUnit': value.packagingUnit,
        'packagingType': value.packagingType,
        'productManufacturers': value.productManufacturers,
        'productThemes': value.productThemes,
        'productTypes': value.productTypes,
        'isUnlisted': value.isUnlisted,
        'unlistedAt': value.unlistedAt === undefined ? undefined : (value.unlistedAt.toISOString()),
        'imageDownloadUrl': value.imageDownloadUrl,
    };
}

