import { LoadingOverlay } from "@mantine/core";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { RoutesEnum } from "../RoutesEnum";
import { useIsAuthenticated } from "./useIsAuthenticated";

const GuestRoute: React.FC = () => {
  const { isAuthenticated, isLoading } = useIsAuthenticated();

  if (isLoading) return <LoadingOverlay visible />;
  if (isAuthenticated) return <Navigate to={RoutesEnum.DASHBOARD} replace />;

  return <Outlet />;
};

export { GuestRoute };
