import { Paper, Stack, Text } from "@mantine/core";
import { clsx } from "clsx";

import { CancellationRateStatus } from "../../../CancellationRateStatus";

import styles from "./CancellationRateAlertBox.module.css";

type CancellationRateAlertBoxProps = {
  status: CancellationRateStatus;
  title: string;
  descripton?: string;
};

const CancellationRateAlertBox: React.FC<CancellationRateAlertBoxProps> = (props) => {
  const { status, title, descripton } = props;

  const boxClasses = clsx(
    styles.box,
    { [styles.green]: status === CancellationRateStatus.GOOD },
    {
      [styles.orange]: status === CancellationRateStatus.MEDIUM,
    },
    {
      [styles.red]: status === CancellationRateStatus.BAD,
    },
  );

  return (
    <Paper className={boxClasses}>
      <Stack gap="0">
        <Text fz="md" fw="bold">
          {title}
        </Text>
        <Text size={"md"}>{descripton}</Text>
      </Stack>
    </Paper>
  );
};

export { CancellationRateAlertBox };
