import { OrderOrigin } from "~/api/openapi";
import { OrderStatusEnum } from "~/ui/pages/CustomerOrdersPage/OrderStatusBadge/OrderStatusEnum";

import { CustomerOrdersPageDE } from "../de/de.customerOdersPage";

export const CustomerOrdersPageEN: typeof CustomerOrdersPageDE = {
  pageTitle: "My orders",
  title: "My orders ({{ count }})",
  sort: {
    orderedDate: "Order date",
    orderNumber: "Order no.",
    totalAmount: "Order total",
    orderStatus: "Status",
    customerReference: "Order reference",
  },
  card: {
    totalAmount: "Order total",
    orderStatus: "Status",
    customerReference: "Order reference",
  },
  filter: {
    orderedDate: "Order date",
    searchPlaceholder: "Search orders (at least 3 characters)",
    title: "Filte & Sort",
    showOnlyUnconfirmedSegmentOn: "Unconfirmed/partially confirmed articles",
    showOnlyUnconfirmedSegmentOff: "All",
  },
  table: {
    head: {
      orderedDate: "Order date",
      number: "Order no.",
      articles: "Articles",
      amount: "Order total",
      orderStatus: "Status",
      reference: "Order reference",
    },
    body: {
      noOrdersFound: "No orders found that match your search criteria.",
      noOrdersAvailable: "No orders available yet.",
      originTooltip: {
        API: "Order was placed via the API.",
      } as Record<OrderOrigin, string>,
      orderStatus: {
        badge: {
          CONFIRMED: "Confirmed",
          UNCONFIRMED: "Unconfirmed",
          PARTLY_CONFIRMED: "Partially confirmed",
        } as Record<OrderStatusEnum, string>,
        partlyConfirmedInfo: "{{count}} confirmed by {{maxCount}}",
      },
    },
  },
  detail: {
    mobile: {
      head: {
        itemCountLabel: "Number of items:",
        itemCountValue: "{{ itemCount }} of {{totalCount}}",
        totalAmountLabel: "Total value:",
        shipmentToLabel: "Delivery to:",
        shipmentToValue: "{{name}} <br /> {{zipCode}} {{city}}",
        orderRefLabel: "Order reference:",
      },
      content: {
        quantity: "Order quantity",
        acceptedQuantity: "Confirmed quantity",
        shippedQuantity: "Shipped quantity",
        unitPrice: "Unit price",
        discount: "Discount",
        itemTotal: "Total",
      },
    },
    searchPlaceholder: "Search order items (at least 3 characters)",
    orderNumber: "Order: {{ orderNumber }}",
    itemCount: "Number of items: {{ itemCount }} of {{totalCount}}",
    noDataFound: "No order items found that match your search criteria.",
    shipmentTo: "Delivery to: {{name}}, {{zipCode}} {{city}}",
    orderRef: "Order reference: {{orderRef}}",
    totalQuantity: "Total quantity: {{totalQuantity}}",
    totalValue: "Total value:",
    articleNumber: "{{ articleNumber }}",
    quantity: "Order quantity",
    acceptedQuantity: "Confirmed quantity",
    shippedQuantity: "Shipped quantity",
    itemRef: "Item reference",
    unitPrice: "Unit price",
    discount: "Discount",
    itemTotal: "Total",
    showUnconfirmedArticleToggle: "Show only unconfirmed/partially confirmed items",
  },
  csv: {
    downloadFileName: "heo-csv-export-customerorders_{{date}}",
    tooltipDownloadButton: "CSV export (data from search result)",
    customerOrderNumber: "Order number",
    customerReference: "Order reference",
    itemCustomerItemReference: "Item reference",
    createdAt: "Order date",
    total: "Order amount",
    shippingAddress: "Shipping address",
    itemQuantity: "Order quantity",
    itemPricePerUnit: "Unit price",
    itemTotal: "Total",
    itemHeoPositionNumberCompanyCode: "itemHeoPositionNumberCompanyCode",
    itemHeoPositionNumberNumber: "itemHeoPositionNumberNumber",
    itemStatus: "itemStatus",
    itemDiscount: "Discount",
    itemTax: "itemTax",
    itemAcceptedQuantity: "Confirmed quantity",
    shippedQuantity: "Shipped quantity",
    itemProductNumber: "Item number",
    itemProductName: "Item description",
    gtin: "GTIN",
    orderOrigin: "Origin",
    orderOriginValue: {
      API: "API",
      SHOP: "Shop",
    },
    orderType: "Typ",
    orderTypeValue: {
      DROPSHIPPING: "Dropshipping",
      NORMAL: "Normal",
    },
  },
};
