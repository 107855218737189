/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PagedResultInvoiceItemSummary,
  SortDirection,
} from '../models';
import {
    PagedResultInvoiceItemSummaryFromJSON,
    PagedResultInvoiceItemSummaryToJSON,
    SortDirectionFromJSON,
    SortDirectionToJSON,
} from '../models';

export interface GetInvoiceItemProjectionOfCurrentRetailerRequest {
    offset?: number;
    limit?: number;
    parentInvoiceId?: string;
    productNumber?: string;
    fulltextQuery?: string;
    sortByHeoItemId?: SortDirection;
    sortByInvoiceCreatedAt?: SortDirection;
    sortByInvoiceNumber?: SortDirection;
    sortByInvoiceTotal?: SortDirection;
    sortByCustomerOrderNumber?: SortDirection;
    sortByProductOriginCountry?: SortDirection;
    sortByProductTariffNumber?: SortDirection;
    sortByQuantity?: SortDirection;
    sortByPricePerUnit?: SortDirection;
    sortByDiscount?: SortDirection;
    sortByDiscountedPricePerUnit?: SortDirection;
    sortByTotal?: SortDirection;
    sortByCustomerItemReference?: SortDirection;
    sortByCustomerReference?: SortDirection;
    sortByProductNumber?: SortDirection;
}

/**
 * 
 */
export class InvoiceItemResourceApi extends runtime.BaseAPI {

    /**
     */
    async getInvoiceItemProjectionOfCurrentRetailerRaw(requestParameters: GetInvoiceItemProjectionOfCurrentRetailerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagedResultInvoiceItemSummary>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.parentInvoiceId !== undefined) {
            queryParameters['parentInvoiceId'] = requestParameters.parentInvoiceId;
        }

        if (requestParameters.productNumber !== undefined) {
            queryParameters['productNumber'] = requestParameters.productNumber;
        }

        if (requestParameters.fulltextQuery !== undefined) {
            queryParameters['fulltextQuery'] = requestParameters.fulltextQuery;
        }

        if (requestParameters.sortByHeoItemId !== undefined) {
            queryParameters['sortByHeoItemId'] = requestParameters.sortByHeoItemId;
        }

        if (requestParameters.sortByInvoiceCreatedAt !== undefined) {
            queryParameters['sortByInvoiceCreatedAt'] = requestParameters.sortByInvoiceCreatedAt;
        }

        if (requestParameters.sortByInvoiceNumber !== undefined) {
            queryParameters['sortByInvoiceNumber'] = requestParameters.sortByInvoiceNumber;
        }

        if (requestParameters.sortByInvoiceTotal !== undefined) {
            queryParameters['sortByInvoiceTotal'] = requestParameters.sortByInvoiceTotal;
        }

        if (requestParameters.sortByCustomerOrderNumber !== undefined) {
            queryParameters['sortByCustomerOrderNumber'] = requestParameters.sortByCustomerOrderNumber;
        }

        if (requestParameters.sortByProductOriginCountry !== undefined) {
            queryParameters['sortByProductOriginCountry'] = requestParameters.sortByProductOriginCountry;
        }

        if (requestParameters.sortByProductTariffNumber !== undefined) {
            queryParameters['sortByProductTariffNumber'] = requestParameters.sortByProductTariffNumber;
        }

        if (requestParameters.sortByQuantity !== undefined) {
            queryParameters['sortByQuantity'] = requestParameters.sortByQuantity;
        }

        if (requestParameters.sortByPricePerUnit !== undefined) {
            queryParameters['sortByPricePerUnit'] = requestParameters.sortByPricePerUnit;
        }

        if (requestParameters.sortByDiscount !== undefined) {
            queryParameters['sortByDiscount'] = requestParameters.sortByDiscount;
        }

        if (requestParameters.sortByDiscountedPricePerUnit !== undefined) {
            queryParameters['sortByDiscountedPricePerUnit'] = requestParameters.sortByDiscountedPricePerUnit;
        }

        if (requestParameters.sortByTotal !== undefined) {
            queryParameters['sortByTotal'] = requestParameters.sortByTotal;
        }

        if (requestParameters.sortByCustomerItemReference !== undefined) {
            queryParameters['sortByCustomerItemReference'] = requestParameters.sortByCustomerItemReference;
        }

        if (requestParameters.sortByCustomerReference !== undefined) {
            queryParameters['sortByCustomerReference'] = requestParameters.sortByCustomerReference;
        }

        if (requestParameters.sortByProductNumber !== undefined) {
            queryParameters['sortByProductNumber'] = requestParameters.sortByProductNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/invoice-item`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagedResultInvoiceItemSummaryFromJSON(jsonValue));
    }

    /**
     */
    async getInvoiceItemProjectionOfCurrentRetailer(requestParameters: GetInvoiceItemProjectionOfCurrentRetailerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagedResultInvoiceItemSummary> {
        const response = await this.getInvoiceItemProjectionOfCurrentRetailerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
