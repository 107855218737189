/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactDetails } from './ContactDetails';
import {
    ContactDetailsFromJSON,
    ContactDetailsFromJSONTyped,
    ContactDetailsToJSON,
} from './ContactDetails';
import type { ContactPermissions } from './ContactPermissions';
import {
    ContactPermissionsFromJSON,
    ContactPermissionsFromJSONTyped,
    ContactPermissionsToJSON,
} from './ContactPermissions';
import type { HeoCustomerNumber } from './HeoCustomerNumber';
import {
    HeoCustomerNumberFromJSON,
    HeoCustomerNumberFromJSONTyped,
    HeoCustomerNumberToJSON,
} from './HeoCustomerNumber';

/**
 * 
 * @export
 * @interface AccountDetails
 */
export interface AccountDetails {
    /**
     * 
     * @type {string}
     * @memberof AccountDetails
     */
    id: string;
    /**
     * 
     * @type {HeoCustomerNumber}
     * @memberof AccountDetails
     */
    customerNumber: HeoCustomerNumber;
    /**
     * 
     * @type {string}
     * @memberof AccountDetails
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDetails
     */
    email: string;
    /**
     * 
     * @type {ContactDetails}
     * @memberof AccountDetails
     */
    contactDetails: ContactDetails;
    /**
     * 
     * @type {ContactPermissions}
     * @memberof AccountDetails
     */
    contactPermissions: ContactPermissions;
}

/**
 * Check if a given object implements the AccountDetails interface.
 */
export function instanceOfAccountDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "customerNumber" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "contactDetails" in value;
    isInstance = isInstance && "contactPermissions" in value;

    return isInstance;
}

export function AccountDetailsFromJSON(json: any): AccountDetails {
    return AccountDetailsFromJSONTyped(json, false);
}

export function AccountDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerNumber': HeoCustomerNumberFromJSON(json['customerNumber']),
        'username': json['username'],
        'email': json['email'],
        'contactDetails': ContactDetailsFromJSON(json['contactDetails']),
        'contactPermissions': ContactPermissionsFromJSON(json['contactPermissions']),
    };
}

export function AccountDetailsToJSON(value?: AccountDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerNumber': HeoCustomerNumberToJSON(value.customerNumber),
        'username': value.username,
        'email': value.email,
        'contactDetails': ContactDetailsToJSON(value.contactDetails),
        'contactPermissions': ContactPermissionsToJSON(value.contactPermissions),
    };
}

