/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductClaimsOverview
 */
export interface ProductClaimsOverview {
    /**
     * 
     * @type {number}
     * @memberof ProductClaimsOverview
     */
    numOpen: number;
    /**
     * 
     * @type {number}
     * @memberof ProductClaimsOverview
     */
    numInProgress: number;
    /**
     * 
     * @type {number}
     * @memberof ProductClaimsOverview
     */
    numWaitingForSupplies: number;
    /**
     * 
     * @type {number}
     * @memberof ProductClaimsOverview
     */
    numRetailerActionRequired: number;
    /**
     * 
     * @type {number}
     * @memberof ProductClaimsOverview
     */
    totalSumOfClaims: number;
}

/**
 * Check if a given object implements the ProductClaimsOverview interface.
 */
export function instanceOfProductClaimsOverview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "numOpen" in value;
    isInstance = isInstance && "numInProgress" in value;
    isInstance = isInstance && "numWaitingForSupplies" in value;
    isInstance = isInstance && "numRetailerActionRequired" in value;
    isInstance = isInstance && "totalSumOfClaims" in value;

    return isInstance;
}

export function ProductClaimsOverviewFromJSON(json: any): ProductClaimsOverview {
    return ProductClaimsOverviewFromJSONTyped(json, false);
}

export function ProductClaimsOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductClaimsOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numOpen': json['numOpen'],
        'numInProgress': json['numInProgress'],
        'numWaitingForSupplies': json['numWaitingForSupplies'],
        'numRetailerActionRequired': json['numRetailerActionRequired'],
        'totalSumOfClaims': json['totalSumOfClaims'],
    };
}

export function ProductClaimsOverviewToJSON(value?: ProductClaimsOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numOpen': value.numOpen,
        'numInProgress': value.numInProgress,
        'numWaitingForSupplies': value.numWaitingForSupplies,
        'numRetailerActionRequired': value.numRetailerActionRequired,
        'totalSumOfClaims': value.totalSumOfClaims,
    };
}

