export const LoginPageDE = {
  emailPlaceholder: "E-Mail",
  passwordPlaceholder: "Passwort",
  staySignedInLabel: "Angemeldet bleiben",
  forgotPassword: "Passwort vergessen?",
  loginButton: "Anmelden",
  invalidCredentialsError: "Der eingegebene Benutzername und/oder das Passwort sind ungültig",
  footer: {
    noCustomerYet: "Noch kein Kunde?",
    retailRegisterLink: "Hier geht es zur Händler-Registrierung",
  },
};
