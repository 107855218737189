import { ReactNode } from "react";

import { TableRowType } from "..";
import { DataTableColumn } from "../interface/DataTableColumn";

export function getRowContent<RowType extends TableRowType = TableRowType>(
  row: RowType,
  column: DataTableColumn<RowType>,
  isSelected: boolean,
  isHovered: boolean,
): ReactNode {
  const rowValue = row[column.accessor];

  if (column.cell) {
    return column.cell({ row, value: rowValue, isSelected, isHovered });
  }

  if (typeof rowValue !== "string" && typeof rowValue !== "number") {
    return "";
  }

  return rowValue;
}
