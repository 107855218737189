/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Credentials,
} from '../models';
import {
    CredentialsFromJSON,
    CredentialsToJSON,
} from '../models';

export interface LoginRequest {
    credentials: Credentials;
}

export interface ShopLoginRequest {
    credentials: Credentials;
}

/**
 * 
 */
export class LoginResourceApi extends runtime.BaseAPI {

    /**
     */
    async loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.credentials === null || requestParameters.credentials === undefined) {
            throw new runtime.RequiredError('credentials','Required parameter requestParameters.credentials was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CredentialsToJSON(requestParameters.credentials),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async login(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.loginRaw(requestParameters, initOverrides);
    }

    /**
     */
    async shopLoginRaw(requestParameters: ShopLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.credentials === null || requestParameters.credentials === undefined) {
            throw new runtime.RequiredError('credentials','Required parameter requestParameters.credentials was null or undefined when calling shopLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/shop-login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CredentialsToJSON(requestParameters.credentials),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shopLogin(requestParameters: ShopLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.shopLoginRaw(requestParameters, initOverrides);
    }

}
