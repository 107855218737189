/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RetailerNotificationMessage,
} from '../models';
import {
    RetailerNotificationMessageFromJSON,
    RetailerNotificationMessageToJSON,
} from '../models';

export interface GetNotificationsOnDisplayRequest {
    langCode: string;
}

export interface MarkRetailerNotificationAsReadRequest {
    notificationId: number;
}

/**
 * 
 */
export class RetailerNotificationResourceApi extends runtime.BaseAPI {

    /**
     */
    async getNotificationsOnDisplayRaw(requestParameters: GetNotificationsOnDisplayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RetailerNotificationMessage>>> {
        if (requestParameters.langCode === null || requestParameters.langCode === undefined) {
            throw new runtime.RequiredError('langCode','Required parameter requestParameters.langCode was null or undefined when calling getNotificationsOnDisplay.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/notification/{langCode}`.replace(`{${"langCode"}}`, encodeURIComponent(String(requestParameters.langCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RetailerNotificationMessageFromJSON));
    }

    /**
     */
    async getNotificationsOnDisplay(requestParameters: GetNotificationsOnDisplayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RetailerNotificationMessage>> {
        const response = await this.getNotificationsOnDisplayRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async markRetailerNotificationAsReadRaw(requestParameters: MarkRetailerNotificationAsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling markRetailerNotificationAsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/notification/{notificationId}/read`.replace(`{${"notificationId"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async markRetailerNotificationAsRead(requestParameters: MarkRetailerNotificationAsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.markRetailerNotificationAsReadRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
