import { Group, Text, ThemeIcon } from "@mantine/core";
import { IconExclamationMark } from "@tabler/icons-react";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";

import { CancellationRateStatus } from "../../CancellationRateStatus";
import { getCancellationRateStatus } from "../../getCancellationRateStatus/getCancellationRateStatus";

import styles from "./CancellationRateInfoBadge.module.css";

type CancellationRateInfoBadgeProps = {
  cancellationRate: number | undefined;
};

const CancellationRateInfoBadge: React.FC<CancellationRateInfoBadgeProps> = (props) => {
  const { cancellationRate } = props;

  if (cancellationRate === undefined) return null;
  const { t } = useTranslation(undefined, {
    keyPrefix: "dashboardPage.totalSalesReportWidget.table",
  });

  const status = getCancellationRateStatus(cancellationRate);

  const quotaInfoClasses = clsx(
    styles.box,
    { [styles.green]: status === CancellationRateStatus.GOOD },
    {
      [styles.orange]: status === CancellationRateStatus.MEDIUM,
    },
    {
      [styles.red]: status === CancellationRateStatus.BAD,
    },
  );

  const iconColor = status === CancellationRateStatus.MEDIUM ? "orange" : "red";
  const showIcon = status !== CancellationRateStatus.GOOD;

  return (
    <Group className={quotaInfoClasses} justify={"center"} gap={"6"}>
      <Text fw="bold" className={styles.quotaText}>
        {t("cancellationRate", { cancellationRate: cancellationRate })}
      </Text>

      {showIcon && (
        <ThemeIcon radius={"md"} variant="white" c={iconColor} size="sm">
          <IconExclamationMark />
        </ThemeIcon>
      )}
    </Group>
  );
};

export { CancellationRateInfoBadge };
