import {
  CUSTOMER_ORDERS_DETAIL_PAGE_SEARCH_QUERY_KEY,
  CUSTOMER_ORDERS_PAGE_PERIOD_QUERY_KEY,
  CUSTOMER_ORDERS_PAGE_SEARCH_QUERY_KEY,
  CUSTOMER_ORDERS_PAGE_SELECTED_QUERY_KEY,
} from "~/ui/pages/CustomerOrdersPage/CustomerOrdersPageQueryKeys";

import { RoutesEnum } from "../RoutesEnum";
import { createDynamicRoute } from "./util/createDynamicRoute";

const createCustomerOrderPageLink = createDynamicRoute({
  url: RoutesEnum.CUSTOMER_ORDERS,
  params: {
    period: CUSTOMER_ORDERS_PAGE_PERIOD_QUERY_KEY,
    selected: CUSTOMER_ORDERS_PAGE_SELECTED_QUERY_KEY,
    search: CUSTOMER_ORDERS_PAGE_SEARCH_QUERY_KEY,
    detailSearch: CUSTOMER_ORDERS_DETAIL_PAGE_SEARCH_QUERY_KEY,
  },
});

export { createCustomerOrderPageLink };
