import { Box, Group, MantineColor, Stack, Text } from "@mantine/core";
import React from "react";

import styles from "./LimitBar.module.css";

export type LimitBarItem = {
  value: number;
  formattedValue: string;
  label: string;
  color: MantineColor;
};

export interface LimitBarProps {
  leftItem: LimitBarItem;
  rightItem: LimitBarItem;
}

const LimitBar: React.FC<LimitBarProps> = (props) => {
  const { leftItem, rightItem } = props;

  const factor = leftItem.value / (rightItem.value + leftItem.value);
  const minMaxFactor = Math.min(Math.max(factor, 0.02), 0.98);

  return (
    <Stack gap={"xs"}>
      <Box className={styles.barContainer} bg={rightItem.color}>
        <Box className={styles.usedBar} w={`${minMaxFactor * 100}%`} bg={leftItem.color} />
      </Box>

      <Group justify="space-between">
        <Stack gap={0}>
          <Text fz={"xl"} fw={"bold"} c={leftItem.color}>
            {leftItem.formattedValue}
          </Text>
          <Text c={"dimmed"}>{leftItem.label}</Text>
        </Stack>

        <Stack gap={0}>
          <Text ta="right" size={"xl"} fw={"bold"} c={rightItem.color}>
            {rightItem.formattedValue}
          </Text>
          <Text ta="right" c={"dimmed"}>
            {rightItem.label}
          </Text>
        </Stack>
      </Group>
    </Stack>
  );
};

export { LimitBar };
