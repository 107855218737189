/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChangeRequestOpeningDay } from './ChangeRequestOpeningDay';
import {
    ChangeRequestOpeningDayFromJSON,
    ChangeRequestOpeningDayFromJSONTyped,
    ChangeRequestOpeningDayToJSON,
} from './ChangeRequestOpeningDay';

/**
 * 
 * @export
 * @interface ChangeRequestOpeningHourRequest
 */
export interface ChangeRequestOpeningHourRequest {
    /**
     * 
     * @type {ChangeRequestOpeningDay}
     * @memberof ChangeRequestOpeningHourRequest
     */
    monday?: ChangeRequestOpeningDay;
    /**
     * 
     * @type {ChangeRequestOpeningDay}
     * @memberof ChangeRequestOpeningHourRequest
     */
    tuesday?: ChangeRequestOpeningDay;
    /**
     * 
     * @type {ChangeRequestOpeningDay}
     * @memberof ChangeRequestOpeningHourRequest
     */
    wednesday?: ChangeRequestOpeningDay;
    /**
     * 
     * @type {ChangeRequestOpeningDay}
     * @memberof ChangeRequestOpeningHourRequest
     */
    thursday?: ChangeRequestOpeningDay;
    /**
     * 
     * @type {ChangeRequestOpeningDay}
     * @memberof ChangeRequestOpeningHourRequest
     */
    friday?: ChangeRequestOpeningDay;
    /**
     * 
     * @type {ChangeRequestOpeningDay}
     * @memberof ChangeRequestOpeningHourRequest
     */
    saturday?: ChangeRequestOpeningDay;
    /**
     * 
     * @type {ChangeRequestOpeningDay}
     * @memberof ChangeRequestOpeningHourRequest
     */
    sunday?: ChangeRequestOpeningDay;
}

/**
 * Check if a given object implements the ChangeRequestOpeningHourRequest interface.
 */
export function instanceOfChangeRequestOpeningHourRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeRequestOpeningHourRequestFromJSON(json: any): ChangeRequestOpeningHourRequest {
    return ChangeRequestOpeningHourRequestFromJSONTyped(json, false);
}

export function ChangeRequestOpeningHourRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestOpeningHourRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monday': !exists(json, 'monday') ? undefined : ChangeRequestOpeningDayFromJSON(json['monday']),
        'tuesday': !exists(json, 'tuesday') ? undefined : ChangeRequestOpeningDayFromJSON(json['tuesday']),
        'wednesday': !exists(json, 'wednesday') ? undefined : ChangeRequestOpeningDayFromJSON(json['wednesday']),
        'thursday': !exists(json, 'thursday') ? undefined : ChangeRequestOpeningDayFromJSON(json['thursday']),
        'friday': !exists(json, 'friday') ? undefined : ChangeRequestOpeningDayFromJSON(json['friday']),
        'saturday': !exists(json, 'saturday') ? undefined : ChangeRequestOpeningDayFromJSON(json['saturday']),
        'sunday': !exists(json, 'sunday') ? undefined : ChangeRequestOpeningDayFromJSON(json['sunday']),
    };
}

export function ChangeRequestOpeningHourRequestToJSON(value?: ChangeRequestOpeningHourRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monday': ChangeRequestOpeningDayToJSON(value.monday),
        'tuesday': ChangeRequestOpeningDayToJSON(value.tuesday),
        'wednesday': ChangeRequestOpeningDayToJSON(value.wednesday),
        'thursday': ChangeRequestOpeningDayToJSON(value.thursday),
        'friday': ChangeRequestOpeningDayToJSON(value.friday),
        'saturday': ChangeRequestOpeningDayToJSON(value.saturday),
        'sunday': ChangeRequestOpeningDayToJSON(value.sunday),
    };
}

