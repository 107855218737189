import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "~/store/store";

import { ClaimableProductItem } from "../interface/ClaimableProductItem";
import {
  ProductClaimFormDict,
  ProductClaimFormFile,
  ProductClaimFormValues,
} from "../interface/ProductClaimFormData";

export interface CreateClaimsPageState {
  selectedClaimableProductItems: ClaimableProductItem[];
  productClaimDetailsDict: ProductClaimFormDict;
}

export const initialCreateClaimsPageState: CreateClaimsPageState = {
  selectedClaimableProductItems: [],
  productClaimDetailsDict: {},
};

export type UpdateClaimPayload = {
  id: string;
  index: number;
  formData: Partial<ProductClaimFormValues>;
};

export type AddClaimPayload = {
  id: string;
  formData?: Partial<ProductClaimFormValues>;
};

export type MarkClaimFormAsTouchedPayload = {
  id: string;
  index: number;
};
export type RemoveClaimPayload = {
  id: string;
  index: number;
};
export type RemoveClaimGroupPayload = {
  invoiceItemId: string;
};

export type UpdateClaimFormErrors = {
  id: string;
  index: number;
  hasErrors: boolean;
};

export type SetFilesPayload = {
  id: string;
  index: number;
  files: ProductClaimFormFile[];
};

export type AddFilesPayload = SetFilesPayload;

export type RemoveFilePayload = {
  id: string;
  index: number;
  fileSource: string;
};

const CreateClaimsPageSlice = createSlice({
  name: "createClaimsPage",
  initialState: initialCreateClaimsPageState,
  reducers: {
    toggleSelectedItem: (state, action: PayloadAction<ClaimableProductItem>) => {
      const existingIndex = state.selectedClaimableProductItems.findIndex(
        (it) => it.id === action.payload.id,
      );

      if (existingIndex >= 0) {
        state.selectedClaimableProductItems.splice(existingIndex, 1);
        delete state.productClaimDetailsDict[action.payload.id];
      } else {
        state.selectedClaimableProductItems.push(action.payload);
        state.productClaimDetailsDict[action.payload.id] = [
          {
            formData: {},
            hasErrors: false,
            wasTouched: false,
          },
        ];
      }
    },

    setSelectedClaimableProductItems: (state, action: PayloadAction<ClaimableProductItem[]>) => {
      state.selectedClaimableProductItems = action.payload;

      for (const invoiceItemId of Object.keys(state.productClaimDetailsDict)) {
        const actionTarget = action.payload.find((actionItem) => actionItem.id === invoiceItemId);

        if (!actionTarget) {
          delete state.productClaimDetailsDict[invoiceItemId];
        }
      }

      for (const productItem of action.payload) {
        if (!state.productClaimDetailsDict[productItem.id]) {
          state.productClaimDetailsDict[productItem.id] = [
            {
              formData: {},
              hasErrors: false,
              wasTouched: false,
            },
          ];
        }
      }
    },

    addClaim: (state, action: PayloadAction<AddClaimPayload>) => {
      const { id, formData } = action.payload;
      state.productClaimDetailsDict[id].push({
        formData: formData ?? {},
        hasErrors: false,
        wasTouched: formData !== undefined,
      });
    },

    resetClaim: (state, action: PayloadAction<UpdateClaimPayload>) => {
      const { id, index } = action.payload;
      state.productClaimDetailsDict[id][index].formData = {};
    },

    updateClaim: (state, action: PayloadAction<UpdateClaimPayload>) => {
      const { id, index, formData } = action.payload;
      state.productClaimDetailsDict[id][index].formData = {
        ...state.productClaimDetailsDict[id][index].formData,
        ...formData,
      };
    },

    removeClaim: (state, action: PayloadAction<RemoveClaimPayload>) => {
      const { id, index } = action.payload;
      state.productClaimDetailsDict[id].splice(index, 1);
    },

    removeClaimGroup: (state, action: PayloadAction<RemoveClaimGroupPayload>) => {
      const { invoiceItemId } = action.payload;
      delete state.productClaimDetailsDict[invoiceItemId];
      state.selectedClaimableProductItems = state.selectedClaimableProductItems.filter(
        (item) => item.id !== invoiceItemId,
      );
    },

    markClaimFormAsTouched: (state, action: PayloadAction<MarkClaimFormAsTouchedPayload>) => {
      const { id, index } = action.payload;
      if (!state.productClaimDetailsDict[id][index].wasTouched) {
        state.productClaimDetailsDict[id][index].wasTouched = true;
        state.productClaimDetailsDict[id][index].hasErrors = true;
      }
    },

    updateClaimFormErrors: (state, action: PayloadAction<UpdateClaimFormErrors>) => {
      const { id, index, hasErrors } = action.payload;
      state.productClaimDetailsDict[id][index].hasErrors = hasErrors;
    },

    setFiles: (state, action: PayloadAction<SetFilesPayload>) => {
      const { id, index, files } = action.payload;
      state.productClaimDetailsDict[id][index].formData.files = files;
    },

    addFiles: (state, action: PayloadAction<AddFilesPayload>) => {
      const { id, index, files } = action.payload;
      if (!state.productClaimDetailsDict[id][index].formData.files) {
        state.productClaimDetailsDict[id][index].formData.files = files;
      } else {
        state.productClaimDetailsDict[id][index].formData.files?.push(...files);
      }
    },

    removeFile: (state, action: PayloadAction<RemoveFilePayload>) => {
      const { id, index, fileSource } = action.payload;
      const files = state.productClaimDetailsDict[id][index].formData.files;
      const targetIndex = files?.findIndex((file) => file.src === fileSource);
      if (files && targetIndex !== undefined && targetIndex >= 0) {
        files.splice(targetIndex, 1);
      }
    },

    clearSelection: (state) => {
      state.selectedClaimableProductItems = [];
      state.productClaimDetailsDict = {};
    },
  },
});

export const productClaimDetailsDictSelector = (state: RootState) =>
  state.createClaimsPage.productClaimDetailsDict;

export const selectedClaimableProductItemsSelector = (state: RootState) =>
  state.createClaimsPage.selectedClaimableProductItems;
export const CreateClaimsPageSliceActions = CreateClaimsPageSlice.actions;
export const CreateClaimsPageSliceReducer = CreateClaimsPageSlice.reducer;
