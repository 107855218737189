import { Box, Modal, ModalProps } from "@mantine/core";
import React, { ReactNode } from "react";
import { ModalFooter } from "./ModalFooter/ModalFooter";

import clsx from "clsx";
import { useResponsiveMode } from "~/hooks/ui/useResponsiveMode";
import { Scroller } from "../Scroller/Scroller";
import styles from "./HeoModal.module.css";

export interface HeoModalProps extends ModalProps {
  /**
   * If true the modal will be rendered in fullscreen in mobile view
   * @default true
   */
  mobileFullscreen?: boolean;

  /**
   * Optional modal footer elements
   */
  footer?: ReactNode;
}

const HeoModal: React.FC<HeoModalProps> = (props) => {
  const { mobileFullscreen = true, footer, children, ...modalProps } = props;

  const { isMobile } = useResponsiveMode();

  const rootClasses = clsx(
    modalProps.classNames?.root,
    styles.modalRoot,
    isMobile && styles.mobile,
  );
  const contentClasses = clsx(modalProps.classNames?.content, styles.modalContent);
  const headerClasses = clsx(
    modalProps.classNames?.header,
    styles.modalHeader,
    isMobile && styles.mobileHeader,
  );
  const bodyClasses = clsx(
    modalProps.classNames?.body,
    styles.modalBody,
    footer && styles.withFooter,
  );

  return (
    <Modal
      scrollAreaComponent={Scroller}
      {...modalProps}
      fullScreen={mobileFullscreen ? isMobile : modalProps.fullScreen}
      classNames={{
        ...modalProps.classNames,
        root: rootClasses,
        content: contentClasses,
        header: headerClasses,
        body: bodyClasses,
      }}
    >
      <Box className={styles.heoModalContent}>{children}</Box>

      {footer && <ModalFooter>{footer}</ModalFooter>}
    </Modal>
  );
};

export { HeoModal };
