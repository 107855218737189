import { Box, Button, Center } from "@mantine/core";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PeriodRange } from "~/common/classes/PeriodRange";
import { PeriodOption } from "~/common/enum/PeriodOption";
import { ResponsiveButtonGroup } from "~/ui/components/ResponsiveButtonGroup/ResponsiveButtonGroup";
import { createInvoiceLink } from "~/ui/routes/DynamicRoutes/createInvoiceLink";
import { WidgetContainer } from "../../WidgetContainer/WidgetContainer";
import { CurrentDeliveriesSkeleton } from "./CurrentDeliveriesSkeleton";
import { CurrentDeliveriesTimeline } from "./CurrentDeliveriesTimeline/CurrentDeliveriesTimeline";
import styles from "./CurrentDeliveriesWidget.module.css";
import emptyWidget from "./animations/emptyWidget.json";
import { useGetCurrentDeliveriesQuery } from "./api/useGetCurrentDeliveriesQuery";
const CurrentDeliveriesWidget = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "dashboardPage.CurrentDeliveriesWidget" });

  const createdPeriodRange = PeriodRange.periodKeyToRange(PeriodOption.SEVEN_DAYS);

  const { data, isLoading } = useGetCurrentDeliveriesQuery({
    createdFrom: createdPeriodRange.from,
    createdUntil: createdPeriodRange.until,
  });

  const quantity = data?.length ?? 0;
  const countInProgress = data?.filter((item) => !item.filed).length ?? 0;

  const getSubTitleText = (): string => {
    if (quantity === 0) return t("subtitle.noDeliveries");

    return t("subtitle.delivery", {
      count: quantity,
      countInProgress: countInProgress,
    });
  };

  return (
    <WidgetContainer title={t("title")} isLoading={isLoading} subtitle={getSubTitleText()}>
      <Box data-testid="timeline-wrapper" py={"md"}>
        {isLoading && <CurrentDeliveriesSkeleton />}

        {!isLoading && quantity === 0 && (
          <Center className={styles.noDataWrapper}>
            <Lottie animationData={emptyWidget} loop={false} style={{ height: 200 }} />
          </Center>
        )}
        {!isLoading && data && quantity !== 0 && <CurrentDeliveriesTimeline timelineData={data} />}
      </Box>
      <ResponsiveButtonGroup pt={"md"}>
        <Button
          variant={"outline"}
          component={Link}
          to={createInvoiceLink({ period: PeriodOption.ALL })}
        >
          {t("showAllDeliveriesButton")}
        </Button>
      </ResponsiveButtonGroup>
    </WidgetContainer>
  );
};

export { CurrentDeliveriesWidget };
