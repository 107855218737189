/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoicePrices
 */
export interface InvoicePrices {
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    grossAmount: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    undiscountedNetAmount: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    vatNormalPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    vatReducedPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    vatAmountOfDiscountedNetShareHavingNormalVat: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    vatAmountOfDiscountedNetShareHavingReducedVat: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    discountedNetShareHavingNormalVat: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    discountedNetShareHavingReducedVat: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    cashDiscountedGrossAmount: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    customerDiscountPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    customerDiscountAmount: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    discountedNetAmount: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    cashDiscountPercentage: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    freightSurchargeOfPositions: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePrices
     */
    shippingCost: number;
}

/**
 * Check if a given object implements the InvoicePrices interface.
 */
export function instanceOfInvoicePrices(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "grossAmount" in value;
    isInstance = isInstance && "undiscountedNetAmount" in value;
    isInstance = isInstance && "vatNormalPercentage" in value;
    isInstance = isInstance && "vatReducedPercentage" in value;
    isInstance = isInstance && "vatAmountOfDiscountedNetShareHavingNormalVat" in value;
    isInstance = isInstance && "vatAmountOfDiscountedNetShareHavingReducedVat" in value;
    isInstance = isInstance && "discountedNetShareHavingNormalVat" in value;
    isInstance = isInstance && "discountedNetShareHavingReducedVat" in value;
    isInstance = isInstance && "cashDiscountedGrossAmount" in value;
    isInstance = isInstance && "customerDiscountPercentage" in value;
    isInstance = isInstance && "customerDiscountAmount" in value;
    isInstance = isInstance && "discountedNetAmount" in value;
    isInstance = isInstance && "cashDiscountPercentage" in value;
    isInstance = isInstance && "freightSurchargeOfPositions" in value;
    isInstance = isInstance && "shippingCost" in value;

    return isInstance;
}

export function InvoicePricesFromJSON(json: any): InvoicePrices {
    return InvoicePricesFromJSONTyped(json, false);
}

export function InvoicePricesFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoicePrices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'grossAmount': json['grossAmount'],
        'undiscountedNetAmount': json['undiscountedNetAmount'],
        'vatNormalPercentage': json['vatNormalPercentage'],
        'vatReducedPercentage': json['vatReducedPercentage'],
        'vatAmountOfDiscountedNetShareHavingNormalVat': json['vatAmountOfDiscountedNetShareHavingNormalVat'],
        'vatAmountOfDiscountedNetShareHavingReducedVat': json['vatAmountOfDiscountedNetShareHavingReducedVat'],
        'discountedNetShareHavingNormalVat': json['discountedNetShareHavingNormalVat'],
        'discountedNetShareHavingReducedVat': json['discountedNetShareHavingReducedVat'],
        'cashDiscountedGrossAmount': json['cashDiscountedGrossAmount'],
        'customerDiscountPercentage': json['customerDiscountPercentage'],
        'customerDiscountAmount': json['customerDiscountAmount'],
        'discountedNetAmount': json['discountedNetAmount'],
        'cashDiscountPercentage': json['cashDiscountPercentage'],
        'freightSurchargeOfPositions': json['freightSurchargeOfPositions'],
        'shippingCost': json['shippingCost'],
    };
}

export function InvoicePricesToJSON(value?: InvoicePrices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'grossAmount': value.grossAmount,
        'undiscountedNetAmount': value.undiscountedNetAmount,
        'vatNormalPercentage': value.vatNormalPercentage,
        'vatReducedPercentage': value.vatReducedPercentage,
        'vatAmountOfDiscountedNetShareHavingNormalVat': value.vatAmountOfDiscountedNetShareHavingNormalVat,
        'vatAmountOfDiscountedNetShareHavingReducedVat': value.vatAmountOfDiscountedNetShareHavingReducedVat,
        'discountedNetShareHavingNormalVat': value.discountedNetShareHavingNormalVat,
        'discountedNetShareHavingReducedVat': value.discountedNetShareHavingReducedVat,
        'cashDiscountedGrossAmount': value.cashDiscountedGrossAmount,
        'customerDiscountPercentage': value.customerDiscountPercentage,
        'customerDiscountAmount': value.customerDiscountAmount,
        'discountedNetAmount': value.discountedNetAmount,
        'cashDiscountPercentage': value.cashDiscountPercentage,
        'freightSurchargeOfPositions': value.freightSurchargeOfPositions,
        'shippingCost': value.shippingCost,
    };
}

