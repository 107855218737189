import { PaymentType } from "~/api/openapi";

import { InvoicesPageDE } from "../de/de.invoicesPage";

export const InvoicesPageEN: typeof InvoicesPageDE = {
  filter: {
    title: "Filter & Sort",
    statusTiles: {
      overdueOfWhich: "of which",
      total: "total",
    },
    searchPlaceholder: "Search invoices (at least 3 characters)",
    statusFilterPlaceholder: "Filter status",
    invoiceCreatedAt: "Created at",
    status: "Status",
  },
  pageTitle: "My invoices",
  title: "My invoices ({{ count }})",

  piece: "piece",

  pdfDownload: {
    notFound: "No document exists for the invoice {{ invoiceNumber }}.",
  },

  sort: {
    invoiceDate: "Created at",
    invoiceNumber: "Invoice",
    totalAmount: "Amount",
    paymentMethod: "Payment method",
    paymentStatus: "Payment status",
    shippingStatus: "Shipping status",
    shippingAddress: "Shipping address",
    reference: "Reference",
  },
  card: {
    totalAmount: "Amount",
    paymentMethod: "Payment method",
    paymentStatus: "Payment status",
    shippingStatus: "Shipping status",
    reference: "Reference of invoice",
    showInvoiceButton: "Show invoice {{invoiceNumber}}",
    shippingAddress: "Shipping address",
  },
  table: {
    head: {
      date: "Date",
      number: "Invoice",
      amount: "Amount",
      paymentMethod: "Payment method",
      paymentStatus: "Payment status",
      shippingStatus: "Shipping status",
      shippingAddress: "Shipping address",
      reference: "Reference",
    },
    body: {
      noInvoicesAvailable: "No invoices available yet.",
      noInvoicesFound: "No invoices found that match your search criteria.",
      noPaymentMethod: "Unknown",
      paidAt: "on {{ date }}",
      canceled: "Cancelled",
      type: {
        [PaymentType.Prepayment]: "Payment term {{ date }}",
        [PaymentType.Factoring]: "Payment term {{ date }}",
        [PaymentType.Other]: "{{ date }}",
      } as Record<PaymentType, string>,
      cancellationReasons: {
        BY_HEO: "by heo",
        BY_CUSTOMER: "by customer",
        null: "---",
      } as Record<string, string>,
      shippingStatusInProgress: "In progress",
      shippingStatusShipped: "Shipped",
      shippingStatusTrackingHint_other: "{{ count }} Packages",
      shippingStatusTrackingHint_one: "{{ count }} Package",
    },
  },

  csv: {
    downloadFileName: "heo-csv-export-invoices_{{date}}",
    tooltipDownloadButton: "CSV export (data from search result)",
    invoiceDate: "Invoice date",
    invoiceNumber: "Invoice number",
    paymentDueDate: "Payment due date",
    paymentStatus: "Payment status",
    paymentDate: "paid on",
    paymentMethod: "Payment method",
    shippingAddress: "Shipping address",
    productNumber: "Item number",
    barcode: "Barcode",
    productName: "Item name",
    productManufacturers: "Manufacturer",
    originCountry: "Country of origin",
    tariffNumber: "HS code",
    quantity: "Quantity",
    pricePerUnit: "Unit price",
    discount: "Discount",
    discountedPricePerUnit: "Final price (incl. discount)",
    postionPrice: "Total price",
    reference: "Invoice reference",
    customerReference: "Order reference",
    customerItemReference: "Item reference",
    manufacturer: "Manufacturer",
    shippingStatus: "Shipping status",
  },

  detail: {
    invoiceNumber: "Invoice: {{ invoiceNumber }}",
    baseInformation: "Basic information",
    paymentStatus: "Payment status",
    paymentDueDatePrepayment: "Payment deadline",
    paymentDueDate: "Payment due date",
    canceled: "Cancelled",
    canceledReason: "Reason for cancellation",
    paymentMethod: "Payment method",
    paymentDate: "Incoming payment",
    totalAmount: "Total amount",
    undiscountedNetAmount: "Subtotal",
    discountedNetAmount: "Total",
    freightCharges: "plus shipping costs",
    freightSurcharge: "incl. freight surcharge",
    grantedDiscount: "granted discount",
    grantedDiscountPercentage: "({{percentage}}%)",
    addedTax: "plus {{taxRate}}% tax",
    reference: "Reference",
    invoiceItemCount: "Invoice items {{count}}",
    payNow: "Pay now",
    missingPackage: {
      reportPackageButton: "Report missing package",
      reportPackageButtonTooltip:
        "Report a package not received that should have already been delivered",
      modal: {
        title: "Report missing parcel",
        closeButton: "Close",
        sendButton: "Send",

        infoContent: {
          delayToReport: "Missing packages can be reported at the earliest 5 days after invoicing.",
          todoIntro:
            "If you have not yet received a parcel even though it should have been delivered already, you can do the following in advance:",
          listEntries: {
            1: "Check whether all consignment numbers show the status “Delivered“.",
            2: "Check the delivery address specified within the invoice.",
            3: "Check whether a delivery attempt message has been left",
            4: "Find out if the parcel has been accepted by someone else",
            5: "Contact the relevant shipping service provider and clarify the whereabouts of a parcel indicated as delivered. Have the consignment number or delivery confirmation ready.",
          } as Record<string, string>,
          infoDescription: "Still can't find the parcel?",
        },
        reportContent: {
          description:
            "We are sorry that you have not received all packages of the <strong>invoice {{invoiceNumber}}</strong>. Please fill in the following fields to inform us about the missing packages. We will contact you as soon as possible to solve the problem.",
          selectTrackingIdInfo_other:
            "Please select the consignment numbers for which you have not received any parcels:",
          selectTrackingIdInfo_one:
            "I would like to submit a message for the following consignment number:",
          commentLabel: "Please enter a short description (max. 500 characters)",
          commentPlaceholder: "Enter description",
          commentError: "Please enter a description with max. 500 characters",
          placeholderDropdown: "Select consignment numbers",
          nothingFound: "No consignment number found",
        },
      },
    },
    card: {
      orderNr: "Order no.",
      originCountry: "CO",
      manufacturerCode: "HS Code",
      quantity: "Quantity",
      unitPrice: "Unit price",
      discount: "Discount",
      discountedUnitPrice: "Final price",
      totalPrice: "Total price",
      customerReference: "Order reference",
      customerItemReference: "Item reference",
    },
    filter: {
      title: "Filter & Sort",
      searchPlaceholder: "Search invoice items (at least 3 characters)",
      noDataAvailable: "No invoice items found yet.",
      noDataFound: "No invoice items found that match your search criteria.",
    },
    sort: {
      orderNumber: "Order no.",
      originCountry: "CO",
      tariffNumber: "HS Code",
      quantity: "Quantity",
      unitPrice: "Unit price",
      discount: "Discount",
      discountedUnitPrice: "Final price",
      total: "Total price",
      customerReference: "Order reference",
      customerItemReference: "Item reference",
    },
    createNewClaimButton: "Claim item",
    createNewClaimButtonTooltip: "Claim one or more items from this invoice",
    noDataAvailable: "No invoice items found yet.",
    noDataFound: "No invoice items found that match your search criteria.",
    positionTable: {
      noDataAvailable: "No invoice items found yet.",
      noDataFound: "No invoice items found that match your search criteria.",
      titleWithTotalCount: "Invoice items ({{totalCount}})",
      titleWithItemCount: "Invoice items ({{ itemCount }} of {{totalCount}})",
      position: "Item",
      article: "Article",
      orderNr: "Order no.",
      originCountry: "CO",
      manufacturerCode: "HS Code",
      quantity: "Quantity",
      unitPrice: "Unit price",
      discount: "Discount",
      discountedUnitPrice: "Final price",
      totalPrice: "Total price",
      customerReference: "Order reference",
      customerItemReference: "Item reference",
      searchPlaceholder: "Search invoice items (at least 3 characters)",
      freightSurchargeTooltip:
        "Unit price = base price {{ basePrice }} + {{ percentage }} ({{ portion }}) freight surcharge",

      endOfTableTitle: "Not found what you are looking for?",
      endOfTableSubtitle: "Refine your results with the search filter and sorting!",
    },
    infoBox: {
      title: "Delivery and shipping information",
      selectedShippingAddress: "Selected delivery address",
      shipmentTracking: "Shipment tracking",
      noShipmentTracking: "No shipment tracking data available.",
    },
    cancellationReasons: {
      BY_HEO: "Cancellation by heo",
      BY_CUSTOMER: "Cancellation by customer",
      null: "---",
    } as Record<string, string>,
  },
};
