/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeoCustomerNumber } from './HeoCustomerNumber';
import {
    HeoCustomerNumberFromJSON,
    HeoCustomerNumberFromJSONTyped,
    HeoCustomerNumberToJSON,
} from './HeoCustomerNumber';

/**
 * 
 * @export
 * @interface ContactPerson
 */
export interface ContactPerson {
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    id: string;
    /**
     * 
     * @type {HeoCustomerNumber}
     * @memberof ContactPerson
     */
    customerNumber: HeoCustomerNumber;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    mail: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPerson
     */
    contactType: ContactPersonContactTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ContactPerson
     */
    createdAt: Date;
}


/**
 * @export
 */
export const ContactPersonContactTypeEnum = {
    Delivery: 'DELIVERY',
    Invoice: 'INVOICE',
    Contractual: 'CONTRACTUAL',
    Other: 'OTHER'
} as const;
export type ContactPersonContactTypeEnum = typeof ContactPersonContactTypeEnum[keyof typeof ContactPersonContactTypeEnum];


/**
 * Check if a given object implements the ContactPerson interface.
 */
export function instanceOfContactPerson(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "customerNumber" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "mail" in value;
    isInstance = isInstance && "contactType" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function ContactPersonFromJSON(json: any): ContactPerson {
    return ContactPersonFromJSONTyped(json, false);
}

export function ContactPersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactPerson {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerNumber': HeoCustomerNumberFromJSON(json['customerNumber']),
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'mail': json['mail'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'contactType': json['contactType'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function ContactPersonToJSON(value?: ContactPerson | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerNumber': HeoCustomerNumberToJSON(value.customerNumber),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'mail': value.mail,
        'phone': value.phone,
        'mobile': value.mobile,
        'contactType': value.contactType,
        'createdAt': (value.createdAt.toISOString()),
    };
}

