/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductOrderStatusSummary
 */
export interface ProductOrderStatusSummary {
    /**
     * 
     * @type {string}
     * @memberof ProductOrderStatusSummary
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOrderStatusSummary
     */
    productNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOrderStatusSummary
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof ProductOrderStatusSummary
     */
    productThumbnailUrl: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductOrderStatusSummary
     */
    productManufacturers: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductOrderStatusSummary
     */
    productThemes: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductOrderStatusSummary
     */
    productTypes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductOrderStatusSummary
     */
    requestedQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof ProductOrderStatusSummary
     */
    customerOrderCount: number;
    /**
     * 
     * @type {number}
     * @memberof ProductOrderStatusSummary
     */
    totalValueOfCustomerOrders: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductOrderStatusSummary
     */
    lastRequestedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof ProductOrderStatusSummary
     */
    acceptedQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof ProductOrderStatusSummary
     */
    salesOrderCount: number;
    /**
     * 
     * @type {number}
     * @memberof ProductOrderStatusSummary
     */
    totalValueOfSalesOrders: number;
    /**
     * 
     * @type {number}
     * @memberof ProductOrderStatusSummary
     */
    shippedQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof ProductOrderStatusSummary
     */
    processingQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof ProductOrderStatusSummary
     */
    outstandingQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof ProductOrderStatusSummary
     */
    claimCount: number;
}

/**
 * Check if a given object implements the ProductOrderStatusSummary interface.
 */
export function instanceOfProductOrderStatusSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "productNumber" in value;
    isInstance = isInstance && "productName" in value;
    isInstance = isInstance && "productThumbnailUrl" in value;
    isInstance = isInstance && "productManufacturers" in value;
    isInstance = isInstance && "productThemes" in value;
    isInstance = isInstance && "productTypes" in value;
    isInstance = isInstance && "requestedQuantity" in value;
    isInstance = isInstance && "customerOrderCount" in value;
    isInstance = isInstance && "totalValueOfCustomerOrders" in value;
    isInstance = isInstance && "acceptedQuantity" in value;
    isInstance = isInstance && "salesOrderCount" in value;
    isInstance = isInstance && "totalValueOfSalesOrders" in value;
    isInstance = isInstance && "shippedQuantity" in value;
    isInstance = isInstance && "processingQuantity" in value;
    isInstance = isInstance && "outstandingQuantity" in value;
    isInstance = isInstance && "claimCount" in value;

    return isInstance;
}

export function ProductOrderStatusSummaryFromJSON(json: any): ProductOrderStatusSummary {
    return ProductOrderStatusSummaryFromJSONTyped(json, false);
}

export function ProductOrderStatusSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductOrderStatusSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'productNumber': json['productNumber'],
        'productName': json['productName'],
        'productThumbnailUrl': json['productThumbnailUrl'],
        'productManufacturers': json['productManufacturers'],
        'productThemes': json['productThemes'],
        'productTypes': json['productTypes'],
        'requestedQuantity': json['requestedQuantity'],
        'customerOrderCount': json['customerOrderCount'],
        'totalValueOfCustomerOrders': json['totalValueOfCustomerOrders'],
        'lastRequestedAt': !exists(json, 'lastRequestedAt') ? undefined : (new Date(json['lastRequestedAt'])),
        'acceptedQuantity': json['acceptedQuantity'],
        'salesOrderCount': json['salesOrderCount'],
        'totalValueOfSalesOrders': json['totalValueOfSalesOrders'],
        'shippedQuantity': json['shippedQuantity'],
        'processingQuantity': json['processingQuantity'],
        'outstandingQuantity': json['outstandingQuantity'],
        'claimCount': json['claimCount'],
    };
}

export function ProductOrderStatusSummaryToJSON(value?: ProductOrderStatusSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'productNumber': value.productNumber,
        'productName': value.productName,
        'productThumbnailUrl': value.productThumbnailUrl,
        'productManufacturers': value.productManufacturers,
        'productThemes': value.productThemes,
        'productTypes': value.productTypes,
        'requestedQuantity': value.requestedQuantity,
        'customerOrderCount': value.customerOrderCount,
        'totalValueOfCustomerOrders': value.totalValueOfCustomerOrders,
        'lastRequestedAt': value.lastRequestedAt === undefined ? undefined : (value.lastRequestedAt.toISOString()),
        'acceptedQuantity': value.acceptedQuantity,
        'salesOrderCount': value.salesOrderCount,
        'totalValueOfSalesOrders': value.totalValueOfSalesOrders,
        'shippedQuantity': value.shippedQuantity,
        'processingQuantity': value.processingQuantity,
        'outstandingQuantity': value.outstandingQuantity,
        'claimCount': value.claimCount,
    };
}

