/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Changelog
 */
export interface Changelog {
    /**
     * 
     * @type {string}
     * @memberof Changelog
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Changelog
     */
    commitHash: string;
    /**
     * 
     * @type {string}
     * @memberof Changelog
     */
    changes: string;
    /**
     * 
     * @type {Date}
     * @memberof Changelog
     */
    releasedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Changelog
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Changelog
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the Changelog interface.
 */
export function instanceOfChangelog(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "commitHash" in value;
    isInstance = isInstance && "changes" in value;
    isInstance = isInstance && "releasedAt" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function ChangelogFromJSON(json: any): Changelog {
    return ChangelogFromJSONTyped(json, false);
}

export function ChangelogFromJSONTyped(json: any, ignoreDiscriminator: boolean): Changelog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'commitHash': json['commitHash'],
        'changes': json['changes'],
        'releasedAt': (new Date(json['releasedAt'])),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function ChangelogToJSON(value?: Changelog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'commitHash': value.commitHash,
        'changes': value.changes,
        'releasedAt': (value.releasedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

