import {
  Accordion,
  ActionIcon,
  Anchor,
  Button,
  CSSVariablesResolver,
  DefaultMantineColor,
  Image,
  Indicator,
  InputBase,
  LoadingOverlay,
  MantineColorsTuple,
  MantineProvider,
  Menu,
  MenuItem,
  MenuLabel,
  Modal,
  MultiSelect,
  NumberInput,
  PasswordInput,
  ProgressLabel,
  ProgressRoot,
  ScrollArea,
  Select,
  Table,
  Tabs,
  TabsTab,
  Text,
  TextInput,
  Textarea,
  colorsTuple,
  createTheme,
} from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import { Notifications } from "@mantine/notifications";
import React from "react";
import { useTranslation } from "react-i18next";
import { useResponsiveMode } from "~/hooks/ui/useResponsiveMode";

import ImageFallback from "./assets/imagePlaceholder.svg";

import "@mantine/carousel/styles.layer.css";
import "@mantine/core/styles.layer.css";
import "@mantine/dates/styles.layer.css";
import "@mantine/dropzone/styles.layer.css";
import "@mantine/notifications/styles.layer.css";

import "./Fonts.css";

import "dayjs/locale/de";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import "dayjs/locale/pl";

import globalOverridesStyles from "./overrides.module.css";

export interface ThemeProps {
  children: React.ReactNode;
}

type ExtendedCustomColors =
  | "heoGray"
  | "heoPetrol"
  | "heoMango"
  | "heoGreen"
  | "heoRed"
  | "heoBlue"
  | "heoTeal"
  | "heoBlack"
  | "mango"
  | DefaultMantineColor;

declare module "@mantine/core" {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, MantineColorsTuple>;
  }
}

/**
 * HEO BASE COLORS
 * Green:   #37b24d
 * Red:     #df273d
 * Blue:    #1c7ed6
 * Orange:  #fbbb31
 * Teal:    #006d73
 * Gray:    #151515
 *
 * Generate shades with https://mantine.dev/docs/theming/colors/#color-shades or https://colorkit.co/color-shades-generator/
 */

const Theme: React.FC<ThemeProps> = (props) => {
  const { isMobile } = useResponsiveMode();

  const theme = createTheme({
    fontFamily: "Proxima Nova",

    colors: {
      heoGray: [
        "#B0BAC6",
        "#94A1B1",
        "#7C8C9E",
        "#697A8C",
        "#5C6A79",
        "#515C69",
        "#444E5A",
        "#3A424D",
        "#293038",
        "#1D2329",
      ],
      heoPetrol: [
        "#cde1e7",
        "#9bc4ce",
        "#6aa6b6",
        "#38899d",
        "#1f7a91",
        "#056078",
        "#044b5d",
        "#033643",
        "#02222a",
        "#010b0d",
      ],

      heoMango: colorsTuple("#fbbb31"),
      heoGreen: colorsTuple("#37b24d"),
      heoGateGreen: colorsTuple("#25d29e"),
      heoRed: colorsTuple("#df273d"),
      heoBlue: colorsTuple("#1c7ed6"),
      heoBlack: colorsTuple("#151515"),
      heoTeal: colorsTuple("#006d73"),
      heoForest: colorsTuple("#003541"),
      heoOrange: colorsTuple("#EF7C10"),

      // heoPort Colors

      // orange: [
      //   "#fedea7",
      //   "#fed794",
      //   "#fdd180",
      //   "#fcc96a",
      //   "#fcc252",
      //   "#fbbb31",
      //   "#daa229",
      //   "#ba8a22",
      //   "#9c731a",
      //   "#7e5c13",
      // ],
      // green: [
      //   "#a4daa7",
      //   "#91d296",
      //   "#7eca84",
      //   "#69c273",
      //   "#53ba60",
      //   "#37b24d",
      //   "#2e9a42",
      //   "#268337",
      //   "#1e6d2c",
      //   "#165722",
      // ],
      // red: [
      //   "#f99f9c",
      //   "#f58c89",
      //   "#f17776",
      //   "#ec6164",
      //   "#e64851",
      //   "#df273d",
      //   "#c22034",
      //   "#a51a2b",
      //   "#8a1422",
      //   "#6f0e1a",
      // ],
      // blue: [
      //   "#eaf2fc",
      //   "#d5e6f9",
      //   "#abccf2",
      //   "#81b3e9",
      //   "#5599e0",
      //   "#1c7ed6",
      //   "#176dba",
      //   "#125c9e",
      //   "#0d4c84",
      //   "#083c6a",
      // ],
      // teal: [
      //   "#8eb4b7",
      //   "#77a6a9",
      //   "#009AA2",
      //   "#49898e",
      //   "#2e7b80",
      //   "#006d73",
      //   "#005e63",
      //   "#004f54",
      //   "#004145",
      //   "#003336",
      // ],
      dark: [
        "#cacaca",
        "#b0b0b0",
        "#989898",
        "#7f7f7f",
        "#686868",
        "#525252",
        "#3c3c3c",
        "#282828",
        "#151515",
        "#0c0c0c",
      ],
    },

    primaryShade: 7,

    spacing: {
      xxs: "4px",
      xxl: "36px",
    },

    breakpoints: {
      xs: "30em",
      sm: "48em",
      md: "64em",
      lg: "74em",
      xl: "100em",
    },

    components: {
      Button: Button.extend({
        defaultProps: {
          size: "md",
        },
        classNames: {
          root: globalOverridesStyles.buttonRoot,
          inner: globalOverridesStyles.buttonInner,
          label: globalOverridesStyles.buttonLabel,
        },
      }),

      Title: {
        defaultProps: {
          color: "heoGray.7",
        },
      },

      Text: Text.extend({
        styles: () => ({
          root: {
            fontSize: "var(--text-fz, inherit)",
          },
        }),
      }),

      Paper: {
        defaultProps: {
          radius: "md",
        },
      },

      Space: {
        defaultProps: { h: "md" },
      },

      Table: Table.extend({
        styles: (theme) => ({
          table: {
            fontSize: theme.fontSizes.md,
          },
        }),
      }),

      TextInput: TextInput.extend({
        defaultProps: {
          size: isMobile ? "md" : "sm",
        },
        classNames: {
          input: globalOverridesStyles.defaultInput,
        },
      }),

      InputBase: InputBase.extend({
        defaultProps: {
          size: isMobile ? "md" : "sm",
        },
      }),

      NumberInput: NumberInput.extend({
        defaultProps: {
          size: isMobile ? "md" : "sm",
        },
      }),

      Select: Select.extend({
        defaultProps: {
          size: isMobile ? "md" : "sm",
        },
        classNames: {
          input: globalOverridesStyles.defaultInput,
          option: globalOverridesStyles.selectOption,
        },
      }),

      PasswordInput: PasswordInput.extend({
        defaultProps: {
          size: isMobile ? "md" : "sm",
        },
        classNames: {
          input: globalOverridesStyles.defaultInput,
        },
      }),

      Textarea: Textarea.extend({
        defaultProps: {
          size: isMobile ? "md" : "sm",
        },

        classNames: {
          input: globalOverridesStyles.defaultInput,
        },
      }),

      LoadingOverlay: LoadingOverlay.extend({
        defaultProps: {
          zIndex: 3, // default is 400 and overlaps modals in some places, 3 is min required for tables
        },
      }),

      Modal: Modal.extend({
        classNames: {
          root: globalOverridesStyles.modalRoot,
          content: globalOverridesStyles.modalContent,
          header: globalOverridesStyles.modalHeader,
          body: globalOverridesStyles.modalBody,
        },
        styles: (theme) => ({
          title: {
            fontWeight: 700,
            fontSize: theme.fontSizes.xl,
          },
        }),
        defaultProps: {
          padding: 35,
          size: "md",
        },
      }),

      Anchor: Anchor.extend({
        defaultProps: {
          fw: "bold",
        },
        styles: () => ({
          root: {
            fontSize: "var(--anchor-fz, inherit)",
          },
        }),
      }),

      Stepper: {
        styles: {
          step: {
            "&:disabled": {
              opacity: 0.5,
              cursor: "not-allowed",
            },
          },
        },
      },

      ScrollArea: ScrollArea.extend({
        styles: () => ({
          root: { height: "100%", minWidth: 0 },
          viewport: { height: "100%", minWidth: 0 },
        }),
      }),

      Image: Image.extend({
        defaultProps: {
          fallbackSrc: ImageFallback,
        },
        classNames: {
          root: globalOverridesStyles.imageRoot,
        },
      }),

      MultiSelect: MultiSelect.extend({
        defaultProps: {
          size: isMobile ? "md" : "sm",
        },
      }),

      ActionIcon: ActionIcon.extend({
        defaultProps: {
          color: "heoGray.2",
          variant: "subtle",
        },

        classNames: {
          root: globalOverridesStyles.actionIconDefault,
        },
      }),

      Indicator: Indicator.extend({
        defaultProps: {
          zIndex: 1,
        },
        styles: () => ({
          root: {
            // fixes indicator adding unwanted bottom space to child element
            display: "inline-flex",
          },
        }),
      }),

      Accordion: Accordion.extend({
        styles: () => ({
          label: {
            fontWeight: "bold",
          },
        }),
      }),

      ProgressRoot: ProgressRoot.extend({
        defaultProps: {
          h: 18,
        },
      }),
      ProgressLabel: ProgressLabel.extend({
        defaultProps: {
          fz: "sm",
          fw: "normal",
          lts: 1,
        },
      }),
      Tabs: Tabs.extend({
        defaultProps: {
          variant: "outline",
        },
        classNames: {
          tab: globalOverridesStyles.tab,
          list: globalOverridesStyles.tabList,
        },
      }),

      TabsTab: TabsTab.extend({
        defaultProps: {
          fz: "md",
        },
      }),

      Menu: Menu.extend({
        classNames: {
          item: globalOverridesStyles.menuItem,
        },
      }),
      MenuLabel: MenuLabel.extend({
        defaultProps: {
          fz: "sm",
        },
      }),
      MenuItem: MenuItem.extend({
        defaultProps: {
          fz: "md",
        },
      }),
    },
  });

  const resolver: CSSVariablesResolver = (theme) => ({
    variables: {
      "--mantine-color-dimmed": theme.colors.heoGray[5],
    },
    light: {},
    dark: {},
  });

  const { i18n } = useTranslation();

  return (
    <MantineProvider theme={theme} cssVariablesResolver={resolver}>
      <DatesProvider settings={{ locale: i18n.language }}>
        <Notifications position="top-center" limit={3} />
        {props.children}
      </DatesProvider>
    </MantineProvider>
  );
};

export { Theme };
