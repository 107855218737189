/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreProfileDetailsOpeningHours } from './StoreProfileDetailsOpeningHours';
import {
    StoreProfileDetailsOpeningHoursFromJSON,
    StoreProfileDetailsOpeningHoursFromJSONTyped,
    StoreProfileDetailsOpeningHoursToJSON,
} from './StoreProfileDetailsOpeningHours';
import type { StoreProfilePreviewAddress } from './StoreProfilePreviewAddress';
import {
    StoreProfilePreviewAddressFromJSON,
    StoreProfilePreviewAddressFromJSONTyped,
    StoreProfilePreviewAddressToJSON,
} from './StoreProfilePreviewAddress';

/**
 * 
 * @export
 * @interface StoreProfilePreviewQuery
 */
export interface StoreProfilePreviewQuery {
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewQuery
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewQuery
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewQuery
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewQuery
     */
    phoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreProfilePreviewQuery
     */
    isOts: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreProfilePreviewQuery
     */
    isWpn: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewQuery
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewQuery
     */
    banner?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewQuery
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfilePreviewQuery
     */
    openingYear?: string;
    /**
     * 
     * @type {StoreProfilePreviewAddress}
     * @memberof StoreProfilePreviewQuery
     */
    address?: StoreProfilePreviewAddress;
    /**
     * 
     * @type {StoreProfileDetailsOpeningHours}
     * @memberof StoreProfilePreviewQuery
     */
    openingHours: StoreProfileDetailsOpeningHours;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreProfilePreviewQuery
     */
    types: Array<StoreProfilePreviewQueryTypesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreProfilePreviewQuery
     */
    activities?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreProfilePreviewQuery
     */
    paymentMethods?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreProfilePreviewQuery
     */
    categories?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreProfilePreviewQuery
     */
    accessibilities?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreProfilePreviewQuery
     */
    equipments?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreProfilePreviewQuery
     */
    gallery?: Array<string>;
}


/**
 * @export
 */
export const StoreProfilePreviewQueryTypesEnum = {
    Online: 'online',
    Local: 'local'
} as const;
export type StoreProfilePreviewQueryTypesEnum = typeof StoreProfilePreviewQueryTypesEnum[keyof typeof StoreProfilePreviewQueryTypesEnum];


/**
 * Check if a given object implements the StoreProfilePreviewQuery interface.
 */
export function instanceOfStoreProfilePreviewQuery(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isOts" in value;
    isInstance = isInstance && "isWpn" in value;
    isInstance = isInstance && "openingHours" in value;
    isInstance = isInstance && "types" in value;

    return isInstance;
}

export function StoreProfilePreviewQueryFromJSON(json: any): StoreProfilePreviewQuery {
    return StoreProfilePreviewQueryFromJSONTyped(json, false);
}

export function StoreProfilePreviewQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfilePreviewQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'isOts': json['isOts'],
        'isWpn': json['isWpn'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'banner': !exists(json, 'banner') ? undefined : json['banner'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'openingYear': !exists(json, 'openingYear') ? undefined : json['openingYear'],
        'address': !exists(json, 'address') ? undefined : StoreProfilePreviewAddressFromJSON(json['address']),
        'openingHours': StoreProfileDetailsOpeningHoursFromJSON(json['openingHours']),
        'types': json['types'],
        'activities': !exists(json, 'activities') ? undefined : json['activities'],
        'paymentMethods': !exists(json, 'paymentMethods') ? undefined : json['paymentMethods'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'accessibilities': !exists(json, 'accessibilities') ? undefined : json['accessibilities'],
        'equipments': !exists(json, 'equipments') ? undefined : json['equipments'],
        'gallery': !exists(json, 'gallery') ? undefined : json['gallery'],
    };
}

export function StoreProfilePreviewQueryToJSON(value?: StoreProfilePreviewQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'isOts': value.isOts,
        'isWpn': value.isWpn,
        'website': value.website,
        'banner': value.banner,
        'image': value.image,
        'openingYear': value.openingYear,
        'address': StoreProfilePreviewAddressToJSON(value.address),
        'openingHours': StoreProfileDetailsOpeningHoursToJSON(value.openingHours),
        'types': value.types,
        'activities': value.activities,
        'paymentMethods': value.paymentMethods,
        'categories': value.categories,
        'accessibilities': value.accessibilities,
        'equipments': value.equipments,
        'gallery': value.gallery,
    };
}

