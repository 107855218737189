import { useMutation, useQueryClient } from "@tanstack/react-query";

import { SetUiSettingsRequest } from "../../openapi";
import { useRestAPI } from "../../useRestAPI";
import { GetUiSettingsQueryKey } from "./useGetUiSettings";

export const useSetUiSettings = () => {
  const api = useRestAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (requestParameters: SetUiSettingsRequest) =>
      api.account.settings.setUiSettings(requestParameters),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GetUiSettingsQueryKey] });
    },
  });
};
