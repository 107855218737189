import { Group } from "@mantine/core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { formatCurrency } from "~/helper/Formatter/formatCurrency";
import { OneLineCell } from "~/ui/components/DataTable/Cells/OneLineCell/OneLineCell";
import { DataTableColumn } from "~/ui/components/DataTable/interface/DataTableColumn";

import { CancellationRateInfoBadge } from "../../DashboardWidgets/TotalSalesReportWidget/TotalSalesReportTable/CancellationRateInfoBadge/CancellationRateInfoBadge";
import { CancellationRateInformationenPopover } from "../../DashboardWidgets/TotalSalesReportWidget/TotalSalesReportTable/CancellationRateInformationenPopover/CancellationRateInformationenPopover";
import { TotalSalesItem } from "./TotalSalesItem";

export const useTotalSalesTableConfig = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "totalSalesDetailsPage.table.header",
  });

  const totalSalesListColumns: DataTableColumn<TotalSalesItem>[] = useMemo(() => {
    const columns: DataTableColumn<TotalSalesItem>[] = [
      {
        accessor: "year",
        header: t("year"),
        minWidth: 110,
        disableSort: true,
        cell: ({ value }) => <OneLineCell>{value}</OneLineCell>,
      },
      {
        accessor: "orderAmount",
        header: t("orderAmount"),
        minWidth: 110,
        disableSort: true,
        cell: ({ value }) => <OneLineCell>{formatCurrency(value)}</OneLineCell>,
      },
      {
        accessor: "invoiceAmount",
        header: t("invoiceAmount"),
        minWidth: 110,
        disableSort: true,
        cell: ({ value }) => <OneLineCell>{formatCurrency(value)}</OneLineCell>,
      },
      {
        accessor: "creditNoteAmount",
        header: t("creditNoteAmount"),
        minWidth: 110,
        disableSort: true,
        cell: ({ value }) => <OneLineCell>{formatCurrency(value)}</OneLineCell>,
      },
      {
        accessor: "canceledOrderAmount",
        header: t("canceledOrderAmount"),
        minWidth: 110,
        disableSort: true,
        cell: ({ value }) => <OneLineCell>{formatCurrency(value)}</OneLineCell>,
      },
      {
        accessor: "canceledInvoiceAmount",
        header: t("canceledInvoiceAmount"),
        minWidth: 110,
        disableSort: true,
        cell: ({ value }) => <OneLineCell>{formatCurrency(value)}</OneLineCell>,
      },
      {
        accessor: "quota",
        header: (
          <Group>
            {t("quota")}
            <CancellationRateInformationenPopover />
          </Group>
        ),
        disableHeaderTooltip: true,
        minWidth: 110,
        disableSort: true,
        cell: ({ value }) => (
          <OneLineCell>
            <Group>
              <CancellationRateInfoBadge cancellationRate={value} />
            </Group>
          </OneLineCell>
        ),
      },
    ];

    return columns;
  }, [t]);

  return { totalSalesListColumns };
};
