/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreProfileGeoPosition } from './StoreProfileGeoPosition';
import {
    StoreProfileGeoPositionFromJSON,
    StoreProfileGeoPositionFromJSONTyped,
    StoreProfileGeoPositionToJSON,
} from './StoreProfileGeoPosition';

/**
 * 
 * @export
 * @interface StoreProfileAddress
 */
export interface StoreProfileAddress {
    /**
     * 
     * @type {string}
     * @memberof StoreProfileAddress
     */
    additional: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileAddress
     */
    countryCode: string;
    /**
     * 
     * @type {StoreProfileGeoPosition}
     * @memberof StoreProfileAddress
     */
    geoPosition?: StoreProfileGeoPosition;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileAddress
     */
    googlePlaceId: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileAddress
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileAddress
     */
    streetNumber: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileAddress
     */
    zip: string;
}

/**
 * Check if a given object implements the StoreProfileAddress interface.
 */
export function instanceOfStoreProfileAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "additional" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "googlePlaceId" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "streetNumber" in value;
    isInstance = isInstance && "zip" in value;

    return isInstance;
}

export function StoreProfileAddressFromJSON(json: any): StoreProfileAddress {
    return StoreProfileAddressFromJSONTyped(json, false);
}

export function StoreProfileAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfileAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'additional': json['additional'],
        'city': json['city'],
        'countryCode': json['countryCode'],
        'geoPosition': !exists(json, 'geoPosition') ? undefined : StoreProfileGeoPositionFromJSON(json['geoPosition']),
        'googlePlaceId': json['googlePlaceId'],
        'street': json['street'],
        'streetNumber': json['streetNumber'],
        'zip': json['zip'],
    };
}

export function StoreProfileAddressToJSON(value?: StoreProfileAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'additional': value.additional,
        'city': value.city,
        'countryCode': value.countryCode,
        'geoPosition': StoreProfileGeoPositionToJSON(value.geoPosition),
        'googlePlaceId': value.googlePlaceId,
        'street': value.street,
        'streetNumber': value.streetNumber,
        'zip': value.zip,
    };
}

