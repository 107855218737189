import { Box, Button, Group } from "@mantine/core";
import { clsx } from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useGetTotalSales } from "~/api/hooks/totalSales/useGetTotalSales";
import { RoutesEnum } from "~/ui/routes/RoutesEnum";

import { WidgetContainer } from "../../WidgetContainer/WidgetContainer";
import { CancellationRateStatus } from "./CancellationRateStatus";
import { TotalSalesNoDataContainer } from "./TotalSalesNoDataContainer";
import { TotalSalesReportTable } from "./TotalSalesReportTable/TotalSalesReportTable";
import { TotalSalesReportTableSkeleton } from "./TotalSalesReportTable/TotalSalesReportTableSkeleton";
import { TotalSalesReportTitel } from "./TotalSalesReportTitel";
import { mapTotalSalesReportResponseToTotalSalesReport } from "./mapTotalSalesReportResponseToTotalSalesReport";

import { useResponsiveMode } from "~/hooks/ui/useResponsiveMode";
import styles from "./TotalSalesReportWidget.module.css";

const TotalSalesReportWidget = () => {
  const { data, isFetching } = useGetTotalSales();
  const totalSales = mapTotalSalesReportResponseToTotalSalesReport(data);

  const { t } = useTranslation(undefined, {
    keyPrefix: "dashboardPage.totalSalesReportWidget",
  });

  const [cancellationRateStatus, setCancellationRateStatus] = useState<
    CancellationRateStatus | undefined
  >(undefined);

  const handleUpdateCancellationRateStatus = (rateStatus: CancellationRateStatus | undefined) => {
    setCancellationRateStatus(rateStatus);
  };

  const borderColorClass = clsx(
    {
      [styles.medium]: cancellationRateStatus === CancellationRateStatus.MEDIUM,
    },
    {
      [styles.bad]: cancellationRateStatus === CancellationRateStatus.BAD,
    },
  );

  const { isMobile } = useResponsiveMode();

  return (
    <WidgetContainer className={borderColorClass} title={<TotalSalesReportTitel />}>
      <Box className={styles.wrapper} data-testid="TotalSalesReportWrapper">
        {isFetching && <TotalSalesReportTableSkeleton />}

        {!totalSales && !isFetching && <TotalSalesNoDataContainer />}

        {totalSales && !isFetching && (
          <>
            <TotalSalesReportTable
              onHandleUpdateCancellationRateStatus={handleUpdateCancellationRateStatus}
              salesOrderItem={totalSales}
            />

            {!isMobile && (
              <Group justify="end">
                <Button component={Link} to={RoutesEnum.TOTAL_SALES_DETAILS} variant="outline">
                  {t("showDetails")}
                </Button>
              </Group>
            )}
          </>
        )}
      </Box>
    </WidgetContainer>
  );
};

export { TotalSalesReportWidget };
