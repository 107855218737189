import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { PageSettingsSliceReducer } from "~/hooks/settings/PageSettingsSlice";
import { ReleaseNotesSliceReducer } from "~/ui/components/ReleaseNotes/store/ReleaseNotesSlice";
import { CreateClaimsPageSliceReducer } from "~/ui/pages/ProductClaims/CreateClaimsPage/store/CreateClaimsPage.slice";

export const ReduxStore = configureStore({
  reducer: {
    createClaimsPage: CreateClaimsPageSliceReducer,
    pageSettings: PageSettingsSliceReducer,
    releaseNotes: ReleaseNotesSliceReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof ReduxStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ReduxStore.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
