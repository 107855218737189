import { addBreadcrumb } from "@sentry/react";
import { atom, useAtom } from "jotai";
import { z } from "zod";

import { ENV } from "~/env";

export const AppConfigSchema = z.object({
  logo: z.enum(["stage", "preprod", "prod"]).default("prod"),

  domain: z.string().default("port.heo.com"),

  api: z.object({
    backendBaseUrl: z.string().nonempty(),
    imageBaseUrl: z.string().nonempty(),
  }),

  links: z.object({
    shop: z.string().nonempty(),
    impressum: z.string().nonempty(),
    privacy: z.string().nonempty(),
    copyright: z.string().nonempty(),
    agb: z.string().nonempty(),
    retailerApiAgb: z.string().nonempty(),
    retailerApiDocs: z.string().default(""),
  }),

  keycloak: z.object({
    issuerUrl: z.string().nonempty(),
    clientId: z.string().nonempty(),
    redirectUri: z.string().nonempty(),
    legacyLoginEnabled: z.boolean(),
  }),

  tracking: z.object({
    gtmId: z.string().nullable().default(null),
    hotjarId: z.number().nullable().default(null),
    crispId: z.string().nullable().default(null),
  }),

  mail: z.object({
    customerSupport: z.string().nonempty(),
    retailerApi: z.string().nonempty(),
  }),

  feature: z
    .object({
      manageApiKeys: z.boolean().optional(),
      languageSwitch: z.boolean().optional(),
    })
    .optional(),
});

export type AppConfig = z.infer<typeof AppConfigSchema>;

const appConfigAtom = atom(async (_, { signal }) => {
  try {
    const mode = ENV.mode;

    let configPath = "/config.json";
    if (mode === "development") configPath = "/development.config.json";
    if (mode === "mock") configPath = "/mock.config.json";

    addBreadcrumb({
      category: "config",
      message: `Loading config.json from ${configPath}`,
      level: "info",
    });

    const response = await fetch(configPath, { signal });
    const responseJson: unknown = await response.json();
    const configJson: AppConfig = AppConfigSchema.parse(responseJson);

    addBreadcrumb({
      category: "config",
      message: "config.json retrieved and parsed",
      data: configJson,
      level: "info",
    });

    return configJson;
  } catch (error) {
    addBreadcrumb({ category: "config", message: "config.json loading failed", level: "error" });

    let errorMessage = `Could not load config file from ${window.location.origin}/config.json`;

    if (error instanceof Error) {
      errorMessage += `\n${error.message}`;
    }

    throw Error(errorMessage);
  }
});

export const useAppConfig = () => {
  const [appConfig] = useAtom(appConfigAtom);
  return appConfig;
};
