import { ActionIcon, Tooltip } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { useResponsiveMode } from "~/hooks/ui/useResponsiveMode";
import { MoodType } from "./FeedbackDrawer";

export interface MoodIconProps {
  moodType: MoodType;
  isSelected: boolean;
  onSelect: (moodType: MoodType) => void;
  children: React.ReactNode;
}

const MoodIcon: React.FC<MoodIconProps> = (props) => {
  const { t } = useTranslation();
  const { moodType, isSelected, onSelect, children } = props;

  const { isMobile } = useResponsiveMode();

  return (
    <Tooltip withinPortal withArrow position="bottom" label={t(`feedback.drawer.mood.${moodType}`)}>
      <ActionIcon
        variant="transparent"
        color={isSelected ? "blue" : "dark"}
        onClick={() => onSelect(moodType)}
        size={isMobile ? 42 : 48}
      >
        {children}
      </ActionIcon>
    </Tooltip>
  );
};
export { MoodIcon };
