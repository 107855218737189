/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProductClaimProcessingStatus = {
    ToBeSubmitted: 'TO_BE_SUBMITTED',
    Open: 'OPEN',
    Pending: 'PENDING',
    Confirmed: 'CONFIRMED',
    Denied: 'DENIED',
    WaitingForSupplier: 'WAITING_FOR_SUPPLIER',
    RetailerActionRequired: 'RETAILER_ACTION_REQUIRED'
} as const;
export type ProductClaimProcessingStatus = typeof ProductClaimProcessingStatus[keyof typeof ProductClaimProcessingStatus];


export function ProductClaimProcessingStatusFromJSON(json: any): ProductClaimProcessingStatus {
    return ProductClaimProcessingStatusFromJSONTyped(json, false);
}

export function ProductClaimProcessingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductClaimProcessingStatus {
    return json as ProductClaimProcessingStatus;
}

export function ProductClaimProcessingStatusToJSON(value?: ProductClaimProcessingStatus | null): any {
    return value as any;
}

