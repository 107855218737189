import { Box, BoxProps, Divider, Group } from "@mantine/core";
import React, { ReactNode } from "react";

import clsx from "clsx";
import styles from "./ModalFooter.module.css";

export interface ModalFooterProps extends BoxProps {
  children: ReactNode;
  withDivider?: boolean;
}

const ModalFooter: React.FC<ModalFooterProps> = (props) => {
  const { children, withDivider = true, ...boxProps } = props;

  return (
    <Box
      {...boxProps}
      className={clsx(styles.footerContainer, boxProps.className)}
      mt={boxProps.mt ?? "xl"}
      pb={"md"}
    >
      {withDivider && <Divider pb={"md"} />}
      <Group justify="right">{children}</Group>
    </Box>
  );
};

export { ModalFooter };
