import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AccountSettingsKeys } from "~/hooks/accountSettings/AccountSettingsSchema";
import { useAccountSettings } from "~/hooks/accountSettings/useAccountSettings";
import { useAppSelector } from "~/store/store";

import {
  releaseNotesLoadingSelector,
  toggleReleaseNotesModalSelector,
} from "../ReleaseNotes/store/ReleaseNotesSlice";
import { InfoContainer } from "./InfoContainer";
import { IntroContainer } from "./IntroContainer";

import { useToogleGuidedTourModal } from "./hooks/useToogleGuidedTourModal";

export type GuidedTourData = {
  descriptionTranslationKey?: string;
  image: string;
};

export type GuidedTour = {
  guidedTourData: GuidedTourData[];
  title: string;
  translationBaseKey?: string;
};

export type GuidedTourModalProps = {
  guidedTour: GuidedTour;
  guidedTourStorageKey: AccountSettingsKeys;
  showIntro?: boolean;
};

const TIMEOUT_TO_SHOW_GUIDED_TOUR = 750;

const GuidedTourModal = (props: GuidedTourModalProps) => {
  const { guidedTour, guidedTourStorageKey, showIntro } = props;

  const { t } = useTranslation();

  const releaseNotesModalIsOpen = useAppSelector(toggleReleaseNotesModalSelector);
  const releaseNotesLoading = useAppSelector(releaseNotesLoadingSelector);

  const { accountSettings, updateSettings, accountSettingsLoading } = useAccountSettings();
  const hasSeen = accountSettings ? accountSettings[guidedTourStorageKey] : undefined;

  const { isGuidedTourModalOpen, setIsGuidedTourModalOpen } = useToogleGuidedTourModal();

  const handleStartGuidedTour = () => {
    handle.close();
    setIsGuidedTourModalOpen(true);
  };

  const handleCloseGuidedTour = () => {
    setIsGuidedTourModalOpen(false);
    updateSettings({ [guidedTourStorageKey]: true });
  };

  const handleCancelAll = () => {
    setIsGuidedTourModalOpen(false);
    updateSettings({
      creditNotesPageGuidedTourSeen: true,
      customerOrderPageGuidedTourSeen: true,
      dashboardGuidedTourSeen: true,
      invoicePageGuidedTourSeen: true,
      productClaimPageGuidedTourSeen: true,
      productPageGuidedTourSeen: true,
      salesOrderPageGuidedTourSeen: true,
    });
  };

  useEffect(() => {
    if (!releaseNotesLoading && !releaseNotesModalIsOpen && !hasSeen && !accountSettingsLoading) {
      setTimeout(() => {
        showIntro && handle.open();
        setIsGuidedTourModalOpen(true);
      }, TIMEOUT_TO_SHOW_GUIDED_TOUR);
    }
  }, [
    hasSeen,
    releaseNotesModalIsOpen,
    releaseNotesLoading,
    accountSettingsLoading,
    setIsGuidedTourModalOpen,
    showIntro,
  ]);

  const [showIntroContainer, handle] = useDisclosure(false);

  return (
    <>
      <Modal
        opened={isGuidedTourModalOpen}
        onClose={handleCloseGuidedTour}
        closeOnClickOutside={false}
        closeOnEscape={false}
        size={720}
        title={
          showIntroContainer
            ? t("guidedTour.intro.title")
            : t("guidedTour.title", { customTitle: t(`${guidedTour.title}`) })
        }
      >
        {showIntroContainer && (
          <IntroContainer
            handleStartGuidedTour={handleStartGuidedTour}
            handleStartLater={handleCancelAll}
          />
        )}
        {!showIntroContainer && (
          <InfoContainer
            guidedTourData={guidedTour.guidedTourData}
            onClose={handleCloseGuidedTour}
            translationBaseKey={guidedTour.translationBaseKey}
            onCancelAll={handleCancelAll}
          />
        )}
      </Modal>
    </>
  );
};

export { GuidedTourModal };
