import { Alert, Stack, Text } from "@mantine/core";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { formatCurrency } from "~/helper/Formatter/formatCurrency";
import { LimitBar } from "~/ui/components/LimitBar/LimitBar";

export interface FactoringChartProps {
  used: number;
  limit: number;
}

const FactoringChart: React.FC<FactoringChartProps> = (props) => {
  const { used, limit } = props;

  const { t } = useTranslation();

  const available = limit - used;
  const isLimitExceeded = used > limit;

  return (
    <Stack>
      {isLimitExceeded ? (
        <Alert color="red">
          <Trans
            i18nKey={"dashboardPage.factoringWidget.exceededAlert"}
            values={{ amount: formatCurrency(used - limit) }}
          />
        </Alert>
      ) : (
        <Text fz={"lg"}>
          <Trans
            i18nKey={"dashboardPage.factoringWidget.description"}
            values={{ amount: formatCurrency(limit) }}
          />
        </Text>
      )}

      {isLimitExceeded ? (
        <LimitBar
          leftItem={{
            value: limit,
            formattedValue: formatCurrency(limit),
            label: t("dashboardPage.factoringWidget.limit"),
            color: "orange",
          }}
          rightItem={{
            value: used - limit,
            formattedValue: formatCurrency(used - limit),
            label: t("dashboardPage.factoringWidget.exceeded"),
            color: "red",
          }}
        />
      ) : (
        <LimitBar
          leftItem={{
            value: used,
            formattedValue: formatCurrency(used),
            label: t("dashboardPage.factoringWidget.used"),
            color: "orange",
          }}
          rightItem={{
            value: available,
            formattedValue: formatCurrency(available),
            label: t("dashboardPage.factoringWidget.available"),
            color: "green",
          }}
        />
      )}
    </Stack>
  );
};

export { FactoringChart };
