import { Paper, PaperProps, Skeleton, Stack, Text, Title, TitleProps } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { ReactNode } from "react";

export interface WidgetContainerProps extends PaperProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  isLoading?: boolean;
  children: ReactNode;
  className?: string;
  titleProps?: TitleProps;
}

const WidgetContainer: React.FC<WidgetContainerProps> = (props) => {
  const { title, subtitle, isLoading, children, className, titleProps, ...paperProps } = props;

  const isSmall = useMediaQuery("(max-width: 500px)");

  return (
    <Paper
      shadow="md"
      p={isSmall ? "lg" : "xl"}
      radius={isSmall ? 0 : undefined}
      className={className}
      {...paperProps}
    >
      <Stack mb={"xs"} gap={0}>
        {title && (
          <Title order={2} size={24} {...titleProps}>
            {title}
          </Title>
        )}

        {isLoading && <Skeleton height={16} width={"60%"} mt={6} />}
        {!isLoading && subtitle && <Text c={"dimmed"}>{subtitle}</Text>}
      </Stack>

      {children}
    </Paper>
  );
};

export { WidgetContainer };
