/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface PostFeedbackRequest {
    selectedMood?: string;
    categorie?: string;
    summary?: string;
    description?: string;
    buildVersionInfo?: string;
}

/**
 * 
 */
export class FeedbackResourceApi extends runtime.BaseAPI {

    /**
     */
    async postFeedbackRaw(requestParameters: PostFeedbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.selectedMood !== undefined) {
            queryParameters['selectedMood'] = requestParameters.selectedMood;
        }

        if (requestParameters.categorie !== undefined) {
            queryParameters['categorie'] = requestParameters.categorie;
        }

        if (requestParameters.summary !== undefined) {
            queryParameters['summary'] = requestParameters.summary;
        }

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.buildVersionInfo !== undefined) {
            queryParameters['buildVersionInfo'] = requestParameters.buildVersionInfo;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/ui/feedback/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postFeedback(requestParameters: PostFeedbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postFeedbackRaw(requestParameters, initOverrides);
    }

}
