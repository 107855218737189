/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderType = {
    Dropshipping: 'DROPSHIPPING',
    Normal: 'NORMAL'
} as const;
export type OrderType = typeof OrderType[keyof typeof OrderType];


export function OrderTypeFromJSON(json: any): OrderType {
    return OrderTypeFromJSONTyped(json, false);
}

export function OrderTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderType {
    return json as OrderType;
}

export function OrderTypeToJSON(value?: OrderType | null): any {
    return value as any;
}

