/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SalesOrderItemSummary } from './SalesOrderItemSummary';
import {
    SalesOrderItemSummaryFromJSON,
    SalesOrderItemSummaryFromJSONTyped,
    SalesOrderItemSummaryToJSON,
} from './SalesOrderItemSummary';

/**
 * 
 * @export
 * @interface PagedResultSalesOrderItemSummary
 */
export interface PagedResultSalesOrderItemSummary {
    /**
     * 
     * @type {Array<SalesOrderItemSummary>}
     * @memberof PagedResultSalesOrderItemSummary
     */
    content: Array<SalesOrderItemSummary>;
    /**
     * 
     * @type {number}
     * @memberof PagedResultSalesOrderItemSummary
     */
    totalElements: number;
    /**
     * 
     * @type {number}
     * @memberof PagedResultSalesOrderItemSummary
     */
    totalPages: number;
}

/**
 * Check if a given object implements the PagedResultSalesOrderItemSummary interface.
 */
export function instanceOfPagedResultSalesOrderItemSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "totalElements" in value;
    isInstance = isInstance && "totalPages" in value;

    return isInstance;
}

export function PagedResultSalesOrderItemSummaryFromJSON(json: any): PagedResultSalesOrderItemSummary {
    return PagedResultSalesOrderItemSummaryFromJSONTyped(json, false);
}

export function PagedResultSalesOrderItemSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagedResultSalesOrderItemSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': ((json['content'] as Array<any>).map(SalesOrderItemSummaryFromJSON)),
        'totalElements': json['totalElements'],
        'totalPages': json['totalPages'],
    };
}

export function PagedResultSalesOrderItemSummaryToJSON(value?: PagedResultSalesOrderItemSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': ((value.content as Array<any>).map(SalesOrderItemSummaryToJSON)),
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
    };
}

