import { Embla, useAnimationOffsetEffect } from "@mantine/carousel";
import { useWindowEvent } from "@mantine/hooks";
import { useCallback, useEffect, useState } from "react";
const FIRST_PERIOD_TO_SHOW = 0;
export const useAvailabilityPeriodGroupCarousel = () => {
  const TRANSITION_DURATION = 200;
  const [embla, setEmbla] = useState<Embla | null>(null);
  const [canScollPrevious, setCanScrollPrevious] = useState(false);
  const [canScollNext, setCanScrollNext] = useState(true);

  const handleSelectPreviousPeriod = () => {
    embla?.scrollPrev();
  };

  const handleSelectNextPeriod = () => {
    embla?.scrollNext();
  };

  const handleScroll = useCallback(() => {
    if (!embla) return;

    setCanScrollPrevious(embla.canScrollPrev());
    setCanScrollNext(embla.canScrollNext());
  }, [embla]);

  //needed because embla is not ready on first render
  useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  const reset = () => {
    setTimeout(() => {
      embla?.reInit();
    }, 100);
  };

  // needed to reinit embla after orientation change
  useWindowEvent("orientationchange", reset);

  useEffect(() => {
    if (embla) {
      embla.on("select", handleScroll);
      handleScroll();
    }
  }, [embla, handleScroll]);

  return {
    setEmbla,
    handleSelectPreviousPeriod,
    handleSelectNextPeriod,
    canScollPrevious,
    canScollNext,
    firstPeriodToShow: FIRST_PERIOD_TO_SHOW,
  };
};
