import { Button, CopyButton, Group, Stack, Text } from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

import { useGetBuildVersions } from "~/hooks/useGetBuildVersions";

import styles from "./BuildVersions.module.css";

const BuildVersions = () => {
  const { backendCommitHash, frontendCommitHash, buildDateString, formatedConcatValues } =
    useGetBuildVersions();
  const { t } = useTranslation();

  return (
    <Stack gap="xs">
      <Group>
        <Text c={"dimmed"}>{t("footer.backendVersionLabel")}</Text>
        <Text>{backendCommitHash}</Text>
      </Group>
      <Group>
        <Text c={"dimmed"}>{t("footer.frontendVersionLabel")}</Text>
        <Text>{frontendCommitHash}</Text>
      </Group>
      <Group>
        <Text c={"dimmed"}>{t("footer.buildDateLabel")}</Text>
        <Text>{buildDateString}</Text>
      </Group>
      <Group justify="right">
        <CopyButton value={formatedConcatValues}>
          {({ copied, copy }) => (
            <Button
              variant="outline"
              className={styles.copyButton}
              color={copied ? "teal" : "blue"}
              onClick={copy}
              leftSection={copied ? <IconCheck size={14} /> : <IconCopy size={14} />}
            >
              {copied ? t("navBar.version.copied") : t("navBar.version.copy")}
            </Button>
          )}
        </CopyButton>
      </Group>
    </Stack>
  );
};
export { BuildVersions };
