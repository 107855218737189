/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderOrigin = {
    Shop: 'SHOP',
    Api: 'API',
    Bc: 'BC'
} as const;
export type OrderOrigin = typeof OrderOrigin[keyof typeof OrderOrigin];


export function OrderOriginFromJSON(json: any): OrderOrigin {
    return OrderOriginFromJSONTyped(json, false);
}

export function OrderOriginFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderOrigin {
    return json as OrderOrigin;
}

export function OrderOriginToJSON(value?: OrderOrigin | null): any {
    return value as any;
}

