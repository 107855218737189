import { Button, Group, Image, Space, Stack, Text } from "@mantine/core";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import { Trans, useTranslation } from "react-i18next";

import Teaser from "./GT-Teaser_1.jpg";

type IntroContainerProps = {
  handleStartLater?: () => void;
  handleStartGuidedTour?: () => void;
};

const IntroContainer = (props: IntroContainerProps) => {
  const { handleStartLater, handleStartGuidedTour } = props;
  const { t } = useTranslation();
  return (
    <Stack>
      <Image mah={237} src={Teaser} />
      <Space h={"xs"} />
      <Text>
        <Trans t={t} i18nKey={"guidedTour.intro.description"} />
      </Text>
      <Space />
      <Group justify="flex-end">
        <Button onClick={handleStartLater} variant={"outline"}>
          {t("guidedTour.intro.startLaterButtonLabel")}
        </Button>

        <Button onClick={handleStartGuidedTour} rightSection={<IconArrowNarrowRight />}>
          {t("guidedTour.intro.startGuidedTourButtonLabel")}
        </Button>
      </Group>
    </Stack>
  );
};
export { IntroContainer };
