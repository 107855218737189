/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreProfileStoreDetails } from './StoreProfileStoreDetails';
import {
    StoreProfileStoreDetailsFromJSON,
    StoreProfileStoreDetailsFromJSONTyped,
    StoreProfileStoreDetailsToJSON,
} from './StoreProfileStoreDetails';

/**
 * 
 * @export
 * @interface ChangeRequestDetails
 */
export interface ChangeRequestDetails {
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestDetails
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestDetails
     */
    storeProfileId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestDetails
     */
    approvalStatus: ChangeRequestDetailsApprovalStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof ChangeRequestDetails
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ChangeRequestDetails
     */
    updatedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeRequestDetails
     */
    storeProfileVisibleAfterApproval?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChangeRequestDetails
     */
    rejectionReason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeRequestDetails
     */
    draft: boolean;
    /**
     * 
     * @type {StoreProfileStoreDetails}
     * @memberof ChangeRequestDetails
     */
    store: StoreProfileStoreDetails;
}


/**
 * @export
 */
export const ChangeRequestDetailsApprovalStatusEnum = {
    InReview: 'IN_REVIEW',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Draft: 'DRAFT',
    Submitted: 'SUBMITTED'
} as const;
export type ChangeRequestDetailsApprovalStatusEnum = typeof ChangeRequestDetailsApprovalStatusEnum[keyof typeof ChangeRequestDetailsApprovalStatusEnum];


/**
 * Check if a given object implements the ChangeRequestDetails interface.
 */
export function instanceOfChangeRequestDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "approvalStatus" in value;
    isInstance = isInstance && "draft" in value;
    isInstance = isInstance && "store" in value;

    return isInstance;
}

export function ChangeRequestDetailsFromJSON(json: any): ChangeRequestDetails {
    return ChangeRequestDetailsFromJSONTyped(json, false);
}

export function ChangeRequestDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeRequestDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'storeProfileId': !exists(json, 'storeProfileId') ? undefined : json['storeProfileId'],
        'approvalStatus': json['approvalStatus'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'storeProfileVisibleAfterApproval': !exists(json, 'storeProfileVisibleAfterApproval') ? undefined : json['storeProfileVisibleAfterApproval'],
        'rejectionReason': !exists(json, 'rejectionReason') ? undefined : json['rejectionReason'],
        'draft': json['draft'],
        'store': StoreProfileStoreDetailsFromJSON(json['store']),
    };
}

export function ChangeRequestDetailsToJSON(value?: ChangeRequestDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'storeProfileId': value.storeProfileId,
        'approvalStatus': value.approvalStatus,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'storeProfileVisibleAfterApproval': value.storeProfileVisibleAfterApproval,
        'rejectionReason': value.rejectionReason,
        'draft': value.draft,
        'store': StoreProfileStoreDetailsToJSON(value.store),
    };
}

