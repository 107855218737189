/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProductClaimReason = {
    Unknown: 'UNKNOWN',
    DamagedPackaging: 'DAMAGED_PACKAGING',
    DamagedProduct: 'DAMAGED_PRODUCT',
    MissingParts: 'MISSING_PARTS',
    Overdelivered: 'OVERDELIVERED',
    Underdelivered: 'UNDERDELIVERED',
    WronglyDelivered: 'WRONGLY_DELIVERED',
    WronglyOrdered: 'WRONGLY_ORDERED',
    InferiorQuality: 'INFERIOR_QUALITY',
    WrongSerialNumber: 'WRONG_SERIAL_NUMBER'
} as const;
export type ProductClaimReason = typeof ProductClaimReason[keyof typeof ProductClaimReason];


export function ProductClaimReasonFromJSON(json: any): ProductClaimReason {
    return ProductClaimReasonFromJSONTyped(json, false);
}

export function ProductClaimReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductClaimReason {
    return json as ProductClaimReason;
}

export function ProductClaimReasonToJSON(value?: ProductClaimReason | null): any {
    return value as any;
}

