import "../i18n/i18n";
import { ModalsProvider } from "@mantine/modals";
import { QueryClientProvider } from "@tanstack/react-query";
import { Suspense } from "react";
import { Provider } from "react-redux";
import { useDomainRedirect } from "~/hooks/useDomainRedirect";
import { queryClient } from "~/queryClient";
import { ReduxStore } from "~/store/store";
import { Theme } from "../theme/Theme";
import { AuthContainer } from "./AuthContainer";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { Router } from "./routes/Router";

const App = () => {
  useDomainRedirect();

  return (
    <Suspense>
      <Theme>
        <ErrorBoundary>
          <AuthContainer>
            <Provider store={ReduxStore}>
              <QueryClientProvider client={queryClient}>
                <ModalsProvider>
                  <Router />
                </ModalsProvider>
              </QueryClientProvider>
            </Provider>
          </AuthContainer>
        </ErrorBoundary>
      </Theme>
    </Suspense>
  );
};

export { App };
