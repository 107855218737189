import { useQuery } from "@tanstack/react-query";

import { useRestAPI } from "../../useRestAPI";

export const GetApiAccessQueryKey = "GetApiAccess";

export const useGetApiAccess = () => {
  const api = useRestAPI();
  return useQuery({
    queryKey: [GetApiAccessQueryKey],
    queryFn: () => api.apiAccess.getApiAccess(),
  });
};
