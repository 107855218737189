import { useWindowEvent } from "@mantine/hooks";
import { ErrorResponse } from "oidc-client-ts";
import { useCallback, useMemo } from "react";
import { useAuth } from "react-oidc-context";

import { useMeQuery } from "~/api/hooks/me.hooks";
import { MINUTE_IN_MILLISECONDS } from "~/common/constants";
import { useAppConfig } from "~/config/AppConfig";
import { useOnce } from "~/hooks/useOnce/useOnce";

export const useIsAuthenticated = () => {
  const auth = useAuth();

  const {
    keycloak: { legacyLoginEnabled },
  } = useAppConfig();

  const isOidcAuth = !legacyLoginEnabled;

  const oidcLogin = useCallback(
    ({ forceLogout }: { forceLogout: boolean }) =>
      () => {
        const login = async () => {
          try {
            await auth.signinSilent();
          } catch (error) {
            // ErrorResponse is an oidc flow error
            if (forceLogout && error instanceof ErrorResponse) {
              auth.removeUser();
            }
          }
        };

        if (isOidcAuth) {
          login();
        }
      },
    [isOidcAuth, auth.removeUser, auth.signinSilent],
  );

  /**
   * The user might have had a stale window where the refresh mechanism was not active anymore or he logged out from a different app.
   * In those cases we do a silent signin and clear the local user if there is no active session anymore
   */
  useWindowEvent("focus", () => {
    oidcLogin({ forceLogout: true })();
  });

  /**
   * Do a silent signin on first render of this hook
   */
  useOnce(oidcLogin({ forceLogout: false }));

  /**
   * Fallback mechanism for the legacy login to determine whether we are successfully logged in
   */

  const { isSuccess, isFetching } = useMeQuery({
    queryKey: ["AuthRouteGetMe"],
    staleTime: 15 * MINUTE_IN_MILLISECONDS,
    retry: false,
    enabled: legacyLoginEnabled,
  });

  const isLoading = isOidcAuth
    ? auth.isLoading || auth.activeNavigator === "signinSilent"
    : isFetching;

  return useMemo(
    () => ({
      isAuthenticated: isSuccess || auth.isAuthenticated,
      isLoading: isLoading,
    }),
    [isSuccess, auth.isAuthenticated, isLoading],
  );
};
