import {
  INVOICES_PAGE_DETAIL_SEARCH_QUERY_KEY,
  INVOICES_PAGE_PERIOD_QUERY_KEY,
  INVOICES_PAGE_SEARCH_QUERY_KEY,
  INVOICES_PAGE_SELECTED_QUERY_KEY,
  INVOICES_PAGE_STATUS_QUERY_KEY,
} from "~/ui/pages/InvoicesPage/InvoicesPageQueryKeys";

import { RoutesEnum } from "../RoutesEnum";
import { createDynamicRoute } from "./util/createDynamicRoute";

const createInvoiceLink = createDynamicRoute({
  url: RoutesEnum.INVOICES,
  params: {
    search: INVOICES_PAGE_SEARCH_QUERY_KEY,
    selected: INVOICES_PAGE_SELECTED_QUERY_KEY,
    period: INVOICES_PAGE_PERIOD_QUERY_KEY,
    status: INVOICES_PAGE_STATUS_QUERY_KEY,
    detailSearch: INVOICES_PAGE_DETAIL_SEARCH_QUERY_KEY,
  },
});

export { createInvoiceLink };
