import { DataTableColumn } from "../interface/DataTableColumn";

export interface GetColumnPaddingsProps {
  column: DataTableColumn;
  index: number;
  columnCount: number;
  columnGap: number;
}

export const getColumnPaddings = (props: GetColumnPaddingsProps) => {
  const { column, columnCount, columnGap, index } = props;

  const isFirstColumn = index === 0;
  const isLastColumn = index === columnCount;

  const pl = isFirstColumn
    ? column.firstColumnLeftPadding ?? column.paddingLeft ?? columnGap * 2
    : column.paddingLeft ?? columnGap;

  const pr = isLastColumn
    ? column.lastColumnRightPadding ?? column.paddingRight ?? columnGap * 2
    : column.paddingRight ?? columnGap;

  return { pl, pr };
};
