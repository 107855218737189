/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DisplayPeriod
 */
export interface DisplayPeriod {
    /**
     * 
     * @type {Date}
     * @memberof DisplayPeriod
     */
    displayFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DisplayPeriod
     */
    displayUntil?: Date;
}

/**
 * Check if a given object implements the DisplayPeriod interface.
 */
export function instanceOfDisplayPeriod(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DisplayPeriodFromJSON(json: any): DisplayPeriod {
    return DisplayPeriodFromJSONTyped(json, false);
}

export function DisplayPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayPeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayFrom': !exists(json, 'displayFrom') ? undefined : (new Date(json['displayFrom'])),
        'displayUntil': !exists(json, 'displayUntil') ? undefined : (new Date(json['displayUntil'])),
    };
}

export function DisplayPeriodToJSON(value?: DisplayPeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayFrom': value.displayFrom === undefined ? undefined : (value.displayFrom.toISOString()),
        'displayUntil': value.displayUntil === undefined ? undefined : (value.displayUntil.toISOString()),
    };
}

