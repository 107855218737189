/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { HeoCustomerOrderNumber } from './HeoCustomerOrderNumber';
import {
    HeoCustomerOrderNumberFromJSON,
    HeoCustomerOrderNumberFromJSONTyped,
    HeoCustomerOrderNumberToJSON,
} from './HeoCustomerOrderNumber';
import type { OrderOrigin } from './OrderOrigin';
import {
    OrderOriginFromJSON,
    OrderOriginFromJSONTyped,
    OrderOriginToJSON,
} from './OrderOrigin';
import type { OrderType } from './OrderType';
import {
    OrderTypeFromJSON,
    OrderTypeFromJSONTyped,
    OrderTypeToJSON,
} from './OrderType';

/**
 * 
 * @export
 * @interface CustomerOrderDetails
 */
export interface CustomerOrderDetails {
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderDetails
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerOrderDetails
     */
    createdAt: Date;
    /**
     * 
     * @type {HeoCustomerOrderNumber}
     * @memberof CustomerOrderDetails
     */
    orderNumber: HeoCustomerOrderNumber;
    /**
     * 
     * @type {OrderType}
     * @memberof CustomerOrderDetails
     */
    orderType?: OrderType;
    /**
     * 
     * @type {OrderOrigin}
     * @memberof CustomerOrderDetails
     */
    orderOrigin?: OrderOrigin;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderDetails
     */
    totalAmount: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderDetails
     */
    customerReference: string;
    /**
     * 
     * @type {Address}
     * @memberof CustomerOrderDetails
     */
    shippingAddress?: Address;
}

/**
 * Check if a given object implements the CustomerOrderDetails interface.
 */
export function instanceOfCustomerOrderDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "orderNumber" in value;
    isInstance = isInstance && "totalAmount" in value;
    isInstance = isInstance && "customerReference" in value;

    return isInstance;
}

export function CustomerOrderDetailsFromJSON(json: any): CustomerOrderDetails {
    return CustomerOrderDetailsFromJSONTyped(json, false);
}

export function CustomerOrderDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOrderDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'orderNumber': HeoCustomerOrderNumberFromJSON(json['orderNumber']),
        'orderType': !exists(json, 'orderType') ? undefined : OrderTypeFromJSON(json['orderType']),
        'orderOrigin': !exists(json, 'orderOrigin') ? undefined : OrderOriginFromJSON(json['orderOrigin']),
        'totalAmount': json['totalAmount'],
        'customerReference': json['customerReference'],
        'shippingAddress': !exists(json, 'shippingAddress') ? undefined : AddressFromJSON(json['shippingAddress']),
    };
}

export function CustomerOrderDetailsToJSON(value?: CustomerOrderDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'orderNumber': HeoCustomerOrderNumberToJSON(value.orderNumber),
        'orderType': OrderTypeToJSON(value.orderType),
        'orderOrigin': OrderOriginToJSON(value.orderOrigin),
        'totalAmount': value.totalAmount,
        'customerReference': value.customerReference,
        'shippingAddress': AddressToJSON(value.shippingAddress),
    };
}

