import { t } from "i18next";

import { ProductClaimsOverview } from "~/api/openapi";

export interface ProductClaimStatusItem {
  id: string;
  label: string;
  value: number;
}

export enum ProductClaimStatus {
  Pending = "PENDING",
  Open = "OPEN",
  RetailerActionRequired = "RETAILER_ACTION_REQUIRED",
  WaitingForSupplier = "WAITING_FOR_SUPPLIER",
}

export type ProductClaimWidgetDict = Record<ProductClaimStatus, ProductClaimStatusItem>;

const calcTotalSumOfProductClaimsOverview = (
  productClaimsOverview: ProductClaimsOverview | undefined,
) => {
  if (!productClaimsOverview) {
    return 0;
  }

  return (
    productClaimsOverview.numInProgress +
    productClaimsOverview.numOpen +
    productClaimsOverview.numWaitingForSupplies +
    productClaimsOverview.numRetailerActionRequired
  );
};

export const mapProductClaimQueryDataToBarChart = (
  productClaimsOverview: ProductClaimsOverview | undefined,
) => {
  const productClaimWidgetDict: {
    productClaimWidgetDict: ProductClaimWidgetDict;
    totalSum: number;
  } = {
    productClaimWidgetDict: {
      [ProductClaimStatus.Open]: {
        id: t(`productClaimProcessingStatus.${ProductClaimStatus.Open}`),
        label: t(`productClaimProcessingStatus.${ProductClaimStatus.Open}`),
        value: productClaimsOverview?.numOpen ?? 0,
      },
      [ProductClaimStatus.Pending]: {
        id: t(`productClaimProcessingStatus.${ProductClaimStatus.Pending}`),
        label: t(`productClaimProcessingStatus.${ProductClaimStatus.Pending}`),
        value: productClaimsOverview?.numInProgress ?? 0,
      },
      [ProductClaimStatus.WaitingForSupplier]: {
        id: t(`productClaimProcessingStatus.${ProductClaimStatus.WaitingForSupplier}`),
        label: t(`productClaimProcessingStatus.${ProductClaimStatus.WaitingForSupplier}`),
        value: productClaimsOverview?.numWaitingForSupplies ?? 0,
      },
      [ProductClaimStatus.RetailerActionRequired]: {
        id: t(`productClaimProcessingStatus.${ProductClaimStatus.RetailerActionRequired}`),
        label: t(`productClaimProcessingStatus.${ProductClaimStatus.RetailerActionRequired}`),
        value: productClaimsOverview?.numRetailerActionRequired ?? 0,
      },
    },
    totalSum: calcTotalSumOfProductClaimsOverview(productClaimsOverview),
  };

  return productClaimWidgetDict;
};
