import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { AccountSettingsSchema } from "~/hooks/accountSettings/AccountSettingsSchema";

import { useRestAPI } from "../../useRestAPI";

export const GetUiSettingsQueryKey = "GetUiSettingsQuery";

export const useGetUiSettings = () => {
  const api = useRestAPI();

  return useQuery({
    queryKey: [GetUiSettingsQueryKey],
    queryFn: () => api.account.settings.getUiSettings(),
    select: (data) => {
      const settings = AccountSettingsSchema.safeParse(JSON.parse(data));

      if (settings.success === false) {
        // eslint-disable-next-line no-console
        console.error(settings.error);
      }
      if ("data" in settings) {
        return settings.data;
      }
      return undefined;
    },
    placeholderData: keepPreviousData,
    staleTime: 3600000,
  });
};
