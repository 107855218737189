import { Burger, Modal } from "@mantine/core";
import { useDisclosure, usePrevious } from "@mantine/hooks";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { NavbarContent } from "../NavbarContent/NavbarContent";

import styles from "./MobileNavbar.module.css";

export interface MobileNavbarProps {
  hideNavbarLinks: boolean;
}

const MobileNavbar: React.FC<MobileNavbarProps> = (props) => {
  const { hideNavbarLinks } = props;

  const [isOpen, handler] = useDisclosure(false);

  const location = useLocation();

  const previewsLocation = usePrevious(location);

  useEffect(() => {
    if (previewsLocation?.pathname !== location.pathname) handler.close();
  }, [location.pathname, previewsLocation?.pathname, handler.close]);

  return (
    <>
      <Burger opened={isOpen} onClick={handler.toggle} />

      <Modal
        onClose={handler.close}
        transitionProps={{ transition: "slide-down" }}
        opened={isOpen}
        keepMounted={true}
        classNames={{
          inner: styles.inner,
          header: styles.header,
          content: styles.content,
        }}
        withCloseButton={false}
        radius={0}
        fullScreen
        zIndex={1}
      >
        <NavbarContent hideNavbarLinks={hideNavbarLinks} onItemClick={handler.close} isMobileView />
      </Modal>
    </>
  );
};

export { MobileNavbar };
