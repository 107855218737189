import { ProductClaimReasonType } from "~/ui/pages/ProductClaims/CreateClaimsPage/ClaimsDetailsStep/ClaimsDetailGroup/ClaimsDetailForm/createClaimDetailItems";

import { CreateClaimsPageDE } from "../de/de.createClaimsPage";

export const CreateClaimsPageEN: typeof CreateClaimsPageDE = {
  title: "New claim",
  submitClaims_one: "Submit {{ count }} claim",
  submitClaims_other: "Submit {{ count }} claims",
  submitProgressTitle: "Claims will be saved",
  submitProgressMessage: "{{ count }}/{{ total }} saved.",
  submitFailMessage_one: "{{ count }} claim could not be saved.",
  submitFailMessage_other: "{{ count }} claims could not be saved.",
  submitSuccess_one: "{{ count }}/{{ total }} claim was successfully created.",
  submitSuccess_other: "{{ count }}/{{ total }} claims have been successfully created.",
  submitClaimsDisabledTooltip: "Please enter all mandatory information first to continue.",
  selectClaimsDisabledTooltip: "Please select one or more items to continue.",
  selectClaimsDisabledLabel: "No items selected",

  selectProducts: {
    claimInfoModalTitel: "Claims notice",
    backToClaimsPageConfirmTitle: "Abort claim creation?",
    backToClaimsPageConfirmInfoText:
      "Are you sure you want to return to the complaints overview? All data entered so far will be lost.",
    backToClaimsPageConfirmLabel: "Abort claim creation",
    backToClaimsPageCancelLabel: "Cancel",
    stepTitle: "Select item",
    stepDescription: "{{ count }} item selected",
    claimableProducts: "Select the item you want to claim:",
    noClaimableProductsFound: "No claimable items were found that match your search criteria.",
    noClaimableProductsAvailable: "No claimable items available.",
    filter: {
      timePeriod: {
        lastTwoWeeks: "Invoices from the last 14 days",
      },
      title: "Filter & sort",
      searchPlaceholder: "Search claimable items (at least 3 characters)",
    },
    sort: {
      productNumber: "Item no.",
      invoiceNumber: "Invoice no.",
      priceAndDiscount: "Price",
      quantity: "Quantity",
      claimedQuantity: "Claimed",
      customerItemReference: "Item reference",
    },
    card: {
      invoice: "Invoice",
      price: "Price",
      quantity: "Quantity",
      customerItemReference: "Item reference",
      alreadyClaimdCount: "Item already claimed",
    },
    table: {
      invoiceOneClickTooltip: "Search list for invoice {{invoiceNumber}}",
      head: {
        product: "Item",
        order: "Order",
        invoice: "Invoice",
        pricePerUnit: "Unit price",
        quantity: "Quantity",
        claimedQuantity: "Claimed",
        customerItemReference: "Item reference",
        inclDiscount: "incl. {{ discount }} discount",
      },
    },
  },
  claimDetails: {
    card: {
      claimCount: "{{count}}/{{remainingClaimableQuantity}}",
      invoiceNumber: "Invoice: {{ invoiceNumber }}",
      alreadyClaimed: "Already claimed",
    },
    stepTitle: "Enter claim details",
    stepTitleMobile: "Enter details",

    articleNumber: "Item no.: {{ productNumber }}",
    invoiceNumber: "Invoice number",
    unitPrice: "Unit price",
    deliveredQuantity: "Quantity delivered",
    allreadyClaimed: "(of which {{count}} item already claimed)",
    inclDiscount: "(incl. {{ discount }} discount)",
    claimCartInfo: {
      title: "Summary",
      description: "You have selected the following items for claim:",
      invoiceNumber: "Invoice no.: {{ invoiceNumber }}",
    },
    claimInfo: {
      title: "How to file a claim with heo",
      text1:
        "An item is defective, damaged or does not fulfill the promised quality? The wrong item was delivered? The packaging of the item is damaged?",
      text2:
        "We're sorry to hear that. You can use this online form to send us your claim quickly and easily. We will then find a suitable solution for you!",
      generalTitle: "General information",
      generalInfo1: {
        title: "1. Which item can I make a claim about?",
        text: "All items with a defect can be claimed within 12 months from the date of purchase. The prerequisite is to document this defect for us in the best possible way. This online form will help you do this.",
      },
      generalInfo2: {
        title: "2. What about items that were delivered to me more than 12 months ago?",
        text: "According to our terms and conditions, items that were delivered more than 12 months ago are excluded from a claim. A claim is only possible as a gesture of goodwill.",
      },
      generalInfo3: {
        title: "3. What happens after your claim?",
        text: "The claim will usually be processed by us within 14 days. The current status of a claim can be viewed at any time in the claim overview. You will also be informed about all important aspects of the claim by e-mail.",
      },
    },
    form: {
      title: "# {{ index }}",
      serialNumberInputLabel: "Serial number",
      serialNumberInputPlaceholder: {
        default: "Enter serial number (if available)",
        wronglyOrdered: "Enter wrong serial number of the delivered item",
      } as Record<string, string>,
      claimReasonSelectLabel: "Reason for claim",
      claimReasonSelectPlaceholder: "Select a reason",
      claimReasonSelectNoReason: "No reason selected yet",
      preferredSettlementSelectLabel: "Processing preference",
      preferredSettlementSelectPlaceholder: "Select an editing preference",
      overdeliverLabel: "Number of overdelivered copies",
      underdeliverLabel: "Number of underdelivered copies",
      commentTextareaLabel: "Description",
      commentTextarea: {
        label: "",
        placeholder: {
          DAMAGED_PACKAGING: "Enter detailed description",
          DAMAGED_PRODUCT: "Enter detailed description",
          INFERIOR_QUALITY: "Enter detailed description",
          MISSING_PARTS: "Enter detailed description",
          OVERDELIVERED: "Enter detailed description",
          UNDERDELIVERED: "Enter detailed description",
          WRONGLY_ORDERED: "Enter detailed description",
          WRONG_SERIAL_NUMBER: "Enter detailed description",
          WRONGLY_DELIVERED: "Enter detailed description",
        } as Record<ProductClaimReasonType, string>,
      },
      uploadFilesLabel: "Photo upload {{ size }}/{{ max }}MB",
      missingFiles: "At least 1 photo must be uploaded.",
      maxFileSizeExceeded: "A maximum of 20MB is allowed!",
    },
    tutorials: {
      DAMAGED_PACKAGING: {
        accordionTitle: "Damaged packaging (DAP)",
        description:
          "If you receive a damaged product, we need two pictures from you. The first picture should show the entire damaged product and the second picture should take a closer look at the damage. It would be helpful if you could mark the area where the damage is visible on the second picture. If you can't do this, please write a description in the comments field.",
      },
      DAMAGED_PRODUCT: {
        accordionTitle: "Damaged item (DEF)",
        description:
          "If you receive a defective or faulty product, we need two pictures from you. The first picture should show the whole product and the second picture should take a closer look at the damage or defect. It would be helpful if you could mark the area where the damage or fault is visible on the second picture. Sometimes it is better to take the damaged or faulty item out of the packaging to better identify the problem. If this is not possible, please write a description of the problem in the comments field.",
      },
      INFERIOR_QUALITY: {
        accordionTitle: "Quality is not right",
        description:
          "If you receive a product with poor quality or an obvious defect (for example, it looks different than described or has colour defects), then we need two pictures from you. The first picture should show the defect in the product and the second picture should take a closer look at the defect. It would be helpful if you could mark the area where the defect is visible on the second picture. If you can't do this, please write a description of the defect in the comments field.",
      },
      MISSING_PARTS: {
        accordionTitle: "Item incomplete",
        description:
          "If you receive an incomplete item where, for example, parts are missing from the packaging or separately packaged additional parts are not included in the parcel, then we need two pictures from you. The first picture should show the item as a whole and the second picture should take a closer look at the empty packaging area. If the missing parts were packed separately, then one picture of the whole item will suffice. If you can't take a picture, please write a description of the missing or replacement parts in the comments field.",
      },
      OVERDELIVERED: {
        accordionTitle: "Overdelivery",
        description:
          "If you realise that you have accidentally received too much of an item, tell us the item and invoice number, as well as the quantity you have received in excess. A description is sufficient, pictures do not need to be sent.",
      },
      UNDERDELIVERED: {
        accordionTitle: "Underdelivery",
        description:
          "If you realise that you have accidentally received too little of an item, tell us the item and invoice number and the quantity you have received too little of. A description is sufficient, you do not need to send pictures.",
      },
      WRONGLY_ORDERED: {
        accordionTitle: "Wrong order",
        description:
          "If you realise that you have inadvertently received the wrong item, please tell us the item and invoice number. A description is sufficient, pictures do not need to be sent.",
      },
      WRONGLY_DELIVERED: {
        accordionTitle: "Incorrectly delivered items",
        description:
          "If you have accidentally received the wrong item, you have two options. You can return the item and receive a free shipping label from us. You only need to select the item you did not receive in the claim. Alternatively, you can keep the item and buy it separately; if you choose this option, we will simply issue you with a separate invoice. In any case, the missing item will be delivered later.",
      },
      WRONG_SERIAL_NUMBER: {
        accordionTitle: "Wrong serial number",
        description:
          "If the serial numbers of the delivered item do not match, we need a photo of all delivered packaging or the item itself and a detailed photo of the serial numbers. A description of the problem may help, but is not essential.",
      },
    } as Record<ProductClaimReasonType, Record<string, string>>,
    allDetailsValid: "All details specified",
    missingDetails: "Necessary details are still missing",
    untouchedDetails: "Some details are still missing.",
    deleteClaimConfirmTitle: "Do you really want to delete the claim?",
    deleteClaimConfirmLabel: "Delete the claim",
    deleteClaimCancelLabel: "Cancel",
    deleteClaimGroupConfirmTitle: "Remove item from claim",
    deleteClaimGroupConfirmDescription_other:
      "Do you really want to remove the item <bold><italic>{{ productName }}</italic></bold>? This will cause {{ count }} prepared claims to be lost.",
    deleteClaimGroupConfirmDescription_one:
      "Do you really want to remove the item <bold><italic>{{ productName }}</italic></bold>? This will cause {{ count }} prepared claim to be lost.",
    deleteClaimGroupConfirmLabel: "Remove item",
    deleteClaimGroupCancelLabel: "Cancel",
    addClaimClone: "Claim another copy with the same details",
    addClaim: "Claim another copy",
    continueButtonLabel: "Continue",
    continueButtonWithCountLabel: "{{count}} item/s to claim",
    backButtonLabel: "Back",
    backToClaimsPage: "Back to the claims overview",
  },
  confirmClaim: {
    stepTitle: "Check & send data",
    continueButtonLabel: "Continue to check",
  },
};
