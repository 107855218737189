import { Box } from "@mantine/core";
import { AvailabilityMonthBox } from "./AvailabilityMonthBox";
import styles from "./ExpectedAvailabilityWidget.module.css";
import { LoadingSkeleton } from "./LoadingSkeleton/LoadingSkeleton";
import { getMonthRangeByMonthNumber } from "./availabilityWidgetDateRangeFns";
import { AvailableItemsWithMonthToAdd } from "./interface/AvailableItems";

type AvailabilityPeriodGroupProps = {
  isLoading: boolean;
  itemAvailabilityGroupByMonth: AvailableItemsWithMonthToAdd[];
};

const AvailabilityPeriodGroup: React.FC<AvailabilityPeriodGroupProps> = (props) => {
  const { isLoading, itemAvailabilityGroupByMonth } = props;

  return (
    <Box mr="2px">
      <Box data-testid="expectedAvailabilityWrapper" w={"100%"} className={styles.wrapper}>
        {isLoading && <LoadingSkeleton />}
        {itemAvailabilityGroupByMonth?.map((value) => (
          <AvailabilityMonthBox
            key={value.monthNumber}
            articleCount={value.countPositions}
            month={value.monthNumber}
            price={value.sumOfPositionsPrice}
            products={value.products}
            availabilityPeriod={getMonthRangeByMonthNumber(value.monthToAdd)}
            isLoading={isLoading}
          />
        ))}
      </Box>
    </Box>
  );
};

export { AvailabilityPeriodGroup };
