import { Text, useMantineTheme } from "@mantine/core";
import { ActionIcon, Group } from "@mantine/core";
import { IconShoppingCart } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useShopUrl } from "~/ui/pages/ProductsPage/ProductDetail/ProductInformation/PriceBox/useShopUrl";
import styles from "./ActionBar.module.css";

const ToShopActionButton = () => {
  const { shopUrl } = useShopUrl();
  const { t } = useTranslation();
  const theme = useMantineTheme();

  return (
    <Group align={"center"}>
      <ActionIcon
        classNames={{ root: styles.actionIcon }}
        component="a"
        href={shopUrl}
        target="_blank"
        size={32}
        color="blue"
      >
        <IconShoppingCart size="27" color={theme.colors.blue[5]} />

        <Text c="dark" pl="8px">
          {t("header.toShopLabel")}
        </Text>
      </ActionIcon>
    </Group>
  );
};

export { ToShopActionButton };
