import { isValid } from "date-fns";
import { useTranslation } from "react-i18next";

import { useHealthQuery } from "~/api/hooks/health.hooks";
import { ENV } from "~/env";

import { DateStyle, useLocalizedDateFormatter } from "./date/useLocalizedDateFormatter";

export type UseGetBuildVersionsResult = {
  backendCommitHash: string;
  frontendCommitHash: string;
  buildDateString: string;
  formatedConcatValues: string;
};

export const useGetBuildVersions = (): UseGetBuildVersionsResult => {
  const { t } = useTranslation();
  const { formatDate } = useLocalizedDateFormatter();

  const { data: healthData } = useHealthQuery();
  const { commit: backendCommitHash = "?" } = healthData ?? {};
  const { buildDate, commitHash: frontendCommitHash } = ENV;

  const buildDateString = isValid(new Date(buildDate))
    ? formatDate(buildDate, DateStyle.DEFAULT_WITH_TIME)
    : "?";

  const formatedConcatValues = `
    ${t("footer.backendVersionLabel")}: ${backendCommitHash}
    ${t("footer.frontendVersionLabel")}: ${frontendCommitHash}
    ${t("footer.buildDateLabel")}: ${buildDateString}
  `;
  return {
    backendCommitHash,
    frontendCommitHash: frontendCommitHash ?? "-",
    buildDateString,
    formatedConcatValues,
  };
};
