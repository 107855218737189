import { useQuery } from "@tanstack/react-query";

import { GetKpiDataOverviewOfCurrentRetailerRequest } from "../../openapi";
import { useRestAPI } from "../../useRestAPI";

export const GetKpiDataOverviewQueryKey = "GetKpiDataOverview";

export const useGetKpiDataOverviewQuery = (
  requestParameters: GetKpiDataOverviewOfCurrentRetailerRequest,
  enabled: boolean,
) => {
  const api = useRestAPI();

  return useQuery({
    queryKey: [GetKpiDataOverviewQueryKey, requestParameters],
    queryFn: () => api.dashboard.getKpiDataOverviewOfCurrentRetailer({ ...requestParameters }),
    enabled: enabled,
  });
};
