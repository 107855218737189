/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeoTransactionNumber } from './HeoTransactionNumber';
import {
    HeoTransactionNumberFromJSON,
    HeoTransactionNumberFromJSONTyped,
    HeoTransactionNumberToJSON,
} from './HeoTransactionNumber';

/**
 * 
 * @export
 * @interface ShipmentProjection
 */
export interface ShipmentProjection {
    /**
     * 
     * @type {HeoTransactionNumber}
     * @memberof ShipmentProjection
     */
    invoiceNumber: HeoTransactionNumber;
    /**
     * 
     * @type {string}
     * @memberof ShipmentProjection
     */
    service: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentProjection
     */
    trackingNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentProjection
     */
    trackingLink: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentProjection
     */
    weight: number;
    /**
     * 
     * @type {Date}
     * @memberof ShipmentProjection
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the ShipmentProjection interface.
 */
export function instanceOfShipmentProjection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "invoiceNumber" in value;
    isInstance = isInstance && "service" in value;
    isInstance = isInstance && "trackingNumber" in value;
    isInstance = isInstance && "trackingLink" in value;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function ShipmentProjectionFromJSON(json: any): ShipmentProjection {
    return ShipmentProjectionFromJSONTyped(json, false);
}

export function ShipmentProjectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentProjection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invoiceNumber': HeoTransactionNumberFromJSON(json['invoiceNumber']),
        'service': json['service'],
        'trackingNumber': json['trackingNumber'],
        'trackingLink': json['trackingLink'],
        'weight': json['weight'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function ShipmentProjectionToJSON(value?: ShipmentProjection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invoiceNumber': HeoTransactionNumberToJSON(value.invoiceNumber),
        'service': value.service,
        'trackingNumber': value.trackingNumber,
        'trackingLink': value.trackingLink,
        'weight': value.weight,
        'createdAt': (value.createdAt.toISOString()),
    };
}

