/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HeoItemId } from './HeoItemId';
import {
    HeoItemIdFromJSON,
    HeoItemIdFromJSONTyped,
    HeoItemIdToJSON,
} from './HeoItemId';
import type { HeoTransactionNumber } from './HeoTransactionNumber';
import {
    HeoTransactionNumberFromJSON,
    HeoTransactionNumberFromJSONTyped,
    HeoTransactionNumberToJSON,
} from './HeoTransactionNumber';

/**
 * 
 * @export
 * @interface ClaimableInvoiceItem
 */
export interface ClaimableInvoiceItem {
    /**
     * 
     * @type {string}
     * @memberof ClaimableInvoiceItem
     */
    id: string;
    /**
     * 
     * @type {HeoItemId}
     * @memberof ClaimableInvoiceItem
     */
    heoItemId: HeoItemId;
    /**
     * 
     * @type {number}
     * @memberof ClaimableInvoiceItem
     */
    pricePerUnit: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimableInvoiceItem
     */
    discountedPricePerUnit: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimableInvoiceItem
     */
    discount: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimableInvoiceItem
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof ClaimableInvoiceItem
     */
    claimedQuantity: number;
    /**
     * 
     * @type {string}
     * @memberof ClaimableInvoiceItem
     */
    productNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimableInvoiceItem
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimableInvoiceItem
     */
    productImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ClaimableInvoiceItem
     */
    productThumbnailUrl: string;
    /**
     * 
     * @type {HeoTransactionNumber}
     * @memberof ClaimableInvoiceItem
     */
    invoiceNumber: HeoTransactionNumber;
    /**
     * 
     * @type {boolean}
     * @memberof ClaimableInvoiceItem
     */
    invoiceFiled: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ClaimableInvoiceItem
     */
    invoiceCreatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ClaimableInvoiceItem
     */
    invoiceCustomerItemReference: string;
}

/**
 * Check if a given object implements the ClaimableInvoiceItem interface.
 */
export function instanceOfClaimableInvoiceItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "heoItemId" in value;
    isInstance = isInstance && "pricePerUnit" in value;
    isInstance = isInstance && "discountedPricePerUnit" in value;
    isInstance = isInstance && "discount" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "claimedQuantity" in value;
    isInstance = isInstance && "productNumber" in value;
    isInstance = isInstance && "productName" in value;
    isInstance = isInstance && "productImageUrl" in value;
    isInstance = isInstance && "productThumbnailUrl" in value;
    isInstance = isInstance && "invoiceNumber" in value;
    isInstance = isInstance && "invoiceFiled" in value;
    isInstance = isInstance && "invoiceCreatedAt" in value;
    isInstance = isInstance && "invoiceCustomerItemReference" in value;

    return isInstance;
}

export function ClaimableInvoiceItemFromJSON(json: any): ClaimableInvoiceItem {
    return ClaimableInvoiceItemFromJSONTyped(json, false);
}

export function ClaimableInvoiceItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClaimableInvoiceItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'heoItemId': HeoItemIdFromJSON(json['heoItemId']),
        'pricePerUnit': json['pricePerUnit'],
        'discountedPricePerUnit': json['discountedPricePerUnit'],
        'discount': json['discount'],
        'quantity': json['quantity'],
        'claimedQuantity': json['claimedQuantity'],
        'productNumber': json['productNumber'],
        'productName': json['productName'],
        'productImageUrl': json['productImageUrl'],
        'productThumbnailUrl': json['productThumbnailUrl'],
        'invoiceNumber': HeoTransactionNumberFromJSON(json['invoiceNumber']),
        'invoiceFiled': json['invoiceFiled'],
        'invoiceCreatedAt': (new Date(json['invoiceCreatedAt'])),
        'invoiceCustomerItemReference': json['invoiceCustomerItemReference'],
    };
}

export function ClaimableInvoiceItemToJSON(value?: ClaimableInvoiceItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'heoItemId': HeoItemIdToJSON(value.heoItemId),
        'pricePerUnit': value.pricePerUnit,
        'discountedPricePerUnit': value.discountedPricePerUnit,
        'discount': value.discount,
        'quantity': value.quantity,
        'claimedQuantity': value.claimedQuantity,
        'productNumber': value.productNumber,
        'productName': value.productName,
        'productImageUrl': value.productImageUrl,
        'productThumbnailUrl': value.productThumbnailUrl,
        'invoiceNumber': HeoTransactionNumberToJSON(value.invoiceNumber),
        'invoiceFiled': value.invoiceFiled,
        'invoiceCreatedAt': (value.invoiceCreatedAt.toISOString()),
        'invoiceCustomerItemReference': value.invoiceCustomerItemReference,
    };
}

