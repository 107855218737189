/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreProfileDay } from './StoreProfileDay';
import {
    StoreProfileDayFromJSON,
    StoreProfileDayFromJSONTyped,
    StoreProfileDayToJSON,
} from './StoreProfileDay';

/**
 * 
 * @export
 * @interface StoreProfileDetailsOpeningHours
 */
export interface StoreProfileDetailsOpeningHours {
    /**
     * 
     * @type {StoreProfileDay}
     * @memberof StoreProfileDetailsOpeningHours
     */
    monday?: StoreProfileDay;
    /**
     * 
     * @type {StoreProfileDay}
     * @memberof StoreProfileDetailsOpeningHours
     */
    tuesday?: StoreProfileDay;
    /**
     * 
     * @type {StoreProfileDay}
     * @memberof StoreProfileDetailsOpeningHours
     */
    wednesday?: StoreProfileDay;
    /**
     * 
     * @type {StoreProfileDay}
     * @memberof StoreProfileDetailsOpeningHours
     */
    thursday?: StoreProfileDay;
    /**
     * 
     * @type {StoreProfileDay}
     * @memberof StoreProfileDetailsOpeningHours
     */
    friday?: StoreProfileDay;
    /**
     * 
     * @type {StoreProfileDay}
     * @memberof StoreProfileDetailsOpeningHours
     */
    saturday?: StoreProfileDay;
    /**
     * 
     * @type {StoreProfileDay}
     * @memberof StoreProfileDetailsOpeningHours
     */
    sunday?: StoreProfileDay;
}

/**
 * Check if a given object implements the StoreProfileDetailsOpeningHours interface.
 */
export function instanceOfStoreProfileDetailsOpeningHours(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StoreProfileDetailsOpeningHoursFromJSON(json: any): StoreProfileDetailsOpeningHours {
    return StoreProfileDetailsOpeningHoursFromJSONTyped(json, false);
}

export function StoreProfileDetailsOpeningHoursFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfileDetailsOpeningHours {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monday': !exists(json, 'monday') ? undefined : StoreProfileDayFromJSON(json['monday']),
        'tuesday': !exists(json, 'tuesday') ? undefined : StoreProfileDayFromJSON(json['tuesday']),
        'wednesday': !exists(json, 'wednesday') ? undefined : StoreProfileDayFromJSON(json['wednesday']),
        'thursday': !exists(json, 'thursday') ? undefined : StoreProfileDayFromJSON(json['thursday']),
        'friday': !exists(json, 'friday') ? undefined : StoreProfileDayFromJSON(json['friday']),
        'saturday': !exists(json, 'saturday') ? undefined : StoreProfileDayFromJSON(json['saturday']),
        'sunday': !exists(json, 'sunday') ? undefined : StoreProfileDayFromJSON(json['sunday']),
    };
}

export function StoreProfileDetailsOpeningHoursToJSON(value?: StoreProfileDetailsOpeningHours | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monday': StoreProfileDayToJSON(value.monday),
        'tuesday': StoreProfileDayToJSON(value.tuesday),
        'wednesday': StoreProfileDayToJSON(value.wednesday),
        'thursday': StoreProfileDayToJSON(value.thursday),
        'friday': StoreProfileDayToJSON(value.friday),
        'saturday': StoreProfileDayToJSON(value.saturday),
        'sunday': StoreProfileDayToJSON(value.sunday),
    };
}

