import { useDraggable, useDroppable } from "@dnd-kit/core";
import clsx from "clsx";
import React, { CSSProperties } from "react";

import { GridItemWrapper } from "../GridItemWrapper/GridItemWrapper";
import { GridLayoutItem, OnToggleVisibility } from "../GridLayout";

import styles from "./SortableGridItem.module.css";

export interface SortableGridItemProps {
  id: string;
  item: GridLayoutItem;
  isEditing: boolean;
  onToggleVisibility: OnToggleVisibility;
}

const SortableGridItem: React.FC<SortableGridItemProps> = (props) => {
  const { id, item, isEditing, onToggleVisibility } = props;

  const {
    setNodeRef: setDraggableRef,
    transform,
    attributes,
    listeners,
    isDragging,
  } = useDraggable({
    id,
    disabled: item.static || !isEditing,
  });

  const { setNodeRef: setDroppableRef } = useDroppable({ id });

  const style: CSSProperties = {
    transform: transform ? `translate(${transform?.x}px, ${transform?.y}px)` : undefined,
  };

  const overlayClassNames = clsx(
    styles.overlay,
    isEditing && styles.editing,
    item.static && styles.static,
    item.disabled && styles.disabled,
  );

  if (item.disabled && !isEditing) return null;

  return (
    <GridItemWrapper
      item={item}
      isEditing={isEditing}
      isDragging={isDragging}
      onToggleVisibility={onToggleVisibility}
      withFadeIn
    >
      <div ref={setDroppableRef}>
        <div
          ref={setDraggableRef}
          style={style}
          className={styles.draggable}
          {...attributes}
          {...listeners}
        >
          <div className={overlayClassNames} />
          <div className={styles.content}>{item.content}</div>
        </div>
      </div>
    </GridItemWrapper>
  );
};

export { SortableGridItem };
