import { PaymentMethod } from "~/api/openapi";

export const CreditNotesPageDE = {
  pageTitle: "Meine Gutschriften",
  title: "Meine Gutschriften ({{ count }})",
  creditNoteSummaryRefundMethod: {
    PREPAYMENT: "Rückerstattung",
    PAYPAL: "Rückzahlung",
    SEPA_DIRECT_DEBIT: "Rückzahlung",
    FACTORING: "Verrechnung",
    BRAINTREE: "Rückzahlung",
    CREDIT_CARD: "Rückzahlung",
    DIRECT_DEBIT: "Verrechnung",
    INVOICE: "Rückzahlung",
    CASH: "Barauszahlung",
    OTHER: "Andere",
  } as Record<PaymentMethod, string>,

  refundMethodInfoText: {
    PREPAYMENT: "Der Betrag wird auf dein Konto überwiesen.",
    PAYPAL: "Der Betrag wird auf dein Paypal-Konto überwiesen.",
    SEPA_DIRECT_DEBIT: "Der Betrag wird beim nächsten Einzug berücksichtigt.",
    FACTORING: "Bitte mit nächster Zahlung verrechnen.",
    BRAINTREE: "Der Betrag wird auf dein Kreditkarten-Konto überwiesen.",
    CREDIT_CARD: "Der Betrag wird deiner Kreditkarte gutgeschrieben.",
    DIRECT_DEBIT: "Bitte mit nächster Zahlung verrechnen.",
    INVOICE: "Der Betrag wird auf dein Konto überwiesen.",
    CASH: "Der Betrag wird bar ausgezahlt.",
    OTHER: "Andere",
  } as Record<PaymentMethod, string>,

  filter: {
    title: "Filtern & Sortieren",
    createdAt: "Datum",
    searchPlaceholder: "Gutschriften durchsuchen (min. 3 Zeichen)",
  },
  sort: {
    creditCreatedAt: "Erstellt am",
    creditNumber: "Gutschrift",
    amount: "Betrag",
    refundMethod: "Erstattungsmethode",
    description: "Beschreibung",
  },

  card: {
    amount: "Betrag",
    refundMethod: "Erstattungsmethode",
    description: "Beschreibung",
    showCreditNotesButton: "Gutschrift {{creditNoteNumber}} anzeigen",
  },

  pdfDownload: {
    notFound: "Für die Gutschrift {{ creditNoteNumber }} existiert kein Dokument.",
  },

  table: {
    noCreditsAvailable: "Keine Gutschriften vorhanden.",
    noCreditsFound: "Keine Gutschriften gefunden, die deinen Suchkriterien entsprechen.",
    header: {
      creditCreatedAt: "Datum",
      creditNumber: "Gutschrift",
      amount: "Betrag",
      refundMethod: "Erstattungsmethode",
      description: "Beschreibung",
    },
    body: {
      refundMethodInfoText: {
        paypal: "Bitte mit nächster Zahlung verrechnen",
      },
    },
  },
};
