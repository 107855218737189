import { Group, Skeleton, Stack } from "@mantine/core";

const CurrentDeliveriesSkeleton = () => {
  return (
    <Group>
      <Skeleton height={40} circle mb="xl" />
      <Group>
        <Stack>
          <Skeleton height={16} width={120} />
          <Skeleton height={25} width={110} />
          <Skeleton height={20} width={200} />
        </Stack>
        <Stack style={{ marginLeft: 30, marginTop: 35 }}>
          <Skeleton height={20} width={100} />
        </Stack>
      </Group>
    </Group>
  );
};

export { CurrentDeliveriesSkeleton };
