import { z } from "zod";

export const KpiTilesPeriodsSchema = z
  .object({
    customerOrderPeriod: z.string().optional().catch("7d"),
    salesOrderPeriod: z.string().optional().catch("7d"),
    invoicePeriod: z.string().optional().catch("7d"),
    creditNotePeriod: z.string().optional().catch("7d"),
  })
  .nullable()
  .catch({
    customerOrderPeriod: "7d",
    salesOrderPeriod: "7d",
    invoicePeriod: "7d",
    creditNotePeriod: "7d",
  });

export type KpiTilesPeriods = z.infer<typeof KpiTilesPeriodsSchema>;
