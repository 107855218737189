import { useLocalStorage, useMediaQuery } from "@mantine/hooks";

const NAVBAR_EXTENDED_LOCAL_STORAGE_KEY = "navbar-is-extended";

export const useNavbarExtend = () => {
  const [isExtended = true, setIsExtended] = useLocalStorage({
    key: NAVBAR_EXTENDED_LOCAL_STORAGE_KEY,
    defaultValue: true,
    getInitialValueInEffect: false,
  });

  const toggleIsExtended = () => {
    setIsExtended((state) => !state);
  };

  const forceCollapse = useMediaQuery("(max-width: 1200px)");

  return {
    forceCollapse,
    isExtended: forceCollapse ? false : isExtended,
    setIsExtended,
    toggleIsExtended,
  };
};
