import { Box, Button, Center, Group, Skeleton, Stack, Timeline } from "@mantine/core";
import { clsx } from "clsx";
import Lottie from "lottie-react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGetOpenInvoiceOverviewQuery } from "~/api/hooks/dashboard/useGetOpenInvoiceOverviewQuery";
import { PeriodOption } from "~/common/enum/PeriodOption";
import { formatCurrency } from "~/helper/Formatter/formatCurrency";
import { ResponsiveButtonGroup } from "~/ui/components/ResponsiveButtonGroup/ResponsiveButtonGroup";
import { Scroller } from "~/ui/components/Scroller/Scroller";
import { createInvoiceLink } from "~/ui/routes/DynamicRoutes/createInvoiceLink";
import { WidgetContainer } from "../../WidgetContainer/WidgetContainer";
import styles from "./OpenInvoicesWidget.module.css";
import { PaymentStatusIcon } from "./PaymentStatusIcon";
import { TimelineItemData } from "./TimelineItem";
import emptyWidget from "./animations/emptyWidget.json";
import { mapInvoiceResponseToInvoiceWidgetData } from "./mapInvoiceResponseToInvoiceWidgetData";

const OpenInvoicesWidget: React.FC = () => {
  const { t } = useTranslation();

  const { data: invoicesSummaryData, isLoading } = useGetOpenInvoiceOverviewQuery();
  const invoiceWidgetData = useMemo(
    () => mapInvoiceResponseToInvoiceWidgetData(invoicesSummaryData),
    [invoicesSummaryData],
  );
  const hasOverdueInvoices =
    invoiceWidgetData && invoiceWidgetData?.invoiceSummaryData.overDueCount > 0;

  const getSubTitleText = (): string => {
    if (invoiceWidgetData?.invoiceSummaryData.totalCount === 0)
      return t("dashboardPage.OpenInvoicesWidget.subtitle.noInvoices");

    if (invoiceWidgetData?.invoiceSummaryData.overDueCount === 0)
      return t("dashboardPage.OpenInvoicesWidget.subtitle.invoiceWithoutOverdue", {
        count: invoiceWidgetData?.invoiceSummaryData.totalCount,
        totalAmount: formatCurrency(invoiceWidgetData?.invoiceSummaryData.totalAmount),
      });

    return t("dashboardPage.OpenInvoicesWidget.subtitle.invoiceWithOverdue", {
      count: invoiceWidgetData?.invoiceSummaryData.totalCount,
      totalAmount: formatCurrency(invoiceWidgetData?.invoiceSummaryData.totalAmount),
      overdueCount: invoiceWidgetData?.invoiceSummaryData.overDueCount,
    });
  };

  return (
    <WidgetContainer
      title={t("dashboardPage.OpenInvoicesWidget.title")}
      isLoading={isLoading}
      subtitle={getSubTitleText()}
      className={clsx(hasOverdueInvoices && styles.overdueBorder)}
    >
      <Box data-testid="timeline-wrapper" py={"md"}>
        {isLoading && (
          <Group>
            <Skeleton height={40} circle mb="xl" />
            <Group>
              <Stack>
                <Skeleton height={16} width={120} />
                <Skeleton height={25} width={110} />
                <Skeleton height={20} width={200} />
              </Stack>
              <Stack style={{ marginLeft: 30, marginTop: 35 }}>
                <Skeleton height={20} width={100} />
                <Skeleton height={20} width={100} />
              </Stack>
            </Group>
          </Group>
        )}

        {!isLoading && invoiceWidgetData?.invoiceSummaryData.totalCount === 0 && (
          <Center className={styles.noDataWrapper}>
            <Lottie animationData={emptyWidget} loop={false} style={{ height: 200 }} />
          </Center>
        )}

        {!isLoading && invoiceWidgetData?.invoiceSummaryData.totalCount !== 0 && (
          <Scroller mah={400}>
            <Timeline
              style={{ marginLeft: 16 }}
              classNames={{
                item: styles.item,
                itemBullet: styles.itemBullet,
                itemBody: styles.itemBody,
              }}
            >
              {invoiceWidgetData?.invoiceWidgetChartData.map((widgetData) => {
                return (
                  <Timeline.Item
                    key={widgetData.invoiceNumber}
                    bullet={<PaymentStatusIcon paymentStatus={widgetData.paymentStatus} />}
                  >
                    <TimelineItemData widgetData={widgetData} />
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </Scroller>
        )}
      </Box>
      <ResponsiveButtonGroup pt={"md"}>
        <Button
          variant={"outline"}
          component={Link}
          to={createInvoiceLink({ period: PeriodOption.ALL })}
        >
          {t("dashboardPage.OpenInvoicesWidget.showAllInvoicesButton")}
        </Button>
      </ResponsiveButtonGroup>
    </WidgetContainer>
  );
};

export { OpenInvoicesWidget };
