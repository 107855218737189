/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreOverview } from './StoreOverview';
import {
    StoreOverviewFromJSON,
    StoreOverviewFromJSONTyped,
    StoreOverviewToJSON,
} from './StoreOverview';
import type { StoreProfileChangeRequest } from './StoreProfileChangeRequest';
import {
    StoreProfileChangeRequestFromJSON,
    StoreProfileChangeRequestFromJSONTyped,
    StoreProfileChangeRequestToJSON,
} from './StoreProfileChangeRequest';

/**
 * 
 * @export
 * @interface StoreProfile
 */
export interface StoreProfile {
    /**
     * 
     * @type {string}
     * @memberof StoreProfile
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreProfile
     */
    published: boolean;
    /**
     * 
     * @type {string}
     * @memberof StoreProfile
     */
    url?: string;
    /**
     * 
     * @type {Date}
     * @memberof StoreProfile
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StoreProfile
     */
    updatedAt?: Date;
    /**
     * 
     * @type {StoreOverview}
     * @memberof StoreProfile
     */
    store: StoreOverview;
    /**
     * 
     * @type {StoreProfileChangeRequest}
     * @memberof StoreProfile
     */
    changeRequest?: StoreProfileChangeRequest;
}

/**
 * Check if a given object implements the StoreProfile interface.
 */
export function instanceOfStoreProfile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "published" in value;
    isInstance = isInstance && "store" in value;

    return isInstance;
}

export function StoreProfileFromJSON(json: any): StoreProfile {
    return StoreProfileFromJSONTyped(json, false);
}

export function StoreProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'published': json['published'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'store': StoreOverviewFromJSON(json['store']),
        'changeRequest': !exists(json, 'changeRequest') ? undefined : StoreProfileChangeRequestFromJSON(json['changeRequest']),
    };
}

export function StoreProfileToJSON(value?: StoreProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'published': value.published,
        'url': value.url,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'store': StoreOverviewToJSON(value.store),
        'changeRequest': StoreProfileChangeRequestToJSON(value.changeRequest),
    };
}

