/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { HeoCustomerOrderNumber } from './HeoCustomerOrderNumber';
import {
    HeoCustomerOrderNumberFromJSON,
    HeoCustomerOrderNumberFromJSONTyped,
    HeoCustomerOrderNumberToJSON,
} from './HeoCustomerOrderNumber';
import type { OrderOrigin } from './OrderOrigin';
import {
    OrderOriginFromJSON,
    OrderOriginFromJSONTyped,
    OrderOriginToJSON,
} from './OrderOrigin';
import type { OrderType } from './OrderType';
import {
    OrderTypeFromJSON,
    OrderTypeFromJSONTyped,
    OrderTypeToJSON,
} from './OrderType';

/**
 * 
 * @export
 * @interface CustomerOrderDetailsExport
 */
export interface CustomerOrderDetailsExport {
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderDetailsExport
     */
    id: string;
    /**
     * 
     * @type {HeoCustomerOrderNumber}
     * @memberof CustomerOrderDetailsExport
     */
    number: HeoCustomerOrderNumber;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderDetailsExport
     */
    customerReference: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerOrderDetailsExport
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderDetailsExport
     */
    total: number;
    /**
     * 
     * @type {OrderOrigin}
     * @memberof CustomerOrderDetailsExport
     */
    orderOrigin?: OrderOrigin;
    /**
     * 
     * @type {OrderType}
     * @memberof CustomerOrderDetailsExport
     */
    orderType?: OrderType;
    /**
     * 
     * @type {Address}
     * @memberof CustomerOrderDetailsExport
     */
    shippingAddress?: Address;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderDetailsExport
     */
    itemPricePerUnit: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderDetailsExport
     */
    itemTotal: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderDetailsExport
     */
    itemHeoPositionNumberCompanyCode: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderDetailsExport
     */
    itemHeoPositionNumberNumber: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderDetailsExport
     */
    itemStatus?: CustomerOrderDetailsExportItemStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderDetailsExport
     */
    itemDiscount: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderDetailsExport
     */
    itemCustomerItemReference: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderDetailsExport
     */
    itemTax: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerOrderDetailsExport
     */
    itemFiled: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderDetailsExport
     */
    itemProductNumber: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderDetailsExport
     */
    itemQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderDetailsExport
     */
    itemShippedQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderDetailsExport
     */
    itemAcceptedQuantity: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderDetailsExport
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrderDetailsExport
     */
    productGtin: string;
}


/**
 * @export
 */
export const CustomerOrderDetailsExportItemStatusEnum = {
    Unconfirmed: 'UNCONFIRMED',
    Confirmed: 'CONFIRMED',
    Other: 'OTHER'
} as const;
export type CustomerOrderDetailsExportItemStatusEnum = typeof CustomerOrderDetailsExportItemStatusEnum[keyof typeof CustomerOrderDetailsExportItemStatusEnum];


/**
 * Check if a given object implements the CustomerOrderDetailsExport interface.
 */
export function instanceOfCustomerOrderDetailsExport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "customerReference" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "itemPricePerUnit" in value;
    isInstance = isInstance && "itemTotal" in value;
    isInstance = isInstance && "itemHeoPositionNumberCompanyCode" in value;
    isInstance = isInstance && "itemHeoPositionNumberNumber" in value;
    isInstance = isInstance && "itemDiscount" in value;
    isInstance = isInstance && "itemCustomerItemReference" in value;
    isInstance = isInstance && "itemTax" in value;
    isInstance = isInstance && "itemFiled" in value;
    isInstance = isInstance && "itemProductNumber" in value;
    isInstance = isInstance && "itemQuantity" in value;
    isInstance = isInstance && "itemShippedQuantity" in value;
    isInstance = isInstance && "itemAcceptedQuantity" in value;
    isInstance = isInstance && "productName" in value;
    isInstance = isInstance && "productGtin" in value;

    return isInstance;
}

export function CustomerOrderDetailsExportFromJSON(json: any): CustomerOrderDetailsExport {
    return CustomerOrderDetailsExportFromJSONTyped(json, false);
}

export function CustomerOrderDetailsExportFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOrderDetailsExport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': HeoCustomerOrderNumberFromJSON(json['number']),
        'customerReference': json['customerReference'],
        'createdAt': (new Date(json['createdAt'])),
        'total': json['total'],
        'orderOrigin': !exists(json, 'orderOrigin') ? undefined : OrderOriginFromJSON(json['orderOrigin']),
        'orderType': !exists(json, 'orderType') ? undefined : OrderTypeFromJSON(json['orderType']),
        'shippingAddress': !exists(json, 'shippingAddress') ? undefined : AddressFromJSON(json['shippingAddress']),
        'itemPricePerUnit': json['itemPricePerUnit'],
        'itemTotal': json['itemTotal'],
        'itemHeoPositionNumberCompanyCode': json['itemHeoPositionNumberCompanyCode'],
        'itemHeoPositionNumberNumber': json['itemHeoPositionNumberNumber'],
        'itemStatus': !exists(json, 'itemStatus') ? undefined : json['itemStatus'],
        'itemDiscount': json['itemDiscount'],
        'itemCustomerItemReference': json['itemCustomerItemReference'],
        'itemTax': json['itemTax'],
        'itemFiled': json['itemFiled'],
        'itemProductNumber': json['itemProductNumber'],
        'itemQuantity': json['itemQuantity'],
        'itemShippedQuantity': json['itemShippedQuantity'],
        'itemAcceptedQuantity': json['itemAcceptedQuantity'],
        'productName': json['productName'],
        'productGtin': json['productGtin'],
    };
}

export function CustomerOrderDetailsExportToJSON(value?: CustomerOrderDetailsExport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': HeoCustomerOrderNumberToJSON(value.number),
        'customerReference': value.customerReference,
        'createdAt': (value.createdAt.toISOString()),
        'total': value.total,
        'orderOrigin': OrderOriginToJSON(value.orderOrigin),
        'orderType': OrderTypeToJSON(value.orderType),
        'shippingAddress': AddressToJSON(value.shippingAddress),
        'itemPricePerUnit': value.itemPricePerUnit,
        'itemTotal': value.itemTotal,
        'itemHeoPositionNumberCompanyCode': value.itemHeoPositionNumberCompanyCode,
        'itemHeoPositionNumberNumber': value.itemHeoPositionNumberNumber,
        'itemStatus': value.itemStatus,
        'itemDiscount': value.itemDiscount,
        'itemCustomerItemReference': value.itemCustomerItemReference,
        'itemTax': value.itemTax,
        'itemFiled': value.itemFiled,
        'itemProductNumber': value.itemProductNumber,
        'itemQuantity': value.itemQuantity,
        'itemShippedQuantity': value.itemShippedQuantity,
        'itemAcceptedQuantity': value.itemAcceptedQuantity,
        'productName': value.productName,
        'productGtin': value.productGtin,
    };
}

