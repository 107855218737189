/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Name } from './Name';
import {
    NameFromJSON,
    NameFromJSONTyped,
    NameToJSON,
} from './Name';

/**
 * 
 * @export
 * @interface ContactDetails
 */
export interface ContactDetails {
    /**
     * 
     * @type {Name}
     * @memberof ContactDetails
     */
    name: Name;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails
     */
    mobileNumber: string;
}

/**
 * Check if a given object implements the ContactDetails interface.
 */
export function instanceOfContactDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "phoneNumber" in value;
    isInstance = isInstance && "mobileNumber" in value;

    return isInstance;
}

export function ContactDetailsFromJSON(json: any): ContactDetails {
    return ContactDetailsFromJSONTyped(json, false);
}

export function ContactDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': NameFromJSON(json['name']),
        'phoneNumber': json['phoneNumber'],
        'mobileNumber': json['mobileNumber'],
    };
}

export function ContactDetailsToJSON(value?: ContactDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': NameToJSON(value.name),
        'phoneNumber': value.phoneNumber,
        'mobileNumber': value.mobileNumber,
    };
}

