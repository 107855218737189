import { z } from "zod";

export const TableColumnSettingsSchema = z
  .array(
    z
      .object({
        column: z.string(),
        label: z.string().optional(),
        hidden: z.boolean().optional(),
      })
      .optional(),
  )
  .catch([]);
