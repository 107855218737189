import { ApiAdvantageType } from "~/ui/pages/ManageApiPage/ActivateApiPage/ApiAdvantage";

export const ManageApiPageDE = {
  title: "heoGATE - Verwalte deinen Zugang zur Retailer API",

  activateApiPage: {
    pageTitle: "API-Zugang aktivieren",
    notYetEnabled:
      "Dein Account ist noch nicht für die Nutzung der Retailer API freigeschaltet. Schalte deinen Zugang jetzt gleich frei.",
    apiAdvantagesTitle: "Nutze die Vorteile der heoGATE Retailer API",
    apiAdvantagesHeaderInfo:
      "heoGATE - unsere Retailer-API, die deinen Webshop mit dem starken Backend des heoVERSE verbindet.",
    advantages: {
      contentManagement: {
        title: "Vereinfachte Content-Pflege",
        description:
          "Du kannst unsere Produkte direkt in der eigenen Webseite oder im eigenen Online-Shop einspielen lassen. Spare viele Stunden an manueller Content-Pflege und Copy&Paste.",
      },
      dataQuality: {
        title: "Erhöhte Datenqualität",
        description:
          "Alle Produktinformationen, Verfügbarkeiten und Preise werden direkt und automatisch aus unserem System bezogen und sind in deinem Online Shop immer auf dem neuesten Stand.",
      },
      dropshipping: {
        title: "Dropshipping an Endkunden",
        description:
          "Du kannst Produkte direkt an Endkunden verkaufen, ohne dass du diese Produkte vorher selbst kaufen und lagern musst.",
      },
      simpleUsage: {
        title: "Einfach & sicher",
        description:
          "Die Verwendung unserer API ist unkompliziert. Nutze unsere umfassende Dokumentation, finde Lösungen für deine Anwendungsfälle. Die Sicherheit unserer API ist State-of-the-Art. Nutze auch eine dedizierte Testumgebung.",
      },
    } as Record<ApiAdvantageType, Record<string, string>>,
    apiActivateTitle: "Aktiviere jetzt deinen heoGATE Retailer API Zugang",
    form: {
      formDescription:
        "Um dir Zugriff auf die Retailer API zu gewähren, benötigen wir die URLs deiner Webseiten, auf denen du beabsichtigst, die Daten unserer API zu veröffentlichen. Dies stellt sicher, dass diese Daten rechtmäßig und gemäß unseren Richtlinien verwendet werden.",
      formTitle: "Webseiten für API-Datenveröffentlichung:",
      submitButtonLabel: "Jetzt API-Zugang aktivieren",
      addShopUrlButtonLabel: "Weitere Website hinzufügen",
      shopUrlPlaceholder: "https://beispielshop.de",
      invalidUrl: "Bitte gib eine gültige URL ein. (https://beispielshop.de)",
      userAgreementCheckboxLabel: "Ich habe die <0>AGB</0><1/> gelesen und stimme ihnen zu.",
      userAgreementNotChecked:
        "Du musst die AGB akzeptieren, um deinen API-Zugang aktivieren zu können.",
      successMessage: "Dein API-Zugang wurde erfolgreich aktiviert!",
      noUrlsAdded: "Bitte gib mindestens eine URL an.",
      identiclUrlsAdded: "Bitte gib keine identischen URLs an.",
    },
  },

  sideInfo: {
    documentationTitle: "Dokumentation zur Retailer API",
    documentationDescription: [
      "Wir bieten dir eine <0>umfassende Dokumentation zur Retailer API</0> inkl. einem <i>Getting Started</i> Kapitel, einem <i>UseCase Guide</i>, einem <i>Reference Guide</i>, sowie einer <i>Swagger UI</i> documentation.<br><br>Für den Zugriff auf die Dokumentation benötigst du die nebenstehenden Zugangsdaten (<i>Client ID</i> und <i>Client Secret</i>) für die Produktivumgebung.",
    ],
    postmanTitle: "Quick Start: Postman Collection",
    postmanDescription: [
      "Für deinen schnellen und einfachen Einstieg in die Verwendung der Retailer API kannst du dir hier eine <0>Postman Collection herunterladen</0>.",
    ],
    supportTitle: "Support",
    supportDescription: [
      "Solltest du darüber hinaus noch Fragen haben, steht dir unser Support-Team jederzeit unter <0>api@heo.com</0> gerne zur Verfügung.",
    ],
    websitesTitle: "Datenveröffentlichung",
    websitesDescription: [
      "Die nachfolgend aufgeführten Websites hast du für die Veröffentlichung der Daten aus der Retailer API registriert.<br><br>Für Details zu Verwendung der Daten, beachte unsere <0>AGB zur Retailer API</0>.",
    ],
    changeUrlsButtonLabel: "Angaben ändern",

    publicationUrlForm: {
      formTitle: "Webseiten für API-Datenveröffentlichung ändern",
      userAgreementCheckboxLabel: "Ich habe die <0>AGB</0><1/> gelesen und stimme ihnen zu.",
      userAgreementNotChecked: "Du musst die AGB akzeptieren, um deine Webseiten zu ändern.",
      shopUrlPlaceholder: "https://beispielshop.de",
      invalidUrl: "Bitte gib eine gültige URL ein.",
      addShopUrlButtonLabel: "Weitere Website hinzufügen",
      submitButtonLabel: "Änderungen speichern",
      cancelButtonLabel: "Abbrechen",
      submitError: "Die URLs konnten nicht gespeichert werden, versuche es bitte erneut.",
    },
  },

  apiDisabled: {
    title: "API-Zugang gesperrt",
    pageTitle: "API-Zugang gesperrt",
    subtitle:
      "Dein API-Zugang wurde von heo deaktiviert. Bitte wende dich an den <0>Customer Service</0>, wenn du weiterhin Zugang zur Retailer-API haben möchtest.",
    mailSubject: "Anfrage Zugang zur Retailer-API",
  },

  apiAccessDisabled: {
    title: "heoGATE Zugang deaktiviert",
    pageTitle: "heoGATE Zugang deaktiviert",
    subtitle: "Bei Fragen steht Ihnen ihr <0>Customer Service Team</0> zur Verfügung.",
    mailSubject: "Anfrage Zugang zur Retailer-API",
  },

  apiClientManager: {
    pageTitle: "heoGATE - Verwalte deinen Zugang zur Retailer API",
    pageDescription:
      "Verwalte deine Clients für den Zugriff auf die Produktivumgebung und die Testinstanzen der Retailer API.",
    liveClientTitle: "Produktivumgebung der Retailer API",
    liveClientDescription:
      "Für den Zugriff auf Live-Daten benötigen wir die IP-Adresse deiner Produktivumgebung. Bitte beachte, dass der API-Client nur mit der von dir angegebenen IP-Adresse verwendbar ist. <br /> <strong>Wichtiger Hinweis: Es kann nur ein API-Client pro Produktivumgebung generiert werden. </strong>",
    ipAddressPlaceholder: "IP-Adresse (IPv4) deines Shops (bspw. 172.217.168.206)",
    ipAddressLabel: "IP-Adresse",
    liveClientSubmitLabel: "API-Client für Live-Umgebung generieren",
    invalidIp: "Bitte gib eine gültige IPv4-Adresse ein.",
    reservedIp:
      "Diese IP ist für private Netzwerke reserviert und nur intern sichtbar. Bitte gib die IP-Adresse der Komponente an über die die Retailer-API genutzt wird.",
    clientIpAddress: "Dein Retailer API Client ist registriert für diese IP-Adresse",
    clientIpAddresses: "Dein Retailer API Client ist registriert für diese IP-Adressen",
    clientCreatedAt: "Erstellt am: {{ date }}",
    clientIdLabel: "Client ID",
    clientSecretLabel: "Client Secret",
    deleteLiveClientButtonLabel: "API-Client löschen",
    deleteLiveClientModalTitle: "API-Client für Produktivumgebung löschen",

    deleteLiveClientForm: {
      description:
        "Bist du sicher, dass du den API Client mit der folgenden Client ID löschen möchtest?",
      confirmText: "Bitte bestätige das Löschen durch die Eingabe der Client ID.",
      clientIdLabel: "Client ID",
      clientIdPlaceholder: "Client ID hier bestätigen",
      clientIdError: "Die eingegebene Client ID stimmt nicht überein",
      warningText:
        "Das Löschen dieses API-Clients führt zum sofortigen Zugriffsverlust auf die Produktivumgebung der Retailer-API und deren Services. Für erneuten Zugriff musst du einen neuen API Client erstellen.",
      deleteButtonLabel: "API Client unwiderruflich löschen",
      cancelButtonLabel: "Abbrechen",
    },

    testClientForm: {
      title: "Testinstanzen der Retailer API",
      description:
        "Du kannst du beliebig viele API-Clients für Testinstanzen (Sandboxes) generieren. Diese Clients sind ausschließlich für den Einsatz in den Sandboxen vorgesehen.",
      descriptionLabel: "Beschreibung",
      descriptionPlaceholder: "Gib deinem Sandbox-Client eine Beschreibung..",
      createTestClientButtonLabel: "API-Client für Sandbox generieren",
      clientIdColumnLabel: "Client ID",
      clientSecretColumnLabel: "Client Secret",
      createdAtColumnLabel: "Erstellt am",
      noDataText: "Du hast noch keine API-Clients für die Sandbox angelegt",
      deleteModalTitle: "API Client für Testinstanz löschen",
      deleteModalDescription:
        "Bist du sicher, dass du den API Client mit der folgenden Client ID löschen möchtest?",
      deleteModalHint: "Das Löschen kann nicht rückgängig gemacht werden.",
      deleteModalConfirmButtonLabel: "API Client löschen",
      deleteModalCancelButtonLabel: "Abbrechen",
    },
  },
};
