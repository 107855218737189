import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { TotalSales } from "~/api/openapi";
import { formatCurrency } from "~/helper/Formatter/formatCurrency";

import { ChartColors } from "./ChartColors";

export const useTotalSalesChartConfig = (totalSales: TotalSales[] | undefined) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "totalSalesDetailsPage",
  });

  const totalSalesTop10 =
    totalSales
      ?.toSorted((a, b) => a.year - b.year)
      .toReversed()
      .toSpliced(10)
      .toReversed() ?? [];

  const chartData: ApexAxisChartSeries = useMemo(() => {
    return [
      {
        name: t("orders"),
        type: "column",
        color: ChartColors.ORDERS,
        data: totalSalesTop10?.map((item) => item.ordersAmount) ?? [],
      },
      {
        name: t("invoices"),
        type: "column",
        color: ChartColors.INVOICES,
        data: totalSalesTop10?.map((item) => item.invoiceAmount) ?? [],
      },
      {
        name: t("creditNotes"),
        type: "column",
        color: ChartColors.CREDIT_NOTES,
        data: totalSalesTop10?.map((item) => item.creditNoteAmount) ?? [],
      },
      {
        name: t("canceledOrders"),
        type: "column",
        color: ChartColors.CANCELED_ORDERS,
        data: totalSalesTop10?.map((item) => item.canceledOrdersAmount) ?? [],
      },
      {
        name: t("canceledInvoices"),
        type: "column",
        color: ChartColors.CANCELED_INVOICES,
        data: totalSalesTop10?.map((item) => item.canceledInvoiceAmount) ?? [],
      },
      {
        name: t("quota"),
        type: "line",
        color: ChartColors.QUOTA,
        data: totalSalesTop10?.map((item) => item.quota) ?? [],
      },
    ] as ApexAxisChartSeries;
  }, [totalSalesTop10, t]);

  const quotaIndex = chartData.findIndex((item) => item.type === "line");

  const formatTooltip = useCallback(
    (value: number, seriesIndex: number) => {
      if (seriesIndex === quotaIndex) {
        return `${value} %`;
      }
      return formatCurrency(value);
    },
    [quotaIndex],
  );

  const chartOptions: ApexCharts.ApexOptions | undefined = useMemo(() => {
    if (!totalSales) return undefined;

    const options: ApexCharts.ApexOptions = {
      plotOptions: {
        bar: {
          hideZeroBarsWhenGrouped: true,
        },
      },
      chart: {
        stacked: false,
        zoom: {
          enabled: false,
        },

        id: "totalSalesChart",
        toolbar: {
          show: false,
        },
      },

      dataLabels: {
        enabledOnSeries: [5],
        enabled: true,
        formatter: (value) => {
          return `${value} %`;
        },
        offsetY: -5,
      },

      yaxis: [
        {
          seriesName: t("orders"),
          showAlways: true,
          decimalsInFloat: 0,
          tickAmount: 10,
          labels: {
            style: { fontWeight: "bolder" },
            formatter(val) {
              if (val < 1000) {
                return val.toString();
              }

              return `${val / 1000} K`;
            },
          },
        },
        { seriesName: t("orders"), show: false },
        { seriesName: t("orders"), show: false },
        { seriesName: t("orders"), show: false },
        { seriesName: t("orders"), show: false },
        { seriesName: t("quota"), show: false },
      ],

      tooltip: {
        y: {
          formatter(val, opts) {
            return formatTooltip(val, opts.seriesIndex);
          },
        },
      },

      stroke: {
        show: true,
        curve: "smooth",
        colors: ["transparent"],
        width: 3,
      },

      xaxis: {
        categories: totalSalesTop10?.map((item) => item.year.toString()) ?? [],
        axisTicks: {
          show: false,
        },

        labels: {
          show: true,
          style: {
            fontWeight: "bolder",
          },
        },
      },
      grid: {
        padding: {
          left: 30,
          right: 30,
        },

        show: true,
      },
    };
    return options;
  }, [totalSalesTop10, totalSales, formatTooltip, t]);

  return { chartData, chartOptions };
};
