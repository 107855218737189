import { useQuery } from "@tanstack/react-query";

import { useRestAPI } from "../../useRestAPI";

const GetTotalSalesQueryKey = "GetTotalSales";

export const useGetTotalSales = () => {
  const api = useRestAPI();
  return useQuery({
    queryKey: [GetTotalSalesQueryKey],
    queryFn: () => api.totalSales.getTotalSalesOfCurrentRetailer(),
  });
};
