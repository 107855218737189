import { Group, Skeleton, Stack } from "@mantine/core";

const OpenOrdersSkeleton: React.FC = () => {
  return (
    <Group ml={40} mt={40} align={"flex-start"}>
      <Skeleton height={240} circle mb="xl" />
      <Stack ml={10} mt={20}>
        <Group>
          <Skeleton height={23} circle />
          <Skeleton height={16} width={100} />
        </Group>
        <Group>
          <Skeleton height={20} circle />
          <Skeleton height={16} width={100} />
        </Group>
        <Group>
          <Skeleton height={20} circle />
          <Skeleton height={16} width={100} />
        </Group>
        <Group>
          <Skeleton height={20} circle />
          <Skeleton height={16} width={100} />
        </Group>
      </Stack>
    </Group>
  );
};

export { OpenOrdersSkeleton };
