import { Carousel, Embla } from "@mantine/carousel";
import { AvailabilityPeriodGroup } from "./AvailabilityPeriodGroup";
import styles from "./ExpectedAvailabilityWidget.module.css";
import { AvailableItemsWithMonthToAdd } from "./interface/AvailableItems";

type AvailabilityPeriodGroupCarouselProps = {
  salesOrderItemsLoading: boolean;
  itemAvailabilityGroupedByPeriod: Record<number, AvailableItemsWithMonthToAdd[]>;
  firstPeriodToShow: number;
  setEmbla: (embla: Embla | null) => void;
};

const AvailabilityPeriodGroupCarousel: React.FC<AvailabilityPeriodGroupCarouselProps> = (props) => {
  const { salesOrderItemsLoading, itemAvailabilityGroupedByPeriod, firstPeriodToShow, setEmbla } =
    props;

  return (
    <Carousel
      withControls={false}
      initialSlide={firstPeriodToShow}
      classNames={{ root: styles.root, controls: styles.controls, control: styles.control }}
      getEmblaApi={setEmbla}
    >
      <Carousel.Slide>
        <AvailabilityPeriodGroup
          isLoading={salesOrderItemsLoading}
          itemAvailabilityGroupByMonth={itemAvailabilityGroupedByPeriod[0]}
        />
      </Carousel.Slide>

      <Carousel.Slide>
        <AvailabilityPeriodGroup
          isLoading={salesOrderItemsLoading}
          itemAvailabilityGroupByMonth={itemAvailabilityGroupedByPeriod[1]}
        />
      </Carousel.Slide>
      <Carousel.Slide>
        <AvailabilityPeriodGroup
          isLoading={salesOrderItemsLoading}
          itemAvailabilityGroupByMonth={itemAvailabilityGroupedByPeriod[2]}
        />
      </Carousel.Slide>
    </Carousel>
  );
};

export { AvailabilityPeriodGroupCarousel };
