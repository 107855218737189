import { Stack } from "@mantine/core";
import clsx from "clsx";
import { useNavbarExtend } from "../useNavbarExtend";
import styles from "./Navbar.module.css";
import { NavbarContent } from "./NavbarContent/NavbarContent";

export type NavbarProps = {
  hideNavbarLinks?: boolean;
};

const Navbar = (props: NavbarProps) => {
  const { hideNavbarLinks } = props;

  const { isExtended, forceCollapse, toggleIsExtended } = useNavbarExtend();

  const containerStyles = clsx(styles.container, hideNavbarLinks && styles.noPermissions);

  return (
    <Stack py={50} className={containerStyles}>
      <NavbarContent
        hideNavbarLinks={Boolean(hideNavbarLinks)}
        isExtended={isExtended}
        forceCollapse={forceCollapse}
        onExtend={toggleIsExtended}
      />
    </Stack>
  );
};

export { Navbar };
