import { PeriodOption } from "~/common/enum/PeriodOption";

export const DashboardPageDE = {
  title: "Mein Dashboard",

  editDashboardTooltip: "Dashboard bearbeiten",
  endEditing: "Dashboard speichern",
  noDataTitle: "Dein Dashboard ist aktuell leer",
  noDataDescription:
    "Du hast aktuell keine Widgets für dein Dashboard aktiviert. Wechsle in den Bearbeitungsmodus und passe dein Dashboard an deine Bedürfnisse an.",
  noDataDescriptionMobile: "Du hast aktuell keine Widgets für dein Dashboard aktiviert.",
  startEditingButtonLabel: "Dashboard bearbeiten",
  resetDashboard: "Dashboard zurücksetzen",

  CurrentDeliveriesWidget: {
    title: "Aktuelle Lieferungen",
    subtitle: {
      noDeliveries: "Keine Lieferungen vorhanden",
      delivery_one:
        "{{count}} Lieferung (davon {{countInProgress}} in Bearbeitung) in den letzten 7 Tagen",
      delivery_other:
        "{{count}} Lieferungen (davon {{countInProgress}} in Bearbeitung) in den letzten 7 Tagen",
    },
    numberOfPackages: {
      package_one: "{{count}} Paket",
      package_other: "{{count}} Pakete",
    },
    shippingStatusInProgress: "In Bearbeitung",
    shippingStatusShipped: "Versandt",
    showAllDeliveriesButton: "Alle Lieferungen anzeigen",

    noAdditionalProducts: "{{productNumber}}",
    moreAdditionalProducts: "{{productNumber}} + {{count}} weitere",
  },

  OpenOrderWidget: {
    title: "Produkte in offenen Bestellungen",
    subtitle: {
      noOrders: "Keine offenen Bestellungen vorhanden",
      order_one: "{{count}} Artikel ({{totalAmount}}) in Summe",
      order_other: "{{count}} Artikel ({{totalAmount}}) in Summe",
    },
    urlTooltip: 'Zur Rückstandsliste mit Filter "{{filter}}"',
    totalLabel: "Gesamt",
    allOpenOrdersButton: "Alle Rückstände anzeigen",
  },
  OpenInvoicesWidget: {
    title: "Offene Rechnungen",

    subtitle: {
      invoiceWithOverdue_one:
        "{{count}} offene Rechnung ({{totalAmount}}), davon {{overdueCount}} überfällig",
      invoiceWithoutOverdue_one: "{{count}} offene Rechnung ({{totalAmount}})",
      invoiceWithOverdue_other:
        "{{count}} offene Rechnungen ({{totalAmount}}), davon {{overdueCount}} überfällig",
      invoiceWithoutOverdue_other: "{{count}} offene Rechnungen ({{totalAmount}})",
      noInvoices: "Keine offenen Rechnungen vorhanden",
    },

    item: {
      paymentDueDateLabel: "Zahlungsziel: {{paymentDueDate}}",
      paymentDeadlineLabel: "Zahlungsfrist: {{paymentDueDate}}",
    },
    showAllInvoicesButton: "Alle Rechnungen anzeigen",
  },
  tiles: {
    customerOrderTitle: "Bestellungen",
    customerOrderTooltipTitle: "Zur Bestellübersicht",
    salesOrderTiele: "Rückstände",
    salesOrderTooltipTitle: "Zur Rückstandsliste",
    invoicesTitle: "Rechnungen",
    invoicesTooltipTitle: "Zur Rechnungsübersicht",
    creditNotesTitle: "Gutschriften",
    creditNotesTooltipTitle: "Zur Gutschriftenübersicht",
    periodSelect: {
      [PeriodOption.SEVEN_DAYS]: "in den letzten 7 Tagen",
      [PeriodOption.ONE_MONTH]: "im letzten Monat",
      [PeriodOption.THREE_MONTHS]: "in den letzten 3 Monaten",
      [PeriodOption.SIX_MONTHS]: "in den letzten 6 Monaten",
      [PeriodOption.TWELVE_MONTHS]: "in den letzten 12 Monaten",
      [PeriodOption.START_OF_YEAR]: "seit Jahresbeginn",
    } as Record<PeriodOption, string>,
  },

  OpenClaimsWidget: {
    title: "Offene Reklamationen",
    subtitle: {
      withClaims_one: "{{count}} Reklamation in Bearbeitung",
      withClaims_other: "{{count}} Reklamationen in Bearbeitung",
      noClaims: "Keine offenen Reklamationen vorhanden",
    },

    newClaimButton: "Neue Reklamation",
    allClaimsButton: "Alle Reklamationen anzeigen",
    claims: "Reklamationen",
  },
  ExpectedAvailabilityWidget: {
    title: "Vsl. Verfügbarkeit bestellter Artikel",
    subtitle: {
      item_one: "{{count}} Artikel bald verfügbar",
      item_other: "{{count}} Artikel bald verfügbar",
      item_zero: "Keine Artikel bald verfügbar",
    },

    tile: {
      header: {
        data_other: " ({{count}} Positionen für {{price}})",
        data_one: " ({{count}} Position für {{price}})",
        noData: " (Keine Position vorhanden)",
      },
      showItemButton: "Artikel anzeigen",
      noData: "Keine Position vorhanden",
    },
    showNextPeriod: "Nächster Zeitraum",
    showPreviousPeriod: "Vorheriger Zeitraum",
  },

  factoringWidget: {
    title: "Dein Factoring-Limit",
    description: "Dein Gesamtlimit beträgt <strong>{{ amount }}</strong>",
    used: "Genutzt",
    limit: "Limit",
    available: "Verfügbar",
    exceeded: "Überschritten",
    exceededAlert: "Dein Limit wurde mit <strong>{{ amount }}</strong> überschritten!",
  },
  totalSalesReportWidget: {
    title: "Umsatzüberblick",
    titleInfoTooltip:
      "In der Umsatzübersicht siehst du die Gesamtbeträge aller bestellten, in Rechnung gestellten und gutgeschriebenen Artikel. Außerdem werden dir hier die Gesamtbeträge aller von dir stornierten Bestell- und Rechnungsspositionen angezeigt.",
    showDetails: "Details anzeigen",
    noDataInfoText:
      "Die Umsatzübersicht ist noch leer. <strong>Mache jetzt deine erste Bestellung im heo-Shop</strong>, um die Umsatzseite mit Inhalten zu füllen und dir einen Überblick über deine Bestellungen, Rechnungen und Gutschriften zu verschaffen.",
    heoShopButton: "Zum heo-Shop",
    table: {
      totalLabel: "Gesamt",
      ordersLabel: "Bestellungen",
      invoicesLabel: "Rechnungen",
      creditsLabel: "Gutschriften",
      cancledOrdersLabel: "Stornierte Bestellungen",
      cancledInvoicesLabel: "Stornierte Rechnungen",
      cancellationRateLabel: "Quote",
      cancellationRate: "{{cancellationRate}} %",
    },
    quote: {
      description:
        "Die Quote ist ein Indikator für das Verhältnis von Bestellungen, Rechnungen, Gutschriften und Stornierungen.",
      ok: "{{lowerBorder}} - {{upperBorder}} %: Akzeptabel",
      medium: {
        title: "{{lowerBorder}} - {{upperBorder}} %: Achtung",
        description: "Reduzierung der Quote erforderlich",
      },
      bad: {
        title: "Ab {{border}} %: Kritisch",
        description:
          "Kann zu Einschränkungen, bis hin zur Beendigung der Geschäftsbeziehung, führen.",
      },
    },
  },
};
