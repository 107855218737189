export const SmileyNeutral = ({ size = 48, color = "currentColor", ...restProps }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>SmileyNeutral</title>
      <path
        d="M18 22C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20C20 19.4696 19.7893 18.9609 19.4142 18.5858C19.0391 18.2107 18.5304 18 18 18C17.4696 18 16.9609 18.2107 16.5858 18.5858C16.2107 18.9609 16 19.4696 16 20C16 20.5304 16.2107 21.0391 16.5858 21.4142C16.9609 21.7893 17.4696 22 18 22ZM18 30C17.4696 30 16.9609 30.2107 16.5858 30.5858C16.2107 30.9609 16 31.4696 16 32C16 32.5304 16.2107 33.0391 16.5858 33.4142C16.9609 33.7893 17.4696 34 18 34H30C30.5304 34 31.0391 33.7893 31.4142 33.4142C31.7893 33.0391 32 32.5304 32 32C32 31.4696 31.7893 30.9609 31.4142 30.5858C31.0391 30.2107 30.5304 30 30 30H18ZM32 20C32 20.5304 31.7893 21.0391 31.4142 21.4142C31.0391 21.7893 30.5304 22 30 22C29.4696 22 28.9609 21.7893 28.5858 21.4142C28.2107 21.0391 28 20.5304 28 20C28 19.4696 28.2107 18.9609 28.5858 18.5858C28.9609 18.2107 29.4696 18 30 18C30.5304 18 31.0391 18.2107 31.4142 18.5858C31.7893 18.9609 32 19.4696 32 20Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44 24C44 35.046 35.046 44 24 44C12.954 44 4 35.046 4 24C4 12.954 12.954 4 24 4C35.046 4 44 12.954 44 24ZM40 24C40 28.2435 38.3143 32.3131 35.3137 35.3137C32.3131 38.3143 28.2435 40 24 40C19.7565 40 15.6869 38.3143 12.6863 35.3137C9.68571 32.3131 8 28.2435 8 24C8 19.7565 9.68571 15.6869 12.6863 12.6863C15.6869 9.68571 19.7565 8 24 8C28.2435 8 32.3131 9.68571 35.3137 12.6863C38.3143 15.6869 40 19.7565 40 24Z"
        fill={color}
      />
    </svg>
  );
};
