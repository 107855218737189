import { useMutation } from "@tanstack/react-query";
import { MarkRetailerNotificationAsReadRequest } from "~/api/openapi";
import { useRestAPI } from "../../useRestAPI";

export const useSetRetailerNotificationAsRead = () => {
  const api = useRestAPI();

  return useMutation({
    mutationFn: (requestParameters: MarkRetailerNotificationAsReadRequest) =>
      api.retailerNotification.markRetailerNotificationAsRead(requestParameters),
  });
};
