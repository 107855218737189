/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PreferredSettlement = {
    Unknown: 'UNKNOWN',
    FullReplacement: 'FULL_REPLACEMENT',
    PartialReplacement: 'PARTIAL_REPLACEMENT',
    Refund: 'REFUND',
    Return: 'RETURN',
    Purchase: 'PURCHASE',
    ReturnAndRedelivery: 'RETURN_AND_REDELIVERY',
    PurchaseAndRedelivery: 'PURCHASE_AND_REDELIVERY'
} as const;
export type PreferredSettlement = typeof PreferredSettlement[keyof typeof PreferredSettlement];


export function PreferredSettlementFromJSON(json: any): PreferredSettlement {
    return PreferredSettlementFromJSONTyped(json, false);
}

export function PreferredSettlementFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreferredSettlement {
    return json as PreferredSettlement;
}

export function PreferredSettlementToJSON(value?: PreferredSettlement | null): any {
    return value as any;
}

