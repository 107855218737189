/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreProfileAccessibility } from './StoreProfileAccessibility';
import {
    StoreProfileAccessibilityFromJSON,
    StoreProfileAccessibilityFromJSONTyped,
    StoreProfileAccessibilityToJSON,
} from './StoreProfileAccessibility';
import type { StoreProfileActivity } from './StoreProfileActivity';
import {
    StoreProfileActivityFromJSON,
    StoreProfileActivityFromJSONTyped,
    StoreProfileActivityToJSON,
} from './StoreProfileActivity';
import type { StoreProfileCategory } from './StoreProfileCategory';
import {
    StoreProfileCategoryFromJSON,
    StoreProfileCategoryFromJSONTyped,
    StoreProfileCategoryToJSON,
} from './StoreProfileCategory';
import type { StoreProfileEquipment } from './StoreProfileEquipment';
import {
    StoreProfileEquipmentFromJSON,
    StoreProfileEquipmentFromJSONTyped,
    StoreProfileEquipmentToJSON,
} from './StoreProfileEquipment';
import type { StoreProfilePaymentMethod } from './StoreProfilePaymentMethod';
import {
    StoreProfilePaymentMethodFromJSON,
    StoreProfilePaymentMethodFromJSONTyped,
    StoreProfilePaymentMethodToJSON,
} from './StoreProfilePaymentMethod';

/**
 * 
 * @export
 * @interface StoreProfileMasterData
 */
export interface StoreProfileMasterData {
    /**
     * 
     * @type {Array<StoreProfileCategory>}
     * @memberof StoreProfileMasterData
     */
    categories: Array<StoreProfileCategory>;
    /**
     * 
     * @type {Array<StoreProfileActivity>}
     * @memberof StoreProfileMasterData
     */
    activities: Array<StoreProfileActivity>;
    /**
     * 
     * @type {Array<StoreProfileAccessibility>}
     * @memberof StoreProfileMasterData
     */
    accessibilities: Array<StoreProfileAccessibility>;
    /**
     * 
     * @type {Array<StoreProfilePaymentMethod>}
     * @memberof StoreProfileMasterData
     */
    storeProfilePaymentMethods: Array<StoreProfilePaymentMethod>;
    /**
     * 
     * @type {Array<StoreProfileEquipment>}
     * @memberof StoreProfileMasterData
     */
    storeProfileEquipments: Array<StoreProfileEquipment>;
}

/**
 * Check if a given object implements the StoreProfileMasterData interface.
 */
export function instanceOfStoreProfileMasterData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "activities" in value;
    isInstance = isInstance && "accessibilities" in value;
    isInstance = isInstance && "storeProfilePaymentMethods" in value;
    isInstance = isInstance && "storeProfileEquipments" in value;

    return isInstance;
}

export function StoreProfileMasterDataFromJSON(json: any): StoreProfileMasterData {
    return StoreProfileMasterDataFromJSONTyped(json, false);
}

export function StoreProfileMasterDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfileMasterData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categories': ((json['categories'] as Array<any>).map(StoreProfileCategoryFromJSON)),
        'activities': ((json['activities'] as Array<any>).map(StoreProfileActivityFromJSON)),
        'accessibilities': ((json['accessibilities'] as Array<any>).map(StoreProfileAccessibilityFromJSON)),
        'storeProfilePaymentMethods': ((json['storeProfilePaymentMethods'] as Array<any>).map(StoreProfilePaymentMethodFromJSON)),
        'storeProfileEquipments': ((json['storeProfileEquipments'] as Array<any>).map(StoreProfileEquipmentFromJSON)),
    };
}

export function StoreProfileMasterDataToJSON(value?: StoreProfileMasterData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categories': ((value.categories as Array<any>).map(StoreProfileCategoryToJSON)),
        'activities': ((value.activities as Array<any>).map(StoreProfileActivityToJSON)),
        'accessibilities': ((value.accessibilities as Array<any>).map(StoreProfileAccessibilityToJSON)),
        'storeProfilePaymentMethods': ((value.storeProfilePaymentMethods as Array<any>).map(StoreProfilePaymentMethodToJSON)),
        'storeProfileEquipments': ((value.storeProfileEquipments as Array<any>).map(StoreProfileEquipmentToJSON)),
    };
}

