/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GrantedSettlement } from './GrantedSettlement';
import {
    GrantedSettlementFromJSON,
    GrantedSettlementFromJSONTyped,
    GrantedSettlementToJSON,
} from './GrantedSettlement';
import type { HeoTransactionNumber } from './HeoTransactionNumber';
import {
    HeoTransactionNumberFromJSON,
    HeoTransactionNumberFromJSONTyped,
    HeoTransactionNumberToJSON,
} from './HeoTransactionNumber';
import type { PreferredSettlement } from './PreferredSettlement';
import {
    PreferredSettlementFromJSON,
    PreferredSettlementFromJSONTyped,
    PreferredSettlementToJSON,
} from './PreferredSettlement';
import type { ProductClaimAttachment } from './ProductClaimAttachment';
import {
    ProductClaimAttachmentFromJSON,
    ProductClaimAttachmentFromJSONTyped,
    ProductClaimAttachmentToJSON,
} from './ProductClaimAttachment';
import type { ProductClaimConversation } from './ProductClaimConversation';
import {
    ProductClaimConversationFromJSON,
    ProductClaimConversationFromJSONTyped,
    ProductClaimConversationToJSON,
} from './ProductClaimConversation';
import type { ProductClaimProcessingStatus } from './ProductClaimProcessingStatus';
import {
    ProductClaimProcessingStatusFromJSON,
    ProductClaimProcessingStatusFromJSONTyped,
    ProductClaimProcessingStatusToJSON,
} from './ProductClaimProcessingStatus';
import type { ProductClaimReason } from './ProductClaimReason';
import {
    ProductClaimReasonFromJSON,
    ProductClaimReasonFromJSONTyped,
    ProductClaimReasonToJSON,
} from './ProductClaimReason';

/**
 * 
 * @export
 * @interface ProductClaimDetailProjection
 */
export interface ProductClaimDetailProjection {
    /**
     * 
     * @type {string}
     * @memberof ProductClaimDetailProjection
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ProductClaimDetailProjection
     */
    number?: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductClaimDetailProjection
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductClaimDetailProjection
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductClaimDetailProjection
     */
    description: string;
    /**
     * 
     * @type {GrantedSettlement}
     * @memberof ProductClaimDetailProjection
     */
    grantedSettlement?: GrantedSettlement;
    /**
     * 
     * @type {PreferredSettlement}
     * @memberof ProductClaimDetailProjection
     */
    preferredSettlement: PreferredSettlement;
    /**
     * 
     * @type {ProductClaimProcessingStatus}
     * @memberof ProductClaimDetailProjection
     */
    processingStatus: ProductClaimProcessingStatus;
    /**
     * 
     * @type {number}
     * @memberof ProductClaimDetailProjection
     */
    deliveryDeviation?: number;
    /**
     * 
     * @type {ProductClaimReason}
     * @memberof ProductClaimDetailProjection
     */
    reason: ProductClaimReason;
    /**
     * 
     * @type {string}
     * @memberof ProductClaimDetailProjection
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ProductClaimDetailProjection
     */
    productNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ProductClaimDetailProjection
     */
    productName: string;
    /**
     * 
     * @type {HeoTransactionNumber}
     * @memberof ProductClaimDetailProjection
     */
    invoiceNumber: HeoTransactionNumber;
    /**
     * 
     * @type {Date}
     * @memberof ProductClaimDetailProjection
     */
    invoiceCreatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof ProductClaimDetailProjection
     */
    creditNoteNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductClaimDetailProjection
     */
    rmaNumber?: number;
    /**
     * 
     * @type {Array<ProductClaimAttachment>}
     * @memberof ProductClaimDetailProjection
     */
    attachments: Array<ProductClaimAttachment>;
    /**
     * 
     * @type {Array<ProductClaimConversation>}
     * @memberof ProductClaimDetailProjection
     */
    conversations: Array<ProductClaimConversation>;
}

/**
 * Check if a given object implements the ProductClaimDetailProjection interface.
 */
export function instanceOfProductClaimDetailProjection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "preferredSettlement" in value;
    isInstance = isInstance && "processingStatus" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "serialNumber" in value;
    isInstance = isInstance && "productNumber" in value;
    isInstance = isInstance && "productName" in value;
    isInstance = isInstance && "invoiceNumber" in value;
    isInstance = isInstance && "invoiceCreatedAt" in value;
    isInstance = isInstance && "attachments" in value;
    isInstance = isInstance && "conversations" in value;

    return isInstance;
}

export function ProductClaimDetailProjectionFromJSON(json: any): ProductClaimDetailProjection {
    return ProductClaimDetailProjectionFromJSONTyped(json, false);
}

export function ProductClaimDetailProjectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductClaimDetailProjection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'description': json['description'],
        'grantedSettlement': !exists(json, 'grantedSettlement') ? undefined : GrantedSettlementFromJSON(json['grantedSettlement']),
        'preferredSettlement': PreferredSettlementFromJSON(json['preferredSettlement']),
        'processingStatus': ProductClaimProcessingStatusFromJSON(json['processingStatus']),
        'deliveryDeviation': !exists(json, 'deliveryDeviation') ? undefined : json['deliveryDeviation'],
        'reason': ProductClaimReasonFromJSON(json['reason']),
        'serialNumber': json['serialNumber'],
        'productNumber': json['productNumber'],
        'productName': json['productName'],
        'invoiceNumber': HeoTransactionNumberFromJSON(json['invoiceNumber']),
        'invoiceCreatedAt': (new Date(json['invoiceCreatedAt'])),
        'creditNoteNumber': !exists(json, 'creditNoteNumber') ? undefined : json['creditNoteNumber'],
        'rmaNumber': !exists(json, 'rmaNumber') ? undefined : json['rmaNumber'],
        'attachments': ((json['attachments'] as Array<any>).map(ProductClaimAttachmentFromJSON)),
        'conversations': ((json['conversations'] as Array<any>).map(ProductClaimConversationFromJSON)),
    };
}

export function ProductClaimDetailProjectionToJSON(value?: ProductClaimDetailProjection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': value.number,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'description': value.description,
        'grantedSettlement': GrantedSettlementToJSON(value.grantedSettlement),
        'preferredSettlement': PreferredSettlementToJSON(value.preferredSettlement),
        'processingStatus': ProductClaimProcessingStatusToJSON(value.processingStatus),
        'deliveryDeviation': value.deliveryDeviation,
        'reason': ProductClaimReasonToJSON(value.reason),
        'serialNumber': value.serialNumber,
        'productNumber': value.productNumber,
        'productName': value.productName,
        'invoiceNumber': HeoTransactionNumberToJSON(value.invoiceNumber),
        'invoiceCreatedAt': (value.invoiceCreatedAt.toISOString()),
        'creditNoteNumber': value.creditNoteNumber,
        'rmaNumber': value.rmaNumber,
        'attachments': ((value.attachments as Array<any>).map(ProductClaimAttachmentToJSON)),
        'conversations': ((value.conversations as Array<any>).map(ProductClaimConversationToJSON)),
    };
}

