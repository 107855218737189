import { Select } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./LanguageSwitch.module.css";

const LanguageSwitch: React.FC = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (value: string | null) => {
    if (value) {
      i18n.changeLanguage(value);
    }
  };

  return (
    <Select
      classNames={{ input: styles.input }}
      w={100}
      value={i18n.language}
      onChange={handleLanguageChange}
      data={[
        { label: "DE", value: "de" },
        { label: "EN", value: "en" },
      ]}
    />
  );
};

export { LanguageSwitch };
