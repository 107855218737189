/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoreProfileAddress } from './StoreProfileAddress';
import {
    StoreProfileAddressFromJSON,
    StoreProfileAddressFromJSONTyped,
    StoreProfileAddressToJSON,
} from './StoreProfileAddress';
import type { StoreProfileContact } from './StoreProfileContact';
import {
    StoreProfileContactFromJSON,
    StoreProfileContactFromJSONTyped,
    StoreProfileContactToJSON,
} from './StoreProfileContact';
import type { StoreProfileDetailsImages } from './StoreProfileDetailsImages';
import {
    StoreProfileDetailsImagesFromJSON,
    StoreProfileDetailsImagesFromJSONTyped,
    StoreProfileDetailsImagesToJSON,
} from './StoreProfileDetailsImages';
import type { StoreProfileDetailsOpeningHours } from './StoreProfileDetailsOpeningHours';
import {
    StoreProfileDetailsOpeningHoursFromJSON,
    StoreProfileDetailsOpeningHoursFromJSONTyped,
    StoreProfileDetailsOpeningHoursToJSON,
} from './StoreProfileDetailsOpeningHours';

/**
 * 
 * @export
 * @interface StoreProfileStoreDetails
 */
export interface StoreProfileStoreDetails {
    /**
     * 
     * @type {string}
     * @memberof StoreProfileStoreDetails
     */
    type: StoreProfileStoreDetailsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileStoreDetails
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileStoreDetails
     */
    openingDate?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileStoreDetails
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StoreProfileStoreDetails
     */
    wpnStore: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StoreProfileStoreDetails
     */
    otsStore: boolean;
    /**
     * 
     * @type {StoreProfileAddress}
     * @memberof StoreProfileStoreDetails
     */
    address?: StoreProfileAddress;
    /**
     * 
     * @type {StoreProfileContact}
     * @memberof StoreProfileStoreDetails
     */
    contact?: StoreProfileContact;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreProfileStoreDetails
     */
    categories?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreProfileStoreDetails
     */
    accessibilities?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreProfileStoreDetails
     */
    equipments?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreProfileStoreDetails
     */
    paymentMethods?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreProfileStoreDetails
     */
    activities?: Array<string>;
    /**
     * 
     * @type {StoreProfileDetailsOpeningHours}
     * @memberof StoreProfileStoreDetails
     */
    openingHours?: StoreProfileDetailsOpeningHours;
    /**
     * 
     * @type {StoreProfileDetailsImages}
     * @memberof StoreProfileStoreDetails
     */
    images?: StoreProfileDetailsImages;
}


/**
 * @export
 */
export const StoreProfileStoreDetailsTypeEnum = {
    Online: 'ONLINE',
    Local: 'LOCAL'
} as const;
export type StoreProfileStoreDetailsTypeEnum = typeof StoreProfileStoreDetailsTypeEnum[keyof typeof StoreProfileStoreDetailsTypeEnum];


/**
 * Check if a given object implements the StoreProfileStoreDetails interface.
 */
export function instanceOfStoreProfileStoreDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "wpnStore" in value;
    isInstance = isInstance && "otsStore" in value;

    return isInstance;
}

export function StoreProfileStoreDetailsFromJSON(json: any): StoreProfileStoreDetails {
    return StoreProfileStoreDetailsFromJSONTyped(json, false);
}

export function StoreProfileStoreDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfileStoreDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'name': json['name'],
        'openingDate': !exists(json, 'openingDate') ? undefined : json['openingDate'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'wpnStore': json['wpnStore'],
        'otsStore': json['otsStore'],
        'address': !exists(json, 'address') ? undefined : StoreProfileAddressFromJSON(json['address']),
        'contact': !exists(json, 'contact') ? undefined : StoreProfileContactFromJSON(json['contact']),
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'accessibilities': !exists(json, 'accessibilities') ? undefined : json['accessibilities'],
        'equipments': !exists(json, 'equipments') ? undefined : json['equipments'],
        'paymentMethods': !exists(json, 'paymentMethods') ? undefined : json['paymentMethods'],
        'activities': !exists(json, 'activities') ? undefined : json['activities'],
        'openingHours': !exists(json, 'openingHours') ? undefined : StoreProfileDetailsOpeningHoursFromJSON(json['openingHours']),
        'images': !exists(json, 'images') ? undefined : StoreProfileDetailsImagesFromJSON(json['images']),
    };
}

export function StoreProfileStoreDetailsToJSON(value?: StoreProfileStoreDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'name': value.name,
        'openingDate': value.openingDate,
        'description': value.description,
        'wpnStore': value.wpnStore,
        'otsStore': value.otsStore,
        'address': StoreProfileAddressToJSON(value.address),
        'contact': StoreProfileContactToJSON(value.contact),
        'categories': value.categories,
        'accessibilities': value.accessibilities,
        'equipments': value.equipments,
        'paymentMethods': value.paymentMethods,
        'activities': value.activities,
        'openingHours': StoreProfileDetailsOpeningHoursToJSON(value.openingHours),
        'images': StoreProfileDetailsImagesToJSON(value.images),
    };
}

