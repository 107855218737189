import { useQuery } from "@tanstack/react-query";

import { useRestAPI } from "../useRestAPI";

export const GetAnnouncementsQueryKey = "GetAnnouncements";

export const useGetAnnouncements = () => {
  const api = useRestAPI();
  return useQuery({
    queryKey: [GetAnnouncementsQueryKey],
    queryFn: () => api.announcement.getAnnouncementsOnDisplay(),
  });
};
