import { Crisp } from "crisp-sdk-web";

import { useAppConfig } from "~/config/AppConfig";

type UseInitCrispChatProps = {
  customerNumber: string;
  email: string;
  company: string;
  name: string;
  hasData: boolean;
};

let isLoaded = false;

export const useInitCrispChat = (props: UseInitCrispChatProps) => {
  const { customerNumber, email, company, name, hasData } = props;

  const appConfig = useAppConfig();
  const crispWebsiteId = appConfig.tracking.crispId;

  if (crispWebsiteId && hasData && !isLoaded) {
    Crisp.configure(crispWebsiteId, {
      autoload: false,
    });

    Crisp.user.setEmail(email);
    Crisp.user.setNickname(name ? name : "Unbekannt");
    Crisp.user.setCompany(company, {});
    if (customerNumber !== undefined) {
      Crisp.session.setData({ kunden_nummer: customerNumber });
    }

    Crisp.load();
    isLoaded = true;
  }
};
