import { Avatar, AvatarProps, Divider, Group, Menu, Text, UnstyledButton } from "@mantine/core";
import { useClipboard, useDisclosure } from "@mantine/hooks";
import {
  IconApi,
  IconBuilding,
  IconCheck,
  IconCheckupList,
  IconChevronDown,
  IconClipboard,
  IconDownload,
  IconLogout,
  IconUser,
} from "@tabler/icons-react";
import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useGetCurrentRetailerQuery } from "~/api/hooks/retailer/useGetCurrentRetailerQuery";
import { useAppConfig } from "~/config/AppConfig";
import { getInitialChars } from "~/helper/InitialChars/getInitialChars";
import { useAuthProfile } from "~/hooks/profile/useAuthProfile";
import { RoutesEnum } from "~/ui/routes/RoutesEnum";
import { ReleaseNotes } from "../../../components/ReleaseNotes/ReleaseNotes";
import styles from "./UserMenu.module.css";

const MENU_ICON_SIZE = 16;

type UserMenuProps = {
  hideUserMenuLinks?: boolean;
  isMobileView?: boolean;
};

const UserMenu: React.FC<UserMenuProps> = (props) => {
  const { hideUserMenuLinks, isMobileView } = props;

  const { t } = useTranslation();
  const profile = useAuthProfile();
  const { data: retailerData } = useGetCurrentRetailerQuery();
  const [opened, handlers] = useDisclosure(false);
  const [releaseNotesOpened, releaseNotesHandlers] = useDisclosure(false);
  const exportLinkClipboard = useClipboard({ timeout: 3000 });

  const productExportLink = retailerData?.productExportLink;
  const appConfig = useAppConfig();

  if (!profile) {
    return null;
  }

  const fullName = profile.fullName ? profile.fullName : profile.email ? profile.email : "";

  const showMyServicesLabel =
    (appConfig.feature?.manageApiKeys || productExportLink) && !isMobileView;

  const avatarProps = {
    size: isMobileView ? 34 : 28,
  } satisfies Partial<AvatarProps>;

  return (
    <Menu
      opened={opened}
      onOpen={handlers.open}
      onClose={handlers.close}
      closeOnItemClick={false}
      transitionProps={{ transition: "pop-top-right" }}
      position="bottom-end"
      shadow={"md"}
      withinPortal={false}
    >
      <Menu.Target>
        <UnstyledButton>
          <Group gap={7}>
            <Avatar alt={`${fullName} avatar`} radius="xl" {...avatarProps}>
              {getInitialChars(fullName)}
            </Avatar>

            {!isMobileView && (
              <>
                <Text className={styles.avatarText}>{fullName}</Text>
                <IconChevronDown size={16} />
              </>
            )}
          </Group>
        </UnstyledButton>
      </Menu.Target>

      {!hideUserMenuLinks && (
        <Menu.Dropdown miw={200}>
          <Menu.Label>{t("userMenu.title")}</Menu.Label>

          <Menu.Item
            onClick={handlers.close}
            leftSection={<IconUser size={MENU_ICON_SIZE} />}
            component={Link}
            to={RoutesEnum.ACCOUNT}
          >
            {t("userMenu.accountSettingsLink")}
          </Menu.Item>

          <Menu.Item
            onClick={handlers.close}
            leftSection={<IconBuilding size={MENU_ICON_SIZE} />}
            component={Link}
            to={RoutesEnum.RETAILER}
          >
            {t("userMenu.companyProfileLink")}
          </Menu.Item>

          {showMyServicesLabel && (
            <>
              <Divider my={"xs"} /> <Menu.Label>{t("userMenu.myServices")}</Menu.Label>
            </>
          )}
          {appConfig.feature?.manageApiKeys && !isMobileView && (
            <Menu.Item
              onClick={handlers.close}
              leftSection={<IconApi size={MENU_ICON_SIZE} />}
              component={Link}
              to={RoutesEnum.MANAGE_API}
            >
              {t("userMenu.manageApi")}
            </Menu.Item>
          )}

          {productExportLink && !isMobileView && (
            <>
              <Menu.Item
                component="a"
                href={productExportLink}
                target="_blank"
                leftSection={<IconDownload size={MENU_ICON_SIZE} />}
              >
                {t("userMenu.downloadProductCatalog")}
              </Menu.Item>

              <Menu.Item
                className={cn(exportLinkClipboard.copied && styles.copied)}
                onClick={() => {
                  exportLinkClipboard.copy(productExportLink);
                }}
                leftSection={
                  exportLinkClipboard.copied ? (
                    <IconCheck size={MENU_ICON_SIZE} />
                  ) : (
                    <IconClipboard size={MENU_ICON_SIZE} />
                  )
                }
              >
                {exportLinkClipboard.copied
                  ? t("userMenu.linkCopied")
                  : t("userMenu.copyProductCatalogLink")}
              </Menu.Item>
            </>
          )}

          {productExportLink && <Divider my={"xs"} />}

          <Menu.Item
            leftSection={<IconCheckupList size={MENU_ICON_SIZE} />}
            onClick={releaseNotesHandlers.open}
          >
            {t("userMenu.releaseNotes")}
          </Menu.Item>

          <Divider my={"xs"} />

          <Menu.Item
            leftSection={<IconLogout size={MENU_ICON_SIZE} />}
            component={Link}
            to={RoutesEnum.LOGOUT}
          >
            {t("userMenu.logoutLink")}
          </Menu.Item>
        </Menu.Dropdown>
      )}
      {hideUserMenuLinks && (
        <Menu.Dropdown>
          <Menu.Item
            leftSection={<IconLogout size={MENU_ICON_SIZE} />}
            component={Link}
            to={RoutesEnum.LOGOUT}
          >
            {t("userMenu.logoutLink")}
          </Menu.Item>
        </Menu.Dropdown>
      )}

      {releaseNotesOpened && <ReleaseNotes forceShow onClose={releaseNotesHandlers.close} />}
    </Menu>
  );
};

export { UserMenu };
