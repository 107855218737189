export interface FormatCurrencyOptions {
  currency?: string;
}

export const formatCurrency = (
  value: number | undefined | null,
  options?: FormatCurrencyOptions,
) => {
  const { currency = "EUR" } = options ?? {};

  return (value || 0).toLocaleString("de-DE", {
    style: "currency",
    currency,
  });
};
