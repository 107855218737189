/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RetailerBillingStop
 */
export interface RetailerBillingStop {
    /**
     * 
     * @type {string}
     * @memberof RetailerBillingStop
     */
    key: RetailerBillingStopKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof RetailerBillingStop
     */
    reason: string;
    /**
     * 
     * @type {Date}
     * @memberof RetailerBillingStop
     */
    validUntil?: Date;
}


/**
 * @export
 */
export const RetailerBillingStopKeyEnum = {
    OverduePayment: 'BILLING_STOP_OVERDUE_PAYMENT',
    PaymentAdaption: 'BILLING_STOP_PAYMENT_ADAPTION',
    TemporaryAbsence: 'BILLING_STOP_TEMPORARY_ABSENCE',
    Others: 'BILLING_STOP_OTHERS',
    Unknown: 'BILLING_STOP_UNKNOWN'
} as const;
export type RetailerBillingStopKeyEnum = typeof RetailerBillingStopKeyEnum[keyof typeof RetailerBillingStopKeyEnum];


/**
 * Check if a given object implements the RetailerBillingStop interface.
 */
export function instanceOfRetailerBillingStop(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "reason" in value;

    return isInstance;
}

export function RetailerBillingStopFromJSON(json: any): RetailerBillingStop {
    return RetailerBillingStopFromJSONTyped(json, false);
}

export function RetailerBillingStopFromJSONTyped(json: any, ignoreDiscriminator: boolean): RetailerBillingStop {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'reason': json['reason'],
        'validUntil': !exists(json, 'validUntil') ? undefined : (new Date(json['validUntil'])),
    };
}

export function RetailerBillingStopToJSON(value?: RetailerBillingStop | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'reason': value.reason,
        'validUntil': value.validUntil === undefined ? undefined : (value.validUntil.toISOString()),
    };
}

