import {
  Country,
  CustomerOrderItemSummaryStatusEnum,
  GrantedSettlement,
  OrderType,
  PaymentMethod,
  PaymentStatus,
  PreferredSettlement,
  ProductClaimProcessingStatus,
  ProductClaimReason,
  RetailerDetailsBusinessTypeEnum,
  SalesOrderItemAvailabilitySummaryStatusEnum,
} from "~/api/openapi";
import { PeriodOption } from "~/common/enum/PeriodOption";
import { RelativeMonthPrefix } from "~/hooks/date/useRelativeMonth";
import { CategoryType, MoodType } from "~/ui/components/Feedback/FeedbackDrawer";

import { GlobalDE } from "../de/de.global";

export const GlobalEN: typeof GlobalDE = {
  All: "All",
  showMore: "Show more",
  showLess: "Show less",
  time: {
    suffix: "",
  },
  date: {
    lastWeek: "'last' eeee ',' p",
    today: "'today,' p",
    yesterday: "'yesterday,' p",
    tomorrow: "'tomorrow,' p",
  },
  incl: "incl.",

  relativeMonth: {
    [RelativeMonthPrefix.START]: "Early",
    [RelativeMonthPrefix.MID]: "Mid",
    [RelativeMonthPrefix.END]: "End",
  } as Record<RelativeMonthPrefix, string>,

  errors: {
    unknownErrorTitle: "An unexpected error has occurred. Please try again later.",
    unknownError: "An unknown error has occurred.",
    runtimeErrorTitle: "An error has occurred.",
    runtimeErrorReloadLabel: "Reload page",
    runtimeErrorShowDetails: "Show error",
    runtimeErrorHideDetails: "Hide error",
    dynamicLoadError: "The page could not be loaded.",
    dynamicLoadErrorMeasure: "Reload the page to fix the problem.",
    logoutError: "An error occurred during logout.",
    statusTitle: "Error {{ status }}",
  },

  pageTitle: {
    default: "Business Console - heo Business Console",
    prefix: "Business Console - ",
  },

  form: {
    errors: {
      required: "Please enter something here.",
      mail: {
        invalid: "You must enter a valid email address!",
      },
      phone: {
        required: "Please enter a phone number.",
      },
      max: "The input may be a maximum of {{ max }} characters.",
      nonempty: "The field must not be empty.",
    },
  },

  header: {
    guidedTourLabel: "Guided Tour",
    feedbackLabel: "Feedback",
    toShopLabel: "Shop",
    helpAndSupportLabel: "Help & Support",
  },

  navBar: {
    homeLabel: "Home",
    productsLabel: "Products",
    customerOrdersLabel: "Orders",
    invoicesLabel: "Invoices",
    productClaimsLabel: "Claims",
    backorderLabel: "Backorders",
    dashboadLabel: "Dashboard",
    creditsLabel: "Credits",
    impressLabel: "Legal",
    feedbackLabel: "Feedback",
    contactLabel: "Contact",
    version: {
      label: "Version",
      copy: "Copy",
      copied: "Copied",
    },
    expandNavBar: "Expand",
    collapseNavBar: "Collapse",
  },

  dropzone: {
    addMoreFiles: "Add more files",
  },

  aria: {
    productImageAltText: "An image of product {{ productName }} is displayed.",
  },

  country: {
    DE: "Germany",
    AT: "Austria",
    CH: "Switzerland",
    BE: "Belgium",
    DK: "Denmark",
    ES: "Spain",
    FI: "Finland",
    FR: "France",
    GB: "Great Britain",
    IE: "Ireland",
    IT: "Italy",
    NL: "Netherlands",
    LU: "Luxembourg",
    PT: "Portugal",
    SE: "Sweden",
    BG: "Bulgaria",
    CZ: "Czech Republic",
    EE: "Estonia",
    GR: "Greece",
    HU: "Hungary",
    LV: "Latvia",
    LT: "Lithuania",
    PL: "Poland",
    RO: "Romania",
    SI: "Slovenia",
    SK: "Slovakia",
    NO: "Norway",
  } as Record<Country["iso2"], string>,

  productClaimReason: {
    [ProductClaimReason.Unknown]: "Unknown",
    [ProductClaimReason.DamagedPackaging]: "DAP (Damaged packaging)",
    [ProductClaimReason.DamagedProduct]:
      "DEF (Production defect, breakage, scratches on the product)",
    [ProductClaimReason.MissingParts]: "Missing parts",
    [ProductClaimReason.Overdelivered]: "Overdelivered",
    [ProductClaimReason.Underdelivered]: "Underdelivery",
    [ProductClaimReason.WronglyDelivered]: "Wrong item delivered",
    [ProductClaimReason.WronglyOrdered]: "Incorrectly ordered",
    [ProductClaimReason.InferiorQuality]: "Quality is not right",
    [ProductClaimReason.WrongSerialNumber]: "Serial numbers are not correct",
  } as Record<ProductClaimReason, string>,

  productClaimProcessingStatus: {
    [ProductClaimProcessingStatus.ToBeSubmitted]: "Will be submitted",
    [ProductClaimProcessingStatus.Open]: "Open",
    [ProductClaimProcessingStatus.Pending]: "In progress",
    [ProductClaimProcessingStatus.Confirmed]: "Completed",
    [ProductClaimProcessingStatus.Denied]: "Rejected",
    [ProductClaimProcessingStatus.WaitingForSupplier]: "Waiting for supplier",
    [ProductClaimProcessingStatus.RetailerActionRequired]: "Retailer action required",
  } as Record<ProductClaimProcessingStatus, string>,

  preferredSettlement: {
    [PreferredSettlement.FullReplacement]: "Replacement item",
    [PreferredSettlement.PartialReplacement]: "Replacement part delivery",
    [PreferredSettlement.Refund]: "Credit note",
    [PreferredSettlement.Return]: "Return shipment",
    [PreferredSettlement.Purchase]: "Purchase",
    [PreferredSettlement.PurchaseAndRedelivery]: "Purchase & redelivery",
    [PreferredSettlement.ReturnAndRedelivery]: "Return & subsequent delivery",
  } as Record<PreferredSettlement, string>,

  grantedSettlement: {
    [GrantedSettlement.FullRefund]: "Full credit",
    [GrantedSettlement.FullReplacement]: "Replacement item",
    [GrantedSettlement.PartialRefund]: "Partial credit",
    [GrantedSettlement.PartialReplacement]: "Replacement part delivery",
    [GrantedSettlement.Unknown]: "Open",
    [GrantedSettlement.Return]: "Return shipment",
    [GrantedSettlement.Purchase]: "Purchase",
    [GrantedSettlement.PurchaseAndRedelivery]: "Purchase & redelivery",
    [GrantedSettlement.ReturnAndRedelivery]: "Return & subsequent delivery",
    [GrantedSettlement.ReturnAndRefund]: "Return & credit note",
  } as Record<GrantedSettlement, string>,

  paymentMethod: {
    CASH_ON_DELIVERY: "Cash on delivery",
    DIRECT_DEBIT: "Direct debit",
    PREPAYMENT: "Prepayment",
    INVOICE: "Invoice",
    CHEQUE: "cheque",
    CASH: "Cash",
    CREDIT_CARD: "Credit card",
    PAYPAL: "PayPal",
    SEPA_DIRECT_DEBIT: "SEPA Direct Debit",
    FACTORING: "Invoice",
    BRAINTREE: "Braintree",
    SHOPIFY_PAYMENT: "Shopify",
    SHOPIFY_PAYMENT_PAYPAL: "Shopify PayPal",
    OTHER: "Other",
  } as Record<PaymentMethod, string>,

  orderTypeEnum: {
    DROPSHIPPING: "Dropshipping",
  } as Record<OrderType, string>,

  paymentStatus: {
    [PaymentStatus.Open]: "Open",
    [PaymentStatus.Overdue]: "Overdue",
    [PaymentStatus.Paid]: "Paid",
    [PaymentStatus.Canceled]: "Cancelled",
  } as Record<PaymentStatus, string>,

  periodSelect: {
    [PeriodOption.SEVEN_DAYS]: "7 days",
    [PeriodOption.TWO_WEEKS]: "14 days",
    [PeriodOption.ONE_MONTH]: "1 month",
    [PeriodOption.THREE_MONTHS]: "3 months",
    [PeriodOption.SIX_MONTHS]: "6 months",
    [PeriodOption.TWELVE_MONTHS]: "12 months",
    [PeriodOption.START_OF_YEAR]: "Year to date",
    [PeriodOption.ALL]: "All",
    [PeriodOption.OLDER_TWELVE_MONTH]: "Older than 12 months",
    [PeriodOption.CUSTOM]: "Customized",
  } as Record<PeriodOption, string>,

  customerOrderItemStatus: {
    [CustomerOrderItemSummaryStatusEnum.Confirmed]: "Confirmed",
    [CustomerOrderItemSummaryStatusEnum.Unconfirmed]: "Unconfirmed",
  } as Record<CustomerOrderItemSummaryStatusEnum, string>,

  salesOrderAvailabilityStatus: {
    [SalesOrderItemAvailabilitySummaryStatusEnum.InStock]: "in stock",
    [SalesOrderItemAvailabilitySummaryStatusEnum.InStockOverdue]: "overdue",
    [SalesOrderItemAvailabilitySummaryStatusEnum.Incoming]: "coming soon",
    [SalesOrderItemAvailabilitySummaryStatusEnum.OnRequest]: "on demand",
    [SalesOrderItemAvailabilitySummaryStatusEnum.InPreorder]: "on pre-order",
  } as Record<SalesOrderItemAvailabilitySummaryStatusEnum, string>,

  businessType: {
    [RetailerDetailsBusinessTypeEnum.Store]: "Business",
    [RetailerDetailsBusinessTypeEnum.Wholesale]: "Wholesale",
    [RetailerDetailsBusinessTypeEnum.Ecommerce]: "E-Commerce",
    [RetailerDetailsBusinessTypeEnum.StoreAndEcommerce]: "Business & E-Commerce",
    [RetailerDetailsBusinessTypeEnum.Other]: "---",
  } as Record<RetailerDetailsBusinessTypeEnum, string>,

  fileUpload: {
    uploadText: "Drop files or click here",
    uploadTextSingle: "Drop files or click here",
    uploadAccepted: "Drop files",
    uploadAcceptedSingle: "Drop file",
    uploadRejected: "Incorrect file format",
    tooManyFiles: "Only one image can be uploaded.",
    uploadRejectedDetails: {
      "file-invalid-type": "Incorrect file format",
      "file-too-large": "File too large",
    } as Record<string, string>,
    uploadRejectedDetailNotification: "Error with files: {{wrongFiles}}",
    mimeTypes: {
      "image/png": "PNG",
      "image/gif": "GIF",
      "image/jpeg": "JPEG",
      "image/svg+xml": "SVG",
      "image/webp": "WEBP",
      "application/pdf": "PDF",
    } as Record<string, string>,
  },

  helpAndSupportMenu: {
    guidedTour: "Guided Tour",
    contact: "Contact",
  },

  userMenu: {
    accountSettingsLink: "My account",
    companyProfileLink: "My company",
    myStoreProfileLink: "My store profiles",
    logoutLink: "Logout",
    title: "My area",
    myServices: "My services",
    manageApi: "heoGATE - API",
    downloadProductCatalog: "Download product catalogue",
    copyProductCatalogLink: "Copy product catalogue link",
    linkCopied: "Link copied",
    releaseNotes: "Release notes",
  },

  copyGroup: {
    copyTooltipLabel: "Copy",
    copiedTooltipLabel: "Copied",
  },

  contact: {
    title: "Contact",
    infoText:
      "Do you have any questions or need support? Our sales colleagues will be happy to help you. Choose one of the following contact options:",
    infoTextFallback:
      "Do you have any questions or need support? Our sales colleagues will be happy to help you. You can reach us by e-mail at the following address:",

    infoTextApi:
      "If you have any questions about API access (heoGATE) and its use, please use the following e-mail address:",
  },

  feedback: {
    buttonTitle: "Feedback",

    drawer: {
      title: "Your feedback",

      mood: {
        title: "What is your general impression of the Business Console?",
        [MoodType.VERY_BAD]: "Very bad",
        [MoodType.BAD]: "Bad",
        [MoodType.NEUTRAL]: "Neutral",
        [MoodType.GOOD]: "Good",
        [MoodType.VERY_GOOD]: "Very good",
      } as Record<string, string>,
      category: {
        title: "Which topic would you like to give feedback on?",
        required: "Please select a topic",
        [CategoryType.IMPROVEMENT]: "Improvement",
        [CategoryType.ERROR]: "Error",
        [CategoryType.FEATURE]: "New feature idea",
      } as Record<string, string>,
      summary: {
        title: "Short summary / title of the feedback",
        placeholder: "Title of your feedback (max. 60 characters)",
        required: "Please enter a title",
      },
      description: {
        title: "Please enter your feedback below:",
        placeholder: "Enter feedback (max. 500 characters)",
        required: "Please enter a description of your request",
      },
      submitCloseTitle: "Confirmation",
      submitCloseText:
        "Do you really want to close the feedback form and discard all the content you have already entered?",
      cancel: "Cancel",
      submitCancel: "Keep open",
      submitClose: "Discard",
      send: "Send feedback",
      submitMessage: "Thank you for your feedback. It has been successfully sent to us.",
      requiredText: "Fields with * are mandatory.",
    },
  },

  releaseNotes: {
    title: "What's new?",
    releaseDate: "Released at {{ date }}",
    closeButtonLabel: "Close",
  },

  signedPdfLink: {
    defaultError: "An error occurred while retrieving the document. Please try again later.",
  },

  footer: {
    backendVersionLabel: "BE-Version",
    frontendVersionLabel: "FE version",
    buildDateLabel: "built on",
    impressumLinkLabel: "Legal information",
    privacyLinkLabel: "Data privacy",
    copyrightLinkLabel: "Copyright",
    copyTooltip: "Copy version",
    copiedTooltip: "Copied",
  },

  datatable: {
    noNextPage: "There are no further entries.",
  },

  filter: {
    resetAll: "Reset filter",
  },

  orderList: {
    showTooltip: "Show",
    hideTooltip: "Hide",
  },

  announcements: {
    moreInfo: "Read more",
    modalTitle: "Announcements",
  },

  tableColumnSettings: {
    toggleTooltip: "Column settings",
    save: "Save",
    cancel: "Cancel",
    reset: "Reset",
  },

  factoringLimit: {
    exceeded:
      "You have exceeded your factoring limit of <strong>{{ limit }}</strong> by <strong>{{ surplus }}</strong>!",
    cleared:
      "With a utilisation of <strong>{{ usage }}</strong> you are again below your factoring limit of <strong>{{ limit }}</strong>.",
  },

  billingStop: {
    added:
      "A billing stop has been imposed in your account. You can find more information <0>here</0>.",
    lifted: "The billing stop for your account has been lifted.",
  },

  lastSynced: {
    dataStatusLabel: "Status: ",
    notAvailable: "not available",
  },

  imageCropper: {
    newImageTooltip: "Select new image",
    resetViewTooltip: "Reset view",
    zoomInTooltip: "Zoom in",
    zoomOutTooltip: "Zoom out",
    outputTooSmallTooltip: "The cropped area is too small",
  },

  preventPageLeave: {
    modalTitle: "You still have unsaved changes!",
    modalDescription: "You still have unsaved changes that would be lost when leaving the page.",
    confirmPageLeave: "Leave page and discard changes",
    cancelPageLeave: "Stay on the page",
  },

  longTextLineCell: {
    copy: "Copy additional info",
    copied: "Additional info has been copied",
  },

  googlePlaceSelect: {
    noData: "No google place found for your address.",
  },

  gridLayout: {
    staticLockTooltip: "This widget cannot be moved.",
    hideWidgetTooltip: "Hide this widget",
    showWidgetTooltip: "Show this widget",
  },

  pageFilterModal: {
    sortBy: "Sort by",
    showResults: "Show results",
    resetFilter: "Reset filter",
  },

  viewModeToggle: {
    switchToTableMode: "Switch to table view",
    switchToCardsMode: "Switch to cards view",
  },
};
