import { Anchor, Box, Group, Paper, Space, Stack, Title } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PageContainer } from "~/ui/layout/PageContainer/PageContainer";
import { RoutesEnum } from "~/ui/routes/RoutesEnum";

import { TotalSalesChart } from "./Chart/TotalSalesChart";
import { TotalSalesTable } from "./Table/TotalSalesTable";

const TotalSalesDetails = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "totalSalesDetailsPage",
  });
  return (
    <PageContainer
      id="total-sales-details-page-container"
      pageTitle={t("titel")}
      positionRelative
      withPadding
    >
      <Title>{t("titel")}</Title>
      <Paper withBorder p="xxl" radius="md">
        <Stack gap={"lg"}>
          <Box pb={"md"}>
            <Anchor component={Link} to={RoutesEnum.DASHBOARD}>
              <Group align="center" gap={"xs"}>
                <IconArrowLeft size={24} />
                {t("backToDashboardButton")}
              </Group>
            </Anchor>
          </Box>
          <TotalSalesChart />
          <Space h={"lg"} />
          <TotalSalesTable />
        </Stack>
      </Paper>
    </PageContainer>
  );
};

export { TotalSalesDetails };
