/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductClaimConversation
 */
export interface ProductClaimConversation {
    /**
     * 
     * @type {string}
     * @memberof ProductClaimConversation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductClaimConversation
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof ProductClaimConversation
     */
    fromEmail?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductClaimConversation
     */
    toEmails: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof ProductClaimConversation
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductClaimConversation
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductClaimConversation
     */
    lastEditedAt?: Date;
}

/**
 * Check if a given object implements the ProductClaimConversation interface.
 */
export function instanceOfProductClaimConversation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "toEmails" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function ProductClaimConversationFromJSON(json: any): ProductClaimConversation {
    return ProductClaimConversationFromJSONTyped(json, false);
}

export function ProductClaimConversationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductClaimConversation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'body': json['body'],
        'fromEmail': !exists(json, 'from_email') ? undefined : json['from_email'],
        'toEmails': json['to_emails'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'lastEditedAt': !exists(json, 'last_edited_at') ? undefined : (new Date(json['last_edited_at'])),
    };
}

export function ProductClaimConversationToJSON(value?: ProductClaimConversation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'body': value.body,
        'from_email': value.fromEmail,
        'to_emails': value.toEmails,
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'last_edited_at': value.lastEditedAt === undefined ? undefined : (value.lastEditedAt.toISOString()),
    };
}

