import { Alert, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetRetailerNotificationQuery } from "~/api/hooks/retailerNotification/useGetRetailerNotificationQuery";
import { useSetRetailerNotificationAsRead } from "~/api/hooks/retailerNotification/useSetRetailerNotificationAsRead";
import { MarkRetailerNotificationAsReadRequest, RetailerNotificationMessage } from "~/api/openapi";

const isWithinDisplayPeriod = (displayPeriod: RetailerNotificationMessage["displayPeriod"]) => {
  const currentDate = new Date();
  const startDate = new Date(displayPeriod.displayFrom || new Date());
  const endDate = new Date(displayPeriod.displayUntil || new Date());
  return currentDate >= startDate && currentDate <= endDate;
};

const RetailerNotifications: React.FC = () => {
  const { i18n } = useTranslation();
  const { data: retailerNotificationsData, isFetched: isRetailerNotificationsFetched } =
    useGetRetailerNotificationQuery({
      langCode: i18n.language.toUpperCase(),
    });

  const [retailerNotifications, setRetailerNotifications] = useState<RetailerNotificationMessage[]>(
    [],
  );

  const { mutate: updateNotificationReadStatus } = useSetRetailerNotificationAsRead();

  const markNotificationAsRead = (
    notificationId: RetailerNotificationMessage["notificationId"],
  ) => {
    const args: MarkRetailerNotificationAsReadRequest = {
      notificationId: notificationId,
    };
    updateNotificationReadStatus(args);
  };

  useEffect(() => {
    if (!isRetailerNotificationsFetched || !retailerNotificationsData?.length) {
      return;
    }

    setRetailerNotifications(
      retailerNotificationsData.filter((notification: RetailerNotificationMessage) =>
        isWithinDisplayPeriod(notification.displayPeriod),
      ),
    );
  }, [isRetailerNotificationsFetched, retailerNotificationsData]);

  const dismissNotification = (notificationId: RetailerNotificationMessage["notificationId"]) => {
    setRetailerNotifications(
      retailerNotifications?.filter(
        (retailerNotification: RetailerNotificationMessage) =>
          retailerNotification.notificationId !== notificationId,
      ),
    );
  };

  return (
    <>
      {retailerNotifications?.map((notification: RetailerNotificationMessage) => {
        return (
          <Alert
            key={notification.notificationId}
            variant="light"
            color="red"
            withCloseButton={notification.acknowledgeable}
            onClose={
              notification.acknowledgeable
                ? () => {
                    dismissNotification(notification.notificationId);
                    markNotificationAsRead(notification.notificationId);
                  }
                : undefined
            }
          >
            <Text fz="sm">{notification.message}</Text>
          </Alert>
        );
      })}
    </>
  );
};

export { RetailerNotifications };
