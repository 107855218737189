import React from "react";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";

import { wrapCreateBrowserRouter } from "@sentry/react";
import { RouteErrorPage } from "../components/ErrorBoundary/RouteErrorPage";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import { TotalSalesDetails } from "../pages/DashboardPage/TotalSalesDetails/TotalSalesDetails";
import { NoAccessPage } from "../pages/NoAccessPage/NoAccessPage";
import { AuthRoute } from "./RouteGuards/AuthRoute";
import { GuestRoute } from "./RouteGuards/GuestRoute";
import { RoutesEnum } from "./RoutesEnum";

const NotFoundPage = React.lazy(() => import("../pages/NotFoundPage/NotFoundPage"));
const AccountPage = React.lazy(() => import("../pages/AccountPage/AccountPage"));
const ProductsPage = React.lazy(() => import("../pages/ProductsPage/ProductsPage"));
const CreditNotesPage = React.lazy(() => import("../pages/CreditsPage/CreditNotesPage"));
const ProductClaimsPage = React.lazy(
  () => import("../pages/ProductClaims/ProductClaimsPage/ProductClaimsPage"),
);

const CreateClaimsPage = React.lazy(
  () => import("../pages/ProductClaims/CreateClaimsPage/CreateClaimsPage"),
);
const RetailerPage = React.lazy(() => import("../pages/RetailerPage/RetailerPage"));
const InvoicesPage = React.lazy(() => import("../pages/InvoicesPage/InvoicesPage"));
const CustomerOrdersPage = React.lazy(
  () => import("../pages/CustomerOrdersPage/CustomerOrdersPage"),
);
const SalesOrdersPage = React.lazy(() => import("../pages/SalesOrdersPage/SalesOrdersPage"));
const ManageApiPage = React.lazy(() => import("../pages/ManageApiPage/ManageApiPage"));
const LoginPage = React.lazy(() => import("../pages/LoginPage/LoginPage"));
const LogoutPage = React.lazy(() => import("../pages/LogoutPage/LogoutPage"));

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const Router = () => {
  const router = sentryCreateBrowserRouter([
    {
      path: "/",
      element: <AuthRoute />,
      errorElement: <RouteErrorPage />,
      children: [
        { index: true, element: <Navigate to={RoutesEnum.DASHBOARD} replace /> },
        {
          path: `${RoutesEnum.DASHBOARD}/*`,
          children: [
            { index: true, element: <DashboardPage /> },
            {
              id: "TotalSalesDetails",
              path: RoutesEnum.TOTAL_SALES_DETAILS,
              element: <TotalSalesDetails />,
            },
          ],
        },
        { path: `${RoutesEnum.ACCOUNT}/*`, element: <AccountPage /> },
        { path: `${RoutesEnum.RETAILER}/*`, element: <RetailerPage /> },
        { path: `${RoutesEnum.PRODUCTS}/*`, element: <ProductsPage /> },
        { path: `${RoutesEnum.CUSTOMER_ORDERS}/*`, element: <CustomerOrdersPage /> },
        { path: `${RoutesEnum.INVOICES}/*`, element: <InvoicesPage /> },
        { path: `${RoutesEnum.PRODUCT_CLAIMS}/*`, element: <ProductClaimsPage /> },
        { path: `${RoutesEnum.CREATE_PRODUCT_CLAIMS}/*`, element: <CreateClaimsPage /> },
        { path: `${RoutesEnum.SALES_ORDERS}/*`, element: <SalesOrdersPage /> },
        { path: `${RoutesEnum.CREDITS}/*`, element: <CreditNotesPage /> },
        { path: `${RoutesEnum.MANAGE_API}/*`, element: <ManageApiPage /> },
      ],
    },

    {
      path: "/",
      element: <GuestRoute />,
      errorElement: <RouteErrorPage />,
      children: [{ path: RoutesEnum.LOGIN, element: <LoginPage /> }],
    },

    { path: RoutesEnum.NO_ACCESS, element: <NoAccessPage />, errorElement: <RouteErrorPage /> },
    { path: RoutesEnum.LOGOUT, element: <LogoutPage />, errorElement: <RouteErrorPage /> },

    { path: "*", element: <NotFoundPage />, errorElement: <RouteErrorPage /> },
  ]);

  return <RouterProvider router={router} />;
};

export { Router };
