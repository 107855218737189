/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { HeoCustomerNumber } from './HeoCustomerNumber';
import {
    HeoCustomerNumberFromJSON,
    HeoCustomerNumberFromJSONTyped,
    HeoCustomerNumberToJSON,
} from './HeoCustomerNumber';
import type { HeoTransactionNumber } from './HeoTransactionNumber';
import {
    HeoTransactionNumberFromJSON,
    HeoTransactionNumberFromJSONTyped,
    HeoTransactionNumberToJSON,
} from './HeoTransactionNumber';
import type { InvoicePrices } from './InvoicePrices';
import {
    InvoicePricesFromJSON,
    InvoicePricesFromJSONTyped,
    InvoicePricesToJSON,
} from './InvoicePrices';
import type { OrderOrigin } from './OrderOrigin';
import {
    OrderOriginFromJSON,
    OrderOriginFromJSONTyped,
    OrderOriginToJSON,
} from './OrderOrigin';
import type { OrderType } from './OrderType';
import {
    OrderTypeFromJSON,
    OrderTypeFromJSONTyped,
    OrderTypeToJSON,
} from './OrderType';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';
import type { PaymentType } from './PaymentType';
import {
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './PaymentType';
import type { ShipmentProjection } from './ShipmentProjection';
import {
    ShipmentProjectionFromJSON,
    ShipmentProjectionFromJSONTyped,
    ShipmentProjectionToJSON,
} from './ShipmentProjection';

/**
 * 
 * @export
 * @interface InvoiceSummary
 */
export interface InvoiceSummary {
    /**
     * 
     * @type {string}
     * @memberof InvoiceSummary
     */
    id: string;
    /**
     * 
     * @type {HeoTransactionNumber}
     * @memberof InvoiceSummary
     */
    number: HeoTransactionNumber;
    /**
     * 
     * @type {HeoCustomerNumber}
     * @memberof InvoiceSummary
     */
    customerNumber: HeoCustomerNumber;
    /**
     * 
     * @type {Address}
     * @memberof InvoiceSummary
     */
    billingAddress: Address;
    /**
     * 
     * @type {Address}
     * @memberof InvoiceSummary
     */
    shippingAddress: Address;
    /**
     * 
     * @type {PaymentType}
     * @memberof InvoiceSummary
     */
    paymentType?: PaymentType;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof InvoiceSummary
     */
    paymentMethod: PaymentMethod;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceSummary
     */
    paymentDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceSummary
     */
    paymentDueDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof InvoiceSummary
     */
    total: number;
    /**
     * 
     * @type {InvoicePrices}
     * @memberof InvoiceSummary
     */
    prices: InvoicePrices;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceSummary
     */
    filed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceSummary
     */
    canceled: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSummary
     */
    canceledReason?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSummary
     */
    reference: string;
    /**
     * 
     * @type {Array<OrderOrigin>}
     * @memberof InvoiceSummary
     */
    orderOrigins?: Array<OrderOrigin>;
    /**
     * 
     * @type {Array<OrderType>}
     * @memberof InvoiceSummary
     */
    orderTypes?: Array<OrderType>;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceSummary
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<ShipmentProjection>}
     * @memberof InvoiceSummary
     */
    shipments: Array<ShipmentProjection>;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceSummary
     */
    hasDocument: boolean;
}

/**
 * Check if a given object implements the InvoiceSummary interface.
 */
export function instanceOfInvoiceSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "customerNumber" in value;
    isInstance = isInstance && "billingAddress" in value;
    isInstance = isInstance && "shippingAddress" in value;
    isInstance = isInstance && "paymentMethod" in value;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "prices" in value;
    isInstance = isInstance && "filed" in value;
    isInstance = isInstance && "canceled" in value;
    isInstance = isInstance && "reference" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "shipments" in value;
    isInstance = isInstance && "hasDocument" in value;

    return isInstance;
}

export function InvoiceSummaryFromJSON(json: any): InvoiceSummary {
    return InvoiceSummaryFromJSONTyped(json, false);
}

export function InvoiceSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'number': HeoTransactionNumberFromJSON(json['number']),
        'customerNumber': HeoCustomerNumberFromJSON(json['customerNumber']),
        'billingAddress': AddressFromJSON(json['billingAddress']),
        'shippingAddress': AddressFromJSON(json['shippingAddress']),
        'paymentType': !exists(json, 'paymentType') ? undefined : PaymentTypeFromJSON(json['paymentType']),
        'paymentMethod': PaymentMethodFromJSON(json['paymentMethod']),
        'paymentDate': !exists(json, 'paymentDate') ? undefined : (new Date(json['paymentDate'])),
        'paymentDueDate': !exists(json, 'paymentDueDate') ? undefined : (new Date(json['paymentDueDate'])),
        'total': json['total'],
        'prices': InvoicePricesFromJSON(json['prices']),
        'filed': json['filed'],
        'canceled': json['canceled'],
        'canceledReason': !exists(json, 'canceledReason') ? undefined : json['canceledReason'],
        'reference': json['reference'],
        'orderOrigins': !exists(json, 'orderOrigins') ? undefined : ((json['orderOrigins'] as Array<any>).map(OrderOriginFromJSON)),
        'orderTypes': !exists(json, 'orderTypes') ? undefined : ((json['orderTypes'] as Array<any>).map(OrderTypeFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'shipments': ((json['shipments'] as Array<any>).map(ShipmentProjectionFromJSON)),
        'hasDocument': json['hasDocument'],
    };
}

export function InvoiceSummaryToJSON(value?: InvoiceSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'number': HeoTransactionNumberToJSON(value.number),
        'customerNumber': HeoCustomerNumberToJSON(value.customerNumber),
        'billingAddress': AddressToJSON(value.billingAddress),
        'shippingAddress': AddressToJSON(value.shippingAddress),
        'paymentType': PaymentTypeToJSON(value.paymentType),
        'paymentMethod': PaymentMethodToJSON(value.paymentMethod),
        'paymentDate': value.paymentDate === undefined ? undefined : (value.paymentDate.toISOString().substr(0,10)),
        'paymentDueDate': value.paymentDueDate === undefined ? undefined : (value.paymentDueDate.toISOString().substr(0,10)),
        'total': value.total,
        'prices': InvoicePricesToJSON(value.prices),
        'filed': value.filed,
        'canceled': value.canceled,
        'canceledReason': value.canceledReason,
        'reference': value.reference,
        'orderOrigins': value.orderOrigins === undefined ? undefined : ((value.orderOrigins as Array<any>).map(OrderOriginToJSON)),
        'orderTypes': value.orderTypes === undefined ? undefined : ((value.orderTypes as Array<any>).map(OrderTypeToJSON)),
        'createdAt': (value.createdAt.toISOString()),
        'shipments': ((value.shipments as Array<any>).map(ShipmentProjectionToJSON)),
        'hasDocument': value.hasDocument,
    };
}

