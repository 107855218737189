import { CancellationRateStatus } from "../CancellationRateStatus";

export const GOOD_CANCELLATION_RATE_BORDER = 3;
export const MEDIUM_CANCELLATION_RATE_BORDER = 10;

export const getCancellationRateStatus = (quota: number) => {
  if (quota < GOOD_CANCELLATION_RATE_BORDER) {
    return CancellationRateStatus.GOOD;
  }
  if (quota < MEDIUM_CANCELLATION_RATE_BORDER) {
    return CancellationRateStatus.MEDIUM;
  }
  return CancellationRateStatus.BAD;
};
