import {
  Box,
  Divider,
  Drawer,
  Group,
  Radio,
  Space,
  Stack,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { openConfirmModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";

import { useCreateFeedbackMutation } from "~/api/hooks/feedback.hooks";
import { useGetBuildVersions } from "~/hooks/useGetBuildVersions";

import { SmileyMaeh } from "../CustomIcons/SmileyMaeh";
import { SmileyMouthOpenMaeh } from "../CustomIcons/SmileyMouthOpenMaeh";
import { SmileyMouthOpenNaice } from "../CustomIcons/SmileyMouthOpenNaice";
import { SmileyNaice } from "../CustomIcons/SmileyNaice";
import { SmileyNeutral } from "../CustomIcons/SmileyNeutral";
import { MoodIcon } from "./MoodIcon";
import { isSelectedMood } from "./isSelectedMood";

import { useResponsiveMode } from "~/hooks/ui/useResponsiveMode";
import styles from "./FeedbackDrawer.module.css";
import { FeedbackDrawerActionBar } from "./FeedbackDrawerActionBar";
import { FeedbackDrawerActionBarMobile } from "./FeedbackDrawerActionBarMobile";

interface FeedbackDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export enum MoodType {
  VERY_BAD = 0,
  BAD = 1,
  NEUTRAL = 2,
  GOOD = 3,
  VERY_GOOD = 4,
}

export enum CategoryType {
  IMPROVEMENT = "IMPROVEMENT",
  ERROR = "ERROR",
  FEATURE = "FEATURE",
}
interface Mood {
  moodType: MoodType;
  translation: string;
}

export interface FeedbackForm {
  selectedMood?: Mood;
  category: CategoryType | undefined;
  summary: string;
  description: string;
}

const FeedbackDrawer: React.FC<FeedbackDrawerProps> = (props) => {
  const { t } = useTranslation();
  const { onClose, isOpen } = props;

  const handleCreateRetailerFeedbackSuccess = () => {
    showNotification({
      message: t("feedback.drawer.submitMessage"),
      autoClose: 3000,
      color: "green",
    });

    handleOnClose();
  };

  const { mutate: createRetailerFeedback } = useCreateFeedbackMutation({
    onSuccess: handleCreateRetailerFeedbackSuccess,
  });

  const { formatedConcatValues } = useGetBuildVersions();
  const handleSubmit = () => {
    createRetailerFeedback({
      selectedMood: feedbackForm.values.selectedMood?.translation,
      categorie: t(`feedback.drawer.category.${feedbackForm.values.category}`),
      summary: feedbackForm.values.summary.trim(),
      description: feedbackForm.values.description.trim(),
      buildVersionInfo: formatedConcatValues,
    });
  };

  const feedbackForm = useForm<FeedbackForm>({
    validateInputOnChange: true,
    initialValues: {
      category: undefined,
      summary: "",
      description: "",
    },
    validate: {
      summary: isNotEmpty(t("feedback.drawer.summary.required")),
      description: isNotEmpty(t("feedback.drawer.description.required")),
      category: isNotEmpty(t("feedback.drawer.category.required")),
    },
  });

  const handleMoodSelection = (mood: MoodType) => {
    if (isSelectedMood(mood, feedbackForm.values.selectedMood?.moodType)) {
      feedbackForm.setFieldValue("selectedMood", undefined);
      return;
    }
    feedbackForm.setFieldValue("selectedMood", {
      moodType: mood,
      translation: `${mood} = ${t(`feedback.drawer.mood.${mood}`)}`,
    });
  };

  const handleOnClose = () => {
    feedbackForm.reset();
    onClose();
  };

  const checkIfDirtyAndClose = () => {
    if (feedbackForm.isDirty()) {
      openConfirmModal({
        withinPortal: true,
        title: t("feedback.drawer.submitCloseTitle"),
        children: <Text>{t("feedback.drawer.submitCloseText")}</Text>,
        labels: {
          confirm: t("feedback.drawer.submitClose"),
          cancel: t("feedback.drawer.submitCancel"),
        },
        onConfirm: () => handleOnClose(),
      });
      return;
    }
    handleOnClose();
  };

  const { isMobile } = useResponsiveMode();

  const transitionDuration = isMobile ? 0 : 500;

  return (
    <>
      <Drawer
        opened={isOpen}
        onClose={checkIfDirtyAndClose}
        title={
          <Text fw={"bold"} fz={"26px"}>
            {t("feedback.drawer.title")}
          </Text>
        }
        size="xl"
        position="right"
        lockScroll={false}
        transitionProps={{
          transition: "rotate-left",
          duration: transitionDuration,
          timingFunction: "ease",
        }}
        classNames={{ header: styles.header, close: styles.closeButton }}
        className={styles.drawer}
        data-testid="feedback-drawer"
      >
        <Divider />
        <form
          onSubmit={feedbackForm.onSubmit(handleSubmit)}
          className={isMobile ? styles.formMobile : styles.form}
        >
          <Stack gap={"xl"} p={isMobile ? "md" : 36} className={styles.innerForm}>
            <Box mb={10}>
              <Text className={styles.moodText}>{t("feedback.drawer.mood.title")}</Text>
              <Space />

              <Group wrap="nowrap">
                <MoodIcon
                  moodType={MoodType.VERY_BAD}
                  isSelected={isSelectedMood(
                    MoodType.VERY_BAD,
                    feedbackForm.values.selectedMood?.moodType,
                  )}
                  onSelect={handleMoodSelection}
                >
                  <SmileyMouthOpenMaeh />
                </MoodIcon>

                <MoodIcon
                  moodType={MoodType.BAD}
                  isSelected={isSelectedMood(
                    MoodType.BAD,
                    feedbackForm.values.selectedMood?.moodType,
                  )}
                  onSelect={handleMoodSelection}
                >
                  <SmileyMaeh />
                </MoodIcon>

                <MoodIcon
                  moodType={MoodType.NEUTRAL}
                  isSelected={isSelectedMood(
                    MoodType.NEUTRAL,
                    feedbackForm.values.selectedMood?.moodType,
                  )}
                  onSelect={handleMoodSelection}
                >
                  <SmileyNeutral />
                </MoodIcon>
                <MoodIcon
                  moodType={MoodType.GOOD}
                  isSelected={isSelectedMood(
                    MoodType.GOOD,
                    feedbackForm.values.selectedMood?.moodType,
                  )}
                  onSelect={handleMoodSelection}
                >
                  <SmileyNaice />
                </MoodIcon>
                <MoodIcon
                  moodType={MoodType.VERY_GOOD}
                  isSelected={isSelectedMood(
                    MoodType.VERY_GOOD,
                    feedbackForm.values.selectedMood?.moodType,
                  )}
                  onSelect={handleMoodSelection}
                >
                  <SmileyMouthOpenNaice />
                </MoodIcon>
              </Group>
            </Box>

            <Radio.Group
              name="category"
              label={t("feedback.drawer.category.title")}
              withAsterisk
              {...feedbackForm.getInputProps("category")}
              size={"md"}
            >
              <Stack gap={isMobile ? "lg" : "xs"} pt={"xs"}>
                <Radio
                  value={CategoryType.IMPROVEMENT}
                  label={t(`feedback.drawer.category.${CategoryType.IMPROVEMENT}`)}
                />
                <Radio
                  value={CategoryType.ERROR}
                  label={t(`feedback.drawer.category.${CategoryType.ERROR}`)}
                />
                <Radio
                  value={CategoryType.FEATURE}
                  label={t(`feedback.drawer.category.${CategoryType.FEATURE}`)}
                />
              </Stack>
            </Radio.Group>

            <TextInput
              withAsterisk
              placeholder={t("feedback.drawer.summary.placeholder")}
              label={t("feedback.drawer.summary.title")}
              variant={"filled"}
              maxLength={60}
              {...feedbackForm.getInputProps("summary")}
              size={"md"}
              classNames={{ label: styles.textInputLabel }}
            />

            <Textarea
              withAsterisk
              placeholder={t("feedback.drawer.description.placeholder")}
              label={t("feedback.drawer.description.title")}
              variant={"filled"}
              rows={6}
              {...feedbackForm.getInputProps("description")}
              size={"md"}
              maxLength={2000}
              classNames={{ label: styles.textInputLabel }}
            />

            <Text c={"dimmed"} size="sm">
              {t("feedback.drawer.requiredText")}
            </Text>

            {isMobile ? (
              <FeedbackDrawerActionBarMobile
                checkIfDirtyAndClose={checkIfDirtyAndClose}
                isDirty={feedbackForm.isDirty()}
              />
            ) : (
              <FeedbackDrawerActionBar
                checkIfDirtyAndClose={checkIfDirtyAndClose}
                isDirty={feedbackForm.isDirty()}
              />
            )}
          </Stack>
        </form>
      </Drawer>
    </>
  );
};

export { FeedbackDrawer };
