import { useEffect } from "react";
import { useTranslation } from "react-i18next";

type PageTitleProps = {
  title?: string;
  preTitle?: string;
};

const usePageTitle = (props: PageTitleProps) => {
  const { title, preTitle } = props;
  const { t } = useTranslation();

  const pageTitle = title
    ? `${t("pageTitle.prefix")}${preTitle ? `${preTitle} - ${title} ` : title}`
    : t("pageTitle.default");

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);
};

export { usePageTitle };
