import { ThemeIcon } from "@mantine/core";

import { PaymentStatus } from "~/api/openapi";

import { InvoiceOpen } from "../../../../components/CustomIcons/InvoiceOpen";
import { InvoiceOverdue } from "../../../../components/CustomIcons/InvoiceOverdue";

interface PaymentStatusIconProps {
  paymentStatus: PaymentStatus;
}

const PaymentStatusIcon: React.FC<PaymentStatusIconProps> = (props) => {
  const { paymentStatus } = props;

  const isOverdue = paymentStatus === PaymentStatus.Overdue;

  const StatusIcon = () => {
    return isOverdue ? <InvoiceOverdue size={20} /> : <InvoiceOpen size={20} />;
  };

  return (
    <ThemeIcon radius="xl" size="xl" color={isOverdue ? "red" : "blue"} pl={2}>
      <StatusIcon />
    </ThemeIcon>
  );
};
export { PaymentStatusIcon };
