/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoreProfileEquipment
 */
export interface StoreProfileEquipment {
    /**
     * 
     * @type {string}
     * @memberof StoreProfileEquipment
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileEquipment
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileEquipment
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof StoreProfileEquipment
     */
    icon: string;
}

/**
 * Check if a given object implements the StoreProfileEquipment interface.
 */
export function instanceOfStoreProfileEquipment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "icon" in value;

    return isInstance;
}

export function StoreProfileEquipmentFromJSON(json: any): StoreProfileEquipment {
    return StoreProfileEquipmentFromJSONTyped(json, false);
}

export function StoreProfileEquipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoreProfileEquipment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'name': json['name'],
        'description': json['description'],
        'icon': json['icon'],
    };
}

export function StoreProfileEquipmentToJSON(value?: StoreProfileEquipment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'name': value.name,
        'description': value.description,
        'icon': value.icon,
    };
}

