import { Anchor, Button, Group } from "@mantine/core";
import { useTranslation } from "react-i18next";

type FeedbackDrawerActionBarMobileProps = {
  checkIfDirtyAndClose: () => void;
  isDirty: boolean;
};
const FeedbackDrawerActionBarMobile: React.FC<FeedbackDrawerActionBarMobileProps> = (props) => {
  const { isDirty, checkIfDirtyAndClose } = props;
  const { t } = useTranslation();
  return (
    <Group justify={"right"}>
      <Anchor component="button" onClick={checkIfDirtyAndClose} w={"100%"}>
        {t("feedback.drawer.cancel")}
      </Anchor>
      <Button type="submit" disabled={!isDirty} fullWidth>
        {t("feedback.drawer.send")}
      </Button>
    </Group>
  );
};

export { FeedbackDrawerActionBarMobile };
