/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductItem } from './ProductItem';
import {
    ProductItemFromJSON,
    ProductItemFromJSONTyped,
    ProductItemToJSON,
} from './ProductItem';

/**
 * 
 * @export
 * @interface AvailableItems
 */
export interface AvailableItems {
    /**
     * 
     * @type {number}
     * @memberof AvailableItems
     */
    monthNumber: number;
    /**
     * 
     * @type {number}
     * @memberof AvailableItems
     */
    countPositions: number;
    /**
     * 
     * @type {number}
     * @memberof AvailableItems
     */
    sumOfPositionsPrice: number;
    /**
     * 
     * @type {Array<ProductItem>}
     * @memberof AvailableItems
     */
    products: Array<ProductItem>;
}

/**
 * Check if a given object implements the AvailableItems interface.
 */
export function instanceOfAvailableItems(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "monthNumber" in value;
    isInstance = isInstance && "countPositions" in value;
    isInstance = isInstance && "sumOfPositionsPrice" in value;
    isInstance = isInstance && "products" in value;

    return isInstance;
}

export function AvailableItemsFromJSON(json: any): AvailableItems {
    return AvailableItemsFromJSONTyped(json, false);
}

export function AvailableItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'monthNumber': json['monthNumber'],
        'countPositions': json['countPositions'],
        'sumOfPositionsPrice': json['sumOfPositionsPrice'],
        'products': ((json['products'] as Array<any>).map(ProductItemFromJSON)),
    };
}

export function AvailableItemsToJSON(value?: AvailableItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'monthNumber': value.monthNumber,
        'countPositions': value.countPositions,
        'sumOfPositionsPrice': value.sumOfPositionsPrice,
        'products': ((value.products as Array<any>).map(ProductItemToJSON)),
    };
}

